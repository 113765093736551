<app-metas></app-metas>
<app-header *ngIf="showHeaderFooter"></app-header>
<!-- <app-loading-bar></app-loading-bar> -->
<router-outlet></router-outlet>
<app-notification></app-notification>
<app-notificationarray></app-notificationarray>
<app-footer *ngIf="showHeaderFooter"></app-footer>

<app-spinner></app-spinner>

