import { Response } from '../responses/response.model';
export class TypeOfDocuments {
  id: string = '';
  name: string = '';
}

export class ResponseTypeOfDocuments {
  typeOfDocuments: TypeOfDocuments[] = [];
  response: Response = new Response;
}


export class LegalDocuments {
  id: string = '';
  name: string = '';
  description: string = '';
  documentText: string = '';
  showfile: boolean = false;
  url: string = '';
  TypeOfDocuments: TypeOfDocuments[] = [];
  TypeOfDocumentsSort: string = '';
}

export class ResponseLegalDocuments {
  legalDocuments: LegalDocuments[] = [];
  response: Response = new Response;
  totalDocuments: number = 0;
}

