import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
    name: 'mycurrency',
  })
  export class MycurrencyPipe implements PipeTransform {
    transform(
        value: number,
        currencyCode: string = 'CRC',
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol',
        digitsInfo: string = '1.2-2',
        locale: string = 'es',
    ): string | null {
        return formatCurrency(
          (value ? value : 0 ),
          locale,
          getCurrencySymbol(currencyCode, 'narrow'),
          currencyCode,
          digitsInfo,
        ).replace(' ','').replace('₡','₡ ');
    }
}
