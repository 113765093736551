export interface ResponseInterface {

code:string ;
message:string;
status:boolean;
alias:string;
messageCore: string[];
titleMessage:string;
type:string;
action:string;
buttonCancelText:string;
buttonAcceptText:string;
url:string;
Messages: string[];
}

export class Response {

  code: string = '' ;
  message:string = '' ;
  status:boolean = false;
  alias:string = '';
  messageCore: string[] = [];
  titleMessage:string = '';
type:string = '';
action:string = '';
buttonCancelText:string = '';
buttonAcceptText:string = '';
url:string = '';
Messages:string[] = []

  }

export class CoreResponse {
  AllowApplyCoupons: null | boolean = false;
  IDSavedCard: null | string = '';
  IsValid: boolean = false;
  Messages: string[] = [];
  Parameters: string = '';
  ProgId: null | string = '';
  ServerPath: null | string = '';
  UserId: null | string = '';
  WithErrors: boolean = false;
}
