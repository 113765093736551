import { Injectable } from '@angular/core';
import { UtilitiesServicesService } from './utilities-services.service';
import { ProductsService } from '../core/products.service';
import { NotificationService } from './notification.service';
import { LoadingService } from './loading.service';
import { environment } from 'src/environments/environment';
import { DatalakeService } from '../core/datalake.service';
import { CartService } from '../core/cart.service';
import { Subject } from 'rxjs';
import { MessagesService } from '../sitefinity/messages.service';
import { NotificationArrayService } from './notification-array.service';
import { WindowReferenceService } from 'src/app/services/shared/windows-reference.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // private currentPath: string = '';
  // private sharedTitle: string = 'Titulo';
  // private defaultTitle= 'Farmacia Fischel en línea';
  // private defaultTitleEnding= ' | Fischel en línea';
  private windowDataLayer: any;
  private urlSite = environment.urlSite;
  private cartUpdatedSubject = new Subject<void>();
  private headerUpdatedSubject = new Subject<void>();
  private footerUpdatedSubject = new Subject<void>();
  cartUpdated$ = this.cartUpdatedSubject.asObservable();
  headerUpdated$ = this.headerUpdatedSubject.asObservable();
  footerUpdated$ = this.footerUpdatedSubject.asObservable();

  constructor(
    private utilitiesService: UtilitiesServicesService,
    private productService: ProductsService,
    private notificationService: NotificationService,
    private notificationArrayService: NotificationArrayService,
    private loader: LoadingService,
    private datalakeService: DatalakeService,
    private cartService: CartService,
    private messageService: MessagesService,
    private _windowRef: WindowReferenceService

    ){
      this.windowDataLayer = _windowRef.nativeWindow;
    }

  // GetCurrentPath(): string {
  //   return this.currentPath;
  // }

  /*
  *  Method used by appcomponent OnInit
  */
  // SetUrl(nuevaVariable:string) {
  //    this.currentPath=nuevaVariable;

  //    switch(this.currentPath){
  //     case '/marcas-destacadas':
  //       this.sharedTitle= 'Marcas Destacas'+ this.defaultTitleEnding;
  //       break;
  //       case '/registro':
  //       this.sharedTitle= 'Registro'+ this.defaultTitleEnding;
  //       break;
  //       default:
  //         this.sharedTitle=this.defaultTitle;
  //    }
  // }

  // GetTitle(): string {
  //   return this.sharedTitle;
  // }



  async addProductsToCart(product: any, selectedUnits: string, selectedQuantity: number, selectedUnitPrice: number, cupon?: any, isCuponApplie?: boolean) {


    selectedUnits = selectedUnits === 'Unidades' || selectedUnits === 'Unidad' ? 'Unidad' :
    (selectedUnits === 'Cajas' || selectedUnits === 'Caja' ? 'Caja' : selectedUnits);

    const productAttributes = product.ProductAttributes ? product.ProductAttributes : product.SelectedAttributes;
    const cartProductAttributeIndex = selectedUnits === 'Caja' && productAttributes.length > 1 ? 1 : 0;
    const cartProductAttribute = productAttributes[cartProductAttributeIndex];

    let categorieName = '';
    if (product.Categories?.length > 0)
      categorieName = product.Categories[cartProductAttributeIndex]?.Name
    else if (product.categories?.length > 0)
      categorieName = product.categories[cartProductAttributeIndex]?.Name

    let attribute: any = [];

    if (cartProductAttribute) {
      attribute = [{
        AddedPrice: cartProductAttribute.AddedPrice,
        CategoryName: cartProductAttribute.CategoryName ? cartProductAttribute.CategoryName : categorieName,
        IdParentProductAttribute: cartProductAttribute.ParentAttribute ? cartProductAttribute.ParentAttribute : cartProductAttribute.IdParentProductAttribute,
        IdProductAttribute: cartProductAttribute.IDProductAttribute ? cartProductAttribute.IDProductAttribute : cartProductAttribute.IdProductAttribute,
        IsAvailable: cartProductAttribute.IsAvailable,
        IsPriceDifferent: cartProductAttribute.IsPriceDifferent ? cartProductAttribute.IsPriceDifferent : false,
        IsShopAvailable: cartProductAttribute.IsShopAvailable,
        Name: selectedUnits,
        PriceDiff: null,
        PriceDifference: null,
        PriceWithTaxes: cartProductAttribute.PriceWithTaxes,
        PromotionPrice: cartProductAttribute.PriceWithDiscount ? cartProductAttribute.PriceWithDiscount : 0,
        Quantity: selectedQuantity,
        RealPrice: selectedUnitPrice,
        Sku3: cartProductAttribute.SKU ? cartProductAttribute.SKU : cartProductAttribute.Sku3,
        Taxes: cartProductAttribute.Taxes,
      }];
    }

    const cartProduct = {
      AddToQueue: true,
      BasePrice: product.BasePrice,
      Comments: '',
      CurrentPrice: product.CurrentPrice ? product.CurrentPrice : cartProductAttribute?(cartProductAttribute?.PriceWithTaxes):product.PriceWithTaxes,
      CurrentPriceWithoutTaxes: product.CurrentPriceWithoutTaxes ? product.CurrentPriceWithoutTaxes : cartProductAttribute?(cartProductAttribute?.AddedPrice):product.BasePrice,
      CurrentTotalPrice: product.CurrentTotalPrice ? product.CurrentTotalPrice : cartProductAttribute?(cartProductAttribute?.PriceWithTaxes):product.PriceWithTaxes,
      CurrentTotalPriceWithoutTaxes: null,
      Discount: product.PercentDiscountRate ? product.PercentDiscountRate : 0,
      FavoriteName: '',
      IdProduct: product.IDProduct ? product.IDProduct : product.IdProduct,
      Images: product.Images ? product.Images : product.ProductImages,
      InEdition: product.InEdition ? product.InEdition : false,
      IsAdditional: false,
      IsAvailable: product.IsAvailable,
      IsCouponPromotion: product.IsCouponPromotion,
      IsCurrentlyTimeAvailable: product.IsCurrentlyTimeAvailable,
      IsEqualShopType: product.IsEqualShopType ? product.IsEqualShopType : false,
      IsPriceDifferent: false,
      IsShopAvailable: product.IsShopAvailable,
      IsWithDefaultAttributes: false,
      LargeDescription: product.LargeDescription,
      LocalCartProductId: product.LocalCartProductId ? product.LocalCartProductId : 0,
      MaxToPurchase: product.MaxToPurchase  ? product.MaxToPurchase : 0,
      MinToPurchase: product.MinToPurchase ? product.MinToPurchase : 0,
      Name: product.Name ? product.Name : product.name,
      PriceDifference: null,
      PriceWithDiscount: cartProductAttribute?cartProductAttribute?.PriceWithDiscount: product.PriceWithDiscount,
      PriceWithTaxes: product.PriceWithTaxes,
      Quantity: selectedQuantity,
      SelectedAttributes: attribute,
      Sku: product.SKU ? product.SKU : product.Sku,
      Taxes: product.Taxes,
      URL: product.URL,
      ProductImages: product.ProductImages,
      ProductSet: null,
    };

    this.loader.showSpinner();

    if (this.utilitiesService.isUserLoggedIn()) {
      const user: any = this.utilitiesService.getValueStorage('sessionStorage');
      let cartId = await this.utilitiesService.decryptData(user.c);
      let userId = await this.utilitiesService.decryptData(user.i);
      const object = {
        userId: userId,
        email: '',
        sku: product.SKU
      };

      this.insertDatalakeCartProductEcommerce(object);

      const cartRequest = {
        Address: null,
        FavoriteOrderName: null,
        IdCart: cartId ? cartId : null,
        IdClient: userId ? parseInt(userId) : null,
        IdFavoriteOrder: 0,
        IdFavoriteProduct: 0,
        PaymentExtraInfo: null,
        PaymentMethod: null,
        Product: cartProduct,
        IsCardToSave: null,
        UseSavedCard: null,
        UseFAC: null,
        IDSavedCard: null,
        FACRedirect3DSecureResponse: null,
        GenerateElectronicInvoice: null,
        IsAMEX: null,
      };

      this.productService.AddProductsToCartMethod(cartRequest).subscribe(res => {
          if (res.response.code === 'AddProduct-Success') {
            if (isCuponApplie) {
              this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(cartId, cupon, '1', '0').subscribe(c => {
              });
            }
            this.updateCart();
            this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        }
        else {
          this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        }

      });

    }
    else {
      let productsInSession: any = this.utilitiesService.getValueStorage('productStorage') || [];
      const productIndex = productsInSession.findIndex((pc: { IdProduct: any; }) => pc.IdProduct === cartProduct.IdProduct);

      if (productIndex !== -1) {
        // El producto existe en la sesión, actualiza la cantidad
        productsInSession[productIndex].Quantity = cartProduct.Quantity;
        if (productsInSession[productIndex].SelectedAttributes.length > 0) {
          productsInSession[productIndex].SelectedAttributes[0].Quantity = cartProduct.Quantity;
          productsInSession[productIndex].SelectedAttributes[0].RealPrice = cartProduct.SelectedAttributes[0].RealPrice;
        }
      }
      else {
        productsInSession.push(cartProduct);
      }

      this.utilitiesService.saveValueStorage(productsInSession, 'productStorage');
      this.messageService.getMessagesMethod('AddProduct-Success', '2').subscribe(m => {
      this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
      });
    }

    setTimeout(() => {
      // Ocultar el spinner después del tiempo especificado
      this.loader.hideSpinner();
    }, 2000);
  }

  addWindowsDatalayer(product: any,selectedQuantity: number, selectedUnitPrice: number){
    let listCategories = "";
    if(product){
      if(product!.Categories){
        if(product!.Categories.length>0){
          listCategories = product!.Categories.map((x: { Name: any; })=>x.Name).join(',');
        }
      }
    }

    this.windowDataLayer.dataLayer.push({
      'event' : 'add_to_cart',
      'currency': 'CRC',
      'value': selectedUnitPrice.toFixed(2),
      'items' : [{
        'item_name' : '' + product!.Name,
        'item_category' : listCategories,
        'price': selectedUnitPrice / selectedQuantity,
        'item_id' : product.SKU,
        'quantity': selectedQuantity
        }]
    });
  }

  addProductsToCartV2 (product: any, selectedUnits: string, selectedQuantity: number, selectedUnitPrice: number, cupon?: any, isCuponApplie?: boolean) {
    let arrayNotification: any = [];

    if (selectedUnits !== undefined) {
      switch (selectedUnits) {
        case 'Unidades':
        case 'Unidad':
          selectedUnits = 'Unidad';
          break;
        case 'Cajas':
        case 'Caja':
          selectedUnits = 'Caja';
          break;
        // Agrega más casos según sea necesario
        // ...
        default:
          // Mantén el valor original si no coincide con ninguno de los casos anteriores
          break;
      }
    }
    else {
      selectedUnits = 'Unidad'
    } 
    
    const productAttributes = product.ProductAttributes ? product.ProductAttributes : product.SelectedAttributes;
    const cartProductAttributeIndex = selectedUnits === 'Caja' && productAttributes.length > 1 ? 1 : 0;
    const cartProductAttribute = productAttributes[cartProductAttributeIndex];

    let categorieName = '';
    if (product.Categories?.length > 0)
      categorieName = product.Categories[cartProductAttributeIndex]?.Name
    else if (product.categories?.length > 0)
      categorieName = product.categories[cartProductAttributeIndex]?.Name

    let attribute: any = [];

    if (cartProductAttribute) {
      attribute = [{
        AddedPrice: cartProductAttribute.AddedPrice,
        CategoryName: cartProductAttribute.CategoryName ? cartProductAttribute.CategoryName : categorieName,
        IdParentProductAttribute: cartProductAttribute.ParentAttribute ? cartProductAttribute.ParentAttribute : cartProductAttribute.IdParentProductAttribute,
        IdProductAttribute: cartProductAttribute.IDProductAttribute ? cartProductAttribute.IDProductAttribute : cartProductAttribute.IdProductAttribute,
        IsAvailable: cartProductAttribute.IsAvailable,
        IsPriceDifferent: cartProductAttribute.IsPriceDifferent ? cartProductAttribute.IsPriceDifferent : false,
        IsShopAvailable: cartProductAttribute.IsShopAvailable,
        Name: selectedUnits,
        PriceDiff: null,
        PriceDifference: null,
        PriceWithTaxes: cartProductAttribute.PriceWithTaxes,
        PromotionPrice: cartProductAttribute.PriceWithDiscount ? cartProductAttribute.PriceWithDiscount : 0,
        Quantity: selectedQuantity,
        RealPrice: selectedUnitPrice,
        Sku3: cartProductAttribute.SKU ? cartProductAttribute.SKU : cartProductAttribute.Sku3,
        Taxes: cartProductAttribute.Taxes,
      }];
    }

    const cartProduct = {
      AddToQueue: true,
      BasePrice: product.BasePrice,
      Comments: '',
      CurrentPrice: product.CurrentPrice ? product.CurrentPrice : cartProductAttribute?(cartProductAttribute?.PriceWithTaxes):product.PriceWithTaxes,
      CurrentPriceWithoutTaxes: product.CurrentPriceWithoutTaxes ? product.CurrentPriceWithoutTaxes : cartProductAttribute?(cartProductAttribute?.AddedPrice):product.BasePrice,
      CurrentTotalPrice: product.CurrentTotalPrice ? product.CurrentTotalPrice : cartProductAttribute?(cartProductAttribute?.PriceWithTaxes):product.PriceWithTaxes,
      CurrentTotalPriceWithoutTaxes: null,
      Discount: product.PercentDiscountRate ? product.PercentDiscountRate : 0,
      FavoriteName: '',
      IdProduct: product.IDProduct ? product.IDProduct : product.IdProduct,
      Images: product.Images ? product.Images : product.ProductImages,
      InEdition: product.InEdition ? product.InEdition : false,
      IsAdditional: false,
      IsAvailable: product.IsAvailable,
      IsCouponPromotion: product.IsCouponPromotion,
      IsCurrentlyTimeAvailable: product.IsCurrentlyTimeAvailable,
      IsEqualShopType: product.IsEqualShopType ? product.IsEqualShopType : false,
      IsPriceDifferent: false,
      IsShopAvailable: product.IsShopAvailable,
      IsWithDefaultAttributes: false,
      LargeDescription: product.LargeDescription,
      LocalCartProductId: product.LocalCartProductId ? product.LocalCartProductId : 0,
      MaxToPurchase: product.MaxToPurchase  ? product.MaxToPurchase : 0,
      MinToPurchase: product.MinToPurchase ? product.MinToPurchase : 0,
      Name: product.Name ? product.Name : product.name,
      PriceDifference: null,
      PriceWithDiscount: cartProductAttribute?cartProductAttribute?.PriceWithDiscount: product.PriceWithDiscount,
      PriceWithTaxes: product.PriceWithTaxes,
      Quantity: selectedQuantity,
      SelectedAttributes: attribute,
      Sku: product.SKU ? product.SKU : product.Sku,
      Taxes: product.Taxes,
      URL: product.URL,
      ProductImages: product.ProductImages,
      ProductSet: null,
    };

    this.loader.showSpinner();

    if (this.utilitiesService.isUserLoggedIn()) {
      const user: any = this.utilitiesService.getValueStorage('sessionStorage');
      let cartId =  this.utilitiesService.decryptData(user.c);
      let userId =  this.utilitiesService.decryptData(user.i);
      let email =  this.utilitiesService.decryptData(user.e);

      const object = {
        userId: userId,
        email: email,
        sku: product.SKU
      };

      this.insertDatalakeCartProductEcommerce(object);

      const cartRequest = {
        Address: null,
        FavoriteOrderName: null,
        IdCart: cartId ? cartId : null,
        IdClient: userId ? parseInt(userId) : null,
        IdFavoriteOrder: 0,
        IdFavoriteProduct: 0,
        PaymentExtraInfo: null,
        PaymentMethod: null,
        Product: cartProduct,
        IsCardToSave: null,
        UseSavedCard: null,
        UseFAC: null,
        IDSavedCard: null,
        FACRedirect3DSecureResponse: null,
        GenerateElectronicInvoice: null,
        IsAMEX: null,
      };

      this.productService.AddProductsToCartMethodV2(cartRequest).subscribe(res => {
          if (res === 'AddProduct-Success') {
            this.addWindowsDatalayer(product,selectedQuantity,selectedUnitPrice);
            if (isCuponApplie) {
              this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(cartId, cupon, '1', '0').subscribe(c => {
                this.productService.getMessages(res,'2').subscribe(o=>{
                  let elementArrayNotication: any = {};

                  if (c.response.code !== "CAPP") {
                    //this.notificationService.SendNotification(c.response.type, c.response.titleMessage, c.response.message);
                    elementArrayNotication = {
                      typenotification : c.response.type,
                      titleMessage : c.response.titleMessage,
                      message : c.response.message
                    };
                    arrayNotification.push(elementArrayNotication);
                  }

                  //this.notificationService.SendNotification(o.type, o.titleMessage, o.message);
                  elementArrayNotication = {
                    typenotification : o.type,
                    titleMessage : o.titleMessage,
                    message : o.message
                  };

                  arrayNotification.push(elementArrayNotication);
                  this.notificationArrayService.SendNotification(arrayNotification);
                  this.updateCart();
                });
              });
            }
            else{
              this.productService.getMessages(res,'2').subscribe(o=>{
                this.notificationService.SendNotification(o.type, o.titleMessage, o.message);
                this.updateCart();
              });
            }
          }
          else {
            this.productService.getMessages(res,'2').subscribe(o=>{

              this.notificationService.SendNotification(o.type, o.titleMessage, o.message);
              this.updateCart();

            })
          }

      });

    }
    else {
      let productsInSession: any = this.utilitiesService.getValueStorage('productStorage') || [];
      let productIndex;
      if (cartProduct.SelectedAttributes.length > 0) {
        productIndex = productsInSession.findIndex((pc: { IdProduct: any; SelectedAttributes: any; }) => (pc.IdProduct === cartProduct.IdProduct) &&  pc.SelectedAttributes[0].IdProductAttribute === cartProduct.SelectedAttributes[0].IdProductAttribute);
      }
      else {
        productIndex = productsInSession.findIndex((pc: { IdProduct: any; SelectedAttributes: any; }) =>  pc.IdProduct === cartProduct.IdProduct);  
      }

      if (productIndex !== -1) {
        // El producto existe en la sesión, actualiza la cantidad
        productsInSession[productIndex].Quantity += cartProduct.Quantity
        if (productsInSession[productIndex].SelectedAttributes.length > 0) {
          productsInSession[productIndex].SelectedAttributes[0].Quantity += cartProduct.Quantity;
          productsInSession[productIndex].SelectedAttributes[0].RealPrice += cartProduct.SelectedAttributes[0].RealPrice;
        }
      }
      else {
        productsInSession.push(cartProduct);
      }

      this.addWindowsDatalayer(product,selectedQuantity,selectedUnitPrice);
      
      this.utilitiesService.saveValueStorage(productsInSession, 'productStorage');
      this.messageService.getMessagesMethod('AddProduct-Success', '2').subscribe(m => {
        this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
        this.updateCart();
      });
    }
  }

  insertDatalakeCartProductEcommerce(object: any)  {
    const request: any =
    {
      UserID: object.userId,
      Email: object.email,
      Source: "WEB",
      Status: "AC",
      CreateUserId: "1",
      CreateProgId: "LOGIN",
      SKU: object.sku,
      IDShop:"96"
    }

    this.datalakeService.InsertDatalakeCartProductEcommerceMethod(request).subscribe(dl =>{});
  }

  updateCart() {
    this.cartUpdatedSubject.next();
  }

  updateHeader() {
    this.headerUpdatedSubject.next();
  }

  updateFooter() {
    this.footerUpdatedSubject.next();
  }
}
