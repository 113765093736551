import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IModal } from 'src/app/models/modal.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { CheckoutService } from 'src/app/services/core/checkout.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { MessagesService } from 'src/app/services/sitefinity/messages.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { CleanService } from 'src/app/services/core/clean.service';
import { WindowReferenceService } from 'src/app/services/shared/windows-reference.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { UtilitiesService } from 'src/app/services/core/utilities.service';


const settings = new SitefinitySettings();
declare let window: any;
@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.css']
})
export class CheckoutPaymentComponent implements OnInit{
  private windowDataLayer: any;
  settings: SitefinitySettings = new SitefinitySettings;
  validationMsg = this.settings.validationMsgTypeMap;
  totalPrice: number = 0;
  subTotalPrice: number = 0;
  discount: number = 0;
  taxes: number = 0;
  creditCard: any = [];
  userId!: string;
  cartId!: string;
  modalCard: any;
  showCard: boolean = false;
  form!: FormGroup;
  creditForm!: FormGroup;
  isSubmited: boolean = false;
  impulseProduct: any;
  showModalImpulse: boolean = false;
  modalImpulse: IModal = {
    ModalName: 'modalRecomended'
  };
  selectedCard: any;
  isAMEX: boolean = false;
  showModalAmex: boolean = false;
  private cartUpdateSubscription!: Subscription;
  validateCupon: boolean = false;
  notCreditCard: boolean = false;
  modalCupon: IModal = {
    ModalName: 'modalCupon',
    ShowCancelBtn: false
  };
  coupons: {
    Code: string,
    DisplayDiscount: boolean
    LocalCouponId: number
  }[] =  [];
  showModalCupon: boolean = false;
  formCupon!: FormGroup;
  BeneficitCouponsModal: IModal = {
    ModalName: 'BeneficitCouponsModal'
  }
  showBeneficitCouponsModal: boolean = false;
  ValidateBeneficitCouponsModal: IModal = {
    ModalName: 'ValidateBeneficitCouponsModal',
    ShowCancelBtn: false
  }

ValidateShowBeneficitCouponsModal: boolean = false;
isCouponSubmited: boolean = false;
totalPriceCart: number = 0;
subTotalPriceCart: number = 0;
 UserAddCoupons: boolean = false;

 switchcaseOption: number = 0;
 responseInventory: any;

 showMessageCouponsModal: boolean = false;
 MessageCouponsModal: IModal = {
   ModalName: 'MessageCouponsModal',
   ShowCancelBtn: false,

 }
CouponTitle:any = 'Información de cupones'
disableParameterCupon: number = 0;
minPurchase!: string;


  constructor(
    private cartService: CartService,
    private utilitiesService: UtilitiesServicesService,
    private loader: LoadingService,
    private checkoutService: CheckoutService,
    private router: Router,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private notificationService: NotificationService,
    private sitefinityPagesService : SitefinitypagesService,
    private messageService: MessagesService,
    private CleanService:CleanService,
    private _windowRef: WindowReferenceService,
    private http: HttpClient,
    private _utilitiesService: UtilitiesService
  ){


    this.formCupon = this.fb.group({
      cupon: ['', [Validators.required,Validators.minLength(3)]]
    });


    this.form = this.fb.group({
      cupon: ['', [Validators.required]]
    });
    this.creditForm = this.fb.group({
      ccv: ['', [Validators.required]],
      lifeMiles: ['']
    });
    this.windowDataLayer = _windowRef.nativeWindow;
  }

  get cupon() { return this.formCupon.get('cupon')}
  get name() { return this.creditForm.get('name')}
  get ccv() { return this.creditForm.get('ccv')}
  get lifeMiles() { return this.creditForm.get('lifeMiles')}

  ngOnInit(): void {
    setTimeout(() => {

      this.loader.hideSpinner();
    }, 3500);
    if (this.utilitiesService.isUserLoggedIn()) {
      setTimeout(() => {

        this.loader.hideSpinner();
      }, 2500);


      this.UserAddCoupons = false;
      const user: any = this.utilitiesService.getValueStorage('sessionStorage');
      this.userId = this.utilitiesService.decryptData(user.i);
      this.cartId = this.utilitiesService.decryptData(user.c);

      this.cartUpdateSubscription = this.sharedService.cartUpdated$.subscribe(() => {
        this.getCartSummary();
      });

      this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'proceso-compra')!.ID);

      this.getImpulseProduct();
      this.getCreditCards();
      this.getCartSummary();

      this.modalCard = new window.bootstrap.Modal(
        document.getElementById('modalCard')
      );
    }
    else {
      this.router.navigate(['/iniciosesion']);
    }
  }

  ngOnDestroy() {
    this.cartUpdateSubscription.unsubscribe(); // Asegúrate de desuscribirte en onDestroy
  }

  closeModal(event: any, disableParameterCupon:number = 0) {
    if (event == true) {
    if(disableParameterCupon == 1)
    {
      this.disableParameterCupon = 0;
      this.showModalCupon = false;
      this.isSubmited = false;
      this.form = this.fb.group({
        cupon: ['', [Validators.required]]
      });
     return;
    }


      this.showModalCupon = false;
      this.isSubmited = false;
      this.form = this.fb.group({
        cupon: ['', [Validators.required]]
      });
      this.form.updateValueAndValidity();

      this.getCartSummary();

      if(this.modalCupon.UrlBtnConfirm && this.modalCupon.UrlBtnConfirm.trim() != ''){
        window.open(this.modalCupon.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalCupon = false;
      this.disableParameterCupon = 0;
    }
  }


  getCartSummary(): void {
    this.loader.showSpinner();
    this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.totalPrice = res.responseObject.PurchaseInfo.TotalWithDiscount;
        this.subTotalPrice = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
        this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
        this.discount = res.responseObject.PurchaseInfo.Discount;
        this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;
      }
      this.loader.hideSpinner();
    });
  }

  getCreditCards() {
    this.cartService.GetClientActiveSavedCardsMethod(this.userId).subscribe((res) => {
      if (res.response.code === 'OK0001') {
        this.creditCard = [];
        this.creditCard = res.responseObject;
        this.notCreditCard = this.creditCard.length > 0 ? false : true;
        const firstCard  = this.creditCard[0];
        this.selectedCard = firstCard;
      }
      else {
        this.notCreditCard = true;
        this.creditCard = [];
      }
    });
  }

  isSelected(card: any): boolean {
    return card === this.selectedCard;
  }

  redirectToShipping(): void {
    this.router.navigate(['/orden'], { skipLocationChange: true });
  }

  showModalCard() {

    this.CleanService.resetForm();
    this.showCard = true;
    this.modalCard.show();
  }

  closeModalCard(event: boolean) {
    this.showCard = !event;
    this.modalCard.hide();
    this.getCreditCards();
  }

  addCoupon2(): void {
    if (this.form.invalid)
      this.validateCupon = true;
    else {
      this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.form.value.cupon, "1", "0").subscribe(res => {
        if (res.response.code === "CAPP") {
          this.messageService.getMessagesMethod('CAPP-1', '1').subscribe(m => {
            this.modalCupon.Title = m.titleMessage;
            this.modalCupon.Text = m.message;
            this.modalCupon.BtnConfirmText = m.buttonAcceptText;
            this.showModalCupon = true;
            this.getCartSummary();
            this.sharedService.updateCart();
          });
        }
        else {
          this.modalCupon.Title = 'El cupón no aplica';
          this.modalCupon.Text = res.response.message;
          this.showModalCupon = true;
        }
      });
    }
  }


  deleteCoupon2(coupon: any): void {
    this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, coupon.Code, "2", "0").subscribe(res => {
      if (res.response.code === "CDEL") {
        this.modalCupon.Title = res.response.titleMessage;
        this.modalCupon.Text = res.response.message;
        this.showModalCupon = true;
        this.getCartSummary();
        this.sharedService.updateCart();
      }
    });
  }

  getImpulseProduct() {
    this.checkoutService.GetImpulseProductsMethod(this.cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.impulseProduct = res.object;
        if (this.impulseProduct.length > 0) {
          this.showModalImpulse = true;
        }
      }
      else {
        this.impulseProduct = [];
      }
    });
  }

  closeModalImpulse(event: any) {
    if (event == true) {
      this.showModalImpulse = false;
      this.getCartSummary();
      this.router.navigate(['/proceso-compra']);
      if(this.modalImpulse.UrlBtnConfirm && this.modalImpulse.UrlBtnConfirm.trim() != ''){
        window.open(this.modalImpulse.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalImpulse = false;
      this.getCartSummary();
    }
  }

  processPayment() {
    if (this.creditForm.invalid || !this.selectedCard) {
      this.isSubmited = true;
    }
    else {
      if (this.selectedCard.CardNumber.startsWith("3")) {
        this.isAmex();
      }
      else {
        const object: any = {
          Address: null,
          FACRedirect3DSecureResponse: null,
          FavoriteOrderName: null,
          GenerateElectronicInvoice: null,
          IDSavedCard: null,
          IdCart: this.cartId,
          IdClient: parseInt(this.userId),
          IdFavoriteOrder: 0,
          IdFavoriteProduct: 0,
          IsAMEX: this.isAMEX,
          IsCardToSave: null,
          PaymentExtraInfo: null,
          PaymentMethod: {
            Authorization: "",
            CreditCardMethod: 3,
            IdPaymentMethod: 2,
            ImageURL: null,
            ItIsEmergencyPay: false,
            Name: "Tarjeta",
            PayWith: this.totalPrice,
            Sort: 0
          },
          Product: null,
          PurchaseOnBehalf: "",
          Quantity: null,
          UseFAC: null,
          UseSavedCard: null
        };
        const expirationDate = '0000';
        const name = this.selectedCard.Name;

        let lifeMiles = 'N/A';
        if (this.creditForm.value.lifeMiles != '') {
          lifeMiles = this.creditForm.value.lifeMiles;
        }

        let enviroment = 'dev';
        if (window.location.hostname === 'localhost')
          enviroment = 'local';

        const toEncrtp = this.selectedCard.CardNumber + '|' + this.selectedCard.Name + '|' + expirationDate + '|' + this.creditForm.value.ccv;
        let paymentExtraInfo = this.utilitiesService.encryptDataMD5(toEncrtp, true, 'qhUQsZLvWzxg2T9V');
        this.loader.showSpinner();
        this.cartService.GetCartSummaryMethod(this.cartId).subscribe(cs => {


          if (cs.response.code === 'OK0001') {
            this.addPayment(object, paymentExtraInfo, lifeMiles, enviroment, cs.responseObject);
          }
          else {


            if(cs.response.code == "CARTEPA0001")
              {

                this._utilitiesService.GetConfigValueMethod("ExpressMinPurchaseAmount").subscribe(res => {
                  if (res) {
                    this.minPurchase = res;
                    this.messageService.getMessagesMethod('CARTEPA0001', '1').subscribe(message => {
                      this.loader.hideSpinner();
                      this.validateMinPurchase(this.minPurchase, message);
                    });
                  }
                });


               // this.notificationService.SendNotification(obj.response.type, obj.response.titleMessage, obj.response.message);

              }
              else
              {
                 this.loader.hideSpinner();
            this.notificationService.SendNotification(cs.response.type, cs.response.titleMessage, cs.response.message);
          }



          }
          setTimeout(() => {
            this.loader.hideSpinner();
          }, 2000);
        });
      }
    }
  }

  isAmex() {
    this.isAMEX = true;
    this.showModalAmex = true;
    setTimeout(() => {
      this.loader.hideSpinner();
    }, 1000);
  }

  addPayment(object: any, paymentExtraInfo: any, lifeMiles: any, enviroment: any, cart: any) {
    this.cartService.AddPaymentMethodToCartMethod(object).subscribe(obj => {
      if (obj.response.code === "OK0001") {
        if (this.isAMEX) {
          this.showModalAmex = true;
        }
        else {
          this.preOrder(paymentExtraInfo, lifeMiles, enviroment, cart);
        }
      }
      else {
        this.loader.hideSpinner();
        if(obj.response.code == "CARTEPA0001")
        {

          this._utilitiesService.GetConfigValueMethod("ExpressMinPurchaseAmount").subscribe(res => {
            if (res) {
              this.minPurchase = res;
              this.messageService.getMessagesMethod('CARTEPA0001', '1').subscribe(message => {
                this.validateMinPurchase(this.minPurchase, message);
              });
            }
          });


         // this.notificationService.SendNotification(obj.response.type, obj.response.titleMessage, obj.response.message);

        }
        else
        {
        this.notificationService.SendNotification(obj.response.type, obj.response.titleMessage, obj.response.message);
      }
      }
    });
  }

  saveDatalayerOrderConfirm(itemDatalayer: any, cart: any,idOrder:any){
    let infoDatalayer ={
      'event' : 'purchase',
      'transaction_id':idOrder,
      'tax': cart.PurchaseInfo.TaxesAmount,
      'shipping': 0,
      'currency': 'CRC',
      'value': cart.PurchaseInfo.SubTotalWithoutTaxes,
      'items' : itemDatalayer
    }

    let infoencrypt = this.utilitiesService.encryptDataMD5(JSON.stringify(infoDatalayer), true, 'qhUQsZLvWzxg2T9V');
    this.utilitiesService.saveValueStorage(infoencrypt, "DatalayerCompra");
  }

  preOrder(paymentExtraInfo: any, lifeMiles: any, enviroment: any, cart: any) {
    let orderId = null;
    this.cartService.PreorderPowerTranzMethod(this.selectedCard.IDSavedCard, this.cartId, parseInt(this.userId), this.isAMEX, false, paymentExtraInfo, true, true).subscribe(res => {
      if (res.response.code === "OK0001") {
        let itemDatalayer: any = [];
        cart.ProductQueue.forEach((currentProduct: any) => {
          let itemProductDatalayer = {
            'item_name' : '' + currentProduct.Name,
            'item_category' : '',
            'price': currentProduct.PriceWithDiscount > 0?currentProduct.PriceWithDiscount:currentProduct.CurrentTotalPrice,
            'item_id' : currentProduct.Sku,
            'quantity': currentProduct.Quantity
          }
          itemDatalayer.push(itemProductDatalayer);
        });

        this.windowDataLayer.dataLayer.push({
          'event' : 'begin_checkout',
          'currency': 'CRC',
          'value': cart.PurchaseInfo.TotalWithDiscount>0?cart.PurchaseInfo.TotalWithDiscount:cart.PurchaseInfo.total,
          'items' : itemDatalayer
        });

        this.saveDatalayerOrderConfirm(itemDatalayer,cart,res.responseObject.Submit);

        orderId = res.responseObject.Submit;
        this.utilitiesService.saveValueStorage(orderId, "order");
        const cartId = this.cartId;
        const clientId = this.userId;
        const status = true;
        this.router.navigate(['/procesando-compra'], {state: { orderId, cartId, clientId, name, lifeMiles, enviroment, status}});
        this.loader.hideSpinner();
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        this.loader.hideSpinner();
      }
    });
  }

  goToDetailProduct(url: string): void {
    if( url != null) {
      this.router.navigate(['/detalle-producto', url]);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  deleteCreditCard(key: string) {
    if (key != null) {
      this.loader.showSpinner();
      this.cartService.InactivateSavedCardMethod(key).subscribe((res) => {
        if (res.response.code === 'OK0001') {
          this.getCreditCards();
          this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        }
        this.loader.hideSpinner();
      });
    }
  }




  callVerifyIfClienteHasBenefits()
  {

  var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
  var y  = this.utilitiesService.decryptData(x.u);

  const url = settings.BusDeServiciosUrlDev + 'Cupones.svc/rest/ConsultaBeneficiosDescripcionCliente';

  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  const body = {
    Identificacion: y,
    Campana: '',
    Canal:"WEB"
  };

  const jsonData = JSON.stringify(body);

  var result = this.http.post(url, jsonData, { headers })
  result.subscribe((o:any) =>{


  if(o.Ok == true)
  {
    const jsonObject = JSON.parse(o.Value);

    const benefitCards = this.generateBenefitCardsHTML(jsonObject);


    const concatenatedStrings: string[] = jsonObject.map((benefit:any) => `Campaña: ${benefit.NombreCampana}, Cupón: ${benefit.Campana}`);
    const finalString = concatenatedStrings.join(', ');


    this.BeneficitCouponsModal.Title = this.CouponTitle;
    this.BeneficitCouponsModal.Text = benefitCards;
    this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";
    this.BeneficitCouponsModal.ShowCancelBtn = false;
    this.showBeneficitCouponsModal = true;

  }

  });

  }

  closeModalCoupons(event: any) {
    if (event == true) {
      this.showBeneficitCouponsModal = false;

      this.loader.hideSpinner();
    } else {
      this.showBeneficitCouponsModal = false;
    }
  }




  validateShipping(): void {
    this.loader.showSpinner();



 }




 deleteCoupon(coupon: any): void {

  this.loader.showSpinner();
  this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, coupon.Code, "2", "0").subscribe(res => {
    if (res.response.code === "CDEL") {

      this.coupons = this.coupons.filter(c => c.Code !== coupon.Code);
      const index = this.coupons.findIndex(c => c.Code === coupon.Code);
      if (index !== -1) {
        this.coupons.splice(index, 1);
      }
      var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
      var c  = this.utilitiesService.decryptData(x.c);

      var url =  settings.CoreServicesUrl + '/BombaCart.svc/ApplyCouponsFromDokka/'+ c
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      var result = this.http.get(url,{ headers })
    //  this.loader.hideSpinner();
   //   this.updateCartSummary();

      result.subscribe((o:any) =>{


    if(o.IsValid)
    {

      this.updateCartSummary();
    }
    else
    {
  //    this.loader.hideSpinner();
      this.UserAddCoupons = true;
        this.coupons.push({
          Code: this.formCupon.value.cupon,
          DisplayDiscount: false, // Valor por defecto
          LocalCouponId: 0        // Valor por defecto
        });
        this.formCupon.value.cupon = "";
        this.formCupon.get('cupon')?.setValue('');
        this.formCupon = this.fb.group({
          cupon: ['', [Validators.required]]
        });
        this.loader.hideSpinner();
        this.isCouponSubmited = false;

        this.updateCartSummary();


    }



      })


    }
    else {
      this.loader.hideSpinner();
      this.modalCupon.Title = res.response.titleMessage;
      this.modalCupon.Text = res.response.message;
      this.showModalCupon = true;
    }
  });
}






generateBenefitCardsHTML(coupons: any[]): string {
  // Inicializar la variable que contendrá el HTML de la tabla con estilos en línea
  let htmlString = `
      <table style="width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 18px; text-align: left;">
          <thead>
              <tr>
                  <th style="padding: 12px 20px; border: 1px solid #ddd; background-color: #00828E; color: white; text-align: center;">Nombre de la Campaña</th>
                  <th style="padding: 12px 20px; border: 1px solid #ddd; background-color: #00828E; color: white; text-align: center;">Cupón</th>
              </tr>
          </thead>
          <tbody>
  `;

  // Iterar sobre el arreglo de cupones y crear una fila de la tabla para cada uno
  coupons.forEach(coupon => {
      htmlString += `
          <tr>
              <td style="padding: 12px 20px; border: 1px solid #ddd;">${this.escapeHtml(coupon.NombreCampana)}</td>
              <td style="padding: 12px 20px; border: 1px solid #ddd;">${this.escapeHtml(coupon.Campana)}</td>
          </tr>
      `;
  });

  // Cerrar las etiquetas de tbody y table
  htmlString += `
          </tbody>
      </table>
  `;

  return htmlString;
}


escapeHtml(unsafe: string): string {
  return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
}
//funcional
addCoupon(): void {

  if (this.formCupon.invalid)
    this.isCouponSubmited = true;
  else {
    this.loader.showSpinner();
    const exists = this.coupons.some(coupon => coupon.Code === this.formCupon.value.cupon);
    if(exists)
    {

      let ticket = this.formCupon.value.cupon;
      this.formCupon.value.cupon = "";
      this.formCupon.get('cupon')?.setValue('');
      this.formCupon = this.fb.group({
        cupon: ['', [Validators.required]]
      });
      this.loader.hideSpinner();
      this.isCouponSubmited = false;


    var  message = `
      <p>El cupón: <strong> ${ticket} </strong>  ya se encuentra aplicado.</p>
  `


      this.modalCupon.Title =  this.CouponTitle;
      this.modalCupon.Text = message;
      this.modalCupon.BtnConfirmText = "Aceptar";
      this.modalCupon.ShowAcceptBtn = true;
      this.disableParameterCupon = 1;
      this.showModalCupon = true



      return;

    }





    var cupon = this.formCupon.value.cupon;
    var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
    var y  = this.utilitiesService.decryptData(x.u);


    const url = settings.BusDeServiciosUrlDev + 'Cupones.svc/rest/ValidacionActivacionCupones';

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const body = {
      CodigoBeneficio:this.formCupon.value.cupon,
      Identificacion: y,
      IdentificacionTipo: '',
      Canal:"WEB"
    };

    const jsonData = JSON.stringify(body);

    var result = this.http.post(url, jsonData, { headers })
    result.subscribe((o:any) =>{

      if(o.Ok == true)
      {
        this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.formCupon.value.cupon, "1", "0").subscribe(res => {


          if (res.response.code === "CAPP") {
            this.UserAddCoupons = true;
            var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
            var c  = this.utilitiesService.decryptData(x.c);

            var url =  settings.CoreServicesUrl + '/BombaCart.svc/ApplyCouponsFromDokka/'+ c
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            var result = this.http.get(url,{ headers })


            result.subscribe((o:any)=>{
              this.formCupon.reset();


              if(o.IsValid)
              {
                this.loader.hideSpinner();

                const parametersResponse: string = o.Parameters as string;
                const responseObject: any = JSON.parse(parametersResponse);
                var text = '';
                this.coupons.push({
                  Code: cupon,
                  DisplayDiscount: false, // Valor por defecto
                  LocalCouponId: 0        // Valor por defecto
                });


             if(this.UserAddCoupons)
                {



                  if(responseObject.length == 0)
                    {

                      text = 'El cupón ha sido aplicado correctamente.'
                      if(this.coupons.length > 1)
                      {
                        text = 'Los cupones han sido aplicados correctamente.'
                      }


                    }
                  else
                  {


                      // Crear un conjunto de códigos de cupones que no deben ser aplicados
                      const nonApplicableCoupons = new Set(responseObject.map((item:any) => item.Code.toUpperCase()));

                      const couponDetailsMap: Map<string, any> = new Map(
                        responseObject.map((item:any) => [item.Code.toUpperCase(), { ListType: item.ListType, Message: item.Message }])
                    );

                    // Filtrar cupones en el carrito que están en la lista de no aplicables y obtener su ListType y mensaje
                    const couponsToRemove = this.coupons
                        .filter((coupon:any) => nonApplicableCoupons.has(coupon.Code.toUpperCase()))
                        .map((coupon:any) => {
                            const details = couponDetailsMap.get(coupon.Code.toUpperCase());
                            return {
                                Code: coupon.Code,
                                ListType: details?.ListType ?? 'Desconocido', // Usar 'Desconocido' si ListType es null o undefined
                                Message: details?.Message ?? 'No disponible' // Usar 'No disponible' si Message es null o undefined
                            };
                        });



                      if(couponsToRemove.length == 1)
                      {

                      const couponCodes = couponsToRemove.map((coupon:any) => coupon.Code).join(', ');

                      const hasType1 = couponsToRemove.some(coupon => coupon.ListType === 1);
                      const hasType0 = couponsToRemove.some(coupon => coupon.ListType === 0);


                      let message: string;

                      if (hasType1 && hasType0) {

                        const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                        const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);

                        // Generar los textos separados para cada tipo
                        const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                        const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');


                        //message = `Los siguientes cupones: ${couponCodesType0}. No pueden ser aplicados, porque existe uno o varios cupones agregados con mejores beneficios. Razones: ${reasonsType1}.`;
                        message = `
                        <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>, porque existe uno o varios cupones agregados con mejores beneficios.</p>
                        <p><strong>Los cupones:</strong> ${reasonsType1}.</p>
                    `;



                        text = message;
                      } else if (hasType1) {

                          const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);
                          const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');

                          message = `
                          <p><strong>Los siguientes cupones:</strong> ${reasonsType1}.</p>
                      `;



                          text = message;


                      } else if (hasType0) {
                           const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                           const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                           message = `
                           <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>, porque existe uno o varios cupones agregados con mejores beneficios.</p>

                       `;
                       text = message;
                      } else {
                          //message = "No se encontraron cupones con tipos válidos en la lista.";

                          text = 'El cupón ha sido aplicado correctamente.'
                          if(this.coupons.length > 1)
                          {
                            text = 'Los cupones han sido aplicados correctamente.'
                          }

                          //text = message;
                      }




                    }
                    else
                    {


                      const hasType1 = couponsToRemove.some(coupon => coupon.ListType === 1);
                      const hasType0 = couponsToRemove.some(coupon => coupon.ListType === 0);


                      let message: string;

                      if (hasType1 && hasType0) {

                        const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                        const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);

                        // Generar los textos separados para cada tipo
                        const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                        const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');


                        //message = `Los siguientes cupones: ${couponCodesType0}. No pueden ser aplicados, porque existe uno o varios cupones agregados con mejores beneficios. Razones: ${reasonsType1}.`;
                        message = `
                        <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>, porque existe uno o varios cupones agregados con mejores beneficios.</p>
                        <p><strong>Los cupones:</strong> ${reasonsType1}.</p>
                    `;



                        text = message;
                      } else if (hasType1) {

                          const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);
                          const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');

                          message = `
                          <p><strong>Los siguientes cupones:</strong> ${reasonsType1}.</p>
                      `;



                          text = message;


                      } else if (hasType0) {
                           const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                           const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                           message = `
                           <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>, porque existe uno o varios cupones agregados con mejores beneficios.</p>

                       `;
                       text = message;
                      } else {
                          // message = "No se encontraron cupones con tipos válidos en la lista.";
                          // text = message;
                          text = 'El cupón ha sido aplicado correctamente.'
                          if(this.coupons.length > 1)
                          {
                            text = 'Los cupones han sido aplicados correctamente.'
                          }


                      }

               // Construir la lista de códigos de cupones que no se pueden aplicar



                    }



                  }




                this.ValidateBeneficitCouponsModal.Title = this.CouponTitle;
                this.ValidateBeneficitCouponsModal.Text = text;
                this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";
                // this.BeneficitCouponsModal.BtnCancelText = "";

                this.switchcaseOption  = 1;

                this.UserAddCoupons = true;

                this.formCupon.value.cupon = "";
                this.formCupon.get('cupon')?.setValue('');
                this.formCupon = this.fb.group({
                  cupon: ['', [Validators.required]]
                });
                this.loader.hideSpinner();
                this.isCouponSubmited = false;
                this.ValidateShowBeneficitCouponsModal = true;
                this.getCartSummary();
               // this.sharedService.updateCart();




              }
              else
              {
                this.loader.hideSpinner();
                this.UserAddCoupons = true;
                  this.coupons.push({
                    Code: cupon,
                    DisplayDiscount: false, // Valor por defecto
                    LocalCouponId: 0        // Valor por defecto
                  });
                  this.formCupon.value.cupon = "";
                  this.formCupon.get('cupon')?.setValue('');
                  this.formCupon = this.fb.group({
                    cupon: ['', [Validators.required]]
                  });
                  this.loader.hideSpinner();
                  this.isCouponSubmited = false;
                  this.getCartSummary();
               //   this.updateCartSummary();
              // this.sharedService.updateCart();



              }



              }
              else
              {


                const parametersResponse: string = o.Parameters as string;
                const responseObject: any = JSON.parse(parametersResponse);


                if(responseObject.length == 0)
                  {


                    // this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.formCupon.value.cupon, "2", "0").subscribe(res => {
                    //   console.log(res)
                    //   this.loader.hideSpinner();
                    //   this.updateCartSummary();

                    //   this.ValidateBeneficitCouponsModal.Title = this.CouponTitle;
                    //   this.ValidateBeneficitCouponsModal.Text = 'El cupón o cupones que ingresaste no pudieron ser aplicados.';
                    //   this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";

                    //   this.ValidateShowBeneficitCouponsModal = true;

                    // });


              this.loader.hideSpinner();
              this.ValidateBeneficitCouponsModal.Title = this.CouponTitle;
                this.ValidateBeneficitCouponsModal.Text = 'El cupón o cupones que ingresaste no pudieron ser aplicados.';
                this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";

                this.ValidateShowBeneficitCouponsModal = true;
                return;


                  }
                else
                {

                  var text = '';
                  this.coupons.push({
                    Code: cupon,
                    DisplayDiscount: false, // Valor por defecto
                    LocalCouponId: 0        // Valor por defecto
                  });

                    // Crear un conjunto de códigos de cupones que no deben ser aplicados
                    const nonApplicableCoupons = new Set(responseObject.map((item:any) => item.Code.toUpperCase()));

                    const couponDetailsMap: Map<string, any> = new Map(
                      responseObject.map((item:any) => [item.Code.toUpperCase(), { ListType: item.ListType, Message: item.Message }])
                  );

                  // Filtrar cupones en el carrito que están en la lista de no aplicables y obtener su ListType y mensaje
                  const couponsToRemove = this.coupons
                      .filter((coupon:any) => nonApplicableCoupons.has(coupon.Code.toUpperCase()))
                      .map((coupon:any) => {
                          const details = couponDetailsMap.get(coupon.Code.toUpperCase());
                          return {
                              Code: coupon.Code,
                              ListType: details?.ListType ?? 'Desconocido', // Usar 'Desconocido' si ListType es null o undefined
                              Message: details?.Message ?? 'No disponible' // Usar 'No disponible' si Message es null o undefined
                          };
                      });



                    if(couponsToRemove.length == 1)
                    {

                    const couponCodes = couponsToRemove.map((coupon:any) => coupon.Code).join(', ');

                    const hasType1 = couponsToRemove.some(coupon => coupon.ListType === 1);
                    const hasType0 = couponsToRemove.some(coupon => coupon.ListType === 0);


                    let message: string;

                    if (hasType1 && hasType0) {

                      const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                      const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);

                      // Generar los textos separados para cada tipo
                      const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                      const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');


                      //message = `Los siguientes cupones: ${couponCodesType0}. No pueden ser aplicados, porque existe uno o varios cupones agregados con mejores beneficios. Razones: ${reasonsType1}.`;
                      message = `
                      <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>.</p>
                      <p><strong>Los cupones:</strong> ${reasonsType1}.</p>
                  `;



                      text = message;
                    } else if (hasType1) {

                        const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);
                        const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');

                        message = `
                        <p><strong>Los siguientes cupones:</strong> ${reasonsType1}.</p>
                    `;



                        text = message;


                    } else if (hasType0) {
                         const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                         const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                         message = `
                         <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>.</p>

                     `;
                     text = message;
                    }
                    // else {
                    //     //message = "No se encontraron cupones con tipos válidos en la lista.";

                    //     text = 'El cupón ha sido aplicado correctamente.'
                    //     if(this.coupons.length > 1)
                    //     {
                    //       text = 'Los cupones han sido aplicados correctamente.'
                    //     }

                    //     //text = message;
                    // }




                  }
                  else
                  {


                    const hasType1 = couponsToRemove.some(coupon => coupon.ListType === 1);
                    const hasType0 = couponsToRemove.some(coupon => coupon.ListType === 0);


                    let message: string;

                    if (hasType1 && hasType0) {

                      const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                      const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);

                      // Generar los textos separados para cada tipo
                      const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                      const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');


                      //message = `Los siguientes cupones: ${couponCodesType0}. No pueden ser aplicados, porque existe uno o varios cupones agregados con mejores beneficios. Razones: ${reasonsType1}.`;
                      message = `
                      <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>.</p>
                      <p><strong>Los cupones:</strong> ${reasonsType1}.</p>
                  `;



                      text = message;
                    } else if (hasType1) {

                        const couponsWithType1 = couponsToRemove.filter(coupon => coupon.ListType === 1);
                        const reasonsType1 = couponsWithType1.map(coupon => `${coupon.Message}`).join('; ');

                        message = `
                        <p><strong>Los siguientes cupones:</strong> ${reasonsType1}.</p>
                    `;



                        text = message;


                    } else if (hasType0) {
                         const couponsWithType0 = couponsToRemove.filter(coupon => coupon.ListType === 0);
                         const couponCodesType0 = couponsWithType0.map(coupon => coupon.Code).join(', ');
                         message = `
                         <p><strong>Los siguientes cupones:</strong> ${couponCodesType0} <strong>no pueden ser aplicados</strong>.</p>

                     `;
                     text = message;
                    }
                    //  else {
                    //     // message = "No se encontraron cupones con tipos válidos en la lista.";
                    //     // text = message;
                    //     text = 'El cupón ha sido aplicado correctamente.'
                    //     if(this.coupons.length > 1)
                    //     {
                    //       text = 'Los cupones han sido aplicados correctamente.'
                    //     }


                    // }

             // Construir la lista de códigos de cupones que no se pueden aplicar



                  }



                }

                this.coupons = [];
                this.ValidateBeneficitCouponsModal.Title = this.CouponTitle;
                this.ValidateBeneficitCouponsModal.Text = text;
                this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";
              // this.BeneficitCouponsModal.BtnCancelText = "";

                this.switchcaseOption  = 1;

                this.UserAddCoupons = true;

                this.formCupon.value.cupon = "";
                this.formCupon.get('cupon')?.setValue('');
                this.formCupon = this.fb.group({
                  cupon: ['', [Validators.required]]
                });
                this.loader.hideSpinner();
                this.isCouponSubmited = false;
              this.ValidateShowBeneficitCouponsModal = true;













              }

            });



          }
          else
          {




            this.ValidateBeneficitCouponsModal.Title = this.CouponTitle;
            this.ValidateBeneficitCouponsModal.Text = "Lo sentimos, pero este cupón ya lo agregaste a tu carrito o fue aplicado anteriormente.";
            this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";
            // this.BeneficitCouponsModal.BtnCancelText = "";

            this.switchcaseOption  = 1;

            this.UserAddCoupons = true;

            this.formCupon.value.cupon = "";
            this.formCupon.get('cupon')?.setValue('');
            this.formCupon = this.fb.group({
              cupon: ['', [Validators.required]]
            });
            this.loader.hideSpinner();
            this.isCouponSubmited = false;
            this.ValidateShowBeneficitCouponsModal = true;





          }

        });







      }
      else
      {
//No funciono.
        this.UserAddCoupons = false;
        this.formCupon.value.cupon = "";
        this.formCupon.get('cupon')?.setValue('');
        this.formCupon = this.fb.group({
          cupon: ['', [Validators.required]]
        });

        const jsonObject = JSON.parse(o.Messages);



        this.BeneficitCouponsModal.Title = this.CouponTitle;
        this.BeneficitCouponsModal.Text = jsonObject[0].Mensaje;

         this.BeneficitCouponsModal.ShowCancelBtn = false;
        this.BeneficitCouponsModal.ShowAcceptBtn = true;
        this.showBeneficitCouponsModal = true;
        this.loader.hideSpinner();
        this.isCouponSubmited = false;
      }




    });

    // this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.form.value.cupon, "1", "0").subscribe(res => {
    //   if (res.response.code === "CAPP") {
    //     this.messageService.getMessagesMethod('CAPP-1', '1').subscribe(m => {
    //       this.modalCupon.Title = m.titleMessage;
    //       this.modalCupon.Text = m.message;
    //       this.modalCupon.BtnConfirmText = m.buttonAcceptText;
    //       this.showModalCupon = true;
    //      // this.updateCartSummary();
    //       this.sharedService.updateCart();
    //     });
    //   }
    //   else {
    //     this.modalCupon.Title = res.response.titleMessage;
    //     this.modalCupon.Text = res.response.message;
    //     this.showModalCupon = true;
    //   }
    // });
  }
}
addCoupon3(): void {

  if (this.formCupon.invalid)
    this.isCouponSubmited = true;
  else {
    this.loader.showSpinner();
    const exists = this.coupons.some(coupon => coupon.Code === this.formCupon.value.cupon);
    if(exists)
    {

      let ticket = this.formCupon.value.cupon;
      this.formCupon.value.cupon = "";
      this.formCupon.get('cupon')?.setValue('');
      this.formCupon = this.fb.group({
        cupon: ['', [Validators.required]]
      });
      this.loader.hideSpinner();
      this.isCouponSubmited = false;


    var  message = `
      <p>El cupón: <strong> ${ticket} </strong>  ya se encuentra aplicado.</p>
  `


      this.modalCupon.Title =  this.CouponTitle;
      this.modalCupon.Text = message;
      this.modalCupon.BtnConfirmText = "Aceptar";
      this.modalCupon.ShowAcceptBtn = true;
      this.disableParameterCupon = 1;
      this.showModalCupon = true



      return;

    }





    var cupon = this.formCupon.value.cupon;
    var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
    var y  = this.utilitiesService.decryptData(x.u);


    const url = settings.BusDeServiciosUrlDev + 'Cupones.svc/rest/ValidacionActivacionCupones';

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const body = {
      CodigoBeneficio:this.formCupon.value.cupon,
      Identificacion: y,
      IdentificacionTipo: '',
      Canal:"WEB"
    };

    const jsonData = JSON.stringify(body);

    var result = this.http.post(url, jsonData, { headers })
    result.subscribe((o:any) =>{

      if(o.Ok == true)
      {


        this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.formCupon.value.cupon, "1", "0").subscribe(res => {
          if (res.response.code === "CAPP") {
              this.UserAddCoupons = true;
              const sessionData: any = this.utilitiesService.getValueStorage('sessionStorage');
              const decryptedSession = this.utilitiesService.decryptData(sessionData.c);
              const url = settings.CoreServicesUrl + '/BombaCart.svc/ApplyCouponsFromDokka/' + decryptedSession;
              const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

              this.http.get(url, { headers }).subscribe((response: any) => {

                  if (response.IsValid) {
                      this.handleValidResponse(response);
                      this.formCupon.reset();
                  } else {
                      this.handleInvalidResponse(response);
                      this.formCupon.reset();
                    }
              });
          }
      });




      }
      else
      {
//No funciono.
        this.UserAddCoupons = false;
        this.formCupon.value.cupon = "";
        this.formCupon.get('cupon')?.setValue('');
        this.formCupon = this.fb.group({
          cupon: ['', [Validators.required]]
        });

        const jsonObject = JSON.parse(o.Messages);



        this.BeneficitCouponsModal.Title = this.CouponTitle;
        this.BeneficitCouponsModal.Text = jsonObject[0].Mensaje;

         this.BeneficitCouponsModal.ShowCancelBtn = false;
        this.BeneficitCouponsModal.ShowAcceptBtn = true;
        this.showBeneficitCouponsModal = true;
        this.loader.hideSpinner();
        this.isCouponSubmited = false;
      }




    });

    // this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.form.value.cupon, "1", "0").subscribe(res => {
    //   if (res.response.code === "CAPP") {
    //     this.messageService.getMessagesMethod('CAPP-1', '1').subscribe(m => {
    //       this.modalCupon.Title = m.titleMessage;
    //       this.modalCupon.Text = m.message;
    //       this.modalCupon.BtnConfirmText = m.buttonAcceptText;
    //       this.showModalCupon = true;
    //      // this.updateCartSummary();
    //       this.sharedService.updateCart();
    //     });
    //   }
    //   else {
    //     this.modalCupon.Title = res.response.titleMessage;
    //     this.modalCupon.Text = res.response.message;
    //     this.showModalCupon = true;
    //   }
    // });
  }
}

updateCartSummary(): void {
  this.loader.showSpinner();
  this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
    if (res.response.code === "OK0001") {
      this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
      this.subTotalPriceCart = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
      this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
      this.discount = res.responseObject.PurchaseInfo.Discount;
      this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;
      this.getCartSummary();
    }
    else if (res.response.code === 'emptyCart-lightSummary') {
      this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
        if (res.response.code === "OK0001") {
          this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
          this.subTotalPriceCart = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
          this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
          this.discount = res.responseObject.PurchaseInfo.Discount;
          this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;
          this.getCartSummary();
        }
        else {
          // let r = JSON.stringify(res);
          // this.utilitiesService.insertErrorObservable(this.cartId, 'CARTSUMMARY', r, 'CARTSUMARRY').subscribe(t => {
          // });
          this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        }
      });
    }
    else {
      this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
    }
    this.loader.hideSpinner();
  });
}

closeValidateModalCoupons(event: any)
{
    this.formCupon.reset();  // this.getCartSummary();
    this.ValidateShowBeneficitCouponsModal = false;
  this.showMessageCouponsModal = false;

}

OpenModalCouponMessage(event: any)
{

  this.messageService.getMessagesMethod('ModalCouponMessage', '1').subscribe(message => {



      this.MessageCouponsModal.Title = message.titleMessage;
      this.MessageCouponsModal.Text = message.message;
      this.MessageCouponsModal.BtnConfirmText = message.buttonAcceptText;
      this.showMessageCouponsModal = true;



  });



}


handleValidResponse(response: any) {
  this.loader.hideSpinner();
  const parsedResponse = JSON.parse(response.Parameters);
  this.addCouponToCart(this.formCupon.value.cupon);

  if (parsedResponse.length > 0) {
      const couponsToRemove = this.getCouponsToRemove(parsedResponse);
      const message = this.generateMessage(couponsToRemove);
      this.showModal(message);
  } else {
      this.showSuccessMessage();
  }

  this.finalizeCouponApplication();
}

handleInvalidResponse(response: any) {
  this.loader.hideSpinner();
  const parsedResponse = JSON.parse(response.Parameters);

  if (parsedResponse.length === 0) {
      this.showModal('El cupón o cupones que ingresaste no pudieron ser aplicados.');
  } else {
      this.addCouponToCart(this.formCupon.value.cupon);
      const couponsToRemove = this.getCouponsToRemove(parsedResponse);
      const message = this.generateMessage(couponsToRemove);
      this.showModal(message);
  }

  this.finalizeCouponApplication();
}

addCouponToCart(coupon: string) {
  this.coupons.push({
      Code: coupon,
      DisplayDiscount: false,
      LocalCouponId: 0
  });
}

getCouponsToRemove(responseObject: any) {
  const nonApplicableCoupons = new Set(responseObject.map((item: any) => item.Code.toUpperCase()));
  const couponDetailsMap = new Map(
      responseObject.map((item: any) => [item.Code.toUpperCase(), { ListType: item.ListType, Message: item.Message }])
  );
  return this.coupons
      .filter((coupon: any) => nonApplicableCoupons.has(coupon.Code.toUpperCase()))
      .map((coupon: any) => {
          var details:any = couponDetailsMap.get(coupon.Code.toUpperCase());
          return {
              Code: coupon.Code,
              ListType: details?.ListType ?? 'Desconocido',
              Message: details?.Message ?? 'No disponible'
          };
    });
}

generateMessage(couponsToRemove: any[]) {
  if (couponsToRemove.length === 0) {
      return 'El cupón ha sido aplicado correctamente.';
  }

  const hasType1 = couponsToRemove.some(coupon => coupon.ListType === 1);
  const hasType0 = couponsToRemove.some(coupon => coupon.ListType === 0);
  let message = '';

  if (hasType1 && hasType0) {
      message = this.generateType1AndType0Message(couponsToRemove);
  } else if (hasType1) {
      message = this.generateType1Message(couponsToRemove);
  } else if (hasType0) {
      message = this.generateType0Message(couponsToRemove);
  }

  return message;
}

generateType1AndType0Message(couponsToRemove: any[]) {
  const type0Coupons = couponsToRemove.filter(coupon => coupon.ListType === 0).map(coupon => coupon.Code).join(', ');
  const type1Messages = couponsToRemove.filter(coupon => coupon.ListType === 1).map(coupon => coupon.Message).join('; ');

  return `
      <p><strong>Los siguientes cupones:</strong> ${type0Coupons} <strong>no pueden ser aplicados</strong>, porque existe uno o varios cupones agregados con mejores beneficios.</p>
      <p><strong>Razones:</strong> ${type1Messages}.</p>
  `;
}

generateType1Message(couponsToRemove: any[]) {
  const reasons = couponsToRemove.map(coupon => coupon.Message).join('; ');
  return `<p><strong>Razones:</strong> ${reasons}.</p>`;
}

generateType0Message(couponsToRemove: any[]) {
  const couponCodes = couponsToRemove.map(coupon => coupon.Code).join(', ');
  return `<p><strong>Los siguientes cupones:</strong> ${couponCodes} <strong>no pueden ser aplicados</strong>.</p>`;
}

showModal(message: string) {
  this.ValidateBeneficitCouponsModal.Title = this.CouponTitle;
  this.ValidateBeneficitCouponsModal.Text = message;
  this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";
  this.ValidateShowBeneficitCouponsModal = true;
}

showSuccessMessage() {
  let text = 'El cupón ha sido aplicado correctamente.';
  if (this.coupons.length > 1) {
      text = 'Los cupones han sido aplicados correctamente.';
  }
  this.showModal(text);
}

finalizeCouponApplication() {
  this.formCupon.value.cupon = "";
  this.formCupon.get('cupon')?.setValue('');
  this.formCupon = this.fb.group({
      cupon: ['', [Validators.required]]
  });
  this.loader.hideSpinner();
  this.isCouponSubmited = false;
  this.getCartSummary();
}


validateMinPurchase(minPurchase: string, message?: any): void {

  if (this.totalPrice < parseInt(minPurchase)) {
    const currencyCode: string = 'CRC';
    const locale: string = 'es';
    const symbol = getCurrencySymbol(currencyCode, 'narrow');
    const digitsInfo: string = '1.2-2';

    let min = formatCurrency(parseInt(minPurchase), locale, symbol, currencyCode, digitsInfo);
    min = min.replace(/\s+/g, '');
    let price = parseInt(minPurchase) - this.totalPrice;
    let formatPrice = formatCurrency(price, locale, symbol, currencyCode, digitsInfo);
    formatPrice = formatPrice.replace(/\s+/g, '');
    let priceMinPurchase = min;
    let updateMessage = message.message.replace('₡* *MinExpress* *', formatPrice);
    updateMessage = updateMessage.replace(/\s+/g, ' ');
    let text = updateMessage.replace('₡* *MinExpress2* *', priceMinPurchase);
    text = text.replace(/\s+/g, ' ');
    const finalText = text.trim();

    this.notificationService.SendNotification("alert", message.titleMessage, finalText);


    // this.modal.Title = message.titleMessage;
    // this.modal.Text = finalText;
    // this.modal.BtnConfirmText = message.buttonAcceptText;
    // this.modal.BtnCancelText = message.buttonCancelText;
    // this.showModalMinPurchase = true;
  }
}

}
