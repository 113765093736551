import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable,combineLatest,forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreRequest } from 'src/app/models/requests/request.model';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import { Clients,SignIn,ResponseSignIn,ResponseSignUp,InfoSignUp,Client,ClientAddress,
  MapPoints,Phone,ReducedClientRequest,ReducedClientResponse,ClientObjectGlobalResponse,
  ElectoralRollPersonalDataResponse,GetInfoClientResponse, ClientPersonalInfo, RequestFavoriteProduct  }
  from 'src/app/models/core/clients.model';
import { UserFidelity  }
  from 'src/app/models/core/fidelity.model';
  const settings = new SitefinitySettings();




@Injectable({
  providedIn: 'root'
})
export class FidelityService {
  constructor(private http: HttpClient, private MessagesService_: MessagesService) { }

  private GetFidelityPointsUrl = settings.SitefinityServicesUrl + 'GetInfoPointsAndHistoryUser';
  private GetSessionFidelityUrl = settings.SitefinityServicesUrl + 'ValidateFidelityUserServices';
  private FidelityGetProductsPointsServices = settings.SitefinityServicesUrl + 'FidelityGetProductsPointsServices';






  public FidelityUserPoints (client:UserFidelity): Observable<any> {

    const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
    return  this.http.post(this.GetFidelityPointsUrl, client,{ headers });

  }


  public GetSessionFidelity (client:UserFidelity): Observable<any> {

    const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
    return  this.http.post(this.GetSessionFidelityUrl, client,{ headers });

  }


  public CheckIfProductEarnsPoints (object:any): Observable<any> {


    const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
    return  this.http.post(this.FidelityGetProductsPointsServices, object,{ headers });

  }

}
