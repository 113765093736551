import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RemoveFavoriteProduct } from 'src/app/models/core/favorite-products.model';
import { UserFidelity } from 'src/app/models/core/fidelity.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { ClientsService } from 'src/app/services/core/clients.service';
import { FidelityService } from 'src/app/services/core/fidelity.service';
import { ReminderService } from 'src/app/services/core/reminder.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { environment } from 'src/environments/environment';
import { IModal, IModalSeguimientoOrdenes } from 'src/app/models/modal.model';
import { UtilitiesService } from '../../services/core/utilities.service';
import { Observable, forkJoin } from 'rxjs';
declare var window: any;
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css'],
})
export class MyAccountComponent implements OnInit {

  client: any = null;
  products: any = [];
  urlBase = environment.urlBase;
  urlSite = environment.urlSite;
  modalCard: any;
  modalRemember: any;
  modalDirection: any;
  showCard: boolean = false;
  showRemember: boolean = false;
  showDirection: boolean = false;
  creditCard: any = [];
  purchase: any = [];
  isUserLoggedIn: boolean = false;
  passwordDisabled: boolean = true;
  isSubmited: boolean = false;
  form!: FormGroup;
  settings: SitefinitySettings = new SitefinitySettings();
  validationMsg = this.settings.validationMsgTypeMap;
  user: any;
  userId!: string;
  formatPhone: string = 'El formato debe ser xxxx-xxxx';
  userFidelity: any;
  token!: string;
  selectedUnits: { [key: number]: string } = {};
  selectedQuantity: { [key: number]: number } = {};
  selectedUnitPrice: number[] = [];
  selectedSku: string = '0';
  allReminders: any[] = [];
  selectedReminder: any;
  selectedProduct: any;
  reminder: { [key: number]: string } = {};;
  urlOrdenDeCompra:string = '';
  updateCodeError:string = '';
  //for General Modal
  showGeneralModal = false;
  modalConfirmDeleteFavourte: IModal = {
    Title: 'Favoritos',
    Text: '¿Deseás eliminar este producto de tu lista de Favoritos?',
    TextSmall: 'Si el producto tiene un recordatorio asociado, también se eliminará.',
    ModalName: 'modalConfirmDeleteFavourte'
  };
  ModalSeguimientoOrdenes: IModalSeguimientoOrdenes = {
    ModalName: 'SeguimientoOrdenes',
    Title:'',
    Text:'',
    BtnConfirmText: 'Aceptar',
    ShowCancelBtn: false,
  }


  modalError: IModal = {
    ModalName: 'ModalErrorMyAccount',
    Title:'',
    Text:'',
    BtnConfirmText: 'Aceptar',
    ShowCancelBtn: false,
  }
  showModalError:boolean = false;
  showModalSeguimientoOrdenes:boolean = false;
  showModalRemember: boolean = false;
  modalData: IModal = {
    ModalName: 'modalRememberMyAccount',
  };

  latitude: number = 0;
  longitude: number = 0;
  addresses: any = [];
  clientID: string = '';
  quantityValue: number = 1;
  unitSelected: string = 'Unidad'
  displayQuantityComponent: boolean = false;
  quantityByComponent:any = 10;
  notProducts: boolean = false;
  notPurchase: boolean = false;
  notCreditCard: boolean = false;

  constructor(
    private _utilitiesServices: UtilitiesServicesService,
    private utilities: UtilitiesService,
    private _clientService: ClientsService,
    private _cardService: CartService,
    private router: Router,
    private _notificationService: NotificationService,
    private _fidelityService: FidelityService,
    private fb: FormBuilder,
    public loader: LoadingService,
    private sitefinityPagesService: SitefinitypagesService,
    public reminderService: ReminderService,
    private _sharedService: SharedService
  ) {
    this.form = this.fb.group({
      Password: [
        { value: '', disabled: this.passwordDisabled },
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          Validators.pattern(this.settings.regexPatternsTypeMap['password']),
        ],
      ],
      Phone: ['', [Validators.pattern(/^\d{4}-\d{4}$/), Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {




    if (!this.checkUserLoggedIn()) {
      this.router.navigate(['/iniciosesion']);
    } else {
      this.user = this._utilitiesServices.getValueStorage('sessionStorage');
      this.userId = await this._utilitiesServices.decryptData(this.user.i);
      this.token = await this._utilitiesServices.decryptData(this.user.t);
    }

    this.getClient();


    this.modalDirection = new window.bootstrap.Modal(
      document.getElementById('modalDirection')
    );

    this.sitefinityPagesService.setDetailPage(
      this.settings.pages.find((x) => x.Title === 'micuenta')!.ID
    );

    this.getLocation();



  }

  getLocation() {
    this._utilitiesServices.getPosition().then(pos => {
    setTimeout(() => {

         this._utilitiesServices.getPosition().then(pos => {
        console.log(pos);
          this.latitude = pos.lat;
          this.longitude = pos.lng;
      });


    }, 1200);

    });
}

  get Password() {
    return this.form.get('Password');
  }
  get Phone() {
    return this.form.get('Phone');
  }

  showModalCard() {
    this.showCard = true;
    this.modalCard.show();
  }

  ShowModalRemember(product: any) {
    this.selectedSku = product.SKU;
    var filtered = this.allReminders.filter(
      (x) => x.Articulo == product.SKU
    )[0];
    this.selectedReminder = filtered ?? 0;
    this.showModalRemember = true;
  }
  RefreshReminders() {
    this.getReminders();
  }

  hideModalRemember() {
    this.RefreshReminders();
  }

  showModalDirection() {
    this.getLocation();
    setTimeout(() => {
      this.showDirection = true;
      this.modalDirection.show();
    }, 300);
  }

  closeModalDirection(event: boolean) {
    this.showDirection = !event;
    this.modalDirection.hide();
    this.getClient();
  }

  updateInfoClient(form: any) {
    if (this.form.invalid) {
      this.isSubmited = true;
    } 
    else {
      this.loader.showSpinner();
      if ((form.Phone != null && form.Phone != this.client.Phones[0]?.Phone) || (form.Password !== this.client.Password && form.Password !== undefined)) {
        if (!this.client.AcceptTerms) this.client.AcceptTerms = true;
        //Solo cambia la contraseña si desbloquea el candado
        if (!this.passwordDisabled) {
          this.client.Password = this._utilitiesServices.encryptData(this.form.value.Password);
          this.client.ConfirmationPassword = this._utilitiesServices.encryptData(this.form.value.Password);
        }

        if (form.Phone != this.client.Phones[0]?.Phone) {
          if (this.client.Phones.length > 0) {
            this.client.Phones[0].Phone = this.form.value.Phone;
          }
          else {
            let object = {
              IDClient: this.client.IDClient,
              IDClientPhone: 0,
              IdPhoneType: 1,
              IsDefault: true,
              Phone: this.form.value.Phone,
              Status: "AC"
            }
            this.client.Phones.push(object);
          }
          this.client.ApplyInformedConsent = false;
        }

        this._clientService.UpdateClientInfoMethod(this.client).subscribe((res) => {
          if (res.code === 'UpdateClientInfoMethod-success') {
            this.getClient();
            this._notificationService.SendNotification(
              res.type,
              res.titleMessage,
              res.message
            );
            this.form.controls['Password'].disable();
          }
          else
          {
            this.updateCodeError = res.code;
            this.modalError.Title = res.titleMessage;
            this.modalError.Text = res.message;
            this.modalError.codError  = res.alias;
            this.showModalError = true;

          }
          this.loader.hideSpinner();
        });
      } else {
        this.isSubmited = true;
        this.loader.hideSpinner();
      }
    }
  }

  private checkUserLoggedIn(): boolean {
    this.isUserLoggedIn = this._utilitiesServices.isUserLoggedIn();
    return this.isUserLoggedIn;
  }

  ConfirmFavouriteDelete(event: any) {
    if (event == true) {
      this.DeleteFavoriteProduct();
    } else {
      this.showGeneralModal = false;
    }
  }

  ShowDeleteConfirmation(product: any) {
    this.selectedProduct = product;
    this.showGeneralModal = true;
  }

  DeleteFavoriteProduct() {
    this.showGeneralModal = false;
    this.loader.showSpinner();
    var product = this.selectedProduct;

    //check for reminders
    this.selectedSku = product.SKU;
    var filtered = this.allReminders.filter(
      (x) => x.Articulo == product.SKU
    )[0];
    if (filtered) {
      const requestDelete: any = {
        IDCliente: this.clientID,
        Estado: 'A',
        Articulo: product.SKU,
        DiaDelRecordatorio: filtered.DiaDelRecordatorio,
        CanalOrigen: '4', //Ecommerce
        Medio: filtered.MedioDelRecordatorio[0].Medio,
        Accion: 'D', //Actions: D= Delete, I=Insert, U=Update
        FechaInicio: filtered.FechaInicio,
      };

      this.reminderService.CEDProductReminderMethod(requestDelete).subscribe(
        (o) => {
          if (o.response.type == 'success') {
            //reminder delete success
            const productToRemove: RemoveFavoriteProduct = {
              IdClient: parseInt(this.userId),
              Product: [],
              IdFavoriteProduct: product.IDClientFavoriteProduct,
              ProductSetWithoutProducts: false,
              WithProductSet: false,
            };
            this._clientService
              .DeleteClientFavoriteProductMethod(productToRemove)
              .subscribe((res) => {
                if (res.response.code === 'DeletefavoriteProduct-success') {
                  //favourite product delete success
                  this.getFavoritesProducts();

                  setTimeout(() => {
                    this._notificationService.SendNotification(
                      res.response.type,
                      res.response.titleMessage,
                      res.response.message
                    );
                  }, 1500);
                }
              });
          } else {
            this.loader.hideSpinner();
            this._notificationService.SendNotification(
              o.response.type,
              o.response.titleMessage,
              o.response.message
            );
          }
        },
        (err) => {
          this.loader.hideSpinner();
          this._notificationService.SendNotificationByCode('deleteReminderFail-01','1');
        }
      );
    } else {
      const productToRemove: RemoveFavoriteProduct = {
        IdClient: parseInt(this.userId),
        Product: [],
        IdFavoriteProduct: product.IDClientFavoriteProduct,
        ProductSetWithoutProducts: false,
        WithProductSet: false,
      };
      this._clientService
        .DeleteClientFavoriteProductMethod(productToRemove)
        .subscribe((res) => {
          if (res.response.code === 'DeletefavoriteProduct-success') {
            this.getFavoritesProducts();
            this._notificationService.SendNotification(
              res.response.type,
              res.response.titleMessage,
              res.response.message
            );
          }
          this.loader.hideSpinner();
        },
        (err) => {
          this.loader.hideSpinner();
          this._notificationService.SendNotificationByCode('deleteReminderFail-01','1');
        });
    }
  }

  enabledPassword() {
    this.passwordDisabled = !this.passwordDisabled;

    if (this.passwordDisabled) {
      this.form.controls['Password'].disable();
    } else {
      this.form.controls['Password'].enable();
    }
  }

  deleteCreditCard(key: string) {
    if (key != null) {
      this.loader.showSpinner();
      this._cardService.InactivateSavedCardMethod(key).subscribe((res) => {
        if (res.response.code === 'OK0001') {
          this.getCreditCards();
          this._notificationService.SendNotification(
            res.response.type,
            res.response.titleMessage,
            res.response.message
          );
        }
        this.loader.hideSpinner();
      });
    }
  }

  getFavoritesProducts() {
    this.loader.showSpinner();
    this._clientService.GetFavoritesProductsMethod(this.token).subscribe(async (p) => {
      if (p.response.code === 'OK0001') {
        this.products = p.products;
        this.notProducts = this.products.length > 0 ? false : true;
        this.products.forEach((p: any, index: number) => {
          p.displayQuantityComponent = false;
          p.quantityByComponent = 10;
          p.numbers = [];
          if (p.MaxToPurchase > 10) {
            for (let i = p.MinToPurchase; i <= 9; i++) {
              p.numbers.push(i);
            }
          }
          else {
            for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
              p.numbers.push(i);
            }
          }

          this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
          this.selectedQuantity[index] = p.MinToPurchase;

          if (p.ProductAttributes && p.ProductAttributes.length > 0) {
            if (this.selectedUnits[index] === 'Unidad') {
              this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[0].PriceWithTaxes;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
            }
            else if (this.selectedUnits[index] === 'Caja') {
              if (p.ProductAttributes[0].PriceWithDiscount > 0 || p.ProductAttributes[1]?.PriceWithDiscount > 0) {
                this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[1]?.PriceWithDiscount;
                this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
              }
              else {
                this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithTaxes ? p.ProductAttributes[0].PriceWithTaxes : p.ProductAttributes[1]?.PriceWithTaxes;
                this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
              }
            }

            if (p.MinToPurchase > 1) {
              if (this.selectedUnits[index] === 'Caja') {
                this.selectedUnits[index] = 'Cajas';
              }
              else {
                this.selectedUnits[index] = 'Unidades';
              }
            }
          }
          else {
            this.selectedUnitPrice[index] = p.PriceWithDiscount > 0 ? p.PriceWithDiscount : p.PriceWithTaxes; // Establece un valor predeterminado
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
          }
        });

        this.getReminders();
      }
      else {
        this.notProducts = true;
        this.products = [];
        this.loader.hideSpinner();
      }
    });
  }

  selectUnit(index: number, unit: string): void {
  this.selectedUnits[index] = unit;
    this.unitSelected = unit;
    this.selectType(index,this.selectedUnits[index]);
    // Llama a la función para recalcular el precio cuando se cambia la unidad
    // this.calculateTotalPrice(index);
  }

  selectQuantity(index: number, quantity: number): void {
    this.selectedQuantity[index] = quantity;
    this.quantityValue = quantity;
    this.selectType(index,this.selectedUnits[index]);
  }

  calculateTotalPrice(index: number): void {
    const product = this.products[index];
    const quantity = this.selectedQuantity[index];

    if (product.ProductAttributes.length > 0 && (this.selectedUnits[index] === 'Unidad' || this.selectedUnits[index] === 'Unidades')) {
      this.selectedUnitPrice[index] = product.ProductAttributes[0].PriceWithDiscount ? product.ProductAttributes[0].PriceWithDiscount * quantity : product.ProductAttributes[0].PriceWithTaxes * quantity;
    }
    else if (this.selectedUnits[index] === 'Caja' || this.selectedUnits[index] === 'Cajas' && product.ProductAttributes) {
      if (product.ProductAttributes.length > 1)
        this.selectedUnitPrice[index] = product.ProductAttributes[1]?.PriceWithDiscount ? product.ProductAttributes[1]?.PriceWithDiscount * quantity : product.ProductAttributes[1]?.PriceWithTaxes * quantity;
      else
       this.selectedUnitPrice[index] = product.ProductAttributes[0]?.PriceWithDiscount ? product.ProductAttributes[0]?.PriceWithDiscount * quantity : product.ProductAttributes[0]?.PriceWithTaxes * quantity;
    }
    else {
      this.selectedUnitPrice[index] = product.PriceWithDiscount ? product.PriceWithDiscount : product.PriceWithTaxes;
    }
  }

  validateOption(p: any, index: number): boolean {
    return !(
      p.ProductAttributes &&
      p.ProductAttributes.length === 1 &&
      this.selectedUnits[index] === 'Unidad'
    );
  }

  getCreditCards() {
    this._cardService.GetClientActiveSavedCardsMethod(this.userId).subscribe((res) => {
      if (res.response.code === 'OK0001') {
        this.creditCard = res.responseObject;
        this.notCreditCard = this.creditCard.length > 0 ? false : true;
      }
      else {
        this.notCreditCard = true;
        this.creditCard = [];
      }
    });
  }

  getPurchase() {

    this.loader.showSpinner();
    const observables: Observable<any>[] = [
      this.utilities.GetParametersByKey('UrlMapSeguimiento'),
      this._cardService.SearchPurchaseByIDClientMethod(this.userId)
    ];
    forkJoin(observables).subscribe(([resultadoServicio1, resultadoServicio2]) => {
      if(resultadoServicio1.value.length > 0) {
        this.urlOrdenDeCompra = resultadoServicio1.value[0].value;
      }

      if (resultadoServicio2.response.code === 'OK0001') {
        this.purchase = resultadoServicio2.responseObject;
        this.notPurchase = this.purchase.length > 0 ? false : true;
        this.purchase.forEach((elemento:any) => {
          elemento.urlOrden = this.urlOrdenDeCompra+elemento.IDPurchase;
          if (elemento.CartObject.PurchaseInfo.Address.IdDeliveryMethod == 2 && elemento.CheckedFlags) {
            elemento.urlOrden = this.urlOrdenDeCompra+elemento.IDPurchase;
          }
          else {
            elemento.urlOrden = '';
          }
        });
      }
      else {
        this.notPurchase = true;
        this.purchase = [];
      }
      this.loader.hideSpinner();
    });
  }

  getClient() {
    this._clientService.GetUserPersonalInfoMethod(this.userId.toString()).subscribe((res) => {
      if (res.response.code === 'OK0001') {

        this.client = res.responseObject;
        let defaultPadronNameDirection: string = '';
          this.utilities.GetParametersByKey('DefaultPadronNameDirection').subscribe((o) => {
            defaultPadronNameDirection = this.utilities.decodeData(o.value[0].value);
            this.addresses = this.client.Addresses.filter((a: {Name: any; IDShop: null; }) => a.IDShop != null && a.Name != defaultPadronNameDirection);
            this.addresses = this.addresses.sort((a: any, b: any) => b.IDClientAddress - a.IDClientAddress);
            this.form.setValue({
              Phone: this.client.Phones.length > 0 ? this.client.Phones[0].Phone : null,
              Password: this.client.Password,
            });

            const userFidelity: UserFidelity = {
              IdClientType: this.client.IdClientType,
              Identification: this.client.Identification,
            };

            this.clientID = this.client.Identification ?? ''
            this._fidelityService.FidelityUserPoints(userFidelity).subscribe((fs) => {
              if (fs != null) {
                this.userFidelity = fs;
              }
              else {
                this.userFidelity = {};
              }
            });
          });
      }
      else {
        this.client = [];
      }
    });
  }

  getReminders() {
    if (this.clientID.trim() != '' && this.products.length > 0) {
      this.reminderService
        .GetProductRemindersByUserMethod(this.clientID.trim() + '', '1')
        .subscribe((res) => {
          if (res.Listado && res.Listado.Recordatorios) {
            this.allReminders = res.Listado.Recordatorios;
            this.products.forEach((p: any, index: number) => {
              const reminderMatch = this.allReminders.find((x) => x.Articulo === p.SKU);
              this.reminder[index] = reminderMatch || null;
            });
          }
          this.loader.hideSpinner();
        },
        (err) => {
          this.loader.hideSpinner();
          this._notificationService.SendNotificationByCode('getRemindersFail-01','1');
        });
    } else {
      this.loader.hideSpinner();
    }
  }

  goToDetailProduct(url: any) {
    if (url != null) {
      this.router.navigate(['/detalle-producto', url]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  addProductToCart(product: any, unitSelected: any, quantity: number, price: number) {
    if (product) {
      this._sharedService.addProductsToCartV2(product, unitSelected, quantity, price);
    }
    setTimeout(() => {
      this.loader.hideSpinner();
    }, 2000);
  }

  closeModalSeguimiento (event: any) {

    this.showModalSeguimientoOrdenes = false;

}

openSeguimientoOrdenModal(url:string)
{


  this.ModalSeguimientoOrdenes.Text =  url;
  this.ModalSeguimientoOrdenes.Title = 'Seguimiento de ordenes'
  this.showModalSeguimientoOrdenes = true;

}

ModalErrorAction(event:any)
{

  if(this.updateCodeError == 'CARTVAL007')
  {
    this.loader.showSpinner();
    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this._utilitiesServices.deleteValueStorage("sessionStorage");
        this.isUserLoggedIn = false;
        window.location.href = this.urlSite + 'index';
      }
    }, 1000);

  }
  else
  {

    this.showModalError = false;

  }

}

  DeleteAddress(AddressID:any) {
    this.loader.showSpinner();
    if ( this.client.Addresses.length > 0) {
      this.utilities.GetParametersByKey('DefaultPadronNameDirection').subscribe((o) => {
        let defaultPadronNameDirection: string = '';
        defaultPadronNameDirection = this.utilities.decodeData(o.value[0].value);
        this.addresses = this.client.Addresses.filter((a: { Name: string; IDShop: null; }) => a.IDShop != null && a.Name != defaultPadronNameDirection);
        this.addresses = this.addresses.filter((ad:any) => ad.IDClientAddress != AddressID );
        this.client.Addresses = this.addresses;
        this.addresses = this.addresses.sort((a: any, b: any) => b.IDClientAddress - a.IDClientAddress);
        if (!this.client.AcceptTerms) this.client.AcceptTerms = true;
        this._clientService.DeletelientInfoMethod(this.client).subscribe((res) => {
          if (res.code === 'UpdateClientInfoMethod-success') {
            this._clientService.getMessages("UpdateAddress-SUCCESS","1").subscribe(o=>{
              this._notificationService.SendNotification(o.type,o.titleMessage,o.message);
            });
          }
          else {
            this.updateCodeError = res.code;
            this.modalError.Title = res.titleMessage;
            this.modalError.Text = res.message;
            this.modalError.codError  = res.alias;
            this.showModalError = true;
          }
          this.loader.hideSpinner();
        });
      });
    }
  }

getPluralizedName(itemName: string, quantity: number): string {
  if (itemName.toLowerCase() === 'unidad') {
    return quantity > 1 ? 'Unidades' : 'Unidad';
  } else if (itemName.toLowerCase() === 'caja') {
    return quantity > 1 ? 'Cajas' : 'Caja';
  } else {
    // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
    return itemName;
  }
}

selectType(index:any,itemName: string ): string {

  if (itemName.toLowerCase() === 'unidad' ||  itemName.toLowerCase() === 'unidades' ) {

    this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Unidades' : 'Unidad';;
    this.calculateTotalPrice(index);
    return  this.selectedUnits[index]
  } else if (itemName.toLowerCase() === 'caja' ||  itemName.toLowerCase() === 'cajas') {

    this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Cajas' : 'Caja';
    this.calculateTotalPrice(index);
    return  this.selectedUnits[index];
  } else {
    this.selectedUnits = itemName;
    this.calculateTotalPrice(index);
    // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
    return  this.selectedUnits[index]
  }
}

openQuantityComponent(index:any,value:any)  {

  if(value == false)
  {
    this.products[index].quantityByComponent=  this.validateQuantityZero(this.products[index].quantityByComponent)
  this.selectedQuantity[index] = this.products[index].quantityByComponent;
  this.selectType(index,this.selectedUnits[index]);

  }
  this.products[index].displayQuantityComponent = value;


}



validateQuantityZero( quantity:any)
{
  if(quantity <= 0 )
 {
      return 1;

 }
 if(quantity > 50)
 {
   return 50;
 }
 return quantity;
}


}
