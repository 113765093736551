import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LoadChildren } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ICanton } from 'src/app/models/canton.model';
import { IProvince } from 'src/app/models/provinces.model';
import { ProductsService } from 'src/app/services/core/products.service';
import { ShopsService } from 'src/app/services/core/shops.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';

declare let window: any;
@Component({
  selector: 'app-modal-pharmacies',
  templateUrl: './modal-pharmacies.component.html',
  styleUrls: ['./modal-pharmacies.component.css']
})
export class ModalPharmaciesComponent implements OnInit, OnChanges{

  @Input() showModal: boolean = false;
  @Input() sku!: string;
  @Input() optionslength!: number;
  @Input() optionvalue!: string;
  @Input() modalData: any = {
    ModalName: 'modalDelete',
  };
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);

  pharmacies: any = [];
  selectedProvince: any = null;
  cantons: ICanton[] = [];
  selectedCanton: any = null;
  selectedPharmacie: any = null;
  provinces: IProvince[] = [];
  error: boolean = false;
  resetValue = false;
  isNotAvalability: boolean = false;
  modal: any;
  constructor(
    private productService: ProductsService,
    private loader: LoadingService,
    private utilitiesService: UtilitiesService,
    private shopsService: ShopsService,
  ){}

  ngOnInit(): void {
    // this.getShopBySku();
    this.getProvinces();
    this.selectedProvince = 0;
    this.selectedCanton = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.SetDefaultValues();
      this.modal.show();
    }
  }
  
  SetDefaultValues() {
    this.loader.showSpinner();
    this.selectedProvince = this.provinces.filter(x => x.Name == 'San José')[0].IDAddressProvince ?? 0;
    //buscar los cantones:
    this.pharmacies = [];
    this.utilitiesService.GetCantonsByIDProvince(this.selectedProvince.toString()).subscribe(res => {
      this.selectedPharmacie = 0;
      this.cantons = JSON.parse(res.Parameters);
      this.selectedCanton = this.cantons.filter((x: any) => x.Name == 'San José')[0].IDAddressCanton ?? 0;
      this.onChangeCanton(null)
    });
  }

  private getProvinces() {
    this.loader.showSpinner();
    this.utilitiesService.getProvinces().subscribe(res => {
      this.provinces = JSON.parse(res.Parameters);
    this.loader.hideSpinner();
    }, error => {
      this.loader.hideSpinner();
    });
  }

  getShopBySku(pharmaciesRes:any) {
    let sku2: string = 'S' + this.sku;
    if (this.sku != null) {
      this.productService.GetShopsProductDetail(sku2, this.sku).subscribe(res => {

        if (res.Ok) {
            this.pharmacies = JSON.parse(res.Value);
          let stock = JSON.parse(res.Value);
          const pharmaciesSort = JSON.parse(pharmaciesRes.Parameters);
          pharmaciesSort.forEach((pharmacy:any) => {
            const stockItem = stock.find((item:any )=> item.CodigoFarmacia === pharmacy.Alias);
            if (stockItem) {
              pharmacy.stock = stockItem.Cantidad;
              this.pharmacies.push(pharmacy);
            }
          });
          this.pharmacies =  this.pharmacies.sort((a: any, b: any) => a.Name.localeCompare(b.Name));
        }
        else {
          this.pharmacies = [];
          this.error = true;
        }
      });
    }
  }

  onChangeProvince(event: any) {
      this.pharmacies = [];
      this.loader.showSpinner();
      this.utilitiesService.GetCantonsByIDProvince(this.selectedProvince.toString()).subscribe(res => {
      this.selectedCanton = 0;
      this.selectedPharmacie = 0;
      this.cantons = [];
      this.cantons = JSON.parse(res.Parameters);
      this.selectedCanton = this.cantons[0].IDAddressCanton;
      this.onChangeCanton(null);
      this.loader.hideSpinner();
    });
  }

  onChangeCanton(event: any) {
    this.loader.showSpinner();
    let sku2: string;

    if(this.sku.startsWith('S')){
      sku2 = this.sku.substring(1,this.sku.length);
    }
    else{
      sku2 = 'S' + this.sku;
    }

    const observables: Observable<any>[] = [

      this.shopsService.GetShopsByShopType([], this.selectedProvince, this.selectedCanton, 0),
      this.productService.GetShopsProductDetail(sku2, this.sku)


    ];
    forkJoin(observables).subscribe(([resultServices1, resultServices2]) => {

      if (resultServices2.Ok) {
        // this.pharmacies = JSON.parse(res.Value);
        var stock = JSON.parse(resultServices2.Value);
        const pharmaciesSort = JSON.parse(resultServices1.Parameters);
        this.pharmacies = [];

        var newStock: any[] = []

        stock.forEach((element:any) => {
          var IsOk = 0;
          if(this.optionslength == null){
            IsOk = 1;
          }
          else{
            if(this.optionslength > 1 || this.optionslength == 0){
              IsOk = 1;
            }
          }

          if(IsOk==1){
            if (element.Sku.startsWith("S"))
            {
              element.type = "Unidades"
            }
            else
            {
              element.type = "Cajas"
            }
          }

          if (this.optionslength == 1 && this.optionvalue.startsWith("Unidad"))
          {
            element.type = "Unidades"
          }
          if (this.optionslength == 1 && this.optionvalue.startsWith("Caja"))          {
            element.type = "Cajas"
          }
        });

        interface StockInfo {
          StockUnidades: number | null;
          StockCajas: number | null;
          CodigoFarmacia: string;
          Farmacia: string;
          Address: string;
          Name: string;
        }

        let infoCombinada: StockInfo[] = [];

        stock.forEach((item:any) => {
          let existingEntry = infoCombinada.find(entry => entry.CodigoFarmacia === item.CodigoFarmacia);

          if (!existingEntry) {
            existingEntry = {
              StockUnidades: null,
              StockCajas: null,
              CodigoFarmacia: item.CodigoFarmacia,
              Farmacia: item.Farmacia,
              Address: item.Address,
              Name: item.Name
            };
            infoCombinada.push(existingEntry);
          }

          if (item.type === 'Unidades') {
            if(item.Cantidad == null)
            {
              existingEntry.StockUnidades = 0;
            }
            else
            {
              existingEntry.StockUnidades = item.Cantidad;
            }

          } else if (item.type === 'Cajas') {
            if(item.Cantidad == null)
            {
              existingEntry.StockCajas = 0;
            }
            else
            {
              existingEntry.StockCajas = item.Cantidad;
            }
          }
        });
        const codigosFarmacia = new Set(stock.map((item:any) => item.CodigoFarmacia));

        // Filtrar el arreglo de farmacias para obtener solo aquellas cuyo Alias está en codigosFarmacia
        const farmaciasFiltradas = pharmaciesSort.filter((farmacia:any) => codigosFarmacia.has(farmacia.Alias));

        this.pharmacies = [];
        infoCombinada.forEach((element:any) => {
          farmaciasFiltradas.forEach((elementPharmacy:any) => {
            if (element.CodigoFarmacia == elementPharmacy.Alias) {
              element.Address = elementPharmacy.Address;
              element.Name = elementPharmacy.Name;
              this.pharmacies.push(element);
            }
          });
        });

        this.pharmacies =  this.pharmacies.sort((a: any, b: any) => a.Name.localeCompare(b.Name));

        if (this.pharmacies.length === 1 && (this.pharmacies[0].StockCajas === 0 || this.pharmacies[0].StockUnidades === 0)) {
          this.isNotAvalability = true;
        } 
        else if (this.pharmacies.length > 0) {
          this.isNotAvalability = this.pharmacies.every((ph: any) => ph.StockCajas === 0 && ph.StockUnidades === 0);
        } 
        else {
          this.isNotAvalability = true;
        }
        
        this.loader.hideSpinner();
      }
      else {
        this.pharmacies = [];
        this.isNotAvalability = true
        this.error = true;
        this.loader.hideSpinner();
      }
    });
  }

  resetData() {
    this.selectedProvince = 0;
    this.selectedCanton = 0;
    this.pharmacies = [];
  }

  close() {
    this.closeModal.emit(true);
    this.modal.hide();
    this.selectedProvince = 0;
    this.selectedCanton = 0;
    this.resetValue = !this.resetValue;
  }
}
