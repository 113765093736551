import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css']
})
export class ModalLoginComponent {

  @Input() showModal: boolean = false;
  @Input() message!: string;
}
