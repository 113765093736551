<!--Inicio componente header-->
<header class="header-position">

  <div class="container py-2 header-container">
    <div class="row d-flex align-items-center">
      <div class="col-3">
        <div class="d-flex align-items-center">
          <div class="nav-icon d-xl-none me-4">
            <a href="" (click)="setActiveItem(11)" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
              <img [src]="urlBase +'/assets/img/icons/menu.svg'" alt="Icono menú" class="icon-header" width="18"
                height="auto">

            </a>
          </div>
          <a routerLink="/" (click)="setActiveItem(11)">
            <img [src]="urlBase + '/assets/img/logo-color.svg'" loading="lazy" alt="Logo La Bomba" class="logo-header"
              width="150" height="auto">

          </a>
        </div>
      </div>

      <div class="col-6">
        <div class="input-group category-search password-input d-none d-xl-flex dropdown">
          <input type="text" class="form-control input-header" id="inputSearchHeaderDesktop" [(ngModel)]="searchValue"
            autocomplete="off" maxLength="40" placeholder="Buscar producto" onFocus="showDesktop()"
            (keyup.enter)="Search()" tabindex="98" (blur)="hideDesktopInputSearchHeader($event)"
            (keyup)="SearchAutocomplete()">
          <i class="bi bi-search color-primary" (click)="Search()"></i>

          <div class="category-search-content" tabindex="100"
          *ngIf="(listAutocompleteProducts.length > 0) && (isUserLoggedIn == false) && (showAutocomplete)"
          id="listAutocompleteResultDesktop">
            <div class="row align-items-start ps-3 pe-2 cursor">
              <div *ngFor="let result of listAutocompleteProducts" class="col-5 badge badge-filter text-bg-search">
                <span (click)="GoToSearch(result.text)">{{result.text}}</span>
              </div>
            </div>
          </div>

          <div class="category-search-content" id="listSearchResultDesktop" tabindex="99" (blur)="hideDesktopListSearchResult($event)"
            *ngIf="(isUserLoggedIn)&&((listAutocompleteProducts.length > 0)||(SearchsService.GetRecentSearchs().length > 0))">
            <div *ngIf="(listAutocompleteProducts.length > 0) && (isUserLoggedIn) && (showAutocomplete)" class="row align-items-start ps-3 pe-2 cursor">
              <div *ngFor="let result of listAutocompleteProducts" class="col-5 badge badge-filter text-bg-search">
                <span (click)="GoToSearch(result.text)">{{result.text}}</span>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between position-relative pb-3"
            *ngIf="SearchsService.GetRecentSearchs() && SearchsService.GetRecentSearchs().length > 0">
              <span class="mt-3 search-filter"> Últimas búsquedas</span>
              <i class="bi bi-trash delete-product mt-2" (click)="DeleteAllSearch()"></i>
            </div>
            <div>
              <div class="row align-items-start ps-3 pe-2">
              <div *ngFor="let result of SearchsService.GetRecentSearchs()"
                  class="col-5 badge badge-filter text-bg-search cursor">
                  <span (click)="GoToSearch(result.Argument)">{{result.Argument}}</span>
                  <i class="bi bi-x position-static" (click)="DeleteSearchBySearchID(result.IDRecentSearch)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="text-danger"
          *ngIf="showSearchError && searchValue.trim().length <3 && searchValue.trim().length > 0">Revise que tenga
          mínimo 3 caracteres</span>
      </div>

      <div class="col-3">
        <div class="d-flex justify-content-end align-items-center">

          <div *ngIf="!isUserLoggedIn" class="dropdown-center nav-icon me-3 d-none d-sm-block">

            <a class="dropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <img [src]="urlBase+ '/assets/img/icons/user.svg'" loading="lazy" alt="…" class="icon-header" width="18"
                height="auto">

            </a>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><a class="dropdown-item" routerLink="/iniciosesion">Iniciar sesión</a></li>
              <li><a class="dropdown-item" routerLink="/registro">Registrarse</a></li>
            </ul>
          </div>

          <div *ngIf="isUserLoggedIn" class="d-flex align-items-end d-none d-xl-flex">


            <div class="dropdown-center me-3">

              <a class="dropdown d-flex align-items-center text-decoration-none" href="#" data-bs-toggle="dropdown"
                aria-expanded="false">
                <div class="user-name me-3">
                  <span>Hola,<span class="ms-1">{{getShortName(user.n)}}</span></span>
                </div>
                <img [src]="urlBase+'/assets/img/user-fill.svg'" loading="lazy" alt="…" class="icon-header" width="18"
                  height="auto">
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 15rem; padding: 10px 20px;">

                <div class="d-flex align-items-center">
                  <img [src]="urlBase+'/assets/img/account.png'" loading="lazy" alt="…" class="img-account" width="40"
                    height="40">
                  <div class="ms-2">
                    <a routerLink="micuenta" class="d-block account-link-color">Mi cuenta</a>
                  </div>
                </div>
                <div class="pt-1">
                  <a (click)="loginOut()" class="account-link cursor-pointer">Cerrar sesión</a>
                </div>
              </ul>
            </div>
          </div>



          <button [disabled]="isButtonDisabled" id="buttonCart" class="position-relative nav-cart nav-icon px-0 pe-2" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" (click)="getProductsFromCartv2($event)">
            <a href="#" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar">
              <img [src]="urlBase+ '/assets/img/icons/car.svg'" loading="lazy" alt="Icono carrito" class="icon-header"
                width="18" height="auto">

            </a>
            <div *ngIf="!isButtonDisabled" class="translate-middle badge rounded-pill nav-cart-badge">
              {{totalQuantity}}
            </div>

            <span *ngIf="isButtonDisabled" class="translate-middle badge rounded-pill nav-cart-badge" data-bs-toggle="tooltip"  data-placement="bottom" title="Lo sentimos, esta pestaña no está disponible en el proceso de compra">{{totalQuantity}}</span>



          </button>
        </div>
      </div>

    </div>
  </div>

  <div class="sub-nav d-none d-xl-block blue-gradient">
    <ul class="nav nav-underline justify-content-center align-items-center cursor-pointer">
      <li class="nav-item">
        <div class="d-flex align-items-center">
          <img [src]=" urlBase + '/assets/img/discount.svg'" alt="Descuento" class="img-discount">
          <a class="nav-link offer-nav" (click)="goToProductsByCategory('descuentos-27',100)">
            Descuentos
          </a>
        </div>
      </li>
      <li class="nav-item" [ngClass]="{ 'active': activeItemIndex === 1 }">
        <a class="nav-link caret color-nav" [ngClass]="{ 'active': activeItemIndex === 1 }" (click)="changeIndex(1)" type="button" data-bs-toggle="collapse" data-bs-target="#menuBarContainer" aria-expanded="false" aria-controls="searchBarContainer">
          Productos
        </a>
      </li>

      <li class="nav-item">
        <a [ngClass]="{ 'active': activeItemIndex === 2 }" class="nav-link color-nav" (click)="redirectToPlanFrecuente(2)">Plan paciente</a>
      </li>

      <!-- <li class="nav-item">
        <a [ngClass]="{ 'active': activeItemIndex === 5 }" class="nav-link color-nav" (click)="redirectToBlog(5)">Blog</a>
      </li> -->


      <li class="nav-item">
        <a [ngClass]="{ 'active': activeItemIndex === 3 }" class="nav-link color-nav" (click)="redirectToBlog(3)">Blog</a>
      </li>

      <li class="nav-item">
        <a [ngClass]="{ 'active': activeItemIndex === 4 }" class="nav-link color-nav" (click)="redirectToInsurers(4)">Aseguradoras</a>
      </li>

      <li class="nav-item">
        <a [ngClass]="{ 'active': activeItemIndex === 5 }" class="nav-link color-nav" (click)="getFidelity()" target="_blank" rel="noopener noreferrer">
          <img [src]="urlBase + '/assets/img/bombaconmigo.png'" class="logo-conmigo-header">
        </a>
      </li>



      <!-- Soy fischel -->
      <form id="fidelityHiddenForm" class="hidden" action="{{fidelityRedirectUrl}}" target="_blank" rel="noopener noreferrer" name="fidelityForm"
        method="post" enctype="application/x-www-form-urlencoded">
        <input id="sessionIDFidelityInput" type="hidden" name="sessionID" value="{{sessionIDFidelityInput}}"
          autocomplete="off">
      </form>

    </ul>
  </div>

  <div class="sub-nav collapse category-menu" id="menuBarContainer">
    <!--menu normal -->
    <ul class="nav nav-underline justify-content-center sub-menu-container">
      <li class="nav-item" *ngFor="let c of _categories; let i = index">
        <a *ngIf="c.Name != 'Descuentos'" [ngClass]="{ 'click': activeCategoryIndex === i }" class="nav-link"
          aria-current="page" (click)="goToProductsByCategory(c.URL, i)">{{c.Name}}</a>
      </li>

    </ul>
  </div>

  <div class="sub-nav d-xl-none p-2" id="searchBarContainer">
    <!--menu responsives -->
    <div class="input-group category-search password-input dropdown">
      <input type="text" class="form-control input-header" id="inputSearchHeaderMobile" [(ngModel)]="searchValue"
        autocomplete="off" maxLength="40" (keyup.enter)="Search()" placeholder="Buscar producto" onFocus="showMobile()"
        tabindex="98" (blur)="hideMobileInputSearchHeader($event)" (keyup)="SearchAutocompleteMobile()">
      <i class="bi bi-search color-primary" (click)="Search()"></i>

      <div class="category-search-content-mobile position-relative" tabindex="100"
      *ngIf="(listAutocompleteProducts.length > 0) && (isUserLoggedIn == false) && (showAutocomplete)"
      id="listAutocompleteResultMobile">
        <div class="row align-items-start">
          <div *ngFor="let result of listAutocompleteProducts" class="col badge badge-filter text-bg-search">
            <span (click)="GoToSearch(result.text)">{{result.text}}</span>
          </div>
        </div>
      </div>

      <div *ngIf="(isUserLoggedIn)&&((listAutocompleteProducts.length > 0)||(SearchsService.GetRecentSearchs().length > 0))"
        class="category-search-content-mobile position-relative"
        id="listSearchResultMobile"  tabindex="99" (blur)="hideMobileListSearchResult($event)">
          <div *ngIf="(listAutocompleteProducts.length > 0) && (isUserLoggedIn) && (showAutocomplete)" class="row align-items-start">
            <div *ngFor="let result of listAutocompleteProducts" class="col badge badge-filter text-bg-search">
            <span (click)="GoToSearch(result.text)">{{result.text}}</span>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between position-relative pb-3">
            <span class="mt-3"> Últimas búsquedas </span>
            <i class="bi bi-trash delete-product mt-1" (click)="DeleteAllSearch()"></i>
          </div>

          <div>
            <div class="row align-items-start ps-3 pe-2">
              <div *ngFor="let result of SearchsService.GetRecentSearchs()" class="col badge badge-filter text-bg-search" >
                <span (click)="GoToSearch(result.Argument)">{{result.Argument}}</span>
                <i class="bi bi-x position-static" (click)="DeleteSearchBySearchID(result.IDRecentSearch)"></i>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</header>

<!-- off canvas menu -->
<div class="offcanvas offcanvas-start canvas-size" tabindex="-1" id="offcanvasMenu"
  aria-labelledby="offcanvasNavbarLabel">
  <div class="offcanvas-header">
    <div *ngIf="!isUserLoggedIn" class="d-flex align-items-center">
      <img [src]="urlBase+'/assets/img/account-disabled.png'" loading="lazy" alt="Icono usuario" class="img-account"
        width="40" height="40">
      <div class="ms-2">
        <a href="/iniciosesion" class="text-decoration-none">Iniciar sesión</a>
      </div>
    </div>

    <div *ngIf="isUserLoggedIn" class="d-flex">
      <img [src]="urlBase+'/assets/img/account.png'" loading="lazy" alt="Icono usuario" class="img-account" width="40"
        height="40">
      <div class="ms-2">
        <span class="d-block fw-500 name-color">{{getShortName(user.n)}}</span>
        <a routerLink="micuenta" class="account-link">Mi cuenta</a>
      </div>
    </div>

    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>

  <div class="offcanvas-body pt-0" id="myOffcanvas">
    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
      <li class="nav-item">
        <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link" aria-current="page"
          routerLink="/">Inicio</a>
      </li>
      <li class="nav-item">
        <div class="d-flex align-items-center ">
          <img [src]="urlBase + '/assets/img/discount-blue.svg'" alt="Descuento" class="img-discount">
          <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link offer-nav-movil ps-1"
            (click)="goToProductsByCategory('descuentos-27',100)">
            Descuentos
          </a>
        </div>
      </li>
      <li class="nav-item dropdown tree-menu">
        <ul id="treeUl">
          <li class="custom-nav-item">
            <span class="caret active">Productos</span>
            <ul class="nested-menu">
              <li *ngFor="let c of _categories; let i = index"> <a *ngIf="c.Name != 'Descuentos'"
                  data-bs-dismiss="offcanvas" aria-label="Close" href=""
                  (click)="goToProductsByCategory(c.URL, i)">{{c.Name}}</a> </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link" (click)="redirectToPlanFrecuente(2)">Plan paciente</a>
      </li>

      <!-- <li class="nav-item">
        <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link" (click)="redirectToBlog(5)">Blog</a>
      </li> -->

      <li class="nav-item">
        <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link" (click)="redirectToBlog(2)">Blog</a>
      </li>
      <li class="nav-item">
        <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link" (click)="redirectToInsurers(3)">Aseguradoras</a>
      </li>
      <li *ngIf="isUserLoggedIn" class="nav-item">
        <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link" (click)="loginOut()">Cerrar sesión</a>
      </li>
      <li class="nav-item custom-nav-item">
        <a data-bs-dismiss="offcanvas" aria-label="Close" class="nav-link" (click)="getFidelity()" target="_blank" rel="noopener noreferrer">
          <img [src]="urlBase + '/assets/img/labomba.png'" class="logo-conmigo-header">
        </a>
      </li>



      <!-- <div *ngIf="isUserLoggedIn" class="">
        <a aria-label="Close" (click)="getFidelity()" target="_blank" rel="noopener noreferrer">
          <img [src]="urlBase+'/assets/img/soy-fischel-small-color.png'" loading="lazy" alt="…" class="logo-menu"></a>
      </div> -->



      <!-- <div *ngIf="!isUserLoggedIn" class="">
        <a aria-label="Close" href="{{fidelityRedirectUrl}}" target="_blank" rel="noopener noreferrer">
          <img [src]="urlBase+'/assets/img/soy-fischel-small-color.png'" loading="lazy" alt="…" class="logo-menu"></a>
      </div> -->

      <hr>
      <ul class="ps-0 d-flex justify-content-center">
        <a aria-label="Close" href="https://www.facebook.com/farmacialabomba/" target="_blank" rel="noopener noreferrer"><i
            class="bi bi-facebook icon-navbar"></i></a>
        <a aria-label="Close" href="https://www.instagram.com/farmacialabombacr/" target="_blank" rel="noopener noreferrer">
          <i class="bi bi-instagram icon-navbar"></i></a>
        <a href="https://www.tiktok.com/@farmacialabombacr" rel="noopener noreferrer" target="_blank"> <i class="bi bi-tiktok icon-navbar"></i></a>
        <a aria-label="Close" href="https://wa.me/50689650033" target="_blank" rel="noopener noreferrer"> <i
            class="bi bi-whatsapp icon-navbar"></i></a>
      </ul>
    </ul>
  </div>
</div>


<!-- off canvas carrito -->
<div class="offcanvas offcanvas-end canvas-size" tabindex="-1" id="offcanvasNavbar"
  aria-labelledby="offcanvasNavbarLabel" *ngIf="openSidebar">
  <div class="offcanvas-header">
    <h3 class="offcanvas-title" id="offcanvasNavbarLabel">Carrito de compras</h3>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>

  <div class="offcanvas-body pt-0">

    <!-- Mostrar cuando no haya datos -->
    <div class="text-center mt-5" *ngIf="productsCart.length === 0">
      <i class="bi bi-cart icon-page"></i>
      <h3 class="">Tu carrito está vacío</h3>
      <small>Seleccioná un producto para proceder a la compra</small>
    </div>


    <div class="card mb-2 card-menu">
      <div class="row g-0" *ngFor="let p of productsCart; let i = index">
        <div class="col-2 mb-0">
          <div *ngIf="p.Images.length > 0" class="img-favorite-product">
            <a class="cursor-pointer" (click)="goToDetailProduct(p.URL)" data-bs-dismiss="offcanvas" aria-label="Close">
              <img [src]="p.Images[0].WebPath" loading="lazy" alt="" (click)="goToDetailProduct(p.URL)">
            </a>
          </div>
        </div>
        <div class="col-10">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <a class="text-decoration-none color-dark cursor-pointer" (click)="goToDetailProduct(p.URL)" data-bs-dismiss="offcanvas" aria-label="Close">
                <h5 class="card-title">{{p.Name}}</h5>
              </a>
              <a>
                <i class="bi bi bi-trash delete-product" (click)="deleteProductFromCart(p, i)" data-bs-dismiss="offcanvas"></i>
              </a>
            </div>

            <div class="mb-2">
              <span class="product-price mb-0 text-nowrap d-block"> {{selectedUnitPrice[i] | mycurrency}} i.v.a.i</span>
              <span class="product-discount mb-0 text-nowrap" *ngIf="p.PriceWithDiscount > 0"> {{p.CurrentTotalPrice | mycurrency}} i.v.a.i</span>
            </div>

            <div class="mb-2 d-flex align-items-center" *ngIf="p.CouponApplied">
              <i class="bi bi-check-all text-success"></i>
              <span>Cupón aplicado</span>
            </div>

            <div class="d-flex align-items-center me-2">
              <div *ngIf="!p.displayQuantityComponent" class="d-flex align-items-center me-1">
                <div class="nav-item dropdup">

                  <button class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{ selectedQuantity[i] }}


                  </button>
                  <ul class="dropdown-menu">
                    <li *ngFor="let quantity of p.numbers">
                      <a class="dropdown-item" (click)="selectQuantity(i,quantity)"> {{quantity}} </a>
                    </li>
                    <hr *ngIf="this.p.MaxToPurchase > 9" class="my-0">
                    <li *ngIf="this.p.MaxToPurchase > 10" (click)="openQuantityComponent(i,true)"><a
                        class="dropdown-item">10+ </a></li>

                  </ul>

                </div>
              </div>

              <div *ngIf="!p.displayQuantityComponent" class="d-flex align-items-center me-1">
                <div class="nav-item dropup">

                  <button *ngIf="p.SelectedAttributes.length > 1"
                    class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false"
                    [ngClass]="{'btn-outline-secondary': true, 'dropdown-toggle': true, 'dropdown-product': true}">
                    {{ selectedUnits[i] }}
                  </button>

                  <button *ngIf="p.SelectedAttributes.length == 1 || p.SelectedAttributes.length == 0 "
                    class="btn btn-outline-secondary  dropdown-product" type="button" aria-expanded="false">
                    {{ selectedUnits[i] }}
                  </button>


                  <ul class="dropdown-menu option-dropdown">
                    <li *ngFor="let item of p.SelectedAttributes">
                      <a class="dropdown-item" (click)="selectType(i,item.Name)">{{ getPluralizedName(item.Name,
                        selectedQuantity[i]) }}</a>

                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="p.displayQuantityComponent" class="d-flex align-items-center" id="quantity-custom-amount">
                <input [(ngModel)]="p.quantityByComponent" type="number" class="form-control input-quantity"
                  id="quantity-product-input" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                  aria-describedby="emailHelp" [max]="p?.MaxToPurchase" [min]="1">
                <button type="submit" class="btn btn-outline-secondary ms-1" (click)="openQuantityComponent(i,false)"><i
                    class="bi bi-plus-lg"></i></button>
              </div>

            </div>
          </div>
        </div>
        <hr class="my-0">
      </div>

    </div>


  </div>

  <div class="text-center p-4" *ngIf="productsCart.length > 0">
    <div class="d-flex justify-content-center">
      <h3 class="me-2 fw-medium">Total</h3>
      <h3 class="fw-medium">{{totalCart | mycurrency}} i.v.a.i.</h3>
    </div>
    <div class="d-grid mb-2">
      <a>
        <button class="btn btn-primary" (click)="goToCheckoutCart()" data-bs-dismiss="offcanvas"
          aria-label="Close">Realizar compra</button>
      </a>
    </div>
    <a class="fw-medium text-decoration-none" type="button" data-bs-target="#deleteModal"
      (click)="showModalDelete()">
      Vaciar carrito
    </a>
  </div>

</div>
<app-modal-delete-cart #modalDelete [showModal]="showDelete" (closeModal)="closeModalDelete($event)" [cartId]="cartId"
  [modalData]="modalDeleteHeader"></app-modal-delete-cart>
<!--Fin componente header-->
