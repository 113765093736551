<main class="main">
  <div class="container mt-3 mt-lg-5">
    <h1 *ngIf="this.products.length > 0" class="color-primary">{{this.categoryName}}</h1>

    <div class="my-3" *ngIf="this.showFilters && this.products.length > 0 && subCategories.length > 0 ">
      <button class="btn btn-category dropdown-toggle border-color" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        Subcategorías
      </button>
      <ul class="dropdown-menu px-4">
        <li class="list-group-item pb-2">
          <input [(ngModel)]="allSelected" (click)="getProducts(this.principalCategoryID,true,0,true)"
            class="form-check-input me-1" type="checkbox" value="" id="checkboxAll">
          <label class="form-check-label blog-categories" for="secondCheckbox">Todas</label>

        </li>
        <li class="list-group-item pb-2" *ngFor=" let s of subCategories; let i = index">
          <input [(ngModel)]="s.isSelected" (click)="getProducts(s.IDProductCategory,true,i,false)"
            class="form-check-input me-1" type="checkbox" value="" id="checkbox{{i}}">
          <label class="form-check-label blog-categories" for="checkbox{{i}}"> {{s.Name}}
          </label>
        </li>

      </ul>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-4">
          <div class="col" *ngFor="let p of productsV3; let i = index">
            <div id="productId{{i+''+ p.SKU}}" *ngIf="p.IsAvailable" class="card card-costum-shadow h-100 px-4">
              <div class="product-card-content">
                <div class="img-container-item">
                  <a class="nav cursor-pointer" (click)="goToDetailProduct(p.URL,i,p.SKU,p.Categories)">
                    <img *ngIf="p.ProductImages.length > 0" alt="…" [src]="p.ProductImages[0].WebPath">
                    <img *ngIf="p.ProductImages.length == 0" alt="…" src="https://app.farmacialabomba.com/Images/Upload/1/1/fcaa46e527f0424aplaceholderb_400.png">
                  </a>
                </div>
                <span class="badge discount-badge discount-animation" *ngIf="selectedPromotion[i]!='' && p.ShowDiscount">
                  {{selectedPromotion[i]}}
                </span>
              </div>
              <div>
                <a   class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL,i,p.SKU,p.Categories)">
                <h3 class="mb-0 title-card-product">{{p.Name}}</h3>
              </a>
              </div>

              <div class="d-flex align-items-end justify-content-start py-3"
                *ngIf="p.ProductAttributes && p.ProductAttributes.length > 0">

                <a  class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL,i,p.SKU,p.Categories)">
                <span class="product-price text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades')  && p.ProductAttributes[0].Name === 'Unidad'">
                  {{ selectedUnitPrice[i] | mycurrency  }}  i.v.a.i
                </span>
                <span class="product-price text-nowrap" *ngIf="(selectedUnits[i] === 'Caja'  || selectedUnits[i] === 'Cajas') && p.ProductAttributes">
                  {{ selectedUnitPrice[i] | mycurrency }}  i.v.a.i
                </span>
                <!-- Producto con descuento -->
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.ProductAttributes[0].PriceWithDiscount > 0">
                  {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                </span>
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes.length > 1 && p.ProductAttributes[1].PriceWithDiscount > 0">
                  {{p.ProductAttributes[1].PriceWithTaxes * selectedQuantity[i]| mycurrency }} i.v.a.i
                </span>
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes[0].Name === 'Caja' && p.ProductAttributes[0].PriceWithDiscount > 0">
                  {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                </span>
              </a>
              </div>

              <div class="d-flex align-items-end justify-content-start py-3" *ngIf=" p.ProductAttributes.length  == 0">
                <a   class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL,i,p.SKU,p.Categories)">
                <span class="product-price text-nowrap" *ngIf="selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades' ">
                  {{ selectedUnitPrice[i] | mycurrency  }}  i.v.a.i
                </span>

                <!-- Producto con descuento -->
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.PriceWithDiscount > 0">
                  {{ p.PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                </span>

                <span class="product-price text-nowrap" *ngIf="selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas' ">
                  {{ selectedUnitPrice[i] | mycurrency  }}  i.v.a.i
                </span>

                <!-- Producto con descuento -->
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.PriceWithDiscount > 0">
                  {{ p.PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                </span>
</a>
              </div>





              <div   class="product-card-footer d-flex justify-content-start">
                <div  *ngIf="!p.displayQuantityComponent && !p.NotForSale" class="d-flex align-items-center me-2">

                  <div class="d-flex align-items-center me-1">
                    <div class="nav-item dropup">
                      <button class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        {{ selectedQuantity[i] }}
                      </button>


                        <ul  class="dropdown-menu number-dropdown">

                        <li *ngFor="let quantity of p.numbers">
                          <a class="dropdown-item" (click)="selectQuantity(i,quantity)" > {{quantity}} </a>
                        </li>

                        <hr *ngIf="this.p.MaxToPurchase > 9"  class="my-0">
                        <li *ngIf="this.p.MaxToPurchase > 10" (click)="openQuantityComponent(i,true)"><a class="dropdown-item" >10+ </a></li>

                      </ul>



                    </div>
                  </div>

                  <div class="d-flex align-items-center me-1">
                    <div class="nav-item dropup">



                      <button *ngIf="p.ProductAttributes.length > 1"
                      class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false"
                      [ngClass]="{'btn-outline-secondary': true, 'dropdown-toggle': true, 'dropdown-product': true}">
                      {{ selectedUnits[i] }}
                    </button>

                    <button *ngIf="p.ProductAttributes.length == 1 || p.ProductAttributes.length == 0 "
                    class="btn btn-outline-secondary   dropdown-product" type="button"
                     aria-expanded="false"
                    >
                    {{ selectedUnits[i] }}
                  </button>


                    <ul   class="dropdown-menu option-dropdown">
                      <li *ngFor="let item of p.ProductAttributes">
                       <a class="dropdown-item" (click)="selectType(i,item.Name)" >{{ getPluralizedName(item.Name, selectedQuantity[i]) }}</a>

                       </li>
                 </ul>

                    </div>
                  </div>

                     <div>

                            <button  aria-label="Agregar al carrito" type="button" class="btn btn-primary product-icon"
                                data-bs-toggle="tooltip" data-bs-placement="top"
                                data-bs-title="Agregar al carrito" (click)="addProductToCart(p, selectedUnits[i], selectedQuantity[i], selectedUnitPrice[i])"
                                *ngIf="!p.IsRecipeRequired"><i
                                    class="bi bi-cart-plus-fill"></i>
                            </button>

                    </div>
                </div>

                <div  *ngIf="p.displayQuantityComponent" class="d-flex align-items-center" id="quantity-custom-amount">


                  <input  [(ngModel)]="p.quantityByComponent" type="number" class="form-control input-quantity" id="quantity-product-input"
                  onkeydown="javascript: return event.keyCode == 69 ? false : true"  aria-describedby="emailHelp" [max]="p?.MaxToPurchase" [min]="1">

                  <button (click)="openQuantityComponent(i,false)" type="submit" class="btn btn-outline-secondary ms-1" ><i
                          class="bi bi-plus-lg"></i></button>


                </div>

                  </div>




                </div>

              </div>

              </div>
            </div>
          </div>

    <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="countsProducts < products.length">
      <button class="btn btn-primary" (click)="loadProducts()">Cargar más</button>
    </div>


  <h1 *ngIf="this.notResults" class="color-primary">{{this.categoryName}}</h1>
  <div class="my-3" *ngIf="subCategories.length > 0">
    <button class="btn btn-category dropdown-toggle border-color" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      Subcategorías
    </button>
    <ul class="dropdown-menu px-4">
      <li class="list-group-item pb-2">
        <input [(ngModel)]="allSelected" (click)="getProducts(this.principalCategoryID,true,0,true)"
          class="form-check-input me-1" type="checkbox" value="" id="checkboxAll">
        <label class="form-check-label blog-categories" for="secondCheckbox">Todas</label>

      </li>
      <li class="list-group-item pb-2" *ngFor=" let s of subCategories; let i = index">
        <input [(ngModel)]="s.isSelected" (click)="getProducts(s.IDProductCategory,true,i,false)"
          class="form-check-input me-1" type="checkbox" value="" id="checkbox{{i}}">
        <label class="form-check-label blog-categories" for="checkbox{{i}}"> {{s.Name}}
        </label>
      </li>

    </ul>
  </div>

  <div class="text-center mt-5" *ngIf="products.length === 0 && hasSearched">
    <i class="bi bi-search icon-page"></i>
    <h1 class="">Sin resultados</h1>
    <p class="">No se encontraron productos.</p>
  </div>
</div>
</main>
