import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model';
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreRequest } from 'src/app/models/requests/request.model';
import {
  CoreResponse,
  Response,
  ResponseInterface,
} from 'src/app/models/responses/response.model';
import {
  Clients,
  SignIn,
  ResponseSignIn,
  ResponseSignUp,
  InfoSignUp,
  Client,
  ClientAddress,
  MapPoints,
  Phone,
  ReducedClientRequest,
  ReducedClientResponse,
  ClientObjectGlobalResponse,
  ElectoralRollPersonalDataResponse,
  GetInfoClientResponse,
  ClientPersonalInfo,
  RequestFavoriteProduct,
} from 'src/app/models/core/clients.model';

import {
  HighlightedProducts,
  ProductImage,
  Products,
  Request_productsBySkus,
  ReducedExchange,
  ExchangeReducedImage,
  ResponseHighlightedProducts,
  ResponseProducts,
  ResponseCategoriesHeader,
  Category,
  SearchRequest,
} from 'src/app/models/core/products.model';
import { ProductsService } from 'src/app/services/core/products.service';

import { parse } from 'date-fns';
import {
  FavoriteProduct,
  SelectedAttribute,
  FavoriteProductResponse,
  RemoveFavoriteProduct,
  FavoriteProductsResponse,
} from 'src/app/models/core/favorite-products.model';
import { CEDReminder } from 'src/app/models/core/reminder.model';

import {
  ResponseGenericArrayObject,
  ResponseGenericObject,
  ResponseGenericProducts,
} from 'src/app/models/core/products.model';
const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  constructor(
    private http: HttpClient,
    private MessagesService_: MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService,
    private products: ProductsService,
    private utilitiesService: UtilitiesServicesService
  ) {}

  private getMessages(
    code: string,
    category: string
  ): Observable<ResponseInterface> {
    return new Observable<Response>((observer) => {
      var messageCore: string[] = [];
      this.MessagesService_.GetMessagesByKey(code, category).subscribe(
        (data) => {
          var messageResponse = data.value[0];

          if (messageResponse == undefined) {
            messageCore.push(code);
            const responseServices: ResponseInterface = {
              message: 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
              alias: code + '-alias',
              status: true,
              code: code,
              messageCore: messageCore,
              buttonAcceptText: '',
              buttonCancelText: '',
              type: '',
              action: '',
              titleMessage: '',
              url: '',
              Messages:[]
            };
            observer.next(responseServices);
            observer.complete();
          } else {
            const responseServices: ResponseInterface = {
              message: messageResponse.message,
              alias: messageResponse.aliascode,
              status: true,
              code: messageResponse.code,
              messageCore: messageCore,
              buttonAcceptText: messageResponse.buttonAcceptText,
              buttonCancelText: messageResponse.buttonCancelText,
              type: messageResponse.type,
              action: messageResponse.action,
              titleMessage: messageResponse.titleMessage,
              url: messageResponse.url,
              Messages:[]
            };
            observer.next(responseServices);
            observer.complete();
          }
        },
        (error) => {
          const responseServices: ResponseInterface = {
            message:
              'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
            alias: 'getMessages-error-alias-01',
            status: false,
            code: 'getMessages-error-01',
            messageCore: messageCore,
            buttonAcceptText: '',
            buttonCancelText: '',
            type: '',
            action: '',
            titleMessage: 'falta agregar el código',
            url: '',
            Messages:[]
          };
          observer.error(responseServices);
          observer.complete();
        }
      );
    });
  }

  private GetReminderTypesServices(): Observable<any> {
    const url =
      settings.BusDeServiciosUrlDev +
      'Recordatorios.svc/rest/ConsultarRecordatorioMedios';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, '', { headers });
  }

  public GetReminderTypesMethod(): Observable<ResponseGenericArrayObject> {
    return this.GetReminderTypesServices().pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);

        if (coreResponse.Ok) {
          const type: any[] = [];

          return this.getMessages('GetReminderTypesMethod-success', '1').pipe(
            map((data: any) => ({
              object: coreResponse.Listado.Medios,
              response: data,
            }))
          );
        } else {
          return this.getMessages('fail-type-methods', '1').pipe(
            map((data: any) => ({
              object: [],
              response: data,
            }))
          );
        }
      })
    );
  }

  private CEDProductReminderServices(object: CEDReminder): Observable<any> {
    const url =
      settings.BusDeServiciosUrlDev +
      'Recordatorios.svc/rest/GuardarRecordatorioClienteArticulo';
    // const headers = new HttpHeaders({  'Access-Control-Allow-Origin': '*' });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var json = JSON.stringify(object);
    return this.http.post(url, json, { headers });
  }

  public CEDProductReminderMethod(
    object: CEDReminder
  ): Observable<ResponseGenericArrayObject> {
    return this.CEDProductReminderServices(object).pipe(
      switchMap((responseCore: any) => {
        var newCode = '';
        if (responseCore.Ok) {
          switch (object.Accion) {
            case 'I':
              if(object.isEditMode){
                newCode = 'productReminderUpdated-OK';
              }
              else{
                newCode = 'productReminderCreated-OK';
              }
              break;
            case 'U':
              newCode = 'productReminderUpdated-OK';
              break;
            case 'D':
              newCode = 'productReminderDeleted-OK';
              break;
            default:
              newCode = 'CEDProductReminderMethod-success';
              break;
          }
        } else {
          switch (object.Accion) {
            case 'I':
              if(object.isEditMode){
                newCode = 'productReminderFail-02';
              }
              else{
                newCode = 'productReminderFail-01';
              }
              break;
            case 'U':
              newCode = 'productReminderFail-02';
              break;
            case 'D':
              newCode = 'productReminderFail-03';
              break;
            default:
              newCode = 'CEDProductReminderMethod-fail';
              break;
          }
        }
        return this.getMessages(newCode, '1').pipe(
          map((data: any) => ({
            object: responseCore,
            response: data,
          }))
        );
      })
    );
  }

  public GetProductRemindersByUserMethod(
    idCliente: string,
    sucursal: string
  ): Observable<any> {
    const url =
      settings.BusDeServiciosUrlDev +
      'Recordatorios.svc/rest/ConsultarRecordatorioCliente';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const body = {
      IDCliente: idCliente,
      Sucursal: sucursal,
    };

    const jsonData = JSON.stringify(body);

    return this.http.post(url, jsonData, { headers });
  }
}
