import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { InfoSignUp } from 'src/app/models/core/clients.model';
import { IModal } from 'src/app/models/modal.model';
import { IdType } from 'src/app/models/settings/idType.interface';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { LegalDocuments } from 'src/app/models/sitefinity/type-of-documents.model';
import { ClientsService } from 'src/app/services/core/clients.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { DocumentsService } from 'src/app/services/sitefinity/documents.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';

declare var window: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
  form: FormGroup;
  settings: SitefinitySettings = new SitefinitySettings();
  urlBase = this.settings.SitefinityServicesUrl;
  validationMsg = this.settings.validationMsgTypeMap;
  idTypes?: IdType[];
  typeNacional?: IdType;
  typePassport?: IdType;
  typeJuridica?: IdType;
  selectedType?: IdType;
  selectedNameMaxLegth?: number;
  isSubmited = false;
  isEditDisabled = true;

  _clientService: ClientsService;
  _documentService: DocumentsService;
  phoneMaxLength = 8;
  phoneMinLength = 8;
  //lastSearch
  idLastSearch = '';
  typeLastSearch = '';

  showPassword = false;
  termsReaded: boolean = false;
  validateId: boolean = false;
  invalidIdMsg = this.settings.validationMsgTypeMap['validId'];
  isFocusedId = false;

  @ViewChild('identificationInput') identificationInput?: ElementRef;
  documentConsent: LegalDocuments = new LegalDocuments();
  documentTerms?: LegalDocuments = new LegalDocuments();
  documentPolicy?: LegalDocuments = new LegalDocuments();
  showCI = false;
  modalCI: IModal = {
    ModalName: 'modalConsentimientoRegistro',
    BtnConfirmText: 'Acepto consentimiento informado y finalizo registro',
  };

  ModalGeneralSignUp: IModal = {
    ModalName: 'ModalGeneralSignUp',
    Title:'',
    Text:'',
    BtnConfirmText: '',
    ShowCancelBtn: false,
  }

  showModalGeneralSignUp:boolean = false;

  constructor(
    private fb: FormBuilder,
    private utilities: UtilitiesService,
    public loader: LoadingService,
    clientService: ClientsService,
    documentService: DocumentsService,
    public sitefinityPagesService: SitefinitypagesService,
    public router: Router,
    public notificationService: NotificationService
  ) {

    this._clientService = clientService;
    this._documentService = documentService;
    this.form = this.fb.group({});
    this.getDataService()
  }

  get identification() {
    return this.form.get('identification');
  }
  get typeidentification() {
    return this.form.get('typeidentification');
  }
  get name() {
    return this.form.get('name');
  }
  get firstLastName() {
    return this.form.get('firstLastName');
  }
  get secondLastName() {
    return this.form.get('secondLastName');
  }
  get phone() {
    return this.form.get('phone');
  }
  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }
  get isAdult() {
    return this.form.get('isAdult');
  }
  get validId() {
    return this.form.get('validId');
  }

  ngOnInit(): void {
    this.GetLegalDocument();
    this.sitefinityPagesService.setDetailPage(
      this.settings.pages.find((x) => x.Title === 'registro')!.ID
    );
  }

  Submit() {
    this.isSubmited = true;
    if (this.form.invalid) {
    } else {
      //mostrar consentimiento informado
      this.showCI = true;
    }
  }

  IdTypeChanged(evt: any) {
    this.selectedType = this.idTypes!.filter(
      (x) => x.id == evt.srcElement.value
    )[0];

    this.form.controls['identification'].setValidators([
      Validators.required,
      Validators.minLength(+this.selectedType.minLength),
      Validators.maxLength(+this.selectedType.maxLength),
      Validators.pattern(
        this.settings.regexPatternsTypeMap[this.selectedType.idPatternName]
      ),
    ]);
    this.form.controls['name'].setValidators([
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(+this.selectedType.nameMaxLength),
      Validators.pattern(
        this.settings.regexPatternsTypeMap[this.selectedType.namePatternName]
      ),
    ]);
    this.selectedNameMaxLegth = +this.selectedType.nameMaxLength;

    this.ResetControls([
      'identification',
      'name',
      'firstLastName',
      'secondLastName',
      'phone',
      'email',
      'password',
      'isAdult',
    ]);

    this.form.controls['name'].updateValueAndValidity();
    this.form.controls['identification'].updateValueAndValidity();

    this.searchId();
  }

  private ResetControls(controlsNames: string[], enable = true) {
    if (controlsNames.length == 1 && controlsNames[0] == 'all') {
      controlsNames = Object.keys(this.form.controls);
      this.isSubmited = false;
    }
    controlsNames.forEach((controlName) => {
      if (this.form.controls[controlName] != undefined) {
        // if (controlName == 'isAdult' || controlName == 'validId') {
        //   this.form.controls[controlName].setValue('false');
        // } else {
        //   this.form.controls[controlName].setValue('');
        // }

        this.form.controls[controlName].setValue('');
        this.form.controls[controlName].markAsUntouched();
        if (enable) {
          this.form.controls[controlName].enable();
        }
      }
    });
    this.isSubmited = false;
  }

  resetHiddenValues() {
    this.form.controls['birthday'].setValue('');
    this.form.controls['codeElec'].setValue(101002);
    this.form.controls['idCanton'].setValue(3);
    this.form.controls['idDistrict'].setValue(16);
    this.form.controls['idProvince'].setValue(1);
  }

  searchId() {
    this.isFocusedId=false;
    if (this.form.controls['identification'].valid) {
      if (
        this.selectedType!.id != this.typeLastSearch ||
        this.form.controls['identification'].value != this.idLastSearch
      ) {
        this.SetLastSearch(
          this.selectedType!.id,
          this.form.controls['identification'].value
        );
        let resetControls = true;
        this.resetHiddenValues();
        this.form.controls['validId'].setValue(true);
        this.loader.showSpinner();
        this._clientService
          .GetInfoClient(
            this.form.controls['identification'].value,
            this.selectedType!.id,
            true //sendEmail
          )
          .subscribe(
            (o) => {
              let tempIsValidId = false;
              switch (o.response.code) {
                case 'GetInfoClient-success': //registrar normalmente
                  if (o.clients != null) {
                    this.form.controls['name'].setValue(o.clients?.name);
                    this.form.controls['firstLastName'].setValue(
                      o.clients?.lastName
                    );
                    this.form.controls['secondLastName'].setValue(
                      o.clients?.secondSurname
                    );

                    this.form.controls['codeElec'].setValue(
                      o.clients?.codeELEC
                    );
                    this.form.controls['idCanton'].setValue(
                      o.clients?.idCanton
                    );
                    this.form.controls['idDistrict'].setValue(
                      o.clients?.idDistrict
                    );
                    this.form.controls['idProvince'].setValue(
                      o.clients?.idProvince
                    );
                    if (
                      o.clients?.birthday != undefined &&
                      o.clients?.birthday != ''
                    ) {
                      this.form.controls['birthday'].setValue(
                        o.clients?.birthday
                      );
                    }
                    this.isEditDisabled = true;
                    resetControls = false;
                    this.ResetControls(
                      ['phone', 'email', 'password', 'isAdult'],
                      true
                    );
                  }
                  tempIsValidId = true;
                  break;
                case 'digitalUser-SSO-success': //El usuario sí existe y se debe mostrar un mensaje de que inicie sesión
                case 'digitalUser-SSO-password-success': //El usuario sí existe pero no es digital.
                case 'Padron-Fail-01': //id no registrada, no esta en el padron
                case 'SSO-Fail-01': // error consultando el SSO.
                  this.invalidIdMsg= o.response.titleMessage
                  if(o.response.buttonAcceptText != '')
                    {
                      this.showModalGeneralSignUp = false;
                      this.ModalGeneralSignUp.Title = o.response.titleMessage;
                      this.ModalGeneralSignUp.Text =  o.response.message;
                      this.ModalGeneralSignUp.BtnConfirmText = o.response.buttonAcceptText;
                      this.ModalGeneralSignUp.UrlBtnConfirm = o.response.url;
                      this.showModalGeneralSignUp = true;

                    }
                    else
                    {

                      this.notificationService.SendNotification(
                        o.response.type,
                        o.response.titleMessage,
                        o.response.message
                      );

                    }

                  break;
                default:
                  this.invalidIdMsg = (o.response.titleMessage && (o.response.titleMessage.trim() != '' && o.response.titleMessage.toLowerCase() != '"falta agregar el código"' )) ? o.response.titleMessage.trim() : this.settings.validationMsgTypeMap['validId'];
                  if(o.response.buttonAcceptText != '')
                    {
                      this.showModalGeneralSignUp = false;
                      this.ModalGeneralSignUp.Title = o.response.titleMessage;
                      this.ModalGeneralSignUp.Text =  o.response.message;
                      this.ModalGeneralSignUp.BtnConfirmText = o.response.buttonAcceptText;
                      this.ModalGeneralSignUp.UrlBtnConfirm = o.response.url;
                      this.showModalGeneralSignUp = true;

                    }
                    else
                    {

                      this.notificationService.SendNotification(
                        o.response.type,
                        o.response.titleMessage,
                        o.response.message
                      );

                    }

                  break;
              }
              if (resetControls) {
                this.ResetControls(
                  [
                    'name',
                    'firstLastName',
                    'secondLastName',
                    'phone',
                    'email',
                    'password',
                    'isAdult',
                  ],
                  false
                );
                //this.SetLastSearch('', '');
              }

              this.loader.hideSpinner();
              this.validateId = true;
              this.form.controls['validId'].setValue(tempIsValidId);

              this.CheckEnable();
            },
            (error) => {
              this.validateId = true;
              this.loader.hideSpinner();
              this.notificationService.SendNotification(
                'error',
                'Error',
                'Error al buscar por identificación'
              );
            }
          );
      }
      else{
        this.CheckEnable()
      }
    } else {
      this.DisableEdition();
    }
  }

  CheckEnable() {
    if (this.form.controls['validId'].value == true) {
      if (this.selectedType == this.typeNacional) {
        this.EnableEdition();
        this.form.controls['name'].disable();
        this.form.controls['firstLastName'].disable();
        this.form.controls['secondLastName'].disable();
      } else if (this.selectedType == this.typeJuridica) {
        this.EnableEdition();
        this.form.controls['firstLastName'].disable();
        this.form.controls['secondLastName'].disable();
      } else {
        this.EnableEdition();
      }
    } else {
      this.DisableEdition();
    }
  }

  SetLastSearch(typeLastSearch: string, idLastSearch: string) {
    this.typeLastSearch = typeLastSearch;
    this.idLastSearch = idLastSearch;
  }

  DisableEdition() {
    this.form.controls['name'].disable();
    this.form.controls['firstLastName'].disable();
    this.form.controls['secondLastName'].disable();
    this.form.controls['email'].disable();
    this.form.controls['phone'].disable();
    this.form.controls['password'].disable();
    this.form.controls['isAdult'].disable();
  }

  EnableEdition() {
    this.form.controls['name'].enable();
    this.form.controls['firstLastName'].enable();
    this.form.controls['secondLastName'].enable();
    this.form.controls['email'].enable();
    this.form.controls['phone'].enable();
    this.form.controls['password'].enable();
    this.form.controls['isAdult'].enable();
  }

  getSingupObject(): InfoSignUp {
    if (this.form.controls['birthday'].value == '') {
      this.form.controls['birthday'].setValue('2000-01-01T00:00:00.000Z');
    }
    var data: InfoSignUp = {
      identification: this.form.controls['identification'].value.trim(),
      name: this.form.controls['name'].value.trim(),
      firstLastName: this.form.controls['firstLastName'].value.trim(),
      secondLastName: this.form.controls['secondLastName'].value.trim(),
      typeidentification: this.form.controls['typeidentification'].value,
      phone: this.form.controls['phone'].value.trim(),
      email: this.form.controls['email'].value.trim(),
      password: this.form.controls['password'].value,
      idPhone: this.form.controls['idPhone'].value,
      loyaltyCard: this.form.controls['loyaltyCard'].value,
      iAmOfAge: this.form.controls['isAdult'].value,

      idProvince:
        this.form.controls['idProvince'].value != undefined &&
        this.form.controls['idProvince'].value != 0
          ? this.form.controls['idProvince'].value
          : 1,
      idCanton:
        this.form.controls['idCanton'].value != undefined &&
        this.form.controls['idCanton'].value != 0
          ? this.form.controls['idCanton'].value
          : 3,
      idDistrict:
        this.form.controls['idDistrict'].value != undefined &&
        this.form.controls['idDistrict'].value != 0
          ? this.form.controls['idDistrict'].value
          : 16,
      codeElec:
        this.form.controls['codeElec'].value != undefined &&
        this.form.controls['codeElec'].value != 0
          ? this.form.controls['codeElec'].value
          : 101002,

      birthday: this.form.controls['birthday'].value,
      MapPoints: {
        Latitude: 1,
        Longitude: 1,
      },
    };

    var aux = data.phone;
    data.phone = [aux.slice(0, 4), '-', aux.slice(4)].join('');
    return data;
  }

  /**
   * Acepta el formulario de consentimiento informado.
   */
  acceptInformedConsent(acept: boolean) {
    this.showCI = false;
    if (acept) {
      this.loader.showSpinner();
      this.ConfirmTerms();
    }
  }

  ConfirmTerms() {
    if (this.form.valid) {
      //Verificar que la dirección de correo electrónico no esté duplicada.
      this.loader.showSpinner();
      this._clientService.SignUpMethod(this.getSingupObject()).subscribe({
        next: async (response) => {
          if (response != undefined) {
            if (response.response.code != undefined) {
              switch (response.response.code) {
                case 'signup-success':
                  this.ResetControls(['all']);
                  this.notificationService.SendNotification(response.response.type, response.response.titleMessage, response.response.message);
                  setTimeout(() => {
                    this.router.navigate(['/iniciosesion']);
                  }, 5000);
                  break;
                case 'VALCLI0034':
                case 'VALCLI0051':
                  if (response.response.buttonAcceptText != '') {
                      this.showModalGeneralSignUp = false;
                      this.ModalGeneralSignUp.Title = response.response.titleMessage;
                      this.ModalGeneralSignUp.Text =  response.response.message;
                      this.ModalGeneralSignUp.BtnConfirmText = response.response.buttonAcceptText;
                      this.ModalGeneralSignUp.UrlBtnConfirm = response.response.url;
                      this.showModalGeneralSignUp = true;
                    }
                    else {
                      this.notificationService.SendNotification(response.response.type, response.response.titleMessage, response.response.message);
                    }
                  break;
                case 'VALCLI0053':
                case 'ERR0001':
                  if (response.response.buttonAcceptText != '') {
                      this.showModalGeneralSignUp = false;
                      this.ModalGeneralSignUp.Title = response.response.titleMessage;
                      this.ModalGeneralSignUp.Text =  response.response.message;
                      this.ModalGeneralSignUp.BtnConfirmText = response.response.buttonAcceptText;
                      this.ModalGeneralSignUp.UrlBtnConfirm = response.response.url;
                      this.showModalGeneralSignUp = true;
                    }
                    else {
                      this.notificationService.SendNotification(response.response.type, response.response.titleMessage, response.response.message);
                    }
                  break;
                  default:
                    if (response.response.buttonAcceptText != '') {
                      this.showModalGeneralSignUp = false;
                      this.ModalGeneralSignUp.Title = response.response.titleMessage;
                      this.ModalGeneralSignUp.Text =  response.response.message;
                      this.ModalGeneralSignUp.BtnConfirmText = response.response.buttonAcceptText;
                      this.ModalGeneralSignUp.UrlBtnConfirm = response.response.url;
                      this.showModalGeneralSignUp = true;
                    }
                    else {
                      this.notificationService.SendNotification(response.response.type, response.response.titleMessage, response.response.message);
                    }
              }
            } else {
              this.notificationService.SendNotification('error', 'Error', 'Error inesperado');
            }
          } else {
            this.notificationService.SendNotification('error', 'Error', 'Error inesperado');
          }
          this.loader.hideSpinner();
        },
        error: (e) => {
          this.loader.hideSpinner();
          this.notificationService.SendNotification('error', 'Error', 'Error no controlado');
        },
      });
    }
  }

  private GetLegalDocument() {
    //Informed consent
    this._documentService
      .GetLegalDocumentsByIdType(
        0,
        1,
        this.settings.legalDocumentTypeMap['Consentimiento Informado']
      )
      .subscribe(
        (ResponseLegalDocuments) => {
          if (ResponseLegalDocuments.response != undefined) {
            this.documentConsent = ResponseLegalDocuments.legalDocuments[0];

            if (
              !this.documentConsent ||
              this.documentConsent.documentText.trim() == ''
            ) {
              this.notificationService.SendNotificationByCode(
                'consent-info-error',
                '1'
              );
            }
          } else {
            this.notificationService.SendNotificationByCode(
              'consent-info-error',
              '1'
            );
          }
        },
        (error) => {
          this.notificationService.SendNotificationByCode(
            'consent-info-error',
            '1'
          );
        }
      );
    //policies
    this._documentService
      .GetLegalDocumentsByIdType(
        0,
        1,
        this.settings.legalDocumentTypeMap['Políticas de privacidad']
      )
      .subscribe(
        (ResponseLegalDocuments) => {
          if (ResponseLegalDocuments.response != undefined) {
            this.documentPolicy = ResponseLegalDocuments.legalDocuments[0];
            if (this.documentPolicy == undefined) {
              this.documentPolicy = new LegalDocuments();
            }
            if (
              this.documentPolicy.url == undefined ||
              this.documentPolicy.url == ''
            ) {
              this.documentPolicy.url = 'informacion-legal';
            }
          } else {
          }
        },
        (error) => {
        }
      );
    //terms and conditions
    this._documentService
      .GetLegalDocumentsByIdType(
        0,
        1,
        this.settings.legalDocumentTypeMap['Términos y condiciones']
      )
      .subscribe(
        (ResponseLegalDocuments) => {
          if (ResponseLegalDocuments.response != undefined) {
            this.documentTerms = ResponseLegalDocuments.legalDocuments[0];
            if (this.documentTerms == undefined) {
              this.documentTerms = new LegalDocuments();
            }
            if (
              this.documentTerms.url == undefined ||
              this.documentTerms.url == ''
            ) {
              this.documentTerms.url = 'informacion-legal';
            }
          } else {
          }
        },
        (error) => {
        }
      );
  }

  //#region VALIDACION DE DATOS
  keyPressIdentification(event: any) {
    var rta = false;
    if (this.selectedType != undefined) {
      switch (this.selectedType.name) {
        case 'nacional':
        case 'didi':
        case 'nite':
        case 'dimex':
        case 'juridica':
          var charCode = event.which ? event.which : event.keyCode;
          // Only Numbers 0-9
          if (
            charCode == 101 ||
            charCode == 69 ||
            charCode == 45 ||
            charCode == 43 ||
            charCode == 46
          ) {
            event.preventDefault();
            rta = false;
          } else if (
            charCode < 48 ||
            charCode > 57 //numbers
          ) {
            event.preventDefault();
            rta = false;
          } else {
            rta = true;
          }
          break;

        default:
          rta = true;
      }
    }
    return rta;
  }

  keyPressPhone(event: any) {
    var rta = false;
    var strPhoneValue: string = '' + this.form.controls['phone'].value;
    if (strPhoneValue.length >= this.phoneMaxLength) {
      event.preventDefault();
      rta = false;
    } else {
      var charCode = event.which ? event.which : event.keyCode;
      // Only Numbers 0-9
      if (
        charCode == 101 ||
        charCode == 69 ||
        charCode == 45 ||
        charCode == 43 ||
        charCode == 46
      ) {
        event.preventDefault();
        rta = false;
      } else if (
        charCode < 48 ||
        charCode > 57 //numbers
      ) {
        event.preventDefault();
        rta = false;
      } else {
        rta = true;
      }
    }
    return rta;
  }
  //#endregion

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
    /**
   * Obtiene datos del servicio de datos.
   */
    getDataService() {
      forkJoin({
        // Solicitar parámetros por clave
        requestIdentificationTypes: this.utilities.GetParametersByKey('identificationTypes'),
        //requestThree: this.utilities.GetParametersByKey('ConsentimientoInformadoParameters')
      }).subscribe(rqs => {
        if (rqs.requestIdentificationTypes.value) {
          // Analizar la lista de asuntos de la respuesta
          const data: IdType[] = JSON.parse(this.utilities.decodeData(rqs.requestIdentificationTypes.value[0].value));
          // Ordenar la lista de asuntos por prioridad
          this.idTypes = data.sort( (x, y) => +x.sortId - +y.sortId);
          // this.idTypes= this.settings.idTypes.sort((x, y) => +x.sortId - +y.sortId);
          this.typeNacional = this.idTypes.find((x) => x.name == 'nacional')!;
          this.typePassport = this.idTypes.find((x) => x.name == 'pasaporte')!;
          this.typeJuridica = this.idTypes.find((x) => x.name == 'juridica')!;
          this.selectedType = this.idTypes.find((x) => x.name == 'nacional')!;

          this.form = this.fb.group({
            identification: [
              '',
              [
                Validators.required,
                Validators.minLength(+this.selectedType.minLength),
                Validators.maxLength(+this.selectedType.maxLength),
                Validators.pattern(
                  this.settings.regexPatternsTypeMap[this.selectedType.idPatternName]
                ),
              ],
            ],
            typeidentification: [''],
            name: [
              { value: '', disabled: true },
              [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(+this.selectedType.nameMaxLength),
                Validators.pattern(
                  this.settings.regexPatternsTypeMap[
                    this.selectedType.namePatternName
                  ]
                ),
              ],
            ],
            firstLastName: [
              { value: '', disabled: true },
              [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(30),
                Validators.pattern(
                  this.settings.regexPatternsTypeMap['lettersSpanish']
                ),
              ],
            ],
            secondLastName: [
              { value: '', disabled: true },
              [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(30),
                Validators.pattern(
                  this.settings.regexPatternsTypeMap['lettersSpanish']
                ),
              ],
            ],
            phone: [
              { value: '', disabled: true },
              [
                Validators.required,
                Validators.minLength(this.phoneMinLength),
                Validators.maxLength(this.phoneMaxLength),
                Validators.pattern(this.settings.regexPatternsTypeMap['number']),
              ],
            ],
            email: [
              { value: '', disabled: true },
              [
                Validators.required,
                Validators.minLength(7),
                Validators.maxLength(50),
                Validators.pattern(this.settings.regexPatternsTypeMap['email']),
              ],
            ],
            password: [
              { value: '', disabled: true },
              [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(30),
                Validators.pattern(this.settings.regexPatternsTypeMap['password']),
              ],
            ],
            isAdult: [{ value: '', disabled: true }, Validators.requiredTrue],

            validId: [false, Validators.requiredTrue],

            idPhone: '',
            loyaltyCard: '',
            idProvince: 1,
            idCanton: 3,
            idDistrict: 16,
            codeElec: 101002,
            birthday: '',
          });
          this.typeidentification?.setValue(this.selectedType.id);
        }

      });
    }


    closeModalSignUp(event: any, isButtonAccept?:boolean) {
      this.showModalGeneralSignUp = false;
      if(this.ModalGeneralSignUp.UrlBtnConfirm != '')
        {
          let x:any = this.ModalGeneralSignUp.UrlBtnConfirm
          this.loader.showSpinner();
          const parts: string[] = x.split('/');
          const lastPart:  string = "/"+ parts[parts.length - 1];
          this.router.navigate([lastPart]);
      }
    }
}
