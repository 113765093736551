<main class="main">
  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="color-primary">
      Preguntas frecuentes
    </h1>

    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item" *ngFor="let f of faqs; let i = index">
        <h2 class="accordion-header" id="heading{{i}}">
          <button class="accordion-button colored-accordion-button collapsed accordion-questions" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#flush-collapse' + i" aria-expanded="false"
            [attr.aria-controls]="'flush-collapse' + i" [innerHTML]="f.question">
          </button>
        </h2>
        <div [id]="'flush-collapse' + i" class="accordion-collapse collapse collapse" data-bs-parent="#accordionFlushExample">
          <div class="accordion-body accordion-faq accordion-faq" [innerHTML]="f.answer"></div>
        </div>
      </div>
    </div>

    <div class="text-center mt-5" *ngIf="faqs.length === 0 && showMessage">
      <i class="bi bi-search icon-page"></i>
      <h1 class="">Sin resultados</h1>
      <p class="">No se encontraron datos.</p>
    </div>
  </div>
</main>
