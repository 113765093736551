<form [formGroup]="form" (ngSubmit)="AddNotification()">
  <div class="modal fade modal-overlay-light" id="modalProductoFaltante" #modalProductoFaltante tabindex="-1" aria-labelledby="modalProductoFaltanteLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal-style">
        <div class="modal-header">
          <h2 class="modal-title fs-5 color-primary" id="modalProductoFaltanteLabel">Producto faltante</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
        </div>
        <div class="modal-body">
          <h3 class="mb-3">¿Querés que se te notifique por correo electrónico cuando el producto esté disponible?</h3>

          <div>
            <label for="">Seleccioná el tiempo máximo de espera para enviarte el correo</label>
            <select class="form-select" aria-label="Default select example" formControlName="time">
              <option value="" selected>Seleccioná una opción de tiempo</option>
              <option value="7">1 semana</option>
              <option value="14">2 semanas</option>
              <option value="21">3 semanas</option>
              <option value="28">4 semanas</option>
            </select>
          </div>
          <div *ngIf="time!.invalid && ( time!.touched || isSubmited )" class="d-block text-error">
            <span *ngIf="time!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <!-- <p class="text-footer-modal" *ngIf="error">Codigo de error: {{messageError}}</p> -->
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="close()">Cancelar</button>
          <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="form.invalid">Notificar</button>
        </div>
      </div>
    </div>
  </div>
</form>
