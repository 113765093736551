import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import { HighlightedProducts,ProductImage,Products,Request_productsBySkus,ReducedExchange,
  ExchangeReducedImage,ResponseHighlightedProducts, ResponseProducts,ResponseCategoriesHeader,Category,SearchRequest, SelectedAttributes,ResponseGenericProducts,ResponseGenericObject,RecentSearchRequest, ProductAttribute, ProductAttributeCategory }
  from 'src/app/models/core/products.model';
import { IfStmt } from '@angular/compiler';
import { ObjectGlobalResponse} from 'src/app/models/core/utilities.model';
import { CommentByProduct,Molecule,MoleculeAttribute, SearchProductRequest }
from 'src/app/models/core/merge-n.model';
import { CoreRequest } from 'src/app/models/requests/request.model';
import { ObjectListGlobalResponse } from 'src/app/models/core/utilities.model';
import { environment } from 'src/environments/environment';

const settings = new SitefinitySettings();

var UrlGetProductBySearching = environment.SearchLink; // 'https://ss-prod-ecommerce-search.search.windows.net/indexes/labomba-productos/docs?api-version=2021-04-30-Preview&search={{word}}&$count=true&queryType=full&$top=10&queryLanguage=es-MX&$select=IdProduct&searchMode=all&&suggesterName=sg&fuzzy=true'
var UrlGetProductAutocomplete = environment.SearchAutocompleteLink;
var keyProductBySearching = 'DIa2sHL7QGZSeR7W7vNDagRHAJD6ZMRPtpWBxZLtfYAzSeA2Crfi'

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient,private MessagesService_ :MessagesService,private ErrorOrLogsServices:UtilitiesServicesService) { }



private GetCategoriesLandingByIDProductCategoryParentService(idCategory: number):Observable<any>{

  const url = settings.CoreServicesUrl + 'Product.svc/GetCategoriesLandingByIDProductCategoryParent/' + idCategory;

  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});

  return this.http.get(url,{ headers });

}


public GetCategoriesLandingByIDProductCategoryParentMethod(idCategory: number): Observable<ResponseProducts> {
  return new Observable<ResponseProducts>((observer) => {
    this.GetCategoriesLandingByIDProductCategoryParentService(idCategory).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);
          const products: Products[] = [];

          listResponse.forEach((DataItem: any) => {
            const productImages: ProductImage[] = [];
         DataItem.products.forEach((prod: any) => {
           prod.ProductImages.forEach((imagesDataItem: any) => {
              const imageResponse: ProductImage = {
                IDImageGroup: imagesDataItem.IDImageGroup,
                IDImageType: imagesDataItem.IDImageType,
                WebPath: imagesDataItem.WebPath
              };
              productImages.push(imageResponse);
            });

            const product: Products = {
              applyCoupon: false,
              basePrice: 0,
              categories: [],
              components: '',
              detail: '',
              dynamic: '',
              globalRate: 0,
              iDProduct: 0,
              iDProductBrand: 0,
              isAvailable: false,
              isAvailableToCustomize: false,
              isCurrentlyTimeAvailable: false,
              isCustomizable: false,
              isPromotion: false,
              isRecipeRequired: false,
              isRedeemable: false,
              isShopAvailable: false,
              largeDescription: '',
              maxAttributesNumber: 0,
              maxToPurchase: 0,
              mechanics: '',
              minAttributesNumber: 0,
              minToPurchase: 0,
              name: prod.Name,
              percentDiscountRate: 0,
              priceWithDiscount: prod.PriceWithDiscount,
              priceWithTaxes: 0,
              productAttributeCategories: [],
              productAttributes: [],
              productImages: productImages,
              productTypes: [],
              productsetApplies: false,
              promotionDescription: '',
              quantity: prod.Quantity,
              sku: prod.SKU,
              shortDescription: '',
              taxes: 0,
              url: prod.URL,
              votesQuantity: 0
            };

            products.push(product);
          });


          });

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseProducts = {
                products: products,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseProducts = {
                products: [],
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseProducts) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



private GetProductsBySkuListService(skuList: string[]):Observable<any>{
  const url = settings.CoreServicesUrl + 'Product.svc/GetAllActiveProductsAndAttributesbyListSku';
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
  return  this.http.post(url, skuList,{ headers });

}


public GetProductsBySkuListMethod(skuList: string[]): Observable<ResponseHighlightedProducts> {
  return this.GetProductsBySkuListService(skuList).pipe(
    switchMap((responseCore: any) => {
      const coreResponse = Object.assign(new CoreResponse(), responseCore);
      const code = coreResponse.Messages[0];
      if (code === "OK0001") {
        const parametersResponse: string = coreResponse.Parameters as string;
        let listResponse: any[] = JSON.parse(parametersResponse);
        listResponse= listResponse.filter((x)=> x != null)

        const highlightedProducts: HighlightedProducts[] = [];

        listResponse.forEach((DataItem: any) => {
          const productImages: ProductImage[] = [];
          DataItem.ProductImages.forEach((imagesDataItem: any) => {
            const imageResponse: ProductImage = {
              IDImageGroup: imagesDataItem.IDImageGroup,
              IDImageType: imagesDataItem.IDImageType,
              WebPath: imagesDataItem.WebPath
            };
            productImages.push(imageResponse);
          });

          const HighlightedProduct: HighlightedProducts = {
            name: DataItem.Name,
            priority: 0,
            url: DataItem.URL,
            quantity: DataItem.Quantity,
            sku: DataItem.SKU,
            price: DataItem.ProductAttributes[0].PriceWithTaxes,
            priceWithDiscount: DataItem.ProductAttributes[0].PriceWithDiscount,
            isAvailable: DataItem.IsAvailable,
            image: productImages
          };

          highlightedProducts.push(HighlightedProduct);
        });

        return this.getMessages(code,'2').pipe(
          map((data: any) => {
            const response: ResponseHighlightedProducts = {
              response: data,
              highlightedProducts: highlightedProducts
            };
            return response;
          })
        );
      } else {
        return throwError("Error occurred: " + code);
      }
    })
  );
}

public GetProductsBySkuListMethodOK(skuList: string[]): Observable<ResponseHighlightedProducts> {
  return this.GetProductsBySkuListService(skuList).pipe(
    switchMap((responseCore: any) => {
      const coreResponse = Object.assign(new CoreResponse(), responseCore);
      const code = coreResponse.Messages[0];
      const highlightedProducts: HighlightedProducts[] = [];
      if (code === "OK0001") {
        const parametersResponse: string = coreResponse.Parameters as string;
        let listResponse: any[] = JSON.parse(parametersResponse);
        listResponse = listResponse.filter((x)=> x != null)


        listResponse.forEach((DataItem: any) => {
          const productImages: ProductImage[] = [];
          DataItem.ProductImages.forEach((imagesDataItem: any) => {
            const imageResponse: ProductImage = {
              IDImageGroup: imagesDataItem.IDImageGroup,
              IDImageType: imagesDataItem.IDImageType,
              WebPath: imagesDataItem.WebPath
            };
            productImages.push(imageResponse);
          });

          const HighlightedProduct: HighlightedProducts = {
            name: DataItem.Name,
            priority: 0,
            url: DataItem.URL,
            quantity: DataItem.Quantity,
            sku: DataItem.SKU,
            price: DataItem.ProductAttributes[0].PriceWithTaxes,
            priceWithDiscount: DataItem.ProductAttributes[0].PriceWithDiscount,
            isAvailable: DataItem.IsAvailable,
            image: productImages
          };

          highlightedProducts.push(HighlightedProduct);
        });

        return this.getMessages(code,'2').pipe(
          map((data: any) => {
            const response: ResponseHighlightedProducts = {
              response: data,
              highlightedProducts: highlightedProducts
            };
            return response;
          })
        );
      }
      else if(code === 'INF0001'){

        return this.getMessages(code,'2').pipe(
          map((data: any) => {
            const response: ResponseHighlightedProducts = {
              response: data,
              highlightedProducts: highlightedProducts
            };
            return response;
          })
        );

      }
      else {
        return throwError("Error occurred: " + code);
      }
    })
  );
}


public getMessages(code: string,category: string): Observable<ResponseInterface> {

  return new Observable<Response>((observer) => {
       var messageCore: string[]= [];
      this.MessagesService_.GetMessagesByKey(code,category).subscribe((data) => {
      var messageResponse = data.value[0];

          if (messageResponse == undefined) {


            messageCore.push(code);
            const responseServices: ResponseInterface = {
                message : 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
                alias : code + '-alias',
                status : true,
                code: code,
                messageCore: messageCore,
                buttonAcceptText:'',
                buttonCancelText: '',
                type:'',
                action:'',
                titleMessage: '',
                url: '',
                Messages:[]
               };
               observer.next(responseServices);
               observer.complete();

          }
          else
          {
            const responseServices: ResponseInterface = {
                message : messageResponse.message,
                alias : messageResponse.aliascode,
                status : true,
                code:messageResponse.code,
                messageCore: messageCore,
                buttonAcceptText:messageResponse.buttonAcceptText,
                buttonCancelText: messageResponse.buttonCancelText,
                type:messageResponse.type,
                action:messageResponse.action,
                titleMessage: messageResponse.titleMessage,
                url: messageResponse.url,
                Messages:[]
            }
            observer.next(responseServices);
            observer.complete();
          }
    },(error) =>{

      const responseServices: ResponseInterface = {
        message : 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
        alias : 'getMessages-error-alias-01',
        status : false,
        code:'getMessages-error-01',
        messageCore: messageCore,
        buttonAcceptText:'',
        buttonCancelText: '',
        type:'',
        action:'',
        titleMessage: "falta agregar el código",
        url:'',
        Messages:[]
    };
    observer.error(responseServices);
    observer.complete();

    });

});


}



private GetCategoriesHeaderService(idCategory: number):Observable<any>{

  const url = settings.CoreServicesUrl + 'Product.svc/getActiveCategoriesByIDProductCategoryParent/' + idCategory;
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
  return this.http.get(url,{ headers });

}

//EXCHANGES / frequent plan
private GetAllExchangesService():Observable<any>
  {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetAllExchanges';
    return  this.http.get(url);

  }


  public GetAllExchangesMethod(argument: string):
  Observable<ObjectGlobalResponse> {
    return this.GetAllExchangesService().pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        const code = wcfResponse.Messages[0];
        if (code === "OK0001") {

          const parametersResponse: string = coreResponse.Parameters as string;
          const originalListResponse: any[] = JSON.parse(parametersResponse);
          var listResponse =  originalListResponse.filter(
            it =>
              it.Description.toLowerCase().includes(argument.toLowerCase())
              || it.Laboratory.toLowerCase().includes(argument.toLowerCase())
            );

          const ecommerceResponse: ReducedExchange[] = [];

          listResponse.forEach((DataItem: any) => {
            const imagesList: ExchangeReducedImage[] = [];
            DataItem.Images.forEach((imagesDataItem: any) => {
              const imageResponse: ExchangeReducedImage = {
                webPath: imagesDataItem.WebPath
              };
              imagesList.push(imageResponse);
            });

            const newObject: ReducedExchange = {
              code: DataItem.Code,
              customerMessage: DataItem.CustomerMessage,
              description: DataItem.Description,
              mechanics: DataItem.Mechanics,
              url: DataItem.URL,
              Images: imagesList
            };

            ecommerceResponse.push(newObject);
          });

          return this.getMessages(code,"2").pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {
          return throwError("Error occurred: " + code);
        }
      })
    );
  }

public GetCategoriesHeaderMethod(idCategory: any): Observable<ResponseCategoriesHeader> {
  return new Observable<ResponseCategoriesHeader>((observer) => {
    this.GetCategoriesHeaderService(idCategory).pipe(
      switchMap((responseCore: any) => {

        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);
          const categories: Category[] = [];


          listResponse.forEach((DataItem: any) => {



            const category: Category = {
                Description: DataItem.Description,
                IDColor: DataItem.IDColor,
                IDMenuCategory: DataItem.IDMenuCategory,
                IDProductCategory: DataItem.IDProductCategory,
                Images: DataItem.Images,
                Name: DataItem.Name,
                ParentId: DataItem.ParentId,
                Priority: DataItem.Priority,
                URL: DataItem.URL,
                products: DataItem.products,

            };

            if(category.IDProductCategory != 373)
            {
              categories.push(category);
            }

          });




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseCategoriesHeader = {
                categories: categories,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseCategoriesHeader = {
                categories: [],
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseCategoriesHeader) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



private GetProductsByCategorieService(idCategory: number):Observable<any>{

  const url = settings.CoreServicesUrl + 'BombaCart.svc/GetAllReducedProductsByFilter/' + idCategory + '/0/0/' + settings.topProducts.toString();
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
  return this.http.get(url,{ headers });

}


public GetProductsByCategorieMethod (idCategory: number): Observable<ResponseGenericProducts> {
  return new Observable<ResponseGenericProducts>((observer) => {
    this.GetProductsByCategorieService(idCategory).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);
          const products: any[] = [];

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericProducts = {
                products: listResponse,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericProducts = {
                products: [],
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericProducts) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



public processWords(wordSearch: string): string {
  const wordsplit: string[] = wordSearch.split(' ');
  const filteredWords: string[] = wordsplit.filter(word => word.trim() !== "");
  if (filteredWords.length === 0) {
      return "";
  }
  const wordSendService: string = filteredWords.join("+") + "~";
  return wordSendService;
}


public SearchIdProductsServices(wordSearch: string):Observable<any>{

  var wordSendService = this.processWords(wordSearch);
  var url = UrlGetProductBySearching
  .replace("{{word}}", wordSendService);
  const apiKey = keyProductBySearching;
  const headers = {
    'api-key': apiKey
};
  return this.http.get(url, { headers });

}

public SearchAutocompleteProductsServices(wordSearch: string, top: string):Observable<any>{
  var wordSendService = this.processWords(wordSearch);
  var url = UrlGetProductAutocomplete.replace("{{word}}", wordSendService);
  url = url.replace("{{top}}", top);
  const apiKey = keyProductBySearching;
  const headers = {
    'api-key': apiKey
};
  return this.http.get(url, { headers });
}

private GetProductsBySearchServices(properties: SearchRequest):Observable<any>{

  try {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetReducedProductsByPhoneticSearch';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var requestCore = JSON.stringify(properties);
    return this.http.post(url, requestCore, { headers });
  } catch (error) {
    // Puedes realizar acciones adicionales aquí, como mostrar un mensaje de error al usuario.
    throw error; // Lanza la excepción nuevamente para que pueda ser manejada más arriba si es necesario.
  }


}


public SearchProductsMethod(wordSearch: string): Observable<ResponseGenericProducts> {
  return this.SearchIdProductsServices(wordSearch).pipe(
    switchMap((responseApi: any) => {
      const value = responseApi.value;
      if (value.length > 0) {
        const listaSkuString: string = value.map((x: { IdProduct: string }) => x.IdProduct).join(",");
        const mySearchRequest: SearchRequest = {
          Argument: listaSkuString,
          IdProductCategory: '0'
        };

        return this.GetProductsBySearchServices(mySearchRequest).pipe(
          switchMap((responseCore: any) => {
            const coreResponse = Object.assign(new CoreResponse(), responseCore);
            const code = coreResponse.Messages[0];

            if (code === "OK0001") {
              const parametersResponse: string = coreResponse.Parameters as string;
              const listResponse: any[] = JSON.parse(parametersResponse);
              const products: any[] = listResponse;

              if (products.length > 0) {
                return this.getMessages(code, '2').pipe(
                  map((data: any) => ({
                    products: products,
                    response: data
                  }))
                );
              } else {
                return this.getMessages("SearchProductsMethod-fail01", '2').pipe(
                  map((data: any) => ({
                    products: [],
                    response: data
                  }))
                );
              }
            } else {
              return this.getMessages("SearchProductsMethod-fail02", '2').pipe(
                map((data: any) => ({
                  products: [],
                  response: data
                }))
              );
            }
          })
        );
      } else {
        return this.getMessages("SearchProductsMethod-fail03", '2').pipe(
          map((data: any) => ({
            products: [],
            response: data
          }))
        );
      }
    })
  );
}

public fillProductImages(imagesData: any[]): ProductImage[] {
  if(imagesData == undefined )
  {
    return [];
  }

  return imagesData.map((imagesDataItem: any) => ({
    IDImageGroup: imagesDataItem.IDImageGroup,
    IDImageType: imagesDataItem.IDImageType,
    WebPath: imagesDataItem.WebPath
  }));
}

public fillProductCategories(categories: any[]): Category[] {
  if(categories == undefined )
  {
    return [];
  }
  return categories.map((DataItem: any) => ({
      Description: DataItem.Description,
      IDColor: DataItem.IDColor,
      IDProductCategory: DataItem.IDProductCategory,
      IDMenuCategory: DataItem.IDMenuCategory,
      Name: DataItem.Name,
      ParentId: DataItem.ParentId,
      Priority: DataItem.Priority,
      URL: DataItem.URL,
      Images: this.fillProductImages(DataItem.Images),
      products: []
  }));
}


public fillSelectedAttributes(attributes: any[]): SelectedAttributes[] {

  if (attributes == undefined) {
    return [];
  }

  return attributes.map((DataItem: any) => ({
    AddedPrice: DataItem.Attributes[0].AddedPrice,
    ChildrenAttributes: [], // Ajusta esto según corresponda
    Dynamic: '', // Ajusta esto según corresponda
    IDProductAttribute: DataItem.Attributes[0].IdProductAttribute,
    IsAvailable: DataItem.Attributes[0].IsAvailable,
    IsShopAvailable: DataItem.Attributes[0].IsShopAvailable,
    Mechanics: '', // Ajusta esto según corresponda
    Name: DataItem.Attributes[0].NameAttribute,
    ParentAttribute: 0, // Ajusta esto según corresponda
    PriceWithDiscount: DataItem.Attributes[0].PriceWithDiscount,
    PriceWithTaxes: DataItem.Attributes[0].PriceWithTaxes,
    ProductAttributeCategory: null,
    ProductAttributeImages: this.fillProductImages(DataItem.Attributes[0].ProductAttributeImages), // Ajusta esto según corresponda
    PromotionDescription: '', // Ajusta esto según corresponda
    Quantity: DataItem.Attributes[0].Quantity,
    RealPrice: DataItem.Attributes[0].RealPrice,
    Sku3: DataItem.Attributes[0].Sku3,
    Taxes: DataItem.Attributes[0].Taxes,
  }));
}


public fillProductsList(listResponse: any[]): Products[] {
  return listResponse.map((DataItem: any) => {
    var productImages: ProductImage[]  = [];
    var categories: Category[] = [];
    var ProductAttributes: ProductAttribute[] = [];
    var ProductAttributeCategories: ProductAttributeCategory[] = [];
    if(DataItem.ProductImages != undefined)
    {
      productImages = this.fillProductImages(DataItem.ProductImages);
    }

    if(DataItem.Categories != undefined)
    {
      categories = this.fillProductCategories(DataItem.Categories);
    }

    if(DataItem.ProductAttributes != undefined)
    {
      ProductAttributes = this.fillProductAttributes(DataItem.ProductAttributes);
    }

    if(DataItem.ProductAttributeCategories != undefined)
    {
      ProductAttributeCategories = this.fillProductAttributeCategories(DataItem.ProductAttributeCategories);
    }


    const product: Products = {
      applyCoupon: DataItem.applyCoupon,
      basePrice: DataItem.BasePrice,
      categories: categories,
      components: DataItem.components,
      detail: DataItem.detail,
      dynamic: DataItem.dynamic,
      globalRate: DataItem.globalRate,
      iDProduct: DataItem.IDProduct,
      iDProductBrand: DataItem.iDProductBrand,
      isAvailable: DataItem.IsAvailable,
      isAvailableToCustomize: DataItem.isAvailableToCustomize,
      isCurrentlyTimeAvailable: DataItem.IsCurrentlyTimeAvailable,
      isCustomizable: DataItem.isCustomizable,
      isPromotion: DataItem.isPromotion,
      isRecipeRequired: DataItem.isRecipeRequired,
      isRedeemable: DataItem.isRedeemable,
      isShopAvailable: DataItem.IsShopAvailable,
      largeDescription: DataItem.LargeDescription,
      maxAttributesNumber: DataItem.maxAttributesNumber,
      maxToPurchase: DataItem.MaxToPurchase,
      mechanics: DataItem.mechanics,
      minAttributesNumber: DataItem.minAttributesNumber,
      minToPurchase: DataItem.MinToPurchase,
      name: DataItem.Name,
      percentDiscountRate: DataItem.percentDiscountRate,
      priceWithDiscount: DataItem.PriceWithDiscount,
      priceWithTaxes: DataItem.priceWithTaxes,
      productAttributeCategories: ProductAttributeCategories,
      productAttributes: ProductAttributes,
      productImages: productImages,
      productTypes: [], // Rellenar con datos reales
      productsetApplies: DataItem.productsetApplies,
      promotionDescription: DataItem.promotionDescription,
      quantity: DataItem.Quantity,
      sku: DataItem.SKU,
      shortDescription: DataItem.shortDescription,
      taxes: DataItem.taxes,
      url: DataItem.URL,
      votesQuantity: DataItem.votesQuantity,
    };

    return product;
  });
}
  fillProductAttributeCategories(ProductAttributeCategories: any): ProductAttributeCategory[] {
    if(ProductAttributeCategories == undefined )
    {
      return [];
    }
    return ProductAttributeCategories.map((DataItem: any) => ({
      IDAttributeCategory: DataItem.IDAttributeCategory,
      IsDescriptionAttributes: DataItem.IsDescriptionAttributes,
      IsMultiselectable: DataItem.IsMultiselectable,
      IsRequired: DataItem.IsRequired,
      Name: DataItem.Name,
      Priority: DataItem.Priority,
    }));  }


  fillProductAttributes(ProductAttributes: any): ProductAttribute[] {
    if(ProductAttributes == undefined )
    {
      return [];
    }
    return ProductAttributes.map((DataItem: any) => ({
        AddedPrice: DataItem.AddedPrice,
        ChildrenAttributes: DataItem.ChildrenAttributes,
        Dynamic: DataItem.Dynamic,
        IDProductAttribute: DataItem.IDProductAttribute,
        IsAvailable: DataItem.IsAvailable,
        IsShopAvailable: DataItem.IsShopAvailable,
        Mechanics: DataItem.Mechanics,
        Name: DataItem.Name,
        ParentAttribute: DataItem.ParentAttribute,
        PriceWithDiscount: DataItem.PriceWithDiscount,
        PriceWithTaxes: DataItem.PriceWithTaxes,
        ProductAttributeCategory: DataItem.ProductAttributeCategory,
        ProductAttributeImages: this.fillProductImages(DataItem.ProductAttributeImages),
        PromotionDescription: DataItem.PromotionDescription,
        Quantity: DataItem.Quantity,
        SKU: DataItem.SKU,
        Taxes: DataItem.Taxes,
    }));
}


private GetFullProductBySKUService(idProductCategory: number, idShop: number,
  idDeliveryMethod: number, sku: string ): Observable<any> {
  const url = settings.CoreServicesUrl + 'Product.svc/GetFullProductBySKU/'
    + idProductCategory+"/"+idShop+"/"+idDeliveryMethod+"/"+sku;
  return this.http.get(url);
}


public getfeaturedProductsServices():Observable<any>
{

  var url = settings.CoreServicesUrl  + 'Product.svc/GetFeaturedProducts'
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
  return this.http.get(url,{ headers });

}


public GetFullProductBySKUMethod(idProductCategory: number, idShop: number,
  idDeliveryMethod: number, sku: string): Observable<ObjectGlobalResponse> {
  return this.GetFullProductBySKUService(idProductCategory,idShop,idDeliveryMethod,sku).pipe(
    switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0];

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const ecommerceResponse: any = JSON.parse(parametersResponse);

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: null
            };
            return response;
          })
        );
      }
    })
  );
}

private GetFullProductByIDService(idProductCategory: number, idShop: number,
  idDeliveryMethod: number, primaryKey: number ): Observable<any> {
  const url = settings.CoreServicesUrl + 'Product.svc/GetFullProduct/'
    + idProductCategory+"/"+idShop+"/"+idDeliveryMethod+"/"+primaryKey;
  return this.http.get(url);
}



public GetFullProductByIDMethod(idProductCategory: number, idShop: number,
  idDeliveryMethod: number, primaryKey: number): Observable<ObjectGlobalResponse> {
  return this.GetFullProductByIDService(idProductCategory,idShop,idDeliveryMethod,primaryKey).pipe(
    switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0];

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const ecommerceResponse: any = JSON.parse(parametersResponse);

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: null
            };
            return response;
          })
        );
      }
    })
  );
}

public getfeaturedProductsMethod (): Observable<ResponseGenericProducts> {
  return new Observable<ResponseGenericProducts>((observer) => {
    this.getfeaturedProductsServices().pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);
          const products: any[] = [];


          listResponse.forEach((DataItem: any) => {
            products.push(DataItem);
          });




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericProducts = {
                products: products,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericProducts = {
                products: [],
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericProducts) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}


private getRecentClientSearchesServices(clientId:string, top: number):Observable<any>{

var url = settings.CoreServicesUrl  + 'Product.svc/GetClientRecentSearchArguments'
url += "/" + clientId + "/" + top;
const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
return this.http.get(url,{ headers });


}



public getRecentClientSearchesMethod (clientId:string, top: number): Observable<ResponseGenericProducts> {
  return new Observable<ResponseGenericProducts>((observer) => {
    this.getRecentClientSearchesServices(clientId,top).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);
          const products: any[] = [];


          listResponse.forEach((DataItem: any) => {
            products.push(DataItem);
          });




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericProducts = {
                products: products,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericProducts = {
                products: [],
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericProducts) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



public DeleteClientRecentSearchesServices(searchID:string, userId: string):Observable<any>{
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
  var url = '';
  if(searchID != '') {
    url = settings.CoreServicesUrl + 'Product.svc/InactivateClientRecentSearchByID'
    url += "/" + searchID;
  }
  else {
    url = settings.CoreServicesUrl + 'Product.svc/InactivateAllClientRecentSearches'
    url += "/" + userId;
  }
  return this.http.get(url,{ headers });
}



public DeleteClientRecentSearchesMethod (searchID:string, userId: string): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.DeleteClientRecentSearchesServices(searchID,userId).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: listResponse,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



private SaveRecentClientSearchesServices(RecentSearchRequest:RecentSearchRequest):Observable<any>{


var url = settings.CoreServicesUrl  + 'Product.svc/SaveRecentSearch'
//var json = JSON.stringify(RecentSearchRequest);
const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
return  this.http.post(url, RecentSearchRequest,{ headers });


}

public SaveRecentClientSearchesMethod (RecentSearchRequest:RecentSearchRequest): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.SaveRecentClientSearchesServices(RecentSearchRequest).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}


private SaveCommentByProductService(request: CommentByProduct): Observable<any> {
  const url = settings.CoreServicesUrl + 'Product.svc/SaveCommentByProduct';
  const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
  const requestCore: CoreRequest = {
    ProgId: "CLISITE",
    UserId: "1",
    AllowApplyCoupons: false,
    IDSavedCard: null,
    IsValid: false,
    Messages: null,
    ServerPath: '',
    WithErrors: false,
    Parameters: ''
  };

  requestCore.Parameters = JSON.stringify(request);
  return this.http.post(url, requestCore, { headers });

}


public SaveCommentByProductMethod(request: CommentByProduct):
  Observable<ObjectGlobalResponse> {
  return this.SaveCommentByProductService(request).pipe(
    switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0];

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const ecommerceResponse: any = JSON.parse(parametersResponse);

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: null
            };
            return response;
          })
        );
      }
    })
  );
}

private SearchCommentByProductService(idProduct: number, page: number, pageSize: number)
  : Observable<any> {
  const url = settings.CoreServicesUrl + 'Product.svc/SearchCommentByProduct/'
    + idProduct + "/" + page + "/" + pageSize;
  return this.http.get(url);
}


public SearchCommentByProductMethod(idProduct: number, page: number, pageSize: number):
  Observable<ObjectListGlobalResponse> {
  return this.SearchCommentByProductService(idProduct, page, pageSize).pipe(
    switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0];

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const ecommerceResponse: any = JSON.parse(parametersResponse);

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectListGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectListGlobalResponse = {
              response: data,
              responseObject: []
            };
            return response;
          })
        );
      }
    })
  );
}

private GetPunctuationValuesService(idProduct: number)
  : Observable<any> {
  const url = settings.CoreServicesUrl + 'Product.svc/GetPunctuationValues/'
    + idProduct;
  return this.http.get(url);
}


public GetPunctuationValuesMethod(idProduct: number):
  Observable<ObjectGlobalResponse> {
  return this.GetPunctuationValuesService(idProduct).pipe(
    switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0];

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const ecommerceResponse: any = JSON.parse(parametersResponse);

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: null
            };
            return response;
          })
        );
      }
    })
  );
}

private validateAccessibilityToRateService(idProduct: number, idClient: number)
  : Observable<any> {
  const url = settings.CoreServicesUrl + 'Product.svc/validateAccessibilityToRate/'
    + idProduct+"/"+idClient;
  return this.http.get(url);
}


public validateAccessibilityToRateMethod(idProduct: number, idClient: number):
  Observable<ObjectGlobalResponse> {
  return this.validateAccessibilityToRateService(idProduct,idClient).pipe(
    switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0];

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const ecommerceResponse: any = JSON.parse(parametersResponse);

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectGlobalResponse = {
              response: data,
              responseObject: null
            };
            return response;
          })
        );
      }
    })
  );
}

private GetMoleculeService(sku: string)
  : Observable<any> {
  const url = settings.CoreServicesUrl + 'BombaCart.svc/GetMolecule/'
    + sku;
  return this.http.get(url);
}

public FillMoleculeAttributes(requestObject: any[]): MoleculeAttribute[] {
  if (requestObject == undefined) {
    return [];
  }

  return requestObject.map((dataItem: any) => ({
    IDProductAttribute: dataItem.IDProductAttribute,
    PriceWithDiscount: dataItem.PriceWithDiscount,
    PriceWithTaxes: dataItem.PriceWithTaxes
  }));
}

private SetMoleculeProperties(requestObject: any): Molecule {
  const objectResponse: Molecule = {
    IDProduct: requestObject.IDProduct,
    IsAvailable: requestObject.IsAvailable,
    IsShopAvailable: requestObject.IsShopAvailable,
    Name: requestObject.Name,
    PercentDiscountRate: requestObject.PercentDiscountRate,
    PriceWithDiscount: requestObject.PriceWithDiscount,
    PriceWithTaxes: requestObject.PriceWithTaxes,
    ProductAttributes: this.FillMoleculeAttributes(requestObject.ProductAttributes),
    ProductImages: requestObject.ProductImages,
    URL: requestObject.URL,
  };

  return objectResponse;

}

public GetMoleculeMethod(sku: string):
  Observable<ObjectListGlobalResponse> {
  return this.GetMoleculeService(sku).pipe(switchMap((coreResponse: any) => {
    const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
    var code = wcfResponse.Messages[0];

    if (code === "OK0001") {
      const parametersResponse: string = wcfResponse.Parameters as string;
      const listResponse: any[] = JSON.parse(parametersResponse);
      const ecommerceResponse: any[] = [];

      listResponse.forEach((DataItem: any) => {
        ecommerceResponse.push(this.SetMoleculeProperties(DataItem));
      });


      return this.getMessages(code, '1').pipe(
        map((data: any) => {
          const response: ObjectListGlobalResponse = {
            response: data,
            responseObject: ecommerceResponse
          };
          return response;
        })
      );
    } else {

      return this.getMessages(code, '1').pipe(
        map((data: any) => {
          const response: ObjectListGlobalResponse = {
            response: data,
            responseObject: []
          };
          return response;
        })
      );
    }
  })
  );
}

private GetReducedProductsByLinkService(argument: string, type: number)
  : Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetReducedProductsByLink';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    //type 1 = marcas
    //type 2 = categorías
    //type 3 = tags
    //type 4 = nombre producto
    const requestCore: SearchProductRequest = {
        Argument: argument,
        IDProductCategory: type
      };


    return this.http.post(url, requestCore, { headers });
}


public GetReducedProductsByLinkMethod(argument: string, type: number):
  Observable<ObjectListGlobalResponse> {
  return this.GetReducedProductsByLinkService(argument, type).pipe(
    switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0];

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const ecommerceResponse: any = JSON.parse(parametersResponse);

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectListGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectListGlobalResponse = {
              response: data,
              responseObject: []
            };
            return response;
          })
        );
      }
    })
  );
}




public AddProductsToCart(request: any):Observable<any> {
  const url = settings.CoreServicesUrl + 'Cart.svc/AddProductToCart';
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
  return  this.http.post(url, request,{ headers });
}

public AddProductsToCartMethod(request: any): Observable<ResponseGenericObject> {
return new Observable<ResponseGenericObject>((observer) => {
  this.AddProductsToCart(request).pipe(
    switchMap((responseCore: any) => {
      const coreResponse = Object.assign(new CoreResponse(), responseCore);
      let code = coreResponse.Messages[0];
      if (coreResponse.IsValid) {
        const parametersResponse: string = coreResponse.Parameters as string;
        const Response: any[] = JSON.parse(parametersResponse);

        if (code === undefined) {
          code = (coreResponse.IsValid === true && coreResponse.Parameters != null)
          ? "AddProduct-Success" : code;
        }

        if (code === 'AddProduct-Success') {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        }

        else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }

      } else {
        return this.getMessages(code,'2').pipe(
          map((data: any) => {
            const response: ResponseGenericObject = {
              object: false,
              response: data
            };
            return response;
          })
        );
      }
    })
  ).subscribe(
    (response: ResponseGenericObject) => {
      observer.next(response);
      observer.complete();6
    },
    (error: any) => {
      observer.error(error);
    }
  );
});
}

public AddProductsToCartMethodV2(request: any): Observable<any> {

  return new Observable<any>((observer) => {
    this.AddProductsToCart(request).subscribe((prod) => {
      const coreResponse = Object.assign(new CoreResponse(), prod);
      let code = coreResponse.Messages[0];

      if (coreResponse.IsValid) {
        const parametersResponse: string = coreResponse.Parameters as string;
        const Response: any[] = JSON.parse(parametersResponse);
        if (code === undefined) {
          code = coreResponse.IsValid === true && coreResponse.Parameters != null
            ? "AddProduct-Success"
            : code;
            observer.next(code);
        } else {
          // Emitir el código en caso de éxito
          observer.next(code);
        }
      } else {
        // Emitir el error en caso de fallo
        observer.error("error-AddProductsToCart");
      }

      // Completar el Observable después de la lógica
      observer.complete();
    });
  });
}



public GetShopsProductDetail(sku1: string, sku2: string):Observable<any>
{
  const url = settings.BusDeServiciosUrlDev + 'Inventario.svc/rest/ConsultarSku';
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  const body = {
    Valor: sku1 + ',' + sku2,
    Separador: ','
  };

  const jsonData = JSON.stringify(JSON.stringify(body));

  return this.http.post(url, jsonData, { headers });
}

private ValidateProductByLinkService(argument: string)
: Observable<any> {
const url = settings.CoreServicesUrl + 'Product.svc/ValidateProductByLink/'
  + argument;
return this.http.get(url);
}


public ValidateProductByLinkMethod(argument: string):
Observable<ObjectGlobalResponse> {
return this.ValidateProductByLinkService(argument).pipe(
  switchMap((coreResponse: any) => {
    const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
    var code = wcfResponse.Messages[0];

    if (code === "OK0001") {
      const parametersResponse: string = wcfResponse.Parameters as string;
      const ecommerceResponse: any = JSON.parse(parametersResponse);

      return this.getMessages(code, '1').pipe(
        map((data: any) => {
          const response: ObjectGlobalResponse = {
            response: data,
            responseObject: ecommerceResponse
          };
          return response;
        })
      );
    } else {

      return this.getMessages(code, '1').pipe(
        map((data: any) => {
          const response: ObjectGlobalResponse = {
            response: data,
            responseObject: null
          };
          return response;
        })
      );
    }
  })
);
}


}
