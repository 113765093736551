<!-- Modal disponibilidad-->
<div class="modal fade modal-overlay-light" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="modalPharmaciesLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-search-stores">
    <div class="modal-content modal-style">
      <div class="modal-header align-items-start">
        <h2 class="modal-title fs-5 color-primary" id="exampleModalLabel">
          <i class="bi bi-geo-alt-fill pe-2 icon-disponibilidad"></i>
          Disponibilidad en Farmacias La Bomba
        </h2>
        <button type="button" class="btn-close" (click)="close()"></button>
      </div>
      <div class="modal-body">

        <div class="row wrapper">
          <div class="col-12 col-md-6">

            <h3 for="exampleFormControlInput1" class="form-label">Buscar por:</h3>

            <label for="exampleFormControlInput1" class="form-label">Provincia</label>
            <select class="form-select contact-input" aria-label="Buscar por" [(ngModel)]="selectedProvince" (ngModelChange)="onChangeProvince($event)">
              <option [ngValue]="0" (click)="onChangeProvince($event)">
                <!-- Provinica -->
              </option>
              <option *ngFor="let province of this.provinces" [ngValue]="province.IDAddressProvince">
                {{ province.Name }}
              </option>
            </select>

            <label for="exampleFormControlInput1"  class="form-label mt-3" >Cantón</label>
            <select class="form-select contact-input" aria-label="Buscar por" [(ngModel)]="selectedCanton" (ngModelChange)="onChangeCanton($event)">
              <option [ngValue]="0" (click)="onChangeCanton($event)">
                <!-- Cantón -->

              </option>
              <option *ngFor="let canton of this.cantons" [ngValue]="canton.IDAddressCanton">
                {{ canton.Name }}
              </option>
            </select>
            <small class="py-2">Disponibilidad sujeta al momento de la compra</small>
          </div>

          <!-- Resultados -->
          <div class="col-12 col-md-6 mt-3 mt-md-0" *ngIf="pharmacies.length > 0 && !isNotAvalability">
            <div class="results-container pr-2">
              <ng-container *ngFor="let ph of pharmacies" >
                <div class="card mb-2 me-3 border-color" *ngIf="ph.StockUnidades > 0 || ph.StockCajas > 0">
                  <div class="card-body p-2">
                    <h4 class="mb-1 color-tittle">{{ph.Name ? ph.Name : ph.Farmacia}}</h4>
                    <p class="mb-0">{{ph.Address ? ph.Address : ''}}</p>
                    <p class="mb-0 color-secondary" *ngIf="(ph.StockUnidades == null || ph.StockUnidades < 0) ? false : true"> Unidades: {{ (ph.StockUnidades == null || ph.StockUnidades < 0) ? 0 : ph.StockUnidades }}</p>
                    <p class="mb-0 color-secondary" *ngIf="(ph.StockCajas == null || ph.StockCajas < 0) ? false : true"> Cajas: {{ (ph.StockCajas == null || ph.StockCajas < 0) ? 0 : ph.StockCajas }}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- Sin resultados -->
          <div class="col-12 col-lg-6 d-flex flex-colum align-items-center" *ngIf="isNotAvalability">
            <div class="pr-2">
              <div class="">
                <div class="">
                  <div class="text-center">
                    <i class="bi bi-shop-window icon-disponibilidad"></i>
                    <p class="pt-2 mb-0">En este momento no tenemos disponibilidad de este producto.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <!-- <p class="text-footer-modal" *ngIf="error">Codigo de error</p> -->
      </div>
    </div>
  </div>
</div>
