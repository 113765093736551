import { Attribute, Component, OnInit } from '@angular/core';
import { BaseComponent } from "../base.component";
import { LandingService } from '../../services/sitefinity/landing.service';
import { DocumentsService } from '../../services/sitefinity/documents.service';
import { RootUrlService } from "../../services/root-url.service";
import { SitefinitypagesService } from '../../services/sitefinity/sitefinitypages.service';
import { ResponseTypeOfDocuments, TypeOfDocuments, ResponseLegalDocuments } from '../../models/sitefinity/type-of-documents.model';
import { ProductsService } from '../../services/core/products.service';
import { UtilitiesService } from '../../services/core/utilities.service';
import { ShopsService } from '../../services/core/shops.service';
import { UtilitiesServicesService } from '../../services/shared/utilities-services.service'; ///shared/utilities-services.service
import { ClientsService } from '../../services/core/clients.service';
import { Clients, SignIn, InfoSignUp, ReducedClientRequest } from 'src/app/models/core/clients.model';
import { BlogService } from '../../services/sitefinity/blog.service';
import { FAQsService } from '../../services/sitefinity/faqs.service';
import { Client, RequestFavoriteProduct } from '../../models/core/clients.model'
import { RemoveFavoriteProduct } from '../../models/core/favorite-products.model'
import { ReminderService } from '../../services/core/reminder.service';
import { CartService } from '../../services/core/cart.service';
import { CartAddress } from 'src/app/models/core/cart.model';
import { UserFidelity } from 'src/app/models/core/fidelity.model';
import { FidelityService } from '../../services/core/fidelity.service';
import { RecentSearchRequest } from 'src/app/models/core/products.model';
import { AdvertisingService } from '../../services/sitefinity/advertising.service';
import { ContactusService } from '../../services/core/contactus.service';
import { ClientInteractionObject, InformedConsentFormObject } from 'src/app/models/core/contactus.model';
import { MergeNService } from '../../services/core/merge-n.service';
import { CommentByProduct } from 'src/app/models/core/merge-n.model';
import { CartProduct, CartProductAttribute, CartRequest } from 'src/app/models/core/cart.model';
import {HelperCheckoutService} from 'src/app/services/core/helper-checkout.service'
import {SwapsService} from '../../services/core/swaps.service';
import {DatalakeService} from '../../services/core/datalake.service';
import {CheckoutService} from '../../services/core/checkout.service';
import {LifeMilesService} from '../../services/core/life-miles.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-backend',
  templateUrl: './backend.component.html',
  styleUrls: ['./backend.component.css']
})
export class BackendComponent {
  metodo2Value: string = '';

  constructor(private LandingService: LandingService,
    private DocumentsService: DocumentsService, private SFpagesService: SitefinitypagesService,
    private services: RootUrlService,
    private products: ProductsService,
    private utilities: UtilitiesService,
    private shops: ShopsService,
    private ErrorOrLogsServices: UtilitiesServicesService,
    private ClientServices: ClientsService,
    private BlogService: BlogService,
    private FAQsService: FAQsService,
    private ReminderService: ReminderService,
    private CartServices: CartService,
    private FidelityService: FidelityService,
    private AdvertisingService: AdvertisingService,
    private ContactusService: ContactusService,
    private MergeNService: MergeNService,
    private HelperCheckoutService: HelperCheckoutService,
    private Swaps: SwapsService,
    private Datalake: DatalakeService,
    private Checkout: CheckoutService,
    private LifeMilesService: LifeMilesService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {

  }

  getproductbylink = ''

  ngOnInit() {}

  GetAllLandings() {
    const services2 = new RootUrlService();
    let x = services2.getServiceUrl();
    let o = this.LandingService.getLandings();
  }

  GetLandingDetailByID() {
    let o = this.LandingService.getLandingDetail(this.metodo2Value);
  }

  GetAllDocumentsTypes() {
    let ResponseTypeOfDocuments = this.DocumentsService.GetDocumentsType();
  }

  GetAllLegalDocuments() {
    let ResponseLegalDocuments = this.DocumentsService.GetLegalDocuments(0, 10);
    ResponseLegalDocuments.subscribe(o => {

    });
  }



  GetAllDocumentsByIdType() {
    let ResponseLegalDocuments = this.DocumentsService.GetLegalDocumentsByIdType(0, 10, '73b4309e-99b9-413e-87b1-f3b9ce76b5a2');
  }


  SaveErrorLogs() {
    this.ErrorOrLogsServices.insertError('enviado desde FE', 'enviado desde FE', 'enviado desde FE', 'enviado desde FE');

  }

  ///////////Sitefinity end//////////////////


  GetSitefinityPagesMethod() {
    let response = this.SFpagesService.GetSitefinityPages();
    response.subscribe(o => {

    });
  }

  GetProductsBySKuList() {
    const skuList: string[] = ["8340595", "6891947"];
    this.products.GetProductsBySkuListMethod(skuList).subscribe(o => {



    });


  }

  GetProvinces() {
    this.utilities.getProvinces().subscribe(res => {
    });
  }

  GetCantonsByIDProvince() {
    const objId: string = "1";
    let response = this.utilities.GetCantonsByIDProvince(objId);

  }

  GetShopsByShopType() {
    const typesList: number[] = [];
    let response = this.shops.GetShopsByShopType(typesList, 1, 1, 0);

  }



  GetCategoriesLandingByIDProductCategoryParent() {
    const skuList: string[] = ["8340595", "6891947"];
    this.products.GetCategoriesLandingByIDProductCategoryParentMethod(373).subscribe(o => {



    });

  }



  GetAllBlogs() {

    this.BlogService.getBlogListMethod(100, 0).subscribe(o => {



    });

  }

  GetAllTags() {

    this.BlogService.getTagsOrClassificationsMethod().subscribe(o => {



    });

  }


  GetBlogByIdBTags() {

    let IdTgs: string[] = [];
    IdTgs.push('48c7e76a-5a2d-477c-a2d3-0ef6a17bd319'); //familia
    IdTgs.push('40ab5231-f0d6-47ea-b108-1971b27c0ca7'); // salud sexual
    IdTgs.push('609f9b2c-d087-4fa7-9402-1976a910fff0'); //virus
    this.BlogService.filterBlogsByTagsIdMethod(IdTgs, 100, 0).subscribe(o => {



    });

  }


  GetBlogDetail() {


    this.BlogService.getBlogDetailByIdMethod('720d21e3-7096-406e-bc0a-0b4fad701f0e').subscribe(o => {



    });

  }




  GetFaqs() {


    this.FAQsService.GetFAQsMethod(0, 20).subscribe(o => {



    });

  }

  //////Clients/////////////////


  SignIn() {
    let signIn: SignIn = {

      LoginName: "HelperCheckoutServicea@loymark.com",
      Password: "Password.01"
    }

    let m: string = '';

    this.ClientServices.SignInMethod(signIn).subscribe(o => {
      m = o.response.message;
    });


  }

  SignUp() {

    const infoSignUp: InfoSignUp = {
      name: 'Juan',
      firstLastName: 'Perez',
      secondLastName: 'Gonzalez',
      phone: '7932-9523',
      identification: '9876543888',
      email: 'johnjpg@example.com',
      password: 'securepassword',
      idPhone: '2',
      loyaltyCard: 'loyalty1234',

      iAmOfAge: true,
      typeidentification: 2,
      idCanton: 1,
      idDistrict: 2,
      idProvince: 1,
      codeElec: 119031,
      birthday: '1993-10-02T00:00:00.000Z',
      MapPoints : {
        Latitude: 1,
        Longitude: 1
      }
    };

    this.ClientServices.SignUpMethod(infoSignUp).subscribe(o => {



    });

  }

  GetReducedClient() {
    let request: ReducedClientRequest = {

      IDClient: 0,
      Argument: "trabajonan93@gmail.com"
    }

    let m: string = '';

    this.ClientServices.GetReducedClientMethod(request).subscribe(o => {
      m = o.response.message;
    });


  }

  GetElectoralRollPersonData() {
    let m: string = '';

    this.ClientServices.GetElectoralRollPersonDataMethod("115510185").subscribe(o => {
      m = o.response.message;
    });


  }


  GetInfoClient() {
    let m: string = '';

    this.ClientServices.GetInfoClient("114100606", "1").subscribe(o => {



    });

  }

  GetCategoriesHeader() {
    let m: string = '';

    this.products.GetCategoriesHeaderMethod(0).subscribe(o => {



    });

  }




  GetAllExchanges() {
    let m: string = '';

    this.products.GetAllExchangesMethod("apro").subscribe(o => {
      m = o.response.message;
    });

  }

  GetProductsByCategory() {
    let m: string = '';

    this.products.GetProductsByCategorieMethod(46).subscribe(o => {
      m = o.response.message;
    });

  }


  GetIdProductsUsingApiToDoSearch() {
    let m: string = '';

    this.products.SearchProductsMethod("electrolit").subscribe(o => {




    });



  }

  RecoverPassword() {
    let m: string = '';

    this.ClientServices.RecoverPasswordMethod("jihen42818@dnitem.com").subscribe(o => {
      m = o.response.message;
    });


  }

  GetUserPersonalInfo() {
    let m: string = '';

    this.ClientServices.GetUserPersonalInfoMethod("7347").subscribe(o => {



    });

  }


  GetFavoriteProducts() {
    let m: string = '';

    this.ClientServices.GetFavoritesProductsMethod("4").subscribe(o => {



    });

  }



  AddFavoriteProducts() {
    let attribute: any;
    let listAttribute: any[] = [];
    attribute = {

      IdProductAttribute: 220,
      NameAttribute: 'Caja',
      AddedPrice: 13382.4
    }
    listAttribute.push(attribute);

    let RequestFavoriteProduct: RequestFavoriteProduct = {
      AddToQueue: true,
      IdClient: 4,
      IdProduct: 780,
      Quantity: 1,
      Name: 'ANUAR T FLUCONAZOL+TINIDAZOL',
      Presentation:"",
      Attributes: listAttribute
    };



    let object: RequestFavoriteProduct[] = [];
    object.push(RequestFavoriteProduct);





    this.ClientServices.AddClientFavoriteProductMethod(object).subscribe(o => {



    });

  }

  DeleteFavoriteProducts() {


    let removeProduct: RemoveFavoriteProduct = {
      IdClient: 4,
      Product: [],
      IdFavoriteProduct: 329,
      ProductSetWithoutProducts: false,
      WithProductSet: false



    }

    this.ClientServices.DeleteClientFavoriteProductMethod(removeProduct).subscribe(o => {



    });

  }


  CheckIfUserhasAFavoriteProduct() {




    this.ClientServices.CheckIfClientHasFavoriteProductMethod(780, 4).subscribe(o => {



    });

  }






  GetReminderTypesMethod() {




    this.ReminderService.GetReminderTypesMethod().subscribe(o => {



    });

  }

  GetClientAddressProfile() {
    let m: string = '';

    this.ClientServices.GetClientAddressProfileMethod("7347").subscribe(o => {



    });

  }

  AddAddressToCart() {
    let m: string = '';
    let request: CartAddress = {
      DeliveryMethodName: null,
      DeliveryMethodSecondChoice: null,
      FullAddress: "Prueba la del pueblo new ecomm",
      IdAddressCanton: 1,
      IdAddressDistrict: 5,
      IdAddressProvince: 1,
      IdClientAddress: 0,
      IdDeliveryArea: 511,
      IdDeliveryMethod: 2,
      IdShop: 96,
      IsDefault: false,
      IsOpen: true,
      MapPoints: {
        Latitude: 9.9263224,
        Longitude: -84.0756886
      },
      Name: "new ecomm 3",
      Phone: null,
      PickupDate: null,
      PickupTime: "",
      Status: "AP",
      TableNumber: 0,
      ToSave: true,
      InactiveShop: null
    };
    this.CartServices.AddAddressToCartMethod("384c66c1-041d-4336-baee-958282f508cc",
      request).subscribe(o => {

  

      });

  }

  GetClientActiveSavedCards() {
    let m: string = '';

    this.CartServices.GetClientActiveSavedCardsMethod("7347").subscribe(o => {



    });

  }

  InactivateSavedCard() {
    let m: string = '';

    this.CartServices.InactivateSavedCardMethod("66DBF61A-F282-4C6B-87FE-C0F67C94CF4A")
      .subscribe(o => {

  

      });

  }

  SearchPurchaseByIDClient() {
    let m: string = '';

    this.CartServices.SearchPurchaseByIDClientMethod("9727").subscribe(o => {



    });

  }


  UpdateUserPasswordMethod() {
    this.ClientServices.GetUserPersonalInfoMethod("4").subscribe(o => {
      let responseObject = o.responseObject;
      responseObject.Password = 'Password.01';
      responseObject.ConfirmationPassword = 'Password.01';
      responseObject.AcceptTerms = true;
      responseObject.UserToken = "4";
      responseObject.RequirePasswordChange = true;

      this.ClientServices.UpdateClientInfoMethod(responseObject).subscribe(o => {
      })
    })

  }


  FidelityUserPoints() {

    let f: UserFidelity = {

      IdClientType: 1,
      Identification: '114100606'

    }
    this.FidelityService.FidelityUserPoints(f).subscribe(o => {



    })
  }



  GetParameterByKey() {


    this.utilities.GetParametersByKey('FidelityRedirectToLogin').subscribe(o => {



    })
  }



  GetFidelitySession() {
    let f: UserFidelity = {

      IdClientType: 1,
      Identification: '114100606'

    }
    this.FidelityService.GetSessionFidelity(f).subscribe(o => {
    })
  }


  GetFullProductBySKU() {
    let m: string = '';

    this.products.GetFullProductBySKUMethod(0, 0, 0, "7444797").subscribe(o => {



    });

  }


  GetFullProductByID() {
    let m: string = '';

    this.products.GetFullProductByIDMethod(0, 0, 0, 232).subscribe(o => {



    });

  }


  AddOrDeleteCuponToCartWithCertificate() {
    let m: string = '';

    this.CartServices.AddOrDeleteCuponToCartWithCertificateMethod
      ("9CBA0A9E-AAB1-4FB7-BA12-41B3AB8B9BC4", "Prueba0002", "1", "0").subscribe(o => {

  

      });

  }

  AddOrDeleteCuponToCart() {
    let m: string = '';

    this.CartServices.AddOrDeleteCuponToCartMethod
      ("9CBA0A9E-AAB1-4FB7-BA12-41B3AB8B9BC4", "GP7F695", "1").subscribe(o => {

  

      });

  }

  ValidateAddGiftCertificateCuponToCart() {
    let m: string = '';

    this.CartServices.ValidateAddGiftCertificateCuponToCartMethod
      ("9CBA0A9E-AAB1-4FB7-BA12-41B3AB8B9BC4", "Prueba0002", "6044879", "590", "0", "1")
      .subscribe(o => {

  

      });

  }

  GetBlogByUrlContent() {



    this.BlogService.GetBlogIdByUrlContent('blog-urlContet-test').subscribe(o => {




    })
  }



  getFeaturedProducts() {



    this.products.getfeaturedProductsMethod().subscribe(o => {




    })
  }


  getRecentClientSearchesServices() {



    this.products.getRecentClientSearchesMethod('4', 10).subscribe(o => {




    })
  }
  DeleteClientRecentSearchesServices() {



    this.products.DeleteClientRecentSearchesMethod('50608', '4').subscribe(o => {




    })
  }




  SaveClientRecentSearchesServices() {


    const defaultRecentSearchRequest: RecentSearchRequest = {
      Argument: "acetominafen",
      IDClient: 4,
      IDProduct: null,
      IDProductCtegory: 0,
      IPAddress: "::1",
      BrowserName: "Chrome",
      BrowserVersion: "116.0.0.0",
      OperatingSystem: "Windows",
      DeviceType: "Desktop Machine",
      IsMobileBrowser: "0",
      UserAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
      IsToCount: false,
    };
    this.products.SaveRecentClientSearchesMethod(defaultRecentSearchRequest).subscribe(o => {




    })



  }


  GetAdvertisingBanners() {



    this.AdvertisingService.getPrincipalAdvertisingMethod().subscribe(o => {




    })

  }

  GetSubjectServices() {



    this.ContactusService.SubjectMethod().subscribe(o => {




    })
  }

  GetEmailContactUsServices() {

    const clientInteractionRequest: ClientInteractionObject = {
      ClientEmail: 'diegor@loymark.com',
      ClientName: 'DIEGO DE JESUS',
      Subject: 'Consulta General',
      IDClientInteraction: '0',
      LoyaltyCard: '',
      Identification: '116450286',
      Phone: '60000000',
      IDClientInteractionSubject: '',
      Message: 'Medicamento',
      IdType: 'Nacional',
      ClientLastName: 'RODRIGUEZ',
      ClientSecondLastName: 'VARELA',
      idSitefinitySubject: '1',
      Files: null
    };

    // this.ContactusService.SendEmailContactUsMethod(clientInteractionRequest).subscribe(o => {

    //

    // })
  }

  GetInformedConsentServices() {

    const informedConsentFormRequest: InformedConsentFormObject = {
      Identification: '123456789',
      IdType: 1,
      Name: 'Juan',
      FirstLastName: 'Pérez',
      SecondLastName: 'González',
      Email: 'juan.perez@example.com',
      Phone: '555-555-5555',
      Subject: 'Solicitud de información',
      Message: 'Quisiera obtener más información sobre sus servicios.'
    };

    this.ContactusService.InformedConsentMethod(informedConsentFormRequest).subscribe(o => {



    })
  }

  GetShopsProductDetail() {
    this.products.GetShopsProductDetail("S5857871", "5857871").subscribe((o => {

    }));
  }

  SaveCommentByProduct() {
    let m: string = '';
    let request: CommentByProduct = {
      IDCommentByProduct: 0,
      IDClient: 9727,
      IDProduct: 603,
      Comment: "Buen producto",
      Rate: 5

    };
    this.MergeNService.SaveCommentByProductMethod(request).subscribe(o => {



    });

  }

  SearchCommentByProduct() {
    let m: string = '';

    this.MergeNService.SearchCommentByProductMethod(49, 1, 10000).subscribe(o => {



    });

  }

  GetPunctuationValues() {
    let m: string = '';

    this.MergeNService.GetPunctuationValuesMethod(49).subscribe(o => {



    });

  }

  validateAccessibilityToRate() {
    let m: string = '';

    this.MergeNService.validateAccessibilityToRateMethod(603, 9727).subscribe(o => {



    });

  }

  GetMolecule() {
    let m: string = '';

    this.MergeNService.GetMoleculeMethod("3729312").subscribe(o => {



    });

  }
  GetReducedProductsByLink() {
    let m: string = '';

    this.MergeNService.GetReducedProductsByLinkMethod(this.getproductbylink, 1).subscribe(o => {



    });

  }



  AddProductToCart() {

    // CartProduct, CartProductAttribute, CartRequest



    let cartProductAttribute = {
      AddedPrice: 32269.9,
      CategoryName: null,
      IdParentProductAttribute: 0,
      IdProductAttribute: 219,
      IsAvailable: false,
      IsPriceDifferent: false,
      IsShopAvailable: false,
      Name: 'Unidad',
      PriceDiff: null,
      PriceDifference: null,
      PriceWithTaxes: null,
      PromotionPrice: null,
      Quantity: 1,
      RealPrice: null,
      Sku3: null,
      Taxes: null,
    };

    let attribute: any[] = [];
    attribute.push(cartProductAttribute);

    let cartProduct = {
      AddToQueue: true,
      BasePrice: 0,
      Comments: '',
      CurrentPrice: 0,
      CurrentPriceWithoutTaxes: 0,
      CurrentTotalPrice: null,
      CurrentTotalPriceWithoutTaxes: null,
      Discount: 0,
      FavoriteName: '',
      IdProduct: 203,
      Images: null,
      InEdition: false,
      IsAdditional: false,
      IsAvailable: false,
      IsCouponPromotion: false,
      IsCurrentlyTimeAvailable: false,
      IsEqualShopType: false,
      IsPriceDifferent: false,
      IsShopAvailable: false,
      IsWithDefaultAttributes: false,
      LargeDescription: null,
      LocalCartProductId: 0,
      MaxToPurchase: 0,
      MinToPurchase: 0,
      Name: 'ABCUTS SLEEK LEAN CAP X120',
      PriceDifference: null,
      PriceWithDiscount: 0,
      PriceWithTaxes: 0,
      Quantity: 1,
      SelectedAttributes: attribute,
      Sku: null,
      Taxes: null,
      URL: null,
      ProductImages: null,
      ProductSet: null,
    };

    let CartRequest = {
      Address: null,
      FavoriteOrderName: null,
      IdCart: "384c66c1-041d-4336-baee-958282f508cc",
      IdClient: 4,
      IdFavoriteOrder: 0,
      IdFavoriteProduct: 0,
      PaymentExtraInfo: null,
      PaymentMethod: null,
      Product: cartProduct,
      IsCardToSave: null,
      UseSavedCard: null,
      UseFAC: null,
      IDSavedCard: null,
      FACRedirect3DSecureResponse: null,
      GenerateElectronicInvoice: null,
      IsAMEX: null,
    };


    this.HelperCheckoutService.AddProductsToCartMethod(CartRequest).subscribe(o => {




    })


  }


  DoSwapsRequest() {
    //Canje
    const request: any =
    {
      identificacion: "ID123456",
      sku: "SKU789012"
    }
    this.Swaps.SwapRequest(request).subscribe(o => {

    })
  }

  DoPreSwapsRequest() {
    //Pre Canje
    const request: any =
    {
      pharmacie: "ID123456",
      rule: "SKU789012",
      sku: "ID123456",
      identification: "SKU789012",
      clientFullName: "ID123456",
      phone: "SKU789012",
    }
    this.Swaps.PreSwapRequestMethod(request).subscribe(o => {

    })
  }

  CEDProductReminderMethod() {
    //agregar editar y eliminar


    const request: any =
    {
      IDCliente: "114100606",
      Estado: "A",
      Articulo: "6044879",
      DiaDelRecordatorio: "2",
      CanalOrigen: "4",
      Medio: "1",
      Accion: 'I',
      FechaInicio: "01-Nov-2023"
    }


    this.ReminderService.CEDProductReminderMethod(request).subscribe(o => {




    })


  }

  GetOrCreateCart() {
    let m: string = '';

    this.MergeNService.GetOrCreateCartMethod
      ("5D8D8C9E-D728-4E7A-AC49-D6C13D6CAA13", 9727).subscribe(o => {

  

      });

  }

  GetCartSummary() {
    let m: string = '';

    this.MergeNService.GetCartSummaryMethod
      ("5D8D8C9E-D728-4E7A-AC49-D6C13D6CAA13",).subscribe(o => {

  

      });

  }

  GetRedemptionRules() {
    let m: string = '';

    this.MergeNService.GetRedemptionRulesMethod
      ("6552304",).subscribe(o => {

  

      });

  }

GetProductRemindersByUser()
{
  this.ReminderService.GetProductRemindersByUserMethod("114370361", "1").subscribe(o=> {
 })
}

InsertDatalakeCartProductEcommerce()  {
  const request: any =
 {
    UserID: "7347",
    Email: "trabajonan93@gmail.com",
    Source: "WEB",
    Status: "AC",
    CreateUserId: "1",
    CreateProgId: "LOGIN",
    SKU:"1183238",
    IDShop:"96"
  }

  this.Datalake.InsertDatalakeCartProductEcommerceMethod(request).subscribe(o=> {

   })
  }

  InsertDatalakeLoginEcommerce()  {
    const request: any =
   {
      UserID: "7347",
      Email: "trabajonan93@gmail.com",
      Source: "WEB",
      Status: "AC",
      CreateUserId: "1",
      CreateProgId: "LOGIN"
    }

    this.Datalake.InsertDatalakeLoginEcommerceMethod(request).subscribe(o=> {


     })
    }

  GetProductsSummary() {
    let m: string = '';

    this.MergeNService.GetProductsSummaryMethod
      ("5D8D8C9E-D728-4E7A-AC49-D6C13D6CAA13",).subscribe(o => {

  

      });

  }

  ValidateProductByLink() {
    let m: string = '';

    this.MergeNService.ValidateProductByLinkMethod
      ("ADENURIC-80MG-X-28-COMPRIMIDOS-6552304",).subscribe(o => {

  

      });

  }



  encryptData()
  {


  let x =  this.ErrorOrLogsServices.encryptData("Password.01");

  }


  decryptData()
  {

//valor generado desde el visual.
  let x =  this.ErrorOrLogsServices.decryptData("bvzv4OF1zt/qXV257GIQRA==");
  let y =  this.ErrorOrLogsServices.decryptData("9CMNVNXwsK9vNSTuQNYd9w==");
  }

  CheckIfProductEarnsPoints()
  {
   let object :any = {
    sku :'7444797',


   }



   this.FidelityService.CheckIfProductEarnsPoints(object).subscribe(o=>{



   })

  }


  GetShopsByLocation() {
    this.Checkout.GetShopsByLocationMethod(1,0,0).subscribe(o => {



    });

  }


  GetProductsInventory() {

    const object: any = {
      IDAddress: 2996,
      IDCart:"03053240-9c0d-42df-94e6-93975bc82dc9",
      IDDeliveryMethod:2,
      IDShop:96,
      Quantities:"15",
      Skus:"3591077"
    };

    this.Checkout.GetProductsInventoryMethod(object).subscribe(o => {



    })
  }



  GetImpulseProducts() {
    this.Checkout.GetImpulseProductsMethod("d984b301-6137-421e-bfd9-73a2f9c62477").subscribe(o => {



    });

  }


  GetProductsInventoryWithoutInventory() {

    const object: any = {
      IDAddress: 2147483647,
      IDCart:"String content",
      IDDeliveryMethod:2147483647,
      IDShop:2147483647,
      Quantities:"String content",
      Skus:"String content"
    };

    this.Checkout.GetProductsInventoryWithoutInventoryMethod(object).subscribe(o => {



    })
  }


  GetConfigValue() {
    this.utilities.GetConfigValueMethod("ExpressMinPurchaseAmount").subscribe(o => {



    });

  }


  ClearCart() {

    const object: any = {
      Address: null,
      FACRedirect3DSecureResponse: null,
      FavoriteOrderName: null,
      GenerateElectronicInvoice: null,
      IDSavedCard: null,
      IdCart: "25d261ce-633a-4e9a-96f6-6b6904d322d5",
      IdClient: 0,
      IdFavoriteOrder: 0,
      IdFavoriteProduct: 0,
      IsAMEX: null,
      IsCardToSave: null,
      PaymentExtraInfo: null,
      PaymentMethod: null,
      Product: null,
      PurchaseOnBehalf: null,
      Quantity: null,
      UseFAC: null,
      UseSavedCard: null,
    };

    this.Checkout.ClearCartMethod(object).subscribe(o => {



    })
  }


  RemoveProductFromCart() {

    const object: any = {
      Address: null,
      FACRedirect3DSecureResponse: null,
      FavoriteOrderName: null,
      GenerateElectronicInvoice: null,
      IDSavedCard: null,
      IdCart: "669150fb-74d8-458a-84a7-6e58f4f2470c",
      IdClient: 7550,
      IdFavoriteOrder: 0,
      IdFavoriteProduct: 0,
      IsAMEX: null,
      IsCardToSave: null,
      PaymentExtraInfo: null,
      PaymentMethod: null,
      Product: {
        AddToQueue: false,
        BasePrice: null,
        Comments: null,
        CurrentPrice: null,
        CurrentPriceWithoutTaxes: null,
        CurrentTotalPrice: null,
        CurrentTotalPriceWithoutTaxes: null,
        Discount: 0.0,
        FavoriteName: null,
        IdProduct: 0,
        Images: null,
        InEdition: false,
        IsAdditional: false,
        IsAvailable: false,
        IsCouponPromotion: false,
        IsCurrentlyTimeAvailable: false,
        IsEqualShopType: false,
        IsPriceDifferent: false,
        IsShopAvailable: false,
        IsWithDefaultAttributes: false,
        LargeDescription: null,
        LocalCartProductId: 1,
        MaxToPurchase: 0,
        MinToPurchase: 0,
        Name: null,
        PriceDifference: null,
        PriceWithDiscount: 0.0,
        PriceWithTaxes: null,
        ProductImages: null,
        ProductSet: null,
        Quantity: 0,
        SelectedAttributes: null,
        Sku: null,
        Taxes: null,
        URL: null
      },
      PurchaseOnBehalf: null,
      Quantity: null,
      UseFAC: null,
      UseSavedCard: null
    };


    this.Checkout.RemoveProductFromCartMethod(object).subscribe(o => {



    })
  }


  ValidateScheduleAvailability()
  {


  this.HelperCheckoutService.ValidateScheduleAvailabilityMethod(1).subscribe( o => {
  });


  }


  GetShopInfoByAddressMethod()
  {


  this.HelperCheckoutService.GetShopInfoByAddressMethod(4,41,256).subscribe( o => {

  });


  }



  GetShopsByShopTypeV2() {
    //este metodo muestra la informacion de las farmacias con mucho mas detalle.
    const typesList: number[] = [];
     this.shops.GetShopsByShopTypeV2Method(typesList, 1, 1, 0).subscribe(o=>{




     });

  }


  AccrualLifeMiles()
    {


      const object: any = {
        transactionId : "",
        LifemilesNumber : "",
        UserName : ""
      };

      this.LifeMilesService.AccrualLifemilesServices(object).subscribe(o=> {

  

      })
    }

    GetPowerTranzHTMLResponseByPurchaseID() {
      let m: string = '';

      this.CartServices.GetPowerTranzHTMLResponseByPurchaseIDMethod("2175",).subscribe(o => {

    

        });

    }

    PreorderPowerTranz() {
      let m: string = '';
      let idSavedCard: string | null= null;
      let idCart: string = "9CBA0A9E-AAB1-4FB7-BA12-41B3AB8B9BC4";
      let idClient: number = 7347;
      let isAMEX: boolean = false;
      let isCardToSave: boolean = true;
      let paymentExtraInfo: string = "+cY8M9ONXJKq3tHCrDXttDYi9UMpn3mD6p2jWBRCnfc/xX+kUe2RdA==";
      let useFAC: boolean = true;
      let useSavedCard: boolean = false;
      this.CartServices.PreorderPowerTranzMethod(idSavedCard,idCart,idClient,isAMEX,
        isCardToSave,paymentExtraInfo,useFAC,useSavedCard)
      .subscribe(o => {

    

        });

    }

    UpdateCartInfoLight() {
      let m: string = '';

      this.CartServices.UpdateCartInfoLightMethod("9CBA0A9E-AAB1-4FB7-BA12-41B3AB8B9BC4").subscribe(o => {

    

        });

    }

    ValidateCartLight() {
      let m: string = '';

      this.CartServices.ValidateCartLightMethod("9CBA0A9E-AAB1-4FB7-BA12-41B3AB8B9BC4").subscribe(o => {

    

        });

    }

    OrderConfirmPowerTranz() {

      const object: any = {
        Address: null,
        FACRedirect3DSecureResponse: {
          AcqID: null,
          AuthCode: "89bbfa76-9037-4162-8484-ace9ec8b6ede",
          MerID: null,
          OrderID: "S02600001565",
          OriginalResponseCode: null,
          PaddedCardNo: null,
          ReasonCode: null,
          ReasonCodeDesc: "{\"TransactionType\":1,\"Approved\":false,\"TransactionIdentifier\":\"89bbfa76-9037-4162-8484-ace9ec8b6ede\",\"TotalAmount\":8500.0,\"CurrencyCode\":\"188\",\"CardBrand\":\"MasterCard\",\"IsoResponseCode\":\"3D0\",\"ResponseMessage\":\"3D-Secure complete\",\"RiskManagement\":{\"ThreeDSecure\":{\"Eci\":null,\"Cavv\":null,\"Xid\":\"fe84be5a-ef55-49f5-8ba7-9c8685cf4336\",\"AuthenticationStatus\":\"R\",\"StatusReason\":\"01\",\"ProtocolVersion\":\"2.1.0\",\"FingerprintIndicator\":\"U\",\"DsTransId\":\"2eb232f0-5c89-4f1e-993a-8217d2e788b5\",\"ResponseCode\":\"3D0\",\"CardholderInfo\":\"Additional authentication is needed for this transaction, please contact(Issuer Name) at xxx - xxx - xxxx.\"}},\"PanToken\":\"hsysttj0bcoo47r2xs2ntfnqecosod1sx5ynpzbzp2b4wyutt\",\"OrderIdentifier\":\"S02600001565\",\"SpiToken\":\"23e3sg1t3kazzx54jws2cws9apdl8n31wc7a9jvoa7b36smet0-3plyg9wt7wz\"}",
          ReferenceNo: "23e3sg1t3kazzx54jws2cws9apdl8n31wc7a9jvoa7b36smet0-3plyg9wt7wz",
          ResponseCode: null,
          SaveCardName: "TEST",
          Signature: null,
          SignatureMethod: null,
          TokenizedPAN: null
        },
        FavoriteOrderName: null,
        GenerateElectronicInvoice: null,
        IDSavedCard: null,
        IdCart: "9CBA0A9E-AAB1-4FB7-BA12-41B3AB8B9BC4",
        IdClient: 7347,
        IdFavoriteOrder: 0,
        IdFavoriteProduct: 0,
        IsAMEX: null,
        IsCardToSave: true,
        PaymentExtraInfo: "",
        PaymentMethod: null,
        Product: null,
        PurchaseOnBehalf: null,
        Quantity: null,
        UseFAC: true,
        UseSavedCard: false
      };
      this.CartServices.OrderConfirmPowerTranzMethod(object)
      .subscribe(o => {

    

        });

    }

    GetNearestShopAndDeliveryArea() {
      let m: string = '';
      let longitude: number = -84.0756886;
      let latitude: number = 9.9263224;
      this.ClientServices.GetNearestShopAndDeliveryAreaMethod(latitude,longitude).subscribe(o => {

    

        });

    }

  UpdateInformedConsent() {
    let m: string = '';

    this.ClientServices.UpdateInformedConsentMethod(7347).subscribe(o => {



    });

  }

  AddPaymentMethodToCart() {

    const object: any = {
      Address: null,
      FACRedirect3DSecureResponse: null,
      FavoriteOrderName: null,
      GenerateElectronicInvoice: null,
      IDSavedCard: null,
      IdCart: "6D517FC8-3EF8-4F1D-8C79-77DFFAA2E29D",
      IdClient: 32,
      IdFavoriteOrder: 0,
      IdFavoriteProduct: 0,
      IsAMEX: null,
      IsCardToSave: null,
      PaymentExtraInfo: null,
      PaymentMethod: {
        Authorization: "",
        CreditCardMethod: 3,
        IdPaymentMethod: 2,
        ImageURL: null,
        ItIsEmergencyPay: false,
        Name: "Tarjeta",
        PayWith: 7871.17,
        Sort: 0
      },
      Product: null,
      PurchaseOnBehalf: "",
      Quantity: null,
      UseFAC: null,
      UseSavedCard: null
    };
    this.CartServices.AddPaymentMethodToCartMethod(object)
      .subscribe(o => {

  

      });

  }



   prueba_pago (): Observable<any> {
    const url = "https://newlabombadev15.loymarkservices.com/procesando-pago"
    const headers = new HttpHeaders({ 'mi-header': '<div> Hola mundo HelperCheckoutService desde angular </div>' });
    return this.http.get(url, { headers, responseType: 'text' });
  }

  urlParaIframe:any = "";
  prueba_pago2 () {
    this.prueba_pago().subscribe(o => {

      const blob = new Blob([o], { type: 'text/html' });

      this.urlParaIframe = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob))
    })
  }

  getSafeUrl(unsafeUrl: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
  }




  }
