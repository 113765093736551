<main class="main pb-0">
  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="mb-3 color-primary">Entrega</h1>

    <div class="container my-4">
      <div class="row row-cols-auto stepper">
        <div class="col content-col cursor-pointer">
          <span (click)="redirectToCart()">
            <i class="bi bi-cart"></i>
          </span>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col active cursor-pointer">
          <span>
            <i class="bi bi-truck"></i>
          </span>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col cursor-pointer">
          <span>
            <i class="bi bi-credit-card"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-lg-8">

        <div class="border">
          <h3 class="mb-0 text-descuento text-center p-2">Aprovechá un <b>10%</b> de descuento al retirar tu pedido en farmacia</h3>
      </div>


        <div class="d-flex pt-4">
          <div class="form-check me-3">
            <input class="form-check-input" type="radio" name="delivey" id="delivey1" [checked]="!shipping"
              (click)="onChangeShipping()">
            <label class="form-check-label" for="delivey1">
              La Bomba a Domicilio
            </label>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input" type="radio" name="delivey" id="delivery2" [checked]="shipping"
              (click)="onChangeShipping()">
            <label class="form-check-label" for="delivery2">
              Retirar en farmacia
            </label>
          </div>
        </div>

        <!-- Fischel Pickup -->
        <div *ngIf="shipping">
          <h3>Dirección de retiro</h3>
          <form [formGroup]="form">
            <div class="row">
              <div class="col-12 col-lg-6 mb-3">
                <label for="">Seleccioná una provincia</label>
                <select class="form-select contact-input" aria-label="Default select example" formControlName="province"
                  [(ngModel)]="selectedProvince" (ngModelChange)="onChangeProvince($event)">
                  <option *ngFor="let province of provinces" [ngValue]="province.IDAddressProvince">{{ province.Name }}
                  </option>
                </select>
                <div *ngIf="isSubmited" class="d-block text-error">
                  <span *ngIf=" province!.errors?.['required']">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="">Seleccioná un cantón</label>
                <select class="form-select contact-input" aria-label="Default select example" formControlName="canton"
                  [(ngModel)]="selectedCanton" (ngModelChange)="onChangeCanton($event)">
                  <option *ngFor="let canton of cantons" [ngValue]="canton.IDAddressCanton">{{ canton.Name }}</option>
                </select>
                <div *ngIf="isSubmited" class="d-block text-error">
                  <span *ngIf="canton!.errors?.['required'] || selectedCanton === 0">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="">Seleccioná una farmacia</label>
                <select class="form-select contact-input" aria-label="Default select example" formControlName="pharmacie"
                  [(ngModel)]="lastPharmacie" (ngModelChange)="getInfoPharmacie($event)">
                  <option *ngFor="let pharmacie of pharmacies" [ngValue]="pharmacie">{{ pharmacie.Name}}</option>
                </select>
                <div *ngIf="isSubmited" class="d-block text-error">
                  <span
                    *ngIf="pharmacie!.errors?.['required']">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="fecha">Seleccioná la fecha en la que retirarás la orden</label>
                <select class="form-select contact-input" id="date" aria-label="Default select example"
                  formControlName="date" [(ngModel)]="lastDate" (ngModelChange)="onChangePharmacie($event)">
                  <option *ngFor="let date of dates" [ngValue]="date">{{ date }}</option>
                </select>
                <div *ngIf="isSubmited" class="d-block text-error">
                  <span *ngIf="date!.errors?.['required']">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <label for="hour">Seleccioná la hora en la que retirarás la orden:</label>
                <select class="form-select contact-input" id="hour" formControlName="hour" [(ngModel)]="selectedHour" (ngModelChange)="onChangeHour($event)">
                  <option [ngValue]="lastHour" hidden>{{lastHour}}</option>
                  <option *ngFor="let hour of hours" [ngValue]="hour.Time12HrsFormatII">
                    {{ hour.Time12HrsFormatII }}
                  </option>
                </select>
                <div *ngIf="isSubmited" class="d-block text-error">
                  <span *ngIf="hour!.errors?.['required']">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>

            </div>
          </form>
          <div class="mb-2" *ngIf="infoPharmacie != null">
            <div class="card order-data">
              <div class="card-body">
                <h3 class="card-title fw-medium">{{infoPharmacie?.Name}}</h3>
                <p><b>Dirección:</b> {{infoPharmacie?.Address}}</p>
                <p><b>Horario:</b> {{schedules}}</p>
                <p><b>Teléfono:</b> {{infoPharmacie?.Phones[0].Phone}}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Fischel Express -->
        <div *ngIf="!shipping">
          <div class="d-flex align-items-center justify-content-between mb-1">
            <h3 class="mb-0">Dirección de entrega</h3>
            <div class="d-flex align-items-center">
              <i class="bi bi-plus icon-geo"></i>
              <a href="" type="button" data-bs-toggle="modal" data-bs-target="#directionModal"
                (click)="showModalDirection()">
                Agregar nueva dirección
              </a>
            </div>
          </div>

          <div class="card mb-2 border-color" *ngFor="let address of addresses; let i = index">
            <div class="card-body">
              <div class="form-check">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-start">
                    <input class="form-check-input mt-0 me-1" type="radio" name="flexRadioDefault" id="flexRadioDefault{{i}}" [value]="address" [(ngModel)]="selectedAddress" [checked]="isSelected(address)">
                    <h3 class="mb-0">{{address.Name}}</h3>
                  </div>
                  <a (click)="deleteAddress(address)"><i class="bi bi-trash delete-product"></i></a>
                </div>

                <div class="d-flex align-items-center" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseCard' + i"  aria-expanded="false">
                  <small class="mb-0">Detalles</small>
                  <i class="bi bi-chevron-down mt-1 ps-1 icon-collapse"></i>
                </div>
              </div>
              <div class="row collapse mx-2" id="collapseCard{{i}}">
                <p class="">{{address.FullAddress}}</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--orden-->
      <div class="col-12 col-lg-4">
        <div class="card border-color">
          <div class="order-payment">
            <div class="card-body">

              <!-- <form [formGroup]="formCupon" (ngSubmit)="addCoupon()">
                <div class="d-flex mb-2">
                  <input type="text" class="form-control login-input" id="cupon" formControlName="cupon"
                    placeholder="Cupón">
                  <button class="btn btn-outline-secondary ms-1" type="submit">Agregar</button>
                </div>
                <div *ngIf="cupon!.invalid && (cupon!.dirty || cupon!.touched || this.isCouponSubmited)" class="text-danger">
                  <span *ngIf="cupon!.errors?.required">{{validationMsg['required']}}</span>
                  <span *ngIf="cupon!.errors?.minlength">{{validationMsg['minlength'].replace("XXX", cupon!.errors!.minlength.requiredLength)}}</span>
                </div>
              </form> -->

              <div class="mb-2" *ngIf="coupons.length > 0">
                <small class="fw-medium">Cupones agregados</small>
                <div class="d-flex justify-content-between align-items-center" *ngFor="let coupon of coupons">
                  <div class="d-flex align-items-center">
                    <!-- <i class="bi bi-x text-danger pe-2" (click)="deleteCoupon(coupon)"></i> -->
                    <small>{{coupon.Code}}</small>
                  </div>
                </div>
              </div>
              <hr>

              <div class="d-flex justify-content-between">
                <span class="card-text">Subtotal</span>
                <span>{{subTotalPrice | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">Descuento</span>
                <span>{{discount | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">I.V.A</span>
                <span>{{taxes | mycurrency}}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <h3 class="card-text">Total</h3>
                <h3>{{totalPrice | mycurrency}} i.v.a.i.</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <a class="d-grid text-decoration-none">
            <button class="btn btn-primary" type="submit" (click)="validateShipping($event)" [disabled]="isButtonDisabled">Continuar compra</button>
          </a>
        </div>

      </div>
    </div>


  </div>


  <app-modal-general [showModal]="showModalMinPurchase" (showModalChange)="closeModalShipping($event)"
    [modalData]="modal"></app-modal-general>
  <app-modal-general [showModal]="showModalToGoMinPurchase" (showModalChange)="closeModalShippingToGo($event)"
    [modalData]="modalToGo"></app-modal-general>
  <app-modal-general [showModal]="showModalSchedule" (showModalChange)="closeModal($event)"
    [modalData]="modalSchedule"></app-modal-general>
  <app-modal-general [showModal]="showModalShopOutOfTime" (showModalChange)="closeModalShopOutOfTime($event)"
    [modalData]="modalShopOutOfTime"></app-modal-general>
  <app-modal-general [showModal]="showModalShop" (showModalChange)="closeModalShop($event)"
    [modalData]="modalShop"></app-modal-general>
  <app-modal-general [showModal]="showModalCuponNotDelete" (showModalChange)="closeModalCuponNotDelete($event)"
    [modalData]="modalCuponNotDelete"></app-modal-general>
  <app-modal-direction [showModalDirection]="showDirection" [latLng]="{ lat: this.latitude, lng: this.longitude }"
    (closeModal)="closeModalDirection($event)"></app-modal-direction>

    <!-- <app-modal-general [showModal]="showBeneficitCouponsModal" (showModalChange)="closeModalCoupons($event)"
    [modalData]="BeneficitCouponsModal"></app-modal-general>
    <app-modal-general [showModal]="ValidateShowBeneficitCouponsModal" (showModalChange)="closeValidateModalCoupons($event,this.switchcaseOption,this.responseInventory)"
    [modalData]="ValidateBeneficitCouponsModal"></app-modal-general> -->



</main>
