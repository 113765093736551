<div class="container mt-5  mb-5">
  <div class="row justify-content-center">
    <div class="col-lg-8">

      <h1>Métodos del core</h1>

      <div class="accordion" id="metodosAccordion2">
        <!-- Método: Get all landings -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Get products by SKU lists
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetProductsBySKuList()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Descripción detallada:
                    Este  Método se encarga de traer productos por una lista de sku
                    ejemplo : const skuList: string[] = ["8340595", "6891947"];
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Método: Get landing detail by id -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all provinces
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetProvinces()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer todas las provincias.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Cantons By IDProvince
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetCantonsByIDProvince()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer todos los cantones por id Provincia.</p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get shops by types
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetShopsByShopType()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>
                     Método encargado de traer todos los tipos de tiendas, dentro del request se debe enviar lo siguiente:
                    const typesList: number[] = [];
                    idprovincia
                    idcanton
                    iddistrict
                    GetShopsByShopType(typesList, 1, 1, 0);

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Categories Landing By IDProduct Category Parent
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetCategoriesLandingByIDProductCategoryParent()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>se encarga de traer los productos por ID de categoria
                    ejemplo la 373.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              SignIn Clients
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="SignIn()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>


                      LoginName: "emailloymark.com",
                      Password: "Password.01"



                    este  Método permite iniciar sesion en el sitio.


                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              SignUp Clients
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="SignUp()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>este servicio permite hacer el registro de los usuarios.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get All Exchanges
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAllExchanges()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Servicio encargado de traer los canjes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get reduced client
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetReducedClient()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Servicio encargado de traer informacion reducida de un cliente.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get electoral roll personal data
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetElectoralRollPersonData()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Servicio encargado de consultar la informacion de una persona en el padron.".</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Info client
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetInfoClient()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Servicio que trae la informacion del cliente de manera mas detallada.</p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Encrypt Text
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetFaqs()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Descripción detallada
                    sobre el método "Get all landings".</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get categories header
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetCategoriesHeader()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer las categorias que se pintan en el header</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get products By Category
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetProductsByCategory()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer productos por id de categoria..</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Id products By name
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetIdProductsUsingApiToDoSearch()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer productos por nombre y realizar una busqueda..</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Recover password
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="RecoverPassword()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de recuperar la contrasena</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get User Personal Info
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetUserPersonalInfo()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer informacion personal del usuario</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get favorite product by user id
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetFavoriteProducts()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer los productos favoritos del usuario.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Add favorite product to User
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="AddFavoriteProducts()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de agregar un producto favorito..</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Delete favorite product to User
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="DeleteFavoriteProducts()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado para eliminar un producto.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Check if a User has a favorite product
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="CheckIfUserhasAFavoriteProduct()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de verificar si un usuario ya cuenta con un producto favorito.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Reminder Methods
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetReminderTypesMethod()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>
                     Método encargado de traer los tipos de recordatorios.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Update Password Methods
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="UpdateUserPasswordMethod()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de actualizar el password</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get ClientAddress Profile
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetClientAddressProfile()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer las direcciones en el perfil</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Add Address To Cart
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="AddAddressToCart()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de agregar una direccion al carrito.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Client Active Saved Cards
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetClientActiveSavedCards()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer las tarjetas de credito de un usuario</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Inactivate Saved Card
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="InactivateSavedCard()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método que se encarga de inactivar las tarjetas..</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Search Purchase By IDClient
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="SearchPurchaseByIDClient()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer compras por el id del cliente.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Fidelity User Points
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="FidelityUserPoints()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método que se encarga de traer los puntos de fidelity del usuario".</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get parameter by key
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetParameterByKey()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer parametros..</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get GetFidelitySession
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetFidelitySession()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método para traer la sesion de fidelity en el frontend</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get blog by url
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetBlogByUrlContent()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer la info de un blog por una url .</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get  Feature Products
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="getFeaturedProducts()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer productos destacados.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get  recent searches
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="getRecentClientSearchesServices()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer las busquedas recientes del usuario.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- test -->

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Delete  recent searches
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="DeleteClientRecentSearchesServices()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de eliminar las busquedas recientes..</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Save  recent searches
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="SaveClientRecentSearchesServices()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de guardar las busquedas.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Advertising banners
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAdvertisingBanners()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método que trae la publicidad del sitio.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Subject service
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetSubjectServices()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer los asuntos en contactanos</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Contact us service
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetEmailContactUsServices()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de enviar el correo de contactanos.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Add Or Delete Cupon To Cart With Certificate
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="AddOrDeleteCuponToCartWithCertificate()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de agregar o eliminar un cupon con certificado del carrito.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Add Or Delete Cupon To Cart
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="AddOrDeleteCuponToCart()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de agregar o eliminar un cupon del carrito.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Validate Add GiftCertificate Cupon To Cart
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="ValidateAddGiftCertificateCuponToCart()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de validar certificados.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Save Comment By Product
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="SaveCommentByProduct()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de guardar comentarios en un producto.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Search Comment By Product
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="SearchCommentByProduct()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de buscar comentarios en un producto.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Punctuation Values
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetPunctuationValues()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer la puntuacion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              validate Accessibility To Rate
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="validateAccessibilityToRate()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de validar la accesibilidad para puntuar.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- ... otros métodos ... -->

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Molecule
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetMolecule()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de productos de tipo molecula..</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Reduced Products By Link
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="getproductbylink">Parámetro</label>
                  <input type="text" id="getproductbylink" name="getproductbylink" [(ngModel)]="getproductbylink" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetReducedProductsByLink()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer productos por link</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Add products to cart
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="AddProductToCart()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de agregar productos.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Send mail
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetEmailContactUsServices()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de enviar el correo.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Informed consent form
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetInformedConsentServices()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer el consentimiento informado.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get shops product Details by sku
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetShopsProductDetail()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer tiendas por sku</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Product Reminders By User
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetProductRemindersByUser()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer los recordatorios por id usuario.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Create update delete reminder
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="CEDProductReminderMethod()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de eliminar crear o editar un recordatorio</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              GetOrCreateCart
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetOrCreateCart()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer o crear un carrito.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              GetCartSummary
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetCartSummary()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer el resumen del carrito con validaciones y actualizaciones.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              GetRedemptionRules
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetRedemptionRules()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer las reglas de canjes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              InsertDatalakeCartProductEcommerce
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="InsertDatalakeCartProductEcommerce()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de  insertar productos al datalake</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              InsertDatalakeLoginEcommerce
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="InsertDatalakeLoginEcommerce()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de instar informacion de quien inicia sesion..</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              GetProductsSummary
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetProductsSummary()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer el carrito sin validaciones.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              ValidateProductByLink
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="ValidateProductByLink()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de validar un producto por link</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Encriptacion
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="encryptData()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de encriptar la informacion</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Descrypt
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="decryptData()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de desencriptar la informacion</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              check if product earns points
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="CheckIfProductEarnsPoints()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de verificar sin un producto acumula puntos.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Shops By Location
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetShopsByLocation()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer tiendas por ubicacion</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Products Inventory
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetProductsInventory()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p> Método encargado de traer productos por inventarios.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

<!-- test 2 -->

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Get Products Inventory Without Inventory
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="GetProductsInventoryWithoutInventory()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de productos q no tiene  inventario</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Get Config Value
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="GetConfigValue()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de traer configuraciones del core</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Clear Cart
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="ClearCart()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de limpiar el carrito</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Remove Product From Cart
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="RemoveProductFromCart()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de quitar un producto del carrito</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Validate Schedule Availability
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="ValidateScheduleAvailability()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de validar disponibilidad de horarios.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Get Shop Info By AddressMethod
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="GetShopInfoByAddressMethod()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de traer tiendas por direcciones</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Get Shops By Shop Type V2
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="GetShopsByShopTypeV2()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de traer informacion de tiendas detalladas</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Get Impulse Products
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="GetImpulseProducts()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de traer productos de impulso.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Get Power TranzHTML
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="GetPowerTranzHTMLResponseByPurchaseID()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de traer el HTML de power tranz.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Preorder Power Tranz
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="PreorderPowerTranz()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de crear la preorden de la compra mediante power tranz.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
      aria-expanded="false" aria-controls="collapseTwo">
      Update Cart Info Light
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
    data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="UpdateCartInfoLight()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método encargado de actualizar la información del carrito.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
      aria-expanded="false" aria-controls="collapseTwo">
      Validate Cart Light
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
    data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="ValidateCartLight()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método que permite validar la información del carrito.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
      aria-expanded="false" aria-controls="collapseTwo">
      Order ConfirmPower Tranz
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
    data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="OrderConfirmPowerTranz()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método que permite confirmar mediante el proceso de power tranz el pago de la orden.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
      aria-expanded="false" aria-controls="collapseTwo">
      Get Nearest Shop And DeliveryArea
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
    data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="GetNearestShopAndDeliveryArea()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método que permite obtener una tienda y zona con la longitud y latitud seleccionada en el mapa.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
      aria-expanded="false" aria-controls="collapseTwo">
      Update Informed Consent
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
    data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="UpdateInformedConsent()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método que permite actualizar el consentimiento informado.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
      aria-expanded="false" aria-controls="collapseTwo">
      Add Payment Method ToCart
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
    data-bs-parent="#metodosAccordion">
    <div class="accordion-body">
      <div class="row">
        <div class="col-md-3">
          <label for="metodo1">Parámetro</label>
          <input type="text" id="metodo1" name="metodo1" class="form-control">
        </div>
        <div class="col-md-2">
          <button (click)="AddPaymentMethodToCart()" class="btn btn-primary mt-4">Enviar</button>
        </div>
        <div class="col-md-7">
          <label>Descripción:</label>
          <p> Método que permite agregar el método de pago al carrito.</p>
        </div>
      </div>
    </div>
  </div>
</div>


      </div>

    </div>




  </div>



</div>


<div class="container mt-5  mb-5">
  <div class="row justify-content-center">
    <div class="col-lg-8">

      <h1>Métodos de sitefinity</h1>

      <div class="accordion" id="metodosAccordion">
        <!-- Método: Get all landings -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Get all landings
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAllLandings()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Se encarga de traer informacion de marcas destacadas.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Método: Get landing detail by id -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get landing detail by id
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetLandingDetailByID()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Encargado de traer detalle de las marcas detacadas</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all document Types
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAllDocumentsTypes()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>ENcargado de traer todos los tipos de documentos..</p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all Legal document
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAllLegalDocuments()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Encargado de traer todos los documentos legales.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all docuemnts by ID Type
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAllDocumentsByIdType()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Encargado de traer los documentos legales por id Tipo.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all sitefinity Pages
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetSitefinityPagesMethod()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Metodo encargado de traer informacion de las paginas de sitefinity.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              save error logs
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="SaveErrorLogs()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Se encarga de guardar errores en el sistema</p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all blog list
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAllBlogs()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Trae todo los blogs</p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all tags list
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetAllTags()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Trae los tags de los blogs</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get all blogs by id tags
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetBlogByIdBTags()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Trae los blogs por id de tags</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get detail blog
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetBlogDetail()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Trae parametros de sitefinity </p>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Get Faqs
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-3">
                  <label for="metodo1">Parámetro</label>
                  <input type="text" id="metodo1" name="metodo1" class="form-control">
                </div>
                <div class="col-md-2">
                  <button (click)="GetFaqs()" class="btn btn-primary mt-4">Enviar</button>
                </div>
                <div class="col-md-7">
                  <label>Descripción:</label>
                  <p>Trae modulo de respuestas y preguntas.</p>
                </div>
              </div>
            </div>
          </div>
        </div>



              <!-- Método: Get landing detail by id -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                   pago
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#metodosAccordion">
                  <div class="accordion-body">
                    <div class="row">
                      <div class="col-md-3">
                        <label for="metodo1">Parámetro</label>
                        <input type="text" id="metodo1" name="metodo1" class="form-control">
                      </div>
                      <div class="col-md-2">
                        <button (click)="prueba_pago2()" class="btn btn-primary mt-4">Enviar</button>
                      </div>
                      <div class="col-md-7">
                        <label>Descripción:</label>
                        <p> Prueba pago..</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

        <!-- ... otros métodos ... -->
      </div>

    </div>


    <iframe [src]="urlParaIframe" width="600" height="400"></iframe>

  </div>



</div>
