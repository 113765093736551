<div class="modal fade" id="modalAddCard" #modalAddCard tabindex="-1" aria-labelledby="modalAddCardLabel"
  aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="modalAddCardLabel">Agregar tarjeta</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 mb-3">
            <label for="exampleFormControlInput1" class="form-label">Nombre Completo</label>
            <input type="text" class="form-control contact-input" id="exampleFormControlInput1">
          </div>
          <div class="col-12 mb-3">
            <label for="exampleFormControlInput1" class="form-label">Número de tarjeta</label>
            <input type="text" class="form-control contact-input" id="exampleFormControlInput1">
          </div>
          <div class="col-12 mb-3">
            <label for="exampleFormControlInput1" class="form-label">Número LifeMiles (opcional)</label>
            <input type="text" class="form-control contact-input" id="exampleFormControlInput1">
          </div>
          <div class="col-6 mb-3">
            <label for="">Fecha de expiración</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Mes</option>
              <option value="1">Plan paciente</option>
              <option value="2">Ventas La Bomba</option>
              <option value="3">Plan beneficios farmacéuticos</option>
              <option value="3">Consulta general</option>
              <option value="3">Ventas institucionales</option>
            </select>

          </div>

          <div class="col-6 mb-3">
            <label for="">Año</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Seleccione una opción</option>
              <option value="1">Plan paciente</option>
              <option value="2">Ventas La Bomba</option>
              <option value="3">Plan beneficios farmacéuticos</option>
              <option value="3">Consulta general</option>
              <option value="3">Ventas institucionales</option>
            </select>

          </div>

          <div class="col-12 mb-3">
            <label for="exampleFormControlInput1" class="form-label">Código CCV</label>
            <input type="text" class="form-control contact-input" id="exampleFormControlInput1">
          </div>
        </div>




      </div>
      <div class="modal-footer">
        <p>*Su factura electrónica será enviada automáticamente al correo electrónico de su cuenta una vez
          finalizada la compra. Compra definitiva.</p>
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="close()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="accept()">Agregar</button>
      </div>
    </div>
  </div>
</div>
