import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { SitefinityPages, ResponseSitefinityPages } from '../../models/sitefinity/sitefinity-pages.model'
import { MessagesService } from './messages.service';
import { Title, Meta } from '@angular/platform-browser';

const settings = new SitefinitySettings();

@Injectable({
  providedIn: 'root'
})
export class SitefinitypagesService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService, private titleService: Title, private meta: Meta) { }

  private GetSitefinityPagesUrl = settings.SitefinityServicesUrl + '/api/default/pages';


  GetSitefinityPages2(): ResponseSitefinityPages {



    const responseServices: ResponseSitefinityPages = new ResponseSitefinityPages();



    try {


      const servicesResponse = this.http.get<any>(this.GetSitefinityPagesUrl);
      servicesResponse.subscribe((response) => {
        if (response.value == undefined) {
          responseServices.response.code = 'getSitefinityPages-fail01';
          this.MessagesService_.GetMessagesByKey('getSitefinityPages-fail01', '6').subscribe(data => {
            var messageResponse = data.value[0];
            if (messageResponse == undefined) {
              responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getSitefinityPages-fail01';
              responseServices.response.alias = 'getSitefinityPages-fail01';
              responseServices.response.status = true;


            }
            else {

              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = true;

            }

            responseServices.response.message = messageResponse.message;
            responseServices.response.alias = messageResponse.aliascode;
            responseServices.response.status = false;
            return responseServices;


          })

        }

        const Odata = response.value;
        responseServices.response.code = 'getSitefinityPages-success';
        this.MessagesService_.GetMessagesByKey('getSitefinityPages-success', '6').subscribe(data => {
          var messageResponse = data.value[0];

          if (messageResponse == undefined) {
            responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getSitefinityPages-success';
            responseServices.response.alias = 'getSitefinityPages-success';
            responseServices.response.status = true;


          }
          else {

            responseServices.response.message = messageResponse.message;
            responseServices.response.alias = messageResponse.aliascode;
            responseServices.response.status = true;

          }



        });


        Odata.forEach((DataItem: any) => {

          const items = new SitefinityPages();
          items.id = DataItem.Id;
          items.title = DataItem.Title;
          items.url = DataItem.ViewUrl;
          responseServices.sitefinityPage.push(items);

        });


        return responseServices

      });
      return responseServices


    } catch (error) {
      responseServices.response.code = 'getSitefinityPages-error';
      this.MessagesService_.GetMessagesByKey('getdocumentType-error', '6').subscribe(data => {
        var messageResponse = data.value[0];
        if (messageResponse == undefined) {
          responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getSitefinityPages-error';
          responseServices.response.alias = 'getSitefinityPages-error';
          responseServices.response.status = true;


        }
        else {

          responseServices.response.message = messageResponse.message;
          responseServices.response.alias = messageResponse.aliascode;
          responseServices.response.status = true;

        }

        responseServices.response.message = messageResponse.message;
        responseServices.response.alias = messageResponse.aliascode;
        responseServices.response.status = false;


      });
      return responseServices;

    }

  }

  public GetSitefinityPages(): Observable<any> {
    const url = this.GetSitefinityPagesUrl
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.get(url);
  }

  public GetSitefinityDetail(id: string): Observable<any> {
    const url = this.GetSitefinityPagesUrl + '(' + id + ')';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.get(url);
  }

  /**
   * Establece la página de detalle para un ID dado.
   * @param id - El ID de la página.
   */
  setDetailPage(id: string) {
    // Obtener los detalles de Sitefinity para el ID dado
    this.GetSitefinityDetail(id).subscribe(res => {

      // Establecer el título, descripción y palabras clave en base a la respuesta
      const title = res.HtmlTitle ? res.HtmlTitle : '';
      const description = res.Description ? res.Description : '';
      const keywords = res.keywordsSearch ? res.keywordsSearch : '';

      this.setDataPage(title, description, keywords);
    });
  }

  /**
   * Establece los datos para la página.
   *
   * @param {string} title - El nuevo título para la página.
   * @param {string} description - La nueva descripción para la página.
   * @param {string} keywords - Las nuevas palabras clave para la página.
   */
  setDataPage(title: string, description: string, keywords: string) {
    // Actualizar el título de la página
    this.titleService.setTitle(title);

    // Actualizar las etiquetas meta para descripción y palabras clave
    // Title
    if (this.meta.getTag('property="title"')) {
      this.meta.removeTag("property='title''");
    }

    // Descripción
    if (this.meta.getTag('property="description"')) {
      this.meta.removeTag("property='description''");
    }
    this.meta.updateTag({ name: 'description', content: description });

    const metaKeywordsList = document.querySelectorAll('meta[name="keywords"]');

    // Eliminar todos los elementos meta existentes con el mismo nombre
    metaKeywordsList.forEach(meta => meta.remove());

    // Crear un nuevo elemento meta
    const newMetaKeywords = document.createElement('meta');
    newMetaKeywords.setAttribute('name', 'keywords');
    // Eliminar cualquier contenido existente en el atributo 'content'
    newMetaKeywords.removeAttribute('content');
    // Agregar el nuevo contenido al atributo 'content'
    newMetaKeywords.setAttribute('content', keywords);

    // Agregar el nuevo elemento meta al head de la página
    document.head.appendChild(newMetaKeywords);
  }


  setMetadataOpenGraph(title: string, description: string, image: string, url: string) {
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:type'");
    this.meta.removeTag("property='og:url'");
    this.meta.removeTag("property='og:image'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:site_name'");
    this.meta.removeTag("property='og:image:alt'");

    this.meta.removeTag("name='twitter:card'");
    this.meta.removeTag("name='twitter:site'");
    this.meta.removeTag("name='twitter:creator'");
    this.meta.removeTag("name='twitter:description'");
    this.meta.removeTag("name='twitter:image'");
    this.meta.removeTag("name='twitter:url'");

    // <meta name="twitter:image" content="URL de la imagen de la portada">
    // <meta name="twitter:url" content="URL de la página del producto">

    if (title.length > 0) {
      this.meta.addTag({ property: 'og:title', content: title });
      this.meta.addTag({ property: 'og:type', content: "website" });
    }

    if (url.length > 0) {
      this.meta.addTag({ property: 'og:url', content: url });

    }

    if (image.length > 0) {
      this.meta.addTag({ property: 'og:image', content: image });

    }
    if (description.length > 0) {

      this.meta.addTag({ property: 'og:description', content: description });

    }
    else
    {
      this.meta.addTag({ property: 'og:description', content: "Descripción para "+title });

    }

     this.meta.addTag({ property: 'og:site_name', content: "Farmacia La Bomba Costa Rica" });
    this.meta.addTag({ property: 'og:image:alt', content: "Farmacia La Bomba Costa Rica" });


    //twitter
    if (image.length > 0) {

      this.meta.addTag({ property: 'twitter:card', content: 'summary_large_image' });

    }
    if (title.length > 0) {

      this.meta.addTag({ property: 'twitter:title', content: title });
    }
    if (description.length > 0) {

      this.meta.addTag({ property: 'twitter:description', content: description });
    }

    if (url.length > 0) {
      this.meta.addTag({ property: 'twitter:image', content: image });
      this.meta.addTag({ property: 'twitter:site', content: 'La Bomba' });

    }


  }

  removeMetaTags() {
    if (this.meta.getTag('property="og:title"')) {
      this.meta.removeTag('property="og:title"');
    }

    if (this.meta.getTag('property="og:description"')) {
      this.meta.removeTag('property="og:description"');
    }

    if (this.meta.getTag('property="og:image"')) {
      this.meta.removeTag('property="og:image"');
    }

    if (this.meta.getTag('property="og:url"')) {
      this.meta.removeTag('property="og:url"');
    }
  }

 encodeBase64(data: string): string {
    return btoa(data);
  }
  setKeywordsByProduct(keywords: string) {
    if (this.meta.getTag('property="keywords"')) {
      this.meta.removeTag("property='keywords''");
    }
    this.meta.updateTag({ name: 'keywords', content: keywords });

  }
}
