import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { MessagesService } from 'src/app/services/sitefinity/messages.service';
import { CleanService } from 'src/app/services/core/clean.service';
import { WindowReferenceService } from 'src/app/services/shared/windows-reference.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-modal-card',
  templateUrl: './modal-card.component.html',
  styleUrls: ['./modal-card.component.css']
})
export class ModalCardComponent implements OnInit {

  @Input() showModal: boolean = false;
  @Input() cartId!: string;
  @Input() userId!: string;
  @Input() totalPrice: number = 0;
  form!: FormGroup;
  settings: SitefinitySettings = new SitefinitySettings;
  validationMsg = this.settings.validationMsgTypeMap;
  years = this.settings.cardYear;
  months = this.settings.cardMonths
  max: string = '16';
  isSubmited: boolean = false;
  isAMEX: boolean = false;
  showMessage: boolean = false;
  messageAmex!: string;
  private windowDataLayer: any;
  minPurchase!: string;


  constructor(
    private fb: FormBuilder,
    private loader: LoadingService,
    private cartService: CartService,
    private notificationService: NotificationService,
    private utilitiesService: UtilitiesServicesService,
    private router: Router,
    private messageService: MessagesService,
    private CleanService: CleanService,
    private _windowRef: WindowReferenceService,
    private _utilitiesService: UtilitiesService
  ){
    this.form = this.fb.group({
      Name: ['', [Validators.required]],
      CardNumber: ['', [Validators.required, Validators.minLength(16), Validators.maxLength(16), Validators.pattern(this.settings.regexPatternsTypeMap['number'])]],
      Month: ['', [Validators.required]],
      Year: ['', [Validators.required]],
      Code: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      NumberLife: ['']
    });

    this.CleanService.resetFormObservable.subscribe(() => {
      this.resetForm();
    });

    this.windowDataLayer = _windowRef.nativeWindow;
  }

  resetForm() {
    this.form.reset({
      Name: '',
      CardNumber: '',
      Month: '',
      Year: '',
      Code: '',
      NumberLife: ''
    });
  }

  get CardNumber() { return this.form.get('CardNumber'); }
  get Name() { return this.form.get('Name'); }
  get Code() { return this.form.get('Code'); }
  get Year() { return this.form.get('Year'); }
  get Month() { return this.form.get('Month'); }
  get NumberLife() { return this.form.get('NumberLife'); }

  ngOnInit() {



    this.years;
    this.months;
  }

  keyPressCardNumber(event: any) {
    var rta = false;
    var strPhoneValue: string = '' + this.form.controls["CardNumber"].value
    if (strPhoneValue.length >= 16) {
      event.preventDefault();
      rta = false;
    }
    else {
      var charCode = (event.which) ? event.which : event.keyCode;
      // Only Numbers 0-9
      if ((charCode == 101) || (charCode == 69) || (charCode == 45) || (charCode == 43) || (charCode == 46)) {
        event.preventDefault();
        rta = false;
      }
      else if ((charCode < 48 || charCode > 57) //numbers
      ) {
        event.preventDefault();
        rta = false;
      } else {
        rta = true;
      }
    }
    return rta
  }

  keyPressCode(event: any) {
    var rta = false;
    var strPhoneValue: string = '' + this.form.controls["Code"].value
    if (strPhoneValue.length >= 16) {
      event.preventDefault();
      rta = false;
    }
    else {
      var charCode = (event.which) ? event.which : event.keyCode;
      // Only Numbers 0-9
      if ((charCode == 101) || (charCode == 69) || (charCode == 45) || (charCode == 43) || (charCode == 46)) {
        event.preventDefault();
        rta = false;
      }
      else if ((charCode < 48 || charCode > 57) //numbers
      ) {
        event.preventDefault();
        rta = false;
      } else {
        rta = true;
      }
    }
    return rta
  }

  async processPayment() {
    this.loader.showSpinner();
    if (this.form.invalid) {
      this.isSubmited = true
      this.loader.hideSpinner();
    }
    else {
      this.isAMEX = false;
      if (this.form.value.CardNumber.startsWith("3")) {
        this.isAMEX = true;
        this.messageService.getMessagesMethod('AmexFail-01', '1').subscribe(m => {
          this.messageAmex = m.message;
          this.showMessage = true;
        });
        setTimeout(() => {
          this.loader.hideSpinner();
        }, 1000);
      }
      else {
        const object: any = {
          Address: null,
          FACRedirect3DSecureResponse: null,
          FavoriteOrderName: null,
          GenerateElectronicInvoice: null,
          IDSavedCard: null,
          IdCart: this.cartId,
          IdClient: parseInt(this.userId),
          IdFavoriteOrder: 0,
          IdFavoriteProduct: 0,
          IsAMEX: this.isAMEX,
          IsCardToSave: null,
          PaymentExtraInfo: null,
          PaymentMethod: {
            Authorization: "",
            CreditCardMethod: 3,
            IdPaymentMethod: 2,
            ImageURL: null,
            ItIsEmergencyPay: false,
            Name: "Tarjeta",
            PayWith: this.totalPrice,
            Sort: 0
          },
          Product: null,
          PurchaseOnBehalf: "",
          Quantity: null,
          UseFAC: null,
          UseSavedCard: null
        };
        const name = this.form.value.Name;
        let lifeMiles = 'N/A';
        if (this.form.value.NumberLife != '') {
          lifeMiles = this.form.value.NumberLife;
        }
        let enviroment = 'dev';
        if (window.location.hostname === 'localhost')
          enviroment = 'local';

        let year: string = this.form.value.Year;
        year = year.substring(year.length - 2, year.length);
        const expirationDate = year + this.form.value.Month;
        const toEncrtp = this.form.value.CardNumber + '|' + this.form.value.Name + '|' + expirationDate + "|" + this.form.value.Code;
        let paymentExtraInfo = await this.utilitiesService.encryptDataMD5(toEncrtp, true, 'qhUQsZLvWzxg2T9V');

        this.cartService.GetCartSummaryMethod(this.cartId).subscribe(cs => {

          if (cs.response.code === 'OK0001') {
            this.cartService.AddPaymentMethodToCartMethod(object).subscribe(obj => {
              if (obj.response.code === "OK0001") {
                if (this.isAMEX) {
                  this.messageService.getMessagesMethod('AmexFail-01', '1').subscribe(m => {
                    this.messageAmex = m.message;
                    this.showMessage = true;
                  });
                }
                else {
                  this.cartService.PreorderPowerTranzMethod(null, this.cartId, parseInt(this.userId), this.isAMEX, true, paymentExtraInfo, true, false).subscribe(res => {

                    let cart:any = cs.responseObject;
                    let itemDatalayer: any = [];
                    cart.ProductQueue.forEach((currentProduct: any) => {
                      let itemProductDatalayer = {
                        'item_name' : '' + currentProduct.Name,
                        'item_category' : '',
                        'price': currentProduct.PriceWithDiscount > 0?currentProduct.PriceWithDiscount:currentProduct.CurrentTotalPrice,
                        'item_id' : currentProduct.Sku,
                        'quantity': currentProduct.Quantity
                      }
                      itemDatalayer.push(itemProductDatalayer);
                    });

                    this.windowDataLayer.dataLayer.push({
                      'event' : 'begin_checkout',
                      'currency': 'CRC',
                      'value': cart.PurchaseInfo.TotalWithDiscount>0?cart.PurchaseInfo.TotalWithDiscount:cart.PurchaseInfo.total,
                      'items' : itemDatalayer
                    });

                    if (res.response.code === "OK0001") {
                      this.saveDatalayerOrderConfirm(itemDatalayer,cart,res.responseObject.Submit);
                      const orderId = res.responseObject.Submit;
                      const cartId = this.cartId;
                      const clientId = this.userId;
                      const status = true;
                      this.utilitiesService.saveValueStorage(orderId, "order");
                      this.router.navigate(['/procesando-compra'], {state: { orderId, cartId, clientId, name, lifeMiles, enviroment, status }});
                      this.loader.hideSpinner();
                    }
                    else {
                      this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
                      this.loader.hideSpinner();
                    }
                  });
                }
              }
              else {
                this.notificationService.SendNotification(obj.response.type, obj.response.titleMessage, obj.response.message);
                this.loader.hideSpinner();
              }
            });
          }
          else {


            if(cs.response.code == "CARTEPA0001")
              {

                this._utilitiesService.GetConfigValueMethod("ExpressMinPurchaseAmount").subscribe(res => {
                  if (res) {
                    this.minPurchase = res;
                    this.messageService.getMessagesMethod('CARTEPA0001', '1').subscribe(message => {
                      this.loader.hideSpinner();
                      this.validateMinPurchase(this.minPurchase, message);
                    });
                  }
                });


               // this.notificationService.SendNotification(obj.response.type, obj.response.titleMessage, obj.response.message);

              }
              else
              {
                 this.loader.hideSpinner();
            this.notificationService.SendNotification(cs.response.type, cs.response.titleMessage, cs.response.message);
          }



           // this.notificationService.SendNotification(cs.response.type, cs.response.titleMessage, cs.response.message);
          }
        });
      }
    }
  }

  saveDatalayerOrderConfirm(itemDatalayer: any, cart: any,idOrder:any){
    let infoDatalayer ={
      'event' : 'purchase',
      'transaction_id':idOrder,
      'tax': cart.PurchaseInfo.TaxesAmount,
      'shipping': 0,
      'currency': 'CRC',
      'value': cart.PurchaseInfo.SubTotalWithoutTaxes,
      'items' : itemDatalayer
    }

    let infoencrypt = this.utilitiesService.encryptDataMD5(JSON.stringify(infoDatalayer), true, 'qhUQsZLvWzxg2T9V');
    this.utilitiesService.saveValueStorage(infoencrypt, "DatalayerCompra");
  }

  validateMinPurchase(minPurchase: string, message?: any): void {

    if (this.totalPrice < parseInt(minPurchase)) {
      const currencyCode: string = 'CRC';
      const locale: string = 'es';
      const symbol = getCurrencySymbol(currencyCode, 'narrow');
      const digitsInfo: string = '1.2-2';

      let min = formatCurrency(parseInt(minPurchase), locale, symbol, currencyCode, digitsInfo);
      min = min.replace(/\s+/g, '');
      let price = parseInt(minPurchase) - this.totalPrice;
      let formatPrice = formatCurrency(price, locale, symbol, currencyCode, digitsInfo);
      formatPrice = formatPrice.replace(/\s+/g, '');
      let priceMinPurchase = min;
      let updateMessage = message.message.replace('₡* *MinExpress* *', formatPrice);
      updateMessage = updateMessage.replace(/\s+/g, ' ');
      let text = updateMessage.replace('₡* *MinExpress2* *', priceMinPurchase);
      text = text.replace(/\s+/g, ' ');
      const finalText = text.trim();

      this.notificationService.SendNotification("alert", message.titleMessage, finalText);


      // this.modal.Title = message.titleMessage;
      // this.modal.Text = finalText;
      // this.modal.BtnConfirmText = message.buttonAcceptText;
      // this.modal.BtnCancelText = message.buttonCancelText;
      // this.showModalMinPurchase = true;
  }
}

  resetValue() {
    this.isSubmited = false;
  }
}
