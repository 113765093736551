import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { CheckoutService } from 'src/app/services/core/checkout.service';
import {HelperCheckoutService} from 'src/app/services/core/helper-checkout.service'
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { SharedService } from 'src/app/services/shared/shared.service';
declare var window: any;
@Component({
  selector: 'app-modal-producto-faltante',
  templateUrl: './modal-producto-faltante.component.html',
  styleUrls: ['./modal-producto-faltante.component.css']
})
export class ModalProductoFaltanteComponent {

  @Input() showModal: boolean = false;
  @Input() product: any;
  @Input() productInventory: any;
  @Input() cartId!: string;
  @Input() userId!: string;
  @Output() closeModal = new EventEmitter<boolean>();

  error: boolean = false;
  modal: any;
  isSubmited: boolean = false;
  form!: FormGroup;
  settings: SitefinitySettings = new SitefinitySettings;
  validationMsg = this.settings.validationMsgTypeMap;
  messageError!: string;
  constructor(
    private fb: FormBuilder,
    private HelperCheckoutService: HelperCheckoutService,
    private checkoutService: CheckoutService,
    private notificationService: NotificationService,
    private loader: LoadingService,
    private sharedService: SharedService
  ){
    this.form = this.fb.group({
      time: ['', [Validators.required]],
    });
  }

  get time() { return this.form.get('time'); }

  close() {
    this.form = this.fb.group({
      time: ['']
    });

    this.loader.showSpinner();
    const object: any = {
      Address: null,
      FACRedirect3DSecureResponse: null,
      FavoriteOrderName: null,
      GenerateElectronicInvoice: null,
      IDSavedCard: null,
      IdCart: this.cartId,
      IdClient: this.userId,
      IdFavoriteOrder: 0,
      IdFavoriteProduct: 0,
      IsAMEX: null,
      IsCardToSave: null,
      PaymentExtraInfo: null,
      PaymentMethod: null,
      Product: this.product,
      PurchaseOnBehalf: null,
      Quantity: null,
      UseFAC: null,
      UseSavedCard: null
    };

    this.checkoutService.RemoveProductFromCartMethod(object).subscribe((res: any) => {
      if (res) {
        this.sharedService.updateCart();
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        //this.modal.hide();
        setTimeout(() => {
          this.closeModal.emit(true);
        }, 500);
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        this.modal.hide();
      }
      this.loader.hideSpinner();
    });
  }

  AddNotification() {
    this.isSubmited = true;
    if (this.form.invalid) {
      this.isSubmited = true;
    }
    else {
      this.loader.showSpinner();
      this.checkoutService.GetProductsInventoryMethod(this.productInventory).subscribe((pi: any) => {
        if (pi.object.length > 0) {
          let sku = this.product.SelectedAttributes.length > 0 ? this.product.SelectedAttributes[0].Sku3 : this.product.Sku; 
          const product = pi.object.find((p: any) => p.Sku === sku);
          const request = {
            IDClient: this.userId,
            ProductSku: product.Sku,
            Quantity: product.ProductTotalQuantity,
            StockQuantity: product.InventoryQuantity,
            IDCart: this.cartId,
            MaximumNoticePeriod: this.form.value.time,
            SendNotification: true
          };

          this.HelperCheckoutService.AddOrDeleteProductsFromInventoryMethod(request).subscribe(res => {
            if (res.response.code === "OK0001") {
              this.close();
            }
          });
        }
        else {
          this.notificationService.SendNotification(pi.response.type, pi.response.titleMessage, pi.response.message);
          this.error = true;
          this.messageError = pi.response.error;
          this.close();
        }
      });
    }
  }
}
