import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import * as CryptoJS from 'crypto-js';
import { SessionStorageResponse } from 'src/app/models/sitefinity/storage.model';
import { UtilitiesService } from '../core/utilities.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';
import { Meta } from '@angular/platform-browser';

interface Error {
  AppMessage: string;
  AppModule: string;
  AppRequest: string;
  AppResponse: string;
}
interface Log {
  AppMessage: string;
  AppModule: string;

}

const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})
export class UtilitiesServicesService {
  private PutErrors = 'insertErrorServices';
  private PutLogs = 'insertLogServices';

  private secretKey = '6XUcvG2bCT0bx5K1';
  private iv = CryptoJS.lib.WordArray.random(16);
  private ivHex = "4d657373616765496e697469616c5665";
  private sessionMaxTimeParameter = "-1"


  constructor(
    private http: HttpClient,
    private utils: UtilitiesService,
    private router: Router,
    private loader: LoadingService,
    private meta: Meta
  ){
    interval(60000).subscribe(() => {
      if (this.isUserLoggedIn()) {
        this.checkSession();
      }
    });
  }

  private checkSession(): void {
    if (!this.isUserLoggedIn()) {
      // La sesión ha expirado, redirigir a la página de inicio de sesión
      this.loader.hideSpinner();
      window.location.href = environment.urlSite + '/iniciosesion' ;
    }
  }

  async insertError(appMessage: string, appModule: string, appRequest: string, AppResponse: string) {

    const error: Error = {
      AppMessage: appMessage,
      AppModule: appModule,
      AppRequest: appRequest,
      AppResponse: AppResponse,
    };


    var json = JSON.stringify(error);
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*' // Reemplaza '*' con el origen correcto si es necesario
    });
    var url = settings.SitefinityServicesUrl + this.PutErrors;
    const response = await this.http.post(url, error, { headers });
    response.subscribe((responseCore) => {

    })
  }

  insertErrorObservable(appMessage: string, appModule: string, appRequest: string, AppResponse: string): Observable<any> {

    const error: Error = {
      AppMessage: appMessage,
      AppModule: appModule,
      AppRequest: appRequest,
      AppResponse: AppResponse,
    };

    var json = JSON.stringify(error);

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*' // Reemplaza '*' con el origen correcto si es necesario
    });

    var url = settings.SitefinityServicesUrl + this.PutErrors;
    const response = this.http.post(url, error, { headers });
    return response;
  }

  insertInformationCallProcessing(html: string, userHeader: any, spiToken: string): Observable<any> {

    const call: any = {
      html: html,
      userHeader: userHeader,
      spiToken: spiToken,
    };

    var json = JSON.stringify(call);
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*' // Reemplaza '*' con el origen correcto si es necesario
    });
    var url = settings.SitefinityServicesUrl + 'insertInformationCallProcessing';
    const response = this.http.post(url, call, { headers });
    return response;
  }

  insertLog(appMessage: string, appModule: string) {
    const log: Log = {
      AppMessage: appMessage,
      AppModule: appModule,

    };

    var url = settings.SitefinityServicesUrl + this.PutLogs;
    this.http.put(url, log).subscribe();
  }

  // Método de encriptación
  encryptData(data: any): string {
    const iv = this.hexToWordArray(this.ivHex);
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);

    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(data),
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    return encrypted.toString();
  }

  Base64Decode(encodedText: string): string {
    try {
      const decodedText = atob(encodedText);
      return decodedText;
    } catch (error) {
      // Manejar errores de decodificación aquí
      return '';
    }
  }

  // Método de desencriptación
  decryptData(encryptedData: string): any {
    try {

      const iv = this.hexToWordArray(this.ivHex);
      const key = CryptoJS.enc.Utf8.parse(this.secretKey);

      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        key,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );

      const decryptedString = decryptedData.toString(CryptoJS.enc.Utf8);
      return decryptedString;
    } catch (error) {
      throw error;
    }
  }

  saveValueStorage(object: any, storageName: string): boolean {
    const objectSerialize = JSON.stringify(object);
    window.sessionStorage.setItem(storageName, objectSerialize);
    return true;
  }

  getValueStorage(storageName: string): SessionStorageResponse {
    // Recuperar el objeto almacenado en el sessionStorage
    const object = window.sessionStorage.getItem(storageName);
    const objectDeserialize: SessionStorageResponse = object ? JSON.parse(object) : null;
    return objectDeserialize;
  }

  updateValueStorage(object: any, storageName: string): SessionStorageResponse {
    const objectSerialize = JSON.stringify(object);
    window.sessionStorage.setItem(storageName, objectSerialize);
    const objectDeserialize: SessionStorageResponse = objectSerialize ? JSON.parse(objectSerialize) : null;
    return objectDeserialize;
  }

  deleteValueStorage(storageName: string, product?: any): boolean {
    let object: any = this.getValueStorage(storageName);
    let sessionDelete: boolean = false;
    if (object?.length > 1) {
      // Verifica si el producto a eliminar existe en los datos almacenados
      const productToDelete = object?.find((p: any) => p.IdProduct === product.IdProduct);
      let index = object.findIndex((p: any) => p.IdProduct === product.IdProduct);
      if (productToDelete) {
        // Si se encuentra el producto, elimínalo del array
        object.splice(index, 1);

        // Almacena los datos actualizados nuevamente en sessionStorage
        window.sessionStorage.setItem(storageName, JSON.stringify(object));

        sessionDelete = true; // Indica que se eliminó el producto con éxito
      }
    }
    else {
      window.sessionStorage.removeItem(storageName);
      sessionDelete = true;
    }

    return sessionDelete;
  }

  deleteAllStorage(storageName: string) {
    window.sessionStorage.removeItem(storageName);
  }

  isUserLoggedIn(): boolean {
    const sessionStorageData: any = this.getValueStorage('sessionStorage');
    let userLogged = false;
    if (sessionStorageData !== null && sessionStorageData.i && sessionStorageData.t) {
      let sessionStartTimeString = this.decryptData(sessionStorageData.s); //this.decryptData(sessionStorageData.s);
      if(sessionStartTimeString ){
        let sessionStartTime = new Date(sessionStartTimeString);
        let nowDate = new Date()
        if( (nowDate.getTime() - sessionStartTime.getTime()) > this.GetSessionMaxTimeMS()){
          this.deleteValueStorage('sessionStorage');
          window.location.href = environment.urlSite + '/iniciosesion' ;
        }
        else{
          userLogged = true;
        }
      }
    }
    return userLogged;
  }

  UpdateSessionStartTime(): void {
    const sessionStorageData: any = this.getValueStorage('sessionStorage');
    if (sessionStorageData !== null && sessionStorageData.i && sessionStorageData.t) {
      sessionStorageData.s = this.encryptData(new Date().toISOString()); // Actualiza la hora actual
      this.saveValueStorage(sessionStorageData,'sessionStorage');
    }
  }
  //rutas que necesitan que el usuario esté logueado
  isProtectedRoute(url: string): boolean {
    if(settings.availableRoutes.some(route => url.toLowerCase().includes(route.toLowerCase()))){
      return false
    }
    return settings.protectedRoutes.some(route => url.toLowerCase().includes(route.toLowerCase()));
  }

  RefreshSesionMaxTime(){
    this.utils.GetParametersByKey('sessiontimeoff').subscribe((o) => {
      this.sessionMaxTimeParameter = this.utils.decodeData(o.value[0].value);
      this.saveValueStorage( this.sessionMaxTimeParameter, 'timeSession');
    });
  }

  GetSessionMaxTimeMS(): number {
    if (this.sessionMaxTimeParameter == "-1") {
      this.sessionMaxTimeParameter = this.getValueStorage('timeSession')?.toString() || '5';
    }
    if(isNaN(+this.sessionMaxTimeParameter) || +this.sessionMaxTimeParameter < 0){
      return 5*60*1000;
    }
    else{
      return +this.sessionMaxTimeParameter * 60 * 1000;
    }
  }

  GetUserId(): string{
    if(!this.isUserLoggedIn()){
      return ''
    }
    else{
      let user : any = this.getValueStorage("sessionStorage");
      let userId = this.decryptData(user.i);
      return userId;
    }
  }

  private hexToWordArray(hexStr: string): CryptoJS.lib.WordArray {
    return CryptoJS.enc.Hex.parse(hexStr);
  }



  encryptDataMD5(toEncrypt: string, useHashing: boolean, key: string): string {

    let keyArray;

    if (useHashing) {
      keyArray = CryptoJS.MD5(key);
    } else {
      keyArray = CryptoJS.enc.Utf8.parse(key);
    }
    const encrypted = CryptoJS.TripleDES.encrypt(toEncrypt, keyArray, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });


    return encrypted.toString();
  }


  decryptDataMD5(cipherString: any, useHashing: boolean, key: string): string {

    let keyArray;

    if (useHashing) {
      keyArray = CryptoJS.MD5(key);
    } else {
      keyArray = CryptoJS.enc.Utf8.parse(key);
    }

    const decrypted = CryptoJS.TripleDES.decrypt(cipherString, keyArray, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  getPosition(): Promise<any> {
    console.log("entro aqui");
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(resp => {
        console.log(resp)






        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          switch(err.code) {
            case err.PERMISSION_DENIED:
                break;
            case err.POSITION_UNAVAILABLE:
                break;
            case err.TIMEOUT:
                break;

        }

          reject(err);
        },options);
    });
  }

  addMetaNoIndex() {
    this.meta.removeTag('name="robots"');
    this.meta.addTag({ name: 'robots', content: 'index,follow' });
  }

  removeMetaNoIndex() {
    this.meta.removeTag('name="robots"');
    this.meta.addTag({ name: 'robots', content: 'index' });
  }


  SendDataToMarketingCloud(info:any): Observable<any> {
    let  url = settings.SitefinityServicesUrl + 'callSalesforce';


    const response = this.http.post(url, info );


    return response;
  }
}
