import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { ListBlogs, Tags } from 'src/app/models/sitefinity/blogs.model';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { BlogService } from 'src/app/services/sitefinity/blog.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { environment } from 'src/environments/environment';

const settings = new SitefinitySettings();
@Component({
  selector: 'app-blogs-list',
  templateUrl: './blogs-list.component.html',
  styleUrls: ['./blogs-list.component.css']
})
export class BlogsListComponent {
  tags: Tags[] = [];
  blogs: ListBlogs[] = [];
  urlBase = environment.urlBase;
  urlBaseSitefinity = settings.SitefinityServicesUrl;
  filterBlogsId: string[] = [];
  totalDocument = 0;
  todosFilter: Tags | null = null;
  showMessage = false;
  settingsService = {
    skip: settings.skip,
    top: settings.Top
  };

  constructor(
    private blogService: BlogService,
    private loader: LoadingService,
    private sitefinityPagesService: SitefinitypagesService,
    private utilities: UtilitiesService,
    private utilitesServices: UtilitiesServicesService,
    private meta: Meta) {
    this.sitefinityPagesService.setDetailPage(settings.pages.find((p: any) => p.Title === 'blogs')!.ID);

    settings.skip = settings.skip;
    settings.Top = settings.Top;
    this.utilities.GetParametersByKey('BlogsSettings').subscribe((response: any) => {
    // response = JSON.parse(response.Data);
    const settingsBlogs: any = JSON.parse(response.value[0].value);

      this.settingsService = settingsBlogs;
      settings.skip = settingsBlogs.skip;
      settings.Top = settingsBlogs.top;
      this.getTagsOrClassificationsMethod();
      this.getDataBlogsInit();
      this.meta.removeTag('name="robots"');
      this.meta.addTag({ name: 'robots', content: 'index,follow'});
    });
  }

  getDataBlogsInit() {
    this.loader.showSpinner();
    forkJoin({

      requestTwo: this.blogService.getBlogListMethod(settings.Top, settings.skip)
    }).subscribe(rqs => {

      if (rqs.requestTwo.blogs) {
        // Agrego los nuevos items
        this.blogs = rqs.requestTwo.blogs;
        this.totalDocument = rqs.requestTwo.totalDocuments;
        this.loader.hideSpinner();
        this.showMessage = true;
      }

      this.loader.hideSpinner();
    });
  }

  getTagsOrClassificationsMethod()
  {
    this.blogService.getTagsOrClassificationsMethod().subscribe(rqs=>{

      if (rqs.tags) {
        const todos = rqs.tags.filter((item: any) => item.title === 'Todos')[0];
        this.todosFilter = todos;
        const ordenados = rqs.tags.filter((item: any) => item.title !== 'Todos').sort((a: any, b: any) => a.title.localeCompare(b.title));
        // Seteo primero el todos y despues el listado ordenado alfabeticamente
        this.tags.push(this.todosFilter, ...ordenados);

        this.addIdFilter(todos.id);
      }


    })


  }



  /**
   * Obtengo listado de blogs
   **/
  getBlogs() {
    this.loader.showSpinner();
    this.blogService.getBlogListMethod(settings.Top, settings.skip).subscribe(o => {

      if (settings.skip === 0) {
        this.blogs = [];
      }
      // Agrego los nuevos items
      o.blogs.forEach((item: any) => {
        this.blogs.push(item);
      });
      this.totalDocument = o.totalDocuments;
      this.loader.hideSpinner();
      this.showMessage = true;
    }, error => {
      this.loader.hideSpinner();
    });
  }

  /**
   * Obtengo la clasificacion y los tags
   **/
  getTagsOrClassifications() {
    this.blogService.getTagsOrClassificationsMethod().subscribe(o => {
      const todos = o.tags.filter((item: any) => item.title === 'Todos')[0];
      this.todosFilter = todos;
      const ordenados = o.tags.filter((item: any) => item.title !== 'Todos').sort((a: any, b: any) => a.title.localeCompare(b.title));
      // Seteo primero el todos y despues el listado ordenado alfabeticamente
      this.tags.push(this.todosFilter, ...ordenados);

      this.addIdFilter(todos.id);
      // this.filterBlogsByTagsId();
    });
  }

  /**
   * Filtro para aplicar busqueda
   **/
  filterBlogsByTagsId(newBlogs: boolean = false) {
    this.loader.showSpinner();
    if (newBlogs) {
      settings.skip = this.settingsService.skip;
      settings.Top = this.settingsService.top;
    }

    if (this.filterBlogsId.length === 0 || (this.filterBlogsId.length === 1 && this.filterBlogsId.findIndex((item: any) => item === this.todosFilter?.id) !== -1)) {
      this.getBlogs();
      return;
    }

    this.blogService.filterBlogsByTagsIdMethod(this.filterBlogsId, settings.Top, settings.skip).subscribe(o => {
      // Agrego los nuevos items
      if (settings.skip === 0) {
        this.blogs = [];
      }

      this.blogs = o.blogs;
      this.totalDocument = o.totalDocuments;
      this.loader.hideSpinner();
    }, error => {
      this.loader.hideSpinner();
    });
  }

  /**
   * Seleccion de filtro y posterior filtrado a servicio
   **/
  handleSelected(event: any) {
    if (event.target.checked === true) {
      this.addIdFilter(event.target.value);
    } else {
      this.removeFilter(event.target.value);
    }

    if (event.target.value !== this.todosFilter?.id!) {
      this.removeFilter(this.todosFilter?.id!);
    } else {
      this.filterBlogsId = [];
    }

    // Filtro solo si no hay filtro o si hay un solo filtro y ese filtro es de todos
    if (this.filterBlogsId.length === 0 || (this.filterBlogsId.length === 1 && this.filterBlogsId.findIndex((item: any) => item === this.todosFilter?.id) !== -1)) {
      this.addIdFilter(this.todosFilter?.id!);
      settings.skip = this.settingsService.skip;
      settings.Top = this.settingsService.top;
      this.getBlogs();
      return;
    }

    this.filterBlogsByTagsId(true);
  }

  /**
  * Eliminar filtro
  **/
  private removeFilter(id: string) {
    this.filterBlogsId.forEach((item, index) => {
      if (item === id) this.filterBlogsId.splice(index, 1);
    });
  }

  /**
  * Agrego filtro de id
  **/
  private addIdFilter(id: string) {
    if (this.filterBlogsId.findIndex((item: any) => item === id) === -1) {
      this.filterBlogsId.push(id);
    }
  }

  /**
  * Cargar más
  **/
  loadMore() {
    settings.skip += settings.Top;
    this.filterBlogsByTagsId();
  }

  loading() {

this.loader.showSpinner();
//   this.loader.hideSpinner();
  }
}