<main class="main pb-0" *ngIf="product !== null">

  <div class="container">
    <nav class="py-4">
        <ol class="breadcrumb">
            <li class="breadcrumb-item nav-link"><a routerLink="/" class="nav-link">Inicio</a></li>
            <li class="breadcrumb-item active cursor-pointer nav-link" aria-current="page" *ngIf="category != null" (click)="goToCategory(category)">{{category.Name}}</li>
            <li class="breadcrumb-item active cursor-pointer nav-link" aria-current="page" *ngIf="subCategory != null" (click)="goToCategory(subCategory)">{{subCategory.Name}}</li>
        </ol>
    </nav>
  </div>

  <div class="container mt-3 mt-lg-5">
    <div class="row">
      <div class="col-12 d-sm-none">
        <div *ngIf="selectedPromotion!=''" class="discount-chip mb-1">
          <span *ngIf="selectedPromotion!=''">{{selectedPromotion}}</span>
        </div>
        <div class="d-flex align-items-top justify-content-between">
          <h1 class="mb-0 lh-base color-primary">
            {{product?.Name}}
          </h1>
        </div>
        <div class="d-flex align-items-top justify-content-between">
          <div>
            <span class="d-block"> SKU:{{product?.SKU}}</span>
            <span>{{product?.ShortDescription}}</span>
          </div>
          <ng-container *ngIf="validateProductFavorites">
            <i class="bi bi-heart icon-heart" *ngIf="isUserLoggedIn && !productInFavorites" (click)="addFavoriteProduct(product)"></i>
            <i class="bi bi-heart-fill icon-heart" *ngIf="productInFavorites"></i>
          </ng-container>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="d-flex align-items-center justify-content-center img-product-detail-container">
          <img *ngIf="principalImage != '' " [src]="principalImage" class="img-product-detail" loading="lazy"  alt="Foto de producto">
          <img *ngIf="principalImage == '' " [src]="urlBase +'/assets/img/img-default.jpg'" class="img-product-detail" loading="lazy"  alt="Foto de producto">
        </div>
        <div class="cover py-3" *ngIf="product.carrusel?.length > 0 ">
          <div class="row row-cols-3 row-cols-xl-4 g-2 scroll-images carousel-related mt-2">
              <div *ngFor="let i of product.carrusel "  class="col mb-2">
                  <div  (click)="PutImageAsPrincipal(i.WebPath)" class="card h-100 p-2">
                      <div class="product-card-content">
                          <div class="img-container-small">
                              <img  loading="lazy" alt="…" [src]="i.WebPath"   class="w-100">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="mb-2">
          <small class="ps-4">*Imágenes con fines ilustrativos</small>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <div class="d-none d-sm-block">
          <div *ngIf="product && selectedPromotion != ''  && product?.ShowDiscount" class="discount-chip mb-1 me-1">
            <span *ngIf="selectedPromotion != '' ">{{selectedPromotion}}</span>
          </div>
          <div class="d-flex align-items-top justify-content-between">
            <h1 class="mb-0 lh-base">{{product?.Name}}</h1>
            <ng-container *ngIf="validateProductFavorites">
              <i class="bi bi-heart icon-heart" *ngIf="isUserLoggedIn && !productInFavorites" (click)="addFavoriteProduct(product)"></i>
              <i class="bi bi-heart-fill icon-heart" *ngIf="productInFavorites"></i>
            </ng-container>
          </div>
          <span class="d-block">SKU: {{product?.SKU}}</span>
          <span *ngIf="this.attributeDescription == '' ">{{product?.ShortDescription}}</span>
          <span *ngIf="this.attributeDescription != '' ">{{this.attributeDescription}} </span>
        </div>
        <div *ngIf="product.ProductAttributes?.length > 0" class="d-flex align-items-baseline price-detail my-3">
          <h2 class="mb-0 fw-500">{{ selectedUnitPrice | mycurrency}} i.v.a.i.</h2>
          <span class="product-discount"
            *ngIf="(selectedUnits === 'Unidad' || selectedUnits === 'Unidades') && product.ProductAttributes[0].PriceWithDiscount > 0">{{
            product.ProductAttributes[0]?.PriceWithTaxes.toFixed(2) * selectedQuantity | mycurrency}} i.v.a.i </span>
          <span class="product-discount"
            *ngIf="(selectedUnits === 'Caja' || selectedUnits === 'Cajas') && product.ProductAttributes?.length> 1 && product?.ProductAttributes[1].PriceWithDiscount > 0">{{
            product?.ProductAttributes[1].PriceWithTaxes.toFixed(2) * selectedQuantity| mycurrency}} i.v.a.i </span>
          <span class="product-discount"
            *ngIf="(selectedUnits === 'Caja' || selectedUnits === 'Cajas') && product?.ProductAttributes[0].Name === 'Caja' && product?.ProductAttributes[0].PriceWithDiscount > 0">{{
            product?.ProductAttributes[0].PriceWithTaxes.toFixed(2) * selectedQuantity| mycurrency}} i.v.a.i </span>
        </div>
        <div *ngIf="product.ProductAttributes?.length == 0" class="d-flex align-items-baseline price-detail my-3">
          <h2 class="mb-0 fw-500">{{ selectedUnitPrice | mycurrency}} i.v.a.i.</h2>
          <span class="product-discount" *ngIf="product?.PriceWithDiscount > 0">{{
            product?.PriceWithTaxes.toFixed(2) * selectedQuantity | mycurrency}} i.v.a.i</span>
        </div>
        <div class="my-3 d-flex align-items-center" id="quantity-selector">
          <div *ngIf="displayQuantityComponent == false && !product?.NotForSale" class="d-flex align-items-center me-1">
            <div class="nav-item dropup">
              <button id="quantity-button" class="btn btn-outline-secondary dropdown-toggle dropdown-product"
                type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{this.selectedQuantity }}
              </button>
              <ul class="dropdown-menu number-dropdown">
                <li *ngFor="let quantity of numbers">
                  <a class="dropdown-item" (click)="selectQuantity(quantity)"> {{quantity}} </a>
                </li>
                <hr *ngIf="this.product.MaxToPurchase > 9" class="my-0">
                <li *ngIf="this.product.MaxToPurchase > 10" (click)="openQuantityComponent(true)"><a
                    class="dropdown-item">10 + </a></li>
              </ul>
            </div>
          </div>
          <div *ngIf="product.ProductAttributes?.length > 0 && displayQuantityComponent == false && !product?.NotForSale" class="d-flex align-items-center me-1">
            <div class="nav-item dropup">
              <button *ngIf="product.ProductAttributes.length > 1 " class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                {{ selectedUnits }}
              </button>
              <button *ngIf="product.ProductAttributes.length === 1 || product.ProductAttributes.length === 0 " class="btn btn-outline-secondary  dropdown-product" type="button" aria-expanded="false">
                {{ selectedUnits }}
              </button>
              <ul class="dropdown-menu option-dropdown">
                <li *ngFor="let item of product?.ProductAttributes; let t = index">
                  <a class="dropdown-item" (click)="selectType(item.Name,t)">{{ getPluralizedName(item.Name, selectedQuantity) }}</a>
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="product.ProductAttributes?.length === 0 && displayQuantityComponent == false " class="d-flex align-items-center me-1">
            <div class="nav-item dropup">
              <button class="btn btn-outline-secondary dropdown-product" type="button" aria-expanded="false">
                {{ selectedUnits }}
              </button>
            </div>
          </div>

          <div *ngIf="displayQuantityComponent == false && !product?.NotForSale">
            <button *ngIf="!product?.IsRecipeRequired" class="btn btn-primary" id="liveToastBtn" (click)="showRecommendedProduct(product, selectedUnits, selectedQuantity, selectedUnitPrice)">
              Agregar al carrito
            </button>
            <a *ngIf="product?.IsRecipeRequired" target="_blank" rel="noopener noreferrer" class="btn btn-primary" href="https://wa.me/50689650033">
              Solicitar
            </a>
          </div>
        </div>
        <div *ngIf="displayQuantityComponent" class="my-3 d-flex align-items-center" id="quantity-custom-amount">
          <input [(ngModel)]="quantityByComponent" type="number" class="form-control input-quantity"
            id="quantity-product-input" onkeydown="javascript: return event.keyCode == 69 ? false : true"
            aria-describedby="emailHelp" [max]="product?.MaxToPurchase" [min]="1">
          <button (click)="openQuantityComponent(false)" type="submit" class="btn btn-outline-secondary ms-1"><i
              class="bi bi-plus-lg"></i></button>
        </div>
        <div class="toast-container position-fixed bottom-0 end-0 p-3">
          <div id="liveToast" class="toast bg-white" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header border-0">
              <i class="bi bi-cart-check me-2 icon-cart-chip"></i>
              <strong class="me-auto">El producto se agrego al carrito</strong>
              <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body d-flex justify-content-end">
              <a href="">Ver carrito</a>
            </div>
          </div>
        </div>
        <div *ngIf="product?.ApplyCoupon && isUserLoggedIn" class="my-3">
          <div class="">
            <div class="d-flex align-items-center ml-2">
              <i class="bi bi-cash-coin icon-geo"></i>
              <button id="btnShowCupon" type="button" class="btn btn-link text-wrap" data-bs-toggle="collapse"
                data-bs-target="#collapseCupon" aria-expanded="false" aria-controls="collapseCupon">¿Tenés un
                cupón?</button>
            </div>
            <form [formGroup]="form" (ngSubmit)="addCupon()">
              <div class="collapse" id="collapseCupon">
                <div class="d-flex mb-2">
                  <input *ngIf="!isCuponApplie"  type="text" class="form-control login-input" id="cupon" formControlName="cupon"
                    placeholder="Código de descuento" [ngModel]="form.value.cupon" minlength="3">
                    <input *ngIf="isCuponApplie" readonly  type="text" class="form-control login-input" id="cupon" formControlName="cupon"
                    placeholder="Código de descuento" [ngModel]="form.value.cupon" minlength="3">
                  <button class="btn btn-primary ms-1">{{isCuponApplie ? 'Eliminar' : 'Aplicar'}}</button>
                </div>
                <div *ngIf="cupon!.invalid && (cupon!.dirty || cupon!.touched || this.isSubmited)" class="text-danger">
                  <span *ngIf="cupon!.errors?.required">{{validationMsg['required']}}</span>
                  <span *ngIf="cupon!.errors?.minlength">{{validationMsg['minlength'].replace("XXX", cupon!.errors!.minlength.requiredLength)}}</span>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="">
          <h3>Compartir:</h3>
          <ul class="p-0">
            <a target="_blank" data-action="share/whatsapp/share" href="{{this.sharedlinks.waLink}}" rel="noopener noreferrer">
              <i class="bi bi-whatsapp pe-2 icon-color" style="font-size: 1.2rem; color:#003CC6;"></i>
            </a>
            <a target="_blank" href="{{this.sharedlinks.xLink}}" data-size="large" rel="noopener noreferrer">
              <i class="bi bi-twitter-x icon-color pe-2" style="font-size: 1.2rem; color:#003CC6;"></i>
            </a>
            <a  target="_blank" href="{{this.sharedlinks.fbLink}}" rel="noopener noreferrer">
              <i class="bi bi-facebook icon-color pe-2" style="font-size: 1.3rem; color:#003CC6;"></i>
            </a>
            <a target="_blank" href="{{this.sharedlinks.mailToLink}}" rel="noopener noreferrer">
              <i class="bi bi-envelope-check icon-color pe-2" style="font-size: 1.3rem; color:#003CC6;"></i>
            </a>
          </ul>
       </div>


        <div class="product-options mt-1 mb-3 flex-wrap">
          <div class="d-flex align-items-center me-3" *ngIf="product?.Mechanics != ''">
            <i class="bi bi-star-fill icon-star"></i>
            <span class="text-nowrap">Plan paciente</span>
          </div>
          <div *ngIf="ifProductEarnsPoints" class="d-flex align-items-center me-1">
            <img loading="lazy" alt="…" [src]="urlBase + '/assets/img/puntos.png'" class="ponits me-2">
            <span class="text-nowrap">Acumula puntos</span>
          </div>
          <div>
            <img [src]="urlBase + '/assets/img/labomba.png'" class="logo-conmigo-product">
          </div>
        </div>


        <div class="d-flex align-items-center" *ngIf="product?.IsRecipeRequired && !product?.NotForSale">
          <img loading="lazy" alt="…" [src]="urlBase + '/assets/img/icons/recipe.svg'"   class="icon-point">
          <span class="">Este producto requiere de una receta médica para ser comprado, por favor contactanos por medio
            de nuestro chat para más información.</span>
        </div>

        <div class="d-flex align-items-center" *ngIf="product?.IsRecipeRequired && product?.NotForSale">
          <img loading="lazy" alt="…" [src]="urlBase + '/assets/img/icons/recipe.svg'"   class="icon-point">
          <span class="">Por disposiciones regulatorias podés adquirir este producto con receta médica al visitar la sucursal de Farmacia La Bomba de tu preferencia.</span>
        </div>


        <div class="d-flex align-items-center" *ngIf="!product?.IsRecipeRequired  && product?.NotForSale">
          <img loading="lazy" alt="…" [src]="urlBase + '/assets/img/icons/recipe.svg'"   class="icon-point">
          <span class="">Por disposiciones regulatorias podés adquirir este producto con receta médica al visitar la sucursal de Farmacia La Bomba de tu preferencia.</span>
        </div>





      </div>
    </div>
  </div>
  <div class="container">
    <hr class="mb-2">
    <div class="d-flex align-items-center ms-4">
      <i class="bi bi-geo-alt icon-geo"></i>
      <button type="button" class="btn btn-link text-wrap text-decoration-none" (click)="showModalPharmacies()">
        Ver disponibilidad en farmacias
      </button>
    </div>
    <hr class="mt-2 mb-0">
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item" *ngIf="product?.LargeDescription != ''">
        <h2 class="accordion-header custom-accordion-product">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            Detalles
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div [innerHTML]="product?.LargeDescription" class="accordion-body">
          </div>
        </div>
      </div>
      <div class="accordion-item" *ngIf="product?.Detail  != '' ">
        <h2 class="accordion-header custom-accordion-product">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            Indicaciones
          </button>
        </h2>
        <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div  [innerHTML]="product?.Detail" class="accordion-body">
          </div>
        </div>
      </div>
      <div class="accordion-item" *ngIf="product?.Components != '' ">
        <h2 class="accordion-header custom-accordion-product">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            Ingredientes
          </button>
        </h2>
        <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div [innerHTML]="product?.Components" class="accordion-body">
          </div>
        </div>
      </div>
      <div *ngIf="product?.Mechanics != ''" class="accordion-item">
        <h2 class="accordion-header custom-accordion-product">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
            Plan paciente
          </button>
        </h2>
        <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div [innerHTML]="product?.Mechanics" class="accordion-body"></div>
        </div>
      </div>
    </div>
    <hr class="mt-0 mb-5">
  </div>
  <app-modal-pharmacies [showModal]="showPharmacies" [sku]="product?.SKU"
    [optionslength]="product?.ProductAttributes?.length" [optionvalue]="selectedUnits"
    [modalData]="modalPharmacie"
    (closeModal)="closeModalPharmacie($event)">
  </app-modal-pharmacies>
  <app-modal-general [showModal]="showModalCupon" (showModalChange)="closeModalCupon($event)"
    [modalData]="modalCupon"></app-modal-general>
    <app-modal-one-product [showModal]="showProduct"  [selectUnit] ="SelectAttributeUnit" [recommendedProduct]="recommendedProduct" (showModalChange)="closeModalOneProduct($event)"></app-modal-one-product>
</main>

<!-- Mensaje erro  -->
<main class="main d-flex align-items-center" *ngIf="product === null">
  <div class="container container-input">
    <div class="text-center">
      <i class="bi bi-cart-x icon-page"></i>
      <h2 class="">Lo sentimos, este producto no se encuentra disponible</h2>
      <a href="">Volver al inicio</a>
    </div>
  </div>
</main>
