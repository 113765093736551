import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MergeNService } from 'src/app/services/core/merge-n.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { WindowReferenceService } from 'src/app/services/shared/windows-reference.service';

declare let window: any;

@Component({
  selector: 'app-checkout-end',
  templateUrl: './checkout-end.component.html',
  styleUrls: ['./checkout-end.component.css']
})
export class CheckoutEndComponent implements OnInit {
  private windowDataLayer: any;
  state: any;
  source: boolean = false;
  modal: any;
  open: boolean = true;
  user: any;
  clientId: any;
  cartId: any;

  constructor(
    private utilitiesService: UtilitiesServicesService,
    private mergenService: MergeNService,
    private notificationService: NotificationService,
    private sharedService: SharedService,
    private _windowRef: WindowReferenceService,
    private loader: LoadingService
  ){
    this.windowDataLayer = _windowRef.nativeWindow;
  }

  ngOnInit(): void {

    let urlResponse = window.location.href;       

    if (urlResponse?.includes('true')) {
      this.source = true;

      if (urlResponse?.includes('open=false')) {
        this.open = false;
      }
      
      let infoencrypt:any = this.utilitiesService.getValueStorage('DatalayerCompra');
      if(infoencrypt != ""){
        let infodecrypt = this.utilitiesService.decryptDataMD5(infoencrypt,true,'qhUQsZLvWzxg2T9V');
        let normalData = JSON.parse(infodecrypt);
        this.windowDataLayer.dataLayer.push(normalData);
        this.utilitiesService.saveValueStorage("", "DatalayerCompra");
      }
      
      this.user = this.utilitiesService.getValueStorage('sessionStorage');
      this.cartId = this.utilitiesService.decryptData(this.user.c);
      this.clientId = this.utilitiesService.decryptData(this.user.i);
      
      this.mergenService.GetOrCreateCartMethod(this.cartId, parseInt(this.clientId)).subscribe(async o => {
        if (o.response.code === "OK0001") {
          this.cartId = o.responseObject.IdCart;
          
          const IDClient = this.utilitiesService.encryptData(o.responseObject.IdCart.toString());
          this.user.c = IDClient;
          this.utilitiesService.updateValueStorage(this.user, 'sessionStorage');
        }
        else {
          this.notificationService.SendNotification(o.response.type, o.response.titleMessage, o.response.message);
        }  
      });
    }
    else {
      this.source = false;
      this.sharedService.updateCart();
    }
    this.loader.hideSpinner();
  }
}
