import { Injectable } from '@angular/core';
import { MessagesService } from '../sitefinity/messages.service';
import { UtilitiesService } from '../core/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationArrayService {
  private showError: boolean = false;
  private _messageService: MessagesService;
  arrayNotification: any[] = [];

  // showError = false;
  message = ['Datos incorrectos'];
  title = ['Datos incorrectos'];
  type = ['Datos incorrectos'];
  pushClass = ['push-error'];
  iconClass = ['icon-push-error'];
  iconBgClass = ['toast-icon-error'];

  constructor(MessagesService: MessagesService, public utilities : UtilitiesService) {
    this._messageService = MessagesService
  }

  //SendNotification(notificationType: string, title: string, message: string) {
  SendNotification(array: any) {
    if(array.length>0){
      this.message = [];
      this.title = [];
      this.type = [];
      this.pushClass = [];
      this.iconClass = [];
      this.iconBgClass = [];

      this.showError = true;
      for (let a = 0; a < array.length; a++) {
        setTimeout(() => {
          const element = array[a];
        
          this.title.push(element.titleMessage);
          this.message.push(element.message);
          this.type.push(element.typenotification);

          if(this.type[a] == 'warning'){
            this.type[a] = 'alert';
          }

          if (this.type[a] != 'success' && this.type[a] != 'alert' && this.type[a] != 'error'){
            this.type[a] = 'error';
          }
            
          this.pushClass.push('push-' + this.type[a]);
          this.iconBgClass.push('toast-icon-' + this.type[a]);
          //this.iconClass = 'icon-push-' + this.type

          switch (this.type[a]) {
            case 'error':
              this.iconClass.push('bi-x-circle icon-push-error');
              break;
              case 'alert':
              this.iconClass.push('bi-exclamation-triangle icon-push-alert');
              break;
            case 'success':
              this.iconClass.push('bi-check-circle icon-push-success');
              break;
          }
        }, 1000 * a);
      }

      this.arrayNotification = array;
    }
  }

  Hide() {
    this.showError = false;
  }

  GetShowError(): boolean {
    return this.showError;
  }
  GetMessage(indexmessage:number): string {
    return this.utilities.decodeData(this.message[indexmessage]);
  }
  GetTitle(indexmessage:number): string {
    let text = '';
    if (this.title != undefined && this.title[indexmessage]?.trim() != '') {
      text = this.title[indexmessage];
    } else {
      switch (this.type[indexmessage]) {
        case 'error':
          text = 'Error';
          break;
        case 'alert':
          text = 'Atención';
          break;
        case 'success':
          text = 'Correcto';
          break;
      }
    }
    return this.utilities.decodeData(text);
  }
  GetPushClass(indexmessage:number): string {
    return this.pushClass[indexmessage];
  }
  GetIconClass(indexmessage:number): string {
    return this.iconClass[indexmessage];
  }
  GetIconBgClass(indexmessage:number): string {
    return this.iconBgClass[indexmessage];
  }
  GetType(indexmessage:number): string {
    return this.type[indexmessage];
  }
  GetArray(): any {
    return this.arrayNotification;
  }
}
