import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IModal } from 'src/app/models/modal.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { LegalDocuments } from 'src/app/models/sitefinity/type-of-documents.model';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { DocumentsService } from 'src/app/services/sitefinity/documents.service';
declare var window: any;
@Component({
  selector: 'app-modal-consentimiento',
  templateUrl: './modal-consentimiento.component.html',
  styleUrls: ['./modal-consentimiento.component.css'],
})
export class ModalConsentimientoComponent implements OnChanges {
  @Input() showModal: boolean = false;
  @Input() modalData: IModal = {
    ModalName: 'modalConsentimiento',
    BtnConfirmText: 'Acepto consentimiento informado',
  };
  @Input() document: LegalDocuments | null = null;
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;
  termsReaded: boolean = false;
  settings: SitefinitySettings = new SitefinitySettings;
  trustedHtml:any;


  constructor(
    public notificationService: NotificationService,
    public utilities: UtilitiesService,
    public sanitizer: DomSanitizer,
    private documentService: DocumentsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {

    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      //verificar si se obtuvo el texto del consentimiento informado


      if (this.document?.documentText) {
        this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.document.documentText);
      }

      if (this.document == null || this.document.documentText.trim() == '') {
        this.GetLegalDocument();
      }
      else {
        //mostrar consentimiento informado
        this.modal.show();
        if (!this.termsReaded) {
          this.startTermsReaderChecker();
        }
      }
    }
  }

  accept() {
    this.modal.hide();
    this.showModalChange.emit(true);
  }

  close() {
    this.modal.hide();
    this.showModalChange.emit(false);
  }

  private GetLegalDocument() {
    //Informed consent
    this.documentService.GetLegalDocumentsByIdType(0, 1, this.settings.legalDocumentTypeMap['Consentimiento Informado']).subscribe((ResponseLegalDocuments) => {
      if (ResponseLegalDocuments.response != undefined) {
        this.document = ResponseLegalDocuments.legalDocuments[0];


        if (this.document?.documentText) {
          this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.document.documentText);
        }


        if (!this.document || this.document.documentText.trim() == ''){
          this.notificationService.SendNotificationByCode('consent-info-error','1')
        }
      }
      else {
        this.notificationService.SendNotificationByCode('consent-info-error','1')
      }
    },
    (error) => {
      this.notificationService.SendNotificationByCode('consent-info-error','1')
    });
  }

  //#region Check Full Text Readed

  /**
   * Inicia el verificador del lector de términos.
   * Esta función espera a que se cargue el modal y luego verifica la visibilidad del elemento de términos.
   * También agrega un escucha de evento de desplazamiento para verificar continuamente la visibilidad del elemento de términos al desplazarse.
   */
  startTermsReaderChecker() {
    //Para esperar que cargue el modal
    setTimeout(() => {
      this.checkElementVisibility();
    }, 2000);

    // Para verificar si el elemento es visible cuando se hace scroll
    const termsText: HTMLElement | null = document.getElementById('terms-text');
    if (termsText) {
      termsText.addEventListener('scroll', () => {
        this.checkElementVisibility();
      });
    }
  }

  // Función para verificar si el elemento está visible y actualizar termsReaded
  checkElementVisibility() {
    const myElement: HTMLElement | null = document.getElementById('termsEnd');
    if (myElement && !this.termsReaded) {
      this.CalculateVisibility(myElement);
    }
  }
  //funcion que calcula si el elemento esta visible y guarda el valor
  CalculateVisibility(element: HTMLElement) {
    const container: HTMLElement | null = element.parentElement; // Obtener el contenedor del elemento
    var isVisible = false;

    if (container) {
      // Si no hay contenedor, el elemento no puede ser visible
      const containerRect: DOMRect = container.getBoundingClientRect();
      const elementRect: DOMRect = element.getBoundingClientRect();

      //si ambos estan en 0 es porque no se cargó el modal
      if (elementRect.bottom != 0 && containerRect.bottom != 0) {
        // Verificar si la parte inferior del elemento está dentro del área visible del contenedor
        isVisible = elementRect.bottom * 0.975 <= containerRect.bottom;
      }
      // Si el elemento se ha vuelto visible, establece termsReaded en true
      if (isVisible) {
        this.termsReaded = true;
      }
    }
  }

  //#endregion
}
