import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { RequestFavoriteProduct } from 'src/app/models/core/clients.model';
import { ResponseGenericArrayObject } from 'src/app/models/core/products.model';
import { IModal } from 'src/app/models/modal.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { ClientsService } from 'src/app/services/core/clients.service';
import { ReminderService } from 'src/app/services/core/reminder.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
declare var window: any;

@Component({
  selector: 'app-modal-remember',
  templateUrl: './modal-remember.component.html',
  styleUrls: ['./modal-remember.component.css'],
})
export class ModalRememberComponent implements OnInit {
  @Input() showModalRemember: boolean = false;
  @Output() showModalRememberChange = new EventEmitter<boolean>();
  @Input() articleSku?: string = '0';
  @Input() product?: any;
  @Input() clientID?: string = '0';
  @Input() userId?: string = '0';
  @Input() selectedReminder: any;
  @Input() addToFavourite: boolean = false;
  @Output() callHideModalRemember: EventEmitter<any> = new EventEmitter();
  @Output() callRefreshReminders: EventEmitter<any> = new EventEmitter();

  modalTitle = 'recordatorio';
  submitText = 'Guardar';
  isEditMode = false;

  sitefinitySettings = new SitefinitySettings();
  selectedMonth: string = '0';
  selectedDay = '0';
  selectedReminderDay = '0';
  selectedContactMethod = '0';
  isDayTouched = false;
  isMonthTouched = false;
  isReminderDayTouched = false;
  isContactMethodTouched = false;
  hasClickedSave = false;
  modal: any;
  @Input() modalData: IModal = {
    ModalName: 'modalRemember',
  };
  daysTypeMap = this.sitefinitySettings.daysTypeMap;
  fullDays = Array.from({ length: 30 }, (_, i) => i + 1);
  contactMethods: any[] = [];

  minDate = new Date();

  monthTypeArray: { key: string; value: string }[] = Object.entries(
    this.sitefinitySettings.monthTypeMap
  ).map(([key, value]) => ({ key, value }));

  constructor(
    public ReminderService: ReminderService,
    public NotificationService: NotificationService,
    public LoadingService: LoadingService,
    public utilitiesService: UtilitiesService,
    private clientService: ClientsService
  ) {}

  ngOnInit(): void {
    // Calculate tomorrow's date
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.SetDefaultSelectedValues();
    this.GetReminderMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModalRemember && changes.showModalRemember.currentValue && this.modal) {
      this.RefreshReminderValues();
      this.modal.show();
    }
  }

  GetReminderMethods() {
    this.ReminderService.GetReminderTypesMethod().subscribe((o) => {
      if (o.response.code == 'GetReminderTypesMethod-success') {
        this.contactMethods = o.object;
      }
    });
  }
  isValidDate(day: string, month: string): boolean {
    if(this.isEditMode){
      return true;
    }
    const currentDate = new Date();
    const selectedDate = new Date(currentDate.getFullYear(), +month - 1, +day);
    return selectedDate > currentDate;
  }

  RefreshReminderValues() {
    if (this.selectedReminder) {
      this.isEditMode = true;
      this.modalTitle = 'Modificar Recordatorio';
      this.submitText = 'Actualizar';

      const FechaInicio = new Date(this.selectedReminder.FechaInicio);
      // Obtener el número del día del mes
      this.selectedDay = FechaInicio.getDate().toString();
      this.selectedMonth = (FechaInicio.getMonth() + 1).toString();
      this.selectedReminderDay = this.selectedReminder.DiaDelRecordatorio;
      this.selectedContactMethod =
        this.selectedReminder.MedioDelRecordatorio[0].Medio;
    } else {
      this.isEditMode = false;
      this.SetDefaultSelectedValues();
      this.modalTitle = 'Crear Recordatorio';
      this.submitText = 'Guardar';
    }
  }

  CloseModalRemember(){
    this.showModalRemember = false;
    this.showModalRememberChange.emit(false);
    this.modal.hide();
  }

  IsFormValid(): boolean {
    return (
      this.isValidDate(this.selectedDay, this.selectedMonth) &&
      +this.selectedContactMethod > 0 &&
      +this.selectedReminderDay > 0
    );
  }

  Submit() {
    this.hasClickedSave = true;
    if (this.IsFormValid()) {
      //As an additional validation we must verify that image not exceed max size
      var selectedDate = new Date(
        this.minDate.getFullYear() +
          '-' +
          this.selectedMonth +
          '-' +
          this.selectedDay
      );
      // Convertir la fecha a formato UTC
      var selectedDateUTC = this.utilitiesService.fixUTCDate(selectedDate);

      const request: any = {
        IDCliente: this.clientID,
        Estado: 'A',
        Articulo: this.articleSku,
        DiaDelRecordatorio: this.selectedReminderDay,
        CanalOrigen: '4', //Ecommerce
        Medio: this.selectedContactMethod,
        Accion: 'I', //Actions: D= Delete, I=Insert, U=Update
        FechaInicio: this.utilitiesService.formatDate(selectedDateUTC),
        isEditMode: this.isEditMode
      };

      this.LoadingService.showSpinner();

      if (this.isEditMode) {
        //IF IT IS A DIFFERENT DAY IN REMINDER DAY THEN WE HAVE TO DELETE THE REMINDER
        //AND CREATE IT AGAIN.
        this.DeleteSelected().subscribe(
          (o) => {
            if (o.response.type == 'success') {
              //si e eliminó entonces voy a crear el nuevo recordatorio
              //crear recordatorio
              this.ReminderService.CEDProductReminderMethod(request).subscribe(
                (o) => {
                  if (o.response.type == 'success') {
                    this.CloseModalRemember();
                    this.callHideModalRemember.emit();
                    setTimeout(() => {
                      this.NotificationService.SendNotification(
                        o.response.type,
                        o.response.titleMessage,
                        o.response.message
                      );
                    }, 1500);
                  } else if (o.response.type == 'fail') {
                    this.NotificationService.SendNotification(
                      o.response.type,
                      o.response.titleMessage,
                      o.response.message
                    );
                  } else { //(o.response.type == 'error')
                    this.NotificationService.SendNotification(
                      o.response.type,
                      o.response.titleMessage,
                      o.response.message
                    );
                  }
                },
                (err) => {
                  this.LoadingService.hideSpinner();
                  this.NotificationService.SendNotification(
                    'error',
                    'Error',
                    'Error inesperado'
                  );
                }
              );
            } else if (o.response.type == 'fail') {
              this.NotificationService.SendNotification(
                o.response.type,
                o.response.titleMessage,
                o.response.message
              );
            } else { //(o.response.type == 'error')
              this.NotificationService.SendNotification(
                o.response.type,
                o.response.titleMessage,
                o.response.message
              );
            }
          },
          (err) => {
            this.LoadingService.hideSpinner();
            this.NotificationService.SendNotification(
              'error',
              'Error',
              'Error inesperado'
            );
          }
        );
      } else {
        this.ReminderService.CEDProductReminderMethod(request).subscribe(
          (o) => {
            if (o.response.type == 'success') {
              if(this.addToFavourite){
                this.addFavoriteProduct(this.product);
              }
              this.callHideModalRemember.emit();
              this.CloseModalRemember();
              setTimeout(() => {
                this.NotificationService.SendNotification(
                  o.response.type,
                  o.response.titleMessage,
                  o.response.message
                );
              }, 1500);
            } else if (o.response.type == 'fail') {
              this.NotificationService.SendNotification(
                o.response.type,
                o.response.titleMessage,
                o.response.message
              );
            } else { //(o.response.type == 'error')
              this.NotificationService.SendNotification(
                o.response.type,
                o.response.titleMessage,
                o.response.message
              );
            }
          },
          (err) => {
            this.LoadingService.hideSpinner();
            this.NotificationService.SendNotification(
              'error',
              'Error',
              'Error inesperado'
            );
          }
        );
      }
    }
  }

  private DeleteSelected(): Observable<ResponseGenericArrayObject> {
    const requestDelete: any = {
      IDCliente: this.clientID,
      Estado: 'A',
      Articulo: this.articleSku,
      DiaDelRecordatorio: this.selectedReminder.DiaDelRecordatorio,
      CanalOrigen: '4', //Ecommerce
      Medio: this.selectedReminder.MedioDelRecordatorio[0].Medio,
      Accion: 'D', //Actions: D= Delete, I=Insert, U=Update
      FechaInicio: this.selectedReminder.FechaInicio,
    };
    return this.ReminderService.CEDProductReminderMethod(requestDelete);
  }

  SubmitDelete() {
    this.LoadingService.showSpinner();
    this.DeleteSelected().subscribe(
      (o) => {
        if (o.response.type == 'success') {
          this.callHideModalRemember.emit();
          this.CloseModalRemember();
          setTimeout(() => {
            this.NotificationService.SendNotification(
              o.response.type,
              o.response.titleMessage,
              o.response.message
            );
          }, 1500);
        } else if (o.response.type == 'fail') {
          this.NotificationService.SendNotification(
            o.response.type,
            o.response.titleMessage,
            o.response.message
          );
        } else { //(o.response.type == 'error')
          this.NotificationService.SendNotification(
            o.response.type,
            o.response.titleMessage,
            o.response.message
          );
        }
      },
      (err) => {
        this.LoadingService.hideSpinner();
        this.NotificationService.SendNotification(
          'error',
          'Error',
          'Error inesperado'
        );
      }
    );
  }

  SetDefaultSelectedValues() {
    this.selectedMonth = (this.minDate.getMonth() + 1).toString();
    this.selectedDay = this.minDate.getDate().toString();
    this.selectedContactMethod = '0';
    this.selectedReminderDay = '0';

    this.isDayTouched = false;
    this.isMonthTouched = false;
    this.isReminderDayTouched = false;
    this.isContactMethodTouched = false;
    this.hasClickedSave = false;
  }

  FilterArrays() {
    //filter days arrays
    this.daysTypeMap[(+this.minDate.getMonth() + 1).toString()] =
      this.daysTypeMap[(this.minDate.getMonth() + 1).toString()].filter(
        (x) => x >= this.minDate.getDate()
      );
    //filter month array
    this.monthTypeArray = this.monthTypeArray.filter(
      (x) => +x.key >= this.minDate.getMonth() + 1
    );
  }

  addFavoriteProduct(product: any): boolean {
    this.LoadingService.showSpinner();
    let productInFavorites: boolean = false;
    const requestProduct: RequestFavoriteProduct = {
      AddToQueue: true,
      IdClient: parseInt(this.userId as string),
      Quantity: product.Quantity,
      Name: product.Name,
      IdProduct: product.IdProduct,
      Presentation:"",
      Attributes: product.SelectedAttributes
    };

    var object: RequestFavoriteProduct[]= [];
    object.push(requestProduct);

    this.clientService.AddClientFavoriteProductMethod(object).subscribe(res => {
      if (res.response.code === 'addfavoriteProduct-success') {
        this.NotificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      }
      this.LoadingService.hideSpinner();
    });
    return productInFavorites;
  }
}
