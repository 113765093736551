import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ProductsService } from 'src/app/services/core/products.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { FidelityService } from 'src/app/services/core/fidelity.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { MergeNService } from 'src/app/services/core/merge-n.service';
import { ClientsService } from 'src/app/services/core/clients.service';
import { CheckoutService } from 'src/app/services/core/checkout.service';
import { CartService } from 'src/app/services/core/cart.service';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { MessagesService } from 'src/app/services/sitefinity/messages.service';
import { RecentSearchService } from 'src/app/services/shared/recent-search.service';
import { takeUntil } from 'rxjs/operators';

declare var hideDesktop: any;
declare var hideMobile: any;
declare var $: any; // Declara jQuery

declare var showDesktop: any;
declare var showMobile: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {

  urlBase = environment.urlBase;
  urlSite = environment.urlSite;
  showCategories = false;
  _categories: any;
  _responseCategories: any;
  isUserLoggedIn: boolean = false;
  user: any;
  fidelityRedirectUrl!: string;
  productsCart: any = [];
  totalPriceCart: number = 0;
  selectedQuantity: { [key: number]: number } = {};
  sessionIDFidelityInput!: string;
  searchValue = '';
  showSearchError = false
  selectedUnitPrice: number[] = [];
  cartId!: string;
  totalQuantity: number = 0;
  private cartUpdateSubscription!: Subscription;
  private headerUpdateSubscription!: Subscription;
  urlSearch : string = 'busqueda?f=';
  allSelected = false;
  quantityValue: number = 1;
  unitSelected: string = 'Unidad'
  displayQuantityComponent: boolean = false;
  quantityByComponent:any = 10
  selectedUnits: { [key: number]: string } = {};
  userId: any;
  totalCart : number = 0;
  showDelete: boolean = false;
  hasSearched: boolean = false;
  modalDeleteHeader: any = {
    ModalName: 'deleteCartHeader',
  };
  openSidebar: boolean = true;
  showAutocompleteLogIn = false;
  isButtonDisabled = false;
  showAutocomplete = false;
  listAutocompleteProducts: any[] = [];
  topAutocompleteSearch : number = 10;
  private unsubscriber : Subject<void> = new Subject<void>();

  constructor
    (
      public SearchsService : RecentSearchService,
      public loader: LoadingService,
      private sharedService: SharedService,
      private products: ProductsService,
      private _utilitiesService: UtilitiesServicesService,
      private fidelityService: FidelityService,
      private utilitiesService: UtilitiesService,
      private router: Router,
      private notificationService: NotificationService,
      private clienteService: ClientsService,
      private checkoutService: CheckoutService,
      private cartService: CartService,
      private messageService: MessagesService,
      private mergenService: MergeNService
  ){


  }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (window.location.pathname === '/proceso-compra') {
          this.isButtonDisabled = true;

          // console.log($('[data-toggle="tooltip"]'));
           setTimeout(() => {

             $('[data-bs-toggle="tooltip"]').tooltip();
             console.log("entro a ngafter ? ")

         }, 800);

        }
        else {


          this.isButtonDisabled = false;


        }
      }
    });

    this.checkUserLoggedIn();
    if (window.location.pathname === '/proceso-finalizado' || window.location.pathname === '/procesando-compra' ) {
      console.log(this.isButtonDisabled)
      this.isButtonDisabled = true;
    }
    else {
      console.log("else",this.isButtonDisabled)
      this.isButtonDisabled = false;
      this.getProductsFromCartv2();
    }

    this.cartUpdateSubscription = this.sharedService.cartUpdated$.subscribe(() => {
      this.getProductsFromCartv2();
      if (this.sessionIDFidelityInput === '' || !this.sessionIDFidelityInput) {
        this.getSessionFidelity();
        this.getUrlFidelity();
      }
    });

    this.headerUpdateSubscription = this.sharedService.headerUpdated$.subscribe(() => {
      this.activeItemIndex = 0;
    });

    this.GetCategoriesHeader();
    this.SearchsService.UpdateRecentSearchs();
    this.getSessionFidelity();
    this.getUrlFidelity();

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });

    this.utilitiesService.GetParametersByKey("TopAutocompleteSearch").subscribe(res => {
      this.topAutocompleteSearch = res.value[0].value;
    });
  }

  getProductsFromCartv2(event?: any) {
    if (!this.openSidebar && event.type === 'click') {
      this.messageService.getMessagesMethod('CARVAL0002', '2').subscribe(m => {
        this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
      });
    }

    this.checkUserLoggedIn();
    const productStorage: any = this._utilitiesService.getValueStorage('productStorage');
    if (this.isUserLoggedIn) {

      // if (event?.type === 'click') {
      //   this.loader.showSpinner();
      //   this.productsCart = [];
      // }

      let cartId: any =  this._utilitiesService.decryptData(this.user.c);
      let userId: any =  this._utilitiesService.decryptData(this.user.i);
      if (productStorage && productStorage?.length > 0) {
        this.addProductStorageToCart(productStorage, cartId, userId).then(p=>{
          this.updateCartSummary(cartId);
        });
      }
      else {
        this.updateCartSummary(cartId);
      }
    }
    else {
      this.productsCart = productStorage ? productStorage : [];
      this.getTotalQuantity();
      this.AdjustProductsNoLogin();
    }
  }

  getTotalQuantityCart() {
    this.utilitiesService.GetQuantityCart(this.cartId).subscribe(total => {
      this.totalQuantity = total.Parameters;
    });
  }

  ngOnDestroy() {
    this.cartUpdateSubscription.unsubscribe(); // Asegúrate de desuscribirte en onDestroy
    this.headerUpdateSubscription.unsubscribe();
  }

  GetCategoriesHeader() {
    var m: string = '';
    this.products.GetCategoriesHeaderMethod(0).subscribe(response => {

      if (response.response.code == "OK0001") {
        this._categories = response.categories;
      }
      else {
        this._categories = [];
      }
    });
  }

  ToggleCategories() {
    this.showCategories = !this.showCategories;
  }

  checkUserLoggedIn() {
    this.isUserLoggedIn = this._utilitiesService.isUserLoggedIn();
    if (this.isUserLoggedIn) {
      this.user = this._utilitiesService.getValueStorage("sessionStorage");
      this.userId = this._utilitiesService.decryptData(this.user.i);
      this.cartId = this._utilitiesService.decryptData(this.user.c);
    }
    else
    {

      this.getUrlFidelity();
    }
  }

  GoToSearch(searchText:string){
    // this.router.navigateByUrl(this.urlSearch+searchText);
    window.location.href = environment.urlSite + this.urlSearch + searchText;
  }

  DeleteAllSearch(){
    this.SearchsService.DeleteAllSearch();
  }

  DeleteSearchBySearchID(searchID:string){
    this.SearchsService.DeleteSearchBySearchID(searchID);
  }

  getShortName(name: string): string {
    let clientName = name.split(' ')[0];
    return clientName;
  }

  loginOut() {
    this.setCategoryActiveItem(9999); // numero inventado para que se desactiven las categorias.
    this.loader.showSpinner();
    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this._utilitiesService.deleteValueStorage("sessionStorage");
        this.isUserLoggedIn = false;
        window.location.href = this.urlSite + 'index';
      }
    }, 1000);
  }

  hideDesktopListSearchResult(event:any){
    if(document.activeElement !== document.getElementById("listSearchResultDesktop")){
       if(event.relatedTarget === document.getElementById("inputSearchHeaderDesktop")){
         document.getElementById("inputSearchHeaderDesktop")?.focus();
       }
       else{
         hideDesktop();
       }
    }
  }

  hideDesktopInputSearchHeader(event:any){
    if(document.activeElement !== document.getElementById("inputSearchHeaderDesktop")){
      if(event.relatedTarget === null){
        hideDesktop();
      }
      else{
        if(event.relatedTarget === document.getElementById("listSearchResultDesktop")){
          document.getElementById("listSearchResultDesktop")?.focus();
        }
        else{
          if(event.relatedTarget === document.getElementById("listAutocompleteResultDesktop")){
            if((this.listAutocompleteProducts) && (this.listAutocompleteProducts.length > 0)){
              document.getElementById("listSearchResultDesktop")?.focus();
            }
            else{
              hideDesktop();
            }
          }
          else{
            hideDesktop();
          }
        }
      }
    }
  }

  hideMobileListSearchResult(event:any){
    if(document.activeElement !== document.getElementById("listSearchResultMobile")){
       if(event.relatedTarget === document.getElementById("inputSearchHeaderMobile")){
         document.getElementById("inputSearchHeaderMobile")?.focus();
       }
       else{
        hideMobile();
       }
    }
  }

  hideMobileInputSearchHeader(event:any){
    if(document.activeElement !== document.getElementById("inputSearchHeaderMobile")){
      if(event.relatedTarget === null){
        hideMobile();
      }
      else{
        if(event.relatedTarget === document.getElementById("listSearchResultMobile")){
          document.getElementById("listSearchResultMobile")?.focus();
        }
        else{
          if(event.relatedTarget === document.getElementById("listAutocompleteResultMobile")){
            if((this.listAutocompleteProducts) && (this.listAutocompleteProducts.length > 0)){
              document.getElementById("listSearchResultMobile")?.focus();
            }
            else{
              hideMobile();
            }
          }
          else{
            hideMobile();
          }
        }
      }
    }
  }

  Search() {
    if (this.isUserLoggedIn) {
      hideDesktop(); //oculta el panel de búsquedas recientes
    }
    var text = this.searchValue.trim();
    if (text != '') {
      if(text.length<3){
        this.showSearchError = true;
      }
      else{
        this.searchValue = '';
        this.showSearchError = false;
        const searchText = this.utilitiesService.CleanUrl(text);
        const navigationExtras = {
          queryParams: { f: searchText }
        };
        // Navega a la misma ruta con los nuevos parámetros
        this.router.navigate(['/busqueda'], navigationExtras).then(() => {
          // Recarga la página después de navegar
          window.location.reload();
        });
      }
    }
    else{
      this.showSearchError = false;
    }
  }

  //Acutocomplete
  SearchAutocomplete() {
    var text = this.searchValue.trim();
    if (text != '') {
      if(text.length<3){
        this.showAutocomplete = false;
        this.listAutocompleteProducts = [];
        if(!this.isUserLoggedIn){
          hideDesktop();
        }
      }
      else{
        this.products.SearchAutocompleteProductsServices(text,''+ this.topAutocompleteSearch).subscribe((res) => {
          this.listAutocompleteProducts = res.value;
          if(this.listAutocompleteProducts.length>0){
            this.showAutocomplete = true;
            setTimeout(() => {
              showDesktop();
            }, 50);
          }
          else{
            this.showAutocomplete = false;
            this.listAutocompleteProducts = [];
            hideDesktop();
          }
        },
          (err) => {
            this.notificationService.SendNotificationByCode('SearchAutocompleteProductsServices-fail', '2')
          });
      }
    }
    else{
      this.showAutocomplete = false;
      this.listAutocompleteProducts = [];
      if(!this.isUserLoggedIn){
        hideDesktop();
      }
    }
  }

  SearchAutocompleteMobile() {
    var text = this.searchValue.trim();
    if (text != '') {
      if(text.length<3){
        this.showAutocomplete = false;
        this.listAutocompleteProducts = [];
        if(!this.isUserLoggedIn){
          hideMobile();
        }
      }
      else{
        this.products.SearchAutocompleteProductsServices(text,''+ this.topAutocompleteSearch).subscribe((res) => {
          this.listAutocompleteProducts = res.value;
          if(this.listAutocompleteProducts.length>0){
            this.showAutocomplete = true;
            setTimeout(() => {
              showMobile();
            }, 50);
          }
          else{
            this.showAutocomplete = false;
            this.listAutocompleteProducts = [];
            hideMobile();
          }
        },
          (err) => {
            this.notificationService.SendNotificationByCode('SearchAutocompleteProductsServices-fail', '2')
          });
      }
    }
    else{
      this.showAutocomplete = false;
      this.listAutocompleteProducts = [];
      if(!this.isUserLoggedIn){
        hideMobile();
      }
    }
  }

  //SOY FISCHEL
  getFidelity() {
    this.loader.showSpinner();
    setTimeout(() => {
      let form = document.getElementById("fidelityHiddenForm") as HTMLFormElement;
      this.loader.hideSpinner();
      form.submit();
      this.loader.hideSpinner();
    }, 2000);
  }


  getSessionFidelity() {
    if (this.isUserLoggedIn) {
      let user: any = this._utilitiesService.getValueStorage('sessionStorage');
      let id = this._utilitiesService.decryptData(user.i);
      this.clienteService.GetUserPersonalInfoMethod(id).subscribe(res => {
        if (res.response.code === "OK0001") {
          let client = {
            IdClientType: res.responseObject.IdClientType,
            Identification: res.responseObject.Identification
          }
          if (client) {
            this.fidelityService.GetSessionFidelity(client).subscribe(resFidelity => {
              this.sessionIDFidelityInput = resFidelity;
            });
          }
          this.loadDynamicScript(res.responseObject.Identification);
        }
      });
    }
  }

  async loadDynamicScript(identification: string): Promise<void> {
    const scriptElement = document.createElement('script');
    const existingScript = document.getElementById("3");
      if (existingScript) {
        existingScript.remove();
    }
    scriptElement.src = '//546007595.collect.igodigital.com/collect.js';
    scriptElement.id = "3";
    scriptElement.innerHTML = `
    _etmc.push(["setOrgId", "546007595"]);
    _etmc.push(["setUserInfo", {"identification": "${identification}"}]);
    _etmc.push(["trackPageView"]);
    `;
    document.body.appendChild(scriptElement);
  }

  getUrlFidelity() {
    this.isUserLoggedIn = this._utilitiesService.isUserLoggedIn();
    if (this.isUserLoggedIn) {
      this.utilitiesService.GetParametersByKey("FidelityAuthenticatedRedirectUrl").subscribe(res => {
        this.fidelityRedirectUrl = res.value[0].value;
      });
    }
    else {
      this.utilitiesService.GetParametersByKey("FidelityRedirectToLogin").subscribe(res => {
        this.fidelityRedirectUrl = res.value[0].value;
      });
    }
  }

  goToProductsByCategory(url: string, index: any) {
    this.sharedService.updateFooter();
     this._utilitiesService.saveValueStorage(null, "scrollPosition");
    // Navega a la ruta deseada
    this.setCategoryActiveItem(index);
    this.router.navigate(['/categorias', url]);
       this._utilitiesService.saveValueStorage(null, "scrollPosition");

  }

  async getProductsFromCart() {

    const productStorage: any = this._utilitiesService.getValueStorage('productStorage');
    this.totalPriceCart = 0;
    //this.totalQuantity = 0;
    if (!this.isUserLoggedIn) {
      this.productsCart = productStorage ? productStorage : [];

      this.AdjustProductsNoLogin();
    }
    else {
      let cartId: any = await this._utilitiesService.decryptData(this.user.c);
      let userId: any = await this._utilitiesService.decryptData(this.user.i);

      if (productStorage && productStorage?.length > 0) {
        await this.addProductStorageToCart(productStorage, cartId, userId);
      }


      this.updateCartSummary(cartId);
    }
  }

  AdjustProducts() {
    this.productsCart.forEach((p: any, index: number) => {




      p.displayQuantityComponent = false;

      p.quantityByComponent = 10;


      p.numbers = [];

      if(p.MaxToPurchase > 10)
      {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else
      {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);

      }

    }




      this.selectedUnits[index] =  this.getPluralizedName(p.SelectedAttributes[0]?.Name,p.Quantity); // p.SelectedAttributes && p.SelectedAttributes.length > 0 ? p.SelectedAttributes[0]?.Name : 'Unidad';
      this.selectedQuantity[index] = p.Quantity;
      if (p.SelectedAttributes && p.SelectedAttributes.length > 0) {
        if (this.selectedUnits[index] === 'Unidad') {
          this.selectedUnitPrice[index] = p.SelectedAttributes[0]
            .PriceWithDiscount
            ? p.SelectedAttributes[0].PriceWithDiscount
            : p.SelectedAttributes[0].PriceWithTaxes;
        } else if (this.selectedUnits[index] === 'Caja') {
          if (
            p.SelectedAttributes[0].PriceWithDiscount > 0 ||
            p.SelectedAttributes[1]?.PriceWithDiscount > 0
          ) {
            this.selectedUnitPrice[index] = p.SelectedAttributes[0]
              .PriceWithDiscount
              ? p.SelectedAttributes[0].PriceWithDiscount
              : p.SelectedAttributes[1]?.PriceWithDiscount;
          } else {
            this.selectedUnitPrice[index] = p.SelectedAttributes[0]
              .PriceWithTaxes
              ? p.SelectedAttributes[0].PriceWithTaxes
              : p.SelectedAttributes[1]?.PriceWithTaxes;
          }
        }
      } else {
        this.selectedUnitPrice[index] = p.PriceWithDiscount > 0 ? p.PriceWithDiscount : p.PriceWithTaxes; // Establece un valor predeterminado
      }



    if (p.PriceWithDiscount > 0 && p.Quantity > 1) {
      p.PriceWithDiscount = p.PriceWithDiscount / p.Quantity;
    }
    this.selectedUnitPrice[index] = p.PriceWithDiscount ? p.PriceWithDiscount * p.Quantity : p.CurrentPrice * p.Quantity;








    });


  }


  AdjustProductsNoLogin() {
    this.productsCart.forEach((p: any, index: number) => {
      p.displayQuantityComponent = false;
      p.quantityByComponent = 10;
      p.numbers = [];
      if (p.MaxToPurchase > 10) {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);
        }
      }
      this.selectedUnits[index] =  this.getPluralizedName(p.SelectedAttributes[0]?.Name,p.Quantity);
      this.selectedQuantity[index] =  p.Quantity;
      if (p.SelectedAttributes && p.SelectedAttributes.length > 0) {
        if (this.selectedUnits[index] === 'Unidad') {
          this.selectedUnitPrice[index] = p.SelectedAttributes[0].PriceWithDiscount ? p.SelectedAttributes[0].PriceWithDiscount : p.SelectedAttributes[0].PriceWithTaxes;
          this.selectedUnitPrice[index] = p.SelectedAttributes[0].RealPrice;
        }
        else if (this.selectedUnits[index] === 'Caja') {
          if (p.SelectedAttributes[0].PriceWithDiscount > 0 || p.SelectedAttributes[1]?.PriceWithDiscount > 0) {
            this.selectedUnitPrice[index] = p.SelectedAttributes[0].PriceWithDiscount ? p.SelectedAttributes[0].PriceWithDiscount : p.SelectedAttributes[1]?.PriceWithDiscount;
            this.selectedUnitPrice[index] = p.SelectedAttributes[0].RealPrice;
          }
          else {
            this.selectedUnitPrice[index] = p.SelectedAttributes[0].PriceWithTaxes ? p.SelectedAttributes[0].PriceWithTaxes : p.SelectedAttributes[1]?.PriceWithTaxes;
            this.selectedUnitPrice[index] = p.SelectedAttributes[0].RealPrice;
          }
        }
      }
      else {
        this.selectedUnitPrice[index] = p.PriceWithDiscount > 0 ? p.PriceWithDiscount : p.PriceWithTaxes;
      }

      if (p.PriceWithDiscount == 0) {
        this.selectedUnitPrice[index] = (p.CurrentPrice * p.Quantity);
      }
      else {
        this.selectedUnitPrice[index] = (p.PriceWithDiscount * p.Quantity);
        p.CurrentTotalPrice = (p.CurrentPrice * p.Quantity);
      }
    });
    this.totalCart = this.utilitiesService.CalculateTotal(this.productsCart);
  }

  updateCartSummary(cartId: string, turnOffLoader: boolean = false) {
    this.cartService.UpdateCartInfoLightMethod(cartId).subscribe(async res => {
      if (res.response.code === "OK0001") {
        this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
        this.getCartSummary(cartId);
        this.getTotalQuantityCart();
        if (turnOffLoader) {
          this.loader.hideSpinner();
        }
      }
      else if (res.response.code === "CARVAL0001") {
        let orderId: any = this._utilitiesService.getValueStorage('order');
        let request: any;
        if (orderId != null && orderId != '') {
          this.checkoutService.FailedPurchaseRollback(orderId, this.userId).subscribe(r => {
            request = JSON.stringify(r);
            this._utilitiesService.insertErrorObservable(this.cartId, 'UPDATECARTSUMMARY', request, 'RollBack').subscribe(t => {});
            if (r.object) {
              this._utilitiesService.deleteAllStorage('order');
              this.updateCartSummary(cartId, true);
            }
            else {
              this.getOrCreateCartDelete();
            }
          });
        }
        else {
          this.getOrCreateCartDelete();
        }
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      }
    });
  }

  getCartSummary(cartId: string) {
    this.cartService.GetLightCartSummaryMethod(cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
        this.productsCart = [];
        this.productsCart =  res.responseObject.ProductQueue;
        this.loadCartScript(this.productsCart);
        this.totalCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
        this.AdjustProducts();
      }
      else if (res.response.code === "CARVAL0001") {
        let orderId: any = this._utilitiesService.getValueStorage('order');
        let request: any;
        if (orderId != null && orderId != '') {
          this.checkoutService.FailedPurchaseRollback(orderId, this.userId).subscribe(r => {
            request = JSON.stringify(r);
            this._utilitiesService.insertErrorObservable(this.cartId, 'UPDATECARTSUMMARY', request, 'RollBack').subscribe(t => {});
            if (r.object) {
              this._utilitiesService.deleteAllStorage('order');
              this.updateCartSummary(cartId, true);
            }
            else {
              this.getOrCreateCartDelete();
            }
          });
        }
        else {
          this.getOrCreateCartDelete();
        }
      }
      else if (res.response.code === 'emptyCart-lightSummary') {
        this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
        this.productsCart = [];
        this.productsCart =  res.responseObject.ProductQueue;
        this.totalCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
        this.AdjustProducts();
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      }
    })
  }

  async loadCartScript(products: any[]): Promise<void> {
    if (products.length > 0) {
      const existingScript = document.getElementById("1");
      if (existingScript) {
        existingScript.remove();
      }
      const scriptElement = document.createElement('script');
      scriptElement.src = '//546007595.collect.igodigital.com/collect.js';
      scriptElement.id = "1";

      const cartItems = products.map(product => (
        `{"item": "${product.Sku}", "quantity": "${product.Quantity}", "price": "${product.PriceWithDiscount > 0 ? product.PriceWithDiscount : product.CurrentTotalPrice}", "unique_id": "${product.IdProduct}"}`
      )).join(',');

      scriptElement.innerHTML = `
        _etmc.push(["setOrgId", "546007595"]);
        _etmc.push(["trackCart", {"cart": [${cartItems}] }]);
      `;
      document.body.appendChild(scriptElement);
      document.body.appendChild(scriptElement);
    }
  }

  getTotalQuantity() {
    this.totalQuantity = 0;
    for (const product of this.productsCart) {
      let quantity = 0;
      quantity += product.Quantity;
      this.totalQuantity += quantity;
    }
  }

  async deleteProductFromCart(product: any, index: number) {
    this.loader.showSpinner();
    if (!this.isUserLoggedIn) {
      if (this._utilitiesService.deleteValueStorage('productStorage', product)) {
        this.messageService.getMessagesMethod("DeleteProduct-Success", '2').subscribe(m => {
          this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
          setTimeout(() => {
            this.sharedService.updateCart();
          }, 600);
        });
      };
    }

    else {
      let cartId = await this._utilitiesService.decryptData(this.user.c);
      let idClient = await this._utilitiesService.decryptData(this.user.i);
      this.removeProductFromCart(product, cartId, idClient, index);
    }

    setTimeout(() => {
      // Ocultar el spinner después del tiempo especificado
      this.loader.hideSpinner();
    }, 2000);
  }

  async addProductStorageToCart(products: any, cartId: string, userId: string) {
    for (const product of products) {
      this.openSidebar = false;
      delete product.displayQuantityComponent
      delete product.numbers
      delete product.quantityByComponent
      product.InEdition = false;

      const cartRequest = {
        Address: null,
        FavoriteOrderName: null,
        IdCart: cartId ? cartId : null,
        IdClient: userId ? parseInt(userId) : null,
        IdFavoriteOrder: 0,
        IdFavoriteProduct: 0,
        PaymentExtraInfo: null,
        PaymentMethod: null,
        Product: product,
        IsCardToSave: null,
        UseSavedCard: null,
        UseFAC: null,
        IDSavedCard: null,
        FACRedirect3DSecureResponse: null,
        GenerateElectronicInvoice: null,
        IsAMEX: null,
      };

      const res: any = await this.products.AddProductsToCartMethodV2(cartRequest).toPromise();
      if (res.object===undefined) {
        this._utilitiesService.deleteValueStorage('productStorage', product);
      }
      this.loader.hideSpinner();
    }
    this.openSidebar = true;
  }

  selectQuantity(index: number, quantity: number): void {
    this.productsCart[index].Quantity = quantity;
    this.selectedQuantity[index] = quantity;
    this.quantityValue = quantity;
    this.selectType(index,this.selectedUnits[index]);
    this.saveProductWithOutSession();
  }

  calculateTotalPrice(index: number): void {
    const product = this.productsCart[index];
    const quantity = this.selectedQuantity[index];
    this.productsCart.Quantity = quantity;
    let price: number = 0;

    if (product.PriceWithDiscount == 0) {
      this.selectedUnitPrice[index] = (product.CurrentPrice * product.Quantity);
    }
    else {
      this.selectedUnitPrice[index] = (product.PriceWithDiscount * product.Quantity);
      product.CurrentTotalPrice = (product.CurrentPrice * product.Quantity);
    }

    price = this.selectedUnitPrice[index];
    product.InEdition = true;
    this.totalCart = this.utilitiesService.CalculateTotal(this.productsCart);
    this.loader.showSpinner();
    this.sharedService.addProductsToCartV2(product, product.SelectedAttributes.length>0?product.SelectedAttributes[0].Name:product.Name, quantity, price)
    setTimeout(() => {
      this.getProductsFromCartv2();
    }, 400);

    setTimeout(() => {
      this.loader.hideSpinner();
    }, 3000);
  }

  goToCheckoutCart() {
    this.loader.showSpinner();
    if (this.isUserLoggedIn)
      this.router.navigate(['/proceso-compra']);
    else {
      this.router.navigate(['/iniciosesion']);
    }

    setTimeout(() => {
      this.loader.hideSpinner();
    }, 2000);
  }

  removeProductFromCart(product: any, cartId: string, idClient: string, index: number): void {
    const object: any = {
      Address: null,
      FACRedirect3DSecureResponse: null,
      FavoriteOrderName: null,
      GenerateElectronicInvoice: null,
      IDSavedCard: null,
      IdCart: cartId,
      IdClient: idClient,
      IdFavoriteOrder: 0,
      IdFavoriteProduct: 0,
      IsAMEX: null,
      IsCardToSave: null,
      PaymentExtraInfo: null,
      PaymentMethod: null,
      Product: product,
      PurchaseOnBehalf: null,
      Quantity: null,
      UseFAC: null,
      UseSavedCard: null
    };
    this.productsCart.splice(index, 1);
    this.loader.showSpinner();
    this.checkoutService.RemoveProductFromCartMethod(object).subscribe(res => {
      if (res.response.code === "DeleteProduct-Success") {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        this.getOrCreateCart();
        if(this.productsCart.length <= 1){
          this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, "1", "3", "0").subscribe(res => {
            if (res.response.code !== "CSDEL") {
              this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
            }
          });
        }
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      }
    });
  }

  redirectToBlog(index: any) {
    this.sharedService.updateFooter();
    this.setCategoryActiveItem(9999); // numero inventado para que se desactiven las categorias.
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
      this.router.navigate(['blogs']); // Reemplaza '/' con la ruta de tu página de inicio

      this.loader.hideSpinner();
    }, 800);
  }


  redirectToPlanFrecuente(index: any) {
    this.sharedService.updateFooter();
    this.setCategoryActiveItem(9999); // numero inventado para que se desactiven las categorias.
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
      this.router.navigate(['planpaciente']); // Reemplaza '/' con la ruta de tu página de inicio

      this.loader.hideSpinner();
    }, 800);
  }

  redirectToShopper(index: any) {
    this.sharedService.updateFooter();
    this.setCategoryActiveItem(9999); // numero inventado para que se desactiven las categorias.
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
      this.router.navigate(['shopper']); // Reemplaza '/' con la ruta de tu página de inicio

      this.loader.hideSpinner();
    }, 800);
  }

  redirectToInsurers(index: any) {
    this.sharedService.updateFooter();
    this.setCategoryActiveItem(9999); // numero inventado para que se desactiven las categorias.
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
      this.router.navigate(['aseguradoras']); // Reemplaza '/' con la ruta de tu página de inicio

      this.loader.hideSpinner();
    }, 800);
  }

  changeIndex(index: number) {
    this.sharedService.updateFooter();
    this.setActiveItem(index);
  }

  activeItemIndex: number | null = null;
  setActiveItem(index: number): void {
    this.activeItemIndex = index;
  }

  activeHoverProducts(index: any) {
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {

      this.loader.hideSpinner();
    }, 800);
  }

  activeCategoryIndex: number | null = null;
  setCategoryActiveItem(index: number): void {
    this.setActiveItem(9999);
    this.activeCategoryIndex = index;
  }

  openQuantityComponent(index:any,value:any)  {
    this.productsCart[index].quantityByComponent =  this.validateQuantityZero(this.productsCart[index].quantityByComponent, index)

    if (value == false) {
    this.selectedQuantity[index] = this.productsCart[index].quantityByComponent;
    this.productsCart[index].Quantity =  this.selectedQuantity[index]
    this.selectType(index,this.selectedUnits[index]);
    }

    this.productsCart[index].displayQuantityComponent = value;
    this.getTotalQuantity();
    this.saveProductWithOutSession();
  }

  saveProductWithOutSession() {
    if (!this.isUserLoggedIn) {
      this._utilitiesService.deleteAllStorage('productStorage');
      this._utilitiesService.saveValueStorage(this.productsCart, 'productStorage');
    }
  }
  getPluralizedName(itemName: string, quantity: number): string {
    if(!itemName)
      itemName = 'unidad'
    if (itemName.toLowerCase() === 'unidad') {
      return quantity > 1 ? 'Unidades' : 'Unidad';
    } else if (itemName.toLowerCase() === 'caja') {
      return quantity > 1 ? 'Cajas' : 'Caja';
    } else {
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return itemName;
    }
  }

  selectType(index:any,itemName: string ): string {

    if (itemName.toLowerCase() === 'unidad' ||  itemName.toLowerCase() === 'unidades' ) {

      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Unidades' : 'Unidad';;
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index]
    } else if (itemName.toLowerCase() === 'caja' ||  itemName.toLowerCase() === 'cajas') {

      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Cajas' : 'Caja';
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index];
    } else {
      this.selectedUnits = itemName;
      this.calculateTotalPrice(index);
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return  this.selectedUnits[index]
    }
  }


  validateQuantityZero(quantity: any, index: number) {
    if(quantity <= 0 ) {
      return this.productsCart[index].MinToPurchase;
    }
    if (quantity < this.productsCart[index].MinToPurchase) {
      return this.productsCart[index].MinToPurchase;
    }
    if (quantity > this.productsCart[index].MaxToPurchase) {
      return this.productsCart[index].MaxToPurchase;
    }
    return quantity;
  }

  showModalDelete(): void {
    this.showDelete = true;
  }

  closeModalDelete(event: boolean) {
    this.showDelete = false;
    this.hasSearched = true;
    if (event) {
      this.productsCart = [];
      this.getOrCreateCartDelete();
    }
    else if (event === false) {
      this.getProductsFromCartv2();
    }
    this.router.navigate(['/index']);
  }

  getOrCreateCart() {
    this.mergenService.GetOrCreateCartMethod(this.cartId, parseInt(this.userId)).subscribe(async o => {
      if (o.response.code === "OK0001") {
        this.cartId = o.responseObject.IdCart;
        const IDClient = await this._utilitiesService.encryptData(o.responseObject.IdCart.toString());
        this.user.c = IDClient;
        this.updateCartSummary(this.cartId);
        setTimeout(() => {
          this.sharedService.updateCart();
        }, 400);
        this._utilitiesService.updateValueStorage(this.user, 'sessionStorage');
      }
      else {
        this.notificationService.SendNotification(o.response.type, o.response.titleMessage, o.response.message);
      }
    });
  }

  getOrCreateCartDelete() {
    this.mergenService.GetOrCreateCartMethod(this.cartId, parseInt(this.userId)).subscribe(async o => {
      if (o.response.code === "OK0001") {
        this.cartId = o.responseObject.IdCart;
        const IDClient = await this._utilitiesService.encryptData(o.responseObject.IdCart.toString());
        this.user.c = IDClient;
        this.updateCartSummary(this.cartId);
        setTimeout(() => {
          this.sharedService.updateCart();
        }, 200);
        this._utilitiesService.updateValueStorage(this.user, 'sessionStorage');
      }
      else {
        this.notificationService.SendNotification(o.response.type, o.response.titleMessage, o.response.message);
      }
    });
  }

  goToDetailProduct(url: string): void {
    if (url != null) {
      this.router.navigate(['/detalle-producto', url]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }


}
