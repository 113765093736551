import { Component, OnDestroy, HostListener  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogDetailSummary, BlogDetailSummaryResponse } from 'src/app/models/sitefinity/blogs.model';
import { BlogService } from 'src/app/services/sitefinity/blog.service';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { decode } from 'html-entities';
import { environment } from 'src/environments/environment';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';

const settings = new SitefinitySettings();
@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnDestroy {
  blog: any | null = null;
  urlBase = settings.SitefinityServicesUrl;
  SitefinityServicesUrl = environment.SitefinityServicesUrl
  videoEmbedCode!: SafeHtml;
  imageOg = '';

  constructor(
    private activatedroute: ActivatedRoute,
    private blogService: BlogService,
    private loader: LoadingService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private sitefinityPagesService: SitefinitypagesService,
    private meta: Meta) {
    this.getBlog(this.activatedroute.snapshot.params['url']);
    this.meta.removeTag('name="robots"');
    this.meta.addTag({ name: 'robots', content: 'index,follow'});
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    // Redirige a otro componente al presionar el botón de retroceso del navegador
    this.router.navigate(['/blogs']);
  }

  ngOnDestroy(): void {
    this.sitefinityPagesService.removeMetaTags();
  }

  getDatePublish(publishDate: string = ""): string {
    return format(parseISO(publishDate), "'Publicado el' d/MM/yyyy", { locale: es });
  }

  getBlog(url: string) {
    this.loader.showSpinner();
    this.blogService.GetBlogIdByUrlContent(`${url}`).subscribe((id: string) => {
      if (id.length > 0) {
        this.blogService.getBlogDetailByIdMethod(id).subscribe((response: any) => {
          this.blog = response.summary;
          if (this.blog.OpenGraphImage && this.blog.OpenGraphImage.length > 0) {
            this.imageOg = `${this.urlBase}${this.blog.OpenGraphImage[0].Url}`
          }
          this.sitefinityPagesService.setMetadataOpenGraph(this.blog!.blogDetail.MetaTitle ?? '', this.blog!.blogDetail.MetaDescription ?? '', this.imageOg, `${this.urlBase}blogs/${this.activatedroute.snapshot.params['url']}`);
          this.sitefinityPagesService.setDataPage(this.blog.blogDetail.MetaTitle ?? '', this.blog.blogDetail.MetaDescription ?? '', '');
          this.videoEmbedCode = this.getDecodedIframe(decode(this.blog.blogDetail.videoEmbedded, { level: 'html5' }));
          this.loader.hideSpinner();
        }, err => {
          this.loader.hideSpinner();
          this.router.navigate(['/blogs']);
        });
      } 
      else {
        this.loader.hideSpinner();
        this.router.navigate(['/blogs']);
      }
    }, err => {
      this.loader.hideSpinner();
      this.router.navigate(['/blogs']);
    });
  }

  getDecodedIframe(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  getVideoUrlSectionTwo(video: any) {
    if (video.sectionTwoVideo.length > 0) {
      return environment.SitefinityServicesUrl + video.sectionTwoVideo[0].Url;
    }
    return '';
  }
}
