import { Component } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { Faqs } from 'src/app/models/sitefinity/faqs.model';
import { FAQsService } from 'src/app/services/sitefinity/faqs.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
const settings = new SitefinitySettings();
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FAQSComponent {
  showMessage = false;
  constructor(
    private _faqsService: FAQsService,
    private titleService: Title,
    private sitefinityPagesService: SitefinitypagesService,
    private meta: Meta
  ){
    this.sitefinityPagesService.setDetailPage(settings.pages.find(x => x.Title === 'preguntas-frecuentes')!.ID);
  }

  faqs: Faqs[] = [];

  ngOnInit(): void {
    this.getFAQs();
    this.scrollToTop();
  }

  getFAQs() {
    this._faqsService.GetFAQsMethod(0, 100).subscribe(res => {
      if (res.response.code == "Faqs-success") {
        this.faqs = res.faqs;
        this.faqs.sort((a: { priority: number; }, b: { priority: number; }) => a.priority - b.priority);
      }
      else {
        this.faqs = [];
      }
      this.showMessage = true;
    });
  }

  scrollToTop() {
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo(0, 0);
    }
  }
}
