import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreResponse,Response, ResponseInterface  } from 'src/app/models/responses/response.model';
import { UtilitiesResponse } from 'src/app/models/core/utilities.model';
import { ResponseShopsByTypes, WCFRequestShopsByTypes, ShopAvailability } from 'src/app/models/core/shops.model';
import { CoreRequest } from 'src/app/models/requests/request.model';
import {    ResponseGenericObject }
from 'src/app/models/core/products.model';

const settings = new SitefinitySettings();

@Injectable({
  providedIn: 'root'
})
export class ShopsService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService) { }


  GetShopsByShopType(shopTypes: number[] = [], idProvince: number, idCanton: number
    , idDistrict?: number): Observable<CoreRequest> {
    return this.http.post<CoreRequest>(
      `${settings.CoreServicesUrl}Shop.svc/GetShopsByShopType`,
      new WCFRequestShopsByTypes(shopTypes, '', idDistrict, idCanton, idProvince));
  }



  GetShopsByShopTypeV2Services(shopTypes: number[] = [], idProvince: number, idCanton: number
    , idDistrict?: number): Observable<any> {
    return this.http.post<any>(
      `${settings.CoreServicesUrl}Shop.svc/GetShopsByShopType`,
      new WCFRequestShopsByTypes(shopTypes, '', idDistrict, idCanton, idProvince));
  }

  public GetShopsByShopTypeV2Method (shopTypes: number[] = [], idProvince: number, idCanton: number
    , idDistrict?: number): Observable<ResponseGenericObject> {
    return new Observable<ResponseGenericObject>((observer) => {
      this.GetShopsByShopTypeV2Services(shopTypes, idProvince, idCanton, idDistrict).pipe(
        switchMap((responseCore: any) => {
          const coreResponse = Object.assign(new CoreResponse(), responseCore);
          const code = coreResponse.Messages[0];

          if (code === "OK0001") {
            const parametersResponse: string = coreResponse.Parameters as string;
            const Response: any[] = JSON.parse(parametersResponse);

            return this.getMessages(code,'2').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: Response,
                  response: data
                };
                return response;
              })
            );
          } else {
            return this.getMessages(code,'2').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: false,
                  response: data
                };
                return response;
              })
            );
          }
        })
      ).subscribe(
        (response: ResponseGenericObject) => {
          observer.next(response);
          observer.complete();
        },
        (error: any) => {
          observer.error(error);
        }
      );
    });
  }


  private getMessages(code: string,category: string): Observable<ResponseInterface> {

    return new Observable<Response>((observer) => {
         var messageCore: string[]= [];
        this.MessagesService_.GetMessagesByKey(code,category).subscribe((data) => {
        var messageResponse = data.value[0];

            if (messageResponse == undefined) {


              messageCore.push(code);
              const responseServices: ResponseInterface = {
                  message : 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
                  alias : code + '-alias',
                  status : true,
                  code: code,
                  messageCore: messageCore,
                  buttonAcceptText:'',
                  buttonCancelText: '',
                  type:'',
                  action:'',
                  titleMessage: '',
                  url: '',
                  Messages:[]
                 };
                 observer.next(responseServices);
                 observer.complete();

            }
            else
            {
              const responseServices: ResponseInterface = {
                  message : messageResponse.message,
                  alias : messageResponse.aliascode,
                  status : true,
                  code:messageResponse.code,
                  messageCore: messageCore,
                  buttonAcceptText:messageResponse.buttonAcceptText,
                  buttonCancelText: messageResponse.buttonCancelText,
                  type:messageResponse.type,
                  action:messageResponse.action,
                  titleMessage: messageResponse.titleMessage,
                  url: messageResponse.url,
                  Messages:[]
              }
              observer.next(responseServices);
              observer.complete();
            }
      },(error) =>{

        const responseServices: ResponseInterface = {
          message : 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
          alias : 'getMessages-error-alias-01',
          status : false,
          code:'getMessages-error-01',
          messageCore: messageCore,
          buttonAcceptText:'',
          buttonCancelText: '',
          type:'',
          action:'',
          titleMessage: "falta agregar el código",
          url:'',
          Messages:[]
      };
      observer.error(responseServices);
      observer.complete();

      });

  });


  }

}
