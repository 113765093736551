<div class="modal fade" id="modalProcesandoPago" #modalProcesandoPago tabindex="-1"
  aria-labelledby="modalProcesandoPagoLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pb-0 border-0">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>

      </div>


      <div class="modal-body text-center pt-0">
        <div class="text-center modal-spinner">
          <span class="loader-circular"></span>
        </div>
        <h1 class="modal-title pb-2" id="modalProcesandoPagoLabel">Procesando</h1>
        <h2 class="text-order">Por favor no cierres la ventana de tu navegador</h2>
        <p>Estamos procesando y autenticando tu pago. Es posible que por algunos segundos tu pantalla se
          muestre en blanco. Por favor no cierres la ventana de tu explorador mientras se realiza la
          transacción</p>

      </div>

      <div class="modal-footer justify-content-between border-0 pt-0">
        <!-- <p class="text-footer-modal">Codigo de error</p> -->
      </div>
    </div>
  </div>
</div>
