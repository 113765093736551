<div [dynamicAttributes]="model.Attributes">
    <ng-container *ngFor="let item of model.Items; first as isFirst" class="col">
        <hr *ngIf="!isFirst">
        <div>
            <h5 *ngIf="item.Title" [attr.class]="item.Title.Css">
                <ng-container *ngIf="model.OpenDetails">
                    <a href="#" (click)="onDetailItemOpenHandler(item.Original, $event)">{{item.Title.Value}}</a>
                </ng-container>
                <ng-container *ngIf="!model.OpenDetails">
                    {{item.Title.Value}}
                </ng-container>
            </h5>
            <p *ngIf="item.PublicationDate" [attr.class]="item.PublicationDate.Css">
                <small>{{item.PublicationDate.Value}}</small>
            </p>

            <p *ngIf="item.Text" class="{{item.Text.Css}}">{{item.Text.Value}}</p>
        </div>
    </ng-container>
</div>
