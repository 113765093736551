import { Injectable } from '@angular/core';
import { MessagesService } from '../sitefinity/messages.service';
import { UtilitiesServicesService } from './utilities-services.service';
import { ProductsService } from '../core/products.service';
import { LoadingService } from './loading.service';
import { NotificationService } from './notification.service';
import { RecentSearchRequest } from 'src/app/models/core/products.model';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var direccionIP: any;

@Injectable({
  providedIn: 'root',
})
export class RecentSearchService {
  private _messageService: MessagesService;
  recentSearchs: any[] = [];

  private thisIP = '';

  constructor(
    MessagesService: MessagesService,
    public utilities: UtilitiesServicesService,
    private productsService: ProductsService,
    public loader: LoadingService,
    public notificationService: NotificationService,
    private deviceService: DeviceDetectorService
  ) {
    this._messageService = MessagesService;
  }

  GetRecentSearchs(): any[] {
    return this.recentSearchs;
  }

  UpdateRecentSearchs() {
    let userId = this.utilities.GetUserId();
    if (userId != '') {
      this.productsService.getRecentClientSearchesMethod(userId, 5).subscribe((o) => {
        if (o.response.code == 'OK0001') {
          this.recentSearchs = this.OrderRecentSearchs(o.products);
        }
        else {
          this.recentSearchs = [];
        }
      });
    }
  }

  OrderRecentSearchs(productsArray: any[]): any[] {
    if (productsArray.length > 0) {
      productsArray.sort((a, b) => {
        const dateA = new Date(a.CreateDate).getTime();
        const dateB = new Date(b.CreateDate).getTime();
        return dateB - dateA;
      });
      if (productsArray.length > 5) {
        productsArray = productsArray.slice(0, 5);
      }
    }
    return productsArray;
  }

  DeleteAllSearch() {
    let userId = this.utilities.GetUserId();
    if (userId != '') {
      this.loader.showLoadingBar();
      this.productsService
        .DeleteClientRecentSearchesMethod('', userId)
        .subscribe(
          (o) => {
            if (o.response.code == 'OK0001') {
              this.recentSearchs = [];
              this.loader.hideLoadingBar();
            } else {
              this.notificationService.SendNotification(
                o.response.type,
                o.response.titleMessage,
                o.response.message
              );
              this.loader.hideLoadingBar();
            }
          },
          () => {
            this.notificationService.SendNotificationByCode(
              'deleteAllRecentSearchs-fail',
              '1'
            );
            this.loader.hideLoadingBar();
          }
        );
    }
  }

  DeleteSearchBySearchID(ID: string) {
    if (ID != '') {
      this.loader.setLoading(true);
      this.productsService.DeleteClientRecentSearchesMethod(ID, '').subscribe((o) => {
        if (o.response.code == 'OK0001') {
          this.UpdateRecentSearchs();
          this.loader.setLoading(false);
        } 
        else {
          this.notificationService.SendNotification(o.response.type, o.response.titleMessage,o.response.message);
          this.loader.setLoading(false);
        }
      },
      () => {
        this.notificationService.SendNotificationByCode('deleteAllRecentSearchs-fail','1');
        this.loader.setLoading(false);
      });
    }
  }

  SaveRecentSearch(text: string, idCategory: number, idProduct: number) {
    text = text.trim();
    if (idCategory > 0 || idProduct > 0 || text !== '') {
      this.loader.showLoadingBar();
      let userId = this.utilities.GetUserId();
      const deviceInfo = this.deviceService.getDeviceInfo();
      let isMobile = deviceInfo.deviceType !== 'desktop';

      if (this.thisIP == '') {
        this.thisIP = direccionIP;
      }

      let defaultRecentSearchRequest: RecentSearchRequest = {
        Argument: text,
        IDClient: +userId,
        IDProduct: idProduct,
        IDProductCtegory: idCategory,
        IPAddress: this.thisIP,
        BrowserName: deviceInfo.browser,
        BrowserVersion: deviceInfo.browser_version,
        OperatingSystem: deviceInfo.os,
        DeviceType: deviceInfo.deviceType,
        IsMobileBrowser: isMobile ? '1' : '0',
        UserAgent: navigator.userAgent,
        IsToCount: idCategory > 0 ? true : false,
      };

      this.productsService
        .SaveRecentClientSearchesMethod(defaultRecentSearchRequest)
        .subscribe(
          () => {
            // Actualizar las búsquedas recientes después de guardar
            if (userId != '' && text != '')
              this.UpdateRecentSearchs();
            this.loader.hideLoadingBar();
          },
          () => {
            this.notificationService.SendNotificationByCode(
              'saveRecentSearch-fail',
              '1'
            );
            this.loader.hideSpinner();
          }
        );
    }
  }
}
