

<main class="main">
  <div class="container container-input mt-3 mt-lg-5">
    <h1 class="my-1 color-primary">Iniciar sesión</h1>

    <p>Si ya tenés una cuenta en las plataformas de Farmacia La Bomba, solo debés ingresar con el mismo correo electrónico y contraseña que ya registraste. Si no tenés cuenta, registrate
    <a routerLink="/registro">aquí</a>

    <form [formGroup]="form" (ngSubmit)="signUp(form.value)">
      <div class="d-flex flex-column my-4">
          <label for="email" class="form-label">Correo electrónico</label>
          <input type="email" class="form-control contact-input" id="LoginName" formControlName="LoginName"
          [ngClass]="LoginName!.invalid && LoginName!.touched ? 'is-invalid' : ''" maxlength="51">
          <div *ngIf="LoginName!.invalid && (LoginName!.dirty || LoginName!.touched || this.isSubmited)" class="text-danger">
              <span *ngIf="LoginName!.errors?.required">{{validationMsg['required']}}</span>
              <span *ngIf="LoginName!.errors?.['minlength']">
                  {{ validationMsg['minlength'].replace("XXX", LoginName!.errors!.minlength.requiredLength )}}
              </span>
              <span *ngIf="LoginName!.errors?.['maxlength']">
                  {{ validationMsg['maxlength'].replace("XXX", LoginName!.errors!.maxlength.requiredLength )}}
              </span>
              <span *ngIf="LoginName!.errors?.email">{{ validationMsg['emailpattern']}}</span>
          </div>
      </div>
      <div class="d-flex flex-column mt-4">
          <label for="password" class="form-label">Contraseña</label>
          <div class="input-group password-input mb-2">
              <input [type]="showPassword ? 'text' : 'password'" formControlName="Password" id="Password" class="form-control password-border" [ngClass]="Password!.invalid && Password!.touched ? 'is-invalid' : ''" maxlength="31">
              <i class="bi" [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword" (click)="togglePasswordVisibility()"></i>
          </div>
          <div *ngIf="Password!.invalid && (Password!.dirty || Password!.touched || this.isSubmited)" class="text-danger">
              <span *ngIf="Password!.errors?.required">{{validationMsg['required']}}</span>
              <span *ngIf="Password!.errors?.['minlength']">
                  {{ validationMsg['minlength'].replace("XXX", Password!.errors!.minlength.requiredLength )}}
              </span>
              <span *ngIf="Password!.errors?.['maxlength']">
                  {{ validationMsg['maxlength'].replace("XXX", Password!.errors!.maxlength.requiredLength )}}
              </span>
          </div>
      </div>
      <div class="my-3">
        <button class="btn btn-primary px-4" type="submit">Ingresar</button>
      </div>
      <a routerLink="/recuperar-contrasena">¿Olvidaste tu contraseña?</a>
    </form>
  </div>

  <app-modal-login [showModal]="modal" [message]="this.messageModal"></app-modal-login>
  <app-modal-general [showModal]="showModalGeneral" (showModalChange)="closeModalCI($event)" [modalData]="modalGeneral"></app-modal-general>
  <app-modal-consentimiento [showModal]="this.showCI" [modalData]="modalCI" [document]="this.documentConsent" (showModalChange)="UpdateCI($event)"></app-modal-consentimiento>
  <app-modal-general [showModal]="showModalError" (showModalChange)="ModalErrorAction($event)" [modalData]="modalError"></app-modal-general>

  <app-modal-general [showModal]="showmodalGeneral_login" (showModalChange)="ModalGeneralLoginAction($event)" [modalData]="modalGeneral_login"></app-modal-general>

</main>
