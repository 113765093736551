<main class="main">
  <div class="container container-section mt-3 mt-lg-5" *ngIf="insurers.length > 0">
    <div id="smb_12"></div>
    <h1 class="color-primary">
      Aseguradoras
    </h1>
    <p>¡Nos preocupamos por tu bienestar! ¿Necesitás ayuda para tramitar el despacho de tus medicamentos? Estamos aquí para ayudarte de manera rápida y eficiente.</p>
    <div class="row">
      <div class="col-12 col-md-6" *ngFor="let f of insurers; let i = index">
        <div class="card mb-3 border-color">
          <div class="card-body">
            <div class="d-flex align-items-center w-100">
              <img *ngIf="(f.InsurerLogo && f.InsurerLogo.length > 0)" src="{{SitefinityServicesUrl + f.InsurerLogo[0].Url}}" alt="{{f.InsurerLogo[0].AlternativeText}}" class="img-logo-insurance me-3">
              <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between">
                      <h2 class="fw-medium">{{f.InsurerName}}</h2>
                      <a *ngIf="f.InsurerCTA" href="{{f.InsurerCTA}}" target="_blank"> <i class="bi bi-whatsapp fs-5"></i></a>
                  </div>
                  <h3 *ngIf="f.InsurerAcronym" class="mb-0">{{f.InsurerAcronym}}</h3>
                  <a *ngIf="(f.InsurerDescription && f.InsurerDescription != '')" class="caret text-decoration-none color-dark" href="#collapseExample" type="button"
                    data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse' + i" aria-expanded="false"
                    [attr.aria-controls]="'flush-collapse' + i">
                    Más información
                  </a>
              </div>
            </div>
            <div class="collapse px-1" [id]="'flush-collapse' + i">
              <div>
                <p class="fs-small" [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(f.InsurerDescription)"></p>
                <p class="fs-small"> <i class="bi bi-telephone me-2"></i> {{f.InsurerPhone}}</p>
                <p class="fs-small"> <i class="bi bi-envelope me-2"></i>{{f.InsurerEmail}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt-5" *ngIf="insurers.length === 0 && showEmptyMessage">
    <i class="bi bi-search icon-page"></i>
    <h1 class="">Sin resultados</h1>
    <p class="">No se encontraron datos.</p>
  </div>
</main>
