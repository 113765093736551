import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilitiesServicesService } from '../services/shared/utilities-services.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  sessionMaxTimeParameter: string = '-1';

  constructor (
    private utilities: UtilitiesServicesService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedRequest = request.clone({
      setHeaders: {
        'Cache-Control': 'no-store, no-cache, must-revalidate, private'
      }
    });

    //Se agrega este bypass para poder realizar el cambio de contraseña
    if(request.body){
      var BypassSecurity = request.body.BypassSecurity
      if(BypassSecurity){
        this.utilities.UpdateSessionStartTime();
        return next.handle(modifiedRequest);
      }
    }

    if (this.utilities.isProtectedRoute(request.url)) {
      // Verifica la autenticación solo para las rutas protegidas.
      if (!this.utilities.isUserLoggedIn()) {

        window.location.href = environment.urlSite + '/iniciosesion' ;
        return of();
      }
      else {

        this.utilities.UpdateSessionStartTime();
        return next.handle(modifiedRequest);
      }
    }
    else {
      // Continúa con la solicitud HTTP.
      return next.handle(modifiedRequest).pipe(
        catchError((error) => {
          return of(error); // Puedes propagar el error o retornar un valor específico.
        })
      );
    }
  }
}
