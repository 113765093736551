<!-- Modal Recordar producto-->
<div class="modal fade modal-overlay-light" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="rememberModalLabel" aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-style">
      <div class="modal-header">
        <h2 class="modal-title fs-5 color-primary" id="exampleModalLabel">{{modalTitle}}</h2>
        <button type="button" class="btn-close" (click)="CloseModalRemember()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-12">
            <span>Fecha de inicio del recordatorio</span>
          </div>

          <div class="col-6 mb-3">
            <label for="">Día</label>
            <select [disabled]="this.isEditMode" class="form-select" aria-label="Default select example"
              [(ngModel)]="selectedDay" (blur)="isDayTouched = true">
              <option [value]="'0'"></option>
              <option *ngFor="let day of daysTypeMap[selectedMonth]" [value]="day">{{ day }}</option>
            </select>
            <span class="text-danger" *ngIf="(isDayTouched || hasClickedSave) && +selectedDay <= 0">Seleccione un día
              válido</span>
          </div>

          <div class="col-6 mb-3">
            <label for="">Mes</label>
            <select [disabled]="this.isEditMode" class="form-select" aria-label="Default select example"
              [(ngModel)]="selectedMonth" (blur)="isMonthTouched = true">
              <option [value]="'0'"></option>
              <option *ngFor="let month of monthTypeArray" [value]="month.key">{{ month.value }}</option>
            </select>
            <span class="text-danger" *ngIf="(isMonthTouched || hasClickedSave) && +selectedMonth <= 0">Seleccione un
              mes válido</span>
          </div>
          <div class="col-12 d-block text-error">
            <span
              *ngIf="(isMonthTouched || isDayTouched || hasClickedSave) && !isValidDate(selectedDay, selectedMonth)">La
              fecha de inicio debe ser posterior a la fecha actual</span>
          </div>

          <div class="col-12">
            <span>Seleccioná el día del mes para recordarte tu compra y el medio por el cual deseás que
                te contactemos.</span>
        </div>

          <div class="col-6 mb-3">
            <label for="">Día</label>
            <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedReminderDay"
              (blur)="isReminderDayTouched = true">
              <option value="0"></option>
              <option *ngFor="let day of fullDays" [value]="day">{{ day }}</option>
            </select>
            <span class="text-danger"
              *ngIf="(isReminderDayTouched || hasClickedSave) && +selectedReminderDay <= 0">Seleccione un día de
              recordatorio válido</span>
          </div>

          <div class="col-6 mb-3">
            <label for="">Medio de contacto</label>
            <select class="form-select" aria-label="Default select example"
              [(ngModel)]="selectedContactMethod">
              <option value="0"></option>
              <option *ngFor="let method of contactMethods" [value]="method.Medio">{{ method.Descripcion }}</option>
            </select>
            <span class="text-danger"
              *ngIf="(isContactMethodTouched || hasClickedSave) && +selectedContactMethod <= 0">Seleccione un medio de
              contacto válido</span>
          </div>

          <div class="col-12">
            <span>Tomá en cuenta que el medio para contactarte es el mismo para todos los recordatorios.
                Por lo que si ya tenés creados otros recordatorios, actualizaremos el medio de contacto
                en cada uno, según lo que seleccionaste en este recordatorio.</span>
        </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" *ngIf="!isEditMode" class="btn btn-outline-secondary" (click)="CloseModalRemember()">Cancelar</button>
        <button type="button" *ngIf="isEditMode" class="btn btn-danger mr-5" (click)="SubmitDelete()">Eliminar</button>
        <button type="button" class="btn btn-primary ml-3" (click)="Submit()">{{submitText}}</button>
      </div>
    </div>
  </div>
</div>
