import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading: boolean;
  private spinner: boolean;

  constructor() {
    this.loading = false;
    this.spinner = false;
  }

  //Loading Bar
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  getLoading(): boolean {
    return this.loading;
  }
  showLoadingBar(){
    this.loading = true;
  }
  hideLoadingBar(){
    this.loading = false;
  }

  //Spinner
  showSpinner(){
    this.spinner = true;
  }
  hideSpinner(){
    this.spinner = false;
  }
  getSpinner(): boolean {
    return this.spinner;
  }
}
