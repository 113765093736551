<!-- Modal -->
<div [ngClass]="{'show': showModal}" class="modal fade modal-overlay-light" #modalCard id="modalCard" tabindex="-1" data-bs-backdrop="static"
  aria-labelledby="cardModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <form [formGroup]="form" (ngSubmit)="processPayment()" autocomplete="off">
      <div class="modal-content  modal-style">
        <div class="modal-header">
          <h2 class="modal-title fs-5 color-primary" id="cardModalLabel">Agregar tarjeta</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetValue()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="exampleFormControlInput1" class="form-label">Nombre completo</label>
              <input type="text" class="form-control contact-input" id="name" formControlName="Name" autocomplete="off">
              <div *ngIf="Name!.invalid && ( Name!.touched || this.isSubmited )" class="d-block text-error">
                <span *ngIf="Name!.errors?.['required']">
                  {{ validationMsg['required'] }}
                </span>
              </div>
            </div>
            <div class="col-12 mb-3">
              <label for="cardNumber" class="form-label">Número de tarjeta</label>
              <input type="text" class="form-control contact-input" id="cardNumber" formControlName="CardNumber"
                (keypress)="keyPressCardNumber($event)"
                [ngClass]="CardNumber!.invalid && (CardNumber!.touched || this.isSubmited )? 'is-invalid':''"
                maxlength="16" autocomplete="off">
              <div *ngIf="CardNumber!.invalid && ( CardNumber!.touched || this.isSubmited )" class="d-block text-error">
                <span *ngIf="CardNumber!.errors?.['required']">
                  {{ validationMsg['required'] }}
                </span>
                <span *ngIf="CardNumber!.errors?.['minlength']">
                  {{ validationMsg['minlength'].replace("XXX", CardNumber!.errors!.minlength.requiredLength )}}
                </span>
                <span *ngIf="CardNumber!.errors?.['maxlength']">
                  {{ validationMsg['maxlength'].replace("XXX", CardNumber!.errors!.maxlength.requiredLength )}}
                </span>
                <span *ngIf="CardNumber!.errors?.['pattern']">
                  {{ validationMsg['numberpattern']}}
                </span>
              </div>
              <small class="text-error" *ngIf="isAMEX">Lo sentimos, pero no aceptamos pago con tarjetas American Express. Por favor te pedimos que utilicés otra tarjeta.</small>
            </div>
            <div class="col-12 mb-3">
              <label for="exampleFormControlInput1" class="form-label">Número LifeMiles (opcional)</label>
              <input type="text" class="form-control contact-input" id="numberLife" formControlName="NumberLife" autocomplete="off">
              <p class="text-footer-card">Si aún no sos Socio LifeMiles, registrate gratis
                ingresando a <a href="https://www.lifemiles.com" target="_blank">https://www.lifemiles.com</a>. No es requisito contar con tarjeta de crédito para ser socio.
            </p>
            </div>
            <div class="col-6 mb-3">
              <label for="">Fecha de expiración</label>
              <select class="form-select" aria-label="Default select example" formControlName="Month">
                <option *ngFor="let m of months" [value]="m">{{ m }}</option>
                <span *ngIf="Code!.errors?.['required']">
                  {{ validationMsg['required'] }}
                </span>
              </select>
              <div *ngIf="Month!.invalid && ( Month!.touched || this.isSubmited )" class="d-block text-error">
                <span *ngIf="Month!.errors?.['required']">
                  {{ validationMsg['required'] }}
                </span>
              </div>
            </div>

            <div class="col-6 mb-3">
              <label for=""></label>
              <select class="form-select" aria-label="Default select example" formControlName="Year">
                <option *ngFor="let y of years" [value]="y">{{ y }}</option>
              </select>
              <div *ngIf="Year!.invalid && ( Year!.touched || this.isSubmited )" class="d-block text-error">
                <span *ngIf="Year!.errors?.['required']">
                  {{ validationMsg['required'] }}
                </span>
              </div>
            </div>

            <div class="col-12 mb-3">
              <label for="exampleFormControlInput1" class="form-label">Código CVV</label>
              <input type="text" class="form-control contact-input" id="code" formControlName="Code" maxlength="4"
                (keypress)="keyPressCode($event)" autocomplete="off">
              <div *ngIf="Code!.invalid && ( Code!.touched || this.isSubmited )" class="d-block text-error">
                <span *ngIf="Code!.errors?.['required']">
                  {{ validationMsg['required'] }}
                </span>
                <span *ngIf="Code!.errors?.['minlength']">
                  {{ validationMsg['minlength'].replace("XXX", Code!.errors!.minlength.requiredLength )}}
                </span>
                <span *ngIf="Code!.errors?.['maxlength']">
                  {{ validationMsg['maxlength'].replace("XXX", Code!.errors!.maxlength.requiredLength )}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="resetValue()">Cancelar</button>
          <button type="submit" class="btn btn-primary">Realizar pago</button>
        </div>
      </div>
    </form>
  </div>
</div>
