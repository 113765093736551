import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ContentComponent } from "./components/content-block/content-block.component";
import { WrapperComponentDirective } from "./directives/component-wrapper.directive";
import { RootUrlService } from "./services/root-url.service";
import { CommonModule, DatePipe } from "@angular/common";
import { RendererContractImpl } from "./editor/renderer-contract";
import { RenderContext } from "./services/render-context";
import { RenderWidgetService } from "./services/render-widget.service";
import { ErrorComponent } from "./components/error/error.component";
import { SectionComponent } from "./components/section/section.component";
import { DynamicAttributesDirective } from "./directives/dynamic-attributes.directive";
import { RestService } from "./sdk/services/rest.service";
import { StyleGenerator } from "./styling/style-generator.service";
import { ContentListComponent } from "./components/content-list/content-list.component";
import { ContentListDetailComponent } from "./components/content-list/detail/content-list-detail.component";
import { LayoutService } from "./sdk/services/layout.service";
import { ServiceMetadata } from "./sdk/service-metadata";
import { ContentListMasterComponent } from "./components/content-list/master/content-list-master.component";
import { CardsListComponent } from "./components/content-list/master/cards-list/cards-list.component";
import { ContentListRestService } from "./components/content-list/content-list-rest.service";
import { ListWithImageComponent } from "./components/content-list/master/list-with-image/list-with-image.component";
import { ListWithSummaryComponent } from "./components/content-list/master/list-with-summary/list-with-summary.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { ProductosComponent } from './components/productos/productos.component';
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule,FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BackendComponent } from './components/backend/backend.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { LegalInformationComponent } from "./components/legal-information/legal-information.component";
import { PharmaciesComponent } from './components/pharmacies/pharmacies.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationArrayComponent } from './components/notification-array/notification-array.component';

//For currency
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
registerLocaleData(localeEs, 'es');
import {MycurrencyPipe} from './models/settings/custom.currencypipe';
import { DetalleProductoComponent } from './components/detalle-producto/detalle-producto.component';


// Google Maps
import { GoogleMapsModule } from "@angular/google-maps";
import { FAQSComponent } from './components/faqs/faqs.component';
import { InsurersComponent } from './components/Insurers/Insurers.component';
import { IndexComponent } from './components/index/index.component';
import { ShopperComponent } from './components/shopper/shopper.component';
import { FrequentPlanComponent } from './components/frequent-plan/frequent-plan.component';
import { BlogsListComponent } from './components/blogs-list/blogs-list.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { SafePipe } from './pipes/safe.pipe';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { ModalCardComponent } from './components/modals/modal-card/modal-card.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ModalRememberComponent } from './components/modals/modal-remenber/modal-remember.component';
import { ModalDirectionComponent } from './components/modals/modal-direction/modal-direction.component';
import { ModalPharmaciesComponent } from './components/modal-pharmacies/modal-pharmacies.component';
import { AppSocialMediaComponent } from './components/app-social-media/app-social-media.component';

import { ModalAddCardComponent } from './components/modals/modal-add-card/modal-add-card.component';
import { ModalDisponibilidadComponent } from './components/modals/modal-disponibilidad/modal-disponibilidad.component';
import { ModalErrorComponent } from './components/modals/modal-error/modal-error.component';
import { ModalProcesandoPagoComponent } from './components/modals/modal-procesando-pago/modal-procesando-pago.component';
import { ModalProductoFaltanteComponent } from './components/modals/modal-producto-faltante/modal-producto-faltante.component';
import { ModalRecommendedProductComponent } from './components/modals/modal-recommended-product/modal-recommended-product.component';
import { ModalRecordarProductoComponent } from './components/modals/modal-recordar-producto/modal-recordar-producto.component';
import { ModalScrollableComponent } from './components/modals/modal-scrollable/modal-scrollable.component';
import { ModalSuccesComponent } from './components/modals/modal-succes/modal-succes.component';
import { ModalAlertComponent } from './components/modals/modal-alert/modal-alert.component';
import { ModalGeneralComponent } from './components/modals/modal-general/modal-general.component';
import { CheckoutCartComponent } from "./components/checkout-cart/checkout-cart.component";
import { CheckoutPaymentComponent } from './components/checkout-payment/checkout-payment.component';
import { GoogleMapsAutocompleteComponent } from './components/shared/google-maps-autocomplete/google-maps-autocomplete.component';
import { ModalConsentimientoComponent } from './components/modals/modal-consentimiento/modal-consentimiento.component';
import { ModalDeleteCartComponent } from './components/modals/modal-delete-cart/modal-delete-cart.component';
import { CheckoutShippingComponent } from "./components/checkout-shipping/checkout-shipping.component";
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ModalSeguimientoOrdenesComponent } from './components/modals/modal-seguimiento-ordenes/modal-seguimiento-ordenes.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { UnsubscriptionComponent } from './components/unsubscription/unsubscription.component';
import { PaymentProcesingComponent } from './components/payment-procesing/payment-procesing.component';
import { CheckoutEndComponent } from './components/checkout-end/checkout-end.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalOneProductComponent } from "./components/modals/modal-one-product/modal-one-product.component";
import { SessionInterceptor } from "./interceptors/session.interceptor";
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { MetasComponent } from './components/metas/metas.component';

@NgModule({
    declarations: [
        AppComponent,
        ContentComponent,
        SectionComponent,
        ContentListComponent,
        ContentListDetailComponent,
        ContentListMasterComponent,
        CardsListComponent,
        ErrorComponent,
        WrapperComponentDirective,
        DynamicAttributesDirective,
        ListWithImageComponent,
        ListWithSummaryComponent,
        LoginComponent,
        ProductosComponent,
        BackendComponent,
        HeaderComponent,
        FooterComponent,
        SignUpComponent,
        LegalInformationComponent,
        PharmaciesComponent,
        MycurrencyPipe,
        DetalleProductoComponent,
        FAQSComponent,
        InsurersComponent,
        IndexComponent,
        ShopperComponent,
        FrequentPlanComponent,
        BlogsListComponent,
        BlogDetailComponent,
        SafePipe,
        ModalLoginComponent,
        MyAccountComponent,
        ModalCardComponent,
        ContactUsComponent,
        PasswordRecoveryComponent,
        PasswordChangeComponent,
        EllipsisPipe,
        ModalRememberComponent,
        ModalDirectionComponent,
        ModalPharmaciesComponent,
        AppSocialMediaComponent,

        ModalAddCardComponent,
        ModalDisponibilidadComponent,
        ModalErrorComponent,
        ModalProcesandoPagoComponent,
        ModalProductoFaltanteComponent,
        ModalRecommendedProductComponent,
        ModalRecordarProductoComponent,
        ModalScrollableComponent,
        ModalSuccesComponent,
        ModalAlertComponent,
        ModalGeneralComponent,
        CheckoutCartComponent,
        CheckoutShippingComponent,
        CheckoutPaymentComponent,
        GoogleMapsAutocompleteComponent,
        ModalConsentimientoComponent,
        ModalDeleteCartComponent,
        PageNotFoundComponent,
        ModalSeguimientoOrdenesComponent,
        SpinnerComponent,
        UnsubscriptionComponent,
        PaymentProcesingComponent,
        CheckoutEndComponent,
        ModalOneProductComponent,
        LoadingBarComponent,
        MetasComponent

    ],
    entryComponents: [
        ContentComponent,
        ErrorComponent,
        SectionComponent,
        ContentListComponent,
        ModalAddCardComponent,
        ModalDisponibilidadComponent,
        ModalErrorComponent,
        ModalProcesandoPagoComponent,
        ModalProductoFaltanteComponent,
        ModalRecommendedProductComponent,
        ModalRecordarProductoComponent,
        ModalScrollableComponent,
        ModalSuccesComponent,
        ModalConsentimientoComponent,
        ModalSeguimientoOrdenesComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CarouselModule,
        GoogleMapsModule,
        NotificationComponent,
        NotificationArrayComponent,
    ],
    providers: [
        RootUrlService,
        LayoutService,
        ServiceMetadata,
        RestService,
        StyleGenerator,
        RenderContext,
        RenderWidgetService,
        RendererContractImpl,
        WrapperComponentDirective,
        DynamicAttributesDirective,
        ContentListRestService,
        DatePipe,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SessionInterceptor,
          multi: true,
        },

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
