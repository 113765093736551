<div class="modal fade" id="modalDisponibilidad" tabindex="-1" aria-labelledby="modalDisponibilidadLabel"
  aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-search-stores">
    <div class="modal-content">
      <div class="modal-header align-items-start">
        <h2 class="modal-title fs-5" id="modalDisponibilidadLabel">Disponibilidad en Farmacias La Bomba</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="this.close()"></button>
      </div>
      <div class="modal-body">


        <div class="row wrapper">
          <div class="col-12 col-lg-6">

            <h3 for="exampleFormControlInput1" class="form-label">Buscar por:</h3>

            <label for="exampleFormControlInput1" class="form-label">Provincia</label>
            <select class="form-select contact-input" aria-label="Default select example">
              <option selected></option>
              <option value="3">1</option>
              <option value="2">2</option>
              <option value="1">3</option>
            </select>

            <label for="exampleFormControlInput1" class="form-label">Cantón</label>
            <select class="form-select contact-input" aria-label="Default select example">
              <option selected></option>
              <option value="3">1</option>
              <option value="2">2</option>
              <option value="1">3</option>
            </select>


            <p class="py-2">Disponibilidad sujeta al momento de la compra</p>
          </div>

          <!-- Resultados -->
          <div class="col-12 col-lg-6">

            <div class="results-container pr-2">

              <div class="card mb-2">
                <div class="card-body p-2">
                  <h4 class="mb-1">Nombre de la farmacia</h4>
                  <p class="mb-0">Direccion</p>
                  <p class="mb-0">Unidades: 10</p>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-body p-2">
                  <h4 class="mb-1">Nombre de la farmacia</h4>
                  <p class="mb-0">Direccion</p>
                  <p class="mb-0">Unidades: 10</p>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-body p-2">
                  <h4 class="mb-1">Nombre de la farmacia</h4>
                  <p class="mb-0">Direccion</p>
                  <p class="mb-0">Unidades: 10</p>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-body p-2">
                  <h4 class="mb-1">Nombre de la farmacia</h4>
                  <p class="mb-0">Direccion</p>
                  <p class="mb-0">Unidades: 10</p>
                </div>
              </div>
            </div>
          </div>


          <!-- Sin resultados -->
          <!-- <div class="col-12 col-lg-6 d-flex flex-colum align-items-center">
                        <div class="pr-2">
                            <div class="">
                                <div class="">
                                    <div class="text-center">

                                        <i class="bi bi-shop-window icon-disponibilidad"></i>

                                        <p class="pt-2 mb-0">En este momento no tenemos disponibilidad de este
                                            producto.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <!-- <p class="text-footer-modal">Codigo de error</p> -->

      </div>
    </div>
  </div>
</div>
