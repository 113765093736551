<main class="main">
  <div class="container container-input mt-3 mt-lg-5">

    <h1 class="color-primary">Contactanos</h1>

    <form [formGroup]="form" (ngSubmit)="onSend()" class="row">

      <!-- NUMERO DE IDENTIFICACION -->
      <label for="exampleFormControlInput1" class="form-label">Número de identificación</label>
      <div class="d-flex align-items-center">
        <input type="text" class="form-control contact-identification" formControlName="identification"
          #identificationInput (keypress)="keyPressIdentification($event)" [minlength]="this.selectedType.minLength"
          [maxlength]="this.selectedType.maxLength" [placeholder]="this.selectedType.placeholder"
          [ngClass]="identification && identification.invalid && (identification.touched || this.isSubmited )? 'is-invalid':''"
          (blur)="searchId()">
        <select (change)="IdTypeChanged($event)" class="form-select input-select-contact"
          formControlName="typeidentification">
          <option *ngFor=" let type of this.typesList" [value]="type.id">{{ type.displayName }}</option>
        </select>
      </div>
      <div *ngIf="identification && identification!.invalid && ( identification!.touched || this.isSubmited )"
        class="d-block text-error">
        <span *ngIf="identification.errors?.['required']">
          {{ validationMsg['required'] }}
        </span>
        <span *ngIf="identification.errors?.['minlength']">
          {{ validationMsg['minlength'].replace("XXX",identification.errors!.minlength.requiredLength )}}
        </span>
        <span *ngIf="identification.errors?.['maxlength']">
          {{ validationMsg['maxlength'].replace("XXX",identification.errors!.maxlength.requiredLength )}}
        </span>
        <span *ngIf="identification.errors?.['pattern']">
          {{ validationMsg[this.selectedType.idPatternName+'pattern'] }}
        </span>
        <span *ngIf="identification.errors?.['isStartIdentification']">
          La identificación no debe iniciar con el número 0.
        </span>
      </div>

      <!-- NOMBRE -->
      <div class="col-12 col-lg-6 my-2">
        <label for="nameInput" class="form-label">Nombre</label>
        <input id="nameInput" type="text" class="form-control contact-input" formControlName="name"
          [ngClass]="name && name.invalid && (name.touched || this.isSubmited )? 'is-invalid':''" minlength="2"
          maxlength="50">
        <div *ngIf="name && name.invalid && (name.touched || this.isSubmited )" class="d-block text-error">
          <span *ngIf="name.errors?.['required']">
            {{ validationMsg['required'] }}
          </span>
          <span *ngIf="name.errors?.['minlength']">
            {{ validationMsg['minlength'].replace("XXX", name.errors!.minlength.requiredLength )}}
          </span>
          <span *ngIf="name.errors?.['maxlength']">
            {{ validationMsg['maxlength'].replace("XXX", name.errors!.maxlength.requiredLength )}}
          </span>
          <span *ngIf="name.errors?.['pattern']">
            {{ validationMsg['lettersSpanishpattern'] }}
          </span>
        </div>
      </div>

      <!-- PRIMER APELLIDO -->
      <div class="col-12 col-lg-6 my-2">
        <label for="firstLastNameInput" class="form-label">Primer apellido</label>
        <input type="text" class="form-control contact-input" id="firstLastNameInput" formControlName="firstLastName"
          [ngClass]="firstLastName && firstLastName.invalid && (firstLastName.touched || this.isSubmited )? 'is-invalid':''"
          minlength="2" maxlength="50">
        <div *ngIf="firstLastName && firstLastName.invalid && (firstLastName.touched || this.isSubmited )" class="d-block text-error">
          <span *ngIf="firstLastName.errors?.['required']">
            {{ validationMsg['required'] }}
          </span>
          <span *ngIf="firstLastName.errors?.['minlength']">
            {{ validationMsg['minlength'].replace("XXX",
                firstLastName!.errors!.minlength.requiredLength) }}
          </span>
          <span *ngIf="firstLastName.errors?.['maxlength']">
            {{ validationMsg['maxlength'].replace("XXX",
                firstLastName!.errors!.maxlength.requiredLength) }}
          </span>
          <span *ngIf="firstLastName.errors?.['pattern']">
            {{ validationMsg['lettersSpanishpattern'] }}
          </span>
        </div>
      </div>

      <!-- SEGUNDO APELLIDO -->
      <div class="col-12 col-lg-6 my-2">
        <label for="secondLastNameInput" class="form-label">Segundo apellido</label>
        <input type="text" class="form-control contact-input" id="secondLastNameInput" formControlName="secondLastName"
          [ngClass]="secondLastName && secondLastName.invalid && (secondLastName.touched || this.isSubmited )? 'is-invalid':''"
          minlength="2" maxlength="50">

        <div *ngIf="secondLastName && secondLastName.invalid && (secondLastName.touched || this.isSubmited )"
          class="d-block text-error">
          <span *ngIf="secondLastName.errors?.['required']">
            {{ validationMsg['required'] }}
          </span>
          <span *ngIf="secondLastName.errors?.['minlength']">
            {{ validationMsg['minlength'].replace("XXX",
                secondLastName.errors!.minlength.requiredLength )}}
          </span>
          <span *ngIf="secondLastName.errors?.['maxlength']">
            {{ validationMsg['maxlength'].replace("XXX",
                secondLastName.errors!.maxlength.requiredLength )}}
          </span>
          <span *ngIf="secondLastName.errors?.['pattern']">
            {{ validationMsg['lettersSpanishpattern'] }}
          </span>
        </div>
      </div>

      <!-- TELEFONO -->
      <div class="col-12 col-lg-6 my-1">
        <label for="phoneInput" class="form-label">Teléfono</label>
        <input type="text" class="form-control contact-input" id="phoneInput" formControlName="phone"
          [ngClass]="phone && phone.invalid && (phone.touched || this.isSubmited )? 'is-invalid':''"
          (keypress)="keyPressPhone($event)" minlength="8" maxlength="8">
        <div *ngIf="phone && phone.invalid && (phone.touched || this.isSubmited)" class="d-block text-error">
          <span *ngIf="phone.errors?.['required']">
            {{ validationMsg['required'] }}
          </span>
          <span *ngIf="phone.errors?.['minlength']">
            Revisá que tenga {{ this.phoneMaxLength }} dígitos y sin espacios.
          </span>
          <span *ngIf="phone.errors?.['pattern']">
            {{ validationMsg['numberpattern']}}
          </span>
        </div>
      </div>

      <!-- EMAIL -->
      <div class="col-12 col-lg-6 my-1">
        <label for="emailInput" class="form-label">Correo electrónico</label>
        <input type="text" class="form-control contact-input" id="emailInput" formControlName="email"
          [ngClass]="email && email.invalid && (email.touched || this.isSubmited )? 'is-invalid':''">
        <div *ngIf="email && email.invalid && (email.touched || this.isSubmited )" class="text-danger">
          <span *ngIf="email.errors?.['required']">
            {{ validationMsg['required'] }}
          </span>
          <span *ngIf="email.errors?.['minlength']">
            {{ validationMsg['minlength'].replace("XXX", email.errors!.minlength.requiredLength )}}
          </span>
          <span *ngIf="email.errors?.['maxlength']">
            {{ validationMsg['maxlength'].replace("XXX", email.errors!.maxlength.requiredLength )}}
          </span>
          <span *ngIf="email.errors?.['pattern']">
            {{ validationMsg['emailpattern']}}
          </span>
        </div>
      </div>

      <!-- ASUNTO -->
      <div class="col-12 col-lg-6 my-1">
        <label for="subjectInput" class="form-label">Asunto</label>
        <select class="form-select contact-input" id="subjectInput" aria-label="Default select example"
          formControlName="subject"
          [ngClass]="subject && subject.invalid && (subject.touched || this.isSubmited)? 'is-invalid':''">
          <option *ngFor="let item of this.subjectList;" [value]="item.id">{{ item.subject }}</option>
        </select>
        <div *ngIf="subject && subject.invalid && (subject.touched || this.isSubmited)" class="d-block text-error">
          <span *ngIf="subject.errors?.['required']">
            {{ validationMsg['required'] }}
          </span>
        </div>
      </div>

      <!-- MENSAJE -->
      <div class="col-12 my-1">
        <label for="messageTextarea" class="form-label">Mensaje</label>
        <textarea class="form-control contact-input" id="messageTextarea" rows="3" formControlName="message"
          [ngClass]="message && message.invalid && (message.touched || this.isSubmited )? 'is-invalid':''"></textarea>

        <div *ngIf="message && message.invalid && ( message.touched || this.isSubmited )" class="d-block text-error">
          <span *ngIf="message.errors?.['required']">
            {{ validationMsg['required'] }}
          </span>
          <span *ngIf="message.errors?.['minlength']">
            {{ validationMsg['minlength'].replace("XXX", message.errors!.minlength.requiredLength )}}
          </span>
          <span *ngIf="message.errors?.['maxlength']">
            {{ validationMsg['maxlength'].replace("XXX", message.errors!.maxlength.requiredLength )}}
          </span>
        </div>
      </div>

      <!-- ARCHIVO -->
      <div class="col-12 my-2">
        <label for="formFile" class="form-label">Adjuntar documento PDF o imagen (opcional)</label>
        <input class="form-control contact-input" lang="es" type="file" id="formFile" formControlName="file"
          (change)="onFileChange($event)" multiple accept=".jpg,.jpeg,.png,.pdf">
      </div>

      <div class="col-12 my-2" *ngFor="let item of this.filesToUpload; let i=index">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <img loading="lazy" [src]="urlBase +'/assets/img/img-contact.jpg'" alt="Imagen confirmación de carga de archivo"
              class="img-contact">
          </div>
          <div class="col">
            <span>{{ item.name }}</span>
            <div class="progress" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
              style="height: 10px">
              <div class="progress-bar progress-success" style="width: 100%"></div>
            </div>
          </div>
          <div class="col-1 text-end">
            <a (click)="deleteFile(i)"> <i class="bi bi bi-trash delete-product"></i></a>
          </div>
        </div>
      </div>

      <!-- SOY MAYOR DE EDAD -->
      <div class="col-12 my-1">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="ofLegalAgeCheck" formControlName="ofLegalAge">
          <label class="form-check-label" for="ofLegalAgeCheck">
            Soy mayor de edad
          </label>
        </div>
      </div>

      <div class="mt-1">
        <button class="btn btn-primary" type="submit" [disabled]="!form.valid || !form.get('ofLegalAge')?.value || !form.get('identification')?.value ">Enviar</button>
      </div>
    </form>
  </div>
</main>

<!-- Modal de consentimiento informado -->
<app-modal-consentimiento [showModal]="this.showCI" (showModalChange)="acceptInformedConsent($event)"
  [document]="this.document">
</app-modal-consentimiento>

<!-- Modal de mensaje enviado -->
<app-modal-succes [showModal]="this.showOkModal" (showModalChange)="this.showOkModal = $event" [titulo]="titulo"
  [mensaje]="mensaje"></app-modal-succes>
