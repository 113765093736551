import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { BannersService } from 'src/app/services/sitefinity/banners.service';
import { AdvertisingService } from 'src/app/services/sitefinity/advertising.service';
import { ProductsService } from 'src/app/services/core/products.service';
import { Router } from '@angular/router';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { Observable, forkJoin } from 'rxjs';
import { UtilitiesService } from 'src/app/services/core/utilities.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

export class IndexComponent implements OnInit {

  urlBase= environment.urlBase;
  SitefinityServicesUrl= environment.SitefinityServicesUrl;
  showCategories=false;
  _sharedService: SharedService
  banners: any;
  image: any;
  featuredProducts: any = [];
  advertising: any = [];
  selectedUnits: { [key: number]: string } = {};
  selectedQuantity: { [key: number]: number } = {};
  selectedUnitPrice: number[] = [];
  selectedPromotion: string[] = [];
  settings: SitefinitySettings = new SitefinitySettings();
  OriginalFeaturesList: any = [];
  parameterFeaturesProducts : any = [];
  initValueToShow: any = 4;
  finalValueToShow: any = 0;
  quantityValue: number = 1;
  unitSelected: string = 'Unidad'
  displayQuantityComponent: boolean = false;
  quantityByComponent:any = 10
  showConocerMasButton: boolean = true;
  timeBanners: number = 2000;

  constructor(
    public loader: LoadingService,
    private sharedService: SharedService,
    private bannerService: BannersService,
    private advertisingService: AdvertisingService,
    private productService: ProductsService,
    public sitefinityPagesService : SitefinitypagesService,
    private router: Router,
    private utilities: UtilitiesService,

  ){
    this._sharedService = sharedService
  }

  ngOnInit(): void {
    this.loader.showSpinner();
    this.getPrincipalBanner();
    this.getTimeBanners();
    this.sitefinityPagesService.setDetailPage(
      this.settings.pages.find((x) => x.Title === 'index')!.ID
    );

    const observables: Observable<any>[] = [
      this.advertisingService.getAdvertisingServices(),
      this.productService.getfeaturedProductsMethod(),
      this.utilities.GetParametersByKey('ProductosDestacadosSettings')

    ];

    forkJoin(observables).subscribe(([resultServices1, resultServices2, resultServices3]) => {
        this.parameterFeaturesProducts =  JSON.parse(this.utilities.decodeData(resultServices3.value[0].value));
          const Odata = resultServices1.value;
          let Array: any[] = [];

          Odata.forEach((DataItem: any) => {
            let responseImage = this.advertisingService.getAdvertisingImage(DataItem.Id,"Image")
            responseImage.subscribe(o=>{
              let Image: any[] = [];
              DataItem.Image = o.value;
              Array.push(DataItem);
            })
          });


        this.advertising = Array;
        if (resultServices2.response.code === "OK0001") {
          this.initValueToShow = this.parameterFeaturesProducts.take;
          this.OriginalFeaturesList = resultServices2.products;
          this.featuredProducts = resultServices2.products.slice(this.parameterFeaturesProducts.skip, this.parameterFeaturesProducts.take);
          if(this.OriginalFeaturesList.length > this.initValueToShow)
          {
            this.showConocerMasButton = true;
          }
          else
          {
            this.showConocerMasButton = false;
          }

          this.featuredProducts.forEach((p: any, index: number) => {

            p.displayQuantityComponent = false;
            p.quantityByComponent = 10;
            p.numbers = [];

            if(p.MaxToPurchase > 10)
            {
              for (let i = p.MinToPurchase; i <= 9; i++) {
                p.numbers.push(i);
              }
            }
            else
            {
              for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
                p.numbers.push(i);

            }

          }
          this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
          this.selectedQuantity[index] = p.MinToPurchase;
          if (p.ProductAttributes && p.ProductAttributes.length > 0) {
            if (this.selectedUnits[index] === 'Unidad') {
              this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[0].PriceWithTaxes;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
            }
            else if (this.selectedUnits[index] === 'Caja') {
              if (p.ProductAttributes[0].PriceWithDiscount > 0 || p.ProductAttributes[1]?.PriceWithDiscount > 0) {
                this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount: p.ProductAttributes[1]?.PriceWithDiscount;
                this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
              }
              else {
                this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithTaxes ? p.ProductAttributes[0].PriceWithTaxes: p.ProductAttributes[1]?.PriceWithTaxes;
                this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
              }
            }
          }
          else {
            this.selectedUnitPrice[index] = p.PriceWithDiscount > 0 ? p.PriceWithDiscount : p.PriceWithTaxes;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
          }

          if(p.MinToPurchase > 1)
          {
            if(this.selectedUnits[index] === 'Caja')
            {
              this.selectedUnits[index] = 'Cajas';
            }
            else
            {

              this.selectedUnits[index] = 'Unidades';

            }

          }

          if(p.PercentDiscountRate > 0){
            if(p.PriceWithDiscount > 0){
              this.selectedPromotion[index] = p.PercentDiscountRate + "%"
            }
            else{
              if(p.ProductAttributes.length>0){
                let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
                if (this.selectedUnits[index].startsWith('Unidad')) {
                  if(currentAttribute.PriceWithDiscount > 0){
                    this.selectedPromotion[index] = p.PercentDiscountRate + "%"
                  }
                  else{
                    if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                      this.selectedPromotion[index] = currentAttribute.Dynamic;
                    }
                    else{
                      this.selectedPromotion[index] = "";
                    }
                  }
                }
                else{
                  if (this.selectedUnits[index].startsWith('Caja')) {
                    if(currentAttribute.PriceWithDiscount > 0){
                      this.selectedPromotion[index] = p.PercentDiscountRate + "%"
                    }
                    else{
                      if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                        this.selectedPromotion[index] = currentAttribute.Dynamic;
                      }
                      else{
                        this.selectedPromotion[index] = "";
                      }
                    }
                  }
                  else{
                    this.selectedPromotion[index] = "";
                  }
                }
              }
              else{
                if(p.Dynamic && p.Dynamic != ""){
                  this.selectedPromotion[index] = p.Dynamic;
                }
                else{
                  this.selectedPromotion[index] = "";
                }
              }
            }
          }
          else{
            if(p.ProductAttributes.length>0){
              let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
              if(currentAttribute.Dynamic || currentAttribute.Dynamic != ""){
                this.selectedPromotion[index] = currentAttribute.Dynamic;
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
            else{
              if(p.Dynamic || p.Dynamic != ""){
                this.selectedPromotion[index] = p.Dynamic;
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
          }


          });
        }
        else {
          this.featuredProducts = [];
        }
        this.loader.hideSpinner();
    });
  }

  getTimeBanners() {
    this.utilities.GetParametersByKey('timeBanners').subscribe(res => {
      if (res.value.length > 0) {
        let time = res.value[0].value;
        let totalTime = parseInt(time);
        this.timeBanners = totalTime;
      }
    });
  }

  getPrincipalBanner():void{

    this.bannerService.getPrincipalBannersMethod().subscribe(res => {
      if (res.response.code === "banner-success") {
        this.banners = res.Banners;

       // this.banners.sort((a:any, b:any) => a.priority - b.priority);
      }
      else {
        this.banners = [];
      }
    });

  }
  getBanners(): void {

    this.advertisingService.getAdvertisingServices().subscribe(a => {

      const Odata = a.value;
      let Array: any[] = [];

      Odata.forEach((DataItem: any) => {

        let responseImage = this.advertisingService.getAdvertisingImage(DataItem.Id,"Image")
        responseImage.subscribe(o=>{
          let Image: any[] = [];
          DataItem.Image = o.value;
          Array.push(DataItem);
        });
      });

      this.advertising = Array;
    });
  }

  getMoreFeaturedProducts(): void {

    let newProducts: any [];
    newProducts = this.OriginalFeaturesList.slice(this.parameterFeaturesProducts.skip, this.OriginalFeaturesList.length);
    this.initValueToShow = this.OriginalFeaturesList.length;
    this.finalValueToShow = this.OriginalFeaturesList.length;

    if(this.OriginalFeaturesList.length > this.finalValueToShow)
    {
      this.showConocerMasButton = true;
    }
    else
    {
      this.showConocerMasButton = false;
    }

    // this.featuredProducts = this.OriginalFeaturesList.slice(this.parameterFeaturesProducts.skip, this.parameterFeaturesProducts.take);
    this.featuredProducts = this.featuredProducts.concat(newProducts);
    this.featuredProducts.forEach((p: any, index: number) => {
     // p.displayQuantityComponent = false;
      // p.MaxToPurchase  = 11;
      // p.numbers = [1,2,3,4,5,6,7,8,9]
      // p.quantityByComponent = 10;


      p.displayQuantityComponent = false;
      // p.MaxToPurchase  = 50;
      // p.numbers = [1,2,3,4,5,6,7,8,9]
      p.quantityByComponent = 10;


      p.numbers = [];

      if(p.MaxToPurchase > 10)
      {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else
      {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);

      }

    }







      this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
      this.selectedQuantity[index] = p.MinToPurchase;
      if (p.ProductAttributes && p.ProductAttributes.length > 0) {
        if (this.selectedUnits[index] === 'Unidad') {
          this.selectedUnitPrice[index] = p.ProductAttributes[0]
            .PriceWithDiscount
            ? p.ProductAttributes[0].PriceWithDiscount
            : p.ProductAttributes[0].PriceWithTaxes;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
        } else if (this.selectedUnits[index] === 'Caja') {
          if (
            p.ProductAttributes[0].PriceWithDiscount > 0 ||
            p.ProductAttributes[1]?.PriceWithDiscount > 0
          ) {
            this.selectedUnitPrice[index] = p.ProductAttributes[0]
              .PriceWithDiscount
              ? p.ProductAttributes[0].PriceWithDiscount
              : p.ProductAttributes[1]?.PriceWithDiscount;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
          } else {
            this.selectedUnitPrice[index] = p.ProductAttributes[0]
              .PriceWithTaxes
              ? p.ProductAttributes[0].PriceWithTaxes
              : p.ProductAttributes[1]?.PriceWithTaxes;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
          }
        }
      } else {
        this.selectedUnitPrice[index] = p.PriceWithTaxes; // Establece un valor predeterminado
        this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
      }

      if(p.PercentDiscountRate > 0){
        if(p.PriceWithDiscount > 0){
          this.selectedPromotion[index] = p.PercentDiscountRate + "%"
        }
        else{
          if(p.ProductAttributes.length>0){
            let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
            if (this.selectedUnits[index].startsWith('Unidad')) {
              if(currentAttribute.PriceWithDiscount > 0){
                this.selectedPromotion[index] = p.PercentDiscountRate + "%"
              }
              else{
                if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else{
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else{
              if (this.selectedUnits[index].startsWith('Caja')) {
                if(currentAttribute.PriceWithDiscount > 0){
                  this.selectedPromotion[index] = p.PercentDiscountRate + "%"
                }
                else{
                  if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                    this.selectedPromotion[index] = currentAttribute.Dynamic;
                  }
                  else{
                    this.selectedPromotion[index] = "";
                  }
                }
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
          }
          else{
            if(p.Dynamic && p.Dynamic != ""){
              this.selectedPromotion[index] = p.Dynamic;
            }
            else{
              this.selectedPromotion[index] = "";
            }
          }
        }
      }
      else{
        if(p.ProductAttributes.length>0){
          let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if(currentAttribute.Dynamic || currentAttribute.Dynamic != ""){
            this.selectedPromotion[index] = currentAttribute.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
        else{
          if(p.Dynamic || p.Dynamic != ""){
            this.selectedPromotion[index] = p.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
      }

    });


  }

  selectUnit(index: number, unit: string): void {
    this.selectedUnits[index] = unit;
    this.unitSelected = unit;
    this.selectType(index,this.selectedUnits[index]);
    // Llama a la función para recalcular el precio cuando se cambia la unidad
    // this.calculateTotalPrice(index);
  }

  selectQuantity(index: number, quantity: number): void {
    this.selectedQuantity[index] = quantity;
    this.quantityValue = quantity;
    this.selectType(index,this.selectedUnits[index]);
    // Calcula el precio total aquí (dependiendo de la cantidad y la unidad seleccionada)
    // this.calculateTotalPrice(index);
  }

  calculateTotalPrice(index: number): void {
    const product = this.featuredProducts[index];
    const quantity = this.selectedQuantity[index];

    if (product.ProductAttributes.length > 0 && (this.selectedUnits[index] === 'Unidad' || this.selectedUnits[index] === 'Unidades')) {
      this.selectedUnitPrice[index] = product.ProductAttributes[0].PriceWithDiscount ? product.ProductAttributes[0].PriceWithDiscount * quantity : product.ProductAttributes[0].PriceWithTaxes * quantity;
    }
    else if (this.selectedUnits[index] === 'Caja' || this.selectedUnits[index] === 'Cajas' && product.ProductAttributes) {
      if (product.ProductAttributes.length > 1)
        this.selectedUnitPrice[index] = product.ProductAttributes[1]?.PriceWithDiscount ? product.ProductAttributes[1]?.PriceWithDiscount * quantity : product.ProductAttributes[1]?.PriceWithTaxes * quantity;
      else
        this.selectedUnitPrice[index] = product.ProductAttributes[0]?.PriceWithDiscount ? product.ProductAttributes[0]?.PriceWithDiscount * quantity : product.ProductAttributes[0]?.PriceWithTaxes * quantity;
    }

    else {
      this.selectedUnitPrice[index] = product.PriceWithDiscount ? product.PriceWithDiscount : product.PriceWithTaxes;
    }

    if (product.PercentDiscountRate > 0){
      if(product.PriceWithDiscount > 0){
        this.selectedPromotion[index] = product.PercentDiscountRate + "%"
      }
      else {
        if(product.ProductAttributes.length>0){
          let currentAttribute = product.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if (this.selectedUnits[index].startsWith('Unidad')) {
            if(currentAttribute.PriceWithDiscount > 0){
              this.selectedPromotion[index] = product.PercentDiscountRate + "%"
            }
            else{
              if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                this.selectedPromotion[index] = currentAttribute.Dynamic;
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
          }
          else{
            if (this.selectedUnits[index].startsWith('Caja')) {
              if(currentAttribute.PriceWithDiscount > 0){
                this.selectedPromotion[index] = product.PercentDiscountRate + "%"
              }
              else{
                if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else{
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else{
              this.selectedPromotion[index] = "";
            }
          }
        }
        else{
          if(product.Dynamic && product.Dynamic != ""){
            this.selectedPromotion[index] = product.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
      }
    }
    else{
      if(product.ProductAttributes.length>0){
        let currentAttribute = product.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
        if(currentAttribute.Dynamic || currentAttribute.Dynamic != ""){
          this.selectedPromotion[index] = currentAttribute.Dynamic;
        }
        else{
          this.selectedPromotion[index] = "";
        }
      }
      else{
        if(product.Dynamic || product.Dynamic != ""){
          this.selectedPromotion[index] = product.Dynamic;
        }
        else{
          this.selectedPromotion[index] = "";
        }
      }
    }
  }

  validateOption(p: any, index: number): boolean {
    return !(p.ProductAttributes && p.ProductAttributes.length === 1 && this.selectedUnits[index] === 'Unidad');
  }

  goToDetailProduct(url: string) {
    if( url != null) {
      this.router.navigate(['/detalle-producto', url]);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  addProductToCart(product: any, unitSelected: any, quantity:number, price: number) {
    if (product) {
      this.loader.showSpinner();
      this.sharedService.addProductsToCartV2(product, unitSelected, quantity, price);
      this.resetFeatureProduct();
      this.quantityByComponent = 10;
    }
    setTimeout(() => {
      this.loader.hideSpinner();
    }, 3000);
  }

  resetFeatureProduct() {
    this.featuredProducts.forEach((p: any, index: number) => {
      p.displayQuantityComponent = false;
      p.quantityByComponent = 10;
      p.numbers = [];

      if (p.MaxToPurchase > 10) {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);
        }
      }
      this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
      this.selectedQuantity[index] = p.MinToPurchase;

      if (p.ProductAttributes && p.ProductAttributes.length > 0) {
        if (this.selectedUnits[index] === 'Unidad') {
          this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[0].PriceWithTaxes;
          this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
        }
        else if (this.selectedUnits[index] === 'Caja') {
          if (p.ProductAttributes[0].PriceWithDiscount > 0 || p.ProductAttributes[1]?.PriceWithDiscount > 0) {
            this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount: p.ProductAttributes[1]?.PriceWithDiscount;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
          }
          else {
            this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithTaxes ? p.ProductAttributes[0].PriceWithTaxes: p.ProductAttributes[1]?.PriceWithTaxes;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
          }
        }
      }
      else {
        this.selectedUnitPrice[index] = p.PriceWithDiscount > 0 ? p.PriceWithDiscount : p.PriceWithTaxes;
        this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
      }

      if(p.MinToPurchase > 1)
      {
        if(this.selectedUnits[index] === 'Caja') {
          this.selectedUnits[index] = 'Cajas';
        }
        else {
          this.selectedUnits[index] = 'Unidades';
        }
      }
      if (p.PercentDiscountRate > 0) {
        if (p.PriceWithDiscount > 0) {
          this.selectedPromotion[index] = p.PercentDiscountRate + "%"
        }
        else {
          if (p.ProductAttributes.length > 0) {
            let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
            if (this.selectedUnits[index].startsWith('Unidad')) {
              if(currentAttribute.PriceWithDiscount > 0){
                this.selectedPromotion[index] = p.PercentDiscountRate + "%"
              }
              else {
                if (currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else {
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else{
              if (this.selectedUnits[index].startsWith('Caja')) {
                if (currentAttribute.PriceWithDiscount > 0) {
                  this.selectedPromotion[index] = p.PercentDiscountRate + "%"
                }
                else {
                  if(currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                    this.selectedPromotion[index] = currentAttribute.Dynamic;
                  }
                  else{
                    this.selectedPromotion[index] = "";
                  }
                }
              }
              else {
                this.selectedPromotion[index] = "";
              }
            }
          }
          else {
            if(p.Dynamic && p.Dynamic != "") {
              this.selectedPromotion[index] = p.Dynamic;
            }
            else {
              this.selectedPromotion[index] = "";
            }
          }
        }
      }
      else {
        if (p.ProductAttributes.length > 0) {
          let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if (currentAttribute.Dynamic || currentAttribute.Dynamic != "") {
            this.selectedPromotion[index] = currentAttribute.Dynamic;
          }
          else {
            this.selectedPromotion[index] = "";
          }
        }
        else {
          if (p.Dynamic || p.Dynamic != "") {
            this.selectedPromotion[index] = p.Dynamic;
          }
          else {
            this.selectedPromotion[index] = "";
          }
        }
      }
    });
  }

  LoadMore() {
    this.parameterFeaturesProducts.skip  += this.parameterFeaturesProducts.take;
    this.getMoreFeaturedProducts();
  }

  getPluralizedName(itemName: string, quantity: number): string {
    if (itemName.toLowerCase() === 'unidad') {
      return quantity > 1 ? 'Unidades' : 'Unidad';
    } else if (itemName.toLowerCase() === 'caja') {
      return quantity > 1 ? 'Cajas' : 'Caja';
    } else {
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return itemName;
    }
  }

  selectType(index:any,itemName: string ): string {

    if (itemName.toLowerCase() === 'unidad' ||  itemName.toLowerCase() === 'unidades' ) {

      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Unidades' : 'Unidad';;
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index]
    } else if (itemName.toLowerCase() === 'caja' ||  itemName.toLowerCase() === 'cajas') {

      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Cajas' : 'Caja';
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index];
    } else {
      this.selectedUnits = itemName;
      this.calculateTotalPrice(index);
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return  this.selectedUnits[index]
    }
  }

  openQuantityComponent(index:any,value:any)  {
    if(value == false) {
      this.featuredProducts[index].quantityByComponent=  this.validateQuantityZero(this.featuredProducts[index].quantityByComponent, index)
      this.selectedQuantity[index] = this.featuredProducts[index].quantityByComponent;
      this.selectType(index,this.selectedUnits[index]);

    }

    this.featuredProducts[index].displayQuantityComponent = value;
  }

  validateQuantityZero(quantity: any, index: number) {
    if(quantity <= 0 ) {
      return this.featuredProducts[index].MinToPurchase;
    }
    if (quantity < this.featuredProducts[index].MinToPurchase) {
      return this.featuredProducts[index].MinToPurchase;
    }
    if (quantity > this.featuredProducts[index].MaxToPurchase) {
      return this.featuredProducts[index].MaxToPurchase;
    }
    return quantity;
  }
}
