<div class="modal fade modal-overlay-light" id="modalError" #modalError tabindex="-1" aria-labelledby="modalErrorLabel" aria-hidden="true"
  data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-style">
      <div class="modal-header pb-0 border-0">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center pt-0">
        <i class="bi bi-x-circle icon-modal"></i>
        <h1 class="modal-title fs-5" id="modalErrorLabel">{{ titulo }}</h1>
        <p>{{ mensaje }}</p>

      </div>
      <div class="modal-footer justify-content-between">
        <!-- <p class="text-footer-modal">Codigo de error</p> -->
        <!-- <p class="text-footer-modal">{{ codError }}</p> -->
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="accept()">Aceptar</button>
      </div>
    </div>
  </div>
</div>
