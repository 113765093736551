import{Response} from '../responses/response.model';
export class SitefinityPages {

  id:string = '';
  title:string = '';
  url: string = '';


}



export class ResponseSitefinityPages {

  sitefinityPage : SitefinityPages[] = [];
  response:Response = new Response;

}


