<main class="main">
  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="color-primary">Mi cuenta</h1>

    <div class="row">
      <div class="col-12 col-lg-8">
        <ul class="scroll-account nav nav-tabs mb-3">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#" id="pills-home-tab" data-bs-toggle="pill"
              data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
              Datos de perfil
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" id="pills-pass-tab" data-bs-toggle="pill" data-bs-target="#pills-pass"
              type="button" role="tab" aria-controls="pills-pass" aria-selected="false" (click)="getCreditCards()">Datos
              de facturación</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
              type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
              (click)="getFavoritesProducts()">Favoritos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
              type="button" role="tab" aria-controls="pills-contact" aria-selected="false"
              (click)="getPurchase()">Órdenes</a>
          </li>
        </ul>


        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"
            tabindex="0">
            <div *ngIf="client" class="row" [formGroup]="form">
              <div class="col-12 my-1">
                <label for="exampleFormControlInput1" class="form-label">Identificación</label>
                <input type="text" class="form-control login-input" value="{{client.Identification}}"
                  id="identification" disabled="disabled">
              </div>
              <div class="col-12 my-1">
                <label for="exampleFormControlInput1" class="form-label ">Nombre</label>
                <input type="text" class="form-control login-input" id="name" value="{{client.Name}}"
                  disabled="disabled">
              </div>
              <div class="col-12 my-1">
                <label for="exampleFormControlInput1" class="form-label ">Primer apellido</label>
                <input type="text" class="form-control login-input" id="lastName" value="{{client.LastName}}"
                  disabled="disabled">
              </div>
              <div class="col-12 my-1">
                <label for="exampleFormControlInput1" class="form-label ">Segundo apellido</label>
                <input type="text" class="form-control login-input" id="secondName" value="{{client.SecondSurname}}"
                  disabled="disabled">
              </div>
              <div class="col-12 my-1">
                <label for="exampleFormControlInput1" class="form-label ">Teléfono</label>
                <input type="text" class="form-control login-input" id="phone" formControlName="Phone"
                  value="{{client.Phones[0]?.Phone}}" maxlength="9">
                <div *ngIf="Phone!.invalid && ( Phone!.touched || this.isSubmited )" class="d-block text-error">
                  <span *ngIf="Phone!.errors?.required">{{validationMsg['required']}}</span>
                  <span *ngIf="Phone!.errors?.['pattern']">
                    {{formatPhone}}
                  </span>
                </div>
              </div>
              <div class="col-12 my-1">
                <label for="exampleFormControlInput1" class="form-label ">Correo electrónico</label>
                <input type="text" class="form-control login-input" id="email" value="{{client.Email}}"
                  disabled="disabled">
              </div>
              <div class="col-12 my-1">
                <label for="exampleFormControlInput1" class="form-label ">Contraseña</label>
                <div class="password-input">
                  <input type="password" class="form-control password-border" formControlName="Password" id="password"
                    placeholder="******" [disabled]="passwordDisabled"
                    [ngClass]="Password!.invalid && Password!.touched ? 'is-invalid' : ''" maxlength="31">
                  <i class="password-toggle " [ngClass]="passwordDisabled ? 'bi-lock' : 'bi-unlock'" id="togglePassword"
                    (click)="enabledPassword()"></i>
                </div>
                <div *ngIf="Password!.invalid && (Password!.dirty || Password!.touched || this.isSubmited)"
                  class="text-danger">
                  <span *ngIf="Password!.errors?.required">{{validationMsg['required']}}</span>
                  <span *ngIf="Password!.errors?.['minlength']">
                    {{ validationMsg['minlength'].replace("XXX", Password!.errors!.minlength.requiredLength )}}
                  </span>
                  <span *ngIf="Password!.errors?.['maxlength']">
                    {{ validationMsg['maxlength'].replace("XXX", Password!.errors!.maxlength.requiredLength )}}
                  </span>
                  <span *ngIf="Password!.errors?.['pattern']">
                    {{ validationMsg['passwordpattern']}}
                  </span>
                </div>
              </div>
              <div class="col-12 my-1">
                <div *ngIf="addresses.length > 0" class="d-flex align-items-center justify-content-between mb-1">
                  <label for="exampleFormControlInput1" class="form-label ">Dirección de entrega</label>
                </div>

                <div *ngFor="let a of addresses; let i = index">
                  <div class="card mb-2">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <h4 class="mb-0">{{a.Name}}</h4>
                        <a (click)="DeleteAddress(a.IDClientAddress)"><i class="bi bi bi-trash delete-product"></i></a>
                      </div>
                      <div class="d-flex align-items-center" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapseCard' + i" role="button" aria-expanded="false">
                        <small class="mb-0">Detalles</small>
                        <i class="bi bi-chevron-down mt-1 ps-1 icon-collapse"></i>
                      </div>
                      <div class="row collapse mx-2" id="collapseCard{{i}}">
                        <p class="">{{a.FullAddress}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <i class="bi bi-plus icon-geo"></i>
                  <a href="" type="button" data-bs-toggle="modal" data-bs-target="#directionModal"
                    (click)="showModalDirection()">
                    Agregar nueva dirección
                  </a>
                </div>
                <div class="col-12 my-3">
                  <button class="btn btn-primary px-4" (click)="updateInfoClient(form.value)">
                    Actualizar información
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-pass" role="tabpanel" aria-labelledby="pills-pass-tab" tabindex="0">
            <!-- Mostrar cuando no haya datos -->
            <div class="container container-input d-flex align-items-center justify-content-center my-5" *ngIf="notCreditCard">
              <div class="text-center">
                <i class="bi bi-credit-card icon-page"></i>
                <h2>Aún no hay datos de facturación</h2>
                <!-- Por el momento no se muestra ya que no se solicito esta implementación -->
                <!-- <div class="d-flex align-items-center justify-content-center">
                  <i class="bi bi-plus icon-geo"></i>
                  <button class="btn btn-text-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showModalCard()">
                    Agregar nueva tarjeta
                  </button>
                </div> -->
              </div>
            </div>

            <div class="row" *ngFor="let card of creditCard; let i = index">
              <div class="col-12">
                <div>
                  <div class="card mb-2">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <h3 class="">Tarjeta {{card.CardNumber.slice(-5)}}</h3>
                        <a (click)="deleteCreditCard(card.IDSavedCard)">
                          <i class="bi bi bi-trash delete-product"></i>
                        </a>
                      </div>
                      <h5 class="mb-0">{{card.Name}}</h5>
                    </div>
                  </div>
                  <!-- <div class="mb-3" hidden="false">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-plus icon-geo"></i>
                      <button class="btn btn-text-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showModalCard()">
                        Agregar nueva tarjeta
                      </button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
            tabindex="0">
            <!-- Mostrar cuando no haya datos -->
            <div class="container container-input d-flex align-items-center my-5" *ngIf="notProducts">
              <div class="text-center">
                <i class="bi bi-heart icon-page"></i>
                <h2 class="">Aún no tenés favoritos</h2>
                <p class="">
                  Guardá tus productos favoritos y encontralos rápido siempre.
                </p>
              </div>
            </div>
            <div class="card mb-2" *ngFor="let p of products; let i = index">
              <div class="row g-0">
                <div class="col-2 mb-0 d-flex align-items-center justify-content-center">
                  <div class="img-favorite-product">
                    <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
                      <img loading="lazy" alt="…" [src]=" p.ProductImages[0].WebPath"
                        (click)="goToDetailProduct(p.URL)">
                    </a>
                  </div>
                </div>
                <div class="col-10">
                  <div class="card-body">

                    <div class="row d-flex">
                      <div class="col-10">
                        <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
                          <h5 class="card-title">{{p.Name}}</h5>
                        </a>
                      </div>
                      <div class="col-2 text-center">
                        <a class="favorite-product" (click)="ShowDeleteConfirmation(p)">
                          <i class="bi bi-heart-fill favorite-product"></i>
                        </a>
                      </div>
                    </div>

                    <div class="d-flex align-items-end justify-content-star mb-2"
                      *ngIf="p.ProductAttributes && p.ProductAttributes.length > 0">
                      <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
                        <span class="product-price text-nowrap"
                          *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades')  && p.ProductAttributes[0].Name === 'Unidad'">
                          {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                        </span>
                        <span class="product-price text-nowrap"
                          *ngIf="(selectedUnits[i] === 'Caja'  || selectedUnits[i] === 'Cajas') && p.ProductAttributes">
                          {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                        </span>
                        <!-- Producto con descuento -->
                        <span class="product-discount ms-2 text-nowrap"
                          *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.ProductAttributes[0].PriceWithDiscount > 0">
                          {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                        </span>
                        <span class="product-discount ms-2 text-nowrap"
                          *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes.length > 1 && p.ProductAttributes[1].PriceWithDiscount > 0">
                          {{p.ProductAttributes[1].PriceWithTaxes * selectedQuantity[i]| mycurrency }} i.v.a.i
                        </span>
                        <span class="product-discount ms-2 text-nowrap"
                          *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes[0].Name === 'Caja' && p.ProductAttributes[0].PriceWithDiscount > 0">
                          {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                        </span>
                      </a>
                    </div>


                    <div class="d-flex align-items-end justify-content-star mb-2"
                      *ngIf="p.ProductAttributes && p.ProductAttributes.length == 0">
                      <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
                        <span class="product-price text-nowrap"
                          *ngIf="selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades' ">
                          {{ selectedUnitPrice[i] * selectedQuantity[i] | mycurrency }} i.v.a.i
                        </span>

                        <!-- Producto con descuento -->
                        <span class="product-discount ms-2 text-nowrap"
                          *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.PriceWithDiscount > 0">
                          {{p.PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                        </span>

                        <span class="product-price text-nowrap"
                          *ngIf="selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas' ">
                          {{ selectedUnitPrice[i] * selectedQuantity[i] | mycurrency }} i.v.a.i
                        </span>

                        <!-- Producto con descuento -->
                        <span class="product-discount ms-2 text-nowrap"
                          *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.PriceWithDiscount > 0">
                          {{p.PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                        </span>
                      </a>
                    </div>

                    <div *ngIf="!p.displayQuantityComponent && !p.NotForSale"
                      class="product-card-footer d-flex justify-content-start pb-0">
                      <div class="d-flex align-items-center me-2">
                        <div class="nav-item dropup">
                          <button class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ selectedQuantity[i] }}
                          </button>
                          <ul class="dropdown-menu">
                            <li *ngFor="let quantity of p.numbers">
                              <a class="dropdown-item" (click)="selectQuantity(i,quantity)"> {{quantity}} </a>
                            </li>

                            <hr *ngIf="this.p.MaxToPurchase > 9" class="my-0">
                            <li *ngIf="this.p.MaxToPurchase > 10" (click)="openQuantityComponent(i,true)"><a
                                class="dropdown-item">10+ </a></li>

                          </ul>
                        </div>
                      </div>

                      <div class="d-flex align-items-center me-1">
                        <div class="nav-item dropup">

                          <button *ngIf="p.ProductAttributes.length > 1"
                            class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false"
                            [ngClass]="{'btn-outline-secondary': true, 'dropdown-toggle': true, 'dropdown-product': true}">
                            {{ selectedUnits[i] }}
                          </button>

                          <button *ngIf="p.ProductAttributes.length == 1 || p.ProductAttributes.length == 0 "
                            class="btn btn-outline-secondary   dropdown-product" type="button" aria-expanded="false">
                            {{ selectedUnits[i] }}
                          </button>




                          <ul class="dropdown-menu option-dropdown">
                            <li *ngFor="let item of p.ProductAttributes">
                              <a class="dropdown-item" (click)="selectType(i,item.Name)">{{ getPluralizedName(item.Name,
                                selectedQuantity[i]) }}</a>

                            </li>
                          </ul>
                        </div>
                      </div>

                      <button aria-label="Agregar al carrito" type="button" class="btn btn-primary product-icon me-2"
                        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Agregar al carrito"
                        (click)="addProductToCart(p, selectedUnits[i], selectedQuantity[i], selectedUnitPrice[i])">
                        <i class="bi bi-cart-plus-fill"></i>
                      </button>

                    </div>


                    <div *ngIf="p.displayQuantityComponent" class="d-flex align-items-center"
                      id="quantity-custom-amount">


                      <input [(ngModel)]="p.quantityByComponent" type="number" class="form-control input-quantity"
                        id="quantity-product-input" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                        aria-describedby="emailHelp" [max]="p?.MaxToPurchase" [min]="1">

                      <button (click)="openQuantityComponent(i,false)" type="submit"
                        class="btn btn-outline-secondary ms-1"><i class="bi bi-plus-lg"></i></button>


                    </div>

                    <div class="pt-2">
                      <div class="" *ngIf="!reminder[i]">
                        <small>
                          <a class="text-decoration-none cursor-pointer" (click)="ShowModalRemember(p)">
                            <i class="bi bi-plus-lg pe-1"></i>
                            Crear recordatorio
                          </a>
                        </small>
                      </div>
                      <div class="" *ngIf="reminder[i]">
                        <small>
                          <a class="text-decoration-none cursor-pointer" (click)="ShowModalRemember(p)">
                            <i class="bi bi-pencil pe-1"></i>
                            Editar recordatorio
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>

          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"
            tabindex="0">

            <!-- Mostrar cuando no haya datos -->
            <div class="container container-input d-flex align-items-center justify-content-center my-5" *ngIf="notPurchase">
              <div class="text-center">
                <i class="bi bi-truck icon-page"></i>
                <h2 class="">Aún no hay órdenes creadas</h2>
              </div>
            </div>

            <div *ngFor="let order of purchase; let i = index">
              <div>
                <p class="mb-0 fw-medium">Fecha de compra: <span class="fs-6">{{order.Date}}</span></p>
                <p class="mb-0">Sucursal: <span class="fs-6">{{order.ShopName}}</span></p>
                <p class="mb-0 text-price-order">Precio total:


                  <span class="fs-6"> {{ (order.CartObject.PurchaseInfo.TotalWithDiscount > 0 ?
                    order.CartObject.PurchaseInfo.TotalWithDiscount : order.CartObject.PurchaseInfo.Total) | mycurrency
                    }} i.v.a.i</span>


                </p>
                <a *ngIf="order.urlOrden != ''" href="javascript:void(0)"
                  (click)="openSeguimientoOrdenModal(order.urlOrden)">Seguir mi orden</a>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--color-->
      <div class="col-12 col-lg-4">
        <div class="card border-0 overflow-hidden">
          <div class="account-points px-3">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center pb-4">
                <h4>Mis puntos</h4>
                <img [src]="urlBase + '/assets/img/logo-points.png'" alt="" class="hearth-account">
              </div>

              <div class="d-flex align-items-end">
                <img [src]="urlBase + '/assets/img/hearth-points.png'" alt="" class="heart-point">
                <h3 class="mb-0">{{userFidelity?.AvailablePointsFidelity}}</h3>
              </div>

              <div class="point-detail mb-4">
                <p class="mb-0 fw-medium">Disponibles</p>
              </div>

              <!-- <div class="d-flex align-items-end">
                <img [src]="urlBase + '/assets/img/hearth-points.png'" alt="" class="heart-point">
                <h3 class="mb-0">{{userFidelity?.UsedPointsFidelity}}</h3>
              </div>
              <div class="point-detail mb-4">
                <p class="mb-0 fw-medium">Canjeados</p>
              </div>

              <div class="d-flex align-items-end">
                <img [src]="urlBase + '/assets/img/hearth-points.png'" alt="" class="heart-point">
                <h3 class="mb-0">{{userFidelity?.ExpiredPointsFidelity}}</h3>
              </div>

              <div class="point-detail mb-4">
                <p class="mb-0 fw-medium">Vencidos</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <app-modal-card [showModal]="showCard"></app-modal-card>

      <app-modal-remember [(showModalRemember)]="showModalRemember" (callHideModalRemember)="hideModalRemember()"
        (callRefreshReminders)="RefreshReminders()" [modalData]="modalData" [selectedReminder]="this.selectedReminder"
        [clientID]="this.clientID" [articleSku]="this.selectedSku" [addToFavourite]="false">
      </app-modal-remember>

      <app-modal-direction [showModalDirection]="showDirection" [latLng]="{ lat: this.latitude, lng: this.longitude }"
        (closeModal)="closeModalDirection($event)">
      </app-modal-direction>

      <app-modal-general [showModal]="this.showGeneralModal" (showModalChange)="ConfirmFavouriteDelete($event)"
        [modalData]="modalConfirmDeleteFavourte">
      </app-modal-general>


      <app-modal-seguimiento-ordenes [showModal]="this.showModalSeguimientoOrdenes"
        (showModalChange)="closeModalSeguimiento($event)" [modalData]="ModalSeguimientoOrdenes">

      </app-modal-seguimiento-ordenes>

      <app-modal-error [showModal]="showModalError" (showModalChange)="ModalErrorAction($event)"
        [modalData]="modalError"></app-modal-error>


    </div>
  </div>
</main>
