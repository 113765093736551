import { Component } from '@angular/core';
import { Insurers } from 'src/app/models/sitefinity/insurers.model';
import { InsurersService } from 'src/app/services/sitefinity/insurers.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';

@Component({
  selector: 'app-insurers',
  templateUrl: './Insurers.component.html',
  styleUrls: ['./Insurers.component.css']
})
export class InsurersComponent {
  showMessage = false;

  constructor(
    private _insuresService: InsurersService,
    public sanitizer: DomSanitizer,
    private sitefinityPagesService : SitefinitypagesService
  ){}

  showEmptyMessage: boolean = false;
  insurers: Insurers[] = [];
  urlBase= environment.urlBase;
  SitefinityServicesUrl= environment.SitefinityServicesUrl;
  settings : SitefinitySettings = new SitefinitySettings;

  ngOnInit(): void {
    this.getInsurers();
    this.scrollToTop();
    this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'aseguradoras')!.ID);
  }

  getInsurers() {
    this.insurers = [];
    this._insuresService.GetInsurersMethod().subscribe(res => {
      if (res.response.code == "Insurers-success") {
        this.insurers = res.Insurers;
      }
      else {
        this.insurers = [];
        this.showEmptyMessage =  true;
      }
      this.showMessage = true;
    });
  }

  scrollToTop() {
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo(0, 0);
    }
  }
}
