import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/models/must-match.validator';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { ClientsService } from 'src/app/services/core/clients.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { MessagesService } from 'src/app/services/sitefinity/messages.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { IModal } from 'src/app/models/modal.model';
@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css'],
})
export class PasswordChangeComponent implements OnInit {
  form: FormGroup;
  settings: SitefinitySettings = new SitefinitySettings();
  validationMsg = this.settings.validationMsgTypeMap;

  sourceParam: string = '';
  showPassword = false;
  showConfirmPassword = false;
  isSubmited = false;
  isLinkValid: boolean = false;
  personalInfo: any;
  modalGeneral: IModal = {
    ModalName: 'modalCreationPassword',
    Title:'',
    Text:'',
    BtnConfirmText: '',
    ShowCancelBtn: false,
  }

  showModalGeneral:boolean = false;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loader: LoadingService,
    private notificationService: NotificationService,
    private utilitiesService: UtilitiesServicesService,
    private clientsService: ClientsService,
    private MessagesService: MessagesService,
    public sitefinityPagesService: SitefinitypagesService,
    private router: Router
  ) {
    this.form = fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(30),
            Validators.pattern(this.settings.regexPatternsTypeMap['password']),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }

  get password() {
    return this.form.get('password');
  }
  get confirmPassword() {
    return this.form.get('confirmPassword');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.sourceParam = this.utilitiesService.Base64Decode(
        decodeURIComponent(params['source'])
      );
    });
    if (this.sourceParam == undefined || this.sourceParam == '') {
      this.router.navigate(['/']);
    }
    this.sitefinityPagesService.setDetailPage(
      this.settings.pages.find((x) => x.Title === 'cambiocontrasena')!.ID
    );
    this.getInfo();
  }

  getInfo() {
    this.loader.showSpinner();
    this.clientsService.GetUserPersonalInfoMethod(this.sourceParam).subscribe(
      (o) => {
        if (o.response.code == 'OK0001') {
          this.personalInfo = o.responseObject;
          //Verificar que el link sea válido
          if (!this.personalInfo.RequirePasswordChange) {

            this.MessagesService.getMessagesMethod(
              'requirepasswordchange-fail',
              '1'
            ).subscribe((o) => {
              this.loader.hideSpinner();

              if(o.buttonAcceptText != '')
                {
                  this.showModalGeneral = false;
                  this.modalGeneral.Title = o.titleMessage;
                  this.modalGeneral.Text =  o.message;
                  this.modalGeneral.BtnConfirmText = o.buttonAcceptText;
                  this.modalGeneral.UrlBtnConfirm = o.url;
                  this.showModalGeneral = true;

                }

          else
          {
              this.notificationService.SendNotification(
                o.type,
                o.titleMessage,
                o.message
              );
              this.CleanFields();
              setTimeout(() => {
                this.router.navigate(['/inicio-sesion']);
              }, 5000);

            }


            });



             // this.notificationService.SendNotificationByCode(
            //   'requirepasswordchange-fail',
            //   '1'
            // );
          } else {
            this.isLinkValid = true;
          }
        } else {
          this.notificationService.SendNotification(
            o.response.type,
            o.response.titleMessage,
            o.response.message
          );
        }
        this.loader.hideSpinner();
      },
      (err) => {
        this.loader.hideSpinner();
        this.notificationService.SendNotification(
          'error',
          'Error',
          'Error inesperado'
        );
      }
    );
  }

  //#region METHODS
  ChangePassword(formData: any) {
    if (this.form.invalid) {
      this.isSubmited = true;
    } else {
      this.loader.showSpinner();
      if (formData.password != '') {
        if (this.personalInfo.RequirePasswordChange != true) {
         this.MessagesService.getMessagesMethod(
            'requirepasswordchange-fail',
            '1'
          ).subscribe((o) => {
            this.loader.hideSpinner();

            if(o.buttonAcceptText != '')
              {
                this.showModalGeneral = false;
                this.modalGeneral.Title = o.titleMessage;
                this.modalGeneral.Text =  o.message;
                this.modalGeneral.BtnConfirmText = o.buttonAcceptText;
                this.modalGeneral.UrlBtnConfirm = o.url;
                this.showModalGeneral = true;

              }

        else
        {
            this.notificationService.SendNotification(
              o.type,
              o.titleMessage,
              o.message
            );
            this.CleanFields();
            setTimeout(() => {
              this.router.navigate(['/inicio-sesion']);
            }, 5000);

          }


          });



          // this.notificationService.SendNotificationByCode(
          //   'requirepasswordchange-fail',
          //   '1'
          // );
          this.loader.hideSpinner();
        } else {
          this.personalInfo.Password = this.utilitiesService.encryptData(
            formData.password
          );
          this.personalInfo.ConfirmationPassword = this.personalInfo.Password;
          this.personalInfo.AcceptTerms = true;
          this.personalInfo.UserToken = this.sourceParam;
          this.personalInfo.RequirePasswordChange = false;
          this.personalInfo.BypassSecurity = true;

          this.clientsService
            .UpdateClientInfoMethod(this.personalInfo)
            .subscribe(
              (o) => {

                if (o.code == 'UpdateClientInfoMethod-success') {
                  this.MessagesService.getMessagesMethod(
                    'changepassword-OK',
                    '1'
                  ).subscribe((o) => {
                    this.loader.hideSpinner();

                    this.notificationService.SendNotification(
                      o.type,
                      o.titleMessage,
                      o.message
                    );
                    this.CleanFields();
                    setTimeout(() => {
                      this.router.navigate(['/iniciosesion']);
                    }, 5000);
                  });
                } else {
                  this.loader.hideSpinner();

                  if(o.buttonAcceptText != '')
                    {
                      this.showModalGeneral = false;
                      this.modalGeneral.Title = o.titleMessage;
                      this.modalGeneral.Text =  o.message;
                      this.modalGeneral.BtnConfirmText = o.buttonAcceptText;
                      this.modalGeneral.UrlBtnConfirm = o.url;
                      this.showModalGeneral = true;

                    }
                    else
                    {

                      this.notificationService.SendNotification(
                        o.type,
                        o.titleMessage,
                        o.message
                      );

                    }






                }
              },
              (err) => {
                this.loader.hideSpinner();
                this.notificationService.SendNotification(
                  'error',
                  'Error',
                  'Error inesperado'
                );
              }
            );
        }
      }
    }
  }
  //#endregion

  //#region Utilities
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  CleanFields() {
    this.form.controls['password'].setValue('');
    this.form.controls['password'].markAsUntouched();
    this.form.controls['password'].markAsPristine();
    this.form.controls['confirmPassword'].setValue('');
    this.form.controls['confirmPassword'].markAsUntouched();
    this.form.controls['confirmPassword'].markAsPristine();
    this.isSubmited = false;
  }


  closeModalPassword(event: any, isButtonAccept?:boolean)
  {
    if(this.modalGeneral.UrlBtnConfirm != '')
      {
        let x:any = this.modalGeneral.UrlBtnConfirm
        this.loader.showSpinner();
        const parts: string[] = x.split('/');
        const lastPart:  string = "/"+ parts[parts.length - 1];
        this.router.navigate([lastPart]);
    }
    this.showModalGeneral = false;

  }
  //#endregion
}
