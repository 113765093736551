import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, of, catchError, throwError } from 'rxjs';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { TypeOfDocuments, ResponseTypeOfDocuments, ResponseLegalDocuments, LegalDocuments } from '../../models/sitefinity/type-of-documents.model'
import { MessagesService } from './messages.service';
import { LoadingService } from '../shared/loading.service';
import { UtilitiesService } from '../core/utilities.service';

const settings = new SitefinitySettings();

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private http: HttpClient, private messagesService: MessagesService, private loader: LoadingService, private utilities: UtilitiesService) { }

  private GetAllDocumentsUrl = settings.SitefinityServicesUrl + '/api/default/legaldocuments';
  private GetDocumentByIdTypeUrl = settings.SitefinityServicesUrl + '/api/default/legaldocuments?$filter=contains(code,' + '{{value}}' + ')' ///api/default/legaldocuments({key})/document({relatedKey})
  private GetDocumentsTypeUrl = settings.SitefinityServicesUrl + '/api/default/typeofdocuments';

  GetDocumentsType(): Observable<ResponseTypeOfDocuments> {
    return new Observable<ResponseTypeOfDocuments>((observer) => {
      const responseServices: ResponseTypeOfDocuments = new ResponseTypeOfDocuments();
      var url = this.GetDocumentsTypeUrl;

      try {
        const servicesResponse = this.http.get<any>(url);
        servicesResponse.subscribe((response) => {
          if (response.value == undefined) {
            responseServices.response.code = 'getdocumentType-fail01';
            this.messagesService.GetMessagesByKey('getlanding-fail01', '4').subscribe(data => {
              var messageResponse = data.value[0];
              if (messageResponse == undefined) {
                responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getdocumentType-fail01';
                responseServices.response.alias = 'getdocumentType-fail01';
                responseServices.response.status = true;
              }
              else {
                responseServices.response.message = messageResponse.message;
                responseServices.response.alias = messageResponse.aliascode;
                responseServices.response.status = true;
              }

              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = false;
              return responseServices;
            })

          }

          const Odata = response.value;
          responseServices.response.code = 'getdocumentType-success';
          this.messagesService.GetMessagesByKey('getdocumentType-success', '4').subscribe(data => {
            var messageResponse = data.value[0];

            if (messageResponse == undefined) {
              responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getdocumentType-success';
              responseServices.response.alias = 'getdocumentType-success';
              responseServices.response.status = true;
            }
            else {
              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = true;
            }
          });


          Odata.forEach((DataItem: any) => {
            const items = new TypeOfDocuments();
            items.id = DataItem.Id;
            items.name = DataItem.Title;
            responseServices.typeOfDocuments.push(items);
          });

          observer.next(responseServices);
          observer.complete();
        });
      } catch (error) {
        responseServices.response.code = 'getdocumentType-error';
        this.messagesService.GetMessagesByKey('getdocumentType-error', '4').subscribe(data => {
          var messageResponse = data.value[0];
          if (messageResponse == undefined) {
            responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getdocumentType-error';
            responseServices.response.alias = 'getdocumentType-error';
            responseServices.response.status = true;
          }
          else {
            responseServices.response.message = messageResponse.message;
            responseServices.response.alias = messageResponse.aliascode;
            responseServices.response.status = true;
          }

          responseServices.response.message = messageResponse.message;
          responseServices.response.alias = messageResponse.aliascode;
          responseServices.response.status = false;
        });

        observer.next(responseServices);
        observer.complete();
      }
    });
  }


  GetLegalDocuments(skip: number, top: number): Observable<ResponseLegalDocuments> {
    this.loader.showLoadingBar();

    return new Observable<ResponseLegalDocuments>((observer) => {
      const responseServices: ResponseLegalDocuments = new ResponseLegalDocuments();
      var url = this.GetAllDocumentsUrl;
      var UrlSkipTop = '';

      this.GetDocumentSort().subscribe(type => {

        UrlSkipTop = type === 1 ?
          `${url}?$expand=document,typeOfDocument&$skip=${skip}&$top=${top}&$count=true&$orderby=DateCreated desc`
          : `${url}?$expand=document,typeOfDocument&$skip=${skip}&$top=${top}&$count=true`;

        try {
          const servicesResponse = this.http.get<any>(UrlSkipTop);
          servicesResponse.subscribe((response) => {
            if (response.value == undefined) {
              responseServices.response.code = 'getLegaldocument-fail01';
              this.messagesService.GetMessagesByKey('getLegaldocument-fail01', '4').subscribe(data => {
                var messageResponse = data.value[0];
                if (messageResponse == undefined) {
                  responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getLegaldocument-fail01';
                  responseServices.response.alias = 'getLegaldocument-fail01';
                  responseServices.response.status = true;
                }
                else {
                  responseServices.response.message = messageResponse.message;
                  responseServices.response.alias = messageResponse.aliascode;
                  responseServices.response.status = true;
                }

                responseServices.response.message = messageResponse.message;
                responseServices.response.alias = messageResponse.aliascode;
                responseServices.response.status = false;

                this.loader.hideLoadingBar();
                observer.next(responseServices);
                observer.complete();
              })

            }

            const Odata = response.value;
            responseServices.response.code = 'getLegaldocument-success';
            this.messagesService.GetMessagesByKey('getLegaldocument-success', '4').subscribe(data => {
              var messageResponse = data.value[0];

              if (messageResponse == undefined) {
                responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getLegaldocument-success';
                responseServices.response.alias = 'getLegaldocument-success';
                responseServices.response.status = true;
              }
              else {
                responseServices.response.message = messageResponse.message;
                responseServices.response.alias = messageResponse.aliascode;
                responseServices.response.status = true;
              }
            });

            Odata.forEach((DataItem: any) => {
              const items = new LegalDocuments();
              items.id = DataItem.Id;
              items.name = DataItem.documentName;
              items.description = DataItem.description;
              items.showfile = DataItem.showFile;

              // Documento
              const document = DataItem.document[0];
              if (document != undefined) {
                items.url = document.Url;
              }

              // Tipo de documento
              const documentType = DataItem.typeOfDocument;
              documentType.forEach((DataItem: any) => {
                const type = new TypeOfDocuments();
                type.id = DataItem.Id;
                type.name = DataItem.Title;
                items.TypeOfDocuments.push(type);
              })
              items.TypeOfDocumentsSort = items.TypeOfDocuments[0].name;

              responseServices.legalDocuments.push(items);
            });

            responseServices.totalDocuments = response['@odata.count'];
            observer.next(responseServices);
            observer.complete();
            this.loader.hideLoadingBar();
          });
        } catch (error) {
          responseServices.response.code = 'getLegaldocument-error';
          this.messagesService.GetMessagesByKey('getLegaldocument-error', '4').subscribe(data => {
            var messageResponse = data.value[0];
            if (messageResponse == undefined) {
              responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getLegaldocument-error';
              responseServices.response.alias = 'getLegaldocument-error';
              responseServices.response.status = true;
            }
            else {
              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = true;

            }

            responseServices.response.message = messageResponse.message;
            responseServices.response.alias = messageResponse.aliascode;
            responseServices.response.status = false;

          });

          this.loader.hideLoadingBar();
          observer.next(responseServices);
          observer.complete();
        }
      })
    });
  }


  GetLegalDocumentsByIdType(skip: number, top: number, idType: string): Observable<ResponseLegalDocuments> {
    this.loader.showLoadingBar();
    return new Observable<ResponseLegalDocuments>((observer) => {
      const responseServices: ResponseLegalDocuments = new ResponseLegalDocuments();
      var url = this.GetAllDocumentsUrl;

      url = url + '?&$orderby=DateCreated desc &$expand=typeOfDocument&$filter=typeOfDocument/any(t:t/Id eq ' + idType + ')';
      var UrlSkipTop = `${url}&$skip=${skip}&$top=${top}&$count=true`;

      try {
        const servicesResponse = this.http.get<any>(UrlSkipTop);
        servicesResponse.subscribe((response) => {
          if (response.value == undefined) {
            responseServices.response.code = 'getLegalDocumentsByIdType-fail01';
            this.messagesService.GetMessagesByKey('getLegalDocumentsByIdType-fail01', '4').subscribe(data => {
              var messageResponse = data.value[0];
              if (messageResponse == undefined) {
                responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getLegalDocumentsByIdType-fail01';
                responseServices.response.alias = 'getLegalDocumentsByIdType-fail01';
                responseServices.response.status = true;
              } else {
                responseServices.response.message = messageResponse.message;
                responseServices.response.alias = messageResponse.aliascode;
                responseServices.response.status = true;
              }
              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = false;

              this.loader.hideLoadingBar();
              observer.next(responseServices);
              observer.complete();
            });
          }

          const Odata = response.value;
          responseServices.response.code = 'getLegalDocumentsByIdType-success';
          this.messagesService.GetMessagesByKey('getLegaldocument-success', '4').subscribe(data => {
            var messageResponse = data.value[0];
            if (messageResponse == undefined) {
              responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getLegalDocumentsByIdType-success';
              responseServices.response.alias = 'getLegalDocumentsByIdType-success';
              responseServices.response.status = true;
            } else {
              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = true;
            }
          });

          Odata.forEach((DataItem: any) => {
            const items = new LegalDocuments();
            items.id = DataItem.Id;
            items.name = DataItem.documentName;
            items.description = DataItem.description;
            items.documentText = DataItem.documentText;
            items.showfile = DataItem.showFile;
            var urlDocumentRelated = this.GetAllDocumentsUrl + "(" + DataItem.Id + ")/document";
            const servicesFileResponse = this.http.get<any>(urlDocumentRelated);
            servicesFileResponse.subscribe((responseFile) => {
              const document = responseFile.value[0];
              if (document != undefined) {
                items.url = document.Url;
              }
            });

            DataItem.typeOfDocument.forEach((item: any) => {
              const type = new TypeOfDocuments();
              type.id = item.Id;
              type.name = item.Title;
              items.TypeOfDocuments.push(type);
            });

            responseServices.legalDocuments.push(items);
          });

          responseServices.totalDocuments = response['@odata.count'];
          observer.next(responseServices);
          observer.complete();
          this.loader.hideLoadingBar();
        });
      } catch (error) {
        responseServices.response.code = 'getLegaldocument-error';
        this.messagesService.GetMessagesByKey('getLegaldocument-error', '4').subscribe(data => {
          var messageResponse = data.value[0];
          if (messageResponse == undefined) {
            responseServices.response.message = 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getLegaldocument-error';
            responseServices.response.alias = 'getLegaldocument-error';
            responseServices.response.status = true;
          } else {
            responseServices.response.message = messageResponse.message;
            responseServices.response.alias = messageResponse.aliascode;
            responseServices.response.status = true;
          }

          this.loader.hideLoadingBar();
          observer.next(responseServices);
          observer.complete();
        });
      }
    });
  }

  GetDocumentSort(): Observable<number> {
    return this.utilities.GetParametersByKey("LegalDocumentSorts").pipe(
      map((response: any) => {
        var jsonSort: any[] = [];
        jsonSort = JSON.parse(response.value[0].value);

        var objetosFiltrados = jsonSort.filter(obj => obj.estatus === 'Activo');
        var type: number = 0;
        type = objetosFiltrados[0].id;
        return type;
      })
    );
  }

  public GetLegalDocumentsServices(skip: number, top: number, url: string): Observable<any> {

    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.get(url);
  }
}
