import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CheckoutService } from 'src/app/services/core/checkout.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { CartService } from 'src/app/services/core/cart.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
declare var window: any;

@Component({
  selector: 'app-modal-delete-cart',
  templateUrl: './modal-delete-cart.component.html',
  styleUrls: ['./modal-delete-cart.component.css']
})
export class ModalDeleteCartComponent {

  @Input() cartId!: string;
  @Input() showModal: boolean = false;
  @Input() modalData: any = {
    ModalName: 'modalDelete',
  };
  modal: any;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  error: boolean = false;

  constructor(
    private checkoutService: CheckoutService,
    private loader: LoadingService,
    private sharedService: SharedService,
    private cartService: CartService,
    private notificationService: NotificationService,
    private utilitiesService: UtilitiesServicesService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.modal.show();
    }
  }

  clearCart() {
    if (this.utilitiesService.isUserLoggedIn()) {
      const object: any = {
        Address: null,
        FACRedirect3DSecureResponse: null,
        FavoriteOrderName: null,
        GenerateElectronicInvoice: null,
        IDSavedCard: null,
        IdCart: this.cartId,
        IdClient: 0,
        IdFavoriteOrder: 0,
        IdFavoriteProduct: 0,
        IsAMEX: null,
        IsCardToSave: null,
        PaymentExtraInfo: null,
        PaymentMethod: null,
        Product: null,
        PurchaseOnBehalf: null,
        Quantity: null,
        UseFAC: null,
        UseSavedCard: null,
      };

      this.loader.showSpinner();
      this.checkoutService.ClearCartMethod(object).subscribe(o => {
        if (o) {
          // this.sharedService.updateCart();
          this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, "1", "3", "0").subscribe(res => {
            if (res.response.code !== "CSDEL") {
              this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
            }
          });

          this.close(true);
        }
        else {
          this.error = true;
        }
      });

      setTimeout(() => {
        // Ocultar el spinner después del tiempo especificado
        this.loader.hideSpinner();
      }, 2000);
    }
    else {
      this.loader.showSpinner();
      this.utilitiesService.deleteAllStorage('productStorage');
      this.close(false);

      setTimeout(() => {
        // Ocultar el spinner después del tiempo especificado
        this.loader.hideSpinner();
      }, 3000);
    }
  }

  close(event?: boolean) {
    this.closeModal.emit(event);
    this.modal.hide();
  }
}
