import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { ResponseGenericObject } from 'src/app/models/core/products.model';
import { switchMap, map } from 'rxjs/operators';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import { MessagesService } from '../sitefinity/messages.service';
import { UtilitiesServicesService } from '../shared/utilities-services.service';

const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})

export class CheckoutService {

  constructor(
    private http: HttpClient,
    private MessagesService_ :MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService
  ) { }

private GetShopsByLocationServices(province: number, canton: number, district: number): Observable<any> {
  const url = `${settings.CoreServicesUrl}shop.svc/GetShopsByLocation/${province}/${canton}/${district}`;

  return this.http.get(url);
}


public GetShopsByLocationMethod (province: number, canton: number, district: number): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.GetShopsByLocationServices(province, canton, district).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}


private GetProductsInventoryServices(object:any):Observable<any>
{
  const url = settings.CoreServicesUrl + 'BombaCart.svc/GetProductsInventory';
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  var json =  JSON.stringify(object);

  return  this.http.post(url,json,{ headers });
}


public GetProductsInventoryMethod (object:any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.GetProductsInventoryServices(object).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);
          let valid: boolean = true;
          Response.forEach((p: any) => {
            if (!p.ThereIsInventory) {
              valid = false;
            }
          });
          if (valid) {
            return this.getMessages(code,'2').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: Response,
                  response: data
                };
                return response;
              })
            );
          }
          else {
            return this.getMessages(code,'2').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: Response,
                  response: data
                };
                return response;
              })
            );
          }

        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}


private GetImpulseProductsServices(cart: string): Observable<any> {
  const url = `${settings.CoreServicesUrl}Product.svc/GetImpulseProducts/${cart}`;

  return this.http.get(url);
}


public GetImpulseProductsMethod (cart: string): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.GetImpulseProductsServices(cart).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



private GetProductsInventoryWithoutInventoryServices(object:any):Observable<any>
{
  const url = settings.CoreServicesUrl + 'BombaCart.svc/GetProductsInventoryWithoutInventory';
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  var json =  JSON.stringify(object);

  return  this.http.post(url,json,{ headers });
}


public GetProductsInventoryWithoutInventoryMethod (object:any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.GetProductsInventoryWithoutInventoryServices(object).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);




          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}


private ClearCartServices(object:any):Observable<any>
{
  const url = settings.CoreServicesUrl + 'Cart.svc/ClearCart';
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  var json =  JSON.stringify(object);

  return  this.http.post(url,json,{ headers });
}


public ClearCartMethod (object:any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.ClearCartServices(object).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



private RemoveProductFromCartServices(object:any):Observable<any>
{
  const url = settings.CoreServicesUrl + 'Cart.svc/RemoveProductFromCart';
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  var json =  JSON.stringify(object);

  return  this.http.post(url,json,{ headers });
}


public RemoveProductFromCartMethod (object:any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.RemoveProductFromCartServices(object).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        let code = coreResponse.Messages[0];

        let o = JSON.stringify(object);
        let c = JSON.stringify(coreResponse);
        // this.ErrorOrLogsServices.insertErrorObservable(o, 'DELETE PRODUCT', c, 'RemoveProductFromCartMethod').subscribe(t => {
        // });

        if (code === undefined) {
          code = (coreResponse.IsValid === true && coreResponse.Parameters != null)
          ? "DeleteProduct-Success" : code;
        }

        if (code === "DeleteProduct-Success") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



private getMessages(code: string,category: string): Observable<ResponseInterface> {

  return new Observable<Response>((observer) => {
       var messageCore: string[]= [];
      this.MessagesService_.GetMessagesByKey(code,category).subscribe((data) => {
      var messageResponse = data.value[0];

          if (messageResponse == undefined) {


            messageCore.push(code);
            const responseServices: ResponseInterface = {
                message : 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
                alias : code + '-alias',
                status : true,
                code: code,
                messageCore: messageCore,
                buttonAcceptText:'',
                buttonCancelText: '',
                type:'',
                action:'',
                titleMessage: '',
                url: '',
                Messages:[]
               };
               observer.next(responseServices);
               observer.complete();

          }
          else
          {
            const responseServices: ResponseInterface = {
                message : messageResponse.message,
                alias : messageResponse.aliascode,
                status : true,
                code:messageResponse.code,
                messageCore: messageCore,
                buttonAcceptText:messageResponse.buttonAcceptText,
                buttonCancelText: messageResponse.buttonCancelText,
                type:messageResponse.type,
                action:messageResponse.action,
                titleMessage: messageResponse.titleMessage,
                url: messageResponse.url,
                Messages:[]
            }
            observer.next(responseServices);
            observer.complete();
          }
    },(error) =>{

      const responseServices: ResponseInterface = {
        message : 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
        alias : 'getMessages-error-alias-01',
        status : false,
        code:'getMessages-error-01',
        messageCore: messageCore,
        buttonAcceptText:'',
        buttonCancelText: '',
        type:'',
        action:'',
        titleMessage: "falta agregar el código",
        url:'',
        Messages:[]
    };
    observer.error(responseServices);
    observer.complete();

    });

});


}

private FailedPurchaseRollbackServices(IDPurchase: any, IDClient: any):Observable<any> {
  const url = `${settings.CoreServicesUrl}Cart.svc/FailedPurchaseRollback/${IDPurchase}/${IDClient}`;
  return  this.http.get(url);
}

public FailedPurchaseRollback (IDPurchase:any, IDClient: any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.FailedPurchaseRollbackServices(IDPurchase, IDClient).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        let code = coreResponse.Messages[0];

        if (code === undefined) {
          code = (coreResponse.IsValid === true && coreResponse.Parameters != null)
          ? "OK0001" : code;
        }

        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}

}

