import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreResponse } from 'src/app/models/responses/response.model';
import { UtilitiesResponse, Province, Canton }
  from 'src/app/models/core/utilities.model';
import { CoreRequest } from 'src/app/models/requests/request.model';

const settings = new SitefinitySettings();

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private http: HttpClient) { }

  getProvinces(): Observable<CoreRequest> {
    return this.http.get<CoreRequest>(`${settings.CoreServicesUrl}Setting.svc/GetFilteredActiveProvinces`);
  }

  GetCantonsByIDProvince(idProvince: string): Observable<CoreRequest> {
    return this.http.get<CoreRequest>(`${settings.CoreServicesUrl}Setting.svc/GetFilteredCantonsByIDProvince/${idProvince}`);
  }

  GetCantonsCompleteByIDProvince(idProvince: string): Observable<CoreRequest> {
    return this.http.get<CoreRequest>(`${settings.CoreServicesUrl}Setting.svc/GetCantonsByIDProvince/${idProvince}`);
  }


  GetDistrictsByIDCanton(idCanton: string): Observable<CoreRequest> {
    return this.http.get<CoreRequest>(`${settings.CoreServicesUrl}Setting.svc/GetDistrictsByIDAddressCanton/${idCanton}`);
  }


  GetParametersByKey(key: string): Observable<any> {
    var url = settings.SitefinityServicesUrl + `/api/default/parameters?$filter=Title eq '${key}'`;

    return this.http.get<any>(url);
  }

  GetConfigValueMethod(key: string): Observable<any> {
    const url = `${settings.CoreServicesUrl}Setting.svc/GetConfigValue/${key}`;
    return this.http.get(url);
  }

  GetQuantityCart(cartId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*' // Reemplaza '*' con el origen correcto si es necesario
    });
    const url = `${settings.CoreServicesUrl}Cart.svc/GetTotalOfProducts`;
    const requestBody = { IdCart: cartId };
    return this.http.post(url, requestBody, {headers});
  }

  /**
   * Devuelve el texto sin etiquetas html
   * @param rawData - El texto con etiquetas html
   * @returns El texto sin etiquetas html
   */
  decodeData(rawData?: string): string {
    if(!rawData){
      return '';
    }
    // Crea un elemento temporal en el DOM
    const tempElement = document.createElement('div');

    // Asigna el texto con etiquetas html al elemento temporal
    tempElement.innerHTML = rawData;

    // asigna el contenido de texto del elemento temporal o una cadena vacía si no hay contenido de texto
    let text =  tempElement.textContent || tempElement.innerText || '';
    //Corrige espacios en blanco
    text = text.replaceAll('&nbsp;', ' ').trim();
    return text;
  }

  CleanUrl(text: string): string{
    return text
    .trim()
    .toLowerCase()
    // quitar acentos
    .replace(/[áäâà]/g, "a")
    .replace(/[éëêè]/g, "e")
    .replace(/[íïîì]/g, "i")
    .replace(/[óöôò]/g, "o")
    .replace(/[úüûù]/g, "u")
    .replace(/[ýÿ]/g, "y")
    // quitar caracteres especiales, permitiendo espacios, números, letras (incluida la "ñ")
    .replace(/[^\w\sñ]/g, "")
    // cambiar espacios por guiones
    .replace(/\s+/g, "-");
  }

  RecoverTextFromUrl(text: string): string{
    return text
    .replaceAll("-", " ");
  }

  getUbicationByCoordenates(lat: number, lng: number): Observable<any> {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${settings.googleMapsApiKey}`);
  }

  getUbicationByAddress(address: string): Observable<any> {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${settings.googleMapsApiKey}`);
  }

CalculateTotal(products:any[]): any{
  var total  = 0;
  products.forEach(element => {

    if(element.PriceWithDiscount == 0)
    {
      total = (element.CurrentPrice * element.Quantity) + total;
    }
    else
    {
      total = (element.PriceWithDiscount * element.Quantity) + total;
    }
});

return total;


}

  /**
   * Ajusta la fecha para reflejar la misma fecha y hora en la zona horaria local,
   * eliminando cualquier desplazamiento de la zona horaria. Esto es útil para garantizar
   * que las fechas se presenten y manipulen correctamente independientemente de la zona
   * horaria del usuario.
   *
   * @param {Date} date - La fecha que se va a ajustar.
   * @returns {Date} - Una nueva instancia de Date ajustada a la zona horaria local.
   */
  fixUTCDate(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  /**
   * Formatea una fecha como una cadena de texto en un formato específico. El formato
   * predeterminado es "dd-mmm-yyyy" (día-abreviatura del mes-año), pero puede
   * personalizarse según las necesidades.
   *
   * @param {Date} date - La fecha que se va a formatear.
   * @returns {string} - La fecha formateada como una cadena de texto.
   */
  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };

    // Obtener el array de partes de la fecha y reorganizarlas
    const dateParts = date
      .toLocaleDateString('en-US', options)
      .replace(',', '')
      .split(' ');

    return `${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`;
  }

}
