<div class="toast-container position-fixed bottom-0 end-0 p-2" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
  <ngb-toast *ngIf="_toastService.GetShowError()" [delay]="3000" [autohide]="true" (hidden)="_toastService.Hide()" class="fix-toast" [ngClass]="_toastService.GetPushClass()">
    <div class="container">
      <div class="row">
        <div class="col-auto d-flex justify-content-center align-items-center" [ngClass]="_toastService.GetIconBgClass()">
          <i class="bi" [ngClass]="_toastService.GetIconClass()"></i>
        </div>
        <div class="col pt-2 bg-white">
          <span class="text-notification">{{this._utilitiesService.decodeData(_toastService.GetTitle())}}</span>
          <p>{{this._utilitiesService.decodeData(_toastService.GetMessage()) }}</p>
        </div>
        <div class="col-auto d-flex pt-1 bg-white" *ngIf="isHovered">
          <i class="bi bi-x-circle icon-push-success text-dark" (click)="hideToast()"></i>
        </div>
      </div>
    </div>
  </ngb-toast>
</div>
