
    <main class="main">
      <div class="container container-section mt-3 mt-lg-5">

          <div class="row">
              <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
                  <h1 class="mb-4">Farmacia La Bomba
                      llegó a tu teléfono</h1>
                  <div class="d-flex align-items-center">
                      <i class="bi bi-check2-circle icon-app"></i>
                      <h3 class="mb-0">Buscá tus productos ¡Más fácil!</h3>
                  </div>
                  <div class="d-flex align-items-center">
                      <i class="bi bi-check2-circle icon-app"></i>
                      <h3 class="mb-0">Obtené el servicio que merecés.</h3>
                  </div>
                  <div class="d-flex align-items-center">
                      <i class="bi bi-check2-circle icon-app"></i>
                      <h3 class="mb-0">Disfrutá del pago en línea de manera segura y sencilla.</h3>
                  </div>
                  <div class="d-flex align-items-center">
                      <i class="bi bi-check2-circle icon-app"></i>
                      <h3 class="mb-0">Ahorrá dinero</h3>
                  </div>

                  <div class="my-4">
                      <h2 class="">Descargá la app y ganá</h2>
                      <a href="https://play.google.com/store/apps/details?id=com.bigshotapps.cefabomba&hl=es_CR&gl=US&pli=1"><img
                        loading="lazy" [src]="urlBase +'/assets/img/google-play-badge.png'" alt="Google Play badge" class="bagde-app"></a>
                      <a href="https://apps.apple.com/us/app/farmacia-la-bomba/id1569639991">
                        <img [src]="urlBase +'/assets/img/logo-app-store.png'" loading="lazy"  alt="Google Play badge" class="bagde-app"></a>
                  </div>
              </div>

              <div class="col-12 col-lg-6 d-none d-lg-flex justify-content-center">
                  <img [src]="urlBase +'/assets/img/img-app-1.png'" loading="lazy" alt="…" class="img-app">
                  <img [src]="urlBase +'/assets/img/img-app-2.jpg'" loading="lazy" alt="…" class="img-app">

              </div>
          </div>

          <p class="mt-lg-4 text-center">Al hacer tu primer compra, podrás participar por una canasta de productos y
              Certificado
              en Vainilla
              Beauty</p>
          <h1 class="text-center">¡Queremos que estés bien!</h1>
      </div>
  </main>
