<div class="modal fade" id="modalRecordarProducto" #modalRecordarProducto tabindex="-1"
  aria-labelledby="modalRecordarProductoLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="modalRecordarProductoLabel">Crear recordatorio</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-12">
            <span>Fecha de inicio del recordatorio</span>
          </div>
          <div class="col-6 mb-3">
            <label for="">Día</label>
            <select class="form-select" aria-label="Default select example">
              <option selected></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="3">4</option>
              <option value="3">5</option>
            </select>

          </div>

          <div class="col-6 mb-3">
            <label for="">Mes</label>
            <select class="form-select" aria-label="Default select example">
              <option selected></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="3">4</option>
              <option value="3">5</option>
            </select>

          </div>

          <div class="col-12">
            <span>Seleccioná el día del mes para recordarte tu compra y el medio por el cual deseás que
                te contactemos.</span>
        </div>
          <div class="col-6 mb-3">
            <label for="">Día</label>
            <select class="form-select" aria-label="Default select example">
              <option selected></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="3">4</option>
              <option value="3">5</option>
            </select>

          </div>

          <div class="col-6 mb-3">
            <label for="">Medio de contacto</label>
            <select class="form-select" aria-label="Default select example">
              <option selected></option>
              <option value="1">Email</option>
              <option value="2">Llamada telefónca</option>
              <option value="3">Mensaje SMS</option>
              <option value="3">WhatsApp</option>
              <option value="3">Telegram</option>
            </select>

          </div>
          <div class="col-12">
            <span>Tomá en cuenta que el medio para contactarte es el mismo para todos los recordatorios.
                Por lo que si ya tenés creados otros recordatorios, actualizaremos el medio de contacto
                en cada uno, según lo que seleccionaste en este recordatorio.</span>
        </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <!-- <p class="text-footer-modal">Codigo de error</p> -->
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
          (click)="close()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="accept()">Guardar recordatorio</button>
      </div>
    </div>
  </div>
</div>
