import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-app-social-media',
  templateUrl: './app-social-media.component.html',
  styleUrls: ['./app-social-media.component.css']
})
export class AppSocialMediaComponent {
  
  constructor(){}
  
  urlBase= environment.urlBase;
}
