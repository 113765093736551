<!-- Modal consentimiento-->
<div class="modal fade modal-overlay-light" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="modalConsentimientoLabel"
  aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" data-bs-keyboard="false"
    data-bs-backdrop="static">
    <div class="modal-content modal-style" *ngIf="this.document != undefined && this.document.documentText != undefined && this.document.documentText.trim() != '' ">
      <div class="modal-header">
        <h2 class="modal-title fs-5 color-tittle fw-medium" id="modalConsentimientoLabel">
          {{ this.document.name }}</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body" id="terms-text">
        <div [innerHTML]="trustedHtml"></div>
        <div id="termsEnd"></div>
      </div>
      <div class="modal-footer flex-column align-items-center align-items-lg-end justify-content-center">
        <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center">
          <button id="terms-button" type="button" class="btn btn-primary order-lg-2" [disabled]="!this.termsReaded" (click)="accept()">
            {{modalData.BtnConfirmText}}
          </button>
          <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="close()">Regresar</button>
        </div>
      </div>
    </div>

    <div class="modal-content"
      *ngIf="!(this.document != undefined && this.document.documentText != undefined && this.document.documentText.trim() != '' )">
      <div class="modal-body">
        <div> Hubo un problema al obtener el consentimiento. Contacte al administrador
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
          <!-- <p class="text-footer-modal d-none">Codigo de error</p> -->
        </div>
        <div>
          <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="close()">Regresar</button>
        </div>
      </div>
    </div>

  </div>
</div>
