<div class="toast-container position-fixed bottom-0 end-0 p-2" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
	<ngb-toast *ngFor=" let s of _toastService.GetArray(); let i = index" [delay]="3000" [autohide]="true" (hidden)="_toastService.Hide()" class="fix-toast" [ngClass]="_toastService.GetPushClass(i)">
    <ng-container *ngIf="_toastService.GetShowError()">
      <div class="container">
        <div class="row">
          <div class="col-auto d-flex justify-content-center align-items-center" [ngClass]="_toastService.GetIconBgClass(i)">
            <i class="bi" [ngClass]="_toastService.GetIconClass(i)"></i>
          </div>
          <div class="col pt-2 bg-white">
            <span class="text-notification">{{this._utilitiesService.decodeData(_toastService.GetTitle(i))}}</span>
            <p>{{this._utilitiesService.decodeData(_toastService.GetMessage(i)) }}</p>
          </div>
          <div class="col-auto d-flex pt-1 bg-white" *ngIf="isHovered">
            <i class="bi bi-x-circle icon-push-success text-dark" (click)="hideToast()"></i>
          </div>
        </div>
      </div>
    </ng-container>  
	</ngb-toast>
</div>