import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
declare var window: any;
@Component({
  selector: 'app-modal-disponibilidad',
  templateUrl: './modal-disponibilidad.component.html',
  styleUrls: ['./modal-disponibilidad.component.css']
})
export class ModalDisponibilidadComponent implements OnInit, OnChanges {

  @Input() showModal: boolean = false;
  @Input() titulo: string = '';
  @Input() mensaje: string = '';
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;

  ngOnInit(): void {
    this.modal = new window.bootstrap.Modal(
      document.getElementById('modalDisponibilidad')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.modal.show();
    }
  }

  accept() {
    this.showModalChange.emit(true);
  }

  close() {
    this.showModalChange.emit(false);
  }
}
