import { Injectable } from "@angular/core";

@Injectable()
export class RootUrlService {
    public getUrl() {

      return 'https://newlabombadev15.loymarkservices.com/';
      //  return `${window.location.origin}/`;
    }

    public getServiceUrl() {

        return `${this.getUrl()}api/default/`

    }
}
