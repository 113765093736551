
<main class="main" *ngIf="this.productEmpty == 'NP'">
  <!-- Mostrar cuando no haya datos -->
  <div class="d-flex align-items-center justify-content-center my-5" >
    <div class="text-center">
      <i class="bi bi-cart icon-page"></i>
      <h2 class="">Tu carrito está vacío</h2>
      <p>Seleccioná un producto para proceder a la compra</p>
    </div>
  </div>
  </main>


<main class="main" *ngIf="this.productEmpty == 'F'">
  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="color-primary">Carrito de compras</h1>

    <div class="container my-4">
      <div class="row row-cols-auto stepper">
        <div class="col content-col active cursor-pointer">
          <a>
            <span>
              <i class="bi bi-cart"></i>
            </span>
          </a>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col cursor-pointer">
          <a>
            <span>
              <i class="bi bi-truck"></i>
            </span>
          </a>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col cursor-pointer">
          <a>
            <span>
              <i class="bi bi-credit-card"></i>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8">

        <div class="d-flex align-items-center justify-content-end">
          <a href="" class="fw-medium text-decoration-none" type="button" data-bs-toggle="modal"
            data-bs-target="#modalDelete" (click)="showModalDelete()">
            Vaciar carrito
          </a>
        </div>

        <!-- Carrito de compras 1-->
        <div class="card mb-2 border-color" *ngFor="let product of productsCart; let i = index">
          <div class="row g-0">
            <div class="col-2 mb-0 d-flex align-items-center justify-content-center">
              <div class="img-favorite-product">
                <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(product.URL)">
                <img [src]="product.Images[0].WebPath" alt="" (click)="goToDetailProduct(product.URL)">
                </a>
              </div>
            </div>
            <div class="col-10">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(product.URL)">
                  <h5 class="card-title mb-0">{{product.Name}}</h5>
                  </a>
                  <a (click)="removeProductFromCart(product, i)" class="cursor-pointer">
                    <i class="bi bi bi-trash delete-product"></i>
                  </a>
                </div>
                <p class="fs-x-small mb-2">SKU {{product.SelectedAttributes[0]?.Sku3 ? product.SelectedAttributes[0]?.Sku3 : product.Sku}}</p>
                <app-modal-producto-faltante #modalProductoFaltante [showModal]="showModalProductoFaltante"
                  (closeModal)="closeMissingPorduct($event)" [cartId]="cartId" [userId]="userId"
                  [product]="selectedProduct" [productInventory]="productInventory"></app-modal-producto-faltante>

                <div class="d-flex align-items-end justify-content-star">
                  <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(product.URL)">
                  <span class="product-price text-nowrap"> {{selectedUnitPrice[i] | mycurrency}} i.v.a.i</span>
                  <span class="product-discount ms-2 text-nowrap"
                    *ngIf="product.PriceWithDiscount > 0">{{product.CurrentTotalPrice | mycurrency}} i.v.a.i</span>
                  </a>
                </div>

                <div class="d-flex align-items-center" *ngIf="product.CouponApplied">
                  <i class="bi bi-check-all text-success"></i>
                  <span>Cupón aplicado</span>
                </div>

                <div class="mt-2">
                  <div class="d-flex align-items-center">
                    <div *ngIf="!product.displayQuantityComponent" class="d-flex align-items-center me-2">
                      <div class="nav-item dropup">
                        <button class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          {{selectedQuantity[i]}}
                        </button>
                        <ul class="dropdown-menu">
                          <li *ngFor="let quantity of product.numbers">
                            <a class="dropdown-item" (click)="selectQuantity(i, quantity)">{{ quantity }}</a>
                          </li>
                          <hr *ngIf="product.MaxToPurchase > 9" class="my-0">
                          <li *ngIf="product.MaxToPurchase > 10" (click)="openQuantityComponent(i, true)">
                            <a class="dropdown-item">10+ </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div *ngIf="product.displayQuantityComponent" class="d-flex align-items-center"
                      id="quantity-custom-amount">
                      <input [(ngModel)]="product.quantityByComponent" type="number" class="form-control input-quantity"
                        id="quantity-product-input" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                        aria-describedby="emailHelp" [max]="product?.MaxToPurchase" [min]="1">
                      <button (click)="openQuantityComponent(i,false)" type="submit"
                        class="btn btn-outline-secondary ms-1">
                        <i class="bi bi-plus-lg"></i>
                      </button>
                    </div>

                    <div class="d-flex align-items-center me-2">
                      <div class="nav-item dropup">
                        <button  *ngIf="product.SelectedAttributes.length > 1" class="btn btn-outline-secondary dropdown-toggle dropdown-product">
                          {{ selectedUnits[i] }}
                        </button>

                        <button *ngIf="product.SelectedAttributes.length == 1 || product.SelectedAttributes.length == 0 "  class="btn btn-outline-secondary   dropdown-product">
                          {{ selectedUnits[i] }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div *ngFor="let v of ValidationsMessage">
                    <span class="d-block text-error" *ngIf="product.LocalCartProductId === v.LocalProductId && ValidationsMessage.length > 0">
                      Revisar producto
                    </span>
                  </div>

                  <div *ngFor="let pi of productInInventory; let inventoryIndex = index">
                    <span class="d-block text-error" *ngIf="!pi.ThereIsInventory && shouldDisplayMessage(pi, product)">
                      Para este producto contamos con {{pi.InventoryQuantity > 0 ? pi.InventoryQuantity : 0 }} en inventario
                    </span>
                  </div>

                  <span class="d-block text-error" *ngIf="showProductOutOfTime && !product.IsCurrentlyTimeAvailable">
                    Producto fuera de horario
                  </span>

                  <div class="pt-2">
                    <div class="" *ngIf="!reminder[i]">
                      <small>
                        <a class="text-decoration-none cursor-pointer" (click)="ShowModalRemember(product)">
                          <i class="bi bi-plus-lg pe-1"></i>
                          Crear recordatorio
                        </a>
                      </small>
                    </div>
                    <div class="" *ngIf="reminder[i]">
                      <small>
                        <a class="text-decoration-none cursor-pointer" (click)="ShowModalRemember(product)">
                          <i class="bi bi-pencil pe-1"></i>
                          Editar recordatorio
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--orden-->
      <div class="col-12 col-lg-4">
        <div class="card border-color">
          <div class="order-payment">
            <div class="card-body">

              <!-- <form [formGroup]="form" (ngSubmit)="addCoupon()">
                <div class="d-flex mb-2">
                  <input type="text" class="form-control login-input border-color" id="cupon" formControlName="cupon"
                    placeholder="Código promocional">
                  <button class="btn btn-outline-secondary ms-1" type="submit">Aplicar</button>
                </div>
                <div *ngIf="cupon!.invalid && (cupon!.dirty || cupon!.touched || this.isSubmited)" class="text-danger">
                  <span *ngIf="cupon!.errors?.required">{{validationMsg['required']}}</span>
                  <span *ngIf="cupon!.errors?.minlength">{{validationMsg['minlength'].replace("XXX", cupon!.errors!.minlength.requiredLength)}}</span>
                </div>
              </form> -->

              <div class="mb-2" *ngIf="coupons.length > 0">
                <small class="fw-medium">Cupones aplicados</small>
                <div class="d-flex justify-content-between align-items-center" *ngFor="let coupon of coupons">
                  <div class="d-flex align-items-center">
                    <!-- <i class="bi bi-x text-danger pe-2" (click)="deleteCoupon(coupon)"></i> -->
                    <small>{{coupon.Code}}</small>
                  </div>
                </div>
              </div>
              <hr>

              <div class="d-flex justify-content-between">
                <span class="card-text">Subtotal</span>
                <span>{{subTotalPriceCart | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">Descuento</span>
                <span>{{discount | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">I.V.A</span>
                <span>{{taxes | mycurrency}}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <h3 class="card-text">Total</h3>
                <h3>{{totalPriceCart | mycurrency}} i.v.a.i.</h3>
              </div>

            </div>
          </div>

        </div>

        <div class="d-grid my-2">

          <button class="btn btn-primary" type="button" (click)="redirectToOrden()">
            Realizar compra
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!-- <div class="color-section" *ngIf="impulseProduct.length > 0">
      <div class="container">

        <div class="d-flex align-items-center justify-content-between">

          <h2 class="mb-0"> Productos recomendados</h2>
          <a class="link pb-2"> Conocer más </a>
        </div>
        <div class="cover pt-3">
          <button class="left btn btn-scroll" onclick="scrollrecommendedLeft()">
            <i class="bi bi-chevron-left"></i>
          </button>

          <div
            class="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 g-2 scroll-images carousel-recommended">

            <div class="col mb-2" *ngFor="let p of impulseProduct; let i = index">
              <div class="card h-100 px-3">
                <div class="product-card-content">
                  <div class="img-container-item">
                    <img src="{{p.ProductImages[0].WebPath}}" (click)="goToDetailProduct(p.URL)">
                  </div>
                  <span class="badge discount-badge" *ngIf="p.PercentDiscountRate > 0">
                    {{p.PercentDiscountRate}}%
                  </span>
                </div>

                <div class="card-body p-0">
                  <div>
                    <h3 class="mb-0 title-card-banner">{{p.Name}}</h3>
                  </div>

                  <div class="py-2" *ngIf="p.ProductAttributes && p.ProductAttributes.length > 0">
                    <span class="banner-price text-nowrap"
                      *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades')  && p.ProductAttributes[0].Name === 'Unidad'">
                      {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                    </span>
                    <span class="banner-price text-nowrap"
                      *ngIf="(selectedUnits[i] === 'Caja'  || selectedUnits[i] === 'Cajas') && p.ProductAttributes">
                      {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                    </span>

                    <span class="banner-discount text-nowrap"
                      *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.ProductAttributes[0].PriceWithDiscount > 0">
                      {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                    </span>
                    <span class="banner-discount text-nowrap"
                      *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes.length > 1 && p.ProductAttributes[1].PriceWithDiscount > 0">
                      {{p.ProductAttributes[1].PriceWithTaxes * selectedQuantity[i]| mycurrency }} i.v.a.i
                    </span>
                    <span class="banner-discount text-nowrap"
                      *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes[0].Name === 'Caja' && p.ProductAttributes[0].PriceWithDiscount > 0">
                      {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                    </span>
                  </div>
                </div>

                <div class="py-2" *ngIf=" p.ProductAttributes.length  == 0">
                  <span class="banner-price text-nowrap"
                    *ngIf="selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades' ">
                    {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                  </span>

                  <span class="banner-discount text-nowrap"
                    *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.PriceWithDiscount > 0">
                    {{p.PriceWithDiscount| mycurrency }} i.v.a.i
                  </span>

                  <span class="banner-price text-nowrap"
                    *ngIf="selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas' ">
                    {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                  </span>

                  <span class="banner-discount text-nowrap"
                    *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.PriceWithDiscount > 0">
                    {{p.PriceWithDiscount| mycurrency }} i.v.a.i
                  </span>
                </div>

                <div class="product-card-footer d-flex justify-content-start">

                  <div *ngIf="!p.displayQuantityComponent" class="d-flex align-items-center me-2">
                    <div class="nav-item dropup">
                      <button class="btn btn-outline-secondary dropdown-toggle dropdown-product btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ selectedQuantity[i] }}
                      </button>
                      <ul class="dropdown-menu number-dropdown">
                        <li *ngFor="let quantity of p.numbers">
                          <a class="dropdown-item" (click)="selectQuantity(i, quantity)">{{ quantity }}</a>
                        </li>
                        <hr *ngIf="this.p.MaxToPurchase > 9" class="my-0">
                        <li *ngIf="this.p.MaxToPurchase > 10" (click)="openQuantityComponent(i,true)">
                          <a class="dropdown-item">10+</a>
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex align-items-center me-2">
                      <div class="nav-item dropup">
                        <button class="btn btn-outline-secondary dropdown-toggle dropdown-product btn-sm" type="button"
                          data-bs-toggle="dropdown" aria-expanded="false"
                          [ngClass]="{'btn-outline-secondary': true, 'dropdown-toggle': true, 'dropdown-product': true}">
                          {{ selectedUnits[i] }}
                        </button>
                        <ul class="dropdown-menu option-dropdown">
                          <li *ngFor="let item of p.ProductAttributes">
                            <a class="dropdown-item" (click)="selectType(i,item.Name)">{{ getPluralizedName(item.Name,
                              selectedQuantity[i]) }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <button type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Agregar al carrito" (click)="addProductToCart(p, selectedUnits[i], selectedQuantity[i], selectedUnitPrice[i])">
                        <i class="bi bi-cart-plus-fill"></i>
                      </button>
                    </div>
                  </div>

                  <div *ngIf="p.displayQuantityComponent" class="d-flex align-items-center d-none" id="quantity-custom-amount">
                    <input [(ngModel)]="p.quantityByComponent" type="number" class="form-control input-quantity" id="quantity-product-input" onkeydown="javascript: return event.keyCode == 69 ? false : true" aria-describedby="emailHelp" [max]="p?.MaxToPurchase" [min]="1">
                    <button (click)="openQuantityComponent(i,false)" type="submit" class="btn btn-outline-secondary ms-1">
                      <i class="bi bi-plus-lg"></i>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <button class="right btn btn-scroll" onclick="scrollrecommendedRight()">
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
      </div>
    </div> -->

    <app-modal-remember
      [(showModalRemember)]="showModalRemember"
      (callHideModalRemember)="hideModalRemember()"
      (callRefreshReminders)="refreshReminders()"
      [modalData]="modalData"
      [selectedReminder]="this.selectedReminder"
      [clientID]="this.identificationUser"
      [articleSku]="this.selectedSku"
      [addToFavourite]="true"
      [userId]="this.userId"
      [product]="selectedProduct">
    </app-modal-remember>

    <app-modal-general [showModal]="showModalCupon" (showModalChange)="closeModal($event)"
      [modalData]="modalCupon"></app-modal-general>
    <app-modal-general [showModal]="showModalMissingProduct" (showModalChange)="closeModalProductNotAvailable($event)"
      [modalData]="modalProductNotAvailable"></app-modal-general>
    <app-modal-delete-cart #modalDelete [showModal]="showDelete" (closeModal)="closeModalDelete($event)"
      [cartId]="cartId" [modalData]="modalDeleteCartCheckout"></app-modal-delete-cart>
    <app-modal-general [showModal]="showModalMaxPurchase" (showModalChange)="closeModalMaxPurchase($event)"
      [modalData]="modalMax"></app-modal-general>

  </div>
</main>



<main class="main" *ngIf="this.productEmpty == 'E'">
  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="color-primary">Carrito de compras </h1>

    <div class="container my-4">
      <div class="row row-cols-auto stepper">
        <div class="col content-col active cursor-pointer">
          <a>
            <span>
              <i class="bi bi-cart"></i>
            </span>
          </a>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col cursor-pointer">
          <a>
            <span>
              <i class="bi bi-truck"></i>
            </span>
          </a>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col cursor-pointer">
          <a>
            <span>
              <i class="bi bi-credit-card"></i>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8">

        <div class="d-flex align-items-center justify-content-end">
          <a href="" class="fw-medium text-decoration-none" type="button" data-bs-toggle="modal"
            data-bs-target="#modalDelete">
            Vaciar carrito
          </a>
        </div>

        <!-- Carrito de compras 1-->
        <div class="card mb-2 border-color" *ngFor="let product of productsCart; let i = index">
          <div class="row g-0">
            <div class="col-2 mb-0 d-flex align-items-center justify-content-center">
              <div class="img-favorite-product">
                <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(product.URL)">
                <img [src]="product.Images[0].WebPath" alt="" (click)="goToDetailProduct(product.URL)">
                </a>
              </div>
            </div>
            <div class="col-10">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(product.URL)">
                  <h5 class="card-title mb-0">{{product.Name}}</h5>
                  </a>
                  <a (click)="removeProductFromCart(product, i)" class="cursor-pointer">
                    <i class="bi bi bi-trash delete-product"></i>
                  </a>
                </div>
                <p class="fs-x-small mb-2">SKU {{product.SelectedAttributes[0]?.Sku3 ? product.SelectedAttributes[0]?.Sku3 : product.Sku}}</p>
                <app-modal-producto-faltante #modalProductoFaltante [showModal]="showModalProductoFaltante"
                  (closeModal)="closeMissingPorduct($event)" [cartId]="cartId" [userId]="userId"
                  [product]="selectedProduct" [productInventory]="productInventory"></app-modal-producto-faltante>

                <div class="d-flex align-items-end justify-content-star">
                  <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(product.URL)">
                  <span class="product-price text-nowrap"> {{selectedUnitPrice[i] | mycurrency}} i.v.a.i</span>
                  <span class="product-discount ms-2 text-nowrap"
                    *ngIf="product.PriceWithDiscount > 0">{{product.CurrentTotalPrice | mycurrency}} i.v.a.i</span>
                  </a>
                </div>

                <div class="d-flex align-items-center" *ngIf="product.CouponApplied">
                  <i class="bi bi-check-all text-success"></i>
                  <span>Cupón aplicado</span>
                </div>

                <div class="mt-2">
                  <div class="d-flex align-items-center">
                    <div *ngIf="!product.displayQuantityComponent" class="d-flex align-items-center me-2">
                      <div class="nav-item dropup">
                        <button class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          {{selectedQuantity[i]}}
                        </button>
                        <ul class="dropdown-menu">
                          <li *ngFor="let quantity of product.numbers">
                            <a class="dropdown-item" (click)="selectQuantity(i, quantity)">{{ quantity }}</a>
                          </li>
                          <hr *ngIf="product.MaxToPurchase > 9" class="my-0">
                          <li *ngIf="product.MaxToPurchase > 10" (click)="openQuantityComponent(i, true)">
                            <a class="dropdown-item">10+ </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div *ngIf="product.displayQuantityComponent" class="d-flex align-items-center"
                      id="quantity-custom-amount">
                      <input [(ngModel)]="product.quantityByComponent" type="number" class="form-control input-quantity"
                        id="quantity-product-input" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                        aria-describedby="emailHelp" [max]="product?.MaxToPurchase" [min]="1">
                      <button (click)="openQuantityComponent(i,false)" type="submit"
                        class="btn btn-outline-secondary ms-1">
                        <i class="bi bi-plus-lg"></i>
                      </button>
                    </div>

                    <div class="d-flex align-items-center me-2">
                      <div class="nav-item dropup">
                        <button  *ngIf="product.SelectedAttributes.length > 1" class="btn btn-outline-secondary dropdown-toggle dropdown-product">
                          {{ selectedUnits[i] }}
                        </button>

                        <button *ngIf="product.SelectedAttributes.length == 1 || product.SelectedAttributes.length == 0 "  class="btn btn-outline-secondary   dropdown-product">
                          {{ selectedUnits[i] }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div *ngFor="let v of ValidationsMessage">
                    <span class="d-block text-error" *ngIf="product.LocalCartProductId === v.LocalProductId && ValidationsMessage.length > 0">
                      Revisar producto
                    </span>
                  </div>

                  <div *ngFor="let pi of productInInventory; let inventoryIndex = index">
                    <span class="d-block text-error" *ngIf="!pi.ThereIsInventory && shouldDisplayMessage(pi, product)">
                      Para este producto contamos con {{pi.InventoryQuantity > 0 ? pi.InventoryQuantity : 0 }} en inventario
                    </span>
                  </div>

                  <span class="d-block text-error" *ngIf="showProductOutOfTime && !product.IsCurrentlyTimeAvailable">
                    Producto fuera de horario
                  </span>

                  <div class="pt-2">
                    <div class="" *ngIf="!reminder[i]">
                      <small>
                        <a class="text-decoration-none cursor-pointer" (click)="ShowModalRemember(product)">
                          <i class="bi bi-plus-lg pe-1"></i>
                          Crear recordatorio
                        </a>
                      </small>
                    </div>
                    <div class="" *ngIf="reminder[i]">
                      <small>
                        <a class="text-decoration-none cursor-pointer" (click)="ShowModalRemember(product)">
                          <i class="bi bi-pencil pe-1"></i>
                          Editar recordatorio
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--orden-->
      <div class="col-12 col-lg-4">
        <div class="card border-color">
          <div class="order-payment">
            <div class="card-body">

              <!-- <form [formGroup]="form" (ngSubmit)="addCoupon()">
                <div class="d-flex mb-2">
                  <input type="text" class="form-control login-input border-color" id="cupon" formControlName="cupon"
                    placeholder="Código promocional">
                  <button class="btn btn-outline-secondary ms-1" type="submit">Aplicar</button>
                </div>
                <div *ngIf="cupon!.invalid && (cupon!.dirty || cupon!.touched || this.isSubmited)" class="text-danger">
                  <span *ngIf="cupon!.errors?.required">{{validationMsg['required']}}</span>
                  <span *ngIf="cupon!.errors?.minlength">{{validationMsg['minlength'].replace("XXX", cupon!.errors!.minlength.requiredLength)}}</span>
                </div>
              </form> -->

              <div class="mb-2" *ngIf="coupons.length > 0">
                <small class="fw-medium">Cupones aplicados</small>
                <div class="d-flex justify-content-between align-items-center" *ngFor="let coupon of coupons">
                  <div class="d-flex align-items-center">
                    <!-- <i class="bi bi-x text-danger pe-2" (click)="deleteCoupon(coupon)"></i> -->
                    <small>{{coupon.Code}}</small>
                  </div>
                </div>
              </div>
              <hr>

              <div class="d-flex justify-content-between">
                <span class="card-text">Subtotal</span>
                <span>{{subTotalPriceCart | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">Descuento</span>
                <span>{{discount | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">I.V.A</span>
                <span>{{taxes | mycurrency}}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <h3 class="card-text">Total</h3>
                <h3>{{totalPriceCart | mycurrency}} i.v.a.i.</h3>
              </div>

            </div>
          </div>

        </div>

        <div class="d-grid my-2">

          <button class="btn btn-primary" type="button" (click)="redirectToOrden()">
            Realizar compra
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>




  </div>
</main>




