import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/shared/loading.service';

@Component({
  selector: 'app-unsubscription',
  templateUrl: './unsubscription.component.html',
  styleUrls: ['./unsubscription.component.css']
})
export class UnsubscriptionComponent {
  urlBase= environment.urlBase;
  constructor(private router: Router,    public loader: LoadingService,) { }

  redirectToContactUs(index:any) {
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
      this.router.navigate(['contactanos']); // Reemplaza '/' con la ruta de tu página de inicio
      window.scrollTo(0, 0); // Esto desplazará la página hacia la parte superior
      this.loader.hideSpinner();
  }, 800);
  }
  activeItemIndex: number | null = null;
  setActiveItem(index: number): void {
    this.activeItemIndex = index;
  }

}
