<div class="modal fade bg-white" id="modalProcesandoPago" #modalProcesandoPago tabindex="-1" aria-labelledby="modalProcesandoPagoLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-payment modal-style">
      <div class="modal-header pb-0 border-0">
      </div>

      <div class="modal-body text-center pt-0">
        <div class="text-center modal-spinner">
          <span class="loader-circular"></span>
        </div>
        <h1 class="modal-title pb-2" id="modalProcesandoPagoLabel">Procesando</h1>
        <h2 class="text-order">Por favor no cerrés la ventana de tu navegador</h2>
        <p>
          Estamos en proceso de verificar y autenticar tu pago. 
          Durante este tiempo, es posible que tu pantalla se quede en blanco.  
          Te pedimos amablemente que no cerrés la ventana de tu navegador ni actualicés la página mientras llevamos a cabo la transacción.
        </p>
        <p><strong>Apreciamos tu paciencia y comprensión.</strong></p>
      </div>

      <div class="modal-footer justify-content-between border-0 pt-0">
        <!-- <p class="text-footer-modal">Codigo de error</p> -->
      </div>
    </div>
  </div>
</div>

<iframe id="myIframe" [src]="urlParaIframe" width="600" height="400" ></iframe>


