<!-- Modal recordar producto-->
<div class="modal fade modal-overlay-light" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="modalOneProductLabel"
  aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered justify-content-center">
    <div class="modal-content modal-molecule modal-style modal-background">
      <div class="modal-header border-0">
        <h2 class="modal-title fs-5 text-white" id="modalOneProductLabel">Te recomendamos este producto
        </h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div class="card card-costum-shadow h-100 px-4" *ngIf="recommendedProduct != null">
          <div class="product-card-content">
            <div class="img-container-item">
              <img
                [src]="recommendedProduct.ProductImages.length > 0 ? recommendedProduct.ProductImages[0].WebPath : urlBase +'/assets/img/img-default.jpg'"
                loading="lazy" alt="…">
            </div>
            <span *ngIf="recommendedProduct.PercentDiscountRate > 0" class="badge discount-badge discount-animation">
              {{recommendedProduct.PercentDiscountRate}}%
            </span>
          </div>

          <div>
            <h3 class="mb-0 title-card-product">{{ recommendedProduct.Name }}
            </h3>
          </div>

          <div   class="d-flex align-items-end justify-content-start py-3">
            <span class="product-price text-nowrap" *ngIf="recommendedProduct.ProductAttributes.length>0">
              {{
                  (recommendedProduct.ProductAttributes[0]?.PriceWithDiscount>0?recommendedProduct.ProductAttributes[0]?.PriceWithDiscount:recommendedProduct.ProductAttributes[0]?.PriceWithTaxes.toFixed(2)) | mycurrency
              }}
              i.v.a.i
            </span>
            <span class="product-price text-nowrap" *ngIf="recommendedProduct.ProductAttributes.length==0">
              {{
                  (recommendedProduct?.PriceWithDiscount>0?recommendedProduct?.PriceWithDiscount:recommendedProduct?.PriceWithTaxes.toFixed(2)) | mycurrency
              }}
              i.v.a.i
            </span>


            <span class="product-discount ms-2 text-nowrap" *ngIf="recommendedProduct.ProductAttributes.length>0 && recommendedProduct.ProductAttributes[0]?.PriceWithDiscount>0">
              {{
                recommendedProduct.ProductAttributes[0]?.PriceWithTaxes.toFixed(2) | mycurrency
              }}
              i.v.a.i
            </span>
            <span class="product-discount ms-2 text-nowrap" *ngIf="recommendedProduct.ProductAttributes.length==0 && recommendedProduct?.PriceWithDiscount>0">
              {{
                recommendedProduct?.PriceWithTaxes.toFixed(2) | mycurrency
              }}
              i.v.a.i
            </span>
          </div>



        </div>

      </div>
      <div class="modal-footer border-0">
        <div>

        </div>
        <a class="btn-text-modal me-3" data-bs-dismiss="modal" (click)="continue()">Continuar</a>
        <a class="btn-yellow" data-bs-dismiss="modal" (click)="viewProduct()">Ver producto</a>
      </div>
    </div>
  </div>
</div>
