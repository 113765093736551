
import { Component, HostListener } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf } from '@angular/common';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';

@Component({
  selector: 'app-notification',
	standalone: true,
	imports: [NgbToastModule, NgIf, CommonModule],
  templateUrl: './notification.component.html',
})

export class NotificationComponent {
	show = true;
  _toastService: NotificationService
  _utilitiesService : UtilitiesService
  isHovered: boolean = false;

  constructor( notiService : NotificationService,  utilities : UtilitiesService){
    this._toastService = notiService,
    this._utilitiesService = utilities
  }

  hideToast(): void {
    this.isHovered = false;
    this._toastService.Hide();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.toast-container')) {
      this.isHovered = false;
    }
  }
}


