import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ProductosComponent } from './components/productos/productos.component';
import { APP_BASE_HREF } from '@angular/common';

import { BackendComponent } from './components/backend/backend.component';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { LegalInformationComponent } from './components/legal-information/legal-information.component';
import { PharmaciesComponent } from './components/pharmacies/pharmacies.component';
import { DetalleProductoComponent } from './components/detalle-producto/detalle-producto.component';
import { IndexComponent } from './components/index/index.component';
import { FAQSComponent } from './components/faqs/faqs.component';
import { InsurersComponent } from './components/Insurers/Insurers.component';
import { ShopperComponent } from './components/shopper/shopper.component';
import { FrequentPlanComponent } from './components/frequent-plan/frequent-plan.component';
import { BlogsListComponent } from './components/blogs-list/blogs-list.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { AppSocialMediaComponent } from './components/app-social-media/app-social-media.component';
import { CheckoutCartComponent } from './components/checkout-cart/checkout-cart.component';
import { CheckoutPaymentComponent } from './components/checkout-payment/checkout-payment.component';
import { CheckoutShippingComponent } from './components/checkout-shipping/checkout-shipping.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UnsubscriptionComponent } from './components/unsubscription/unsubscription.component';
import { PaymentProcesingComponent } from './components/payment-procesing/payment-procesing.component';
import { CheckoutEndComponent } from './components/checkout-end/checkout-end.component';
import { AuthGuard } from './services/shared/auth.guard';

  const routes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'index', redirectTo: '/', pathMatch: 'full' },
    { path: '404', component: PageNotFoundComponent }, // Ruta de error 404
    { path: 'iniciosesion', component: LoginComponent, data: {source: '', code: ''} },
    { path: 'micuenta', component: MyAccountComponent },
    { path: 'categorias/:url', component: ProductosComponent, data: { source: '' } },
    { path: 'busqueda', component: ProductosComponent , data: { source: 'search' }  },
    // { path: 'backend', component: BackendComponent },
    { path: 'registro', component: SignUpComponent },
    { path: 'informacion-legal', component: LegalInformationComponent },
    { path: 'farmacias', component: PharmaciesComponent },
    { path: 'desinscripcion', component: UnsubscriptionComponent },
    { path: 'blogs', component: BlogsListComponent },
    { path: 'blogs/:url', component: BlogDetailComponent },
    { path: 'contactanos', component: ContactUsComponent },
    { path: 'detalle-producto', component: DetalleProductoComponent },
    { path: 'preguntas-frecuentes', component: FAQSComponent },
    { path: 'aseguradoras', component: InsurersComponent },
    { path: 'planpaciente', component: FrequentPlanComponent, data: {code: ''}},
    { path: 'plan-frecuente', component: FrequentPlanComponent, data: {code: ''}},
    { path: 'detalle-producto/:url', component: DetalleProductoComponent },
    { path: 'shopper', component: ShopperComponent },
    { path: 'recuperar-contrasena', component: PasswordRecoveryComponent },
    { path: 'cambiocontrasena', component: PasswordChangeComponent },
    { path: 'app', component: AppSocialMediaComponent },
    { path: 'orden', component: CheckoutShippingComponent },
    { path: 'payment', component: CheckoutPaymentComponent },
    { path: 'proceso-compra', component: CheckoutCartComponent, data: { show: false, showMaxPurchase: false, showProductOutOfTime: false }},
    { path: 'procesando-compra', component: PaymentProcesingComponent, data :{idOrder: 0, cartId: '', clientId: '', name: '', lifeMiles: '', enviroment: '', status: false}},
    { path: 'proceso-finalizado', component: CheckoutEndComponent, data : {source: false, open: true}},
    { path: '**', redirectTo: '/404' }
  ]

  const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  };

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }
  ]
})
export class AppRoutingModule {
}
