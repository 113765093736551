<main class="main">
  <div class="container mt-3 mt-lg-5">
    <h1 class="color-primary">Plan Paciente</h1>
    <div class="text-plan my-3">
      <p>Por la compra de tus tratamientos mensuales podés obtener una regalía totalmente gratis. Verificá
        ahora si tu tratamiento cuenta con Plan Paciente, o preguntanos a través del chat y con gusto te
        asesoramos.
      </p>
      <p>*Aplican términos y condiciones establecidos por el laboratorio dueño del programa.</p>
    </div>

    <div class="">
      <div class="input-group mb-3" style="max-width: 400px;">
        <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
          style="max-width: 100%;" [(ngModel)]="searchValue" (input)="searchProduct()">
        <button class="btn btn-primary button-plan" type="button" id="button-addon2">
          <i class="bi bi-search"></i>
        </button>
      </div>
    </div>

    <!-- Opcion 1x1-->
    <!-- <div class="mt-2 d-flex justify-content-between align-items-center" *ngIf="products.length > 0">
      <h3 class="text-plan">Comprá 1, recibí 1 gratis</h3>
      <a href="" class="mb-2">Conocer más</a>
    </div> -->

    <div class="row row-cols-1 row-cols-md-4 g-4 mb-5">
      <div class="col" *ngFor="let p of products.slice(0, countsProducts); let i=index">
        <div class="card card-costum-shadow h-100 px-4">
          <div class="product-card-content">
            <div class="img-container-item">
              <img class="image" [src]="p.Images[0]?.webPath" loading="lazy" alt="…" routerLink="/detalle-producto/{{p.url}}">
            </div>
            <div type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#planModal' + i">
              <span class="badge plan-badge">
                <i class="bi bi-info-lg"></i>
              </span>
            </div>
          </div>

          <div>
            <h5>{{p.description}}</h5>
          </div>

          <div class="product-card-footer text-start">
            <button type="button" class="btn btn-primary" (click)="ShowConfirmation(p)">Canjear</button>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade modal-overlay-light" [id]="'planModal' + i" tabindex="-1" aria-labelledby="planModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-background modal-style">
              <div class="modal-header border-0">
                <h2 class="modal-title fs-5 text-white" id="exampleModalLabel">Plan paciente</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>
                  {{p.customerMessage}}
                </p>
                <div class="text-center pt-4">
                  <img src="https://farmacialabomba.com/LaBombaResources/img/logos/logo-blanco.svg" alt="" class="img-modal-plan-frecuente">
                </div>
              </div>
              <div class="justify-content-between px-2" *ngIf="error">
                <!-- <p class="text-footer-modal px-2">Codigo de error:</p> -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div style="text-align: center;" *ngIf="countsProducts < products.length">
      <button type="button" class="btn btn-primary" style="text-align: center;" (click)="loadProducts()">Cargar
        más</button>
    </div>
  </div>

  <div class="text-center mt-5" *ngIf="products.length === 0 && searched">
    <i class="bi bi-search icon-page"></i>
    <h1 class="">Sin resultados</h1>
    <p class="">No se encontraron productos.</p>
  </div>


  <app-modal-general [showModal]="this.showModalConfirmExchange" (showModalChange)="ModalConfirmExchangeConfirmation($event)"
  [modalData]="modalConfirmExchange">
  </app-modal-general>

  <app-modal-general [showModal]="this.showModalExchangeResult" (showModalChange)="ModalExchangeResultConfirmation($event)"
  [modalData]="modalExchangeResult">
  </app-modal-general>

</main>
