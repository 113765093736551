import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ICanton } from 'src/app/models/canton.model';
import { IAvailability, IPharmacie } from 'src/app/models/pharmacie.model';
import { IProvince } from 'src/app/models/provinces.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { ShopsService } from 'src/app/services/core/shops.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { environment } from 'src/environments/environment';
import { IMaker } from '../../models/maker.model';

const settings = new SitefinitySettings();
@Component({
  selector: 'app-pharmacies',
  templateUrl: './pharmacies.component.html',
  styleUrls: ['./pharmacies.component.css']
})
export class PharmaciesComponent implements OnInit {
  urlBase = environment.urlBase;
  provinces: IProvince[] = [];
  selectedProvince: any = null;

  cantons: ICanton[] = [];
  selectedCanton: any = null;

  pharmacies: IPharmacie[] = [];
  pharmaciesList: IPharmacie[] = [];
  selectedPharmacie: any = null;

  // CONFIGURACION DEL MAPA
  myLatLng = { lat: settings.latDefault, lng: settings.lngDefault }; // Map Options
  mapOptions: google.maps.MapOptions = {
    center: this.myLatLng,
    zoom: settings.zoom,
    streetViewControl: false
  };

  // ICONO Y PROPIEDADES DEL MAPA
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: `${environment.urlBase}${settings.iconMaker}`,
    animation: google.maps.Animation.DROP
  };

  // PUNTOS EN EL MAPA
  spots: IMaker[] = [];

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | any;
  infoContent = '';

  constructor(
    private shopsService: ShopsService,
    private utilitiesService: UtilitiesService,
    private loader: LoadingService,
    private sitefinityPagesService: SitefinitypagesService) {
    this.selectedProvince = 0;
    this.selectedCanton = 0;
    this.selectedPharmacie = 0;
    this.sitefinityPagesService.setDetailPage(settings.pages.find(x => x.Title === 'Farmacias')!.ID);
  }

  ngOnInit(): void {
    this.getProvinces();
    this.getAllPharmacies();
  }

  private getProvinces() {
    this.loader.showLoadingBar();
    this.utilitiesService.getProvinces().subscribe(res => {
      this.provinces = JSON.parse(res.Parameters);
      this.loader.hideLoadingBar();
    }, error => {
      this.loader.hideLoadingBar();
    });
  }

  onChangeProvince(event: any) {
    this.loader.showLoadingBar();
    this.utilitiesService.GetCantonsByIDProvince(this.selectedProvince.toString()).subscribe(res => {
      this.selectedCanton = 0;
      this.selectedPharmacie = 0;
      this.cantons = [];
      this.pharmacies = [];
      this.spots = [];
      this.cantons = JSON.parse(res.Parameters);
      if (this.cantons.length === 1) {
        this.selectedCanton = this.cantons[0].IDAddressCanton;
      }
      this.mapOptions = {
        center: this.myLatLng,
        zoom: settings.zoom,
        streetViewControl: false
      };
      this.loader.hideLoadingBar();
      this.onChangeCanton(null);
    });
  }

  onChangeCanton(event: any) {
    this.loader.showLoadingBar();
    this.shopsService.GetShopsByShopType([], this.selectedProvince, this.selectedCanton, 0).subscribe(res => {
      this.selectedPharmacie = 0;
      const pharmaciesSort = JSON.parse(res.Parameters);
      this.pharmacies = pharmaciesSort.sort((a: any, b: any) => a.Name.localeCompare(b.Name));
      this.pharmaciesList = [];
      this.pharmaciesList = this.pharmacies;
      if (this.pharmacies.length > 0) {
        this.spots = [];
        this.pharmacies.forEach((element: IPharmacie) => {
          this.spots.push({
            lat: element.ShopMapPoints.Latitude,
            lng: element.ShopMapPoints.Longitude,
            pharmacie: element,
          });
        });
        if (this.selectedCanton === 0) {
          this.mapOptions = {
            center: this.myLatLng,
            zoom: settings.zoom,
            streetViewControl: false
          };
        } else {
          this.mapOptions = {
            center: { lat: this.pharmacies[0].ShopMapPoints.Latitude, lng: this.pharmacies[0].ShopMapPoints.Longitude },
            zoom: settings.zoomCanton,
            streetViewControl: false
          };
        }

        if (this.pharmacies.length === 1) {
          this.onChangePharmacie(this.pharmacies[0]);
          this.selectedPharmacie = this.pharmacies[0];
        }
      }
      this.loader.hideLoadingBar();
    });
  }

  onChangePharmacie(event: any) {
    if (event === 0) {
      this.onChangeCanton(null);
      return;
    }
    this.spots = [];
    // Seteo posicion en el mapa
    this.mapOptions = {
      center: {
        lat: event.lat ?? event.ShopMapPoints.Latitude,
        lng: event.lng ?? event.ShopMapPoints.Longitude,
      },
      zoom: settings.zoomFarmacia
    };

    // Seteo punto en el mapa
    this.spots.push({
      lat: event.lat ?? event.ShopMapPoints.Latitude,
      lng: event.lng ?? event.ShopMapPoints.Longitude,
      pharmacie: event.pharmacie ?? event,
    });
    this.pharmaciesList = [];
    this.pharmaciesList.push(event);
  }

  getAllPharmacies() {
    this.loader.showLoadingBar();
    this.shopsService.GetShopsByShopType([], this.selectedProvince, this.selectedCanton, 0).subscribe(res => {
      this.selectedPharmacie = 0;
      const pharmaciesSort = JSON.parse(res.Parameters);
      this.pharmacies = pharmaciesSort.sort((a: any, b: any) => a.Name.localeCompare(b.Name));
      this.pharmaciesList = [];
      this.pharmaciesList = this.pharmacies;
      if (this.pharmacies.length > 0) {
        this.spots = [];
        this.pharmacies.forEach((element: IPharmacie) => {
          this.spots.push({
            lat: element.ShopMapPoints.Latitude,
            lng: element.ShopMapPoints.Longitude,
            pharmacie: element
          });
        });
      }
      this.loader.hideLoadingBar();
    });
  }

  openInfo(marker: any, content: any) {
    this.renderInfoContent(content.pharmacie);
    this.infoWindow.open(marker);
  }

  renderInfoContent(pharmacie: any) {
    this.infoContent = `<h3>${pharmacie.Name}</h3>`;
    if (pharmacie.Address) {
      this.infoContent = this.infoContent.concat(`<h5>${pharmacie.Address}</h5>`);
    }
    if (pharmacie.Availabilities.length > 0) {
      this.infoContent = this.infoContent.concat(`<h4 class="fw-medium">Horario</h4>`);
    }
    pharmacie.Availabilities.forEach((element: any) => {
      if (element.Name.length > 0 && element.IdAvailabilityType === 3) {
        this.infoContent = this.infoContent.concat(`<div>${element.Name}</div>`);
      }
    });
  }

  hasSchedule(list: IAvailability[]) {
    if (list.length === 0) {
      return false;
    }
    return list.some(x => x.IdAvailabilityType === 3 && x.Alias !== null);
  }
}
