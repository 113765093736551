<div [ngClass]="{'show': showModal}" class="modal fade" #exampleModal id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title fs-5 color-primary" id="exampleModalLabel">Creá tu contraseña</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body" [innerHTML]="message"></div>

        <div class="modal-footer justify-content-between">
          <div>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Aceptar</button>
          </div>
        </div>
    </div>
  </div>
</div>
