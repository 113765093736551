import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CleanService {
  private resetFormSubject = new Subject<void>();

  resetFormObservable = this.resetFormSubject.asObservable();
  constructor() { }

  resetForm() {
    this.resetFormSubject.next();
  }
}
