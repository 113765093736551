export const environment = {
  name: 'developmentV2',
  urlBase: 'https://newlabombadev15.loymarkservices.com/sitefinity/public/renderers/angular',
  SitefinityServicesUrl: 'https://newlabombadev15.loymarkservices.com/',
  SitefinityServicesDoc: 'https://newlabombadev15.loymarkservices.com',
  urlSite: 'https://newlabombadev15.loymarkservices.com/',
  urlChat: 'https://api.whatsapp.com/send/?phone=50686540000&text&type=phone_number&app_absent=0',
  CoreServicesUrl:'https://coredevflb.loymarkservices.com/WCFLoymarkCart/',
  CoreServicesLealtadUrl:'https://coredevflb.loymark.com/wcffischel/',
  BusDeServiciosUrlDev:'https://devbusbomba.loymarkservices.com/',
  SearchLink:'https://ss-prod-ecommerce-search.search.windows.net/indexes/labomba-productos/docs?api-version=2021-04-30-Preview&search={{word}}&$count=true&queryType=full&$top=100&queryLanguage=es-MX&$select=IdProduct&searchMode=all&&suggesterName=sg&fuzzy=true',
  SearchAutocompleteLink:'https://ss-prod-ecommerce-search.search.windows.net/indexes/labomba-productos/docs/autocomplete?search={{word}}&$top={{top}}&suggesterName=sg&api-version=2021-04-30-Preview&fuzzy=true&searchFields=Name',
  secretKeyUrl : "6XUcvG2bCT0bx5K1"
};

