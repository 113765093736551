import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ClientInteractionObject, InformedConsentFormObject } from 'src/app/models/core/contactus.model';
import { IdType } from 'src/app/models/settings/idType.interface';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { LegalDocuments } from 'src/app/models/sitefinity/type-of-documents.model';
import { ISubject } from 'src/app/models/subject.model';
import { ClientsService } from 'src/app/services/core/clients.service';
import { ContactusService } from 'src/app/services/core/contactus.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { DocumentsService } from 'src/app/services/sitefinity/documents.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { environment } from 'src/environments/environment';

const settings = new SitefinitySettings();
declare var window: any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  document: LegalDocuments = new LegalDocuments();
  form: FormGroup = new FormGroup({});
  termsModal: any;
  isSubmited = false;
  isEditDisabled = true;
  @ViewChild('identificationInput') identificationInput?: ElementRef;
  validationMsg = settings.validationMsgTypeMap;

  typesList: IdType[] = [];
  typeNacional: IdType | any;
  typePassport: IdType | any;
  selectedType: IdType = settings.idTypes.find(x => x.name == 'nacional')!;

  phoneMaxLength = 8;
  termsReaded: boolean = false;
  subjectList: ISubject[] = [];
  requiresInformedConsent = false;
  isUserLoggedIn = false;
  showOkModal = false;
  showCI = false;
  showErrorModal = false;
  mensajesSucces = settings.mensajesContactanos;
  userLogged: any;

  filesToUpload: any[] = [];
  @ViewChild('labelImport') labelImport: ElementRef | undefined;
  urlBase = environment.urlBase;

  titulo = '';
  mensaje = '';

  idClient = 0;
  consentimientoInformadoParameters = {
    NombreCampannaConsentimiento: '',
    codigoFarmacia: ''
  };
  clientPadron: any = null;
  unRegisteredUser: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sitefinityPagesService: SitefinitypagesService,
    private documentService: DocumentsService,
    private loader: LoadingService,
    private clientService: ClientsService,
    public notificationService: NotificationService,
    private utilities: UtilitiesService,
    private contactusService: ContactusService,
    private utilitiesService: UtilitiesServicesService) {

    // Inicializo Form
    this.form = this.fb.group({
      typeidentification: [1],
      identification: [''],
      name: [''],
      firstLastName: [''],
      secondLastName: [''],
      phone: [''],
      email: [''],
      subject: [''],
      message: [''],
      file: [null],
      ofLegalAge: [false, Validators.required]
    });

    this.sitefinityPagesService.setDetailPage(settings.pages.find(x => x.Title === 'contactanos')!.ID);

    this.typesList = [];

    this.isUserLoggedIn = this.utilitiesService.isUserLoggedIn();
    this.scrollToTop();
    const user: any = this.utilitiesService.getValueStorage('sessionStorage');
    if (this.isUserLoggedIn && user) {
      this.getClient(this.utilitiesService.decryptData(user.t));
    } else {
      this.resetNewForm();
      this.form.controls['name'].disable();
      this.form.controls['firstLastName'].disable();
      this.form.controls['secondLastName'].disable();
    }
  }

  get identification() { return this.form.get('identification'); }
  get typeidentification() { return this.form.get('typeidentification'); }
  get name() { return this.form.get('name'); }
  get firstLastName() { return this.form.get('firstLastName'); }
  get secondLastName() { return this.form.get('secondLastName'); }
  get phone() { return this.form.get('phone'); }
  get email() { return this.form.get('email'); }
  get subject() { return this.form.get('subject'); }
  get message() { return this.form.get('message'); }
  get file() { return this.form.get('file'); }
  get ofLegalAge() { return this.form.get('ofLegalAge'); }

  ngOnInit(): void {
    this.getDataService();

    this.form.controls['identification'].setValidators([
      this.identificadorValidator(),
    ]);
  }

  getClient(userId: string) {
    this.clientService.GetUserPersonalInfoMethod(userId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.userLogged = res.responseObject;
        // Obtengo el Consentimiento Informado del usuario
        this.getCIUser(this.userLogged.IDClient, this.userLogged.Identification);
        // Reseteo formulario
        this.resetNewForm();
      }
    });
  }

  getCIUser(idClient: number = 0, identification: string = '') {
    this.clientService.GetReducedClientMethod({ IDClient: idClient, Argument: identification }).subscribe(data => {
      if (data.responseObject) {
        this.requiresInformedConsent = data.responseObject.requiresInformedConsent;
        this.idClient = data.responseObject.idClient;
        this.form.controls['name'].setValue(data.responseObject.name);
        this.form.controls['firstLastName'].setValue(data.responseObject.lastName);
        this.form.controls['secondLastName'].setValue(data.responseObject.secondSurname);

        this.form.controls["name"].disable();
        this.form.controls["firstLastName"].disable();
        this.form.controls["secondLastName"].disable();
        
      } else {
        // Muestro modal para aceptar en CI
        this.requiresInformedConsent = true;
        this.idClient = 0;

        if (this.selectedType.name.toLowerCase() === 'nacional') {
          this.form.controls["name"].disable();
          this.form.controls["firstLastName"].disable();
          this.form.controls["secondLastName"].disable();
          
        } else 
        if (this.selectedType.name.toLowerCase() === 'juridica') {
          this.form.controls["name"].enable();
          this.form.controls["firstLastName"].disable();
          this.form.controls["secondLastName"].disable();
        } else {
          this.form.controls["name"].enable();
          this.form.controls["firstLastName"].enable();
          this.form.controls["secondLastName"].enable();
        }
      }
    });
  }


  /**
   * Desplaza la ventana hasta la parte superior.
   */
  scrollToTop() {
    /**
     * Opciones para desplazar la ventana.
     */
    const opcionesDesplazamiento = {
      top: 0,
      left: 0,
      behavior: 'smooth'
    };

    // Desplaza la ventana utilizando las opciones de desplazamiento
    window.scrollTo(opcionesDesplazamiento);
  }

  /**
   * Obtiene datos del servicio de datos.
   */
  getDataService() {
    forkJoin({
      // Solicitar documentos legales por tipo de ID
      requestOne: this.documentService.GetLegalDocumentsByIdType(
        0, 1, settings.legalDocumentTypeMap['Consentimiento Informado']
      ),
      // Solicitar parámetros por clave
      requestTwo: this.utilities.GetParametersByKey('SubjectList'),
      requestThree: this.utilities.GetParametersByKey('ConsentimientoInformadoParameters'),
      requestIdentificationTypes: this.utilities.GetParametersByKey('identificationTypes'),
    }).subscribe(rqs => {
      if (rqs.requestOne.response) {
        // Establecer el primer documento legal de la respuesta
        this.document = rqs.requestOne.legalDocuments[0];

        if (!this.document || this.document.documentText.trim() == '') {
          this.notificationService.SendNotificationByCode('consent-info-error', '1')
        }

      }

      if (rqs.requestTwo.value) {
        // Analizar la lista de asuntos de la respuesta
        const data: ISubject[] = JSON.parse(this.utilities.decodeData(rqs.requestTwo.value[0].value));
        // Ordenar la lista de asuntos por prioridad
        this.subjectList = data.sort((x, y) => x.priority - y.priority);
      }

      if (rqs.requestThree.value) {
        this.consentimientoInformadoParameters = JSON.parse(rqs.requestThree.value[0].value);
      }

      if (rqs.requestIdentificationTypes.value) {
        // Analizar la lista de asuntos de la respuesta
        const data: IdType[] = JSON.parse(this.utilities.decodeData(rqs.requestIdentificationTypes.value[0].value));
        
        this.typesList = [];
        // Ordenar la lista de asuntos por prioridad
        this.typesList = data.sort((x, y) => +x.sortId - +y.sortId);

        this.typeNacional = this.typesList.find(x => x.name == 'nacional')!;
        this.typePassport = this.typesList.find(x => x.name == 'pasaporte')!;
        this.selectedType = this.typesList.find(x => x.name == 'nacional')!;
      }
    });
  }

  /**
   * Esta función se llama cuando el usuario hace clic en el botón de enviar.
   * Maneja la lógica para enviar un correo electrónico o mostrar el formulario de consentimiento.
   */
  onSend() {
    this.isSubmited = true;

    if (this.form.valid) {
      if (this.requiresInformedConsent || this.unRegisteredUser) {
        // Muestra el formulario de consentimiento informado si no ha sido aceptado por el usuario
        this.showCI = true;
      } else {
        this.loader.showSpinner();
        // Envía el correo electrónico si el consentimiento informado ya ha sido aceptado
        this.sendEmailContactUs();
      }
    }
  }

  /**
   * Reinicia los controles del formulario.
   * @param controlsNames - Una matriz de nombres de controles que se deben reiniciar.
   */
  private resetControls(controlsNames: string[]) {
    // Verifica si se pasó el valor 'all' en el arreglo de nombres de controles
    if (controlsNames.length == 1 && controlsNames[0] == 'all') {
      // Si es así, obtiene todos los nombres de los controles del formulario
      controlsNames = Object.keys(this.form.controls);
      // Reinicia la bandera de envío del formulario
      this.isSubmited = false;
    }

    // Itera sobre cada nombre de control
    controlsNames.forEach(controlName => {
      // Verifica si el control existe en el formulario
      if (this.form.controls[controlName] !== undefined) {
        // Establece el valor del control como una cadena vacía
        this.form.controls[controlName].setValue('');
        this.form.controls[controlName].enable();
        // Marca el control como no tocado
        this.form.controls[controlName].markAsUntouched();
      }
    });
  }

  /**
   * Esta función maneja el evento de pulsación de tecla para el campo de identificación.
   * Verifica si se deben permitir ciertos caracteres dependiendo del tipo de cliente seleccionado.
   *
   * @param event El evento de pulsación de tecla.
   * @returns Un valor booleano que indica si se debe permitir la pulsación de tecla o no.
   */
  keyPressIdentification(event: any) {
    var rta = false;
    if (this.selectedType != undefined) {
      switch (this.selectedType.name) {
        case 'nacional':
        case 'didi':
        case 'nite':
        case 'dimex':
        case 'juridica':
          var charCode = (event.which) ? event.which : event.keyCode;
          // Solo números del 0 al 9
          if ((charCode == 101) || (charCode == 69) || (charCode == 45) || (charCode == 43) || (charCode == 46)) {
            event.preventDefault();
            rta = false;
          }
          else if ((charCode < 48 || charCode > 57) // números
          ) {
            event.preventDefault();
            rta = false;
          } else { rta = true; }
          break;
        default:
          rta = true;
      }
    }
    return rta;
  }

  /**
   * Esta función busca un ID de cliente y actualiza los campos del formulario con la información encontrada.
   * Solo se ejecuta si el campo de identificación es válido y si el tipo de cliente o el ID de búsqueda han cambiado.
   */
  searchId() {
    if (this.form.controls["identification"].value.length > 0) {
      // Obtengo el Consentimiento Informado del usuario
      this.unRegisteredUser = false;
      if(this.form.controls["identification"].valid){
        this.form.controls["typeidentification"].disable();
        this.clientService.GetInfoClientContactUs(this.form.controls["identification"].value!, this.selectedType.id).subscribe(data => {
          if (this.form.controls["identification"].valid && this.selectedType.name.toLowerCase() === 'nacional') {
            if (!this.isUserLoggedIn) {
              this.resetHiddenValues();
            }
          }
          else {
            this.resetHiddenValues();
            this.form.controls["name"].disable();
            this.form.controls["firstLastName"].disable();
            this.form.controls["secondLastName"].disable();
            this.form.controls["typeidentification"].enable();
            this.loader.hideSpinner();
          }

          if (data.clients) {
            if (data.response.code === "Padron-Fail-01")
              this.unRegisteredUser = true;

            this.clientPadron = data.clients;
            this.applyFormNacional(false);
            this.form.controls["name"].setValue(data.clients!.name);
            this.form.controls["firstLastName"].setValue(data.clients!.lastName);
            this.form.controls["secondLastName"].setValue(data.clients!.secondSurname);
            this.form.controls["name"].disable();
            this.form.controls["firstLastName"].disable();
            this.form.controls["secondLastName"].disable();
            this.form.controls['ofLegalAge'].enable()
            this.requiresInformedConsent = data.clients.requiresInformedConsent ? data.clients.requiresInformedConsent : false; 
          } 
          else {  
            this.unRegisteredUser = true;

            if (this.selectedType.name.toLowerCase() === 'nacional') {
              this.applyFormNacional(true);
              this.notificationService.SendNotification(data.response.type, data.response.titleMessage, data.response.message);
            }
            else {
              if (this.selectedType.name.toLowerCase() === 'juridica') {
                this.form.controls["name"].enable();
                this.form.controls["firstLastName"].disable();
                this.form.controls["secondLastName"].disable();
              }
              else {
                this.applyFormNacional(false);
              }
            }
          }

          this.form.controls["typeidentification"].enable();
          this.loader.hideSpinner();
        },
          (error) => {
            this.loader.hideSpinner();
            this.notificationService.SendNotification(error.response.type, error.response.titleMessage, error.response.message);
          }
        );
      }
    } else {
      this.resetControls(['name', 'firstLastName', 'secondLastName']);
      this.form.controls["name"].disable();
      this.form.controls["firstLastName"].disable();
      this.form.controls["secondLastName"].disable();

      this.form.controls["email"].enable();
      this.form.controls["phone"].enable();
      this.form.controls["subject"].enable();
      this.form.controls["message"].enable();
      this.form.controls["file"].enable();
      this.form.controls["ofLegalAge"].enable();
      this.loader.hideSpinner();
    }
  }

  /**
   * Reinicia los valores ocultos del formulario.
   */
  resetHiddenValues() {
    // Reinicia el valor del campo 'name'
    this.form.controls['name'].setValue('');

    // Reinicia el valor del campo 'firstLastName'
    this.form.controls['firstLastName'].setValue('');

    // Reinicia el valor del campo 'secondLastName'
    this.form.controls['secondLastName'].setValue('');

    // Reinicia el valor del campo 'phone'
    this.form.controls['phone'].setValue('');

    // Reinicia el valor del campo 'email'
    this.form.controls['email'].setValue('');

    // Reinicia el valor del campo 'subject'
    this.form.controls['subject'].setValue('');

    // Reinicia el valor del campo 'message'
    this.form.controls['message'].setValue('');
  }

  /**
   * Maneja el evento cuando cambia el tipo de identificación.
   * @param evt - El objeto de evento.
   */
  IdTypeChanged(evt: any) {
    // Obtener el tipo de identificación seleccionado en base al valor del elemento de entrada
    this.selectedType = this.typesList.filter(x => x.id == evt.srcElement.value)[0];
    // Establecer los validadores para el campo de identificación
    this.form.controls['identification'].setValue('');
    this.form.controls['identification'].setValidators([
      Validators.required,
      Validators.minLength(+this.selectedType.minLength),
      Validators.maxLength(+this.selectedType.maxLength),
      Validators.pattern(
        settings.regexPatternsTypeMap[this.selectedType.idPatternName]
      ),
      this.identificadorValidator(),
    ]);

    // Establecer los validadores para el campo de nombre
    this.form.controls['name'].setValidators([
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(+this.selectedType.nameMaxLength),
      Validators.pattern(
        settings.regexPatternsTypeMap[this.selectedType.namePatternName]
      ),
    ]);

    // Restablecer los valores de los controles
    //this.resetControls(["name", "firstLastName", "secondLastName"]);
    // this.resetControls(["name", "firstLastName", "secondLastName",'phone','email','message','file']);
    // this.filesToUpload = [];
    this.resetNewForm();
    
    // Actualizar la validez del control de identificación
    this.form.controls['identification'].updateValueAndValidity();

    // Realizar una búsqueda basada en el nuevo tipo de identificación
    if (this.selectedType.name.toLowerCase() === 'nacional') {
      this.form.controls["name"].disable();
      this.form.controls["name"].clearValidators();
      this.form.controls["firstLastName"].disable();
      this.form.controls["firstLastName"].clearValidators();
      this.form.controls["secondLastName"].disable();
      this.form.controls["secondLastName"].clearValidators();
      this.searchId();
    } else {
      this.form.controls["name"].enable();
      this.form.controls["email"].enable();
      this.form.controls["phone"].enable();
      this.form.controls["subject"].enable();
      this.form.controls["message"].enable();
      this.form.controls["file"].enable();
      this.form.controls["ofLegalAge"].enable();

      if (this.selectedType.name.toLowerCase() === 'juridica') {
        this.form.controls["name"].disable();
        this.form.controls["name"].clearValidators();
        this.form.controls["name"].setValidators([
          Validators.minLength(2),
          Validators.maxLength(45),
          Validators.pattern(
            settings.regexPatternsTypeMap['juridicName']
          )
        ]);

        this.form.controls["firstLastName"].disable();
        this.form.controls["firstLastName"].clearValidators();

        this.form.controls["secondLastName"].disable();
        this.form.controls["secondLastName"].clearValidators();

        this.form.updateValueAndValidity();
      } else {
        this.form.controls["firstLastName"].disable();
        this.form.controls["firstLastName"].clearValidators();

        this.form.controls["secondLastName"].disable();
        this.form.controls["secondLastName"].clearValidators();

        this.form.controls["name"].disable();
        this.form.controls["name"].clearValidators();

        this.form.controls["name"].setValidators([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(
            settings.regexPatternsTypeMap[this.selectedType.namePatternName]
          ),
        ]);

        this.form.controls["firstLastName"].setValidators([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(
            settings.regexPatternsTypeMap['lettersSpanish']
          )
        ]);

        this.form.controls["secondLastName"].setValidators([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(
            settings.regexPatternsTypeMap['lettersSpanish']
          ),
        ]);

        this.form.updateValueAndValidity();
      }
    }
  }

  /**
   * Verifica si se debe permitir la pulsación de tecla en el campo de teléfono.
   *
   * @param event - El evento de pulsación de tecla.
   * @returns Devuelve `true` si se permite la pulsación de tecla, de lo contrario devuelve `false`.
   */
  keyPressPhone(event: any): boolean {
    let allowKeyPress = false;
    const strPhoneValue: string = '' + this.form.controls['phone'].value;

    if (strPhoneValue.length >= this.phoneMaxLength) {
      event.preventDefault();
      allowKeyPress = false;
    } else {
      const charCode = event.which ? event.which : event.keyCode;

      // Solo se permiten números del 0 al 9
      if (
        charCode == 101 ||
        charCode == 69 ||
        charCode == 45 ||
        charCode == 43 ||
        charCode == 46
      ) {
        event.preventDefault();
        allowKeyPress = false;
      } else if (
        charCode < 48 ||
        charCode > 57 // números
      ) {
        event.preventDefault();
        allowKeyPress = false;
      } else {
        allowKeyPress = true;
      }
    }

    return allowKeyPress;
  }

  /**
   * Envía un correo electrónico para el formulario de contacto.
   *
   * @returns {void}
   */
  sendEmailContactUs() {
    // Preparar los datos para enviar el correo electrónico
    const datosEmail: ClientInteractionObject = {
      ClientEmail: this.email?.value,
      ClientName: this.name?.value,
      Subject: this.subjectList.find(x => x.id == this.subject?.value)!.subject,
      IDClientInteraction: '0',
      LoyaltyCard: '',
      Identification: this.identification?.value,
      Phone: this.phone?.value,
      IDClientInteractionSubject: '',
      Message: this.message?.value,
      IdType: this.typesList.find(x => x.id == this.typeidentification?.value)!.displayName,
      ClientLastName: this.firstLastName?.value,
      ClientSecondLastName: this.secondLastName?.value,
      idSitefinitySubject: this.subject?.value,
      Files: null
    };

    // Enviar el correo electrónico
    this.contactusService.SendEmailContactUsMethod(datosEmail, this.filesToUpload).subscribe(o => {
      if (o.response_code) {
        this.contactusService.getMessages(o.response_code, '1').subscribe(data => {
          this.titulo = data.titleMessage;
          this.mensaje = data.message;
          this.showOkModal = true;
        });
      }

      if (o.response_status) {
        // Correo electrónico enviado correctamente
        this.form.controls['ofLegalAge'].setValue(false);
        if (this.isUserLoggedIn) {
          this.resetNewForm();
          this.idClient = 0;
        } else {
          this.resetControls(['all']);
          this.selectedType = this.typeNacional;
          this.form.controls['identification'].setValidators([
            Validators.required,
            Validators.minLength(+this.selectedType.minLength),
            Validators.maxLength(+this.selectedType.maxLength),
            Validators.pattern(
              settings.regexPatternsTypeMap[this.selectedType.idPatternName]
            ),
            this.identificadorValidator(),
          ]);
          this.form.controls['typeidentification'].setValue(this.typeNacional.id);
          this.form.controls['name'].disable();
          this.form.controls['firstLastName'].disable();
          this.form.controls['secondLastName'].disable();
        }

        this.filesToUpload = [];
      }

      this.loader.hideSpinner();
    }, error => {
      // Correo electrónico no enviado
      this.loader.hideSpinner();
      this.notificationService.SendNotification('error', 'Error', 'Ha ocurrido un error al enviar el correo electrónico');
      this.resetControls(['all']);
      this.form.controls['typeidentification'].setValue(this.typeNacional.id);
    });
  }

  formatDotNetDate(date: Date): string {
    let dateTicks = date.getTime(); // Obtiene la fecha en milisegundos desde la época de Unix
    return `/Date(${dateTicks})/`;
  }

  /**
   * Acepta el formulario de consentimiento informado.
   */
  acceptInformedConsent(acept: boolean) {
    this.showCI = false;
    if (acept) {
      this.loader.showSpinner();
      // Prepara los datos para enviar al API InformedConsentMethod
      let consentimientoInformadoObject: any = {
        pCodigoCliente: "",
        ptipocedula: this.typeidentification?.value,
        pCedula: this.identification?.value,
        pNombre: this.name?.value,
        pApellido1: this.firstLastName?.value,
        pApellido2: this.secondLastName?.value,
        pFechaNacimiento: this.formatDotNetDate(new Date(1901, 0, 1)),
        pGenero: "I",
        pEmail: this.email?.value,
        pTelefono: this.phone?.value,
        pProvincia: this.selectedType.name.toLowerCase() === 'nacional' && this.clientPadron ? this.clientPadron.idProvince : 1,
        pCanton: this.selectedType.name.toLowerCase() === 'nacional' && this.clientPadron ? this.clientPadron.idCanton : 3,
        pDistrito: this.selectedType.name.toLowerCase() === 'nacional' && this.clientPadron ? this.clientPadron.idDistrict : 16,
        pRecibirComunicaciones: true,
        pContactoEmail: true,
        pContactoTelefono: true,
        pCodigoFarmacia: this.consentimientoInformadoParameters.codigoFarmacia,
        pNombreCampanna: this.consentimientoInformadoParameters.NombreCampannaConsentimiento,
        pSucursalCompraRegular: "",
        pMedicamentoCompraRegular: "",
        pFischelExpress: false,
        pcronico: false
      };

      this.idClient = this.clientPadron?.idClient ? this.clientPadron.idClient : 0; 
      
      if (this.idClient !== 0) {
        // Si el cliente está logueado actualiza los datos del cliente del CI
        this.clientService.UpdateInformedConsentMethod(this.isUserLoggedIn ? this.userLogged.IDClient : this.idClient).subscribe(res => {
          if (res.responseObject || res.response.code === "OK0001") {
            this.sendEmailContactUs();
          }
          else {
            this.clientService.getMessages(res.response.code, "1").subscribe(o => {
              this.notificationService.SendNotification('error', o.titleMessage, o.message);
            });
            this.loader.hideSpinner();
          }
        });
      } else {
        // Si el Cliente no está logueado y no obtiene informacion del CI, Informa y envía el correo
        this.contactusService.InformedConsentMethod(consentimientoInformadoObject).subscribe(x => {
          if (x.Ok) {
            this.sendEmailContactUs();
          }
        });
      }
    }
  }

  identificadorValidator(): ValidatorFn {
    return () => {
      const value = this.identification?.value;
      if (value && value.length > 0) {
        return value.substring(0, 1) === '0' ? { isStartIdentification: true } : null;
      } else {
        return null;
      }
    };
  }

  resetNewForm() {
    this.idClient = 0;
    this.form = this.fb.group({
      typeidentification: [this.isUserLoggedIn ? this.userLogged.IdClientType : this.selectedType.id],
      identification: [
        this.isUserLoggedIn ? this.userLogged.Identification : '',
        [
          Validators.required,
          Validators.minLength(+this.selectedType.minLength),
          Validators.maxLength(+this.selectedType.maxLength),
          Validators.pattern(
            settings.regexPatternsTypeMap[this.selectedType.idPatternName]
          ),
          this.identificadorValidator(),
        ]
      ],
      name: [
        this.isUserLoggedIn ? this.userLogged.Name : '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(
            settings.regexPatternsTypeMap['lettersSpanish']
          ),
        ]
      ],
      firstLastName: [
        this.isUserLoggedIn ? this.userLogged.LastName : '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(
            settings.regexPatternsTypeMap['lettersSpanish']
          ),
        ]
      ],
      secondLastName: [
        this.isUserLoggedIn ? this.userLogged.SecondSurname : '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(
            settings.regexPatternsTypeMap['lettersSpanish']
          ),
        ]
      ],
      phone: [
        this.isUserLoggedIn ? (this.userLogged.Phones.length > 0 ? this.userLogged.Phones[0].Phone : '') : '',
        [
          Validators.required,
          Validators.minLength(this.phoneMaxLength),
          Validators.maxLength(this.phoneMaxLength),
          Validators.pattern(settings.regexPatternsTypeMap['number']),
        ]
      ],
      email: [
        this.isUserLoggedIn ? this.userLogged.Email : '',
        [
          Validators.email,
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(50),
          Validators.pattern(settings.regexPatternsTypeMap['email']),
        ],
      ],
      subject: ['', [Validators.required]],
      message: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(800),
        ]
      ],
      file: [null],
      ofLegalAge: [false, [Validators.requiredTrue]]
    });

    if (this.isUserLoggedIn) {
      this.form.controls['typeidentification'].setValidators(null);
      this.form.controls['typeidentification'].disable();

      this.form.controls['identification'].setValidators(null);
      this.form.controls['identification'].disable();

      this.form.controls['name'].setValidators(null);
      this.form.controls['name'].disable();

      this.form.controls['firstLastName'].setValidators(null);
      this.form.controls['firstLastName'].disable();

      this.form.controls['secondLastName'].setValidators(null);
      this.form.controls['secondLastName'].disable();

      this.form.controls['phone'].setValidators(null);
      this.form.controls['typeidentification'].disable();

      this.form.controls['email'].setValidators(null);
      this.form.controls['email'].disable();

      this.form.controls['phone'].setValidators(null);
      this.form.controls['phone'].disable();

      this.form.controls['subject'].setValidators([Validators.required]);
      this.form.controls['message'].setValidators([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(800)
      ]);

      this.isSubmited = false;
    }
  }

  onFileChange(event: any) {
    let files: any[] = [];
    for (let index = 0; index < event.target.files.length; index++) {
      files.push(event.target.files.item(index));
    }

    files.forEach((file: any) => {
      const fileName = file.name.split("\\").pop();
      const fileSize = file.size;

      if (!(/\.(pdf|png|jpeg|jpg)$/i).test(fileName)) {
        this.notificationService.SendNotification(settings.tipoAlertaValidaciones, settings.tituloAlertaValidaciones, settings.mensajeExtensionArchivos);
        event.target.value = null;
        return;
      }
      
      if (fileSize > settings.maxFileSize) {
        this.notificationService.SendNotification(settings.tipoAlertaValidaciones, settings.tituloAlertaValidaciones, settings.mensajeMaximoTamanoArchivo);
        event.target.value = null;
        return;
      }

      let totalSize = 0;
      let filesList: File[] = [];
      this.filesToUpload.forEach((file) => {
        filesList.push(file);
      });
      filesList.push(file);

      for (var i = 0; i < filesList.length; i++) {
        totalSize += filesList[i].size;
      }
      if (totalSize > settings.maxFileSize) {
        this.notificationService.SendNotification(settings.tipoAlertaValidaciones, settings.tituloAlertaValidaciones, settings.mensajeSumaTamanoArchivos);
        event.target.value = null;
        return;
      }

      if (filesList.length > 10) {
        this.notificationService.SendNotification(settings.tipoAlertaValidaciones, settings.tituloAlertaValidaciones, settings.mensajeTotalArchivos);
        event.target.value = null;
        return;
      }

      if (this.filesToUpload.findIndex(file => file.name === fileName) !== -1) {
        this.notificationService.SendNotification(settings.tipoAlertaValidaciones, settings.tituloAlertaValidaciones, settings.mensajeArchivoExistente);
        event.target.value = null;
        return;
      }

      this.filesToUpload.push(file);
    });
  }

  /**
   * Elimina un archivo del array filesToUpload en el índice especificado.
   *
   * @param {number} inde - el índice del archivo a eliminar
   * @return {void}
   */
  deleteFile(index: number) {
    this.filesToUpload.splice(index, 1);
  }

  applyFormNacional(disable: boolean = false) {
    this.form.controls['name'].setValue('');
    disable ? this.form.controls['name'].disable() : this.form.controls['name'].enable();

    this.form.controls['firstLastName'].setValue('');
    disable ? this.form.controls['firstLastName'].disable() : this.form.controls['firstLastName'].enable();

    this.form.controls['secondLastName'].setValue('');
    disable ? this.form.controls['secondLastName'].disable() : this.form.controls['secondLastName'].enable();

    this.form.controls['phone'].setValue('');
    disable ? this.form.controls['phone'].disable() : this.form.controls['phone'].enable();

    this.form.controls['email'].setValue('');
    disable ? this.form.controls['email'].disable() : this.form.controls['email'].enable();

    this.form.controls['phone'].setValue('');
    disable ? this.form.controls['phone'].disable() : this.form.controls['phone'].enable();

    this.form.controls['subject'].setValue('');
    disable ? this.form.controls['subject'].disable() : this.form.controls['subject'].enable();

    this.form.controls['message'].setValue('');
    disable ? this.form.controls['message'].disable() : this.form.controls['message'].enable();

    this.form.controls['file'].setValue('');
    disable ? this.form.controls['file'].disable() : this.form.controls['file'].enable();

    this.form.controls['ofLegalAge'].setValue('');
    disable ? this.form.controls['ofLegalAge'].disable() : this.form.controls['message'].enable();
  }
}
