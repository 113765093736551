
import { Component, HostListener } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf } from '@angular/common';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { NotificationArrayService } from 'src/app/services/shared/notification-array.service';

@Component({
  selector: 'app-notificationarray',
	standalone: true,
	imports: [NgbToastModule, NgIf, CommonModule],
  templateUrl: './notification-array.component.html',
})

export class NotificationArrayComponent {
	show = true;
  _toastService: NotificationArrayService
  _utilitiesService : UtilitiesService
  isHovered: boolean = false;

  constructor( notiService : NotificationArrayService,  utilities : UtilitiesService){
    this._toastService = notiService,
    this._utilitiesService = utilities
  }

  hideToast(): void {
    this.isHovered = false;
    this._toastService.Hide();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.toast-container')) {
      this.isHovered = false;
    }
  }
}


