import { Response } from '../responses/response.model';

export class Shops {
}

export class WCFRequestShopsByTypes {
    ShopTypes: number[] = [];
    Argument: string = '';
    IdDistrict: number = 0;
    IdCanton: number = 0;
    IdProvince: number = 0;

    constructor(shopTypes: number[] = [], argument: string = '', idDistrict: number = 0, idCanton: number = 0, idProvince: number = 0) {
        this.ShopTypes = shopTypes;
        this.Argument = argument;
        this.IdDistrict = idDistrict;
        this.IdCanton = idCanton;
        this.IdProvince = idProvince;
    }
}

export class ResponseShopsByTypes {

    name: string = '';
    idShop: number = 0;
    availabilities: ShopAvailability[] = [];
    address: string = '';
    shopMapPoints: ShopMapPoints = {
        longitude: 0,
        latitude: 0
    };

}

export class ShopAvailability {

    alias: string = '';
    idAvailabilityType: number = 0;

}

export class ShopMapPoints {

    longitude: number = 0;
    latitude: number = 0;

}
