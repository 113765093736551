<!--Inicio componente footer-->
<footer class="footer border-top"
  [style]="'background-color: ' + bgColor + ' !important;'+'color: ' + fColor + ' !important;'">
  <div class="container">
    <div class="row d-flex justify-content-between mb-3">
      <div class="col-12 col-lg-4 mb-3 pb-3 text-center text-lg-start">
        <a (click)="redirectToIndex(8)">
          <img *ngIf="fColor==''" [src]="urlBase+'/assets/img/logo-white.svg'" loading="lazy"   alt="Logo La Bomba" class="logo-footer" />
          <img *ngIf="fColor!=''" [src]="urlBase+'/assets/img/logo-blanco.png'" loading="lazy"  alt="Logo La Bomba" class="logo-footer"
            [style]=" (fColor!='') ? 'filter: drop-shadow( 1000px 0px  0 '+ fColor+')!important; transform: translateX(-1000px)!important;': '' " />
        </a>
      </div>

      <div class="col-12 col-lg-3">
        <ul class="mb-0 nav nav-footer align-items-center align-items-lg-start">
          <li class="mb-3 mb-lg-2 nav-item"><a href="tel:8005226622" class="nav-link footer-list"
              [style]="'color: ' + fColor + ' !important;'">A domicilio: <span style="color: rgb(255, 198, 0); font-size: 1rem;">800-522-6622</span>
            </a></li>
          <li class="mb-3 mb-lg-2 nav-item"><a href="mailto:info@farmacialabomba.com" class="nav-link footer-list"
              [style]="'color: ' + fColor + ' !important;'">Correo electrónico: info@farmacialabomba.com </a>
          </li>
          <li class="mb-3 mb-lg-2 nav-item"><a [ngClass]="{ 'active': activeItemIndex === 4 }" (click)="redirectToContactUs(4)" routerLink="/contactanos" class="nav-link footer-list"
              [style]="'color: ' + fColor + ' !important;'">Contactanos</a></li>
        </ul>
      </div>

      <div class="col-12 col-lg-2 text-center text-lg-start">
        <ul class="mb-0 nav nav-footer align-items-center align-items-lg-start">
          <li class="mb-3 mb-lg-2 nav-item"><a [ngClass]="{ 'active': activeItemIndex === 5 }"  (click)="redirectToLegalDocument(5)"  routerLink="/informacion-legal" class="nav-link activate footer-list"
              [style]="'color: ' + fColor + ' !important;'">Información legal</a></li>
          <li class="mb-3 mb-lg-2 nav-item"><a [ngClass]="{ 'active': activeItemIndex === 6 }"   (click)="redirectToFAQ(6)" routerLink="/preguntas-frecuentes" class="nav-link footer-list"
              [style]="'color: ' + fColor + ' !important;'">Preguntas frecuentes</a></li>
          <li class="mb-3 mb-lg-2 nav-item"><a [ngClass]="{ 'active': activeItemIndex === 7 }"   (click)="redirectToPharmacies(7)"  routerLink="/farmacias"  class="nav-link footer-list"
              [style]="'color: ' + fColor + ' !important;'">Farmacias</a></li>
        </ul>
      </div>

      <div class="col-12 col-lg-2 text-center text-lg-start">
        <ul class="mb-0 nav nav-footer align-items-center align-items-lg-start">
          <li class="mb-3 mb-lg-2 nav-item"><a href="https://grupodokka.com/" target="_blank" rel="noopener noreferrer" class="nav-link footer-list"
              [style]="'color: ' + fColor + ' !important;'">Corporativa</a></li>
          <li class="mb-3 mb-lg-2 nav-item"><a href="https://oportunidades.cuestamoras.com/grupodokka" target="_blank"
            rel="noopener noreferrer"  class="nav-link footer-list" [style]="'color: ' + fColor + ' !important;'">Trabajá con nosotros</a></li>
            <li class="mb-3 mb-lg-2 nav-item footer-list">Descargá
              nuestra app:</li>
        </ul>
        <div class="d-flex align-items-center justify-content-center">
          <a href="https://apps.apple.com/cr/app/farmacia-la-bomba/id1569639991" target="_blank"><img [src]="urlBase + '/assets/img/app-store.png'" alt="App Store" class="pe-2" style="max-width: 100px;"></a>
          <a href="https://play.google.com/store/apps/details?id=com.bigshotapps.cefabomba&hl=es_CR&gl=US" target="_blank"><img [src]="urlBase + '/assets/img/play-store.png'" alt="Google Play" style="max-width: 100px;"></a>
        </div>
      </div>
    </div>
  </div>

  <hr>
  <div class="container">
      <div class="row d-flex align-items-center mb-3">
          <div class="col-12 col-lg-4 text-center text-lg-end order-lg-2 mb-3 my-lg-0">
              <ul class="mb-0">

                <a  aria-label="Ir a la página de Facebook" href="https://www.facebook.com/farmacialabomba/" target="_blank" rel="noopener noreferrer" [style]="'color: ' + fColor + ' !important;'"><i
                  class="bi bi-facebook" [ngClass]="(fColor!='')? '' : 'icon-footer'"></i></a>
              <a  aria-label="Ir a la página de Instagram" href="https://www.instagram.com/farmacialabombacr/" target="_blank"  rel="noopener noreferrer" [style]="'color: ' + fColor + ' !important;'">
                <i class="bi bi-instagram" [ngClass]="(fColor!='')? '' : 'icon-footer'" ></i></a>
                <a href="https://www.tiktok.com/@farmacialabombacr" target="_blank" rel="noopener noreferrer"> <i class="bi bi-tiktok icon-footer"></i></a>
              <a  aria-label="Ir a la página de Whastapp"  href="https://wa.me/50625190592" target="_blank" rel="noopener noreferrer"> <i class="bi bi-whatsapp icon-footer"
                  [style]="'color: ' + fColor + ' !important;'"></i></a>
              </ul>
          </div>

          <div class="col-12 col-lg-8 text-center text-lg-start">
              <div class="">
                  <span>La Bomba 2024. Todos los derechos reservados. |</span>
                  <span> Powered by
                    <a  aria-label="Ir a la página de Loymark"  *ngIf="fColor==''" href="https://www.loymark.com/" target="_blank" rel="noopener noreferrer"><img class="logo-loymark mx-1"
                        [src]="urlBase+'/assets/img/logo-loymark-light.png'" loading="lazy"   alt="Logo Loymark" width="auto" height="12px" >

                    </a>
                    <a  aria-label="Ir a la página de Loymark" *ngIf="fColor!=''" href="https://www.loymark.com/" target="_blank" rel="noopener noreferrer"><img class="logo-loymark mx-1"
                        [src]="urlBase+'/assets/img/logo-loymark-white.png'"
                        [style]="'filter: drop-shadow( 1000px 0px  0 '+ fColor+')!important; transform: translateX(-1000px)!important;'"
                        loading="lazy"
                        alt="Logo Loymark" width="auto" height="12px">
                    </a>
                    |
                    <a [href]="urlSite + 'sitemap.xml'" [style]="'color: ' + fColor + ' !important;'">
                      <small>SiteMap</small>
                    </a>
                  </span>
              </div>
          </div>
      </div>
  </div>

</footer>


<a href="https://api.whatsapp.com/send?phone=50625190592" class="btn-wsp" target="_blank">
  <i class="bi-whatsapp"></i>
</a>






<!--Fin componente footer-->
