import { SafeHtml } from '@angular/platform-browser';
import { HighlightedProducts, Products } from '../core/products.model';
import{Response} from '../responses/response.model';

export class LandingDetails {
  colorTitleSubtitle: string = '';
  firstDescription: string = '';
  sanitizedFirstDescription: SafeHtml = '';
  firstSubTitle: string = '';
  firstTitle: string = '';
  idCategory: string = '';
  id:string = '';
  idCssColor: string = '';
  idCssFooterTextColor: string = '';
  includeInSitemap:boolean = false;
  showBannerHeader: boolean = false;
  showVideo: boolean = false;
  itemDefaultUrl:string = '';
  listSkuFeature: string = '';
  listSkuCategory: string = '';
  pageSection1: string = '';
  pageSection2: string = '';
  pageSection3: string = '';
  pageSection4: string = '';
  pageSection5: string = '';
  pageSection6: string = '';
  pageSection7: string = '';
  pageSectionImgTwoColumn: string = '';
  productFeatureSubTitle: string = '' ;
  productFeatureTitle: string = '';
  productsSubTitle: string = '';
  productsTitle: string = '';
  titleImages: string = '';
  subtitleImages: string = '';
  provider: string = ''; //new
  secondDescription: string = '';
  sanitizedSecondDescription: SafeHtml = '';
  secondTitle: string = '';
  secondSubTitle: string = '';
  showImgTwoColumn: boolean = false;
  subtitleSectionImgTwoColumns: string = '';
  systemSourceKey: string = ''; //new
  title: string = '';
  titleSectionImgTwoColumns: string = '';
  urlName: string = ''; //new
  displayFidelityButton: boolean = false;
  // name: string = '';
  // type: string = '';
  headerBanner: Banner[] = [];
  imageListBrand: ImageRelated[] = [];
  firstListBanner: Banner[] = [];
  secondListBanner:Banner[] = [];
  thirdListBanner:Banner[] = [];
  videoLanding:VideoRelated[] = [];
  listImage:ImageRelated[] = [];
  imageListSectionTwoColumns: ImageRelated[] = [];
  imageHeaderNew: ImageRelatedNew[] = [];
  imageHeader: ImageRelated[] = [];
  productsFeatured: HighlightedProducts[] = [];
  productsCategorySku: HighlightedProducts[] = [];
  productsCategory: Products[] = [];
  metaDescription: string = ''
  metaTitle: string = ''
}

export class LandingList {
  id:string = '';
  name: string = '';
  title: string = '';
  imageListBrand: ImageRelated[] = [];
  urlLandingDetail : string = '';
}

export class Banner {
  id:string='';
  title: string = '';
  bannerTitle: string = '';
  subTitleBanner: string = '';
  url: string = '';
  label: string = '';
  showSubTitle: string = '';
  showTitle: string = '';
  priority: number = 0;
  imageBanner: ImageRelated[] = [];
  scheduleBanner: Schedule[] = [];
}

export class Schedule {
  id:string= '';
  title: string = '';;
  scheduleInit: Date = new Date();
  scheduleFinal: Date = new Date();;
}

export class ImageRelated {
  id :string = '';
  title:string = '';
  mediaUrl: string = '';
  alternativeText:string = '';
}
export class ImageRelatedNew {
  Id :string = '';
  Title:string = '';
  Url: string = '';
  alternativeText:string = '';
}

export class VideoRelated {
  id :string = '';
  title:string = '';
  mediaUrl: string = '';
  alternativeText:string = '';
}

export class LandingDetailResponse {
  landingDetail: LandingDetails = new LandingDetails;
  Response:Response = new Response;
}

export class LandingsListResponse {
  landings: LandingList[] = [];
  response:Response = new Response;
}

