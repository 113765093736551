import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import { ClientObjectGlobalResponse } from 'src/app/models/core/clients.model';
import { ObjectListGlobalResponse } from 'src/app/models/core/utilities.model';
import { CartRequest, CartAddress, SavedCard, Purchase, Cart }
  from 'src/app/models/core/cart.model';
import { IfStmt } from '@angular/compiler';
import { ObjectGlobalResponse } from 'src/app/models/core/utilities.model';
import { CommentByProduct,Molecule,MoleculeAttribute, SearchProductRequest }
from 'src/app/models/core/merge-n.model';
import { CoreRequest } from 'src/app/models/requests/request.model';


const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})

export class MergeNService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService) { }

    private getMessages(code: string,category: string): Observable<ResponseInterface> {

      return new Observable<Response>((observer) => {
           var messageCore: string[]= [];
          this.MessagesService_.GetMessagesByKey(code,category).subscribe((data) => {
          var messageResponse = data.value[0];

              if (messageResponse == undefined) {


                messageCore.push(code);
                const responseServices: ResponseInterface = {
                    message : 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
                    alias : code + '-alias',
                    status : true,
                    code: code,
                    messageCore: messageCore,
                    buttonAcceptText:'',
                    buttonCancelText: '',
                    type:'',
                    action:'',
                    titleMessage: '',
                    url: '',
                    Messages:[]
                   };
                   observer.next(responseServices);
                   observer.complete();

              }
              else
              {
                const responseServices: ResponseInterface = {
                    message : messageResponse.message,
                    alias : messageResponse.aliascode,
                    status : true,
                    code:messageResponse.code,
                    messageCore: messageCore,
                    buttonAcceptText:messageResponse.buttonAcceptText,
                    buttonCancelText: messageResponse.buttonCancelText,
                    type:messageResponse.type,
                    action:messageResponse.action,
                    titleMessage: messageResponse.titleMessage,
                    url: messageResponse.url,
                    Messages:[]
                }
                observer.next(responseServices);
                observer.complete();
              }
        },(error) =>{

          const responseServices: ResponseInterface = {
            message : 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
            alias : 'getMessages-error-alias-01',
            status : false,
            code:'getMessages-error-01',
            messageCore: messageCore,
            buttonAcceptText:'',
            buttonCancelText: '',
            type:'',
            action:'',
            titleMessage: "falta agregar el código",
            url:'',
            Messages:[]
        };
        observer.error(responseServices);
        observer.complete();

        });

    });


    }

  private SaveCommentByProductService(request: CommentByProduct): Observable<any> {
    const url = settings.CoreServicesUrl + 'Product.svc/SaveCommentByProduct';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    const requestCore: CoreRequest = {
      ProgId: "CLISITE",
      UserId: "1",
      AllowApplyCoupons: false,
      IDSavedCard: null,
      IsValid: false,
      Messages: null,
      ServerPath: '',
      WithErrors: false,
      Parameters: ''
    };

    requestCore.Parameters = JSON.stringify(request);
    return this.http.post(url, requestCore, { headers });

  }


  public SaveCommentByProductMethod(request: CommentByProduct):
    Observable<ObjectGlobalResponse> {
    return this.SaveCommentByProductService(request).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private SearchCommentByProductService(idProduct: number, page: number, pageSize: number)
    : Observable<any> {
    const url = settings.CoreServicesUrl + 'Product.svc/SearchCommentByProduct/'
      + idProduct + "/" + page + "/" + pageSize;
    return this.http.get(url);
  }


  public SearchCommentByProductMethod(idProduct: number, page: number, pageSize: number):
    Observable<ObjectListGlobalResponse> {
    return this.SearchCommentByProductService(idProduct, page, pageSize).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetPunctuationValuesService(idProduct: number)
    : Observable<any> {
    const url = settings.CoreServicesUrl + 'Product.svc/GetPunctuationValues/'
      + idProduct;
    return this.http.get(url);
  }


  public GetPunctuationValuesMethod(idProduct: number):
    Observable<ObjectGlobalResponse> {
    return this.GetPunctuationValuesService(idProduct).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private validateAccessibilityToRateService(idProduct: number, idClient: number)
    : Observable<any> {
    const url = settings.CoreServicesUrl + 'Product.svc/validateAccessibilityToRate/'
      + idProduct+"/"+idClient;
    return this.http.get(url);
  }


  public validateAccessibilityToRateMethod(idProduct: number, idClient: number):
    Observable<ObjectGlobalResponse> {
    return this.validateAccessibilityToRateService(idProduct,idClient).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetMoleculeService(sku: string)
    : Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetMolecule/'
      + sku;
    return this.http.get(url);
  }

  public FillMoleculeAttributes(requestObject: any[]): MoleculeAttribute[] {
    if (requestObject == undefined) {
      return [];
    }

    return requestObject.map((dataItem: any) => ({
      IDProductAttribute: dataItem.IDProductAttribute,
      PriceWithDiscount: dataItem.PriceWithDiscount,
      PriceWithTaxes: dataItem.PriceWithTaxes
    }));
  }

  private SetMoleculeProperties(requestObject: any): Molecule {
    const objectResponse: Molecule = {
      IDProduct: requestObject.IDProduct,
      IsAvailable: requestObject.IsAvailable,
      IsShopAvailable: requestObject.IsShopAvailable,
      Name: requestObject.Name,
      PercentDiscountRate: requestObject.PercentDiscountRate,
      PriceWithDiscount: requestObject.PriceWithDiscount,
      PriceWithTaxes: requestObject.PriceWithTaxes,
      ProductAttributes: this.FillMoleculeAttributes(requestObject.ProductAttributes),
      ProductImages: requestObject.ProductImages,
      URL: requestObject.URL,
    };

    return objectResponse;

  }

  public GetMoleculeMethod(sku: string):
    Observable<ObjectListGlobalResponse> {
    return this.GetMoleculeService(sku).pipe(switchMap((coreResponse: any) => {
      const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
      var code = wcfResponse.Messages[0]

      if (code === "OK0001") {
        const parametersResponse: string = wcfResponse.Parameters as string;
        const listResponse: any[] = JSON.parse(parametersResponse);
        const ecommerceResponse: any[] = [];

        listResponse.forEach((DataItem: any) => {
          ecommerceResponse.push(this.SetMoleculeProperties(DataItem));
        });


        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectListGlobalResponse = {
              response: data,
              responseObject: ecommerceResponse
            };
            return response;
          })
        );
      } else {

        return this.getMessages(code, '1').pipe(
          map((data: any) => {
            const response: ObjectListGlobalResponse = {
              response: data,
              responseObject: []
            };
            return response;
          })
        );
      }
    })
    );
  }

  private GetReducedProductsByLinkService(argument: string, type: number)
    : Observable<any> {
      const url = settings.CoreServicesUrl + 'BombaCart.svc/GetReducedProductsByLink';
      const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
      //type 1 = marcas
      //type 2 = categorías
      //type 3 = tags
      //type 4 = nombre producto
      const requestCore: SearchProductRequest = {
          Argument: argument,
          IDProductCategory: type
        };


      return this.http.post(url, requestCore, { headers });
  }


  public GetReducedProductsByLinkMethod(argument: string, type: number):
    Observable<ObjectListGlobalResponse> {
    return this.GetReducedProductsByLinkService(argument, type).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetOrCreateCartService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/GetOrCreateCart';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public GetOrCreateCartMethod(idCart: string, idClient: number):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart,
      IdClient: idClient
    };
    return this.GetOrCreateCartService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetCartSummaryService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetCartSummary';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public GetCartSummaryMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };
    return this.GetCartSummaryService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);

        if (wcfResponse.Parameters) {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages('OK0001', '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages('emptyCart', '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetRedemptionRulesService(sku: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetRedemptionRules/'+ sku;
    return this.http.get(url);
  }


  public GetRedemptionRulesMethod(sku: string):
    Observable<ObjectListGlobalResponse> {
    return this.GetRedemptionRulesService(sku).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetProductsSummaryService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetProductsSummary';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }

  public GetProductsSummaryMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };
    return this.GetProductsSummaryService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private ValidateProductByLinkService(argument: string)
    : Observable<any> {
    const url = settings.CoreServicesUrl + 'Product.svc/ValidateProductByLink/'
      + argument;
    return this.http.get(url);
  }

  public ValidateProductByLinkMethod(argument: string):
    Observable<ObjectGlobalResponse> {
    return this.ValidateProductByLinkService(argument).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private PreorderPowerTranzService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/PreorderPowerTranz';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public PreorderPowerTranzMethod(idSavedCard: string | null, idCart: string, idClient: number,
    isAMEX: boolean, isCardToSave: boolean, paymentExtraInfo: string, useFAC: boolean,
    useSavedCard: boolean):Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      GenerateElectronicInvoice: true,
      IDSavedCard: idSavedCard,
      IdCart: idCart,
      IdClient: idClient,
      IsAMEX: isAMEX,
      IsCardToSave: isCardToSave,
      PaymentExtraInfo: paymentExtraInfo,
      UseFAC: useFAC,
      UseSavedCard: useSavedCard
    };
    return this.PreorderPowerTranzService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetPowerTranzHTMLResponseByPurchaseIDService(idPurchase: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/GetPowerTranzHTMLResponseByPurchaseID/'+ idPurchase;
    return this.http.get(url);
  }


  public GetPowerTranzHTMLResponseByPurchaseIDMethod(idPurchase: string):
    Observable<ObjectListGlobalResponse> {
    return this.GetPowerTranzHTMLResponseByPurchaseIDService(idPurchase).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private UpdateCartInfoLightService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/UpdateCartInfoLight';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }

  public UpdateCartInfoLightMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };

    return this.UpdateCartInfoLightService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }




}
