<main class="main">
  <div class="container container-input mt-3 mt-lg-5">
    <h1 class="color-primary">
      Registro
    </h1>
    <p>Ingresá tu información para registrarte en Farmacia La Bomba. Si ya tenés cuenta en alguna de nuestras
      plataformas, seleccioná <a routerLink="/iniciosesion"> Iniciar sesión</a> e ingresá el mismo correo electrónico y contraseña que
      registraste.
    </p>
    <form [formGroup]="form" (ngSubmit)="Submit()" *ngIf="this.selectedType">
      <div class="row">
        <div class="col-12 mb-2">
          <label for="">Identificación</label>
          <div class="d-flex align-items-center">
            <input type="text" class="form-control contact-identification" #identificationInput formControlName="identification"
              (keypress)="keyPressIdentification($event)" [minlength]="this.selectedType.minLength"
              [maxlength]="this.selectedType.maxLength" [placeholder]="this.selectedType.placeholder"
              [ngClass]="identification!.invalid && (identification!.touched || this.isSubmited )? 'is-invalid':''"
              (blur)="searchId()" (focus)="isFocusedId=true">
            <select (change)="IdTypeChanged($event)" class="form-select input-select-contact" aria-label="Default select example"
              formControlName="typeidentification">
              <option *ngFor=" let type of this.idTypes" [value]="type.id">{{ type.displayName }}</option>
            </select>
          </div>

          <div *ngIf="identification!.invalid && ( identification!.touched || this.isSubmited )" class="d-block text-error">
            <span *ngIf="identification!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="identification!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX",identification!.errors!.minlength.requiredLength )}}
            </span>
            <span *ngIf="identification!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX",identification!.errors!.maxlength.requiredLength )}}
            </span>
            <span *ngIf="identification!.errors?.['pattern']">
              {{ validationMsg[this.selectedType.idPatternName+'pattern'] }}
            </span>
          </div>

          <div *ngIf=" !(identification!.invalid && ( identification!.touched || this.isSubmited )) &&  validId!.invalid && ( identification!.touched || this.isSubmited ) && validateId && !isFocusedId" class="d-block text-error">
            <span *ngIf="validId!.errors?.['required']">
              {{ invalidIdMsg }}
            </span>
          </div>

        </div>
        <div class="col-12 mb-2">
          <label for="nameInput" class="form-label ">Nombre</label>
          <input type="text" class="form-control login-input" id="nameInput" formControlName="name" [maxlength]="selectedNameMaxLegth ?? 0"
            [ngClass]="name!.invalid && (name!.touched || this.isSubmited )? 'is-invalid':''">
          <div *ngIf="name!.invalid && ( name!.touched || this.isSubmited )" class="d-block text-error">
            <span *ngIf="name!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="name!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX",name!.errors!.minlength.requiredLength )}}
            </span>
            <span *ngIf="name!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX",name!.errors!.maxlength.requiredLength )}}
            </span>
            <span *ngIf="name!.errors?.['pattern']">
              {{ validationMsg[this.selectedType.namePatternName+'pattern'] }}
            </span>
          </div>
        </div>
        <div class="col-12 mb-2">
          <label for="firstLastNameInput" class="form-label">Primer apellido</label>
          <input type="text" class="form-control login-input" id="firstLastNameInput" formControlName="firstLastName"
            maxlength="30"
            [ngClass]="firstLastName!.invalid && (firstLastName!.touched || this.isSubmited )? 'is-invalid':''">
          <div *ngIf="firstLastName!.invalid && ( firstLastName!.touched || this.isSubmited )"
            class="d-block text-error">
            <span *ngIf="firstLastName!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="firstLastName!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX",
              firstLastName!.errors!.minlength.requiredLength) }}
            </span>
            <span *ngIf="firstLastName!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX",
              firstLastName!.errors!.maxlength.requiredLength) }}
            </span>
            <span *ngIf="firstLastName!.errors?.['pattern']">
              {{ validationMsg['lettersSpanish'+'pattern'] }}
            </span>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="secondLastNameInput" class="form-label">Segundo apellido</label>
          <input type="text" class="form-control login-input" id="secondLastNameInput" formControlName="secondLastName"
            maxlength="30"
            [ngClass]="secondLastName!.invalid && (secondLastName!.touched || this.isSubmited )? 'is-invalid':''">
          <div *ngIf="secondLastName!.invalid && ( secondLastName!.touched || this.isSubmited )"
            class="d-block text-error">
            <span *ngIf="secondLastName!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="secondLastName!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX",
              secondLastName!.errors!.minlength.requiredLength )}}
            </span>
            <span *ngIf="secondLastName!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX",
              secondLastName!.errors!.maxlength.requiredLength )}}
            </span>
            <span *ngIf="secondLastName!.errors?.['pattern']">
              {{ validationMsg['lettersSpanish'+'pattern'] }}
            </span>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="phoneInput" class="form-label">Teléfono</label>
          <input type="text" class="form-control login-input" id="phoneInput" formControlName="phone"
            [ngClass]="phone!.invalid && (phone!.touched || this.isSubmited )? 'is-invalid':''"
            (keypress)="keyPressPhone($event)" maxlength="8">
          <div *ngIf="phone!.invalid && ( phone!.touched || this.isSubmited )" class="d-block text-error">
            <span *ngIf="phone!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="phone!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX", phone!.errors!.minlength.requiredLength )}}
            </span>
            <span *ngIf="phone!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX", phone!.errors!.maxlength.requiredLength )}}
            </span>
            <span *ngIf="phone!.errors?.['pattern']">
              {{ validationMsg['numberpattern']}}
            </span>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="emailInput" class="form-label">Correo electrónico</label>
          <input type="email" class="form-control login-input" id="emailInput" formControlName="email" maxlength="50"
            [ngClass]="email!.invalid && (email!.touched || this.isSubmited )? 'is-invalid':''">
          <div *ngIf="email!.invalid && ( email!.touched || this.isSubmited )" class="text-danger">
            <span *ngIf="email!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="email!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX", email!.errors!.minlength.requiredLength )}}
            </span>
            <span *ngIf="email!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX", email!.errors!.maxlength.requiredLength )}}
            </span>
            <span *ngIf="email!.errors?.['pattern']">
              {{ validationMsg['emailpattern']}}
            </span>
          </div>

        </div>
        <div class="col-12 mb-3">
          <label for="passwordInput" class="form-label">Contraseña</label>
          <div class="input-group password-input mb-2">
            <input [type]="showPassword ? 'text' : 'password'" formControlName="password" id="passwordInput"
              class="form-control password-border" maxlength="30"
              [ngClass]="password!.invalid && (password!.touched || this.isSubmited )? 'is-invalid':''">
            <i class="bi" [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword"
              (click)="togglePasswordVisibility()"></i>
          </div>
          <div *ngIf="password!.invalid && ( password!.touched || this.isSubmited )" class="text-danger">
            <span *ngIf="password!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="password!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX", password!.errors!.minlength.requiredLength )}}
            </span>
            <span *ngIf="password!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX", password!.errors!.maxlength.requiredLength )}}
            </span>
            <span *ngIf="password!.errors?.['pattern']">
              {{ validationMsg['passwordpattern']}}
            </span>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="edad" formControlName="isAdult">
            <label class="form-check-label" for="edad">
              Soy mayor de edad
            </label>
          </div>
          <div *ngIf="isAdult!.invalid && this.isSubmited" class="text-danger">
            <span>
              {{ validationMsg['required'] }}
            </span>
          </div>
        </div>

        <div class="col-12">
          <p>Al realizar el registro acepto los
            <a [href]="this.urlBase + this.documentTerms!.url" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a> y las
            <a [href]="this.urlBase + this.documentPolicy!.url" target="_blank" rel="noopener noreferrer">Políticas de privacidad</a>
          </p>
        </div>

        <div class="col-12 mb-3">
          <button class="btn btn-primary px-4" type="button" (click)="Submit()">Registrarse</button>
        </div>

      </div>
    </form>


  </div>
  <app-modal-general [showModal]="showModalGeneralSignUp" (showModalChange)="closeModalSignUp($event)" [modalData]="ModalGeneralSignUp"></app-modal-general>

</main>

<!-- Modal de consentimiento informado -->
<app-modal-consentimiento [showModal]="this.showCI" [modalData]="modalCI" [document]="this.documentConsent"
(showModalChange)="acceptInformedConsent($event)">
</app-modal-consentimiento>
