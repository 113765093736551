import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IModal } from 'src/app/models/modal.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { ProductsService } from 'src/app/services/core/products.service';
import { SwapsService } from 'src/app/services/core/swaps.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { environment } from 'src/environments/environment';

declare let window: any;
const settings = new SitefinitySettings();

@Component({
  selector: 'app-frequent-plan',
  templateUrl: './frequent-plan.component.html',
  styleUrls: ['./frequent-plan.component.css'],
})
export class FrequentPlanComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private _productService: ProductsService,
    private _utilitiesService: UtilitiesServicesService,
    private router: Router,
    private loader: LoadingService,
    private Swaps: SwapsService,
    public notificationService: NotificationService,
    private sitefinityPagesService: SitefinitypagesService
  ) {
    window.addEventListener('popstate', this.handlePopState.bind(this));
  }

  urlSite = environment.urlSite;
  urlBase = environment.urlBase;
  codeParam = '';
  products: any = [];
  countsProducts: number = settings.Top;
  searchValue!: string;
  settings: SitefinitySettings = new SitefinitySettings();
  error: boolean = false;

  //for General Modal: modalConfirmExchange
  showModalConfirmExchange = false;
  modalConfirmExchange: IModal = {
    Title: 'Plan paciente',
    Text: '¿Deseás que Farmacia La Bomba tramite el canje de este producto?',
    TextSmall: 'Al indicar que deseás tramitar el canje, la farmacia validará si el canje está disponible y luego se comunicará con vos.',
    BtnConfirmText: 'Tramitar canje',
    ModalName: 'modalConfirmExchange',
  };
  selectedProduct: any;

  //for General Modal: modalExchangeResult
  showModalExchangeResult = false;
  modalExchangeResult: IModal = {
    ModalName: 'modalExchangeResult',
  };
  searched = false;
  user: any;
  userId: any;
  state: any;

  ModalExchangeResultConfirmation(event: any) {
    if (event == true) {
      this.showModalExchangeResult = false;
      if (
        this.modalExchangeResult.UrlBtnConfirm &&
        this.modalExchangeResult.UrlBtnConfirm.trim() != ''
      ) {
        window.open(this.modalExchangeResult.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalExchangeResult = false;
    }
  }

  ModalConfirmExchangeConfirmation(event: any) {
    if (event == true) {
      this.ExchangeSelectedProduct();
    } else {
      this.showModalConfirmExchange = false;
    }
  }

  ShowConfirmation(product: any) {
    let isUserLogin = this._utilitiesService.isUserLoggedIn();
    if (!isUserLogin) {
      this.loader.showSpinner();
      let source = 'planpaciente';
      let code = product.code;
      this.router.navigate(['iniciosesion'], {state: {source, code}});
      setTimeout(() => {
        //Se agrega para mejorar user experience
        this.loader.hideSpinner();
      }, 5000);
    } else {
      this.selectedProduct = product;
      this.showModalConfirmExchange = true;
    }
  }

  ngOnInit(): void {
    this.sitefinityPagesService.setDetailPage(
      this.settings.pages.find((x) => x.Title === 'planpaciente')!.ID
    );
    this.state = window.history.state;
    this.codeParam = this.state.code;
    this.getAllExchanges();
  }

  getAllExchanges() {
    this.loader.showSpinner();
    this._productService.GetAllExchangesMethod('').subscribe((res) => {
      this.searched = true;
      if (res.response.code == 'OK0001') {
        this.products = res.responseObject;
        //Si viene del redirect del login
        if (this.codeParam != undefined && this.codeParam != '') {
          let filteredProduct = this.products.filter((x: { code: string }) => x.code === this.codeParam);
          if (filteredProduct.length > 0) {
            let product = filteredProduct[0];
            this.ShowConfirmation(product);
          }
        }
      } else {
        this.products = [];
        this.error = true;
      }
      this.loader.hideSpinner();
    });
  }

  loadProducts() {
    if (this.countsProducts < this.products.length)
      this.countsProducts = this.countsProducts + 8;
  }

  async ExchangeSelectedProduct() {
    this.loader.showSpinner();
    this.showModalConfirmExchange = false;

    let isUserLogin = this._utilitiesService.isUserLoggedIn();
    if (!isUserLogin) {
      setTimeout(() => {
        this.loader.hideSpinner();
        this.router.navigate(['/iniciosesion']);
      }, 1000);
    } else {
      //Pre Canje

      this.user = this._utilitiesService.getValueStorage('sessionStorage');
      this.userId = await this._utilitiesService.decryptData(this.user.i);

      const request: any = {
        pharmacie: 'ID123456',
        rule: 'SKU789012',
        sku: 'ID123456',
        identification: this.userId,
        clientFullName: 'ID123456',
        phone: 'SKU789012',
      };
      this.Swaps.PreSwapRequestMethod(request).subscribe((o) => {
        if (o.code == 'precanje-success') {
          //acá mostraría el tercer modal
          this.notificationService.SendNotification(
            'success',
            'Canje exito',
            'Canje tramitado con éxito'
          );
        } else {
          //set modalResult data
          this.modalExchangeResult.Text = o.message;
          this.modalExchangeResult.Title = o.titleMessage;
          this.modalExchangeResult.BtnConfirmText = o.buttonAcceptText;
          this.modalExchangeResult.UrlBtnConfirm = o.url;
          this.showModalExchangeResult = true;
        }
        this.loader.hideSpinner();
      });
    }
  }

  searchProduct() {
    if (this.searchValue != null) {
      this._productService
        .GetAllExchangesMethod(this.searchValue)
        .subscribe((res) => {
          if (res.response.code == 'OK0001') {
            this.products = res.responseObject;
          } else {
            this.products = [];
          }
        });
    }
  }

  handlePopState(): void {
    window.location.reload();
  }
}
