import { DatePipe, formatCurrency, formatDate, getCurrencySymbol } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ICanton } from 'src/app/models/canton.model';
import { CartAddress } from 'src/app/models/core/cart.model';
import { IModal } from 'src/app/models/modal.model';
import { IProvince } from 'src/app/models/provinces.model';
import { MycurrencyPipe } from 'src/app/models/settings/custom.currencypipe';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { CheckoutService } from 'src/app/services/core/checkout.service';
import { ClientsService } from 'src/app/services/core/clients.service';
import { ProductsService } from 'src/app/services/core/products.service';
import { ShopsService } from 'src/app/services/core/shops.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { MessagesService } from 'src/app/services/sitefinity/messages.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { HelperCheckoutService } from 'src/app/services/core/helper-checkout.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { sl } from 'date-fns/locale';
declare let window: any;


const settings = new SitefinitySettings();
@Component({
  selector: 'app-checkout-shipping',
  templateUrl: './checkout-shipping.component.html',
  styleUrls: ['./checkout-shipping.component.css']
})
export class CheckoutShippingComponent implements OnInit {

  currency!: MycurrencyPipe;
  form!: FormGroup;
  shipping: boolean = true;
  addresses: any = [];
  userId!: string;
  cartId!: string;
  settings: SitefinitySettings = new SitefinitySettings;
  totalPrice: number = 0;
  subTotalPrice: number = 0;
  discount: number = 0;
  taxes: number = 0;
  provinces: IProvince[]= [];
  cantons: ICanton[] = [];
  pharmacies: any[] = [];
  selectedProvince: number = 0;
  selectedCanton!: number;
  selectedPharmacie: any;
  selectedAddress!: CartAddress;
  isSubmited: boolean = false;
  validationMsg = this.settings.validationMsgTypeMap;
  dates: any[] = [];
  selectedDate!: string;
  selectedHour!: string;
  hours: any[] = [];
  infoPharmacie: any;
  schedules!: string;
  minPurchase!: string;
  minPurchaseToGo!: string;
  modal: IModal = {
    ModalName: 'modalShipping'
  }
  modalToGo: IModal = {
    ModalName: 'modalToGo'
  }
  modalSchedule: IModal = {
    ModalName: 'modalSchedule',
    Title: '',
    Text: '',
    BtnConfirmText: '',
    ShowCancelBtn: false
  }
  modalShopOutOfTime: IModal = {
    ModalName: 'modalShopOutOfTime',
    ShowCancelBtn: false
  }
  modalShop: IModal = {
    ModalName: 'modalShop',
    ShowCancelBtn: false
  }
  modalCuponNotDelete: IModal = {
    ModalName: 'modalCuponNotDelete',
    ShowCancelBtn: false
  }
  modalDirection: any;
  showModalSchedule: boolean = false;
  showModalMinPurchase: boolean = false;
  showModalShopOutOfTime: boolean = false;
  showDirection: boolean = false;
  modalShippingClosed: boolean = true;
  latitude: number = 0;
  longitude: number = 0;
  skus: any = [];
  quantities: any = [];
  productsCart: any;
  showMessage: boolean = false;
  showModalShop: boolean = false;
  showModalCuponNotDelete: boolean =  false;
  addressExpanded: number | null = null;
  impulseProduct: any = [];
  client: any;
  private cartUpdateSubscription!: Subscription;
  categorieOfert: any = [];
  showModalToGoMinPurchase: boolean = false;
  idPharmacie: any;
  lastDate!: string;
  lastHour!: string;
  formatted: boolean = false;
  lastPharmacie: any;
  idShop!: number;
  idClientAddress!: number;
  idDeliveryMethod!: number;
  lastProvince: any;
  lastCanton: any;
  test = false;
  hourCart: any;
  dateCart: any;
  loyaltyCateries: any;
  validateLoyaltyInformation: boolean = false;
  spinnerNumber: number = 0;
  closeSpinner: boolean = false;
  modalCupon: IModal = {
    ModalName: 'modalCupon',
    ShowCancelBtn: false
  };
  coupons: {
    Code: string,
    DisplayDiscount: boolean
    LocalCouponId: number
  }[] =  [];
  showModalCupon: boolean = false;
  formCupon!: FormGroup;
  // BeneficitCouponsModal: IModal = {
  //   ModalName: 'BeneficitCouponsModal'
  // }
  // showBeneficitCouponsModal: boolean = false;
  // ValidateBeneficitCouponsModal: IModal = {
  //   ModalName: 'ValidateBeneficitCouponsModal',
  //   ShowCancelBtn: false
  // }

  // ValidateShowBeneficitCouponsModal: boolean = false;
  isCouponSubmited: boolean = false;
  totalPriceCart: number = 0;
subTotalPriceCart: number = 0;
 UserAddCoupons: boolean = false;

 switchcaseOption: number = 0;
 responseInventory: any;

 @ViewChild('continueButton') continueButton?: ElementRef<HTMLButtonElement>;
 isButtonDisabled = true; // Inicialmente deshabilitado

  constructor(
    private clientService: ClientsService,
    private utilitiesService: UtilitiesServicesService,
    private _utilitiesService: UtilitiesService,
    private sitefinityPagesService : SitefinitypagesService,
    private loader: LoadingService,
    private checkoutService: CheckoutService,
    private shopService: ShopsService,
    private cartService: CartService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private router: Router,
    private messageService: MessagesService,
    private sharedService: SharedService,
    private helperCheckoutService: HelperCheckoutService,
    private products: ProductsService,
    private http: HttpClient
  ){



    this.formCupon = this.fb.group({
      cupon: ['', [Validators.required,Validators.minLength(3)]]
    });

    this.form = this.fb.group({
      province: ['', [Validators.required]],
      canton: ['', [Validators.required]],
      pharmacie: ['', [Validators.required]],
      date: ['', [Validators.required]],
      hour: ['', [Validators.required]]
    });
  }

  get province() { return this.form.get('province'); }
  get canton() { return this.form.get('canton'); }
  get pharmacie() { return this.form.get('pharmacie'); }
  get date() { return this.form.get('date'); }
  get hour() { return this.form.get('hour'); }

  get cupon() { return this.formCupon.get('cupon')}


  async ngOnInit(): Promise<void> {
   this.loader.showSpinner();
    let user: any;
    setTimeout(() => {

      this.isButtonDisabled = false;
      this.loader.hideSpinner();
    }, 3500);

    if (this.utilitiesService.isUserLoggedIn()) {
      this.isButtonDisabled = true;
      this.UserAddCoupons = false;
     // this.callVerifyIfClienteHasBenefits();
      user = this.utilitiesService.getValueStorage('sessionStorage');
      this.userId = await this.utilitiesService.decryptData(user.i);
      this.cartId = await this.utilitiesService.decryptData(user.c);
      this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'proceso-compra')!.ID);
     this._utilitiesService.GetParametersByKey('LoyaltyCategories').subscribe(lc => {

     let categories = JSON.parse(lc.value[0].value);
      this.loyaltyCateries = categories;

     this.getClientInfo();
      this.getDates();
      this.getCategoriesHeader();
     // this.getLoyaltyCategories();
});
      this.modalDirection = new window.bootstrap.Modal(
        document.getElementById('modalDirection')
      );

      this.cartUpdateSubscription = this.sharedService.cartUpdated$.subscribe(() => {
        this.getCartSummary();
      });
    }
    else {
      this.router.navigate(['/iniciosesion']);
    }

  }

  ngOnDestroy() {
    if (this.cartUpdateSubscription)
      this.cartUpdateSubscription.unsubscribe(); // Asegúrate de desuscribirte en onDestroy
  }

  getLocation() {
    this.utilitiesService.getPosition().then(pos => {
    setTimeout(() => {
         this.utilitiesService.getPosition().then(pos => {
          this.latitude = pos.lat;
          this.longitude = pos.lng;
      });


    }, 1200);

    });
}

  getCartSummary(): void {

   if (this.continueButton) {
        this.continueButton.nativeElement.disabled = false;
        this.isButtonDisabled = false;
      }

    this.loader.showSpinner();
    this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.updateCartInfo(res);
        this.validateDeliveryMethod(res);
        this.getProvinces();
      }
      else if (res.response.code === 'emptyCart-lightSummary') {
        this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
          if (res.response.code === "OK0001") {
            this.updateCartInfo(res);
            this.validateDeliveryMethod(res);
            this.getProvinces();
          }
        });
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      }
      this.loader.hideSpinner();
    });
  }

  private validateDeliveryMethod(res: any) {
    if (res.responseObject.PurchaseInfo.Address.IdDeliveryMethod!=2) {
      this.shipping = true;
      this.getToGoMinPurchaseAmount();
      this.updateShipping(res);
    }
    else {
      this.updateShippingExpress(res);
    }
  }

  private updateCartInfo(res: any) {
    this.productsCart = res.responseObject.ProductQueue;
    this.productsCart.forEach((p: any) => {
      let sku = p.SelectedAttributes.length > 0 ? p.SelectedAttributes[0].Sku3 : p.Sku;
      this.skus.push(sku);
    });
    this.quantities = this.productsCart.map((p: any) => p.Quantity.toString());
    this.skus = this.skus.join(',');
    this.quantities = this.quantities.join(',');
    this.totalPrice = res.responseObject.PurchaseInfo.TotalWithDiscount;
    this.subTotalPrice = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
    this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
    this.discount = res.responseObject.PurchaseInfo.Discount;
    this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;



  }

  private updateShipping(res: any) {
    if (res.responseObject.PurchaseInfo.Address.IdAddressProvince && res.responseObject.PurchaseInfo.Address.IdAddressCanton && res.responseObject.PurchaseInfo.Address.IdShop && res.responseObject.PurchaseInfo.Address.PickupDate && res.responseObject.PurchaseInfo.Address.PickupTime) {
      this.selectedProvince = res.responseObject.PurchaseInfo.Address.IdAddressProvince;
      this.selectedCanton = res.responseObject.PurchaseInfo.Address.IdAddressCanton;
      this.idPharmacie = res.responseObject.PurchaseInfo.Address.IdShop;
      this.dateCart = res.responseObject.PurchaseInfo.Address.PickupDate;
      this.hourCart = res.responseObject.PurchaseInfo.Address.PickupTime;

      if (this.hourCart)
        this.hourCart = this.hourCart.toLowerCase();

      if (this.dateCart)
        this.dateCart = formatDate(this.dateCart, 'd-M-yyyy', 'en-US');

      this.loader.hideSpinner();
    }
    else {
      this.loader.hideSpinner();
      this.closeSpinner = true;
    }
  }

  updateShippingExpress(res: any) {
    this.shipping = false;
    this.isSubmited = false;
    this.getClient(res.responseObject.PurchaseInfo.Address);
    this.getMinPurchaseAmount();
    this.messageService.getMessagesMethod('CARTEPA0001', '1').subscribe(message => {
      this.validateMinPurchase(this.minPurchase, message);
      this.loader.hideSpinner()
    });
  }
  getCategoriesHeader() {
    this.products.GetCategoriesHeaderMethod(0).subscribe(response => {
      if (response.response.code == "OK0001") {
        this.categorieOfert = response.categories.filter(c => c.Name === 'Descuentos');
      }
    });
  }

  closeModal(event: any) {
    if (event == true) {
      this.showModalSchedule = false;
      if(this.modalSchedule.UrlBtnConfirm && this.modalSchedule.UrlBtnConfirm.trim() != ''){
        window.open(this.modalSchedule.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalSchedule = false;
    }
  }

  closeModalShipping(event: any) {
    if (event == true) {
      this.showModalMinPurchase = false;
      let url = this.categorieOfert[0].URL;
      this.router.navigateByUrl(`categorias/${url}`);
      if(this.modal.UrlBtnConfirm && this.modal.UrlBtnConfirm.trim() != ''){
        window.open(this.modal.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalMinPurchase = false;
      this.shipping = true;
      this.getToGoMinPurchaseAmount();
    }
  }

  closeModalShippingToGo(event: any) {
    let url = this.categorieOfert[0].URL;
    if (event == true) {
      this.showModalToGoMinPurchase = false;
      this.router.navigateByUrl(`categorias/${url}`);
      if(this.modalToGo.UrlBtnConfirm && this.modalToGo.UrlBtnConfirm.trim() != ''){
        window.open(this.modalToGo.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalToGoMinPurchase = false;
      this.shipping = true;
      this.redirectToCart();
    }
  }

  closeModalShopOutOfTime(event: any) {
    if (event == true) {
      this.showModalShopOutOfTime = false;
      if(this.modalShopOutOfTime.UrlBtnConfirm && this.modalShopOutOfTime.UrlBtnConfirm.trim() != ''){
        window.open(this.modalShopOutOfTime.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalShopOutOfTime = false;
    }
    this.validateBlock();
  }

  closeModalShop(event: any) {
    if (event == true) {
      this.showModalShop = false;
      if(this.modalShop.UrlBtnConfirm && this.modalShop.UrlBtnConfirm.trim() != ''){
        window.open(this.modalShop.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalShop = false;
    }
  }

  closeModalCuponNotDelete(event: any) {
    if (event == true) {
      this.showModalCuponNotDelete = false;
      this.redirectToCart(false, false, false);
      this.loader.hideSpinner();
    } else {
      this.showModalCuponNotDelete = false;
    }
  }

  onChangeShipping(): void {
    this.shipping = !this.shipping;
    if (!this.shipping) {
      this.isSubmited = false;
      this.getClient(null);
      this.getMinPurchaseAmount();
      this.messageService.getMessagesMethod('CARTEPA0001', '1').subscribe(message => {
        this.validateMinPurchase(this.minPurchase, message);
      });
    }
    else {
      this.getToGoMinPurchaseAmount();
    }
  }

  private getClientAddressProfile(address: any): void {
    this.loader.showSpinner();
    this.clientService.GetClientAddressProfileMethod(this.userId).subscribe(res => {
      if (res.response.code === 'OK0001') {
        this._utilitiesService.GetParametersByKey('DefaultPadronNameDirection').subscribe((o) => {
          this.addresses = res.responseObject.Addresses;
          let defaultPadronNameDirection: string = '';
          defaultPadronNameDirection = this._utilitiesService.decodeData(o.value[0].value);
          this.addresses = this.addresses.filter((a: {Name: any; IdShop: null; }) => a.IdShop != null && a.Name != defaultPadronNameDirection);
          this.addresses = this.addresses.sort((a: any, b: any) => b.IdClientAddress - a.IdClientAddress);
          let currentAddress;
          if (address == null) {
            currentAddress = this.addresses[0];
          }
          else {
            currentAddress = this.addresses.find((p:{IdClientAddress:any;})=>p.IdClientAddress === address.IdClientAddress);
          }
          let lastAddress;
          if (currentAddress) {
            lastAddress = currentAddress;
            this.selectedAddress = lastAddress;
          }
          else {
            this.selectedAddress = this.addresses[0];
          }
        });
      }
      else {
        this.addresses = [];
      }
      this.loader.hideSpinner();
    });
  }

  getClientInfo() {
    this.clientService.GetUserPersonalInfoMethod(this.userId).subscribe(res => {
      if (res.response.code === 'OK0001') {
        this.client = res.responseObject;
        if (this.client.LoyaltyInformation != null) {
          this.validateLoyaltyInformation = this.loyaltyCateries.some((lc: { category: any; }) => lc.category.toLowerCase() === this.client.LoyaltyInformation.toLowerCase());
        }
      }
      else {
        this.client = {};
      }
      this.getCartSummary();
    });
  }

  isSelected(address: any): boolean {
    return address === this.selectedAddress;
  }

  private getProvinces(): void {
    this.loader.showSpinner();
    this._utilitiesService.getProvinces().subscribe(res => {
      if (res.Messages && res.Messages.length > 0 && res.Messages[0] === 'OK0001'){
        this.provinces = JSON.parse(res.Parameters);
      }
      setTimeout(() => {
        this.loader.hideSpinner();
      }, 2000);
    });
    if (this.closeSpinner)
      this.loader.hideSpinner();
  }

  onChangeProvince(event: any): void {
    if (this.lastProvince != undefined && this.lastProvince != 0 && event != this.lastProvince){
      this.selectedCanton = 0;
      this.lastHour = '';
    }
    if (event != this.lastProvince) {
      this.isSubmited = false;
      this.cantons = [];
      this.pharmacies = [];
      this.infoPharmacie = null;
      this.hours = [];
      this.lastDate = '';
      this.selectedDate = '';
      this.selectedHour = '';
      this.lastPharmacie = '';
      if (this.spinnerNumber === 3 || this.closeSpinner) {
      this.loader.showSpinner();
      }
      this._utilitiesService.GetCantonsByIDProvince(this.selectedProvince.toString()).subscribe(res => {
      if (res.Messages && res.Messages.length > 0 && res.Messages[0] === 'OK0001') {
        this.cantons = JSON.parse(res.Parameters);
      }
      if (this.spinnerNumber === 3 || this.closeSpinner) {
      this.loader.hideSpinner();
      }
      });
    }

    this.lastProvince = event;
  }

  onChangeCanton(event: any): void {
    if (this.lastCanton != undefined && this.lastCanton != 0 && event != this.lastCanton) {
      this.dateCart = '';
      this.hourCart = '';
    }
    if (event != this.lastCanton) {
      this.pharmacies = [];
      this.infoPharmacie = null;
      this.hours = [];
      this.lastDate = '';
      this.selectedDate = '';
      this.selectedHour = '';
      this.lastHour = '';
      this.selectedPharmacie = '';
      this.lastPharmacie = '';
      this.isSubmited = false;
      if (this.spinnerNumber === 3 || this.closeSpinner) {
      this.loader.showSpinner();
      }
      if (this.selectedCanton) {
        this.checkoutService.GetShopsByLocationMethod(this.selectedProvince, this.selectedCanton, 0).subscribe(res => {
          if (res.response.code === "OK0001") {
            this.pharmacies = res.object;
            this.pharmacies = this.pharmacies.filter(p => p.IsItToGo === true);
            this.lastPharmacie = this.pharmacies.find(p => p.IdShop === this.idPharmacie);
            if (this.selectedDate === this.lastDate && this.lastPharmacie)
              this.hours = this.lastPharmacie.TimeSchedulerNextDayI;
          }
          if (this.spinnerNumber === 3 || this.closeSpinner) {
          this.loader.hideSpinner();
          }
        });
      }
    }

    this.lastCanton = event;
  }


  previousPharmacie: any;
  getInfoPharmacie(event: any): void {


    if (event !== this.previousPharmacie) {


    if ((this.selectedPharmacie != undefined && this.selectedPharmacie != '') && event?.IdShop != this.selectedPharmacie.IdShop) {
      this.selectedDate = '';
      this.lastDate = '';
      this.lastHour = '';
      this.selectedHour = '';
      this.dateCart = '';
      this.hourCart = '';
    }
    this.isSubmited = false;
    const typesList: number[] = [];
    if (this.spinnerNumber === 3 || this.closeSpinner) {
    this.loader.showSpinner();
    }
    this.shopService.GetShopsByShopTypeV2Method(typesList, this.selectedProvince, this.selectedCanton, 0).subscribe(res => {
      if (res.response.code === 'OK0001') {

        this.updateInfoShop(res, event);
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      }
      this.onChangePharmacie(null);
      if (this.spinnerNumber === 1) {
        this.closeSpinner = true;
      }
      if (this.spinnerNumber === 3 || this.closeSpinner) {
      this.loader.hideSpinner();
      }
    });

 }
 else
  {

//console.log("entro aqui al else")

  }



  }

  updateInfoShop(res: any, event: any) {
    if (event != undefined && (event != this.selectedPharmacie || event != this.lastPharmacie)) {
      this.selectedPharmacie = event;
      this.infoPharmacie = res.object.find((p: { IDShop: any; }) => p.IDShop === event.IdShop);
    }
    else {
      this.infoPharmacie = res.object.find((p: { IDShop: any; }) => p.IDShop === this.selectedPharmacie?.IdShop || p.IDShop === this.selectedPharmacie);
    }
    if (this.infoPharmacie) {
      // Optimización para obtener la disponibilidad con Alias no nulo e IdAvailabilityType igual a 7
      const availability = this.infoPharmacie.Availabilities?.find((avail: { Alias: null; IdAvailabilityType: number; }) => avail.Alias !== null && avail.IdAvailabilityType === 7);
      this.schedules = availability ? availability.Alias : null;
    }
  }

  onChangePharmacie(event?: any): void {
    if (event != undefined && this.selectedDate != '' && this.selectedDate != event) {
      this.selectedHour = '';
      this.lastHour = '';
      this.hourCart = '';
    }
    this.isSubmited = false;
    this.hours = [];
    const date = formatDate(new Date(), 'd-M-yyyy', 'en-US');
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);
    const formatNextDate = formatDate(nextDate, 'd-M-yyyy', 'en-US');
    this.selectedDate = event;
    if (!this.selectedPharmacie)
      this.selectedPharmacie = this.pharmacies.find(p => p.IdShop === this.idPharmacie || this.infoPharmacie?.IDShop);

    this.showModalSchedule = false;
    if (this.lastPharmacie != '' && this.lastPharmacie != undefined && !event) {
      this.lastDate = this.dateCart;
      this.lastHour = this.hourCart;
      this.selectedDate = this.lastDate;
      this.selectedHour = this.lastHour;
    }
    if (this.selectedPharmacie != 0 && this.selectedPharmacie != undefined && event) {
      if (this.selectedPharmacie.TimeScheduler?.length === 0 && this.selectedPharmacie.TimeSchedulerNextDayI?.length === 0 && this.selectedPharmacie.TimeSchedulerNextDayII?.length === 0) {
        this.pharmacieWithoutHours();
      }
      else {
        this.showModalSchedule = false;
        if (this.selectedDate === date.toString()) {
          if (this.selectedPharmacie.TimeScheduler.length > 0) {
            this.hours = this.selectedPharmacie.TimeScheduler;
          }
          else {
            this.pharmacieWithoutHours();
          }

        }
        else if (this.selectedDate === formatNextDate) {
          if (this.selectedPharmacie.TimeSchedulerNextDayI.length > 0) {
            this.hours = this.selectedPharmacie.TimeSchedulerNextDayI;
          }
          else {
            this.pharmacieWithoutHours();
          }
        }
        else {
          if (this.selectedPharmacie.TimeSchedulerNextDayII.length > 0) {
            this.hours = this.selectedPharmacie.TimeSchedulerNextDayII;
          }
          else {
            this.pharmacieWithoutHours();
          }
        }
      }
    }
    else if (this.lastPharmacie?.IdShop) {
      if (this.lastPharmacie.TimeScheduler?.length === 0 && this.lastPharmacie.TimeSchedulerNextDayI?.length === 0 && this.lastPharmacie.TimeSchedulerNextDayII?.length === 0) {
        this.pharmacieWithoutHours();
      }
      else {
        this.showModalSchedule = false;
        if (this.lastDate === date.toString()) {
          if (this.lastPharmacie.TimeScheduler.length > 0) {
            this.hours = this.lastPharmacie.TimeScheduler;
            this.validateHour(this.hours);
          }
          else {
            this.pharmacieWithoutHours();
          }

        }
        else if (this.lastDate === formatNextDate) {
          if (this.lastPharmacie.TimeSchedulerNextDayI.length > 0) {
            this.hours = this.lastPharmacie.TimeSchedulerNextDayI;
            this.validateHour(this.hours);
          }
          else {
            this.pharmacieWithoutHours();
          }
        }
        else {
          if (this.lastPharmacie.TimeSchedulerNextDayII.length > 0) {
            this.hours = this.lastPharmacie.TimeSchedulerNextDayII;
            this.validateHour(this.hours);
          }
          else {
            this.pharmacieWithoutHours();
          }
        }
      }
    }
    if (this.spinnerNumber < 3 && !this.closeSpinner) {
      this.spinnerNumber = this.spinnerNumber + 1;
    }
    else {
      this.loader.hideSpinner();
    }

    if (this.closeSpinner)
      this.loader.hideSpinner();
  }

  pharmacieWithoutHours() {
    this.messageService.getMessagesMethod('PharmacieWithoutHours', '1').subscribe(message => {
      this.modalSchedule.Title = message.titleMessage;
      this.modalSchedule.Text = message.message;
      this.modalSchedule.BtnConfirmText = message.buttonAcceptText;
      this.showModalSchedule = true;
    });
  }

  onChangeHour(event: any) {
    if (this.dateCart != '' && this.dateCart != undefined) {
      let date = this.dates.find(d => d === this.dateCart)
      if (!date) {
        this.dateCart = '';
        this.hourCart = '';
        this.selectedDate = '';
        this.lastDate = '';
        this.lastHour = '';
        this.selectedHour = '';
      }
    }
  }

  validateHour(hours: any) {
    if (this.lastHour != '' && this.lastHour != undefined) {
      let t = this.lastHour;
      t = t.replace(/am/i, 'a.m.').replace(/pm/i, 'p.m.');
      let h =hours.some((hs: { Time12HrsFormatII: string; }) => hs.Time12HrsFormatII.toLowerCase() === t);
      if (!h) {
        this.lastHour = '';
        this.selectedHour = '';
        this.hourCart = '';
      }
    }
  }

  validateShipping2(): void {
    if (!this.shipping) {
      this.selectedAddress.IdDeliveryMethod = 2;
      this.addAddressToCart(0,0,0,'2','','',this.selectedAddress);
    }
    else {
      if (this.selectedPharmacie === undefined)
        this.selectedPharmacie = this.lastPharmacie;

      if (this.selectedHour === undefined)
        this.selectedHour = this.lastHour;

      if (this.selectedDate === undefined)
        this.selectedDate = this.lastDate;

      if (this.selectedProvince === 0 || this.selectedCanton === 0 || this.selectedPharmacie === 0 || this.selectedDate === "" || this.selectedHour === "") {
        this.isSubmited = true;
      }
      else {
        this.addAddressToCart(this.selectedProvince, this.selectedCanton, this.selectedPharmacie, '1', this.selectedDate, this.selectedHour);
      }
    }
  }

  addAddressToCart(province: number, canton: number, shop: any, deliveryMethod: string, date: string, hour: string, cartRequest?: any) {
    let request: any;
    const partes = date.split("-");
    const fechaFormateada = partes[2] + "-" + partes[1] + "-" + partes[0] + " 00:00:00";
    if (cartRequest) {
      request = {
        DeliveryMethodName: null,
        DeliveryMethodSecondChoice: null,
        FullAddress: cartRequest.FullAddress,
        IdAddressCanton: cartRequest.IDAddressCanton,
        IdAddressDistrict: cartRequest.IDAddressDistrict,
        IdAddressProvince: cartRequest.IDAddressProvince,
        IdClientAddress: cartRequest.IDClientAddress,
        IdDeliveryArea: cartRequest.IDDeliveryArea,
        IdDeliveryMethod: cartRequest.IdDeliveryMethod,
        IdShop: cartRequest.IDShop,
        InactiveShop: false,
        IsDefault: cartRequest.IsDefault,
        IsOpen: true,
        MapPoints: cartRequest.MapPoints,
        Name: cartRequest.Name,
        Phone: null,
        PickupDate: null,
        PickupTime: '',
        Status: cartRequest.Status,
        TableNumber: 0,
        ToSave: false,
        ShopCode: "",
      }
    }
    else {
      request = {
        DeliveryMethodName: null,
        DeliveryMethodSecondChoice: null,
        FullAddress: shop.Address,
        IdAddressCanton: canton,
        IdAddressDistrict: 0,
        IdAddressProvince: province,
        IdClientAddress: 0,
        IdDeliveryArea: 0,
        IdDeliveryMethod: parseInt(deliveryMethod),
        IdShop: shop.IdShop,
        IsDefault: false,
        IsOpen: true,
        MapPoints: this.infoPharmacie.ShopMapPoints,
        Name: shop.Name,
        Phone: null,
        PickupDate: fechaFormateada,
        PickupTime: hour,
        Status: "AP",
        TableNumber: 0,
        ToSave: false
      };
    }
    this.loader.showSpinner();
    this.cartService.AddAddressToCartMethod(this.cartId, request).subscribe(res => {

      if (res.response.code === "OK0001-AddAdress-success") {
        this.idShop = res.responseObject.IdShop;
        this.idClientAddress = res.responseObject.IdClientAddress;
        this.idDeliveryMethod = res.responseObject.IdDeliveryMethod;
            this.cartService.GetCartSummaryMethod(this.cartId).subscribe(async o => {

          if (o.response.code === "OK0001") {
            this.helperCheckoutService.ValidateScheduleAvailabilityMethod(res.responseObject.IdShop).subscribe(f => {

              if (f.response.code === 'OK0001') {
                this.validateBlock();
              }
              else if (f.response.code === "CARTADDVAL0001") {
                this.modalShopOutOfTime.Title = f.response.titleMessage;
                this.modalShopOutOfTime.Text = f.response.message;
                this.modalShopOutOfTime.BtnConfirmText = f.response.buttonAcceptText;
                this.showModalShopOutOfTime = true;
                this.loader.hideSpinner();
              }
              else {
                this.notificationService.SendNotification(f.response.type, f.response.titleMessage, f.response.message);
                this.loader.hideSpinner();
              }
            });
          }
          else {
            this.validateCodes(o.response);
            this.loader.hideSpinner();
          }
        })
      }
      else {
        this.validateCodes(res.response);
        this.loader.hideSpinner();
      }
    });
  }

  validateInventory() {
    const request = {
      IDAddress: this.idClientAddress,
      IDAddressSpecified: true,
      IDCart: this.cartId,
      IDDeliveryMethod: this.idDeliveryMethod,
      IDDeliveryMethodSpecified: true,
      IDShop: this.idShop,
      IDShopSpecified: true,
      Quantities: this.quantities,
      Skus: this.skus
    }
    this.checkoutService.GetProductsInventoryMethod(request).subscribe(pi => {
      if (pi.response.code != "ERR0001") {
        if (pi.response.code === 'INF0001') {
          this.messageService.getMessagesMethod('ERRP001', '1').subscribe(m => {
            this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
            this.loader.hideSpinner();
          });
        }
        else {
          const pv = pi.object.some((p: any) => !p.ThereIsInventory);
          if (pi && pv) {
            this.redirectToCart(true);
          }
          else {
            this.loader.showSpinner();
            this.redirectToPayment();
          }
        }
      }
      else {
        this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
          this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
          this.loader.hideSpinner();
        });
      }
    });
  }

  validateblockTotalTimes: any = 0;
  validateBlock() {

  if(this.validateblockTotalTimes == 0) {
    this.loader.showSpinner();
    this.validateblockTotalTimes = 1; // esto para validar que no se repita varias veces el llamado.
    this.helperCheckoutService.ValidateShopAvailabilityByBlockMethod(this.idShop).subscribe(block => {

      if (block.response.code === "OK0001") {
        const request = {
          IDAddress: this.idClientAddress,
          IDAddressSpecified: true,
          IDCart: this.cartId,
          IDDeliveryMethod: this.idDeliveryMethod,
          IDDeliveryMethodSpecified: true,
          IDShop: this.idShop,
          IDShopSpecified: true,
          Quantities: this.quantities,
          Skus: this.skus
        }
        switch (block.object) {
          case 1:
            this.checkoutService.GetProductsInventoryMethod(request).subscribe(pi => {
              if (pi.response.code != "ERR0001") {
                if (pi.response.code === 'INF0001') {
                  this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
                    this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
                    this.loader.hideSpinner();
                  });
                }
                else {


                  var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
                  var c  = this.utilitiesService.decryptData(x.c);

                  var url =  settings.CoreServicesUrl + '/BombaCart.svc/ApplyCouponsFromDokka/'+ c
                  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                  var result = this.http.get(url,{ headers })

                  result.subscribe((o:any)=>{
                    this.formCupon.reset();


                       const pv = pi.object.some((p: any) => !p.ThereIsInventory);
                      if (pi && pv) {
                        this.redirectToCart(true);
                      }
                      else {
                        this.loader.showSpinner();
                        this.redirectToPayment();


                  }


                  });

                }
              }
              else {
                this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
                  this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
                  this.loader.hideSpinner();
                });
              }
            });
            break;
          case 2:
            this.checkoutService.GetProductsInventoryMethod(request).subscribe(p => {
              if (p.response.code != "ERR0001") {
                if (p.response.code === 'INF0001') {
                  this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
                    this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
                    this.loader.hideSpinner();
                  });
                }
                else {




                  var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
                  var c  = this.utilitiesService.decryptData(x.c);

                  var url =  settings.CoreServicesUrl + '/BombaCart.svc/ApplyCouponsFromDokka/'+ c
                  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                  var result = this.http.get(url,{ headers })

                  result.subscribe((o:any)=>{


                    this.formCupon.reset();


                    const pi = p.object.some((p: any) => !p.ThereIsInventory);
                    if (p && pi) {
                      this.redirectToCart(true);
                    }
                    else {
                      this.loader.showSpinner();
                      this.redirectToPayment();
                    }
                  });
                }
              }
              else {
                this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
                  this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
                  this.loader.hideSpinner();
                });
              }
            });
            break;
          case 3:
            this.checkoutService.GetProductsInventoryMethod(request).subscribe(pw => {
              if (pw.response.code != "ERR0001") {
                if (pw.response.code === 'INF0001') {
                  this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
                    this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
                    this.loader.hideSpinner();
                  });
                }
                else {



                  var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
                  var c  = this.utilitiesService.decryptData(x.c);

                  var url =  settings.CoreServicesUrl + '/BombaCart.svc/ApplyCouponsFromDokka/'+ c
                  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                  var result = this.http.get(url,{ headers })

                  result.subscribe((o:any)=>{

                    this.formCupon.reset();



                    const productValid = pw.object.some((p: any) => !p.ThereIsInventory);
                    if (pw && productValid) {
                      this.redirectToCart(true);
                    }
                    else {
                      this.loader.showSpinner();
                      this.redirectToPayment();
                    }

                  })


                }





              }
              else {
                this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
                  this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
                  this.loader.hideSpinner();
                });
              }
            });
            break;
          default:
            this.loader.hideSpinner();
            break;
        }
      }
      else {
        this.notificationService.SendNotification(block.response.type, block.response.titleMessage, block.response.message);
        this.loader.hideSpinner();
      }
    });

  }

  }






  getDates(): void {
    // Obtener la fecha actual
    const fechaActual = new Date();
    // Generar opciones para los próximos 2 días
    for (let i = 0; i < 3; i++) {
      this.dates.push(this.datePipe.transform(fechaActual, 'd-M-yyyy'));
      fechaActual.setDate(fechaActual.getDate() + 1);
    }
  }

  redirectToPayment(): void {
    this.router.navigate(['/payment'], { skipLocationChange: true });
  }

  async getMinPurchaseAmount(): Promise<void> {

    if (!this.validateLoyaltyInformation) {
    await this._utilitiesService.GetConfigValueMethod("ExpressMinPurchaseAmount").subscribe(res => {
      if (res) {
        this.minPurchase = res;
        this.messageService.getMessagesMethod('CARTEPA0001', '1').subscribe(message => {
          this.validateMinPurchase(this.minPurchase, message);
        });
      }
    });
  }
  }

  getLoyaltyCategories(): void {
    this._utilitiesService.GetParametersByKey('LoyaltyCategories').subscribe(lc => {
      let categories = JSON.parse(lc.value[0].value);
      this.loyaltyCateries = categories;
    });
  }

  async getToGoMinPurchaseAmount(): Promise<void> {
    if (!this.validateLoyaltyInformation) {
    await this._utilitiesService.GetConfigValueMethod("ToGoMinPurchaseAmount").subscribe(res => {
      if (res) {
        this.minPurchaseToGo = res;
        this.messageService.getMessagesMethod('CARTTPA0001', '1').subscribe(message => {
          this.validateToGoMinPurchase(this.minPurchaseToGo, message);
        });
      }
    });
  }
  }

  validateMinPurchase(minPurchase: string, message?: any): void {

    if (this.totalPrice < parseInt(minPurchase)) {
      const currencyCode: string = 'CRC';
      const locale: string = 'es';
      const symbol = getCurrencySymbol(currencyCode, 'narrow');
      const digitsInfo: string = '1.2-2';

      let min = formatCurrency(parseInt(minPurchase), locale, symbol, currencyCode, digitsInfo);
      min = min.replace(/\s+/g, '');
      let price = parseInt(minPurchase) - this.totalPrice;
      let formatPrice = formatCurrency(price, locale, symbol, currencyCode, digitsInfo);
      formatPrice = formatPrice.replace(/\s+/g, '');
      let priceMinPurchase = min;
      let updateMessage = message.message.replace('₡* *MinExpress* *', formatPrice);
      updateMessage = updateMessage.replace(/\s+/g, ' ');
      let text = updateMessage.replace('₡* *MinExpress2* *', priceMinPurchase);
      text = text.replace(/\s+/g, ' ');
      const finalText = text.trim();
      this.modal.Title = message.titleMessage;
      this.modal.Text = finalText;
      this.modal.BtnConfirmText = message.buttonAcceptText;
      this.modal.BtnCancelText = message.buttonCancelText;
      this.showModalMinPurchase = true;
    }
  }

  validateToGoMinPurchase(minPurchase: string, message?: any): void {
    if (this.totalPrice < parseInt(minPurchase)) {
      const currencyCode: string = 'CRC';
      const locale: string = 'es';
      const symbol = getCurrencySymbol(currencyCode, 'narrow');
      const digitsInfo: string = '1.2-2';

      let min = formatCurrency(parseInt(minPurchase), locale, symbol, currencyCode, digitsInfo);
      min = min.replace(/\s+/g, '');
      let updateMessage = message.message.replace('₡ * *MinTogo2* *', min);
      updateMessage = updateMessage.replace(/\s+/g, ' ');
      const finalText = updateMessage.trim();
      this.modalToGo.Title = message.titleMessage;
      this.modalToGo.Text = finalText;
      this.modalToGo.BtnConfirmText = message.buttonAcceptText;
      this.modalToGo.ShowCancelBtn = false;
      this.showModalToGoMinPurchase = true;
    }
  }

  redirectToCart(show?: boolean, showMaxPurchase?: boolean, showProductOutOfTime?: boolean): void {
    if (show || showMaxPurchase || showProductOutOfTime) {
      this.router.navigate(['/proceso-compra'], {
        state: { show, showMaxPurchase, showProductOutOfTime }
      });
    }
    else {
      this.router.navigate(['/proceso-compra']);
    }
  }

  showModalDirection() {
    this.getLocation();
    setTimeout(() => {
      this.showDirection = true;
      this.modalDirection.show();
    }, 300);
  }

  closeModalDirection(event: boolean) {
    this.showDirection = !event;
    this.modalDirection.hide();
    this.getClient(null);
  }

  validateCodes(response: any) {
    this.modalShop.Title = response.titleMessage;
    this.modalShop.Text = response.message;
    this.modalShop.BtnConfirmText = response.buttonAcceptText;

    switch (response.code) {
      case 'ADDVAL0002':
      case 'ADDVAL0003':
      case 'ADDVAL0004':
      case 'ADDVAL0005':
      case 'CARTVAL0011':
      case 'CARTVAL0013':
      case 'CARTVAL0009':
      case 'CARTVAL0036':
      case 'CARTVAL0047':
        this.showModalShop = true;
        this.loader.hideSpinner();
        break;

      case 'CARTVALINV001':
      case 'CARTVAL0037':
      case 'CARTVAL0038':
        this.redirectToCart(true, true, false);
        this.loader.hideSpinner();
        break;

      case 'CARTVAL0014':
      case 'CARTVAL0015':
      case 'CARTVAL0016':
      case 'CARTVAL0017':
      case 'CARTVAL0040':
      case 'CARTVAL0042':
      case 'CARTVAL0043':
        this.redirectToCart(false, false, true);
        this.loader.hideSpinner();
        break;

      case 'CARTVAL0046':
        this.redirectToCart(true, true);
        break;

      case 'CARTNOTFS0001':

        this.messageService.getMessagesMethod('CARTNOTFS0001', '1').subscribe(response => {
         // this.notificationService.SendNotification(response.type, response.titleMessage, response.message);
          this.redirectToCart(false, false, true);
          this.loader.hideSpinner()
        });
      break;
      case 'ERR0001':
        // let r = JSON.stringify(response);
        // this.utilitiesService.insertErrorObservable(this.cartId, 'CARTSUMMARY', r, 'CARTSUMARRY').subscribe(t => {
        // });
        this.notificationService.SendNotification(response.type, response.titleMessage, response.message);
        this.loader.hideSpinner();
        break;

      case 'NOPP':
        this.modalCuponNotDelete.Title = response.titleMessage;
        this.modalCuponNotDelete.Text = response.message;
        this.modalCuponNotDelete.BtnConfirmText = response.buttonAcceptText;
        this.showModalCuponNotDelete = true;
        break;

      default:
        this.showModalShop = true;
    }
  }

  getImpulseProduct() {
    this.checkoutService.GetImpulseProductsMethod(this.cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.impulseProduct = res.object;
      }
    });
  }

  goToDetailProduct(url: string): void {
    if( url != null) {
      this.router.navigate(['/detalle-producto', url]);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  deleteAddress2(address: any) {
    this.loader.showSpinner();
    this.addresses = [];
    this.addresses = this.client.Addresses.filter((a: { IDShop: null; }) => a.IDShop != null);
    this.client.Addresses = this.addresses.filter((o:any) => o.IDClientAddress != address.IdClientAddress);
    this.addresses = this.addresses.filter((o:any) => o.IDClientAddress != address.IdClientAddress);
    this.addresses = this.addresses.sort((a: any, b: any) => b.IDClientAddress - a.IDClientAddress);
    if (!this.client.AcceptTerms) this.client.AcceptTerms = true;
    this.clientService.DeletelientInfoMethod(this.client).subscribe((res) => {
      if (res.code === 'UpdateClientInfoMethod-success') {
        this.clientService.getMessages("UpdateAddress-SUCCESS","1").subscribe((o: { type: any; titleMessage: any; message: any; }) =>{
          this.notificationService.SendNotification(o.type, o.titleMessage, o.message);
              this.loader.hideSpinner();
        });
      }
      else {
        this.notificationService.SendNotification(res.type, res.titleMessage, res.message);
      }
      this.loader.hideSpinner();
    });
  }

  deleteAddress(address: any) {
    this.loader.showSpinner();
    if (this.client.Addresses.length > 0) {

      this._utilitiesService.GetParametersByKey('DefaultPadronNameDirection').subscribe((o) => {
        let defaultPadronNameDirection: string = '';
        //let t = JSON.parse(o.Data);
        let t = o.value;
        defaultPadronNameDirection = t[0].value;
        this.addresses = this.client.Addresses.filter((a: { Name: string; IDShop: null; }) => a.IDShop != null && a.Name != defaultPadronNameDirection);
        this.addresses = this.addresses.filter((ad: any) => ad.IDClientAddress !== address.IDClientAddress);
        this.client.Addresses = this.addresses;
        this.addresses = this.addresses.sort((a: any, b: any) => b.IDClientAddress - a.IDClientAddress);
        if (!this.client.AcceptTerms) this.client.AcceptTerms = true;
        this.clientService.DeletelientInfoMethod(this.client).subscribe((res) => {
          if (res.code === 'UpdateClientInfoMethod-success') {
            this.clientService.getMessages("UpdateAddress-SUCCESS","1").subscribe((o: { type: any; titleMessage: any; message: any; }) => {
              this.notificationService.SendNotification(o.type, o.titleMessage, o.message);
              this.loader.hideSpinner();
            });
          }
          else {
            this.notificationService.SendNotification(res.type, res.titleMessage, res.message);
            this.loader.hideSpinner();
          }
          this.loader.hideSpinner();
        });
      });
    }
    else
    {
      this.loader.hideSpinner();

    }
  }



callVerifyIfClienteHasBenefits()
{

var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
var y  = this.utilitiesService.decryptData(x.u);


const url = settings.BusDeServiciosUrlDev + 'Cupones.svc/rest/ConsultaBeneficiosDescripcionCliente';
//const url = 'http://devbusfischel.loymarkservices.com/Cupones.svc/rest/ConsultaBeneficiosDescripcionCliente';
const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

const body = {
  Identificacion: y,
  Campana: '',
  Canal:"WEB"
};

const jsonData = JSON.stringify(body);

var result = this.http.post(url, jsonData, { headers })
result.subscribe((o:any) =>{


if(o.Ok == true)
{
  const jsonObject = JSON.parse(o.Value);


  const benefitCards = this.generateBenefitCardsHTML(jsonObject);


  const concatenatedStrings: string[] = jsonObject.map((benefit:any) => `Campaña: ${benefit.NombreCampana}, Cupón: ${benefit.Campana}`);
  const finalString = concatenatedStrings.join(', ');


  // this.BeneficitCouponsModal.Title = "Beneficios";
  // this.BeneficitCouponsModal.Text = benefitCards;
  // this.BeneficitCouponsModal.BtnConfirmText = "Aceptar";
  // this.BeneficitCouponsModal.ShowCancelBtn = false;
  // this.showBeneficitCouponsModal = true;

}

});

}

closeModalCoupons(event: any) {
  if (event == true) {
 //   this.showBeneficitCouponsModal = false;

    this.loader.hideSpinner();
  } else {
 //   this.showBeneficitCouponsModal = false;
  }
}

callShippingValidations()
{
  this.formCupon.reset();
  if (!this.shipping) {
    this.selectedAddress.IdDeliveryMethod = 2;
    this.addAddressToCart(0,0,0,'2','','',this.selectedAddress);
  }

  if (this.selectedPharmacie === undefined){
    this.selectedPharmacie = this.lastPharmacie;
  }

  if (this.selectedHour === undefined){
    this.selectedHour = this.lastHour;
  }

  if (this.selectedDate === undefined){
    this.selectedDate = this.lastDate;
  }

  if (this.selectedProvince === 0 || this.selectedCanton === 0 || this.selectedPharmacie === 0 || this.selectedDate === "" || this.selectedHour === "") {
    this.isSubmited = true;
  }

  else {
    this.addAddressToCart(this.selectedProvince, this.selectedCanton, this.selectedPharmacie, '1', this.selectedDate, this.selectedHour);
  }



}

closeValidateModalCoupons(event: any, switchEvent: any, response: any)
{
  this.formCupon.reset();

  if(switchEvent == 1)
  {
    const pv = response.object.some((p: any) => !p.ThereIsInventory);
                    if (response && pv) {
                      this.redirectToCart(true);
                    }
                    else {
                      this.loader.showSpinner();
                      this.redirectToPayment();
                  }
  }
    if(switchEvent == 2)
      {
          const pi = response.object.some((p: any) => !p.ThereIsInventory);
          if (response && pi) {
            this.redirectToCart(true);
          }
          else {
            this.loader.showSpinner();
            this.redirectToPayment();
          }
      }
    if(switchEvent == 3)
      {
        const productValid = response.object.some((p: any) => !p.ThereIsInventory);
        if (response && productValid) {
          this.redirectToCart(true);
        }
        else {
          this.loader.showSpinner();
          this.redirectToPayment();
        }

      }

 // this.callShippingValidations()

}

updateCartSummary(): void {
  this.loader.showSpinner();
  this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
    if (res.response.code === "OK0001") {
      this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
      this.subTotalPriceCart = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
      this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
      this.discount = res.responseObject.PurchaseInfo.Discount;
      this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;
      this.getCartSummary();
    }
    else if (res.response.code === 'emptyCart-lightSummary') {
      this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
        if (res.response.code === "OK0001") {
          this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
          this.subTotalPriceCart = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
          this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
          this.discount = res.responseObject.PurchaseInfo.Discount;
          this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;
          this.getCartSummary();
        }
        else {
          // let r = JSON.stringify(res);
          // this.utilitiesService.insertErrorObservable(this.cartId, 'CARTSUMMARY', r, 'CARTSUMARRY').subscribe(t => {
          // });
          this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        }
      });
    }
    else {
      this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
    }
    this.loader.hideSpinner();
  });
}


validatePreviewsclick:any
validateShipping(event:any): void {
  this.loader.showSpinner();
  if (event !== this.previousPharmacie) {

    this.formCupon.reset();
    if (!this.shipping) {
      this.selectedAddress.IdDeliveryMethod = 2;
      this.addAddressToCart(0,0,0,'2','','',this.selectedAddress);
    }
    else {
    if (this.selectedPharmacie === undefined){
      this.selectedPharmacie = this.lastPharmacie;
    }

    if (this.selectedHour === undefined){
      this.selectedHour = this.lastHour;
    }

    if (this.selectedDate === undefined){
      this.selectedDate = this.lastDate;
    }

    if (this.selectedProvince === 0 || this.selectedCanton === 0 || this.selectedPharmacie === 0 || this.selectedDate === "" || this.selectedHour === "") {
      this.isSubmited = true;
      this.loader.hideSpinner();
    }

    else {
      this.addAddressToCart(this.selectedProvince, this.selectedCanton, this.selectedPharmacie, '1', this.selectedDate, this.selectedHour);
    }
  }
  }
  else {
    this.loader.hideSpinner();
  }
}

addCoupon(): void {

  if (this.formCupon.invalid)
    this.isCouponSubmited = true;
  else {
    this.loader.showSpinner();
    const exists = this.coupons.some(coupon => coupon.Code === this.formCupon.value.cupon);
    if(exists)
    {

      this.formCupon.value.cupon = "";
      this.formCupon.get('cupon')?.setValue('');
      this.formCupon = this.fb.group({
        cupon: ['', [Validators.required]]
      });
      this.loader.hideSpinner();
      this.isCouponSubmited = false;
      return;

    }





    var x: any =  this.utilitiesService.getValueStorage('sessionStorage');
    var y  = this.utilitiesService.decryptData(x.u);


    const url = settings.BusDeServiciosUrlDev + 'Cupones.svc/rest/ValidacionActivacionCupones';

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const body = {
      CodigoBeneficio:this.formCupon.value.cupon,
      Identificacion: y,
      IdentificacionTipo: '',
      Canal:"WEB"
    };

    const jsonData = JSON.stringify(body);

    var result = this.http.post(url, jsonData, { headers })
    result.subscribe((o:any) =>{

      if(o.Ok == true)
      {
        this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.formCupon.value.cupon, "1", "0").subscribe(res => {


          if (res.response.code === "CAPP") {
            this.UserAddCoupons = true;
            this.coupons.push({
              Code: this.formCupon.value.cupon,
              DisplayDiscount: false, // Valor por defecto
              LocalCouponId: 0        // Valor por defecto
            });
            this.formCupon.value.cupon = "";
            this.formCupon.get('cupon')?.setValue('');
            this.formCupon = this.fb.group({
              cupon: ['', [Validators.required]]
            });
            this.loader.hideSpinner();
            this.isCouponSubmited = false;

              // this.updateCartSummary();
              // this.sharedService.updateCart();

          }

        });







      }
      else
      {
//No funciono.
        this.UserAddCoupons = false;
        this.formCupon.value.cupon = "";
        this.formCupon.get('cupon')?.setValue('');
        this.formCupon = this.fb.group({
          cupon: ['', [Validators.required]]
        });

        const jsonObject = JSON.parse(o.Messages);



        // this.BeneficitCouponsModal.Title = "Beneficios";
        // this.BeneficitCouponsModal.Text = jsonObject[0].Mensaje;

        //  this.BeneficitCouponsModal.ShowCancelBtn = false;
        // this.BeneficitCouponsModal.ShowAcceptBtn = false;
        // this.showBeneficitCouponsModal = true;
        this.loader.hideSpinner();
        this.isCouponSubmited = false;
      }




    });

    // this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.form.value.cupon, "1", "0").subscribe(res => {
    //   if (res.response.code === "CAPP") {
    //     this.messageService.getMessagesMethod('CAPP-1', '1').subscribe(m => {
    //       this.modalCupon.Title = m.titleMessage;
    //       this.modalCupon.Text = m.message;
    //       this.modalCupon.BtnConfirmText = m.buttonAcceptText;
    //       this.showModalCupon = true;
    //      // this.updateCartSummary();
    //       this.sharedService.updateCart();
    //     });
    //   }
    //   else {
    //     this.modalCupon.Title = res.response.titleMessage;
    //     this.modalCupon.Text = res.response.message;
    //     this.showModalCupon = true;
    //   }
    // });
  }
}

deleteCoupon(coupon: any): void {




  this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, coupon.Code, "2", "0").subscribe(res => {
    if (res.response.code === "CDEL") {

      // Filtrar la lista para remover el cupón
     this.coupons = this.coupons.filter(c => c.Code !== coupon.Code);

     // También puedes usar findIndex y splice, aunque esto ya no sería necesario después de filter
     const index = this.coupons.findIndex(c => c.Code === coupon.Code);
     if (index !== -1) {
       this.coupons.splice(index, 1);
     }

      // this.updateCartSummary();
      // this.sharedService.updateCart();
    }
    else {
      this.modalCupon.Title = res.response.titleMessage;
      this.modalCupon.Text = res.response.message;
      this.showModalCupon = true;
    }
  });
}






generateBenefitCardsHTML(coupons: any[]): string {
  // Inicializar la variable que contendrá el HTML de la tabla con estilos en línea
  let htmlString = `
      <table style="width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 18px; text-align: left;">
          <thead>
              <tr>
                  <th style="padding: 12px 20px; border: 1px solid #ddd; background-color: #00828E; color: white; text-align: center;">Nombre de la Campaña</th>
                  <th style="padding: 12px 20px; border: 1px solid #ddd; background-color: #00828E; color: white; text-align: center;">Cupón</th>
              </tr>
          </thead>
          <tbody>
  `;

  // Iterar sobre el arreglo de cupones y crear una fila de la tabla para cada uno
  coupons.forEach(coupon => {
      htmlString += `
          <tr>
              <td style="padding: 12px 20px; border: 1px solid #ddd;">${this.escapeHtml(coupon.NombreCampana)}</td>
              <td style="padding: 12px 20px; border: 1px solid #ddd;">${this.escapeHtml(coupon.Campana)}</td>
          </tr>
      `;
  });

  // Cerrar las etiquetas de tbody y table
  htmlString += `
          </tbody>
      </table>
  `;

  return htmlString;
}


escapeHtml(unsafe: string): string {
  return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
}

  getClient(address: any) {
    this.clientService.GetUserPersonalInfoMethod(this.userId.toString()).subscribe((res) => {
      if (res.response.code === 'OK0001') {
        this.client = res.responseObject;
        let defaultPadronNameDirection: string = '';
        this._utilitiesService.GetParametersByKey('DefaultPadronNameDirection').subscribe((o) => {
          debugger;
          console.log(o);
          //let t = JSON.parse(o.value);
          let t = o.value;
          defaultPadronNameDirection = t[0].value;// this._utilitiesService.decodeData(t[0].value);
          this.addresses = this.client.Addresses.filter((a: {Name: any; IDShop: null; }) => a.IDShop != null && a.Name != defaultPadronNameDirection);
          this.addresses = this.addresses.sort((a: any, b: any) => b.IDClientAddress - a.IDClientAddress);
          let currentAddress;
          if (address == null) {
            currentAddress = this.addresses[0];
          }
          else {
            currentAddress = this.addresses.find((p:{IdClientAddress:any;})=>p.IdClientAddress === address.IdClientAddress);
          }
          let lastAddress;
          if (currentAddress) {
            lastAddress = currentAddress;
            this.selectedAddress = lastAddress;
          }
          else {
            this.selectedAddress = this.addresses[0];
          }
        });
      }
      else {
        this.addresses = [];
      }
    });
  }

}
