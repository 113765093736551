<main class="main">
  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="color-primary">
      Información legal
    </h1>

    <div class="mb-3">
      <div class="input-sections">
        <label for="exampleFormControlInput1" class="form-label">Buscar por</label>
        <select class="form-select mb-4 container-input" aria-label="Buscar por" aria-placeholder="Buscar por"
          style="max-width: 100%;" [(ngModel)]="selectedOption" (change)="onChangeTypeOfDocuments()">
          <option *ngFor="let item of this.typeOfDocuments; let i=index" [value]="item.id">{{ item.name }}</option>
        </select>
      </div>

    </div>

    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col" *ngFor="let item of this.listOfData; let i = index">
        <div class="card h-100 border-color">

          <div class="card-body">
            <h4 class="card-title">{{ item.name }}</h4>

          </div>
          <div class="card-footer border-color">
            <a class="btn btn-link px-0" [href]="this.urlBaseDoc + item.url" target="_blank" rel="noopener noreferrer">
              Ver documento</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Sin resultados -->
    <div class="text-center mt-5" *ngIf="this.listOfData.length === 0 && showMessage">
      <i class="bi bi-search icon-page"></i>
      <h1 class="">Sin resultados</h1>
      <p class="">No se encontraron resultados que coincidan con los datos especificados en la búsqueda.
      </p>
    </div>

    <div *ngIf="this.totalDocuments > top && this.listOfData.length !== this.totalDocuments"
      class="d-flex align-items-center justify-content-center mt-3">
      <button class="btn btn-primary" (click)="loadMore()">Cargar más</button>
    </div>

  </div>
</main>
