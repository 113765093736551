import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MessagesService } from './messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import {InsurersResponse,Insurers} from 'src/app/models/sitefinity/insurers.model'

const settings = new SitefinitySettings();

@Injectable({
  providedIn: 'root'
})
export class InsurersService {

  private GetInsuresUrl = settings.SitefinityServicesUrl + 'api/default/insurers?$expand=InsurerLogo';
  private GetInsuresUrlTotal = settings.SitefinityServicesUrl + 'api/default/insurers/$count';

  constructor(private http: HttpClient, private MessagesService_: MessagesService,
  private ErrorOrLogsServices: UtilitiesServicesService) { }

  private GetInsuresUrlService():Observable<any>
  {
    const url =  this.GetInsuresUrl;
    return  this.http.get(url);
  }

  private GetTotalInsuresService():Observable<any>
  {
    const url =  this.GetInsuresUrlTotal;
    return  this.http.get(url);
  }


  public GetInsurersMethod(): Observable<any> {
    var InsuresResponse = this.GetInsuresUrlService();
    var TotalInsures = this.GetTotalInsuresService();

    return InsuresResponse.pipe(
      switchMap(response => {
        const Odata = response.value;
        var ArrayInsurers: Insurers[] = [];

        Odata.forEach((DataItem: any) => {
            ArrayInsurers.push(DataItem);
        });
        if(ArrayInsurers.length > 0 )
        {
          ArrayInsurers = ArrayInsurers.sort((a: {InsurerPriority: number},b:{InsurerPriority: number})=>a.InsurerPriority - b.InsurerPriority);
          return TotalInsures.pipe(
            switchMap(total => this.handleCondition("Insurers-success", ArrayInsurers, total))
          );
        }
        else
        {
          return this.handleCondition("Insurers-fail01",ArrayInsurers,0)
        }
      })
    );
  }

  private handleCondition(code:string,Faqs: Insurers[], total:number): Observable<InsurersResponse> {
    return new Observable<InsurersResponse>(observer => {
      this.MessagesService_.getMessagesMethod(code,'1').subscribe(message => {
        const result: InsurersResponse = {
          Insurers: Faqs,
          response: message,
          total:total
        };
        observer.next(result);
        observer.complete();
      })
    })
  }
}



