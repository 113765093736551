import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { ClientsService } from 'src/app/services/core/clients.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { IModal } from 'src/app/models/modal.model';



@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  settings: SitefinitySettings = new SitefinitySettings;
  validationMsg = this.settings.validationMsgTypeMap;
  form!: FormGroup;
  isSubmited: boolean = false;


  modalGeneralRecoveryPassword: IModal = {
    ModalName: 'modalGeneralRecoveryPassword',
    Title:'',
    Text:'',
    BtnConfirmText: '',
    ShowCancelBtn: false,
  }

  showModalGeneralRecoveryPassword:boolean = false;


  constructor(
    private _clientsService: ClientsService,
    private _notificationService: NotificationService,
    private fb: FormBuilder,
    private loader: LoadingService,
    private notificationService: NotificationService,
    public sitefinityPagesService : SitefinitypagesService,
    private router: Router
  ) {
    this.form = this.fb.group({
      UserEmail: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(50), Validators.email]],
    });
  }
  ngOnInit(): void {
    this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'recuperar-contrasena')!.ID);
  }

  get UserEmail() { return this.form.get('UserEmail'); }

  RecoverPassword(formData: any) {
    if (this.form.invalid) {
      this.isSubmited = true;
    }
    else {
      this.loader.showSpinner();
      if (formData.UserEmail != '') {
        this._clientsService.RecoverPasswordMethod(formData.UserEmail).subscribe(res => {
          if (res.response.code === "recoverpassword-01") {
            this.loader.hideSpinner();
            this.CleanFields();


            if(res.response.buttonAcceptText != '')
              {
                this.showModalGeneralRecoveryPassword = false;
                this.modalGeneralRecoveryPassword.Title = res.response.titleMessage;
                this.modalGeneralRecoveryPassword.Text =  res.response.message;
                this.modalGeneralRecoveryPassword.BtnConfirmText = res.response.buttonAcceptText;
                this.modalGeneralRecoveryPassword.UrlBtnConfirm = res.response.url;
                this.showModalGeneralRecoveryPassword = true;

              }
              else
              {



                this.notificationService.SendNotification('success', res.response.titleMessage, res.response.message)
                setTimeout(() => {
                  this.router.navigate(['/iniciosesion'])
                }, 5000);

              }








          }
          else {
            this.loader.hideSpinner();
            if(res.response.buttonAcceptText != '')
              {
                this.showModalGeneralRecoveryPassword = false;
                this.modalGeneralRecoveryPassword.Title = res.response.titleMessage;
                this.modalGeneralRecoveryPassword.Text =  res.response.message;
                this.modalGeneralRecoveryPassword.BtnConfirmText = res.response.buttonAcceptText;
                this.modalGeneralRecoveryPassword.UrlBtnConfirm = res.response.url;
                this.showModalGeneralRecoveryPassword = true;

              }
              else
              {


                this._notificationService.SendNotification('error', res.response.titleMessage, res.response.message)

              }
          }
        }, err => {
          this.loader.hideSpinner();
          this._notificationService.SendNotification('error', 'Error', 'Ocurrió un error inesperado');
        });
      }
    }
  }

  CleanFields() {
    this.form.controls["UserEmail"].setValue('')
    this.form.controls["UserEmail"].markAsUntouched()
    this.form.controls["UserEmail"].markAsPristine()
    this.isSubmited = false;
  }



  closeModalPassword(event: any, isButtonAccept?:boolean)
  {

    this.showModalGeneralRecoveryPassword = false;
    if(this.modalGeneralRecoveryPassword.UrlBtnConfirm != '')
      {
        let x:any = this.modalGeneralRecoveryPassword.UrlBtnConfirm
        this.loader.showSpinner();
        const parts: string[] = x.split('/');
        const lastPart:  string = "/"+ parts[parts.length - 1];
        this.router.navigate([lastPart]);
    }


  }

}
