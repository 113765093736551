import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable,combineLatest,forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreRequest } from 'src/app/models/requests/request.model';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import { Clients,SignIn,ResponseSignIn,ResponseSignUp,InfoSignUp,Client,ClientAddress,
  MapPoints,Phone,ReducedClientRequest,ReducedClientResponse,ClientObjectGlobalResponse,
  ElectoralRollPersonalDataResponse,GetInfoClientResponse, ClientPersonalInfo,
  RequestFavoriteProduct, AddressProfile, WCFRequestAddress   }
from 'src/app/models/core/clients.model';

import { HighlightedProducts,ProductImage,Products,Request_productsBySkus,ReducedExchange,
    ExchangeReducedImage,ResponseHighlightedProducts, ResponseProducts,ResponseCategoriesHeader,Category,SearchRequest }
from 'src/app/models/core/products.model';

import {ProductsService} from 'src/app/services/core/products.service'
import { parse } from 'date-fns';
import {    ResponseGenericObject }
from 'src/app/models/core/products.model';
import { ObjectGlobalResponse} from 'src/app/models/core/utilities.model';
const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})
export class LifeMilesService {

  constructor(
    private http: HttpClient,
    private MessagesService_ :MessagesService,
    private ErrorOrLogsServices:UtilitiesServicesService,
    private products: ProductsService,
    private utilitiesService: UtilitiesServicesService) { }



    public AccrualLifemilesServices(request: any):Observable<any> {
      const url = settings.SitefinityServicesUrl + 'AccrualLifemilesServices';
      const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});

      return  this.http.post(url, request,{ headers });
  }


}
