import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreResponse } from 'src/app/models/responses/response.model';
import { UtilitiesResponse } from 'src/app/models/core/utilities.model';
import { ResponseShopsByTypes, WCFRequestShopsByTypes, ShopAvailability } from 'src/app/models/core/shops.model';
import { CoreRequest } from 'src/app/models/requests/request.model';
import {FaqsResponse,Faqs} from 'src/app/models/sitefinity/faqs.model'

const settings = new SitefinitySettings();

@Injectable({
  providedIn: 'root'
})
export class FAQsService {



  private GetFAQsUrl = settings.SitefinityServicesUrl + 'api/default/faqs';
  private GetFAQsUrlTotal = settings.SitefinityServicesUrl + 'api/default/faqs/$count';


    constructor(private http: HttpClient, private MessagesService_: MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService) { }


    private GetFAQsUrlService(skip: number, top:number):Observable<any>
    {
      const url =  this.GetFAQsUrl;
      return  this.http.get(url);

    }

    private GetTotalFaqsService():Observable<any>
    {
      const url =  this.GetFAQsUrlTotal;
      return  this.http.get(url);

    }


    public GetFAQsMethod(skip: number, top: number): Observable<FaqsResponse> {
      var FaqsResponse = this.GetFAQsUrlService(skip, top);
      var TotalFaqs = this.GetTotalFaqsService();
     // ResponseInterface
      return FaqsResponse.pipe(
        switchMap(response => {
          const Odata = response.value;
          var ArrayFaqs: Faqs[] = [];

          Odata.forEach((DataItem: any) => {
            const FaqsResponse:Faqs = {
              priority: DataItem.priority,
              question: DataItem.question,
              answer: DataItem.answer,
               id: DataItem.Id,
             };

             ArrayFaqs.push(FaqsResponse);


          });

          if(ArrayFaqs.length > 0 )
          {


                return TotalFaqs.pipe(
                  switchMap(total => this.handleCondition("Faqs-success", ArrayFaqs, total))
                );

          }
         else
          {


                return this.handleCondition("Faqs-fail01",ArrayFaqs,0)

          }






        })
      );
    }

    private handleCondition(code:string,Faqs: Faqs[], total:number): Observable<FaqsResponse> {

      return new Observable<FaqsResponse>(observer => {

        this.MessagesService_.getMessagesMethod(code,'1').subscribe(message => {

          const result: FaqsResponse = {
            faqs: Faqs,
            response: message,
            total:total
          };
          observer.next(result);
          observer.complete();
        })


      })



    }



}



