import { Component, OnInit } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { LegalDocuments } from 'src/app/models/sitefinity/type-of-documents.model';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { DocumentsService } from 'src/app/services/sitefinity/documents.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';

const settings = new SitefinitySettings();
@Component({
  selector: 'app-legal-information',
  templateUrl: './legal-information.component.html',
  styleUrls: ['./legal-information.component.css']
})
export class LegalInformationComponent {
  selectedOption: any = '';
  typeOfDocuments: any = [];
  listOfData: LegalDocuments[] = [];
  urlBase = settings.SitefinityServicesUrl;
  urlBaseDoc = settings.SitefinityServicesUrlDoc;
  top = settings.informacionLegal.top;
  totalDocuments = 0;
  showMessage = false;

  typeOrderList = 1;

  constructor(
    private utilities: UtilitiesService,
    private documentService: DocumentsService,
    private sitefinityPagesService: SitefinitypagesService) {
    this.selectedOption = 'all';
    this.sitefinityPagesService.setDetailPage(settings.pages.find(x => x.Title === 'Informacion-legal')!.ID);
    settings.informacionLegal.skip = settings.skip;
    settings.informacionLegal.top = settings.Top;
    this.utilities.GetParametersByKey('InformationLegalSettings').subscribe((response: any) => {
      const settingsLegalInformation: any = JSON.parse(response.value[0].value);
      settings.informacionLegal.top = settingsLegalInformation.top;
      settings.informacionLegal.skip = settingsLegalInformation.skip;

      this.getDataServices();
    });
  }

  getDataServices() {
    const observables: Observable<any>[] = [
      this.documentService.GetDocumentsType(),
      this.documentService.GetDocumentSort(),
      this.documentService.GetLegalDocuments(settings.informacionLegal.skip, settings.informacionLegal.top),
    ];
    forkJoin(observables).subscribe(([resultadoServicio1, resultadoServicio2, resultadoServicio3]) => {
      if (resultadoServicio1) {
        const documents = resultadoServicio1.typeOfDocuments.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.typeOfDocuments.push({ id: 'all', name: 'Todos' }, ...documents);
      }

      if (resultadoServicio2) {
        this.typeOrderList = resultadoServicio2;
      }

      if (resultadoServicio3) {
        if (settings.informacionLegal.skip === 0) {
          this.listOfData = [];
        }
        resultadoServicio3.legalDocuments.map((mp: any) => this.listOfData.push(mp));

        // Ordeno por Tipo de documento
        if (this.typeOrderList === 2) {
          this.listOfData = this.listOfData.sort((a, b) => {
            const nameA = a.TypeOfDocumentsSort.toString().toUpperCase(); // ignore upper and lowercase
            const nameB = b.TypeOfDocumentsSort.toString().toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        }

        this.totalDocuments = resultadoServicio3.totalDocuments;
        this.showMessage = true;
      }
    });
  }

  onChangeTypeOfDocuments(skip: number = 0) {
    settings.informacionLegal.skip = skip;
    if (this.selectedOption === 'all') {
      this.getAllDocuments();
    } else {
      this.documentService.GetLegalDocumentsByIdType(settings.informacionLegal.skip, settings.informacionLegal.top, this.selectedOption).subscribe(data => {
        if (settings.informacionLegal.skip === 0) {
          this.listOfData = [];
        }
        data.legalDocuments.map(mp => this.listOfData.push(mp));
        this.totalDocuments = data.totalDocuments;
      });
    }
  }

  getAllDocuments() {
    this.documentService.GetLegalDocuments(settings.informacionLegal.skip, settings.informacionLegal.top).subscribe(data => {
      if (settings.informacionLegal.skip === 0) {
        this.listOfData = [];
      }
      data.legalDocuments.map(mp => this.listOfData.push(mp));

      // Ordeno por Tipo de documento
      if (this.typeOrderList === 2) {
        this.listOfData = this.listOfData.sort((a, b) => {
          const nameA = a.TypeOfDocumentsSort.toString().toUpperCase(); // ignore upper and lowercase
          const nameB = b.TypeOfDocumentsSort.toString().toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }

      this.totalDocuments = data.totalDocuments;
      this.showMessage = true;
    })
  }

  getTypeDocuments() {
    this.documentService.GetDocumentsType().subscribe(o => {
      const documents = o.typeOfDocuments.sort((a: any, b: any) => a.name.localeCompare(b.name));
      this.typeOfDocuments.push({ id: 'all', name: 'Todos' }, ...documents);
    });
  }

  loadMore() {
    settings.informacionLegal.skip += settings.informacionLegal.top;
    this.onChangeTypeOfDocuments(settings.informacionLegal.skip);
  }
}
