import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/shared/loading.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.css']
})
export class LoadingBarComponent {

  constructor(public loader: LoadingService){

  }

}
