import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IModal, IModalSeguimientoOrdenes } from 'src/app/models/modal.model';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var window: any;



@Component({
  selector: 'app-modal-seguimiento-ordenes',
  templateUrl: './modal-seguimiento-ordenes.component.html',
  styleUrls: ['./modal-seguimiento-ordenes.component.css']
})
export class ModalSeguimientoOrdenesComponent implements OnChanges {

  @Input() showModal: boolean = false;
  @Input() modalData: IModalSeguimientoOrdenes = {
    ModalName: 'ModalGeneralOrdenes',
    Text:''
  };
  @Input() title: string = '';
  @Input() text: string = '';
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;
  iframeUrl:any;
  constructor(public utilities : UtilitiesService,private sanitizer: DomSanitizer){
  }
  ngOnChanges(changes: SimpleChanges): void {


    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {

      const url = this.modalData.Text;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);



      this.modal.show();
    }
  }


  Accept() {
    this.modal.hide();
    this.showModalChange.emit(true);
  }

  Close() {
    this.modal.hide();
    this.showModalChange.emit(false);
  }



}
