<main class="main pb-0">
  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="color-primary">Información de pago</h1>

    <div class="container my-4">
      <div class="row row-cols-auto stepper">
        <div class="col content-col cursor-pointer">
          <span><i class="bi bi-cart"></i></span>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col cursor-pointer">
          <span (click)="redirectToShipping()"><i class="bi bi-truck"></i></span>
        </div>
        <div class="col line-col px-0">
          <hr>
        </div>
        <div class="col content-col active cursor-pointer">
          <span><i class="bi bi-credit-card"></i></span>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-12 col-lg-8">
        <div class="d-flex justify-content-between align-items-center mb-0">
          <h3 class="mb-0">Mis tarjetas</h3>
          <div class="d-flex align-items-center">
            <i class="bi bi-plus icon-geo"></i>
            <button class="btn btn-text-secondary" type="button" (click)="showModalCard()">Agregar nueva tarjeta</button>
          </div>
        </div>

        <!-- Mostrar cuando no haya datos -->
        <div class="d-flex align-items-center justify-content-center my-5" *ngIf="notCreditCard">
          <div class="text-center">
            <i class="bi bi-credit-card icon-page"></i>
            <h3>Aún no hay datos de facturación</h3>
          </div>
        </div>

        <div class="card mb-2 border-color" *ngFor="let card of creditCard; let i = index">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-start">
                <input class="form-check-input mt-0 me-1" type="radio" name="flexRadioDefault" id="flexRadioDefault{{i}}"
                  [attr.data-bs-toggle]="'collapse'"
                  [attr.aria-expanded]="isSelected(card) ? 'true' : 'false'"
                  [attr.data-bs-target]="'#collapseCard' + i" role="button" aria-expanded="false" [value]="card" aria-controls="collapseCard"
                  [(ngModel)]="selectedCard" [checked]="isSelected(card)">
                <h3 class="me-4 mb-0">Tarjeta {{card.CardNumber.slice(-5)}}</h3>
                <h3 class="mb-0">{{card.Name}}</h3>
              </div>
              <a (click)="deleteCreditCard(card.IDSavedCard)">
                <i class="bi bi bi-trash delete-product"></i>
              </a>
            </div>

            <form [formGroup]="creditForm" autocomplete="off">
              <div class="row collapse mt-2" [ngClass]="{'show': isSelected(card)}" id="collapseCard{{i}}">
                <div class="col-12 col-lg-6 mb-1">
                  <label for="" class="form-label">Código CVV</label>
                  <input type="text" class="form-control login-input" id="ccv" formControlName="ccv">
                  <div *ngIf="ccv!.invalid && this.isSelected(card) && (ccv!.touched || isSubmited)" class="d-block text-error">
                    <span *ngIf="ccv!.errors?.['required']">
                      {{ validationMsg['required'] }}
                    </span>
                  </div>
                </div>

                <div class="col-12 col-lg-6 mb-1">
                  <label for="" class="form-label">Número LifeMiles (opcional)</label>
                  <input type="text" class="form-control login-input" id="lifeMiles" formControlName="lifeMiles">
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>

      <!--orden-->
      <div class="col-12 col-lg-4 order-lg-2">
        <div class="card border-color">
          <div class="order-payment">
            <div class="card-body">

              <div class="text-center pt-1 pb-3">
                <a   class="text-decoration-none icono-con-texto">
                    <i class="bi bi-question-circle"></i>
                    <button (click)="OpenModalCouponMessage($event)" class="btn btn-text-secondary text-cupones mb-1"> Conocé más sobre cupones y beneficios</button>
                </a>
             </div>

              <form [formGroup]="formCupon" (ngSubmit)="addCoupon()">
                <div class="d-flex mb-2">
                  <input type="text" class="form-control login-input border-color" id="cupon" formControlName="cupon"
                    placeholder="Cupón">
                  <button class="btn btn-outline-secondary ms-1" type="submit">Agregar</button>
                </div>
                <div *ngIf="cupon!.invalid && (cupon!.dirty || cupon!.touched || this.isCouponSubmited)" class="text-danger">
                  <span *ngIf="cupon!.errors?.required">{{validationMsg['required']}}</span>
                  <span *ngIf="cupon!.errors?.minlength">{{validationMsg['minlength'].replace("XXX", cupon!.errors!.minlength.requiredLength)}}</span>
                </div>
              </form>

              <div class="mb-2" *ngIf="coupons.length > 0">
                <small class="fw-medium">Cupones aplicados</small>
                <div class="d-flex justify-content-between align-items-center" *ngFor="let coupon of coupons">
                  <div class="d-flex align-items-center">
                    <i class="bi bi-x text-danger pe-2" (click)="deleteCoupon(coupon)"></i>
                    <small>{{coupon.Code}}</small>
                  </div>
                </div>
              </div>
              <hr>

              <div class="d-flex justify-content-between">
                <span class="card-text">Subtotal</span>
                <span>{{subTotalPrice | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">Descuento</span>
                <span>{{discount | mycurrency}}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="card-text">I.V.A</span>
                <span>{{taxes | mycurrency}}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <h3 class="card-text">Total</h3>
                <h3>{{totalPrice | mycurrency}} i.v.a.i</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <a class="d-grid text-decoration-none">
            <button class="btn btn-primary" type="button" (click)="processPayment()">Realizar pago</button>
          </a>

        </div>

      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 col-lg-8">
        <small class="fw-medium ">*Tu factura electrónica será enviada automáticamente al correo electrónico de tu
          cuenta una vez finalizada la compra. Compra definitiva.
        </small>
      </div>

      <div class="col-12 col-lg-4">
      </div>
    </div>
  </div>



  <app-modal-card [showModal]="showCard" [cartId]="cartId" [userId]="userId" [totalPrice]="totalPrice"></app-modal-card>
  <app-modal-general [showModal]="showModalCupon" (showModalChange)="closeModal($event,this.disableParameterCupon)"
    [modalData]="modalCupon"></app-modal-general>
  <app-modal-recommended-product [showModal]="showModalImpulse" (showModalChange)="closeModalImpulse($event)"
    [products]="impulseProduct" [modalData]="modalImpulse"></app-modal-recommended-product>

    <app-modal-general [showModal]="showBeneficitCouponsModal" (showModalChange)="closeModalCoupons($event)"
    [modalData]="BeneficitCouponsModal"></app-modal-general>

    <app-modal-general [showModal]="ValidateShowBeneficitCouponsModal" (showModalChange)="closeValidateModalCoupons($event)"
    [modalData]="ValidateBeneficitCouponsModal"></app-modal-general>

    <app-modal-general [showModal]="showMessageCouponsModal" (showModalChange)="closeValidateModalCoupons($event)"
    [modalData]="MessageCouponsModal"></app-modal-general>

</main>
