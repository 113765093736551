<main class="main mt-3 mt-lg-5" *ngIf="this.blog !== null">

  <!-- Segmento 1 -->
  <div>
    <div class="container mb-4 mb-lg-5">
      <div class="text-center">
        <h1 *ngIf="this.blog.blogDetail.Title.length > 0"
          [ngStyle]="{'color': this.blog.blogDetail.titleColor.length > 0 ? this.blog.blogDetail.titleColor : '#464646' + '!important;' }">
          {{ this.blog.blogDetail.Title }}
        </h1>
        <h2 *ngIf="this.blog.blogDetail.autor.length > 0"
          [ngStyle]="{'color': this.blog.blogDetail.autorColor.length > 0 ? this.blog.blogDetail.autorColor : '#464646' + '!important;' }">
          Por {{ this.blog.blogDetail.autor | ellipsis: 30 }}
        </h2>
        <span>{{ getDatePublish(this.blog.blogDetail.publishDate) }}</span>
      </div>
    </div>
  </div>

  <!-- Segmento 2 -->
  <!-- <div *ngIf="this.blog.blogDetail.sectionTwoDesc.length > 0" class="blog-section-color"
    [ngStyle]="{'background-color': this.blog.blogDetail.sectionTwoBackground }">
    <div class="my-4 my-lg-5 container-fluid">
      <div class="row d-flex align-items-center">

        <div class="col-12 col-lg-6 d-flex justify-content-center p-0">

          this.blog.blogDetail.sectionTwoType === '1' - IMAGEN
          <img loading="lazy"  *ngIf="this.blog.blogDetail.sectionTwoType === '1' && this.blog.DetailImage.length > 0" [src]="urlBase + this.blog.DetailImage[0].Url"
            [alt]="this.blog.DetailImage[0].AlternativeText" class="img-banner-blog">

          this.blog.blogDetail.sectionTwoType === '2' - VIDEO
          <video *ngIf="this.blog.blogDetail.sectionTwoType === '2' && getVideoUrlSectionTwo(this.blog).length > 0"
            id="videolanding" width="100%" controls="controls">
            <source [src]="getVideoUrlSectionTwo(this.blog)" type="video/mp4" />
            Tu explorador no acepta el reproductor de video.
          </video>

        </div>

        <div class="col-12 col-lg-6 p-4">
          <div class="color-blog" *ngIf="this.blog.blogDetail.sectionTwoDesc.length > 0"
            [ngStyle]="{'color': this.blog.blogDetail.sectionTwoTextColor.length > 0 ? this.blog.blogDetail.sectionTwoTextColor : '#464646' + '!important;' }"
            [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.blog.blogDetail.sectionTwoDesc | ellipsis: 2000)">
          </div>
          <a *ngIf="this.blog.blogDetail.sectionTwoBtnOneText && this.blog.blogDetail.sectionTwoBtnOneText.length > 0 && this.blog.blogDetail.sectionTwoBtnOneLink.length > 0"
            [target]="this.blog.blogDetail.sectionTwoBtnOneNewTab ? '_blank' : '_self'"
            [href]="this.blog.blogDetail.sectionTwoBtnOneLink"
            class="btn btn-primary">{{ this.blog.blogDetail.sectionTwoBtnOneText }}</a>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Segmento 3 -->

  <!-- <div *ngIf="this.blog.blogDetail.videoEmbedded.length > 0 && videoEmbedCode">
    <div class="container my-4 my-lg-5 img-banner-blog">
      <div class="d-flex justify-content-center" [innerHTML]="videoEmbedCode">

      </div>
    </div>
  </div> -->


  <!-- Segmento 4 -->
  <div *ngIf="this.blog.blogDetail.detaildescription.length > 0">
    <div class="container my-4 my-lg-5">
      <div [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.blog.blogDetail.detaildescription)"></div>

      <a *ngIf="this.blog.blogDetail.sectionFourBtnOneText && this.blog.blogDetail.sectionFourBtnOneText.length > 0 && this.blog.blogDetail.sectionFourBtnOneLink"
        class="btn btn-primary" [href]="this.blog.blogDetail.sectionFourBtnOneLink"
        [target]="this.blog.blogDetail.sectionFourBtnOneNewTab ? '_blank' : '_self'">{{ this.blog.blogDetail.sectionFourBtnOneText }}</a>
    </div>
  </div>


  <!-- Segmento 5 -->
  <!-- <div
    *ngIf="this.blog.blogDetail.sectionFourDescOne.length > 0 || this.blog.blogDetail.sectionFourDescTwo.length > 0">
    <div class="container my-4 my-lg-5">
      <div class="row">
        <div class="col-12" *ngIf="this.blog.blogDetail.sectionFourTitle.length > 0">
          <h2 class="mb-3"
            [ngStyle]="{'color': this.blog.blogDetail.sectionFourTitleColor.length > 0 ? this.blog.blogDetail.sectionFourTitleColor : '#464646' }">
            {{this.blog.blogDetail.sectionFourTitle}}</h2>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <div class="d-flex justify-content-center mb-3" *ngIf="this.blog.sectionFourImageOne.length > 0">
            <img loading="lazy"   [src]="urlBase + this.blog.sectionFourImageOne[0].Url"
              [alt]="this.blog.sectionFourImageOne[0].AlternativeText" class="img-section-blog">
          </div>

          <div [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.blog.blogDetail.sectionFourDescOne)"></div>

          <a *ngIf="this.blog.blogDetail.sectionFourBtnOneText && this.blog.blogDetail.sectionFourBtnOneText.length > 0 && this.blog.blogDetail.sectionFourBtnOneLink"
            class="btn btn-primary" [href]="this.blog.blogDetail.sectionFourBtnOneLink"
            [target]="this.blog.blogDetail.sectionFiveBtnOneNewTab ? '_blank' : '_self'">{{ this.blog.blogDetail.sectionFourBtnOneText }}</a>
        </div>

        <div class="col-12 col-lg-6 ">
          <div class="d-flex justify-content-center mb-3" *ngIf="this.blog.sectionFourImageTwo.length > 0">
            <img loading="lazy"  [src]="urlBase + this.blog.sectionFourImageTwo[0].Url"
              [alt]="this.blog.sectionFourImageTwo[0].AlternativeText" class="img-section-blog">
          </div>

          <div [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.blog.blogDetail.sectionFourDescTwo)"></div>

          <a *ngIf="this.blog.blogDetail.sectionFourBtnTwoText && this.blog.blogDetail.sectionFourBtnTwoText.length > 0 && this.blog.blogDetail.sectionFourBtnTwoLink.length > 0"
            class="btn btn-primary" [href]="this.blog.blogDetail.sectionFourBtnTwoLink"
            [target]="this.blog.blogDetail.sectionFourBtnTwoNewTab ? '_blank' : '_self'">{{ this.blog.blogDetail.sectionFourBtnTwoText }}</a>
        </div>
      </div>
    </div>
  </div> -->


  <!-- Segmento 6 -->
  <!-- <div *ngIf="this.blog.blogDetail.sectionFiveDesc.length > 0">
    <div class="container my-4 my-lg-5">
      <div [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.blog.blogDetail.sectionFiveDesc)"></div>

      <a *ngIf="this.blog.blogDetail.sectionFiveBtnOneText &&this.blog.blogDetail.sectionFiveBtnOneText.length > 0 && this.blog.blogDetail.sectionFiveBtnOneLink.length > 0"
        class="btn btn-primary" [href]="this.blog.blogDetail.sectionFiveBtnOneLink"
        [target]="this.blog.blogDetail.sectionSixBtnOneNewTab ? '_blank' : '_self'">{{ this.blog.blogDetail.sectionFiveBtnOneText }}</a>
    </div>
  </div> -->


  <!-- Segmento 7 -->
  <!-- <div *ngIf="this.blog.blogDetail.sectionSixDesc.length > 0">
    <div class="container my-4 my-lg-5">
      <div class="d-flex justify-content-center mb-3" *ngIf="this.blog.sectionSixImage.length > 0">
        <img loading="lazy"   [src]="urlBase + this.blog.sectionSixImage[0].Url" [alt]="this.blog.sectionSixImage[0].AlternativeText"
          class="img-section-blog">
      </div>

      <div [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.blog.blogDetail.sectionSixDesc)"></div>

      <a *ngIf="this.blog.blogDetail.sectionSixBtnOneText && this.blog.blogDetail.sectionSixBtnOneText.length > 0 && this.blog.blogDetail.sectionSixBtnOneLink.length > 0"
        class="btn btn-primary" [href]="this.blog.blogDetail.sectionSixBtnOneLink"
        [target]="this.blog.blogDetail.sectionSevenBtnOneNewTab ? '_blank' : '_self'">{{ this.blog.blogDetail.sectionSixBtnOneText }}</a>
    </div>
  </div> -->

  <!-- Segmento 8 -->
  <!-- <div *ngIf="this.blog.blogDetail.sectionSevenDesc.length > 0">
    <div class="container my-4 my-lg-5">
      <div [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.blog.blogDetail.sectionSevenDesc)"></div>

      <a *ngIf="this.blog.blogDetail.sectionSevenBtnOneText && this.blog.blogDetail.sectionSevenBtnOneText.length > 0 && this.blog.blogDetail.sectionSevenBtnOneLink.length > 0"
        class="btn btn-primary" [href]="this.blog.blogDetail.sectionSevenBtnOneLink"
        target="_blank" rel="noopener noreferrer" >{{ this.blog.blogDetail.sectionSevenBtnOneText }}</a>
    </div>
  </div> -->
</main>
