<div class="modal fade" id="modalScrollable" tabindex="-1" aria-labelledby="modalScrollableLabel" aria-hidden="true"
  data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="modalScrollableLabel">{{ this.titulo }}</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body">
        <div [innerHTML]="this.mensaje"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="close()">Regresar al
          formulario</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="accept()">Aceptar</button>
      </div>
    </div>
  </div>
</div>
