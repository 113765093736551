import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { map } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { CoreResponse, Response, ResponseInterface, } from 'src/app/models/responses/response.model';

import { ClientInteractionObject, ResponseGenericObject, InformedConsentFormObject }
  from 'src/app/models/core/contactus.model';
const settings = new SitefinitySettings();

@Injectable({
  providedIn: 'root'
})
export class ContactusService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService) { }

  private SubjectUrl = settings.CoreServicesUrl + 'user.svc/GetAllVisibleClientInteractionSubject';

  private SendMailUrl = settings.SitefinityServicesUrl + 'sendEmailContactUs';

  private InformedConsentUrl = settings.BusDeServiciosUrlDev + 'Lealtad.svc/rest/InsertaDatosConsentimientoInformado';

  public getSubjectServices(): Observable<any> {
    var url = this.SubjectUrl;
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.get(url);
  }

  public getMessages(code: string, category: string): Observable<ResponseInterface> {

    return new Observable<Response>((observer) => {
      var messageCore: string[] = [];
      this.MessagesService_.GetMessagesByKey(code, category).subscribe((data) => {
        var messageResponse = data.value[0];

        if (messageResponse == undefined) {
          messageCore.push(code);
          const responseServices: ResponseInterface = {
            message: 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
            alias: code + '-alias',
            status: true,
            code: code,
            messageCore: messageCore,
            buttonAcceptText: '',
            buttonCancelText: '',
            type: '',
            action: '',
            titleMessage: "falta agregar el código",
            url: '',
            Messages: []
          };
          observer.next(responseServices);
          observer.complete();

        }
        else {
          const responseServices: ResponseInterface = {
            message: messageResponse.message,
            alias: messageResponse.aliascode,
            status: true,
            code: messageResponse.code,
            messageCore: messageCore,
            buttonAcceptText: messageResponse.buttonAcceptText,
            buttonCancelText: messageResponse.buttonCancelText,
            type: messageResponse.type,
            action: messageResponse.action,
            titleMessage: messageResponse.titleMessage,
            url: messageResponse.url,
            Messages: []
          }
          observer.next(responseServices);
          observer.complete();
        }
      }, (error) => {

        const responseServices: ResponseInterface = {
          message: 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
          alias: 'getMessages-error-alias-01',
          status: false,
          code: 'getMessages-error-01',
          messageCore: messageCore,
          buttonAcceptText: '',
          buttonCancelText: '',
          type: '',
          action: '',
          titleMessage: "falta agregar el código",
          url: '',
          Messages: []
        };
        observer.error(responseServices);
        observer.complete();

      });

    });


  }


  public SubjectMethod(): Observable<ResponseGenericObject> {
    return new Observable<ResponseGenericObject>((observer) => {
      this.getSubjectServices().pipe(
        switchMap((responseCore: any) => {
          const coreResponse = Object.assign(new CoreResponse(), responseCore);
          const code = coreResponse.Messages[0];
          if (code === "OK0001") {
            const parametersResponse: string = coreResponse.Parameters as string;
            const Response: any[] = JSON.parse(parametersResponse);




            return this.getMessages(code, '2').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: Response,
                  response: data
                };
                return response;
              })
            );
          } else {
            return this.getMessages(code, '2').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: false,
                  response: data
                };
                return response;
              })
            );
          }
        })
      ).subscribe(
        (response: ResponseGenericObject) => {
          observer.next(response);
          observer.complete();
        },
        (error: any) => {
          observer.error(error);
        }
      );
    });
  }


  public SendEmailContactUsMethod(ClientInteractionObject: ClientInteractionObject, file: File[]): Observable<any> {
    const formData = new FormData();
    file.forEach((file: any, index: number) => {
      formData.append(`adjunto${index}`, file);
    });
    formData.append('jsonData', JSON.stringify(ClientInteractionObject)); // Agrega otros datos
    const headers = new HttpHeaders();
    return this.http.post(this.SendMailUrl, formData, { headers });
  }

  public InformedConsentMethod(InformedConsentFormObject: any): Observable<any> {
    var url = this.InformedConsentUrl;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, InformedConsentFormObject, { headers });
  }
}
