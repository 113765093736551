import { Component,Inject,OnInit  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-metas',
  templateUrl: './metas.component.html',
  styleUrls: ['./metas.component.css']
})
export class MetasComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,private titleService: Title, private metaService: Meta,private router: Router) { }

  ngOnInit() {


    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateCanonicalLink();
    });
   // this.setTitleAndCanonicalLink();
  }

  setTitleAndCanonicalLink() {
    const url: string = window.location.href;
    this.titleService.setTitle(url); // Ejemplo para establecer el título

    // Verificar si ya existe una etiqueta <link rel="canonical">
    let link: HTMLLinkElement = this.metaService.getTag('rel="canonical"') as any;
    if (link) {
      // Si existe, actualizar el atributo href
      this.metaService.updateTag({ rel: 'canonical', href: url });
    } else {
      // Si no existe, añadir la etiqueta al <head>
      this.metaService.addTag({ rel: 'canonical', href: url });
    }
  }

  updateCanonicalLink() {
    const url = this.document.location.href;
    let link: HTMLLinkElement = this.document.querySelector('link[rel="canonical"]') as any;

    if (link) {
      link.href = url;
    } else {
      link = this.document.createElement('link');
      link.rel = 'canonical';
      link.href = url;
      this.document.head.appendChild(link);
    }
  }
}
