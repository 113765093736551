import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model';
import { MessagesService } from './messages.service';
import { UtilitiesServicesService } from '../shared/utilities-services.service';

import { Response } from '../../models/responses/response.model';

import {
  LandingList,
  LandingDetails,
  LandingsListResponse,
  LandingDetailResponse,
  ImageRelated,
  Banner,
  Schedule,
  VideoRelated,
} from '../../models/sitefinity/landing.model';
import { environment } from 'src/environments/environment';

const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root',
})
export class LandingService {
  private GetLandingsUrl = 'api/default/landings';
  private GetBannerLandingsUrl = 'api/default/banner_landings';


  constructor(
    private http: HttpClient,
    private MessagesService_: MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService
  ) {}

  getLandingDetailNew(id: string):Observable<any>{
    var DetailUrl = this.GetLandingsUrl + '(' + id + ')';
    const url = `${environment.SitefinityServicesUrl + DetailUrl}` ;
    return this.http.get<any>(url);
  }

  getLandingsNew():Observable<any>{
    var url = environment.SitefinityServicesUrl + this.GetLandingsUrl;
    return this.http.get<any>(url);
  }


  getLandings(): LandingsListResponse {
    const responseServices: LandingsListResponse = new LandingsListResponse();

    try {
      var url = environment.SitefinityServicesUrl + this.GetLandingsUrl;
      const servicesResponse = this.http.get<any>(url);

      servicesResponse.subscribe((response) => {
        if (response.value == undefined) {
          responseServices.response.code = 'getlanding-fail01';
          this.MessagesService_.GetMessagesByKey('getlanding-fail01','4').subscribe(
            (data) => {
              var messageResponse = data.value[0];

              if (messageResponse == undefined) {
                responseServices.response.message =
                  'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getlanding-fail01';
                responseServices.response.alias = 'getlanding-fail01';
                responseServices.response.status = true;
              } else {
                responseServices.response.message = messageResponse.message;
                responseServices.response.alias = messageResponse.aliascode;
                responseServices.response.status = true;
              }

              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = false;
              return responseServices;
            }
          );
        }

        const landingsData = response.value;
        responseServices.response.code = 'getlanding-success';
        this.MessagesService_.GetMessagesByKey('getlanding-success','4').subscribe(
          (data) => {
            var messageResponse = data.value[0];

            if (messageResponse == undefined) {
              responseServices.response.message =
                'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: getlanding-fail01';
              responseServices.response.alias = 'getlanding-fail01';
              responseServices.response.status = true;
            } else {
              responseServices.response.message = messageResponse.message;
              responseServices.response.alias = messageResponse.aliascode;
              responseServices.response.status = true;
            }
          }
        );

        landingsData.forEach((landingsDataItem: any) => {
          const landing = new LandingList();
          landing.id = landingsDataItem.Id;
          landing.name = landingsDataItem.name;
          landing.title = landingsDataItem.Title;
          landing.urlLandingDetail = landingsDataItem.UrlName;
          var urlImage = url + '(' + landingsDataItem.Id + ')/ImageListBrand';
          const ImageResponse = this.http.get<any>(urlImage);
          ImageResponse.subscribe((imgResponse) => {
            const img = imgResponse.value;
            img.forEach((ImgDataItem: any) => {
              const image = new ImageRelated();
              image.id = ImgDataItem.Id;
              image.title = ImgDataItem.Title;
              image.mediaUrl = ImgDataItem.Url;
              image.alternativeText = ImgDataItem.AlternativeText;
              landing.imageListBrand.push(image);
            });

            responseServices.landings.push(landing);
          });
        });
        return responseServices;
      });

      return responseServices;
    } catch (error) {
      throw new Error('Error en el método GetMessagesByKey');
      return responseServices;
    }
  }
  getLandingsCompleteObs(id: string) {
   this.getLandingDetailObs(id).subscribe((response)=>{

      var DetailUrl = this.GetLandingsUrl + '(' + id + ')';
      var url = environment.SitefinityServicesUrl + DetailUrl;

      if (response == undefined) {
        var result = this.getMessages('getlandingDetail-fail01');
      }

      var landingsDataItem = response.landingDetail;

      //#region properties
      const landing = new LandingDetails();
      landing.id = landingsDataItem.id;
      landing.urlName = landingsDataItem.urlName;
      // landing.name = landingsDataItem.name;
      // landing.type = landingsDataItem.type;
      landing.title = landingsDataItem.title;
      landing.firstDescription = landingsDataItem.firstDescription;
      landing.listSkuFeature = landingsDataItem.listSkuFeature;
      landing.secondDescription = landingsDataItem.secondDescription;
      landing.idCategory = landingsDataItem.idCategory;
      landing.firstTitle = landingsDataItem.firstTitle;
      landing.firstSubTitle = landingsDataItem.firstSubTitle;
      landing.productsTitle = landingsDataItem.productsTitle;
      landing.productsSubTitle = landingsDataItem.productsSubTitle;
      landing.productFeatureTitle = landingsDataItem.productFeatureTitle;
      landing.productFeatureSubTitle =
        landingsDataItem.productFeatureSubTitle;
      landing.secondTitle = landingsDataItem.secondTitle;
      landing.idCssColor = landingsDataItem.idCssColor;
      landing.pageSection1 = landingsDataItem.pageSection1;
      landing.pageSection2 = landingsDataItem.pageSection2;
      landing.pageSection3 = landingsDataItem.pageSection3;
      landing.pageSection4 = landingsDataItem.pageSection4;
      landing.pageSection5 = landingsDataItem.pageSection5;
      landing.colorTitleSubtitle = landingsDataItem.colorTitleSubtitle;
      landing.pageSectionImgTwoColumn =
        landingsDataItem.pageSectionImgTwoColumn;
      landing.subtitleSectionImgTwoColumns =
        landingsDataItem.subtitleSectionImgTwoColumns;
      landing.titleSectionImgTwoColumns =
        landingsDataItem.titleSectionImgTwoColumns;
      landing.showVideo = landingsDataItem.showVideo;
      landing.showImgTwoColumn = landingsDataItem.showImgTwoColumn;
      landing.showBannerHeader = landingsDataItem.showBannerHeader;
      landing.displayFidelityButton = landingsDataItem.displayFidelityButton;
      //SEO
      landing.metaTitle = landingsDataItem.metaTitle;
      landing.metaDescription = landingsDataItem.metaDescription;
      //#endregion
      var urlImage = url + '/ImageListBrand';
      var ImageListBrand = this.getImages(urlImage);
      ImageListBrand.then((o) => (landing.imageListBrand = o));

      var urlListImage = url + '/ListImage';
      var ListImage = this.getImages(urlListImage);
      ListImage.then((o) => (landing.listImage = o));

      var ImageListSectionTwoColumnsUrl = url + '/ImageListSectionTwoColumns';
      var ImageListSectionTwoColumns = this.getImages(
        ImageListSectionTwoColumnsUrl
      );
      ImageListSectionTwoColumns.then(
        (o) => (landing.imageListSectionTwoColumns = o)
      );

      var urlImageHeader = url + '/ImageHeader';
      var ImageHeader = this.getImages(urlImageHeader);
      ImageHeader.then((o) => (landing.imageHeader = o));

      var urlVideo = url + '/VideoLanding';
      var videos = this.getVideos(urlVideo);
      videos.then((o) => (landing.videoLanding = o));

      var urlHeaderBanner = url + '/HeaderBanner';
      var banner = this.getBanner(urlHeaderBanner);
      banner.then((o) => (landing.headerBanner = o));

      var urlHeaderBannerfirstListBanner = url + '/FirstListBanner';
      var HeaderBannerfirstListBanner = this.getBanner(
        urlHeaderBannerfirstListBanner
      );
      HeaderBannerfirstListBanner.then((o) => (landing.firstListBanner = o));

      var urlHeaderBannerSecondListBanner = url + '/SecondListBanner';
      var HeaderBannerSecondListBanner = this.getBanner(
        urlHeaderBannerSecondListBanner
      );
      HeaderBannerSecondListBanner.then(
        (o) => (landing.secondListBanner = o)
      );

      var urlHeaderBannerThirdListBanner = url + '/ThirdListBanner';
      var HeaderBannerThirdListBanner = this.getBanner(
        urlHeaderBannerThirdListBanner
      );
      HeaderBannerThirdListBanner.then((o) => (landing.thirdListBanner = o));


      return landing;



    })
  }

  getLandingDetailObs(id: string): Observable<LandingDetailResponse> {
    const responseServices: LandingDetailResponse = new LandingDetailResponse();

    var DetailUrl = this.GetLandingsUrl + '(' + id + ')';
    var url = environment.SitefinityServicesUrl + DetailUrl;
    return this.http.get<any>(url);

  }

  getImagesNew(id:string,urlImage: string) {
    var DetailUrl = this.GetLandingsUrl + '(' + id + ')'+ urlImage;
    var url = environment.SitefinityServicesUrl + DetailUrl;
    return this.http.get<any>(url);
  }

  getBannerImagesNew(id:string,urlImage: string) {
    var DetailUrl = this.GetBannerLandingsUrl + '(' + id + ')'+ urlImage;
    var url = environment.SitefinityServicesUrl + DetailUrl;
    return this.http.get<any>(url);
  }


  getBannerNew(id:string,urlBanner: string) {
    var DetailUrl = this.GetLandingsUrl + '(' + id + ')'+ urlBanner;
    var url = environment.SitefinityServicesUrl + DetailUrl;
    return this.http.get<any>(url);
  }

  getVideosNew(id:string,urlImage: string) {
    var DetailUrl = this.GetLandingsUrl + '(' + id + ')'+ urlImage;
    var url = environment.SitefinityServicesUrl + DetailUrl;
    return this.http.get<any>(url);
  }

  async getLandingDetail(id: string): Promise<LandingDetailResponse> {
    var responseServices: LandingDetailResponse = new LandingDetailResponse();

    try {
      var DetailUrl = this.GetLandingsUrl + '(' + id + ')';
      var url = environment.SitefinityServicesUrl + DetailUrl;
      var servicesResponse = await this.http.get<any>(url);
      servicesResponse.subscribe((response) => {
        if (response == undefined) {
          var result = this.getMessages('getlandingDetail-fail01');
          result.then((o) => (responseServices.Response = o));
          return responseServices;
        }

        const landingsDataItem = response;

        //#region properties
        const landing = new LandingDetails();
        landing.id = landingsDataItem.Id;
        landing.urlName = landingsDataItem.urlName;
        // landing.name = landingsDataItem.name;
        // landing.type = landingsDataItem.type;
        landing.title = landingsDataItem.Title;
        landing.firstDescription = landingsDataItem.FirstDescription;
        landing.listSkuFeature = landingsDataItem.ListSkuFeature;
        landing.secondDescription = landingsDataItem.SecondDescription;
        landing.idCategory = landingsDataItem.IDCategory;
        landing.firstTitle = landingsDataItem.FirstTitle;
        landing.firstSubTitle = landingsDataItem.FirstSubTitle;
        landing.productsTitle = landingsDataItem.ProductsTitle;
        landing.productsSubTitle = landingsDataItem.ProductsSubTitle;
        landing.productFeatureTitle = landingsDataItem.ProductFeatureTitle;
        landing.productFeatureSubTitle =
          landingsDataItem.ProductFeatureSubTitle;
        landing.secondTitle = landingsDataItem.SecondTitle;
        landing.idCssColor = landingsDataItem.IdCssColor;
        landing.pageSection1 = landingsDataItem.PageSection1;
        landing.pageSection2 = landingsDataItem.PageSection2;
        landing.pageSection3 = landingsDataItem.PageSection3;
        landing.pageSection4 = landingsDataItem.PageSection4;
        landing.pageSection5 = landingsDataItem.PageSection5;
        landing.colorTitleSubtitle = landingsDataItem.ColorTitleSubtitle;
        landing.pageSectionImgTwoColumn =
          landingsDataItem.PageSectionImgTwoColum;
        landing.subtitleSectionImgTwoColumns =
          landingsDataItem.SubtitleSectionImgTwoColumns;
        landing.titleSectionImgTwoColumns =
          landingsDataItem.TitleSectionImgTwoColumns;
        landing.showVideo = landingsDataItem.IsVideo;
        landing.showImgTwoColumn = landingsDataItem.ShowImgTwoColumn;
        landing.showBannerHeader = landingsDataItem.IsBannerHeader;
        landing.displayFidelityButton = landingsDataItem.displayFidelityButton;
        //#endregion

        var urlImage = url + '/ImageListBrand';
        var ImageListBrand = this.getImages(urlImage);
        ImageListBrand.then((o) => (landing.imageListBrand = o));

        var urlListImage = url + '/ListImage';
        var ListImage = this.getImages(urlListImage);
        ListImage.then((o) => (landing.listImage = o));

        var ImageListSectionTwoColumnsUrl = url + '/ImageListSectionTwoColumns';
        var ImageListSectionTwoColumns = this.getImages(
          ImageListSectionTwoColumnsUrl
        );
        ImageListSectionTwoColumns.then(
          (o) => (landing.imageListSectionTwoColumns = o)
        );

        var urlImageHeader = url + '/ImageHeader';
        var ImageHeader = this.getImages(urlImageHeader);
        ImageHeader.then((o) => (landing.imageHeader = o));

        var urlVideo = url + '/VideoLanding';
        var videos = this.getVideos(urlVideo);
        videos.then((o) => (landing.videoLanding = o));

        var urlHeaderBanner = url + '/HeaderBanner';
        var banner = this.getBanner(urlHeaderBanner);
        banner.then((o) => (landing.headerBanner = o));

        var urlHeaderBannerfirstListBanner = url + '/FirstListBanner';
        var HeaderBannerfirstListBanner = this.getBanner(
          urlHeaderBannerfirstListBanner
        );
        HeaderBannerfirstListBanner.then((o) => (landing.firstListBanner = o));

        var urlHeaderBannerSecondListBanner = url + '/SecondListBanner';
        var HeaderBannerSecondListBanner = this.getBanner(
          urlHeaderBannerSecondListBanner
        );
        HeaderBannerSecondListBanner.then(
          (o) => (landing.secondListBanner = o)
        );

        var urlHeaderBannerThirdListBanner = url + '/ThirdListBanner';
        var HeaderBannerThirdListBanner = this.getBanner(
          urlHeaderBannerThirdListBanner
        );
        HeaderBannerThirdListBanner.then((o) => (landing.thirdListBanner = o));

        var result = this.getMessages('getlandingDetail-success');
        result.then((o) => (responseServices.Response = o));
        responseServices.landingDetail = landing;


        return responseServices;
      });

      return responseServices;
    } catch (error) {
      var result = this.getMessages('getlandingDetail-error01');
      result.then((o) => (responseServices.Response = o));
      return responseServices;
    }

  }

  async getImages(urlImage: string): Promise<ImageRelated[]> {
    const ImageRelatedResult: ImageRelated[] = [];

    const imageResponse = await this.http.get<any>(urlImage);
    imageResponse.subscribe((imgResponse) => {
      const img = imgResponse.value;
      img.forEach((ImgDataItem: any) => {
        const image = new ImageRelated();
        image.id = ImgDataItem.Id;
        image.title = ImgDataItem.Title;
        image.mediaUrl = ImgDataItem.Url;
        image.alternativeText = ImgDataItem.AlternativeText;
        ImageRelatedResult.push(image);
      });
    });

    return ImageRelatedResult;
  }

  async getVideos(urlVideo: string): Promise<VideoRelated[]> {
    const VideoRelatedResult: VideoRelated[] = [];

    const videoResponse = await this.http.get<any>(urlVideo);
    videoResponse.subscribe((videoResponse) => {
      const video = videoResponse.value;
      video.forEach((videoDataItem: any) => {
        const video = new VideoRelated();
        video.id = videoDataItem.Id;
        video.title = videoDataItem.Title;
        video.mediaUrl = videoDataItem.Url;
        video.alternativeText = videoDataItem.AlternativeText;
        VideoRelatedResult.push(video);
      });
    });

    return VideoRelatedResult;
  }

  async getBanner(urlBanner: string): Promise<Banner[]> {
    const BannerResult: Banner[] = [];

    const headerBannerResponse = await this.http.get<any>(urlBanner);
    headerBannerResponse.subscribe((responseItemHeader) => {
      const response = responseItemHeader.value;
      response.forEach((bannerresponse: any) => {
        const banner = new Banner();

        banner.id = bannerresponse.Id || '';
        banner.title = bannerresponse.Title || '';
        banner.bannerTitle = bannerresponse.BannerTitle || '';
        banner.subTitleBanner = bannerresponse.SubTitleBanner || '';
        banner.url = bannerresponse.url || '';
        banner.label = bannerresponse.label || '';
        banner.showSubTitle = bannerresponse.ShowSubTitle || '';
        banner.showTitle = bannerresponse.ShowTitle || '';
        banner.priority = bannerresponse.Priority || 0;
        var UrlImageBannerById =
          environment.SitefinityServicesUrl + '/api/default/banner_landings';
        var urlImage = UrlImageBannerById + '(' + banner.id + ')/ImageBanner';

        var ImageListBrand = this.getImages(urlImage);
        ImageListBrand.then((o) => (banner.imageBanner = o));

        var UrlScheduleBanner =
          environment.SitefinityServicesUrl + '/api/default/banner_landings';
        var urlSchedule =
          UrlScheduleBanner + '(' + banner.id + ')/ScheduleBanner';

        var Schedule = this.getScheduleBanner(urlSchedule);
        Schedule.then((o) => (banner.scheduleBanner = o));

        BannerResult.push(banner);
      });
    });

    return BannerResult;
  }

  async getScheduleBanner(urlSchedule: string): Promise<Schedule[]> {
    const ScheduleRelatedResult: Schedule[] = [];

    const ScheduleResponse = await this.http.get<any>(urlSchedule);

    ScheduleResponse.subscribe((scheduleResponseSubscribe) => {
      const schedule = scheduleResponseSubscribe.value;
      schedule.forEach((scheduleDataItem: any) => {
        const scheduleResponse = new Schedule();
        scheduleResponse.id = scheduleDataItem.Id;
        scheduleResponse.title = scheduleDataItem.Title;
        scheduleResponse.scheduleInit = scheduleDataItem.ScheduleInit;
        scheduleResponse.scheduleFinal = scheduleDataItem.ScheduleFinal;
        ScheduleRelatedResult.push(scheduleResponse);
      });
    });

    return ScheduleRelatedResult;
  }

  async getMessages(code: string): Promise<Response> {
    var responseServices: Response = new Response();
    await this.MessagesService_.GetMessagesByKey(code,'4').subscribe((data) => {
      var messageResponse = data.value[0];

      if (messageResponse == undefined) {
        responseServices.message =
          'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code;
          responseServices.alias = code + '-alias';
          responseServices.code = code ;
          responseServices.status = true;
      } else {
        responseServices.message = messageResponse.message;
        responseServices.alias = messageResponse.aliascode;
        responseServices.code = code ;
        responseServices.status = true;
      }
    });

    return responseServices;
  }
}
