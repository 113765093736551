import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { CheckoutService } from 'src/app/services/core/checkout.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { ModalRememberComponent } from '../modals/modal-remenber/modal-remember.component';
import { ReminderService } from 'src/app/services/core/reminder.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { IModal } from 'src/app/models/modal.model';
import { ClientsService } from 'src/app/services/core/clients.service';
import { Subscription } from 'rxjs';
import { HelperCheckoutService } from 'src/app/services/core/helper-checkout.service';
import { MessagesService } from 'src/app/services/sitefinity/messages.service';
import { MergeNService } from 'src/app/services/core/merge-n.service';

declare let window: any;
@Component({
  selector: 'app-checkout-cart',
  templateUrl: './checkout-cart.component.html',
  styleUrls: ['./checkout-cart.component.css']
})
export class CheckoutCartComponent implements OnInit {

  userId: any;
  cartId: any;
  productsCart: any = [];
  totalPriceCart: number = 0;
  subTotalPriceCart: number = 0;
  taxes: number = 0;
  discount: number = 0;
  settings: SitefinitySettings = new SitefinitySettings;
  selectedUnits: { [key: number]: string } = {};
  selectedQuantity: { [key: number]: number } = {};
  selectedUnitPrice: number[] = [];
  form!: FormGroup;
  validationMsg = this.settings.validationMsgTypeMap;
  isSubmited: boolean = false;
  modalRemember: any;
  showRemember: boolean = false;
  selectedSku: string = '0';
  allReminders: any[] = [];
  reminder: { [key: number]: string } = {};;
  selectedReminder: any;
  modalCupon: IModal = {
    ModalName: 'modalCupon',
    ShowCancelBtn: false
  };
  coupons: {
    Code: string,
    DisplayDiscount: boolean
    LocalCouponId: number
  }[] =  [];
  showModalCupon: boolean = false;
  showDelete: boolean = false;
  showProductNotAvailable: boolean = false;
  showModalMissingProduct: boolean = false;
  showProductOutOfTime: boolean = false;
  skus: any = [];
  quantities: any = [];
  showMaxPurchase: boolean = false;
  showModalMaxPurchase: boolean = false;
  modalProductNotAvailable: IModal = {
    ModalName: 'modalProductNotAvailable',
    ShowCancelBtn: false
  };
  modalMax: IModal = {
    ModalName: 'modalMax',
    ShowCancelBtn: false
  };
  state = {
    show: false,
    showMaxPurchase: false,
    showProductOutOfTime: false
  };
  impulseProduct: any = [];
  productAvailable: boolean = false;
  showModalProductoFaltante: boolean = false;
  selectedProduct: any;
  idAddress!: number;
  idShop!: number;
  idDeliveryMethod!: number;
  productInventory: any;
  identificationUser!: string;
  productIsNotInventory: boolean = false;
  productInInventory: any
  private cartUpdateSubscription!: Subscription;
  displayQuantityComponent: boolean = false;
  quantityByComponent:any = 10;
  hasSearched: boolean = false;
  productEmpty:string = 'E'; //empty



  showModalRemember: boolean = false;
  modalData: IModal = {
    ModalName: 'modalRememberCheckout',
  };
  modalDeleteCartCheckout: any = {
    ModalName: 'deleteCartCheckout',
  };
  user: any;

  @ViewChild('modalRemember') modalRememberComp!: ModalRememberComponent; // Obtén una referencia al componente hijo
  ValidationsMessage: any = [];
  messageDisplayedIndex: any;

  constructor(
    private utilitiesService: UtilitiesServicesService,
    private checkoutService: CheckoutService,
    private notificationService: NotificationService,
    private sitefinityPagesService : SitefinitypagesService,
    private loader: LoadingService,
    private cartService: CartService,
    private reminderService: ReminderService,
    private sharedService: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private clientService: ClientsService,
    private HelperCheckoutService: HelperCheckoutService,
    private messageService: MessagesService,
    private mergenService: MergeNService
  ){
    this.form = this.fb.group({
      cupon: ['', [Validators.required,Validators.minLength(3)]]
    });
  }

  get cupon() { return this.form.get('cupon')}

  async ngOnInit(): Promise<void> {
    if(!this.utilitiesService.isUserLoggedIn()) {
      this.router.navigate(['/iniciosesion']);
    }
    else {
      this.user = this.utilitiesService.getValueStorage('sessionStorage');
      if (this.user) {
        this.cartId = await this.utilitiesService.decryptData(this.user.c);
        this.userId = await this.utilitiesService.decryptData(this.user.i);
        this.getIdentificationUser(this.userId);
      }

      this.cartUpdateSubscription = this.sharedService.cartUpdated$.subscribe(() => {
        this.getCartSummary();
      });

      this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'proceso-compra')!.ID);
      this.state = window.history.state;
      this.showProductNotAvailable = this.state.show ? this.state.show : false;
      this.showMaxPurchase = this.state.showMaxPurchase ? this.state.showMaxPurchase : false;
      this.showProductOutOfTime = this.state.showProductOutOfTime ? this.state.showProductOutOfTime : false;

      this.updateCartSummary();
    }
  }

  ngOnDestroy() {
    if(this.cartUpdateSubscription)
      this.cartUpdateSubscription.unsubscribe(); // Asegúrate de desuscribirte en onDestroy
  }

  closeModalProductNotAvailable(event: any) {
    if (event) {
      this.showModalMissingProduct = false;
      if(this.modalProductNotAvailable.UrlBtnConfirm && this.modalProductNotAvailable.UrlBtnConfirm.trim() != ''){
        window.open(this.modalProductNotAvailable.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalMissingProduct = false;
    }
  }

  closeModalMaxPurchase(event: any) {
    if (event) {
      this.showModalMaxPurchase = false;
      if (this.modalMax.UrlBtnConfirm && this.modalMax.UrlBtnConfirm.trim() != '') {
        window.open(this.modalMax.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalMaxPurchase = false;
    }
  }

  closeModal(event: any) {
    if (event) {
      this.showModalCupon = false;
      this.isSubmited = false;
      this.form = this.fb.group({
        cupon: ['', [Validators.required]]
      });
      this.form.updateValueAndValidity();

      if(this.modalCupon.UrlBtnConfirm && this.modalCupon.UrlBtnConfirm.trim() != ''){
        window.open(this.modalCupon.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalCupon = false;
    }
  }

  updateCartSummary(): void {
    this.loader.showSpinner();
    this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
        this.subTotalPriceCart = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
        this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
        this.discount = res.responseObject.PurchaseInfo.Discount;
        this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;
        this.getCartSummary();
      }
      else if (res.response.code === 'emptyCart-lightSummary') {
        this.cartService.GetLightCartSummaryMethod(this.cartId).subscribe(res => {
          if (res.response.code === "OK0001") {
            this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
            this.subTotalPriceCart = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
            this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
            this.discount = res.responseObject.PurchaseInfo.Discount;
            this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;
            this.getCartSummary();
          }
          else {
            // let r = JSON.stringify(res);
            // this.utilitiesService.insertErrorObservable(this.cartId, 'CARTSUMMARY', r, 'CARTSUMARRY').subscribe(t => {
            // });
            this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
            this.loader.hideSpinner();
          }
        });
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        this.loader.hideSpinner();
      }
      //this.loader.hideSpinner();
    });
  }

  getCartSummary(): void {
    this.cartService.UpdateCartInfoLightMethod(this.cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.updateCartInfo(res);
        this.updateProduct(res);
        this.skus = this.skus.join(',');
        this.quantities = this.quantities.join(',');
        this.getReminders();

        if (this.showProductOutOfTime) {
          this.validateCart();
        }
        else if(this.showProductNotAvailable) {
          if (this.showMaxPurchase) {
            this.validateCart();
          }
          else {
            const request = {
              IDAddress: res.responseObject.PurchaseInfo.Address.IdClientAddress,
              IDAddressSpecified: true,
              IDCart: res.responseObject.IdCart,
              IDDeliveryMethod: res.responseObject.PurchaseInfo.Address.IdDeliveryMethod,
              IDDeliveryMethodSpecified: true,
              IDShop: res.responseObject.PurchaseInfo.Address.IdShop,
              IDShopSpecified: true,
              Quantities: this.quantities,
              Skus: this.skus
            }
            this.getInventory(request);
          }
        }
      }
      else {
        this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      }
      this.loader.hideSpinner();
    });
  }

  updateCartInfo(res: any) {
    this.totalPriceCart = res.responseObject.PurchaseInfo.TotalWithDiscount;
    this.subTotalPriceCart = res.responseObject.PurchaseInfo.SubTotalWithoutTaxes;
    this.taxes = res.responseObject.PurchaseInfo.TaxesAmount;
    this.discount = res.responseObject.PurchaseInfo.Discount;
    this.coupons = res.responseObject.PurchaseInfo.GiftCertificateCouponList;

    this.productsCart = res.responseObject.ProductQueue.length > 0 ? res.responseObject.ProductQueue : [];
    if(this.productsCart.length == 0 )
    {
      this.productEmpty = 'NP' //no Products.
    }
    else
    {

      this.productEmpty = 'F' // fulled
    }

  }

  updateProduct(res: any) {
    this.skus = [];
    this.quantities = [];
    this.hasSearched = true;
    this.productsCart.forEach((p: any, index: number) => {
      if (p.CurrentPrice > 0 && p.CurrentTotalPrice > 0) {
        p.displayQuantityComponent = false;
        p.quantityByComponent = 10;
        p.numbers = [];

        if(p.MaxToPurchase > 10) {
          for (let i = p.MinToPurchase; i <= 9; i++) {
            p.numbers.push(i);
          }
        }
        else {
          for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
            p.numbers.push(i);
          }
        }

        let unit = p.SelectedAttributes.length > 0 ? p.SelectedAttributes[0]?.Name : 'Unidad';

        if (unit === 'Unidad')
          this.selectedUnits[index] = p.Quantity > 1 ? 'Unidades' : unit;
        else
          this.selectedUnits[index] = p.Quantity > 1 ? 'Cajas' : unit;

        this.selectedQuantity[index] = p.Quantity;
        this.selectedUnitPrice[index] = p.PriceWithDiscount > 0 ? p.PriceWithDiscount : p.CurrentTotalPrice;
        let sku = p.SelectedAttributes.length > 0 ? p.SelectedAttributes[0].Sku3 : p.Sku;
        this.skus.push(sku);
        this.quantities.push(p.Quantity.toString());
        this.idAddress = res.responseObject.PurchaseInfo.Address.IdClientAddress;
        this.idShop = res.responseObject.PurchaseInfo.Address.IdShop;
        this.idDeliveryMethod = res.responseObject.PurchaseInfo.Address.IdDeliveryMethod;
      }
      this.hasSearched = false;
    });
        // let r = JSON.stringify(res);
        // this.utilitiesService.insertErrorObservable(this.cartId, 'CARTSUMMARY', r, 'CARTSUMARRY').subscribe(t => {
        // });
  }

  removeProductFromCart(product: any, index: number) {
    this.loader.showSpinner();
    const object: any = {
      Address: null,
      FACRedirect3DSecureResponse: null,
      FavoriteOrderName: null,
      GenerateElectronicInvoice: null,
      IDSavedCard: null,
      IdCart: this.cartId,
      IdClient: this.userId,
      IdFavoriteOrder: 0,
      IdFavoriteProduct: 0,
      IsAMEX: null,
      IsCardToSave: null,
      PaymentExtraInfo: null,
      PaymentMethod: null,
      Product: product,
      PurchaseOnBehalf: null,
      Quantity: null,
      UseFAC: null,
      UseSavedCard: null
    };

    let sku = product.SelectedAttributes.length > 0 ? product.SelectedAttributes[0].Sku3 : product.Sku;
    let validateProduct = this.productInInventory?.find((p: { Sku: any; }) => p.Sku === sku);
    if (this.productIsNotInventory && (validateProduct != undefined && !validateProduct.ThereIsInventory)) {
      this.selectedProduct = product;
      this.productInventory = {
        IDAddress: this.idAddress,
        IDCart: this.cartId,
        IDDeliveryMethod: this.idDeliveryMethod,
        IDShop: this.idShop,
        Quantities: this.quantities,
        Skus:this.skus
      };
      this.showMissingProduct();
      this.loader.hideSpinner();
    }
    else {
      // let t = this.productsCart;
      // this.productsCart = [];
      // let x = t.filter((p: any, i: number) => p != product);
      // this.productsCart = x;
      this.checkoutService.RemoveProductFromCartMethod(object).subscribe(res => {
        if (res.response.code === 'DeleteProduct-Success') {
          this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
          this.getOrCreateCartDelete();
          setTimeout(() => {
            this.sharedService.updateCart();
            if(this.productsCart.length <= 1){
              this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, "1", "3", "0").subscribe(res => {
                if (res.response.code !== "CSDEL") {
                  this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
                }
              });
            }

            if(this.productsCart.length == 0 )
            {
              this.productEmpty = 'NP' //no Products.
            }
            else
            {

              this.productEmpty = 'F' // fulled
            }



          }, 600);
        }
        else {
          this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        }
      });
      setTimeout(() => {
        this.loader.hideSpinner();
      }, 3000);
    }
  }

  selectUnit(index: number, unit: string): void {
    this.selectedUnits[index] = unit;
    // Llama a la función para recalcular el precio cuando se cambia la unidad
    this.calculateTotalPrice(index);
  }

  selectQuantity(index: number, quantity: number): void {
    this.selectedQuantity[index] = quantity;
    // Calcula el precio total aquí (dependiendo de la cantidad y la unidad seleccionada)
    this.calculateTotalPrice(index);
  }

  calculateTotalPrice(index: number): void {
    this.loader.showSpinner();
    const product = this.productsCart[index];
    const quantity = this.selectedQuantity[index];
    let price: number = 0;
    if (product.PriceWithDiscount > 0 && product.Quantity > 1) {
      product.PriceWithDiscount = product.PriceWithDiscount / product.Quantity;
    }
    this.selectedUnitPrice[index] = product.PriceWithDiscount ? product.PriceWithDiscount * quantity : product.CurrentPrice * quantity;
    price = this.selectedUnitPrice[index];
    product.InEdition = true;
    this.sharedService.addProductsToCartV2(product, product.SelectedAttributes[0]?.Name, quantity, price);
  }

  selectType(index:any,itemName: string ): string {
    if (itemName.toLowerCase() === 'unidad' ||  itemName.toLowerCase() === 'unidades' ) {
      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Unidades' : 'Unidad';
      return  this.selectedUnits[index]
    }
    else if (itemName.toLowerCase() === 'caja' ||  itemName.toLowerCase() === 'cajas') {
      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Cajas' : 'Caja';
      return  this.selectedUnits[index];
    }
    else {
      this.selectedUnits = itemName;
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return  this.selectedUnits[index]
    }
  }

  openQuantityComponent(index: number, value: boolean)  {
    this.productsCart[index].quantityByComponent =  this.validateQuantityZero(this.productsCart[index].quantityByComponent, index)

    if(!value) {
      this.selectedQuantity[index] = this.productsCart[index].quantityByComponent;
      this.selectType(index,this.selectedUnits[index]);
      this.calculateTotalPrice(index);
    }

    this.productsCart[index].displayQuantityComponent = value;
  }

  validateQuantityZero(quantity: any, index: number) {
    if(quantity <= 0 ) {
      return this.productsCart[index].MinToPurchase;
    }
    if (quantity < this.productsCart[index].MinToPurchase) {
      return this.productsCart[index].MinToPurchase;
    }
    if (quantity > this.productsCart[index].MaxToPurchase) {
      return this.productsCart[index].MaxToPurchase;
    }
    return quantity;
  }

  addCoupon(): void {
    if (this.form.invalid)
      this.isSubmited = true;
    else {
      this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, this.form.value.cupon, "1", "0").subscribe(res => {
        if (res.response.code === "CAPP") {
          this.messageService.getMessagesMethod('CAPP-1', '1').subscribe(m => {
            this.modalCupon.Title = m.titleMessage;
            this.modalCupon.Text = m.message;
            this.modalCupon.BtnConfirmText = m.buttonAcceptText;
            this.showModalCupon = true;
            this.updateCartSummary();
            this.sharedService.updateCart();
          });
        }
        else {
          this.modalCupon.Title = res.response.titleMessage;
          this.modalCupon.Text = res.response.message;
          this.showModalCupon = true;
        }
      });
    }
  }

  deleteCoupon(coupon: any): void {
    this.cartService.AddOrDeleteCuponToCartWithCertificateMethod(this.cartId, coupon.Code, "2", "0").subscribe(res => {
      if (res.response.code === "CDEL") {
        this.modalCupon.Title = res.response.titleMessage;
        this.modalCupon.Text = res.response.message;
        this.showModalCupon = true;
        this.updateCartSummary();
        this.sharedService.updateCart();
      }
      else {
        this.modalCupon.Title = res.response.titleMessage;
        this.modalCupon.Text = res.response.message;
        this.showModalCupon = true;
      }
    });
  }

  goToDetailProduct(url: string): void {
    if( url != null) {
      this.router.navigate(['/detalle-producto', url]);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  ShowModalRemember(product: any) {
    this.selectedProduct = product;
    this.selectedSku = product.Sku;
    let filtered = this.allReminders.filter(
      (x) => x.Articulo == product.Sku
    )[0];
    this.selectedReminder = filtered;
    this.showModalRemember = true;
  }

  showModalDelete(): void {
    this.showDelete = true;
  }

  refreshReminders(){
    this.getReminders();
  }

  hideModalRemember(): void {
    this.refreshReminders()
  }

  closeModalDelete(event: boolean) {
    this.showDelete = false;
    this.hasSearched = true;
    if (event)
      this.productsCart = [];
    this.getOrCreateCart();
    setTimeout(() => {
      this.sharedService.updateCart();
    }, 600);
  }

  getOrCreateCart() {
    this.mergenService.GetOrCreateCartMethod(this.cartId, parseInt(this.userId)).subscribe(async o => {
      if (o.response.code === "OK0001") {
        this.cartId = o.responseObject.IdCart;

        const IDClient = this.utilitiesService.encryptData(o.responseObject.IdCart.toString());
        this.user.c = IDClient;
        this.utilitiesService.updateValueStorage(this.user, 'sessionStorage');
      }
      else {
        this.notificationService.SendNotification(o.response.type, o.response.titleMessage, o.response.message);
      }
    });
  }

  getOrCreateCartDelete() {
    this.mergenService.GetOrCreateCartMethod(this.cartId, parseInt(this.userId)).subscribe(async o => {
      if (o.response.code === "OK0001") {
        this.cartId = o.responseObject.IdCart;

        const IDClient = this.utilitiesService.encryptData(o.responseObject.IdCart.toString());
        this.user.c = IDClient;
        this.utilitiesService.updateValueStorage(this.user, 'sessionStorage');
      }
      else {
        this.notificationService.SendNotification(o.response.type, o.response.titleMessage, o.response.message);
      }
    });
  }

  getReminders() {
    if (this.identificationUser.trim() != '' && this.productsCart.length > 0) {
      this.reminderService.GetProductRemindersByUserMethod(this.identificationUser.trim() + '', '1').subscribe((res) => {
        if (res.Listado && res.Listado.Recordatorios) {
          this.allReminders = res.Listado.Recordatorios;
          this.productsCart.forEach((p: any, index: number) => {
            const reminderMatch = this.allReminders.find((x) => x.Articulo === p.Sku);
            this.reminder[index] = reminderMatch || null;
          });
        }
          this.loader.hideSpinner();
        },
        (err) => {
          this.loader.hideSpinner();
          this.notificationService.SendNotificationByCode('getRemindersFail-01','1');
        });
    } else {
      this.loader.hideSpinner();
    }
  }

  redirectToOrden(): void {
    this.router.navigate(['/orden'], { skipLocationChange: true });
  }

  getInventory(request: any) {
    this.checkoutService.GetProductsInventoryMethod(request).subscribe(pi => {
      if (pi.response.code != "ERR0001") {
        const productValid = pi.object.some((p: any) => !p.ThereIsInventory);
        if (productValid) {
          this.validateInventory(pi.object);
        }
        else {
          this.showProductNotAvailable = false;
        }
      }
      else {
        this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
          this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
          this.loader.hideSpinner();
        });
      }
    });
  }

  getValidateShop(request: any) {
    this.HelperCheckoutService.ValidateShopAvailabilityByBlockMethod(request.IDShop).subscribe(block => {
      if (block.response.code === "OK0001") {
        switch (block.object) {
          case 1:
            this.checkoutService.GetProductsInventoryMethod(request).subscribe(pi => {
              if (pi.response.code != "ERR0001") {
                const productValid = pi.object.some((p: any) => !p.ThereIsInventory);
                if (productValid) {
                  this.validateInventory(pi.object);
                }
                else {
                  this.showProductNotAvailable = false;
                }
              }
              else {
                this.messageService.getMessagesMethod('ERR0001', '1').subscribe(m => {
                  this.notificationService.SendNotification(m.type, m.titleMessage, m.message);
                  this.loader.hideSpinner();
                });
              }
            });
            break;

          case 2:
            this.checkoutService.GetProductsInventoryMethod(request).subscribe(pi => {
              const productValid = pi.object.some((p: any) => !p.ThereIsInventory);
              if (productValid) {
                this.validateInventory(pi.object);
              }
              else {
                this.showProductNotAvailable = false;
              }
            });
            break;

            case 3:
              this.checkoutService.GetProductsInventoryWithoutInventoryMethod(request).subscribe(pi => {
                const productValid = pi.object.some((p: any) => !p.ThereIsInventory);
                if (productValid) {
                  this.validateInventory(pi.object);
                }
                else {
                  this.showProductNotAvailable = false;
                }
              });
              break;
        }
        this.loader.hideSpinner();
      }
      else {
        this.notificationService.SendNotification(block.response.type, block.response.titleMessage, block.response.message);
        this.loader.hideSpinner();
      }
    });
  }

  validateInventory(object: any) {
    this.messageService.getMessagesMethod('CARTVAL0058', '1').subscribe(m => {
      this.modalProductNotAvailable.Title = m.titleMessage;
      this.modalProductNotAvailable.Text = m.message;
      this.modalProductNotAvailable.BtnConfirmText = m.buttonAcceptText;
      this.productInInventory = object;
      this.productIsNotInventory = true;
      this.showProductNotAvailable = false;
      this.showModalMissingProduct = true;
    });
  }

  shouldDisplayMessage(pi: any, product: any): boolean {
    const sku = product.SelectedAttributes.length > 0 ? product.SelectedAttributes[0]?.Sku3 : product.Sku;
    return sku === pi.Sku;
  }

  validateCart() {
    this.loader.showSpinner();
    this.ValidationsMessage = [];
    this.cartService.GetCartSummaryMethod(this.cartId).subscribe(gc => {
      const response = gc.response;
      this.ValidationsMessage = gc.responseObject.ValidationsMessage;

      if (response.code === 'CARTVAL0027') {
        this.modalProductNotAvailable.Text = response.message;
        this.modalProductNotAvailable.Title = response.titleMessage;
        this.productAvailable = true;
        this.showModalMissingProduct = true;
      }
      else {
        switch (response.code) {
          case 'OK0001':
            this.showMaxPurchase = false;
            this.showProductNotAvailable = false;
            this.productAvailable = false;
            this.showModalMissingProduct = false;
            break;

          case 'CARVAL0001':
          case 'CARTVAL0014':
          case 'CARTVAL0015':
          case 'CARTVAL0016':
          case 'CARTVAL0017':
          case 'CARTVAL0040':
          case 'CARTVAL0042':
          case 'CARTVAL0043':
            this.modalProductNotAvailable.Text = response.message;
            this.modalProductNotAvailable.Title = response.titleMessage;
            this.productAvailable = true;
            this.showModalMissingProduct = true;
            break;

          case 'CARTVAL0037':
            this.modalProductNotAvailable.Text = response.message;
            this.modalProductNotAvailable.Title = response.titleMessage;
            this.showModalMissingProduct = true;
            break;

          case 'CARTVAL0038':
            this.modalProductNotAvailable.Text = response.message;
            this.modalProductNotAvailable.Title = response.titleMessage;
            this.showModalMissingProduct = true;
            break;

          case 'CARTVAL0046':
            this.modalMax.Text = response.message;
            this.modalMax.Title = response.titleMessage;
            this.showModalMaxPurchase = true;
            this.showMaxPurchase = true;
            break;

          case 'ERR0001':
            this.modalProductNotAvailable.Text = response.message;
            this.modalProductNotAvailable.Title = response.titleMessage;
            this.showModalMissingProduct = true;
            // let r = JSON.stringify(response);
            // this.utilitiesService.insertErrorObservable(this.cartId, 'CARTSUMMARY', r, 'CARTSUMARRY').subscribe(t => {
            // });
            break;

          default:
            this.modalProductNotAvailable.Text = response.message;
            this.modalProductNotAvailable.Title = response.titleMessage;
            this.showModalMissingProduct = true;
            break;
        }
      }
      this.loader.hideSpinner();
    });
  }

  getImpulseProduct() {
    this.checkoutService.GetImpulseProductsMethod(this.cartId).subscribe(res => {
      if (res.response.code === "OK0001") {
        this.impulseProduct = res.object;
        this.adjustProducts();
      }
    });
  }

  showMissingProduct(): void {
    let modal = new window.bootstrap.Modal(
      document.getElementById('modalProductoFaltante')
    );
    this.showModalProductoFaltante = true;
    modal.show();
  }

  closeMissingPorduct(event: any): void {
    if (event == true) {
      this.updateCartSummary();
      this.showModalProductoFaltante = false;
    }
  }

  getIdentificationUser(userId: string) {
    this.clientService.GetUserPersonalInfoMethod(userId).subscribe(res => {
      if (res.response.code === 'OK0001') {
        this.identificationUser = res.responseObject.Identification;
      }
    })
  }

  getPluralizedName(itemName: string, quantity: number): string {
    if (itemName.toLowerCase() === 'unidad') {
      return quantity > 1 ? 'Unidades' : 'Unidad';
    }
    else if (itemName.toLowerCase() === 'caja') {
      return quantity > 1 ? 'Cajas' : 'Caja';
    }
    else {
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return itemName;
    }
  }

  addProductToCart(product: any, unitSelected: any, quantity: number, price: number) {
    if (product) {
      this.sharedService.addProductsToCart(product, unitSelected, quantity, price);
      this.validateCart();
      this.sharedService.updateCart();
    }
  }

  adjustProducts() {
    this.impulseProduct.forEach((p: any, index: number) => {
      p.displayQuantityComponent = false;
      p.quantityByComponent = 10;
      p.numbers = [];

      if(p.MaxToPurchase > 10) {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);
        }
      }

      this.validateUnit(p, index);
    });
  }

  validateUnit(p: any, index: number) {
    this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
    this.selectedQuantity[index] = p.MinToPurchase;

    if (p.ProductAttributes && p.ProductAttributes.length > 0) {
      if (this.selectedUnits[index] === 'Unidad') {
        this.selectedUnitPrice[index] = p.ProductAttributes[0] .PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[0].PriceWithTaxes;
      } else if (this.selectedUnits[index] === 'Caja') {
        if (p.ProductAttributes[0].PriceWithDiscount > 0 || p.ProductAttributes[1]?.PriceWithDiscount > 0) {
          this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[1]?.PriceWithDiscount;
        }
        else {
          this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithTaxes ? p.ProductAttributes[0].PriceWithTaxes : p.ProductAttributes[1]?.PriceWithTaxes;
        }
      }
    }
    else {
      this.selectedUnitPrice[index] = p.PriceWithTaxes; // Establece un valor predeterminado
    }
  }

  validateProduct(product: any): boolean {
    const isProductUnavailable = !product.IsAvailable;
    const isAttributeUnavailable = !product.SelectedAttributes[0]?.IsAvailable;
    const isSkuEmpty = product.Sku === '' || product.Sku === null;
    const isSku3Empty = product.SelectedAttributes[0]?.Sku3 === '' || product.SelectedAttributes[0]?.Sku3 === null;

    return isProductUnavailable || isAttributeUnavailable || isSkuEmpty || isSku3Empty;
  }
}
