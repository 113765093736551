import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IModal } from 'src/app/models/modal.model';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
declare var window: any;

@Component({
  selector: 'app-modal-general',
  templateUrl: './modal-general.component.html',
  styleUrls: ['./modal-general.component.css'],
})
export class ModalGeneralComponent implements OnChanges {
  @Input() showModal: boolean = false;
  @Input() modalData: any = {
    ModalName: 'modalGeneral'

  };
  @Input() title: string = '';
  @Input() text: string = '';
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;

  constructor(public utilities : UtilitiesService, public sanitizer: DomSanitizer,){
    window.addEventListener('popstate', this.handlePopState.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {


    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.modal.show();
    }
  }

  Accept() {
    this.modal.hide();
    this.showModalChange.emit(true);
  }

  Close() {
    this.modal.hide();
    this.showModalChange.emit(false);
  }

  handlePopState(): void {
    // Verificar si el modal está abierto y cerrarlo si es necesario
    if (this.modal) {
      this.Close();
    }
  }
}
