import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';


declare var window: any;
const settings = new SitefinitySettings();

@Component({
  selector: 'app-payment-procesing',
  templateUrl: './payment-procesing.component.html',
  styleUrls: ['./payment-procesing.component.css']
})
export class PaymentProcesingComponent implements OnInit {

  state: any;
  orderId!: string;
  cartId!: string;
  modal: any;
  html!: any;
  test: any;
  urlParaIframe: any;
  clientId!: string;
  form: any;
  unSafeHtml!: SafeHtml;
  safeHtml: any;
  user: any;
  name!: string;
  LifeMiles: any;
  environment: any;
  key: string = 'qhUQsZLvWzxg2T9V';
  settings: SitefinitySettings = new SitefinitySettings;
  status: boolean = false;

  constructor(
    private cartService: CartService,
    private utilitiesService: UtilitiesServicesService,
    private router: Router,
    private notificationService: NotificationService,
    private sitefinityPagesService: SitefinitypagesService,
  ){}

  ngOnInit(): void {
    this.state = window.history.state;
    this.status = this.state.status;

    if (this.utilitiesService.isUserLoggedIn() && this.status) {
      this.orderId = '';
      this.orderId = this.state.orderId;
      this.cartId = this.state.cartId;
      this.clientId = this.state.clientId;
      this.name = this.state.name;
      this.LifeMiles = this.state.lifeMiles;
      this.environment = this.state.enviroment;

      this.modal = new window.bootstrap.Modal(
        document.getElementById('modalProcesandoPago')
      );

      this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'proceso-compra')!.ID);

      if(this.modal){
        this.modal.show();
      }
      this.procesingPayment();
    }
    else {
      this.router.navigate(['/iniciosesion']);
    }

    window.history.replaceState({}, '', window.location.href);
  }

  procesingPayment() {
    if(this.orderId) {
      this.cartService.GetPowerTranzHTMLResponseByPurchaseIDMethod(this.orderId).subscribe(res => {
        if (res.response.code === "OK0001") {
          let headers = null;
          let object: any = {};
          let decrypHtml: any = this.utilitiesService.decryptDataMD5(res.responseObject, true, this.key);
          const base64 = this.encodeToBase64(decrypHtml);
          const url = settings.SitefinityServicesUrl + 'services/CallProcessing';
          object = {
            cartId: this.cartId,
            userId: this.clientId,
            OrdenId: this.orderId,
            HolderName: this.name,
            LifeMiles: this.LifeMiles,
            Environment: this.environment
          }
          object = JSON.stringify(object);
          // headers = new HttpHeaders({
          //   'mi-header': base64,
          //   'userInfo': object
          // });

          const miGUID = this.generarGUID();

          this.utilitiesService.insertInformationCallProcessing(base64, object, miGUID).subscribe(()=> {
            setTimeout(() => {

              window.location.href = url + '?p=' + miGUID;

            }, 1200);
          });
        }
        else {
          this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
        }
      });
    }
  }

  encodeToBase64(texto: string): string {
    return btoa(texto);
  }

  generarGUID(): string {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }
}
