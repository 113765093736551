<main>
  <h1 class="d-none">Farmacias La Bomba</h1>
  <!-- seccion -->
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" attr.data-bs-interval="{{timeBanners}}">
    <div class="carousel-indicators">


      <!-- <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
        [attr.aria-current]="true" [attr.aria-label]="'Slide 1'"></button>
      <button *ngFor="let i of banners; let idx = index" type="button" data-bs-target="#carouselExampleIndicators"
        [attr.data-bs-slide-to]="idx + 1" [attr.aria-label]="'Slide ' + (idx + 2)"></button>   -->

<button *ngFor="let i of banners; let idx = index" type="button" data-bs-target="#carouselExampleIndicators"
    [attr.data-bs-slide-to]="idx" [attr.aria-label]="'Slide ' + (idx + 1)"
    [class.active]="idx === 0" [attr.aria-current]="idx === 0 ? 'true' : null"></button>




    </div>

    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let i of banners; let isFirst = first" [class.active]="isFirst">
        <a *ngIf="i.openNewTab" [href]="i.url" target="_blank" rel="noopener noreferrer">
          <img src="{{ SitefinityServicesUrl + i.Images[0].Url }}" class="d-block w-100"  alt="…">
          <div class="carousel-content">

        </div>
        </a>
        <div *ngIf="!i.openNewTab && i.url =='' ">
          <img src="{{ SitefinityServicesUrl + i.Images[0].Url }}" class="d-block w-100"  alt="…">
          <div class="carousel-content">

        </div>
        </div>

        <a *ngIf="!i.openNewTab  && i.url !=''" [href]="i.url"  rel="noopener noreferrer">
          <img src="{{ SitefinityServicesUrl + i.Images[0].Url }}" class="d-block w-100"  alt="…">
          <div class="carousel-content">

         </div>
        </a>


      </div>
    </div>

    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <!-- seccion -->


  <!-- banner -->
  <div *ngIf="advertising.length > 0">
    <div class="container my-5">

      <div class="row g-4">
        <div class="col-12 col-lg-6 text-center" *ngFor="let a of advertising">

          <div class="banner-container" *ngIf="a.openLinkInNewTab && a.url == '' ">
            <img [src]="SitefinityServicesUrl + a.Image[0].Url" loading="lazy" alt="…" class="w-100">
          </div>
          <a  class="banner-container" *ngIf="a.openLinkInNewTab && a.url != '' " target="_blank" rel="noopener noreferrer" [href]="a.url">

            <div class="banner-container">
              <img [src]="SitefinityServicesUrl + a.Image[0].Url" loading="lazy" alt="…" class="w-100">
            </div>



          </a>



          <a class="banner-container" *ngIf="!a.openLinkInNewTab && a.url != '' "  [href]="a.url">
            <div class="banner-container">
              <img [src]="SitefinityServicesUrl + a.Image[0].Url" loading="lazy" alt="…" class="w-100">
            </div>

          </a>
          <div class="banner-container" *ngIf="!a.openLinkInNewTab && a.url == ''">
            <img [src]="SitefinityServicesUrl + a.Image[0].Url" loading="lazy" alt="…" class="w-100">
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="Iframeproductos"> </div>
  <!-- sec.3 -->
  <div *ngIf="featuredProducts.length > 0"  class="color-section"   >
    <div class="container">

      <div class="d-flex align-items-center justify-content-between">
        <h2 class="mb-0"> Productos destacados</h2>
        <a  *ngIf="showConocerMasButton"  (click)="LoadMore()" class="link cursor-pointer "> Conocer más </a>

      </div>


      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-4 pt-3">
        <div class="col" *ngFor="let p of featuredProducts; let i = index">
          <div *ngIf="p.IsAvailable" class="card card-costum-shadow h-100 px-3">
            <div class="product-card-content">
              <div class="img-container-item">
                <a  class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
                  <img *ngIf="p.ProductImages.length > 0" loading="lazy" alt="…" [src]="p.ProductImages[0].WebPath">
                  <img *ngIf="p.ProductImages.length == 0" loading="lazy" alt="…" src="https://app.farmacialabomba.com/Images/Upload/1/1/fcaa46e527f0424aplaceholderb_400.png">
                </a>
              </div>
              <!-- <span class="badge discount-badge" *ngIf="p.PercentDiscountRate > 0">
                {{p.PercentDiscountRate}}%
              </span>

              <span class="badge discount-badge" *ngIf="p.ProductAttributes.length <=0 && p.Dynamic">
                {{p.Dynamic}}
              </span>
              <span class="badge discount-badge"
                *ngIf="p.ProductAttributes.length>0 && p.ProductAttributes[0].Dynamic">
                {{p.ProductAttributes[0].Dynamic}}
              </span> -->
              <span class="badge discount-badge discount-animation" *ngIf="selectedPromotion[i]!='' && p.ShowDiscount">{{selectedPromotion[i]}}</span>
            </div>

            <div>
              <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
              <h3 class="mb-0 title-card-product">{{p.Name}}</h3>
            </a>
            </div>

            <div class="d-flex align-items-end justify-content-start py-3"
              *ngIf="p.ProductAttributes && p.ProductAttributes.length > 0">

              <a  class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
                <span class="product-price text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades')  && p.ProductAttributes[0].Name === 'Unidad'">
                  {{ selectedUnitPrice[i] | mycurrency  }}  i.v.a.i
                </span>
                <span class="product-price text-nowrap" *ngIf="(selectedUnits[i] === 'Caja'  || selectedUnits[i] === 'Cajas') && p.ProductAttributes">
                  {{ selectedUnitPrice[i] | mycurrency }}  i.v.a.i
                </span>
                <!-- Producto con descuento -->
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.ProductAttributes[0].PriceWithDiscount > 0">
                  {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                </span>
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes.length > 1 && p.ProductAttributes[1].PriceWithDiscount > 0">
                  {{p.ProductAttributes[1].PriceWithTaxes * selectedQuantity[i]| mycurrency }} i.v.a.i
                </span>
                <span class="product-discount ms-2 text-nowrap"
                  *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes[0].Name === 'Caja' && p.ProductAttributes[0].PriceWithDiscount > 0">
                  {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                </span>
            </a>
            </div>

            <div class="d-flex align-items-end justify-content-start py-3" *ngIf=" p.ProductAttributes.length  == 0">
              <a class="text-decoration-none cursor-pointer" (click)="goToDetailProduct(p.URL)">
              <span class="product-price text-nowrap" *ngIf="selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades' ">
                {{ selectedUnitPrice[i] * selectedQuantity[i] | mycurrency  }}  i.v.a.i
              </span>

              <!-- Producto con descuento -->
              <span class="product-discount ms-2 text-nowrap"
                *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.PriceWithDiscount > 0">
                {{p.PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
              </span>

              <span class="product-price text-nowrap" *ngIf="selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas' ">
                {{ selectedUnitPrice[i] * selectedQuantity[i] | mycurrency  }}  i.v.a.i
              </span>

              <!-- Producto con descuento -->
              <span class="product-discount ms-2 text-nowrap"
                *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.PriceWithDiscount > 0">
                {{p.PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
              </span>
            </a>
            </div>





            <div   class="product-card-footer d-flex justify-content-start">
              <div  *ngIf="!p.displayQuantityComponent && !p.NotForSale" class="d-flex align-items-center me-2">

                <div class="d-flex align-items-center me-1">
                  <div class="nav-item dropup">
                    <button class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      {{ selectedQuantity[i] }}
                    </button>




                      <ul  class="dropdown-menu number-dropdown">

                      <li *ngFor="let quantity of p.numbers">
                        <a class="dropdown-item" (click)="selectQuantity(i,quantity)" > {{quantity}} </a>
                      </li>

                      <hr *ngIf="this.p.MaxToPurchase > 9"  class="my-0">
                      <li *ngIf="this.p.MaxToPurchase > 10" (click)="openQuantityComponent(i,true)"><a class="dropdown-item" >10+ </a></li>

                    </ul>



                  </div>
                </div>

                <div class="d-flex align-items-center me-1">
                  <div class="nav-item dropup">



                    <button *ngIf="p.ProductAttributes.length > 1"
                    class="btn btn-outline-secondary dropdown-toggle dropdown-product" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false"
                    [ngClass]="{'btn-outline-secondary': true, 'dropdown-toggle': true, 'dropdown-product': true}">
                    {{ selectedUnits[i] }}
                   </button>


                   <button *ngIf="p.ProductAttributes.length == 1 || p.ProductAttributes.length == 0 "
                    class="btn btn-outline-secondary dropdown-product" type="button"
                    aria-expanded="false"
                   >
                    {{ selectedUnits[i] }}
                   </button>


                  <ul   class="dropdown-menu option-dropdown">
                    <li *ngFor="let item of p.ProductAttributes">
                     <a class="dropdown-item" (click)="selectType(i,item.Name)" >{{ getPluralizedName(item.Name, selectedQuantity[i]) }}</a>

                     </li>
               </ul>

                  </div>
                </div>


                   <div (click)="addProductToCart(p, selectedUnits[i], selectedQuantity[i], selectedUnitPrice[i])">

                          <button aria-label="Agregar al carrito" type="button" class="btn btn-primary product-icon"
                              data-bs-toggle="tooltip" data-bs-placement="top"
                              data-bs-title="Agregar al carrito" ><i
                                  class="bi bi-cart-plus-fill"></i>

                          </button>

                  </div>
              </div>

              <div  *ngIf="p.displayQuantityComponent" class="d-flex align-items-center" id="quantity-custom-amount">


                <input  [(ngModel)]="p.quantityByComponent" type="number" class="form-control input-quantity" id="quantity-product-input"
                onkeydown="javascript: return event.keyCode == 69 ? false : true"  aria-describedby="emailHelp" [max]="p?.MaxToPurchase" [min]="1">

                <button (click)="openQuantityComponent(i,false)" type="submit" class="btn btn-outline-secondary ms-1" ><i
                        class="bi bi-plus-lg"></i></button>


              </div>

                </div>




              </div>

            </div>

            </div>





    </div>
  </div>
</main>
