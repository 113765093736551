import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable,combineLatest,forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreRequest } from 'src/app/models/requests/request.model';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import { Clients,SignIn,ResponseSignIn,ResponseSignUp,InfoSignUp,Client,ClientAddress,
  MapPoints,Phone,ReducedClientRequest,ReducedClientResponse,ClientObjectGlobalResponse,
  ElectoralRollPersonalDataResponse,GetInfoClientResponse, ClientPersonalInfo,
  RequestFavoriteProduct, AddressProfile, WCFRequestAddress   }
from 'src/app/models/core/clients.model';

import { HighlightedProducts,ProductImage,Products,Request_productsBySkus,ReducedExchange,
    ExchangeReducedImage,ResponseHighlightedProducts, ResponseProducts,ResponseCategoriesHeader,Category,SearchRequest }
from 'src/app/models/core/products.model';

import {ProductsService} from 'src/app/services/core/products.service'
import { parse } from 'date-fns';
import {    ResponseGenericObject }
from 'src/app/models/core/products.model';
import { ObjectGlobalResponse} from 'src/app/models/core/utilities.model';

const settings = new SitefinitySettings();


@Injectable({
  providedIn: 'root'
})
export class HelperCheckoutService {

  constructor(    private http: HttpClient,
    private MessagesService_ :MessagesService,
    private ErrorOrLogsServices:UtilitiesServicesService,
    private products: ProductsService,
    private utilitiesService: UtilitiesServicesService
    ) {




  }



  public AddProductsToCart(request: any):Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/AddProductToCart';
    const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});

    return  this.http.post(url, request,{ headers });
}

public AddProductsToCartMethod (request: any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.AddProductsToCart(request).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}
private getMessages(code: string,category: string): Observable<ResponseInterface> {

  return new Observable<Response>((observer) => {
       var messageCore: string[]= [];
      this.MessagesService_.GetMessagesByKey(code,category).subscribe((data) => {
      var messageResponse = data.value[0];

          if (messageResponse == undefined) {


            messageCore.push(code);
            const responseServices: ResponseInterface = {
                message : 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
                alias : code + '-alias',
                status : true,
                code: code,
                messageCore: messageCore,
                buttonAcceptText:'',
                buttonCancelText: '',
                type:'',
                action:'',
                titleMessage: '',
                url: '',
                Messages:[]
               };
               observer.next(responseServices);
               observer.complete();

          }
          else
          {
            const responseServices: ResponseInterface = {
                message : messageResponse.message,
                alias : messageResponse.aliascode,
                status : true,
                code:messageResponse.code,
                messageCore: messageCore,
                buttonAcceptText:messageResponse.buttonAcceptText,
                buttonCancelText: messageResponse.buttonCancelText,
                type:messageResponse.type,
                action:messageResponse.action,
                titleMessage: messageResponse.titleMessage,
                url: messageResponse.url,
                Messages:[]
            }
            observer.next(responseServices);
            observer.complete();
          }
    },(error) =>{

      const responseServices: ResponseInterface = {
        message : 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
        alias : 'getMessages-error-alias-01',
        status : false,
        code:'getMessages-error-01',
        messageCore: messageCore,
        buttonAcceptText:'',
        buttonCancelText: '',
        type:'',
        action:'',
        titleMessage: "falta agregar el código",
        url:'',
        Messages:[]
    };
    observer.error(responseServices);
    observer.complete();

    });

});


}


public AddOrDeleteProductsFromInventoryServices(request: any):Observable<any>{

  const url = settings.CoreServicesUrl + 'BombaCart.svc/AddDeletedProductCartWithoutInventory';
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});

  return  this.http.post(url, request,{ headers });

}

public AddOrDeleteProductsFromInventoryMethod (request: any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.AddOrDeleteProductsFromInventoryServices(request).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}

public ValidateScheduleAvailabilityServices(request: any):Observable<any>{

  const url = settings.CoreServicesUrl + 'Shop.svc/ValidateScheduleAvailability/'+ request;
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});

  return  this.http.get(url,{ headers });

}


public ValidateScheduleAvailabilityMethod (request: any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.ValidateScheduleAvailabilityServices(request).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);
          if (Response) {
            return this.getMessages(code,'2').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: Response,
                  response: data
                };
                return response;
              })
            );
          }
          else {
            return this.getMessages('CARTADDVAL0001','6').pipe(
              map((data: any) => {
                const response: ResponseGenericObject = {
                  object: false,
                  response: data
                };
                return response;
              })
            );
          }
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



public GetShopInfoByAddressServices(idProvince:any,idCanton:any,idDictrict:any):Observable<any>{

  const url = settings.CoreServicesUrl + 'shop.svc/GetShopsByLocation/'+ idProvince+'/'+idCanton+'/'+idDictrict;
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});

  return  this.http.get(url,{ headers });

}

public GetShopInfoByAddressMethod (idProvince:any,idCanton:any,idDictrict:any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.GetShopInfoByAddressServices(idProvince,idCanton,idDictrict).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}



public ValidateShopAvailabilityByBlockServices(request: any):Observable<any>{

  const url = settings.CoreServicesUrl + 'Shop.svc/ValidateShopAvailabilityByBlock/'+ request;
  const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});

  return  this.http.get(url,{ headers });

}


public ValidateShopAvailabilityByBlockMethod (request: any): Observable<ResponseGenericObject> {
  return new Observable<ResponseGenericObject>((observer) => {
    this.ValidateShopAvailabilityByBlockServices(request).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const Response: any[] = JSON.parse(parametersResponse);

          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: Response,
                response: data
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code,'2').pipe(
            map((data: any) => {
              const response: ResponseGenericObject = {
                object: false,
                response: data
              };
              return response;
            })
          );
        }
      })
    ).subscribe(
      (response: ResponseGenericObject) => {
        observer.next(response);
        observer.complete();
      },
      (error: any) => {
        observer.error(error);
      }
    );
  });
}

}
