import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable,combineLatest,forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreRequest } from 'src/app/models/requests/request.model';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';

import { BannerResponse  } from 'src/app/models/sitefinity/banners.model';
import { UserFidelity  }
  from 'src/app/models/core/fidelity.model';
  const settings = new SitefinitySettings();




@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService) {   }


   private GetPrincipalBannersUrl = settings.SitefinityServicesUrl + 'api/default/banners?$expand=Images';



private getPrincipalBannersServices(): Observable<any>
{

  // gt – greater than
  // ge – greater than or equal to
  // lt – less than
  // le – less than or equal to

var url = this.GetPrincipalBannersUrl;
var currentDate = new Date();
var formattedDate = this.formatAsDesiredFormat(currentDate);
//var filter = '&$filter={date} ge initialDate and {date} lt finalDate'
var filter = '&$filter=initialDate le {date} and finalDate gt {date}'

const modifiedFilter = filter.replace(/{date}/g, formattedDate);
const finalUrl = url + modifiedFilter+ '&$orderby=priority';


const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
return this.http.get(finalUrl);


}

formatAsDesiredFormat(date: Date): string {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

public getPrincipalBannersMethod(): Observable<BannerResponse> {
  var BannerResponse = this.getPrincipalBannersServices();

 // ResponseInterface
  return BannerResponse.pipe(
    switchMap(response => {
      const Odata = response.value;
      var Array: any[] = [];

      Array = Odata;
      // Odata.forEach((DataItem: any) => {


      //    Array.push(DataItem);


      // });

      if(Array.length > 0 )
      {
        return this.handleCondition("banner-success",Array)



      }
     else
      {


            return this.handleCondition("banner-fail01",Array)

      }






    })
  );
}

private handleCondition(code:string,banner:any[]): Observable<BannerResponse> {

  return new Observable<BannerResponse>(observer => {

    this.MessagesService_.getMessagesMethod(code,'1').subscribe(message => {

      const result: BannerResponse = {
        Banners:banner,
        response: message,

      };
      observer.next(result);
      observer.complete();
    })


  })



}


}
