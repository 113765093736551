import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreRequest } from 'src/app/models/requests/request.model';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import {
  Clients, SignIn, ResponseSignIn, ResponseSignUp, InfoSignUp, Client, ClientAddress,
  MapPoints, Phone, ReducedClientRequest, ReducedClientResponse, ClientObjectGlobalResponse,
  ElectoralRollPersonalDataResponse, GetInfoClientResponse, ClientPersonalInfo,
  RequestFavoriteProduct, AddressProfile, WCFRequestAddress
}
  from 'src/app/models/core/clients.model';

import {
  HighlightedProducts, ProductImage, Products, Request_productsBySkus, ReducedExchange,
  ExchangeReducedImage, ResponseHighlightedProducts, ResponseProducts, ResponseCategoriesHeader, Category, SearchRequest
}
  from 'src/app/models/core/products.model';

import { ProductsService } from 'src/app/services/core/products.service'


import { parse } from 'date-fns';
import { FavoriteProduct, SelectedAttribute, FavoriteProductResponse, RemoveFavoriteProduct, FavoriteProductsResponse }
  from 'src/app/models/core/favorite-products.model';
import { UtilitiesService } from './utilities.service';
import { eachMonthOfInterval } from 'date-fns/esm/fp';



const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  private defaultPadronNameDirection = "Default_Name_Direction_01";

  constructor(
    private http: HttpClient,
    private MessagesService_: MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService,
    private products: ProductsService,
    private utilitiesService: UtilitiesServicesService,
    private utils:UtilitiesService
  ) { }


  private SignInServices(SignIn: SignIn): Observable<any> {
    const url = settings.CoreServicesUrl + 'user.svc/VaidateUserAccount';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, SignIn, { headers });
  }



  public SignInMethod(SignIn: SignIn): Observable<ResponseSignIn> {
    return new Observable<ResponseSignIn>((observer) => {
      const requestSSO: ReducedClientRequest = {
        IDClient: 0,
        Argument: SignIn.LoginName
      };
      const SSOResponse = this.GetReducedClientMethod(requestSSO);
      const handleCondition = (code: string): Observable<ResponseSignIn> => {
        return this.handleConditionSignIn(code);
      };

      let res = this.SignInServices(SignIn).subscribe(responseCore => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "CODMSS0004" || code === "VALCLIRPC01") {

          //obtiene la info del usuario logueado
          const parametersResponse: string = coreResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          //Encriptacion de los datos sencibles
          const IDClient = this.utilitiesService.encryptData(responseObject.IDClient.toString());
          const UserToken = this.utilitiesService.encryptData(responseObject.UserToken.toString());
          const IdCart = this.utilitiesService.encryptData(responseObject.CartSummary?.IdCart.toString());
          const Email = this.utilitiesService.encryptData(responseObject.Email.toString());
          const identification = this.utilitiesService.encryptData(responseObject.Identification.toString());

          const newSessionClient: any = {
            i: IDClient,
            n: responseObject.FullName,
            t: UserToken,
            c: IdCart,
            e: Email,
            u:identification
          };

          SSOResponse.subscribe(sso => {
            const SSOresponseMessages = sso.response;
            const SSOclientInfo = sso.responseObject;
            var SSOvalidateCode = SSOresponseMessages.Messages[0];
            if(SSOvalidateCode == undefined)
            {
              SSOvalidateCode = SSOresponseMessages.code;

            }

            if (SSOvalidateCode === "OK0001") {
              const ApplyInformedConsent = SSOclientInfo.applyInformedConsent;
              const RequirePasswordChange = SSOclientInfo.requirePasswordChange;

              // if (ApplyInformedConsent && !RequirePasswordChange) {
              //   handleCondition("digitalUser-SSO-success").subscribe(response => {
              //     observer.next(response);
              //     observer.complete();
              //   });
              // } else

              if (ApplyInformedConsent && RequirePasswordChange) {
                //No tiene contraseña
                handleCondition("digitalUser-SSO-password-success").subscribe(response => {
                  observer.next(response);
                  observer.complete();
                });
              } else {

                if(code  === "VALCLIRPC01")
                {

                  handleCondition(code).subscribe(response => {
                    response.sessionClient = newSessionClient;
                    observer.next(response);
                    observer.complete();
                  });

                }
                else
                {

                  handleCondition("SignInMethod-success").subscribe(response => {
                    response.sessionClient = newSessionClient;
                    observer.next(response);
                    observer.complete();
                  });


                }


              }
            } else {
              //this.ErrorOrLogsServices.insertError("SignInMethod-SSO-error", "SignInMethod-Services", SignIn.LoginName, SSOvalidateCode);
              handleCondition("SSO-Fail-01").subscribe(response => {
                observer.next(response);
                observer.complete();
              });
            }
          });
        } else {

       if(coreResponse.Messages[0] == "CLICARTVAL0001" )
       {

        const parametersResponse: string = coreResponse.Parameters as string;
        const responseObject: any = JSON.parse(parametersResponse);

       const IDClient = this.utilitiesService.encryptData(responseObject.IDClient.toString());
       const UserToken = this.utilitiesService.encryptData(responseObject.UserToken.toString());
       const IdCart = this.utilitiesService.encryptData(responseObject.CartSummary?.IdCart.toString());
        const newSessionClient: any = {
          i: IDClient,
          n: responseObject.FullName,
          t: UserToken,
          c: IdCart
        };
        handleCondition(coreResponse.Messages[0]).subscribe(response => {

          response.Object = responseObject;
          response.sessionClient = newSessionClient;
          observer.next(response);
          observer.complete();
        });

      }
       else
       {



        handleCondition(coreResponse.Messages[0]).subscribe(response => {

          observer.next(response);
          observer.complete();
        });

       }

        }
      });

      if (!res) {
        // Agregar un retorno de valor predeterminado en caso de que no se cumpla ninguna de las condiciones anteriores
        return handleCondition("default-value").subscribe(response => {
          observer.next(response);
          observer.complete();
        });
      }

      else {
        return res;
      }
    });
  }


  private handleConditionSignIn(code: string): Observable<ResponseSignIn> {
    return this.MessagesService_.getMessagesMethod(code, '1').pipe(
      map(message => {
        const response: ResponseSignIn = {
          response: message
        };
        return response;
      }),
      catchError(error => {
        // Manejar el error aquí si es necesario
        return throwError(error);
      })
    );
  }






  public getMessages(code: string, category: string): Observable<ResponseInterface> {

    return new Observable<Response>((observer) => {
      var messageCore: string[] = [];
      this.MessagesService_.GetMessagesByKey(code, category).subscribe((data) => {
        var messageResponse = data.value[0];

        if (messageResponse == undefined) {


          messageCore.push(code);
          const responseServices: ResponseInterface = {
            message: 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
            alias: code + '-alias',
            status: true,
            code: code,
            messageCore: messageCore,
            buttonAcceptText: '',
            buttonCancelText: '',
            type: '',
            action: '',
            titleMessage: '',
            url: '',
            Messages: []
          };
          observer.next(responseServices);
          observer.complete();

        }
        else {
          const responseServices: ResponseInterface = {
            message: messageResponse.message,
            alias: messageResponse.aliascode,
            status: true,
            code: messageResponse.code,
            messageCore: messageCore,
            buttonAcceptText: messageResponse.buttonAcceptText,
            buttonCancelText: messageResponse.buttonCancelText,
            type: messageResponse.type,
            action: messageResponse.action,
            titleMessage: messageResponse.titleMessage,
            url: messageResponse.url,
            Messages: []
          }
          observer.next(responseServices);
          observer.complete();
        }
      }, (error) => {

        const responseServices: ResponseInterface = {
          message: 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
          alias: 'getMessages-error-alias-01',
          status: false,
          code: 'getMessages-error-01',
          messageCore: messageCore,
          buttonAcceptText: '',
          buttonCancelText: '',
          type: '',
          action: '',
          titleMessage: "falta agregar el código",
          url: '',
          Messages: []
        };
        observer.error(responseServices);
        observer.complete();

      });

    });


  }


  private SignUpServices(InfoSignUp: InfoSignUp): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/SaveClient';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    this.utils.GetParametersByKey('DefaultPadronNameDirection').subscribe((o) => {
      this.defaultPadronNameDirection = this.utils.decodeData(o.value[0].value);
    });

    var clientInfo = this.SetClientProperties(InfoSignUp);
    const requestCore: CoreRequest = {
      ProgId: "CLISITE",
      UserId: "1",
      AllowApplyCoupons: false,
      IDSavedCard: null,
      IsValid: false,
      Messages: null,
      ServerPath: '',
      WithErrors: false,
      Parameters: ''
    };
    requestCore.Parameters = JSON.stringify(clientInfo);
    return this.http.post(url, requestCore, { headers });
  }

  public SignUpMethod(InfoSignUp: InfoSignUp): Observable<any> {


    return new Observable<ResponseSignIn>((observer) => {
      this.SignUpServices(InfoSignUp).subscribe(responseCore => {

        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        var code = coreResponse.Messages[0];

        if(code=='OK0001'){
          code = 'signup-success'
        }

        this.getMessages(code, '1').subscribe((data) => {

          const response: ResponseSignIn = {
            response: data
          };
          observer.next(response);
          observer.complete();
        })


      });

    });

  }



  private SetClientProperties(InfoSignUp: InfoSignUp): Client {

    const client: Client = {
      CartSummary: null,
      Email: InfoSignUp.email,
      FullName: `${InfoSignUp.name} ${InfoSignUp.firstLastName} ${InfoSignUp.secondLastName}`,
      IDClient: 0,
      Password: InfoSignUp.password,
      UserToken: null,
      AcceptTerms: true,
      RequirePasswordChange: false,
      Addresses: [
        {
          FullAddress: this.defaultPadronNameDirection,
          IDAddressCanton: InfoSignUp.idCanton,
          IDAddressDistrict: InfoSignUp.idDistrict,
          IDAddressProvince: InfoSignUp.idProvince,
          IDClient: 0,
          IDClientAddress: 0,
          IDDeliveryArea: 0,
          IDShop: 0,
          InactiveShop: false,
          IsDefault: false,
          IsNew: true,
          MapPoints: {
            Latitude: 0,
            Longitude: 0
          },
          Name: this.defaultPadronNameDirection,
          Phone: InfoSignUp.phone,
          Status: "AP"
        }
      ],
      AllowsCalls: true,
      AllowsEmails: true,
      AllowsSMS: true,
      ApplyInformedConsent: true,
      AllowApplyCoupons: null,
      // BirthdayFormat: `${InfoSignUp.day}/${InfoSignUp.month}/${InfoSignUp.year}`,
      Birthday: InfoSignUp.birthday,
      ConfirmationPassword: InfoSignUp.password,
      Extended: null,
      Gender: null,
      IAmOfAge: true,
      IdClientType: InfoSignUp.typeidentification,
      Identification: InfoSignUp.identification,
      LastName: InfoSignUp.firstLastName,
      LoyaltyCard: "",
      Name: InfoSignUp.name,
      Phones: [
        {
          IDClient: 0,
          IDClientPhone: 0,
          IdPhoneType: 2,
          IsDefault: true,
          Phone: InfoSignUp.phone,
          Status: "AC"
        }
      ],
      SecondSurname: InfoSignUp.secondLastName,
      Status: "AC"
    };


    // Setear IdPhoneType en base al primer dígito del número de teléfono
    // Corregir el código para establecer el valor correcto de IdPhoneType
    const firstDigit = InfoSignUp.phone.substring(0, 1);
    const IdPhoneType = settings.phoneTypeMap[firstDigit] || 0;
    client.Phones[0].IdPhoneType = IdPhoneType;

    return client;
  }

  private GetReducedClientService(request: ReducedClientRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/GetReducedClient';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });
  }

  public GetReducedClientMethod(request: ReducedClientRequest):
    Observable<ClientObjectGlobalResponse> {
    return this.GetReducedClientService(request).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        const code = wcfResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: ReducedClientResponse = {
            idClient: responseObject.IDClient,
            idClientType: responseObject.IdClientType,
            applyInformedConsent: responseObject.ApplyInformedConsent,
            requirePasswordChange: responseObject.RequirePasswordChange,
            source: responseObject.Source,
            status: responseObject.Status,
            requiresInformedConsent: responseObject.RequiresInformedConsent,
            email: responseObject.Email,
            phone: responseObject.Phone,
            lastName: responseObject.LastName,
            name: responseObject.Name,
            secondSurname: responseObject.SecondSurname,
          };

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {
          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetElectoralRollPersonDataService(argument: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/GetPersonData/' + argument;
    return this.http.get(url);
  }

  public GetElectoralRollPersonDataMethod(argument: string):
    Observable<ClientObjectGlobalResponse> {
    return this.GetElectoralRollPersonDataService(argument).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        const code = wcfResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);
          const ecommerceResponse: ElectoralRollPersonalDataResponse = {
            birthday: responseObject.Birthday,
            codeELEC: responseObject.CodeELEC,
            idCanton: responseObject.IDCanton,
            idDistrict: responseObject.IDDistrict,
            idProvince: responseObject.IDProvince,
            lastName: responseObject.LastName,
            name: responseObject.Name,
            secondSurname: responseObject.SecondSurname,
            address: responseObject.Address,
            identification: responseObject.Identification,
            provinceName: responseObject.ProvinceName,
            cantonName: responseObject.CantonName,
            districtName: responseObject.DistrictName,
            gender: responseObject.Gender,
          };

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }


  private handleCondition(code: string, clientInfo: ElectoralRollPersonalDataResponse): Observable<GetInfoClientResponse> {

    return new Observable<GetInfoClientResponse>(observer => {

      this.MessagesService_.getMessagesMethod(code, '1').subscribe(message => {

        const result: GetInfoClientResponse = {
          clients: clientInfo, // Puedes asignar aquí el valor adecuado de 'ElectoralRollPersonalDataResponse' si tienes la información disponible.
          response: message as ResponseInterface
        };
        observer.next(result);
        observer.complete();
      })


    })



  }



  public GetInfoClient(identification: string, typeidentification: string, sendEmail? : boolean): Observable<GetInfoClientResponse> {
    let requestSSO: ReducedClientRequest;
    if (sendEmail == true) {
      requestSSO = {
        IDClient: 0,
        Argument: identification,
        SendEmail: true,
      };
    } else {
      requestSSO = {
        IDClient: 0,
        Argument: identification,
      };
    }

    const SSOResponse = this.GetReducedClientMethod(requestSSO);

    return SSOResponse.pipe(
      switchMap(sso => {
        var SSOresponseMessages = sso.response;
        var SSOvalidateCode = SSOresponseMessages.messageCore[0];
        if(SSOvalidateCode == undefined)
        {
          SSOvalidateCode = SSOresponseMessages.code;

        }


        if (typeidentification === "1") {

          return this.GetElectoralRollPersonDataMethod(identification).pipe(
            switchMap(GetPadronInfoResponse => {
              const responseMessages = GetPadronInfoResponse.response;
              const clientInfo = GetPadronInfoResponse.responseObject;
              var validateCode = responseMessages.messageCore[0];

              if(validateCode == undefined)
              {
                validateCode = responseMessages.code;

              }


              if(sso.responseObject != null && sso.responseObject.status != 'AC')
              {
                //se usa para validar que el usuario tiene un status diferente a activo.
                return this.handleCondition("GetInfoClient-status-fail", clientInfo);
              }


              if (validateCode === "OK0001") {
                if (SSOvalidateCode === "OK0001") {
                  const SSOclientInfo = sso.responseObject;
                  const ApplyInformedConsent = SSOclientInfo.applyInformedConsent;
                  const RequirePasswordChange = SSOclientInfo.requirePasswordChange;


                  if (ApplyInformedConsent && !RequirePasswordChange) {
                    // El usuario sí existe y se debe mostrar un mensaje de que inicie sesión (Usuario Digital).
                    return this.handleCondition("digitalUser-SSO-success", clientInfo);
                  }

                  if (ApplyInformedConsent && RequirePasswordChange) {
                    // El usuario sí existe pero no es digital.
                    //Debe mostrar un mensaje de que cree su contraseña, según el correo que se le envió porque ya tiene cuenta.
                    return this.handleCondition("digitalUser-SSO-password-success", clientInfo);
                  }

                  // Si no cumple las condiciones anteriores, se debe registrar normalmente.
                  return this.handleCondition("digitalUser-SSO-success", clientInfo);
                }
                else if (SSOvalidateCode === "INF0001") {
                  return this.handleCondition("GetInfoClient-success", clientInfo);
                }
                else {
                  // Si hay un error consultando el SSO.
                  // this.ErrorOrLogsServices.insertError("GetInfoClient-Padron-error", "GetInfoClient-Services", identification, validateCode);
                  return this.handleCondition("SSO-Fail-01", clientInfo);
                }

              }
              else {
                // Si hay un error en la respuesta de Padron.
                //  this.ErrorOrLogsServices.insertError("GetInfoClient-SSO-error", "GetInfoClient-Services", identification, SSOvalidateCode);
                return this.handleCondition("Padron-Fail-01", clientInfo);

              }


            })
          );

        }
        else {
          if (SSOvalidateCode === "OK0001") {
            const SSOclientInfo = sso.responseObject;
            const ApplyInformedConsent = SSOclientInfo.applyInformedConsent;
            const RequirePasswordChange = SSOclientInfo.requirePasswordChange;
            var clientInfo: any;

            if (ApplyInformedConsent && !RequirePasswordChange) {
              return this.handleCondition("digitalUser-SSO-success", clientInfo);
            }
            if (ApplyInformedConsent && RequirePasswordChange) {
              return this.handleCondition("digitalUser-SSO-password-success", clientInfo);
            }
            return this.handleCondition("digitalUser-SSO-success", clientInfo);
          }
          else if (SSOvalidateCode === "INF0001") {
            return this.handleCondition("GetInfoClient-success", clientInfo);
          }
          else {
            return this.handleCondition("SSO-Fail-01", clientInfo);
          }
        }
      })
    );
  }

  public GetInfoClientContactUs(identification: string, typeidentification: string, sendEmail? : boolean): Observable<GetInfoClientResponse> {
    let requestSSO: ReducedClientRequest;
    requestSSO = {
      IDClient: 0,
      Argument: identification,
    };

    const SSOResponse = this.GetReducedClientMethod(requestSSO);

    return SSOResponse.pipe(
      switchMap(sso => {
        var SSOresponseMessages = sso.response;
        var SSOvalidateCode = SSOresponseMessages.messageCore[0];
        if(SSOvalidateCode == undefined)
        {
          SSOvalidateCode = SSOresponseMessages.code;
        }

        if(sso.responseObject){
          if (SSOvalidateCode === "OK0001") {
            const SSOclientInfo = sso.responseObject;
            if(SSOclientInfo.idClientType == typeidentification){
              return this.handleCondition("GetInfoClient-success",SSOclientInfo);
            }
            else{
              return this.handleCondition("Padron-Fail-01", clientInfo);
            }
          }
          else {
            return this.handleCondition("SSO-Fail-01", clientInfo);
          }
        }
        else{
          if (typeidentification === "1") {
            return this.GetElectoralRollPersonDataMethod(identification).pipe(
              switchMap(GetPadronInfoResponse => {
                const responseMessages = GetPadronInfoResponse.response;
                const clientInfo = GetPadronInfoResponse.responseObject;
                var validateCode = responseMessages.messageCore[0];

                if(validateCode == undefined)
                {
                  validateCode = responseMessages.code;
                }

                if(sso.responseObject != null && sso.responseObject.status != 'AC')
                {
                  //se usa para validar que el usuario tiene un status diferente a activo.
                  return this.handleCondition("GetInfoClient-status-fail", clientInfo);
                }

                if (validateCode === "OK0001") {
                  if (SSOvalidateCode === "OK0001") {
                    const SSOclientInfo = sso.responseObject;
                    // Si no cumple las condiciones anteriores, se debe registrar normalmente.
                    return this.handleCondition("digitalUser-SSO-success", SSOclientInfo);
                  }
                  else {
                    return this.handleCondition("Padron-Fail-01", clientInfo);
                  }
                }
                else {
                  // Si hay un error en la respuesta de Padron.
                  //  this.ErrorOrLogsServices.insertError("GetInfoClient-SSO-error", "GetInfoClient-Services", identification, SSOvalidateCode);
                  return this.handleCondition("Padron-Fail-01", clientInfo);
                }
              })
            );
          }
          else {
            var clientInfo: any;
            return this.handleCondition("GetInfoClient-success", clientInfo);
          }
        }
      })
    );
  }

  private RecoverPasswordService(argument: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/RecoverPassword/' + argument;
    return this.http.get(url);

  }


  public RecoverPasswordMethod(argument: string):
    Observable<ClientObjectGlobalResponse> {
    return this.RecoverPasswordService(argument).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        const code = wcfResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages("recoverpassword-01", '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }


  private GetUserPersonalInfoService(argument: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/GetUserPersonalInfo/' + argument;
    return this.http.get(url);
  }


  public GetUserPersonalInfoMethod(argument: string):
    Observable<ClientObjectGlobalResponse> {
    return this.GetUserPersonalInfoService(argument).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];
        if (code == undefined) {
          code = (wcfResponse.IsValid === false && wcfResponse.Parameters === null) ? "INF0001" : "OK0001";
        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: any = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }
  private GetFavoritesProductsServices(token: string): Observable<any> {
    var url = settings.CoreServicesUrl + 'Cart.svc/GetClientFavoriteProductsWithImages/' + token;

    return this.http.get(url);
  }



  public GetFavoritesProductsMethod(token: string): Observable<FavoriteProductsResponse> {
    return this.GetFavoritesProductsServices(token).pipe(
      switchMap((responseCore: any) => {

        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);

          if (listResponse.length > 0) {
            return this.getMessages(code, '1').pipe(
              map((data: any) => ({
                products: listResponse,
                response: data
              }))
            );
          } else {
            return this.getMessages("GetFavoritesProductsMethod-fail01", '1').pipe(
              map((data: any) => ({
                products: [],
                response: data
              }))
            );
          }
        } else {
          return this.getMessages("GetFavoritesProductsMethod-fail02", '1').pipe(
            map((data: any) => ({
              products: [],
              response: data
            }))
          );
        }


      })
    );
  }

  private AddClientFavoriteProductServices(CoreFavoriteProduct: FavoriteProduct): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/SaveProductAsFavorite';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });


    return this.http.post(url, CoreFavoriteProduct, { headers });
  }

  public AddClientFavoriteProductMethod(product: RequestFavoriteProduct[]): Observable<FavoriteProductResponse> {

    var favoriteProduct: any = this.createFavoriteProduct(product)

    return this.AddClientFavoriteProductServices(favoriteProduct).pipe(
      switchMap((responseCore: any) => {

        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);

          const products: FavoriteProduct[] = [];

          return this.getMessages("addfavoriteProduct-success", '1').pipe(
            map((data: any) => ({
              products: products,
              response: data
            }))
          );

        } else {
          return this.getMessages(code, '1').pipe(
            map((data: any) => ({
              products: [],
              response: data
            }))
          );
        }


      })
    );
  }

  createFavoriteProduct(product: RequestFavoriteProduct[]): FavoriteProduct {
    let tempSelectedAttribute: any | SelectedAttribute;
    if(product[0].Attributes.length>0){
      for (let index = 0; index < product[0].Attributes.length; index++) {
        const element = product[0].Attributes[index];
        tempSelectedAttribute  = {
          AddedPrice: element.AddedPrice,
          Name: element.NameAttribute,
          IdProductAttribute: element.IdProductAttribute,
          Quantity: element.Quantity,
        }
      }
    }
    else{
      tempSelectedAttribute = null;
    }

    const selectedAttribute: SelectedAttribute = tempSelectedAttribute;

    const favoriteProduct: FavoriteProduct = {
      IdClient: product[0].IdClient,
      Product: {
        AddToQueue: product[0].AddToQueue,
        BasePrice: 0.0,
        Comments: "",
        CurrentPrice: 0.0,
        CurrentPriceWithoutTaxes: 0.0,
        CurrentTotalPrice: null,
        CurrentTotalPriceWithoutTaxes: null,
        Discount: 0.0,
        FavoriteName: product[0].Name,
        IdProduct: product[0].IdProduct,
        Images: null,
        InEdition: false,
        IsAdditional: false,
        IsAvailable: false,
        IsCouponPromotion: false,
        IsCurrentlyTimeAvailable: false,
        IsEqualShopType: false,
        IsPriceDifferent: false,
        IsShopAvailable: false,
        IsWithDefaultAttributes: false,
        LargeDescription: null,
        LocalCartProductId: 0,
        MaxToPurchase: 0,
        MinToPurchase: 0,
        Name: product[0].Name,
        PriceDifference: null,
        PriceWithDiscount: 0.0,
        PriceWithTaxes: null,
        ProductImages: null,
        ProductSet: null,
        Quantity: product[0].Quantity,
        SelectedAttributes: selectedAttribute == null?null:[selectedAttribute],
        Sku: null,
        Taxes: null,
        URL: null,
      },
      IdFavoriteProduct: 0,
      ProductSetWithoutProducts: false,
      WithProductSet: false,
    };
    return favoriteProduct;
  }

  //Metodo original pero que nose utiliza
  createFavoriteProductOriginal(product: RequestFavoriteProduct[]): FavoriteProduct {
    let tempSelectedAttribute: any | SelectedAttribute;
    if(product[0].Attributes.length>0){
      tempSelectedAttribute  = {
        AddedPrice: product[0].Attributes[0].AddedPrice,
        CategoryName: null,
        IdParentProductAttribute: 0,
        IdProductAttribute: product[0].Attributes[0].IdProductAttribute,
        IsAvailable: false,
        IsPriceDifferent: false,
        IsShopAvailable: false,
        Name: product[0].Attributes[0].NameAttribute,
        PriceDiff: null,
        PriceDifference: null,
        PriceWithTaxes: null,
        PromotionPrice: null,
        Quantity: product[0].Attributes[0].Quantity,
        RealPrice: null,
        Sku3: null,
        Taxes: null
      };
    }
    else{
      tempSelectedAttribute = null;
    }

    const selectedAttribute: SelectedAttribute = tempSelectedAttribute;

    const favoriteProduct: FavoriteProduct = {
      IdClient: product[0].IdClient,
      Product: {
        AddToQueue: product[0].AddToQueue,
        BasePrice: 0.0,
        Comments: "",
        CurrentPrice: 0.0,
        CurrentPriceWithoutTaxes: 0.0,
        CurrentTotalPrice: null,
        CurrentTotalPriceWithoutTaxes: null,
        Discount: 0.0,
        FavoriteName: product[0].Name,
        IdProduct: product[0].IdProduct,
        Images: null,
        InEdition: false,
        IsAdditional: false,
        IsAvailable: false,
        IsCouponPromotion: false,
        IsCurrentlyTimeAvailable: false,
        IsEqualShopType: false,
        IsPriceDifferent: false,
        IsShopAvailable: false,
        IsWithDefaultAttributes: false,
        LargeDescription: null,
        LocalCartProductId: 0,
        MaxToPurchase: 0,
        MinToPurchase: 0,
        Name: product[0].Name,
        PriceDifference: null,
        PriceWithDiscount: 0.0,
        PriceWithTaxes: null,
        ProductImages: null,
        ProductSet: null,
        Quantity: product[0].Quantity,
        SelectedAttributes: [selectedAttribute],
        Sku: null,
        Taxes: null,
        URL: null,
      },
      IdFavoriteProduct: 0,
      ProductSetWithoutProducts: false,
      WithProductSet: false,
    };

    return favoriteProduct;
  }

  private DeleteClientFavoriteProductServices(CoreFavoriteProduct: RemoveFavoriteProduct): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/DeleteClientFavoriteProduct';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });


    return this.http.post(url, CoreFavoriteProduct, { headers });
  }



  public DeleteClientFavoriteProductMethod(product: RemoveFavoriteProduct): Observable<FavoriteProductResponse> {

    return this.DeleteClientFavoriteProductServices(product).pipe(
      switchMap((responseCore: any) => {

        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);
          const products: FavoriteProduct[] = [];

          return this.getMessages("DeletefavoriteProduct-success", '1').pipe(
            map((data: any) => ({
              products: products,
              response: data
            }))
          );

        } else {
          return this.getMessages(code, '1').pipe(
            map((data: any) => ({
              products: [],
              response: data
            }))
          );
        }


      })
    );
  }


  public CheckIfClientHasFavoriteProductMethod(idProduct: number, token: number): Observable<boolean> {
    return this.GetFavoritesProductsServices(token.toString()).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];
        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);
          const hasFavorite = listResponse.some(item => item.IDProduct === idProduct);

          if (hasFavorite) {
            return of(true);
          }
          return of(false); // Devuelve un observable emitiendo 'true'
        } else {
          return of(false); // Devuelve un observable emitiendo 'false'
        }
      })
    );
  }

  private GetClientAddressProfileService(request: WCFRequestAddress): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/GetClientAddressProfile';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }

  public GetClientAddressProfileMethod(idClient: string):
    Observable<ClientObjectGlobalResponse> {

    const mapData: MapPoints =
    {
      Latitude: 0,
      Longitude: 0
    };
    const requestWCF: WCFRequestAddress = {
      IdClient: Number(idClient),
      Location: mapData
    };
    return this.GetClientAddressProfileService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === false && wcfResponse.Parameters === null) ? "INF0001" : "OK0001";

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: AddressProfile =
            this.SetClientAddressProfileProperties(responseObject);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private SetClientAddressProfileProperties(responseObject: any): AddressProfile {
    const ecommerceResponse: AddressProfile = {

      Addresses: responseObject.Addresses,
      Phones: responseObject.Phones,
      PendingAddressCount: responseObject.PendingAddressCount
    };

    return ecommerceResponse;

  }

  private UpdateClientInfoServices(ClientInfo: any): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/SaveEncryptedClient';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    const requestCore: CoreRequest = {
      ProgId: "CLISITE",
      UserId: "1",
      AllowApplyCoupons: false,
      IDSavedCard: null,
      IsValid: false,
      Messages: null,
      ServerPath: '',
      WithErrors: false,
      Parameters: '',
      BypassSecurity: ClientInfo.BypassSecurity
    };

    requestCore.Parameters = JSON.stringify(ClientInfo);

    return this.http.post(url, requestCore, { headers });
  }




  public UpdateClientInfoMethod(ClientInfo: any): Observable<ResponseInterface> {
    return this.UpdateClientInfoServices(ClientInfo).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);

          return this.getMessages("UpdateClientInfoMethod-success", '1');
        } else {
          return this.getMessages(code, '1');
        }
      })
    );
  }



  private DeleteClientInfoServices(ClientInfo: any): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/DeleteAddress';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    const requestCore: CoreRequest = {
      ProgId: "CLISITE",
      UserId: "1",
      AllowApplyCoupons: false,
      IDSavedCard: null,
      IsValid: false,
      Messages: null,
      ServerPath: '',
      WithErrors: false,
      Parameters: ''
    };


    requestCore.Parameters = JSON.stringify(ClientInfo);
    return this.http.post(url, requestCore, { headers });
  }




  public DeletelientInfoMethod(ClientInfo: any): Observable<ResponseInterface> {
    return this.DeleteClientInfoServices(ClientInfo).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);

          return this.getMessages("UpdateClientInfoMethod-success", '1');
        } else {
          return this.getMessages(code, '1');
        }
      })
    );
  }


  private GetNearestShopAndDeliveryAreaService(request: WCFRequestAddress): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/GetNearestShopAndDeliveryArea';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public GetNearestShopAndDeliveryAreaMethod( latitude: number, longitude: number):
    Observable<ClientObjectGlobalResponse> {
      const mapData: MapPoints =
    {
      Latitude: latitude,
      Longitude: longitude
    };
    const requestWCF: WCFRequestAddress = {
      IdClient: 0,
      Location: mapData
    };
    return this.GetNearestShopAndDeliveryAreaService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        var code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {

          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);
          if (ecommerceResponse.IDShop != 0) {
            return this.getMessages(code, '1').pipe(
              map((data: any) => {
                const response: ClientObjectGlobalResponse = {
                  response: data,
                  responseObject: ecommerceResponse
                };
                return response;
              })
            );
          } else {
            return this.getMessages('SHOPANDDELIVERY03', '7').pipe(
              map((data: any) => {
                const response: ClientObjectGlobalResponse = {
                  response: data,
                  responseObject: ecommerceResponse
                };
                return response;
              })
            );
          }
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private UpdateInformedConsentService(primaryKey: number): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/UpdateInformedConsent/'+ primaryKey;
    return this.http.get(url);
  }


  public UpdateInformedConsentMethod(primaryKey: any):
    Observable<ClientObjectGlobalResponse> {
    return this.UpdateInformedConsentService(primaryKey).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ClientObjectGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private UpdateCIServices(ClientInfo: any): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/SaveEncryptedClient';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });

    const requestCore: CoreRequest = {
      ProgId: "CLISITE",
      UserId: "1",
      AllowApplyCoupons: false,
      IDSavedCard: null,
      IsValid: false,
      Messages: null,
      ServerPath: '',
      WithErrors: false,
      Parameters: ''
    };

    requestCore.Parameters = JSON.stringify(ClientInfo);

    return this.http.post(url, requestCore, { headers });
  }




  public UpdateCIMethod(ClientInfo: any): Observable<ResponseInterface> {
    return this.UpdateCIServices(ClientInfo).pipe(
      switchMap((responseCore: any) => {
        const coreResponse = Object.assign(new CoreResponse(), responseCore);
        const code = coreResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = coreResponse.Parameters as string;
          const listResponse: any[] = JSON.parse(parametersResponse);

          return this.getMessages("UpdateClientInfoMethod-success", '1');
        } else {
          return this.getMessages(code, '1');
        }
      })
    );
  }
}
