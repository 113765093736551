<div class="modal fade" id="modalOk" #modalOk tabindex="-1" aria-labelledby="modalOkLabel" aria-hidden="true"
  data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pb-0 border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="accept()"></button>
      </div>
      <div class="modal-body text-center pt-0">
        <i class="bi bi-check-circle-fill icon-modal"></i>
        <h1 class="modal-title fs-5" id="modalOkLabel">{{ titulo }}</h1>
        <p>{{ mensaje }}</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="accept()">Aceptar</button>
      </div>
    </div>
  </div>
</div>
