import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
declare var window: any;

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})
export class ModalAlertComponent implements OnInit, OnChanges {

  @Input() showModal: boolean = false;
  @Input() titulo: string = '';
  @Input() mensaje: string = '';
  @Input() codError: string = '';
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;

  iconClass = 'bi bi-x-circle';

  ngOnInit(): void {
    this.modal = new window.bootstrap.Modal(
      document.getElementById('modalAlert')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.modal.show();
    }
  }

  accept() {
    this.modal.hide();
    this.showModalChange.emit(false);
  }
}
