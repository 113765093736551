import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy{
  urlBase= environment.urlBase;
  urlSite= environment.urlSite;
  private footerUpdateSubscription!: Subscription;

  @Input('backgroundColor') bgColor: string = '';
  @Input('fontColor') fColor: string = '';
  constructor(
    private router: Router,
    public loader: LoadingService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.footerUpdateSubscription = this.sharedService.footerUpdated$.subscribe(() => {
      this.activeItemIndex = 0;
    });
  }

  ngOnDestroy() {
    this.footerUpdateSubscription.unsubscribe();
  }

  redirectToIndex(index:any) {
    this.sharedService.updateHeader();
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
      this.router.navigate(['index']);  // Reemplaza '/' con la ruta de tu página de inicio
      window.scrollTo(0, 0);  // Esto desplazará la página hacia la parte superior
      this.loader.hideSpinner();
    }, 800);
  }

  redirectToLegalDocument(index:any) {
    this.sharedService.updateHeader();
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
    this.router.navigate(['informacion-legal']); // Reemplaza '/' con la ruta de tu página de inicio
     window.scrollTo(0, 0); // Esto desplazará la página hacia la parte superior
      this.loader.hideSpinner();
    }, 800);
  }

  redirectToFAQ(index:any) {
    this.sharedService.updateHeader();
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
    this.router.navigate(['preguntas-frecuentes']); // Reemplaza '/' con la ruta de tu página de inicio
    window.scrollTo(0, 0); // Esto desplazará la página hacia la parte superior parte superior
    this.loader.hideSpinner();
  }, 800);
  }

  redirectToPharmacies(index:any) {
    this.sharedService.updateHeader();
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
    this.router.navigate(['farmacias']); // Reemplaza '/' con la ruta de tu página de inicio
    window.scrollTo(0, 0); // Esto desplazará la página hacia la parte superior
      this.loader.hideSpinner();
    }, 800);
  }


  redirectToContactUs(index:any) {
    this.sharedService.updateHeader();
    this.loader.showSpinner();
    this.setActiveItem(index);
    setTimeout(() => {
      this.router.navigate(['contactanos']); // Reemplaza '/' con la ruta de tu página de inicio
      window.scrollTo(0, 0); // Esto desplazará la página hacia la parte superior ss
      this.loader.hideSpinner();
    }, 800);
  }

  activeItemIndex: number | null = null;
  setActiveItem(index: number): void {
    this.activeItemIndex = index;
  }
}
