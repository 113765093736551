import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { map, tap } from 'rxjs/operators';
import { LandingList,LandingDetails,LandingsListResponse,LandingDetailResponse,ImageRelated } from '../../models/sitefinity/landing.model';
import { of, throwError } from 'rxjs';
import { ResponseInterface } from 'src/app/models/responses/response.model';




const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})
export class MessagesService {


  private GetAllMessagesUrl = '/api/default/messages';
  private GetMessageByKey =  'api/default/messages?$filter=contains(code,'+'{{value}}'+')'
  //private GetMessageByKeyAndCategory =  'api/default/messages?$filter=cast(category,'+'Edm.String) eq' + ' {{category}} and contains(code,'+' {{value}}'+')'
  private GetMessageByKeyAndCategory = 'api/default/messages?$filter=cast(category,' + 'Edm.String) eq' + ' {{category}} and code eq ' + '{{value}}';

  constructor(private http: HttpClient) { }

  GetMessagesByKey(code: string,category: string):Observable<any> {

    try {
      // var baseUrl = this.GetMessageByKeyAndCategory;
      // var urlWithCode = baseUrl.replace("{{value}}", "'" + code + "'");
      // var urlWithCategory = urlWithCode.replace("{{category}}", "'" + category + "'");
      // var url = settings.SitefinityServicesUrl + urlWithCategory;

      const baseUrl = this.GetMessageByKeyAndCategory;
      const encodedCode = encodeURIComponent(code);
      const encodedCategory = encodeURIComponent(category);

      const url = `${settings.SitefinityServicesUrl}${baseUrl.replace("{{value}}", `'${encodedCode}'`).replace("{{category}}", `'${encodedCategory}'`)}`;
      return this.http.get<any>(url);
    } catch (error)
    {
      throw new Error('Error en el método GetMessagesByKey');
    }
  }


  public getMessagesMethod(code: string, category: string): Observable<ResponseInterface> {

    return new Observable<ResponseInterface>((observer) => {
         var messageCore: string[]= [];
        this.GetMessagesByKey(code,category).subscribe((data) => {
        var messageResponse = data.value[0];
            if (messageResponse == undefined) {


              messageCore.push(code);
              const responseServices: ResponseInterface = {
                  message : 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
                  alias : code + '-alias',
                  status : true,
                  code: code,
                  messageCore: messageCore,
                  buttonAcceptText:'',
                  buttonCancelText: '',
                  type:'',
                  action:'',
                  titleMessage: "falta agregar el código",
                  url:'',
                  Messages:[]
                 };
                 observer.next(responseServices);
                 observer.complete();

            }
            else
            {
              const responseServices: ResponseInterface = {
                  message : messageResponse.message,
                  alias : messageResponse.aliascode,
                  status : true,
                  code:messageResponse.code,
                  messageCore: messageCore,
                  buttonAcceptText:messageResponse.buttonAcceptText,
                  buttonCancelText: messageResponse.buttonCancelText,
                  type:messageResponse.type,
                  action:messageResponse.action,
                  titleMessage: messageResponse.titleMessage,
                  url: messageResponse.url,
                  Messages:[]
              }
              observer.next(responseServices);
              observer.complete();
            }
      },(error) =>{

        const responseServices: ResponseInterface = {
          message : 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
          alias : 'getMessages-error-alias-01',
          status : false,
          code:'getMessages-error-01',
          messageCore: messageCore,
          buttonAcceptText:'',
          buttonCancelText: '',
          type:'',
          action:'',
          titleMessage: "falta agregar el código",
          url:'',
          Messages:[]
      };
      observer.error(responseServices);
      observer.complete();

      });

  });


  }

}


