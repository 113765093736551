import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/core/clients.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { DatalakeService } from 'src/app/services/core/datalake.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { IModal } from 'src/app/models/modal.model';
import { DocumentsService } from 'src/app/services/sitefinity/documents.service';
import { LegalDocuments } from 'src/app/models/sitefinity/type-of-documents.model';
import { RecentSearchService } from 'src/app/services/shared/recent-search.service';
import { SharedService } from 'src/app/services/shared/shared.service';
declare var window: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  _documentService: DocumentsService;
  urlSite = environment.urlSite;
  settings: SitefinitySettings = new SitefinitySettings;
  form!: FormGroup;
  validationMsg = this.settings.validationMsgTypeMap;
  showPassword = false;
  isSubmited: boolean = false;
  disabled: boolean = false;
  modal: boolean = false;
  exampleModal: any;
  messageModal!: string;
  IdUser: string = "";
  sourceParam='';
  codeParam='';

  modalGeneral: IModal = {
    ModalName: 'ValidacionConsentimiento',
    Title:'',
    Text:'',
    BtnConfirmText: 'Aceptar',
    ShowCancelBtn: false,
  }
  modalError: IModal = {
    ModalName: 'ModalError',
    Title:'',
    Text:'',
    BtnConfirmText: 'Aceptar',
    ShowCancelBtn: false,
  }
  showModalGeneral:boolean = false;
  showModalError:boolean = false;
  showCI = false;
  modalCI: IModal = {
    ModalName: 'modalConsentimientoLogin',
    BtnConfirmText: 'Acepto consentimiento informado',
  };
  documentConsent: LegalDocuments = new LegalDocuments();
  documentTerms?: LegalDocuments = new LegalDocuments();
  documentPolicy?: LegalDocuments = new LegalDocuments();
  sessionUserClient?: any
  state: any;

 modalGeneral_login: IModal = {
  ModalName: 'modalGeneral_login',
  Title:'',
  Text:'',
  BtnConfirmText: '',
  ShowCancelBtn: false,
}
showmodalGeneral_login:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _clientsService: ClientsService,
    private _utilitiesService: UtilitiesServicesService,
    private _notificationService: NotificationService,
    private fb: FormBuilder,
    private loader: LoadingService,
    private Datalake: DatalakeService,
    private sitefinityPagesService: SitefinitypagesService,
    private documentService: DocumentsService,
    private _clientService: ClientsService,
    private router: Router,
    private _recentSearchService: RecentSearchService,
    private sharedService: SharedService
  ) {
    this.form = this.fb.group({
      LoginName: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(50), Validators.email]],
      Password: ['', [Validators.required, /*Validators.minLength(8), Validators.maxLength(30)*/]]
    });
    this._documentService = documentService;
  }

  get LoginName() { return this.form.get('LoginName'); }
  get Password() { return this.form.get('Password'); }

  ngOnInit(){
    this._utilitiesService.addMetaNoIndex();
    this.state = window.history.state;
    this.sourceParam = this.state.source;
    this.codeParam  = this.state.code;

    //checkeo de sesión
    if(this._utilitiesService.isUserLoggedIn()){
      this.router.navigateByUrl('/index')
    }

    this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'iniciosesion')!.ID);

    this.exampleModal = new window.bootstrap.Modal(
      document.getElementById('exampleModal')
    );
    this.GetLegalDocument();

    this.loader.hideSpinner();
  }

  ngOnDestroy(): void {
    this._utilitiesService.removeMetaNoIndex();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  signUp(formData: any) {

    this.loader.showSpinner();

    if (this.form.invalid) {
      this.modal = false;
      this.isSubmited = true;
      this.loader.hideSpinner();
    }
    else {
      if (formData.LoginName != '' && formData.Password != '') {
        this.disabled = true;
        this._clientsService.SignInMethod(formData).subscribe(res => {
          if (res.response.code === "SignInMethod-success") {

            this.insertDatalakeLoginEcommerce(res.sessionClient);
            if (res.sessionClient) {
              res.sessionClient.s = this._utilitiesService.encryptData(new Date().toISOString());
              this._utilitiesService.saveValueStorage(res.sessionClient, "sessionStorage");

              if (this.sourceParam != undefined && this.sourceParam != '') {
                let code = this.codeParam;
                this.router.navigate(['/planpaciente'], {state: { code}});
              }
              else {
                this.router.navigate(['/index']);
              }
              this._recentSearchService.UpdateRecentSearchs();
              this.sharedService.updateCart();
            }
          }
          else if (res.response.code === "digitalUser-SSO-password-success" || res.response.code === 'VALCLIRPC01') {
            this.resetForm();
            this.isSubmited = false;
           // this.exampleModal.show();
            this.loader.hideSpinner();
            if(res.response.buttonAcceptText != '')
              {
                this.showmodalGeneral_login = false;
                this.modalGeneral_login.Title = res.response.titleMessage;
                this.modalGeneral_login.Text =  res.response.message;
                this.modalGeneral_login.BtnConfirmText = res.response.buttonAcceptText;
                this.modalGeneral_login.UrlBtnConfirm = res.response.url;
                this.showmodalGeneral_login = true;

              }
              else
              {

                this._notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);

              }

          }
          else {

            this.loader.hideSpinner();
            this.disabled = false;

            if (res.response.code === "CLICARTVAL0001")
            {
              this.sessionUserClient = res.sessionClient;
              this.IdUser = res.Object.IDClient;
              this.modalGeneral.Title = res.response.titleMessage;
              this.modalGeneral.Text =  res.response.message;
              this.showModalGeneral = true;

            }
            else
            {

              this._notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
            }


            const email = document.getElementById('LoginName') as HTMLInputElement;
            const password = document.getElementById('Password') as HTMLInputElement;


            email.value = '';
            password.value = '';



           //
          }
        });
      }
    }
  }

  resetForm() {
    this.form = this.fb.group({
      LoginName: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(50), Validators.email]],
      Password: ['', [Validators.required]]
    });
  }

  async insertDatalakeLoginEcommerce(user: any)  {
    let userId = await this._utilitiesService.decryptData(user.i);
    let email = await this._utilitiesService.decryptData(user.e);

    const request: any =
    {
      UserID: parseInt(userId),
      Email: email,
      Source: "WEB",
      Status: "AC",
      CreateUserId: "1",
      CreateProgId: "LOGIN"
    }

    this.Datalake.InsertDatalakeLoginEcommerceMethod(request).subscribe(dl=>{});
  }

  closeModalCI(event: any, isButtonAccept?:boolean) {
    if (event == true) {
      this.showModalGeneral = false;
      this.showCI = true;
    }
    else {
      this.showModalGeneral = false;
    }
  }

  UpdateCI(event: boolean) {
    this.showCI = false;
    if(event){

      this.getClient(this.IdUser);
    }
    else
    {
      this.showModalGeneral = false;
      //cerrar
    }

  }

  private GetLegalDocument() {
    //Informed consent
    this._documentService
      .GetLegalDocumentsByIdType(
        0,
        1,
        this.settings.legalDocumentTypeMap['Consentimiento Informado']
      )
      .subscribe(
        (ResponseLegalDocuments) => {

          if (ResponseLegalDocuments.response != undefined) {
            this.documentConsent = ResponseLegalDocuments.legalDocuments[0];

            if(!this.documentConsent || this.documentConsent.documentText.trim() == ''){
              this._notificationService.SendNotificationByCode('consent-info-error','1')
            }
          } else {
            this._notificationService.SendNotificationByCode('consent-info-error','1')

          }
        },
        (error) => {
          this._notificationService.SendNotificationByCode('consent-info-error','1')
        }
      );

  }

  getClient(id:string) {
    this.loader.showSpinner();

    this._clientService.UpdateInformedConsentMethod(id).subscribe((res) => {
      if (res.responseObject) {
        if (this.sessionUserClient) {
          let urlRedirect = this.urlSite;
          //creacion del session storage
          this.sessionUserClient.s = this._utilitiesService.encryptData(new Date().toISOString());
          this._utilitiesService.saveValueStorage(this.sessionUserClient, "sessionStorage");
          //redireccion al index
          if (this.sourceParam != undefined && this.sourceParam != ''){
            urlRedirect += this.sourceParam;
            if (this.codeParam != undefined && this.codeParam != ''){
              urlRedirect += '?code=' + this.codeParam;
            }
          }
          this.sharedService.updateCart();
          window.location.href = urlRedirect;
        }
      }
      else {
        this._clientService.getMessages(res.response.code,"1").subscribe(o => {
          this.showModalGeneral = false;
          this.modalError.Title = o.titleMessage;
          this.modalError.Text =  o.message;
          this.showModalError = true;
        });
        this.loader.hideSpinner();
      }
    });
  }

  ModalErrorAction (event: any) {


      this.showModalError = false;



  }

  ModalGeneralLoginAction (event: any) {


    this.showModalError = false;



}

}
