



<div class="modal fade" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content modal-seguimiento" >
          <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <iframe width="100%" height="100%" [src]="iframeUrl"></iframe>

          </div>
          <div class="modal-footer justify-content-between">
              <div>

              </div>
              <div>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" *ngIf="this.modalData.ShowAcceptBtn || this.modalData.ShowAcceptBtn == undefined"
                (click)="Accept()">{{this.modalData.BtnConfirmText ? this.utilities.decodeData(this.modalData.BtnConfirmText) : 'Aceptar'}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
