import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';
import { ClientObjectGlobalResponse } from 'src/app/models/core/clients.model';
import { ObjectListGlobalResponse } from 'src/app/models/core/utilities.model';
import { CartRequest, CartAddress, SavedCard, Purchase, Cart }
  from 'src/app/models/core/cart.model';
import { IfStmt } from '@angular/compiler';
import { ObjectGlobalResponse } from 'src/app/models/core/utilities.model';

const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})

export class CartService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService,
    private ErrorOrLogsServices: UtilitiesServicesService) { }
//
  private getMessages(code: string, category: string): Observable<ResponseInterface> {

    return new Observable<Response>((observer) => {
      let messageCore: string[] = [];
      this.MessagesService_.GetMessagesByKey(code, category).subscribe((data) => {
        let messageResponse = data.value[0];

        if (messageResponse == undefined) {


          messageCore.push(code);
          const responseServices: ResponseInterface = {
            message: 'Lo sentimos, ha ocurrido un error inesperado en el sistema. Por favor, volvé a intentarlo o comunicate con nosotros para ayudarte: ' + code,
            alias: code + '-alias',
            status: true,
            code: code,
            messageCore: messageCore,
            buttonAcceptText:'',
            buttonCancelText: '',
            type:'',
            action:'',
            titleMessage: '',
            url: '',
            Messages: []
          };
          observer.next(responseServices);
          observer.complete();

        }
        else {
          const responseServices: ResponseInterface = {
            message: messageResponse.message,
            alias: messageResponse.aliascode,
            status: true,
            code: messageResponse.code,
            messageCore: messageCore,
            buttonAcceptText:messageResponse.buttonAcceptText,
            buttonCancelText: messageResponse.buttonCancelText,
            type:messageResponse.type,
            action:messageResponse.action,
            titleMessage: messageResponse.titleMessage,
            url: messageResponse.url,
            Messages: []
          }
          observer.next(responseServices);
          observer.complete();
        }
      }, (error) => {

        const responseServices: ResponseInterface = {
          message: 'Lo sentimos pero ha ocurrido un error inesperado obteniendo el mensaje.',
          alias: 'getMessages-error-alias-01',
          status: false,
          code: 'getMessages-error-01',
          messageCore: messageCore,
          buttonAcceptText:'',
          buttonCancelText: '',
          type:'',
          action:'',
          titleMessage: '',
          url: '',
          Messages: []
        };
        observer.error(responseServices);
        observer.complete();

      });

    });


  }

  private AddAddressToCartService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/AddAddressToCart';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public AddAddressToCartMethod(idCart: string, address: CartAddress):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart,
      Address: address
    };
    return this.AddAddressToCartService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          // const ecommerceResponse: CartAddress =
          //   this.SetClientAddressProfileProperties(responseObject);
          const ecommerceResponse: CartAddress = responseObject;

          return this.getMessages("OK0001-AddAdress-success", '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetClientActiveSavedCardsService(primaryKey: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/GetAllActiveSavedCardsByIDClient/'
      + primaryKey;
    return this.http.get(url);
  }


  public GetClientActiveSavedCardsMethod(primaryKey: string):
    Observable<ObjectListGlobalResponse> {
    return this.GetClientActiveSavedCardsService(primaryKey).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const listResponse: any = JSON.parse(parametersResponse);
          const ecommerceResponse: SavedCard[] = [];

          listResponse.forEach((DataItem: any) => {

            const object: SavedCard = {
              IDSavedCard: DataItem.IDSavedCard,
              CardNumber: DataItem.CardNumber,
              LastFour: DataItem.LastFour,
              Name: DataItem.Name,
              Status: DataItem.Status,
              IDCardType: DataItem.IDCardType,
              CreateDate: DataItem.CreateDate,
              UpdateDate: DataItem.UpdateDate,
            };

            ecommerceResponse.push(object);
          });



          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private InactivateSavedCardService(primaryKey: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/InactivateSavedCard/'
      + primaryKey;
    return this.http.get(url);
  }


  public InactivateSavedCardMethod(primaryKey: string):
    Observable<ObjectGlobalResponse> {
    return this.InactivateSavedCardService(primaryKey).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private SearchPurchaseByIDClientService(primaryKey: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/SearchPurchaseByIDClient/'
      + primaryKey;
    return this.http.get(url);
  }

  private SetPurchaseProperties(responseObject: any): Purchase {
    const ecommerceResponse: Purchase = {
      AddressCanton: responseObject.AddressCanton,
      AddressDistrict: responseObject.AddressDistrict,
      AddressProvince: responseObject.AddressProvince,
      CartObject: responseObject.CartObject,
      CheckedFlags: responseObject.CheckedFlags,
      ClientName: responseObject.ClientName,
      CreateDate: responseObject.CreateDate,
      Date: responseObject.Date,
      DeliveryAreaName: responseObject.DeliveryAreaName,
      DeliveryMethodToGo: responseObject.DeliveryMethodToGo,
      DeliveyMethod: responseObject.DeliveyMethod,
      Email: responseObject.Email,
      IDClient: responseObject.IDClient,
      IDDeliveryArea: responseObject.IDDeliveryArea,
      IDPaymentMethod: responseObject.IDPaymentMethod,
      IDPurchase: responseObject.IDPurchase,
      IDPurchaseStatus: responseObject.IDPurchaseStatus,
      IDShop: responseObject.IDShop,
      IDSourceApp: responseObject.IDSourceApp,
      Identification: responseObject.Identification,
      InvoiceNumber: responseObject.InvoiceNumber,
      IsExpress: responseObject.IsExpress,
      PaymentMethod: responseObject.PaymentMethod,
      PaymentProcesed: responseObject.PaymentProcesed,
      SecondPurchaseIDStatus: responseObject.SecondPurchaseIDStatus,
      SecondStatusName: responseObject.SecondStatusName,
      SendedByEmail: responseObject.SendedByEmail,
      SendedToMerchant: responseObject.SendedToMerchant,
      ShopName: responseObject.ShopName,
      StatusName: responseObject.StatusName,
      Time: responseObject.Time
    };

    return ecommerceResponse;

  }


  public SearchPurchaseByIDClientMethod(primaryKey: string):
    Observable<ObjectListGlobalResponse> {
    return this.SearchPurchaseByIDClientService(primaryKey).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const listResponse: any = JSON.parse(parametersResponse);
          const ecommerceResponse: Purchase[] = [];

          listResponse.forEach((DataItem: any) => {

            const object: Purchase = this.SetPurchaseProperties(DataItem);

            ecommerceResponse.push(object);
          });



          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private AddOrDeleteCuponToCartWithCertificateService(idCart: string, cupon: string,
    action: string, idProduct: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/AddOrDeleteCuponToCartWithCertificate/'
      + idCart + "/" + encodeURIComponent(cupon) + "/" + action + "/" + idProduct;
    return this.http.get(url);
  }


  public AddOrDeleteCuponToCartWithCertificateMethod(idCart: string, cupon: string,
    action: string, idProduct: string):
    Observable<ObjectGlobalResponse> {
    return this.AddOrDeleteCuponToCartWithCertificateService(idCart, cupon, action, idProduct).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001" ) {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);
          code = ecommerceResponse.CODE;
          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

private AddOrDeleteCuponToCartService(idCart: string, cupon: string,
    action: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'User.svc/AddOrDeleteCuponToCart/'
      + idCart + "/" + cupon + "/" + action;
    return this.http.get(url);
  }


  public AddOrDeleteCuponToCartMethod(idCart: string, cupon: string,
    action: string):
    Observable<ObjectGlobalResponse> {
    return this.AddOrDeleteCuponToCartService(idCart, cupon, action).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

private ValidateAddGiftCertificateCuponToCartService(idCart: string, cupon: string,
  sku: string, idProduct: string, idAttribute: string, quantity: string): Observable<any> {
    const url = settings.CoreServicesUrl
    + 'BombaCart.svc/ValidateAddGiftCertificateCuponToCart/'
      + idCart + "/" + encodeURIComponent(cupon) + "/" + sku+"/"+idProduct+"/"+idAttribute+"/"+quantity;
    return this.http.get(url);
  }


  public ValidateAddGiftCertificateCuponToCartMethod(idCart: string, cupon: string,
    sku: string, idProduct: string, idAttribute: string, quantity: string):
    Observable<ObjectGlobalResponse> {
    return this.ValidateAddGiftCertificateCuponToCartService(idCart, cupon, sku,idProduct,
      idAttribute,quantity).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);
          code = ecommerceResponse.CODE;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }



  private GetOrCreateCartService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/GetOrCreateCart';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public GetOrCreateCartMethod(idCart: string, idClient: number):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart,
      IdClient: idClient
    };
    return this.GetOrCreateCartService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetCartSummaryService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetCartSummary';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public GetCartSummaryMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };
    return this.GetCartSummaryService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        const parametersResponse: string = wcfResponse.Parameters as string;
        const responseObject: any = JSON.parse(parametersResponse);
        const ecommerceResponse: Cart = responseObject;

        if (code == undefined) {
          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;
        }

        if (code === "OK0001") {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              data.messageCore = wcfResponse.Messages;
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetRedemptionRulesService(sku: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetRedemptionRules/'+ sku;
    return this.http.get(url);
  }


  public GetRedemptionRulesMethod(sku: string):
    Observable<ObjectListGlobalResponse> {
    return this.GetRedemptionRulesService(sku).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetProductsSummaryService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetProductsSummary';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }

  public GetProductsSummaryMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };
    return this.GetProductsSummaryService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private ValidateProductByLinkService(argument: string)
    : Observable<any> {
    const url = settings.CoreServicesUrl + 'Product.svc/ValidateProductByLink/'
      + argument;
    return this.http.get(url);
  }


  private GetLightCartSummaryServices(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/GetLightCartSummary';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public GetLightCartSummaryMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };
    return this.GetLightCartSummaryServices(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);

        if (wcfResponse.Parameters) {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;
          return this.getMessages('OK0001', '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages('emptyCart-lightSummary', '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              let r = {
                request: requestWCF,
                response: wcfResponse
              };
              let rr = JSON.stringify(r);
              this.ErrorOrLogsServices.insertErrorObservable('GetCartLighSummary', 'CARTSUMMARY', rr, 'CARTSUMARRY').subscribe(t => {
              });
              return response;
            })
          );
        }
      })
    );
  }

  private UpdateCartInfoLightService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/UpdateCartInfoLight';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }

  public UpdateCartInfoLightMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };

    return this.UpdateCartInfoLightService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              let r = {
                request: requestWCF,
                response: wcfResponse
              };
              let rr = JSON.stringify(r);
              this.ErrorOrLogsServices.insertErrorObservable('UpdateCartLighSummary', 'CARTSUMMARY', rr, 'CARTSUMARRY').subscribe(t => {
              });
              return response;
            })
          );
        }
      })
    );
  }

  private ValidateCartLightService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/ValidateCartLight';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }

  public ValidateCartLightMethod(idCart: string):
    Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      IdCart: idCart
    };

    return this.ValidateCartLightService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private PreorderPowerTranzService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/PreorderPowerTranz';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public PreorderPowerTranzMethod(idSavedCard: string | null, idCart: string, idClient: number,
    isAMEX: boolean, isCardToSave: boolean, paymentExtraInfo: string, useFAC: boolean,
    useSavedCard: boolean):Observable<ObjectGlobalResponse> {

    const requestWCF: CartRequest = {
      GenerateElectronicInvoice: true,
      IDSavedCard: idSavedCard,
      IdCart: idCart,
      IdClient: idClient,
      IsAMEX: isAMEX,
      IsCardToSave: isCardToSave,
      PaymentExtraInfo: paymentExtraInfo,
      UseFAC: useFAC,
      UseSavedCard: useSavedCard
    };
    return this.PreorderPowerTranzService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

  private GetPowerTranzHTMLResponseByPurchaseIDService(idPurchase: string): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/GetPowerTranzHTMLResponseByPurchaseID/'+ idPurchase;
    return this.http.get(url);
  }


  public GetPowerTranzHTMLResponseByPurchaseIDMethod(idPurchase: string):
    Observable<ObjectListGlobalResponse> {
    return this.GetPowerTranzHTMLResponseByPurchaseIDService(idPurchase).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];

        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const ecommerceResponse: any = JSON.parse(parametersResponse);

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectListGlobalResponse = {
                response: data,
                responseObject: []
              };
              return response;
            })
          );
        }
      })
    );
  }

  private OrderConfirmPowerTranzService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'BombaCart.svc/OrderConfirmPowerTranz';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public OrderConfirmPowerTranzMethod(requestWCF: CartRequest):
    Observable<ObjectGlobalResponse> {
    return this.OrderConfirmPowerTranzService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true && wcfResponse.Parameters != null)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }

private AddPaymentMethodToCartService(request: CartRequest): Observable<any> {
    const url = settings.CoreServicesUrl + 'Cart.svc/AddPaymentMethodToCart';
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    return this.http.post(url, request, { headers });

  }


  public AddPaymentMethodToCartMethod(requestWCF: CartRequest):
    Observable<ObjectGlobalResponse> {
    return this.AddPaymentMethodToCartService(requestWCF).pipe(
      switchMap((coreResponse: any) => {
        const wcfResponse = Object.assign(new CoreResponse(), coreResponse);
        let code = wcfResponse.Messages[0];
        if (code == undefined) {

          code = (wcfResponse.IsValid === true)
            ? "OK0001" : code;

        }
        if (code === "OK0001") {
          const parametersResponse: string = wcfResponse.Parameters as string;
          const responseObject: any = JSON.parse(parametersResponse);

          const ecommerceResponse: Cart = responseObject;

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: ecommerceResponse
              };
              return response;
            })
          );
        } else {

          return this.getMessages(code, '1').pipe(
            map((data: any) => {
              const response: ObjectGlobalResponse = {
                response: data,
                responseObject: null
              };
              return response;
            })
          );
        }
      })
    );
  }
}
