import { Component, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-shopper',
  templateUrl: './shopper.component.html',
  styleUrls: ['./shopper.component.css']
})
export class ShopperComponent {

  constructor(private elRef: ElementRef) { }
  ngAfterViewInit() {
    const smIframeParentElem = this.elRef.nativeElement.querySelector("#salesmanago-Iframe_Shopper_NFF");
    const smIframeElem = document.createElement("iframe");

    smIframeElem.src = "https://cms.salesmanago.com/ms/l62qhqnrii87lxrl/default/Shopper_NFF.htm?1633370866824";
    smIframeElem.style.margin = "0";
    smIframeElem.style.padding = "0";
    smIframeElem.width = "1366";
    smIframeElem.height = "768";
    smIframeElem.style.overflowY = "hidden";
    smIframeElem.style.overflowX = "hidden";
    smIframeElem.style.maxWidth = "100%";
    smIframeElem.style.background = "#ffffff";
    smIframeElem.style.border = "none";

    smIframeParentElem.appendChild(smIframeElem);
  }


}
