import { IdType, searchType } from "./idType.interface";
import { environment } from "src/environments/environment";
export class SitefinitySettings {
  versionDevApp: string = '1.0.0.1'
  SitefinityServicesUrl: string = environment.SitefinityServicesUrl;
  SitefinityServicesUrlDoc: string = environment.SitefinityServicesDoc;
  CoreServicesUrl: string = environment.CoreServicesUrl;
  CoreServicesLealtadUrl: string = environment.CoreServicesLealtadUrl;
  ipify: string = 'https://api.ipify.org?format=json';

  BusDeServiciosUrlDev: string =  environment.BusDeServiciosUrlDev;
  BusDeServiciosUrlProd: string = "https://devbusbomba.loymarkservices.com/";




  protectedRoutes = [
    // this.CoreServicesUrl+'user.svc',
    this.CoreServicesUrl + 'cart.svc',
    this.CoreServicesUrl + 'BombaCart.svc/SaveEncryptedClient',
    this.CoreServicesUrl + 'User.svc/GetAllActiveSavedCardsByIDClient/',
    this.CoreServicesUrl + 'User.svc/InactivateSavedCard/',
    // this.CoreServicesUrl + 'User.svc/GetUserPersonalInfo/',
    this.CoreServicesUrl + 'User.svc/GetClientAddressProfile',
    this.CoreServicesUrl + 'User.svc/DeleteAddress',
    this.CoreServicesUrl + 'User.svc/UpdateInformedConsent/',
    this.CoreServicesUrl + 'user.svc/GetAllVisibleClientInteractionSubject',
    this.BusDeServiciosUrlDev + 'Recordatorios.svc',
    this.BusDeServiciosUrlProd + 'Recordatorios.svc',

  ];
  availableRoutes = [
    this.CoreServicesUrl + 'user.svc/getreducedclient',
    this.CoreServicesUrl + 'user.svc/vaidateuseraccount',
    this.CoreServicesUrl + 'user.svc/getpersondata',
    this.CoreServicesUrl + 'user.svc/UpdateInformedConsent'
  ];
  maxSessionTimeMs: number = 1 * 15 * 1000;
  Top: number = 20; // variable global para limitar cuantos valores deben venir en las listas..
  skip: number = 0; //Variable global para llamados de listas.
  phoneTypeMap: { [key: string]: number } = {
    '1': 1,
    '2': 1,
    '4': 1,
    '5': 1,
    '3': 2,
    '6': 2,
    '7': 2,
    '8': 2,
    '0': 2,
    '9': 2,
  };  // directorio para mapear numeros de telefono.

  //Mensajes de validaciones
  validationMsgTypeMap: { [key: string]: string } = {
    'required': 'Este campo es requerido.',
    'minlength': 'Este campo debe contener mínimo XXX caracteres.',
    'maxlength': 'Este campo debe contener máximo XXX caracteres.',
    'emailpattern': 'Este campo debe cumplir con el formato de email.',
    'passwordpattern': 'Debe contener al menos una letra mayúscula, una minúscula y un número. Sin espacios en blanco.',
    'juridicNamepattern': 'Este campo debe contener sólo letras, números y/o los siguientes caracteres especiales: - & . ( ) " “ \'',
    'numberpattern': 'Este campo debe contener sólo números',
    'basicAlphanumericpattern': 'Este campo debe contener sólo letras y/o números',
    'lettersSpanishpattern': 'Este campo debe contener sólo letras. Permite acentos',
    'passwordMatch': 'Las contraseñas no coinciden',
    'validId': 'La identificación ingresada no es válida',
  };

  //'nombreReferencia': ['N° identificador','Nombre a mostrar', 'N° minimo de caracteres','N° maximo de caracteres','Placeholder', 'N° de orden']
  idTypes: IdType[] = [
    { name: 'nacional', id: '1', displayName: 'Nacional', minLength: '9', maxLength: '9', placeholder: '9 dígitos, sin guiones', sortId: '1', idPatternName: 'number', nameMaxLength: '30', namePatternName: 'lettersSpanish' },
    { name: 'juridica', id: '2', displayName: 'Jurídica', minLength: '10', maxLength: '10', placeholder: '10 dígitos, sin guiones', sortId: '2', idPatternName: 'number', nameMaxLength: '45', namePatternName: 'juridicName' },
    { name: 'pasaporte', id: '3', displayName: 'Pasaporte', minLength: '3', maxLength: '45', placeholder: 'Digitá tu identificación', sortId: '3', idPatternName: 'basicAlphanumeric', nameMaxLength: '30', namePatternName: 'lettersSpanish' },
    { name: 'didi', id: '4', displayName: 'DIDI', minLength: '12', maxLength: '12', placeholder: '12 dígitos', sortId: '4', idPatternName: 'number', nameMaxLength: '30', namePatternName: 'lettersSpanish' },
    { name: 'dimex', id: '5', displayName: 'DIMEX', minLength: '11', maxLength: '12', placeholder: '11 ó 12 dígitos', sortId: '5', idPatternName: 'number', nameMaxLength: '30', namePatternName: 'lettersSpanish' },
    { name: 'nite', id: '6', displayName: 'NITE', minLength: '10', maxLength: '10', placeholder: '10 dígitos', sortId: '6', idPatternName: 'number', nameMaxLength: '30', namePatternName: 'lettersSpanish' },
  ]

  //'Tipos de búsquedas': ['N° identificador', 'Caracter identificador','Nombre']
  searchTypes: searchType[] = [
    { id : 1 , charValue: 'm', name: 'marcas' },
    { id : 2 , charValue: 'c', name: 'categorias' },
    { id : 3 , charValue: 't', name: 'tags' },
    { id : 4 , charValue: 'p', name: 'nombre producto' },
    { id : 5 , charValue: 'f', name: 'fonetica' },
    { id : 5 , charValue: 'v', name: 'productos vinculados' },
  ]

  regexPatternsTypeMap: { [key: string]: RegExp } = {
    'email': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'password': /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])\S{8,30}$/,
    'number': /^[0-9]+$/,
    'lettersSpanish': /^(?! *$)[a-zA-Z áÁéÉíÍóÓúÚñÑàÀèÈäÄëËïÏöÖüÜÄËÏÖÚ]+$/,
    'alphanumericMinus': /^[a-zA-Z0-9\-]*$/,
    'basicAlphanumeric': /^(?! *$)[a-zA-Z0-9\-]*$/,
    'juridicName': /^(?! *$)[a-zA-Z0-9áÁéÉíÍóÓúÚñÑàÀèÈäÄëËïÏöÖüÜ&.\(\)"“' -]+$/,
  };

  legalDocumentTypeMap: { [key: string]: string } = {
    "Consentimiento Informado": "4ff4489e-08e8-49f6-8c60-ed7e09831722",
    "Promociones": "a25c8d17-c5e4-444e-8a6b-551ecf9f3557",
    "Reglamentos La Bomba": "3edbe89f-09f2-470e-9ad7-dd4ecc1d96a7",
    "Políticas de privacidad": "29ca6921-6498-4228-a581-2d0cad92ce43",
    "Términos y condiciones": "9e433041-9b8b-4396-a3d4-85de09675e28",
  };

  PromoOfProducts: number = 46 // ID para promociones u ofertas de Productos.
  topProducts: number = 1200   // Top para la lista de productos.

  // Configs de Google Maps en Farmacias
  lat: number = 0;
  lng: number = 0;
  latDefault: number = 9.93132;
  lngDefault: number = -84.08107;
  zoom: number = 8;
  zoomCanton: number = 10;
  zoomFarmacia: number = 15;
  iconMaker: string = '/assets/img/pin.png'; // https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless.png

  cardMonths: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  currentDay = new Date().getDate();
  cardYear: number[] = Array.from({ length: 10 }, (_, i) => this.currentYear + i);

  monthTypeMap: { [key: string]: string } = {
    "1": "Enero",
    "2": "Febrero",
    "3": "Marzo",
    "4": "Abril",
    "5": "Mayo",
    "6": "Junio",
    "7": "Julio",
    "8": "Agosto",
    "9": "Septiembre",
    "10": "Octubre",
    "11": "Noviembre",
    "12": "Diciembre",
  };

   daysTypeMap: { [key: string]: number[] } = {
    "1": Array.from({ length: 31 }, (_, i) => i + 1),
    "2": Array.from({ length: this.esBisiesto(this.currentYear) ? 29 : 28 }, (_, i) => i + 1),
    "3": Array.from({ length: 31 }, (_, i) => i + 1),
    "4": Array.from({ length: 30 }, (_, i) => i + 1),
    "5": Array.from({ length: 31 }, (_, i) => i + 1),
    "6": Array.from({ length: 30 }, (_, i) => i + 1),
    "7": Array.from({ length: 31 }, (_, i) => i + 1),
    "8": Array.from({ length: 31 }, (_, i) => i + 1),
    "9": Array.from({ length: 30 }, (_, i) => i + 1),
    "10": Array.from({ length: 31 }, (_, i) => i + 1),
    "11": Array.from({ length: 30 }, (_, i) => i + 1),
    "12": Array.from({ length: 31 }, (_, i) => i + 1),
  };

  esBisiesto(anio: number): boolean {
    return (anio % 4 === 0 && anio % 100 !== 0) || anio % 400 === 0;
  }
//paginas de staging
  // pages: Array<IPages> = [
  //   { ID: '68b0be5c-7cbf-4ae2-bb1d-14c84d690583', Title: 'index' },
  //   { ID: 'eb9072c8-ea9b-4522-8db0-1846e2113d52', Title: 'mi-cuenta' },
  //   { ID: '07d069ca-5812-45ba-b68b-5e9fa62e684e', Title: 'preguntas-frecuentes' },
  //   { ID: 'a824b85e-4f69-4e85-bc9f-8e24cdf19507', Title: 'marcas-destacadas' },
  //   { ID: 'f6eea962-b857-4d08-93ea-c3a2c9d7761f', Title: 'blogs' },
  //   { ID: '1eefc7dd-329d-4753-b0be-ae3595b41ab5', Title: 'inicio-sesion' },
  //   { ID: 'afe23810-e48e-45b0-8f31-b7bfa4af20a5', Title: 'recuperar-contrasena' },
  //   { ID: 'b4accbb9-c81b-4c28-8b33-b7ffecb25821', Title: 'registro' },
  //   { ID: 'e075b60f-32a8-44eb-8940-dc37434254ed', Title: 'Informacion-legal' },
  //   { ID: '71e170c0-7607-43bf-ac26-e98a07fbe1d1', Title: 'Farmacias' },
  //   { ID: '9d53d2c3-c295-4c56-b692-f372452af1b5', Title: 'cambio-contrasena' },
  //   { ID: '7f9a8dd1-5da5-489e-be75-fbd2ac31e496', Title: 'shopper' },
  //   { ID: '5ead6205-3a3b-4e8a-80b0-850bb1380eac', Title: 'contactanos' },
  //   { ID: 'd99100a6-9ac4-4c25-aafa-cb9aff7e3b47', Title: 'categorias'},
  //   { ID: '3b629c09-b14f-4f89-9848-90ea57f36ccd', Title: 'detalle-producto'},
  //   { ID: 'e4231d70-bcfb-411d-b3a4-5f94b9633e22', Title: 'app'},
  //   { ID: 'ff8f028f-e352-45e9-b603-379b9516ff11', Title: 'plan-frecuente'},
  //   { ID: 'bc4f56ad-2ea1-4c80-861c-c3409199d6ad', Title: 'busqueda'},
  //   { ID: 'd025f6ad-aa64-4424-9d3f-31c4bc4ef053', Title: 'proceso-compra'}
  // ];
//paginas de desarrollo v2
  pages: Array<IPages> = [
    { ID: 'df0f9be7-ba66-4ed0-83df-5a5590e4af51', Title: 'index' },
    { ID: 'd9ffb898-a97e-4a20-9a4a-c9876e621f98', Title: 'micuenta' },
    { ID: '1aefffec-eb33-4f1a-a733-967e189db13c', Title: 'preguntas-frecuentes' },
    { ID: '8fb14213-58af-4ef5-a233-7e1bd1289df7', Title: 'marcas-destacadas' },
    { ID: '25887afa-29ad-4c37-8358-0f417049469a', Title: 'blogs' },
    { ID: '5009a989-e7b0-4f78-9a7c-7ed32d00a5b7', Title: 'iniciosesion' },
    { ID: 'aaf45ebe-6848-419d-be5f-11b2fa57fe8b', Title: 'recuperar-contrasena' },
    { ID: '07a1cdfc-2915-4990-bfc5-7b57366bcb0b', Title: 'registro' },
    { ID: 'a3391f2e-14e9-4efb-9774-05680f932dc1', Title: 'Informacion-legal' },
    { ID: 'eab8a91d-a6d9-4a2f-ab65-b8f52c8096c1', Title: 'Farmacias' },
    { ID: '356d2cf6-7af8-4e38-b424-7fcd87ed6458', Title: 'cambiocontrasena' },
    { ID: '13e33dd8-2392-40e6-89fc-966889706d63', Title: 'shopper' },
    { ID: '8b85cd9e-1f32-4165-98c9-12bbbed8eaa6', Title: 'contactanos' },
    { ID: '20539463-a570-4bb1-b6c2-8682dc278485', Title: 'categorias'},
    { ID: '62a4ae58-f929-4d7b-b0a2-3f7e34714a83', Title: 'detalle-producto'},
    { ID: 'caa11c74-33d0-4c4b-b528-b7ee30c11e09', Title: 'app'},
    { ID: 'eb280e4f-e78e-42a0-b515-db88ee25eb46', Title: 'planpaciente'},
    { ID: '367ecba7-1748-4bdb-a725-d1f7017ce31c', Title: 'busqueda'},
    { ID: '5ebddd84-22f2-4c37-8602-e5690984939a', Title: 'proceso-compra'},
    { ID: 'e56821d7-015a-4333-8df0-726dfb44008a', Title: 'aseguradoras'}
  ];

  // Informacion Legal
  informacionLegal: IConfigPaginate = {
    top: 8,
    skip: 0
  };

  // Contactanos
  mensajesContactanos = {
    TituloSucces: 'Tu mensaje ha sido enviado',
    MensajeSucces: 'Gracias por contactarnos. Tu mensaje ha sido enviado y pronto estaremos en contacto.'
  };
  zoomProvinciaModal: number = 8;
  zoomCantonModal: number = 10;
  zoomDistritoModal: number = 15;
  maxFileSize = 10 * 1024 * 1024;
  tipoAlertaValidaciones = 'warning';
  tituloAlertaValidaciones = 'Contactanos';
  mensajeExtensionArchivos = 'Sólo se admiten archivos con extensión .jpg, .jpeg, .png y .pdf';
  mensajeMaximoTamanoArchivo = 'Sólo se admiten archivos de máximo 10 MB';
  mensajeSumaTamanoArchivos = 'La suma del tamaño de todos los archivos debe ser inferior a 10 MB';
  mensajeTotalArchivos = 'El total de archivos a subir debe ser igual o inferior a 10 archivos';
  mensajeArchivoExistente = 'Ya existe el archivo subido';

  urlGoogleMaps = 'https://maps.googleapis.com/maps/api/';
  googleMapsApiKey = 'AIzaSyB4lrUmn6ylma1rPJy4my8TrruTd6RAlP0';
}

export interface IConfigPaginate {
  top: number,
  skip: number
}
export interface ImageRelated {
  id: string,
  title: string,
  mediaUrl: string,
  alternativeText: string
}

export interface IPages {
  ID: string,
  Title: string
}
