<!-- Modal agregar dirección-->
<form id="miFormulario" [formGroup]="form" (ngSubmit)="addAddress()">
  <div id="modalDirection" class="modal fade modal-overlay-light" tabindex="-1" aria-labelledby="modalScrollableLabel" aria-hidden="true"
    data-bs-keyboard="false" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content modal-style">
          <div class="modal-header">
            <h2 class="modal-title fs-5 color-primary" id="modalDirectionLabel">Agregar nueva dirección</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <app-google-maps-autocomplete [resetValue]="this.resetValue"
                  (setAddress)="getEstablishmentAddress($event)" [adressType]="'address'">
                </app-google-maps-autocomplete>
              </div>

              <div class="col-12 mb-3">
                <div>
                  <google-map #googleMap id="googleMap" [options]="mapOptions" [height]="200">
                    <map-marker #marker="mapMarker" [options]="markerOptions" [position]="maker" [clickable]="true"
                      (mapDragend)="makerDragend($event)">
                    </map-marker>
                    <map-info-window></map-info-window>
                  </google-map>
                </div>
              </div>
              <!-- Mensaje de alerta -->
              <div class="mt-2 mb-3 text-alert" *ngIf="error">
                <p class="mb-0">
                  <i class="bi bi-info-circle-fill pe-1"></i>
                  {{ this.utilities.decodeData(messageError) }}
                </p>
              </div>

              <div class="col-12 mb-3">
                <label for="" class="">Nombre para identificar tu ubicación</label>
                <input type="text" class="form-control contact-input" id="direction" formControlName="direction">
                <div *ngIf="direction!.invalid && ( direction!.touched || isSubmited )" class="d-block text-error">
                  <span *ngIf="direction!.errors?.['required']">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>
              <!-- <div class="col-12 mb-3">
                <label for="">Seleccioná una provincia</label>
                <select class="form-select" aria-label="Default select example" formControlName="province"
                  aria-placeholder="test" [(ngModel)]="selectedProvince" (ngModelChange)="onChangeProvince($event)">
                  <option *ngFor="let province of provinces" [ngValue]="province.IDAddressProvince">{{ province.Name }}
                  </option>
                </select>
                <div *ngIf="selectedProvince === 0 && ( province!.touched || isSubmited )" class="d-block text-error">
                  <span *ngIf=" province!.errors?.['required'] || selectedProvince === 0 ">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="">Seleccioná un cantón</label>
                <select class="form-select" aria-label="Default select example" formControlName="canton"
                  [(ngModel)]="selectedCanton" (ngModelChange)="onChangeCanton($event)">
                  <option *ngFor="let canton of cantons" [ngValue]="canton.IDAddressCanton">{{ canton.Name }}</option>
                </select>
                <div *ngIf="selectedCanton === 0 && ( canton!.touched || isSubmited )" class="d-block text-error">
                  <span *ngIf="canton!.errors?.['required'] || selectedCanton === 0">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>

              <div class="col-12 mb-3">
                <label for="">Seleccioná un distrito</label>
                <select class="form-select" aria-label="Default select example" formControlName="district"
                  [(ngModel)]="selectedDistrict">
                  <option *ngFor="let district of districts" [ngValue]="district.IDAddressDistrict">{{ district.Name}}
                  </option>
                </select>
                <div *ngIf="selectedDistrict === 0 && ( district!.touched || isSubmited )" class="d-block text-error">
                  <span *ngIf="district!.errors?.['required'] || selectedDistrict === 0">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div> -->

              <div class="col-12 mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Describí tu ubicación
                </label>
                <textarea name="" id="" cols="30" rows="3" class="form-control contact-input" id="detail"
                  formControlName="detail"></textarea>
                <div *ngIf="detail!.invalid && ( detail!.touched || isSubmited )" class="d-block text-error">
                  <span *ngIf="detail!.errors?.['required']">
                    {{ validationMsg['required'] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <p class="text-footer-modal" *ngIf="codeError">Codigo de error</p> -->
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
              (click)="close()">Cancelar</button>
            <button type="submit" class="btn btn-primary" aria-label="Close" #closeModal>Continuar</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-modal-general [showModal]="showModalWarningAddress" (showModalChange)="ModalErrorAction($event)" [modalData]="ModalWarningAddress"></app-modal-general>
