import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable,combineLatest,forkJoin } from 'rxjs';

import { of, throwError } from 'rxjs';


import {  catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { MessagesService } from '../sitefinity/messages.service';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import axios from 'axios';
import { UtilitiesServicesService } from '../shared/utilities-services.service';
import { CoreRequest } from 'src/app/models/requests/request.model';
import { CoreResponse, Response, ResponseInterface } from 'src/app/models/responses/response.model';

import { AdvertisingResponse  } from 'src/app/models/sitefinity/advertising.model';
import { UserFidelity  }
  from 'src/app/models/core/fidelity.model';
  const settings = new SitefinitySettings();



@Injectable({
  providedIn: 'root'
})
export class AdvertisingService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService) { }

  private AdvertisingUrl2 = settings.SitefinityServicesUrl + 'api/default/advertisings?$expand=Image';
  private AdvertisingUrl = settings.SitefinityServicesUrl + 'api/default/advertisings';

  public getAdvertisingServices(): Observable<any>
  {

  // gt – greater than
  // ge – greater than or equal to
  // lt – less than
  // le – less than or equal to
  var url = this.AdvertisingUrl;
  var currentDate = new Date();
  var formattedDate = this.formatAsDesiredFormat(currentDate);
  var filter = '?$filter={date} ge initialDate and {date} lt finalDate&$orderby=priority asc'
  const modifiedFilter = filter.replace(/{date}/g, formattedDate);
  const finalUrl = url + modifiedFilter;


  return this.http.get(finalUrl);
  }
  public getAdvertisingImage(id: string, relatedFieldName: string): Observable<any> {

    var url = this.AdvertisingUrl;
    var Urldetail = url + '(' + id + ')/' + relatedFieldName;

    return this.http.get(Urldetail);

  }

  public getPrincipalAdvertisingMethod(): Observable<AdvertisingResponse> {
    var BannerResponse = this.getAdvertisingServices();


   // ResponseInterface
    return BannerResponse.pipe(
      switchMap(response => {

        const Odata = response.value;

        var Array: any[] = [];

        Odata.forEach((DataItem: any) => {

          if(DataItem.url == null || DataItem.url == undefined )
          {
            DataItem.url = '';
          }
          var responseImage = this.getAdvertisingImage(DataItem.Id,"Image")
          responseImage.subscribe(o=>{
            var Image: any[] = [];

            Image.push(o.value);
            DataItem.Image = Image;


            Array.push(DataItem);

          })


        });



        if(Array.length > 0 )
        {
          return this.handleCondition("advertising-success",Array);
        }
       else
        {
           return this.handleCondition("advertising-fail01",Array);

        }




      })
    );
  }


  private handleCondition(code:string,banner:any[]): Observable<AdvertisingResponse> {

    return new Observable<AdvertisingResponse>(observer => {

      this.MessagesService_.getMessagesMethod(code,'1').subscribe(message => {

        const result: AdvertisingResponse = {
          Banners:banner,
          response: message,

        };
        observer.next(result);
        observer.complete();
      })


    })



  }

  formatAsDesiredFormat(date: Date): string {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }




}
