<main class="main">
  <div class="container mt-3 mt-lg-5">
    <h1>Blog La Bomba</h1>
    <div class="row">
      <div class="col-12 col-lg-3">

        <div class="">
          <ul class="list-group d-none d-lg-block my-2">

            <li class="list-group-item" *ngFor="let item of this.tags; let i=index">
              <input class="form-check-input me-1" type="checkbox" [value]="item.id" (change)="handleSelected($event)"
                [checked]="this.filterBlogsId.includes(item.id)">
              <label class="form-check-label blog-categories" [for]="i">{{ item.title }}</label>
            </li>

          </ul>
        </div>

        <div class="accordion d-lg-none" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button colored-accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Categorías
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class="list-group blog-accordion">

                  <li class="list-group-item blog-accordion blog-accordion" *ngFor="let item of this.tags; let i=index">
                    <input class="form-check-input me-1" type="checkbox" [value]="item.id" [id]="i"
                      (change)="handleSelected($event)" [checked]="this.filterBlogsId.includes(item.id)">
                    <label class="form-check-label blog-categories" [for]="i">{{ item.title }}</label>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-12 col-lg-9">
        <!-- Mostrar cuando no hay datos -->
        <div *ngIf="this.blogs.length === 0 && showMessage"
          class="container container-input d-flex align-items-center justify-content-center my-5">
          <div class="text-center">
            <i class="bi bi-blockquote-left icon-page"></i>
            <h2 class="">Aún no hay datos para mostrar</h2>
          </div>
        </div>

        <div class="row" *ngIf="this.blogs.length > 0">
          <div class="col-12 col-lg-6 my-2 cursor-pointer" *ngFor="let item of this.blogs; let i=index"
            [routerLink]="['/blogs', item.url]" (click)="loading()">
            <div class="card h-100 card-costum-shadow">
              <img
                [src]="item.image.length > 0 ? urlBaseSitefinity + item.image[0].Url : urlBase + '/assets/img/img-default.jpg'"
                class="card-img-top img-list-blog" [alt]="item.image.length > 0 ? item.image[0].alternativeText : ''">
              <div class="card-body">
                <p>{{ item.datePublished }}</p>
                <h4 class="card-title fw-medium title-blog">
                  {{ item.title }}
                </h4>
                <p class="card-text" [innerHTML]="item.detailDescription | ellipsis: 200"></p>
              </div>
              <div class="card-footer border-0 bg-white pb-3">
                <a [routerLink]="['/blogs', item.url]">Leer más</a>
              </div>
            </div>
          </div>

          <div *ngIf="this.totalDocument > this.settingsService.top && this.blogs.length !== this.totalDocument"
            class="d-flex align-items-center justify-content-center pt-3">
            <button class="btn btn-primary" (click)="loadMore()">Cargar más</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</main>
