<!-- <div class="modal fade modal-overlay-light" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="modalScrollableLabel"
  aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content modal-style">
      <div class="modal-header">
        <h2 class="modal-title fs-5 color-primary" id="modalScrollableLabel">{{ this.utilities.decodeData(this.modalData.Title) }}</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="Close()"></button>
      </div>
      <div class="modal-body">
          <p *ngIf=" this.modalData.Text && this.modalData.Text.trim() != ''">
          {{this.utilities.decodeData(this.modalData.Text)}}
        </p>


        <p [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.modalData.Text)">


        <small *ngIf="this.modalData.TextSmall && this.modalData.TextSmall.trim() != ''">
          {{this.utilities.decodeData(this.modalData.TextSmall)}}
        </small>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" *ngIf="this.modalData.ShowCancelBtn || this.modalData.ShowCancelBtn == undefined"
          (click)="Close()">{{this.modalData.BtnCancelText ? this.utilities.decodeData(this.modalData.BtnCancelText) : 'Cancelar'}}</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" *ngIf="this.modalData.ShowAcceptBtn || this.modalData.ShowAcceptBtn == undefined"
          (click)="Accept()">{{this.modalData.BtnConfirmText ? this.utilities.decodeData(this.modalData.BtnConfirmText) : 'Aceptar'}}</button>
      </div>
    </div>
  </div>
</div> -->



<div class="modal fade modal-overlay-light" [id]="this.modalData.ModalName"  tabindex="-1" aria-labelledby="modalScrollableLabel"
aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal-style">
          <div class="d-flex justify-content-between p-3">
            <h2 class="modal-title fs-5" id="modalScrollableLabel">{{ this.utilities.decodeData(this.modalData.Title) }}</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="Close()"></button>
          </div>
          <div class="modal-body">
            <p [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.modalData.Text)">


              <small *ngIf="this.modalData.TextSmall && this.modalData.TextSmall.trim() != ''">
                {{this.utilities.decodeData(this.modalData.TextSmall)}}
              </small>
          </div>
          <div class="justify-content-between p-3">

              <div class="text-end">
                <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal" *ngIf="this.modalData.ShowCancelBtn || this.modalData.ShowCancelBtn == undefined"
                (click)="Close()">{{this.modalData.BtnCancelText ? this.utilities.decodeData(this.modalData.BtnCancelText) : 'Cancelar'}}</button>


                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" *ngIf="this.modalData.ShowAcceptBtn || this.modalData.ShowAcceptBtn == undefined"
                (click)="Accept()">{{this.modalData.BtnConfirmText ? this.utilities.decodeData(this.modalData.BtnConfirmText) : 'Aceptar'}}</button>

              </div>
          </div>
      </div>
  </div>
</div>
