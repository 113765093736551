import { Component } from "@angular/core";
import { BaseComponent } from "../base.component";

@Component({
    template: "",
    selector: "app-error"
})
export class ErrorComponent extends BaseComponent<any> {

}
