import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, concatMap } from 'rxjs';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'
import { map, switchMap, tap } from 'rxjs/operators';
import { Blogs, ListBlogs, ListBlogsResponse, Tags, TagsResponse, BlogDetailSummary, BlogDetailSummaryResponse } from '../../models/sitefinity/blogs.model';
import { of, throwError } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ResponseInterface } from 'src/app/models/responses/response.model';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { ImageRelated } from '../../models/settings/sitefinity-settings.model';
import { MessagesService } from './messages.service';
import { catchError } from 'rxjs/operators';

const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient, private MessagesService_: MessagesService) { }


  private GetAllBlogs = settings.SitefinityServicesUrl + 'api/default/blogs1';
  private GetTagsOrClassification = settings.SitefinityServicesUrl + 'api/default/flat-taxa';

  private formatAsDesiredFormat(date: Date): string {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }


  private getBlogListService(top: number, skip: number): Observable<any> {
    var url = this.GetAllBlogs;

    // gt – greater than
    // ge – greater than or equal to
    // lt – less than
    // le – less than or equal to
    var currentDate = new Date();
    var formattedDate = this.formatAsDesiredFormat(currentDate);
    var filter = '?$filter=publishDate le {date} '
    const modifiedFilter = filter.replace(/{date}/g, formattedDate);

    var UrlSkipTop = `${url}${modifiedFilter}&$expand=thumbnailImage&$orderby=publishDate desc &$skip=${skip}&$top=${top}&$count=true`;
    return this.http.get(UrlSkipTop);
  }

  public getBlogListMethod(top: number, skip: number): Observable<ListBlogsResponse> {
    return new Observable<ListBlogsResponse>((observer) => {
      this.getBlogListService(top, skip).pipe(
        concatMap(blogList => {
          const Odata = blogList.value;
          const blogPropertiesArray: ListBlogs[] = [];

          Odata.forEach((DataItem: any) => {
            const publishDate = DataItem.publishDate;
            const parsedDate = parseISO(publishDate);
            const formattedDate = format(parsedDate, "'Publicado' eeee, d 'de' MMMM 'de' yyyy", { locale: es });
            const ImageThumbnaild: any[] = [];
            const publishDateString = format(parsedDate, "d 'de' MMMM 'de' yyyy", { locale: es });

            // DataItem.thumbnailImage.forEach((DataItemSitefinity: any) => {
            //   const image: ImageRelated = {
            //     id: DataItemSitefinity.Id,
            //     title: DataItemSitefinity.Title,
            //     mediaUrl: DataItemSitefinity.Url,
            //     alternativeText: DataItemSitefinity.AlternativeText
            //   }
            //   ImageThumbnaild.push(image);
            // }); // fin foreach//
           // ImageThumbnaild.push(DataItem.thumbnailImage);
            const blogProperties: ListBlogs = {
              id: DataItem.Id,
              title: DataItem.Title,
              url: DataItem.ItemDefaultUrl.replace('/', ''),
              formattedDate: formattedDate,
              date: parsedDate,
              datePublished: publishDateString,
              image: DataItem.thumbnailImage,
              detailDescription: DataItem.listDescription ?? '',
              urlContent: DataItem.ItemDefaultUrl

            }

            blogPropertiesArray.push(blogProperties);
          }); //fin del foreach

          if (blogPropertiesArray.length > 0) {

            let total = blogList['@odata.count'];

            return this.MessagesService_.getMessagesMethod('getbloglist-success', '3').pipe(
              concatMap(messages => {
                const responseMessages = messages;

                const listBlogsResponse: ListBlogsResponse = {
                  response: responseMessages,
                  blogs: blogPropertiesArray,
                  totalDocuments: total
                };

                return of(listBlogsResponse);
              })
            )

          }


          let total = blogList['@odata.count'];

          return this.MessagesService_.getMessagesMethod('getbloglist-fail01', '3').pipe(
            concatMap(messages => {
              const responseMessages = messages;

              const listBlogsResponse: ListBlogsResponse = {
                response: responseMessages,
                blogs: blogPropertiesArray,
                totalDocuments: total
              };

              return of(listBlogsResponse);
            })
          )
        })
      ).subscribe(listBlogsResponse => {
        observer.next(listBlogsResponse);
        observer.complete();
      });
    });
  }
  public getBlogListMethod2(top: number, skip: number): Observable<any> {
    return new Observable<ListBlogsResponse>((observer) => {
      this.getBlogListService(top, skip).subscribe(o => {
      })
    });
  }



  private getTagsOrClassificationsServices(): Observable<any> {
    var url = this.GetTagsOrClassification;
    return this.http.get(url);
  }

  public getTagsOrClassificationsMethod(): Observable<TagsResponse> {
    return new Observable<TagsResponse>((observer) => {

      this.getTagsOrClassificationsServices().pipe(
        concatMap(tags => {
          const Odata = tags.value;
          const tagArray: Tags[] = [];
          Odata.forEach((DataItem: any) => {


            const TagsProperties: Tags = {
              id: DataItem.Id,
              title: DataItem.Title,

            }
            tagArray.push(TagsProperties);
          }); //fin del foreach


          if (tagArray.length > 0) {

            return this.MessagesService_.getMessagesMethod('taglist-success', '3').pipe(
              concatMap(messages => {
                const responseMessages = messages;

                const TagResponse: TagsResponse = {
                  response: responseMessages,
                  tags: tagArray
                };

                return of(TagResponse);
              })
            )

          }


          return this.MessagesService_.getMessagesMethod('taglist-fail01', '3').pipe(
            concatMap(messages => {
              const responseMessages = messages;

              const TagResponse: TagsResponse = {
                response: responseMessages,
                tags: []
              };

              return of(TagResponse);
            })
          )



        })).subscribe(TagResponse => {
          observer.next(TagResponse);
          observer.complete();
        });



    });

  }



  public filterBlogsByTagsIdServices(idTgs: string[], top: number, skip: number): Observable<any> {
    const dynamicURL: string = this.buildDynamicURL('Tags', idTgs, top, skip);
    return this.http.get(dynamicURL);
  }



  public buildDynamicURL(taxonomy_name: string, taxonomyIds: string[], top: number, skip: number): string {
    const taxonomyFilter = `${taxonomyIds.map((id) => `${taxonomy_name}/any(s:s eq ${id})`).join(' or ')}`;
    var url = '';
    var url = this.GetAllBlogs;

    var currentDate = new Date();
    var formattedDate = this.formatAsDesiredFormat(currentDate);
    var filter = 'publishDate le {date}'
    const modifiedFilter = filter.replace(/{date}/g, formattedDate);

    return `${url}?$filter=${modifiedFilter} and ${taxonomyIds.length > 1 ? `(${taxonomyFilter})` : taxonomyFilter}&$orderby=publishDate desc &$skip=${skip}&$top=${top}&$expand=thumbnailImage&$count=true`;
  }

  public filterBlogsByTagsIdMethod(idTgs: string[], top: number, skip: number): Observable<ListBlogsResponse> {
    return new Observable<ListBlogsResponse>((observer) => {
      this.filterBlogsByTagsIdServices(idTgs, top, skip).pipe(
        concatMap(blogList => {
          const Odata = blogList.value;
          const blogPropertiesArray: ListBlogs[] = [];

          Odata.forEach((DataItem: any) => {
            const publishDate = DataItem.publishDate;
            const parsedDate = parseISO(publishDate);
            const formattedDate = format(parsedDate, "'Publicado' eeee, d 'de' MMMM 'de' yyyy", { locale: es });
            const publishDateString = format(parsedDate, "d 'de' MMMM 'de' yyyy", { locale: es });
            const ImageThumbnaild: any[] = [];

            // DataItem.thumbnailImage.forEach((DataItemSitefinity: any) => {
            //   const image: ImageRelated = {
            //     id: DataItemSitefinity.Id,
            //     title: DataItemSitefinity.Title,
            //     mediaUrl: DataItemSitefinity.Url,
            //     alternativeText: DataItemSitefinity.AlternativeText
            //   }
            //   ImageThumbnaild.push(image);
            // });
            //ImageThumbnaild.push(DataItem.thumbnailImage);
            const blogProperties: ListBlogs = {
              id: DataItem.Id,
              title: DataItem.Title,
              url: DataItem.ItemDefaultUrl,
              formattedDate: formattedDate,
              date: parsedDate,
              datePublished: publishDateString,
              image: DataItem.thumbnailImage,
              detailDescription: DataItem.listDescription ?? '',
              urlContent: DataItem.ItemDefaultUrl
            }

            blogPropertiesArray.push(blogProperties);
          }); //fin del foreach

          if (blogPropertiesArray.length > 0) {

            let total = blogList['@odata.count'];

            return this.MessagesService_.getMessagesMethod('getbloglist-success', '3').pipe(
              concatMap(messages => {
                const responseMessages = messages;

                const listBlogsResponse: ListBlogsResponse = {
                  response: responseMessages,
                  blogs: blogPropertiesArray,
                  totalDocuments: total
                };

                return of(listBlogsResponse);
              })
            )

          }

          let total = blogList['@odata.count'];

          return this.MessagesService_.getMessagesMethod('getbloglist-fail01', '3').pipe(
            concatMap(messages => {
              const responseMessages = messages;

              const listBlogsResponse: ListBlogsResponse = {
                response: responseMessages,
                blogs: blogPropertiesArray,
                totalDocuments: total
              };

              return of(listBlogsResponse);
            })
          )
        })
      ).subscribe(listBlogsResponse => {
        observer.next(listBlogsResponse);
        observer.complete();
      });
    });
  }



  private getBlogDetailByIdService(id: string): Observable<any> {

    var url = this.GetAllBlogs;
    var Urldetail = url + '(' + id + ')?$expand=thumbnailImage&Tags'
    return this.http.get(Urldetail);

  }

  private getImagesBlogDetailByIdService(id: string, relatedFieldName: string): Observable<any> {

    var url = this.GetAllBlogs;
    var Urldetail = url + '(' + id + ')/' + relatedFieldName;

    return this.http.get(Urldetail);

  }


  public getBlogDetailByIdMethod(id: string): Observable<BlogDetailSummaryResponse> {
    return new Observable<BlogDetailSummaryResponse>((observer) => {
      this.getBlogDetailByIdService(id).pipe(
        catchError(error => {
          const errorMessage = 'Error obteniendo el detalle del blog';
          const errorResponse: ResponseInterface = {
            code: 'Error',
            message: errorMessage,
            status: false,
            alias: 'getBlogDetailByIdService-error',
            messageCore: [],
            buttonAcceptText: '',
            buttonCancelText: '',
            type: '',
            action: '',
            titleMessage: "falta agregar el código",
            url: '',
            Messages: []
          };

          return this.MessagesService_.getMessagesMethod('getblogdetail-fail', '3').pipe(
            concatMap(response => {
              const responseMessages = response;

              const emptySummary: any = {
                blogDetail: null,
                // sectionFourImageOne: [],
                // sectionFourImageTwo: [],
                // sectionSixImage: [],
                //DetailImage: [],
                sectionTwoVideo: [],
                OpenGraphImage:[]
              };

              const errorSummaryResponse: any = {
                summary: emptySummary,
                response: responseMessages
              };

              return throwError(errorSummaryResponse);
            })
          );
        }),
        concatMap(blogDetailResponse => {
          const Odata = blogDetailResponse;
          const blogDetail: any = Odata;
          // let sectionFourImageOne: any[] = [];
          // let sectionFourImageTwo: any[] = [];
          // let sectionSixImage: any[] = [];
          //let DetailImage: any[] = [];
          //let sectionTwoVideo: any[] = [];
          let OpenGraphImage: any[] = [];

          // Obtener las imágenes correspondientes
          const observables = [
            // this.getImagesBlogDetailByIdService(id, 'sectionFourImageOne').pipe(
            //   concatMap(x => {
            //     sectionFourImageOne = x.value;
            //     return of(x);
            //   })
            // ),
            // this.getImagesBlogDetailByIdService(id, 'sectionFourImageTwo').pipe(
            //   concatMap(x => {
            //     sectionFourImageTwo = x.value;
            //     return of(x);
            //   })
            // ),
            // this.getImagesBlogDetailByIdService(id, 'sectionSixImage').pipe(
            //   concatMap(x => {
            //     sectionSixImage = x.value;
            //     return of(x);
            //   })
            // ),
            // this.getImagesBlogDetailByIdService(id, 'DetailImage').pipe(
            //   concatMap(x => {
            //     DetailImage = x.value;
            //     return of(x);
            //   })
            // ),
            // this.getImagesBlogDetailByIdService(id, 'sectionTwoVideo').pipe(
            //   concatMap(x => {
            //     sectionTwoVideo = x.value;
            //     return of(x);
            //   })
            // ),
            this.getImagesBlogDetailByIdService(id, 'OpenGraphImage').pipe(
              concatMap(x => {

                OpenGraphImage = x.value;
                return of(x);
              })
            ),
            //OpenGraphImage
          ];

          return forkJoin(observables).pipe(
            concatMap(() => {
              const blogDetailSummary: any = {
                blogDetail,
                // sectionFourImageOne,
                // sectionFourImageTwo,
                // sectionSixImage,
                // DetailImage,
                // sectionTwoVideo,
                OpenGraphImage
              };

              // Llamar al servicio getMessagesMethod
              return this.MessagesService_.getMessagesMethod('getblogdetail-success', '3').pipe(
                concatMap(response => {
                  const responseMessages = response;

                  const blogDetailSummaryResponse: BlogDetailSummaryResponse = {
                    summary: blogDetailSummary,
                    response: responseMessages
                  };

                  return of(blogDetailSummaryResponse);
                })
              );
            })
          );
        })
      ).subscribe(blogDetailSummaryResponse => {
        observer.next(blogDetailSummaryResponse);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }


  private GetBlogDetailByUrlContentServices(urlContentValue: string): Observable<any> {
    var url = this.GetAllBlogs;
    const filterBy = `?$filter=contains(ItemDefaultUrl,'{value}')&$top=1`;
    const finalFilter = filterBy.replace('{value}', urlContentValue);
    const finalUrl = url + finalFilter;

    return this.http.get(finalUrl);
  }


  public GetBlogIdByUrlContent(urlContentValue: string): Observable<any> {
    return this.GetBlogDetailByUrlContentServices(urlContentValue).pipe(
      switchMap(response => {
        const Odata = response.value;

        if (Odata.length > 0) {
          var id = Odata[0].Id;
          return of(id); // Crear un nuevo observable con el ID
        } else {
          return of(""); // Crear un nuevo observable con una cadena vacía
        }
      })
    );
  }



}


