<main class="main">
  <div class="container container-input mt-3 mt-lg-5">
    <h1 class="color-primary">
      Bienvenido a Farmacia La Bomba
    </h1>
    <p>Para ingresar a la plataforma de Farmacias La Bomba, creá una contraseña.</p>

    <form [formGroup]="form" (ngSubmit)="ChangePassword(form.value)">
      <div class="row">
        <div class="col-12 d-flex flex-column">
          <label for="passwordInput" class="form-label">Contraseña</label>
          <div class="input-group password-input mb-2">
            <input [type]="showPassword ? 'text' : 'password'" formControlName="password" id="passwordInput"
              class="form-control password-border" maxlength="30"
              [ngClass]="password!.invalid && (password!.touched || this.isSubmited )? 'is-invalid':''">
            <i class="bi" [ngClass]="showPassword ? 'bi-eye' : 'bi-eye-slash'" id="togglePassword"
              (click)="togglePasswordVisibility()"></i>
          </div>
          <div *ngIf="password!.invalid && ( password!.touched || this.isSubmited )" class="text-danger">
            <span *ngIf="password!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="password!.errors?.['minlength']">
              {{ validationMsg['minlength'].replace("XXX", password!.errors!.minlength.requiredLength )}}
            </span>
            <span *ngIf="password!.errors?.['maxlength']">
              {{ validationMsg['maxlength'].replace("XXX", password!.errors!.maxlength.requiredLength )}}
            </span>
            <span *ngIf="password!.errors?.['pattern']">
              {{ validationMsg['passwordpattern']}}
            </span>
          </div>
        </div>
        <div class="col-12 d-flex flex-column mt-3">
          <label for="confirmPasswordInput" class="form-label">Confirmar contraseña</label>
          <div class="input-group password-input mb-2">
            <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword"
              id="confirmPasswordInput" class="form-control password-border" maxlength="30"
              [ngClass]="confirmPassword!.invalid && (confirmPassword!.touched || this.isSubmited )? 'is-invalid':''">
            <i class="bi" [ngClass]="showConfirmPassword ? 'bi-eye' : 'bi-eye-slash'" id="toggleconfirmPassword"
              (click)="toggleConfirmPasswordVisibility()"></i>
          </div>
          <div *ngIf="confirmPassword!.invalid && ( confirmPassword!.touched || this.isSubmited )" class="text-danger">
            <span *ngIf="confirmPassword!.errors?.['required']">
              {{ validationMsg['required'] }}
            </span>
            <span *ngIf="confirmPassword!.errors?.['mustMatch']">
              {{ validationMsg['passwordMatch'] }}
            </span>
          </div>
        </div>
        <div class="col-12 my-3">
          <button [disabled]="!isLinkValid" class="btn btn-primary px-4" type="submit">Crear contraseña</button>
        </div>
      </div>
    </form>
  </div>


  <app-modal-general [showModal]="showModalGeneral" (showModalChange)="closeModalPassword($event)" [modalData]="modalGeneral"></app-modal-general>


</main>
