import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IModal } from 'src/app/models/modal.model';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { SharedService } from 'src/app/services/shared/shared.service';
declare var window: any;
@Component({
  selector: 'app-modal-recommended-product',
  templateUrl: './modal-recommended-product.component.html',
  styleUrls: ['./modal-recommended-product.component.css']
})
export class ModalRecommendedProductComponent implements  OnChanges {

  @Input() showModal: boolean = false;
  @Input() modalData: IModal = {
    ModalName: 'modalGeneral',
  };
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() products: any = [];
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;
  price: number[] = [];
  priceDiscount: number[] = [];
  priceWithTaxes: number[] = [];
  selectedUnits: { [key: number]: string } = {};
  selectedQuantity: { [key: number]: number } = {};
  selectedUnitPrice: number[] = [];
  selectedPromotion: string[] = [];
  quantityValue: number = 1;
  unitSelected: string = 'Unidad'
  displayQuantityComponent: boolean = false;
  quantityByComponent:any = 10
  event: boolean = false;
  
  constructor(
    private sharedService: SharedService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.adjustProducts();
      this.modal.show();
    }
  }

  Accept() {
    this.modal.hide();
    this.showModalChange.emit(this.event);
  }

  Close() {
    this.modal.hide();
    this.showModalChange.emit(this.event);
  }

  goToDetailProduct(url: string) {
    if( url != null) {
      window.open(`/detalle-producto/${url}`, '_blank');
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  addProductToCart(product: any, unitSelected: any, quantity: number, price: number) {
    if (product) {
      this.sharedService.addProductsToCartV2(product, unitSelected, quantity, price);
      this.event = true;
    }
  }

  selectQuantity(index: number, quantity: number): void {
    this.selectedQuantity[index] = quantity;
    this.quantityValue = quantity;
    this.selectType(index,this.selectedUnits[index]);
  }

  calculateTotalPrice(index: number): void {
    const product = this.products[index];
    const quantity = this.selectedQuantity[index];

    if (this.selectedUnits[index] === 'Unidad' || this.selectedUnits[index] === 'Unidades' && product.ProductAttributes && product.ProductAttributes.length > 0) {
      this.selectedUnitPrice[index] = product.ProductAttributes[0].PriceWithDiscount ? product.ProductAttributes[0].PriceWithDiscount * quantity : product.ProductAttributes[0].PriceWithTaxes * quantity;
    } 
    else if (this.selectedUnits[index] === 'Caja' || this.selectedUnits[index] === 'Cajas' && product.ProductAttributes) {
      if (product.ProductAttributes.length > 1)
        this.selectedUnitPrice[index] = product.ProductAttributes[1]?.PriceWithDiscount ? product.ProductAttributes[1]?.PriceWithDiscount * quantity : product.ProductAttributes[1]?.PriceWithTaxes * quantity;
      else
       this.selectedUnitPrice[index] = product.ProductAttributes[0]?.PriceWithDiscount ? product.ProductAttributes[0]?.PriceWithDiscount * quantity : product.ProductAttributes[0]?.PriceWithTaxes * quantity;
    }

    if(product.PercentDiscountRate > 0){
      if(product.PriceWithDiscount > 0){
        this.selectedPromotion[index] = product.PercentDiscountRate + "%"
      }
      else{
        if(product.ProductAttributes.length>0){
          let currentAttribute = product.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if (this.selectedUnits[index].startsWith('Unidad')) {
            if(currentAttribute.PriceWithDiscount > 0){
              this.selectedPromotion[index] = product.PercentDiscountRate + "%"
            }
            else{
              if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                this.selectedPromotion[index] = currentAttribute.Dynamic;
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
          }
          else{
            if (this.selectedUnits[index].startsWith('Caja')) {
              if(currentAttribute.PriceWithDiscount > 0){
                this.selectedPromotion[index] = product.PercentDiscountRate + "%"
              }
              else{
                if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else{
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else{
              this.selectedPromotion[index] = "";
            }
          }
        }
        else{
          if(product.Dynamic && product.Dynamic != ""){
            this.selectedPromotion[index] = product.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
      }
    }
    else{
      if(product.ProductAttributes.length>0){
        let currentAttribute = product.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
        if(currentAttribute.Dynamic || currentAttribute.Dynamic != ""){
          this.selectedPromotion[index] = currentAttribute.Dynamic;
        }
        else{
          this.selectedPromotion[index] = "";
        }
      }
      else{
        if(product.Dynamic || product.Dynamic != ""){
          this.selectedPromotion[index] = product.Dynamic;
        }
        else{
          this.selectedPromotion[index] = "";
        }
      }
    }
  }

  getPluralizedName(itemName: string, quantity: number): string {
    if (itemName.toLowerCase() === 'unidad') {
      return quantity > 1 ? 'Unidades' : 'Unidad';
    } 
    else if (itemName.toLowerCase() === 'caja') {
      return quantity > 1 ? 'Cajas' : 'Caja';
    } 
    else {
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return itemName;
    }
  }

  openQuantityComponent(index:any,value:any)  {
    this.products[index].quantityByComponent =  this.validateQuantityZero(this.products[index].quantityByComponent)

    if(value == false) {
      this.selectedQuantity[index] = this.products[index].quantityByComponent;
      this.selectType(index,this.selectedUnits[index]);
      }
      this.products[index].displayQuantityComponent = value;
    }

  selectType(index:any,itemName: string ): string {

    if (itemName.toLowerCase() === 'unidad' ||  itemName.toLowerCase() === 'unidades' ) {
      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Unidades' : 'Unidad';
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index]
    } 
    else if (itemName.toLowerCase() === 'caja' ||  itemName.toLowerCase() === 'cajas') {
      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Cajas' : 'Caja';
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index];
    } 
    else {
      this.selectedUnits = itemName;
      this.calculateTotalPrice(index);
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return  this.selectedUnits[index]
    }
  }

  adjustProducts() {
    this.products.forEach((p: any, index: number) => {

      p.displayQuantityComponent = false;
      p.quantityByComponent = 10;
      p.numbers = [];

      if(p.MaxToPurchase > 10) {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);
        }
      }

      this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
      this.selectedQuantity[index] = p.MinToPurchase;
      if (this.selectedQuantity[index] > 1) {
        if (this.selectedUnits[index] === 'Unidad')
          this.selectedUnits[index] = 'Unidades';
        else 
          this.selectedUnits[index] = 'Cajas';
      }
      if (p.ProductAttributes && p.ProductAttributes.length > 0) {
        if (this.selectedUnits[index] === 'Unidad' || this.selectedUnits[index] === 'Unidades') {
          this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[0].PriceWithTaxes;
          this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
        } 
        else if (this.selectedUnits[index] === 'Caja' || this.selectedUnits[index] === 'Cajas') {
          if (p.ProductAttributes[0].PriceWithDiscount > 0 || p.ProductAttributes[1]?.PriceWithDiscount > 0) {
            this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithDiscount ? p.ProductAttributes[0].PriceWithDiscount : p.ProductAttributes[1]?.PriceWithDiscount;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
          } 
          else {
            this.selectedUnitPrice[index] = p.ProductAttributes[0].PriceWithTaxes ? p.ProductAttributes[0].PriceWithTaxes : p.ProductAttributes[1]?.PriceWithTaxes;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase;
          }
        }
      } 
      else {
        this.selectedUnitPrice[index] = p.PriceWithTaxes * p.MinToPurchase; // Establece un valor predeterminado
      }

      if(p.PercentDiscountRate > 0){
        if(p.PriceWithDiscount > 0){
          this.selectedPromotion[index] = p.PercentDiscountRate + "%"
        }
        else{
          if(p.ProductAttributes.length>0){
            let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
            if (this.selectedUnits[index].startsWith('Unidad')) {
              if(currentAttribute.PriceWithDiscount > 0){
                this.selectedPromotion[index] = p.PercentDiscountRate + "%"
              }
              else{
                if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else{
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else{
              if (this.selectedUnits[index].startsWith('Caja')) {
                if(currentAttribute.PriceWithDiscount > 0){
                  this.selectedPromotion[index] = p.PercentDiscountRate + "%"
                }
                else{
                  if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                    this.selectedPromotion[index] = currentAttribute.Dynamic;
                  }
                  else{
                    this.selectedPromotion[index] = "";
                  }
                }
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
          }
          else{
            if(p.Dynamic && p.Dynamic != ""){
              this.selectedPromotion[index] = p.Dynamic;
            }
            else{
              this.selectedPromotion[index] = "";
            }
          }
        }
      }
      else{
        if(p.ProductAttributes.length>0){
          let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if(currentAttribute.Dynamic || currentAttribute.Dynamic != ""){
            this.selectedPromotion[index] = currentAttribute.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
        else{
          if(p.Dynamic || p.Dynamic != ""){
            this.selectedPromotion[index] = p.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
      }
    });
  }

  validateNumber(event: any): void {
    const inputChar =  event.data;
    // Si es 'e', 'E', '+' o cualquier carácter no numérico, no permitas la entrada
    if ((inputChar === 'e' || inputChar === 'E' || inputChar === '+' || isNaN(parseInt(inputChar, 10))) && !event.ctrlKey) {
      // Prevent default para evitar que el valor sea introducido
      event.preventDefault();
    }
  }

  validateQuantityZero( quantity:any)
  {
    if(quantity <= 0) {
      return 1;
    }
    if(quantity > 50) {
      return 50;
    }

    return quantity;
  }
}