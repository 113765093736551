<main class="main">
  <div class="container container-input mt-3 mt-lg-5">
    <h1 class="color-primary">
      ¿Olvidaste tu contraseña?
    </h1>
    <small>Para recuperar tu contraseña, ingresá la información solicitada y te enviaremos una contraseña temporal por correo electrónico.</small>

    <form [formGroup]="form" (ngSubmit)="RecoverPassword(form.value)">
      <div class="row">
        <div class="col-12 d-flex flex-column mt-3">
          <label for="email" class="form-label">Correo electrónico</label>
          <input type="email" class="form-control login-input" id="UserEmail" formControlName="UserEmail"
          [ngClass]="UserEmail!.invalid && UserEmail!.touched ? 'is-invalid' : ''" maxlength="51">
          <div *ngIf="UserEmail!.invalid && (UserEmail!.dirty || UserEmail!.touched || this.isSubmited)" class="text-danger">
              <span *ngIf="UserEmail!.errors?.required">{{validationMsg['required']}}</span>
              <span *ngIf="UserEmail!.errors?.['minlength']">
                  {{ validationMsg['minlength'].replace("XXX", UserEmail!.errors!.minlength.requiredLength )}}
              </span>
              <span *ngIf="UserEmail!.errors?.['maxlength']">
                  {{ validationMsg['maxlength'].replace("XXX", UserEmail!.errors!.maxlength.requiredLength )}}
              </span>
              <span *ngIf="UserEmail!.errors?.email">{{ validationMsg['emailpattern']}}</span>
          </div>
        </div>
        <div class="col-12 my-3">
          <button class="btn btn-primary px-4" type="submit">Restablecer contraseña</button>
          <small class="my-2 d-block">Si ya tenés tu contraseña, dirigite a  <a routerLink="/iniciosesion">Iniciar sesión</a></small>
        </div>
      </div>
    </form>

  </div>

  <app-modal-general [showModal]="showModalGeneralRecoveryPassword" (showModalChange)="closeModalPassword($event)" [modalData]="modalGeneralRecoveryPassword"></app-modal-general>

</main>




