<!-- Modal delete-->
<div class="modal fade" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pb-0 border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body text-center pt-0">
        <i class="bi  bi-exclamation-circle icon-modal text-order"></i>
        <h2 class="text-order">¿Estás seguro que deseas vaciar tu carrito?</h2>
        <p>Recordá que no podrás volver atrás para recuperar estos productos.</p>
      </div>
      <div class="modal-footer">
        <!-- <p *ngIf="error" class="text-footer-modal">Codigo de error</p> -->
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" (click)="close()">Cancelar</button>
        <button type="button" class="btn btn-primary" aria-label="Close" #modalDelete (click)="clearCart()" data-bs-toggle="#modalDelete">Vaciar carrito</button>
      </div>
    </div>
  </div>
</div>
