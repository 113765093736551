<main class="main">

  <div class="container container-section mt-3 mt-lg-5">
    <h1 class="color-primary">Farmacias</h1>
    <h3 class="mb-3">Call center <a href="tel:800-522-6622" type="tel"> 800-522-6622</a></h3>

    <div class="d-lg-none">
      <p class="d-inline-flex gap-1">
        <a class="" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
          aria-controls="collapseExample">
          <i class="bi bi-filter-left"></i>Filtros
        </a>

      </p>
      <div class="collapse" id="collapseExample">
        <div class="row">
          <div class="col-12 col-lg-4 mb-2">
            <label for="formControlInputProvince" class="form-label">Provincia</label>
            <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
              [(ngModel)]="selectedProvince" (ngModelChange)="onChangeProvince($event)">
              <option [ngValue]="0" (click)="onChangeProvince($event)">Todos</option>
              <option *ngFor="let province of this.provinces" [ngValue]="province.IDAddressProvince">{{ province.Name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <label for="formControlInputCanton" class="form-label">Cantón</label>
            <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
              [(ngModel)]="selectedCanton" (ngModelChange)="onChangeCanton($event)">
              <option [ngValue]="0" (click)="onChangeCanton($event)">Todos</option>
              <option *ngFor="let canton of this.cantons" [ngValue]="canton.IDAddressCanton">{{ canton.Name }}</option>
            </select>
          </div>
          <div class="col-12 col-lg-4 mb-4">
            <label for="exampleFormControlInput1" class="form-label">Farmacias</label>
            <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
              [(ngModel)]="selectedPharmacie" (ngModelChange)="onChangePharmacie($event)">
              <option [ngValue]="0" (click)="onChangeCanton($event)">Todos</option>
              <option *ngFor="let pharmacie of this.pharmacies" [ngValue]="pharmacie">{{ pharmacie.Name }}</option>
            </select>
          </div>
        </div>
      </div>

    </div>


    <div class="row d-none d-lg-flex">
      <div class="col-12 col-lg-4 mb-2">
        <label for="formControlInputProvince" class="form-label">Provincia</label>
        <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
          [(ngModel)]="selectedProvince" (ngModelChange)="onChangeProvince($event)">
          <option [ngValue]="0" (click)="onChangeProvince($event)">Todos</option>
          <option *ngFor="let province of this.provinces" [ngValue]="province.IDAddressProvince">{{ province.Name }}
          </option>
        </select>
      </div>
      <div class="col-12 col-lg-4 mb-2">
        <label for="formControlInputCanton" class="form-label">Cantón</label>
        <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
          [(ngModel)]="selectedCanton" (ngModelChange)="onChangeCanton($event)">
          <option [ngValue]="0" (click)="onChangeCanton($event)">Todos</option>
          <option *ngFor="let canton of this.cantons" [ngValue]="canton.IDAddressCanton">{{ canton.Name }}</option>
        </select>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <label for="exampleFormControlInput1" class="form-label">Farmacias</label>
        <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
          [(ngModel)]="selectedPharmacie" (ngModelChange)="onChangePharmacie($event)">
          <option [ngValue]="0" (click)="onChangeCanton($event)">Todos</option>
          <option *ngFor="let pharmacie of this.pharmacies" [ngValue]="pharmacie">{{ pharmacie.Name }}</option>
        </select>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 col-lg-4 mb-2">
        <label for="formControlInputProvince" class="form-label">Provincia</label>
        <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
          [(ngModel)]="selectedProvince" (ngModelChange)="onChangeProvince($event)">
          <option [ngValue]="0" (click)="onChangeProvince($event)">Todos</option>
          <option *ngFor="let province of this.provinces" [ngValue]="province.IDAddressProvince">{{ province.Name }}
          </option>
        </select>
      </div>
      <div class="col-12 col-lg-4 mb-2">
        <label for="formControlInputCanton" class="form-label">Cantón</label>
        <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
          [(ngModel)]="selectedCanton" (ngModelChange)="onChangeCanton($event)">
          <option [ngValue]="0" (click)="onChangeCanton($event)">Todos</option>
          <option *ngFor="let canton of this.cantons" [ngValue]="canton.IDAddressCanton">{{ canton.Name }}</option>
        </select>
      </div>
      <div class="col-12 col-lg-4 mb-2">
        <label for="exampleFormControlInput1" class="form-label">Farmacias</label>
        <select class="form-select contact-input" aria-label="Buscar por" aria-placeholder="Buscar por"
          [(ngModel)]="selectedPharmacie" (ngModelChange)="onChangePharmacie($event)">
          <option [ngValue]="0" (click)="onChangeCanton($event)">Todos</option>
          <option *ngFor="let pharmacie of this.pharmacies" [ngValue]="pharmacie">{{ pharmacie.Name }}</option>
        </select>
      </div>
    </div> -->

    <google-map [options]="mapOptions">
      <map-marker #marker="mapMarker" [options]="markerOptions" *ngFor="let spot of spots"
        [position]="{ lat: spot.lat, lng: spot.lng }" [clickable]="true" [title]="spot.pharmacie.Name"
        (mapClick)="openInfo(marker, spot)">
      </map-marker>
      <map-info-window [innerHTML]="infoContent"></map-info-window>
    </google-map>

    <div class="accordion accordion-flush accordion-farmacia mt-4" *ngIf="this.pharmacies.length > 0"
      id="accordionFlush">

      <div class="accordion-item" *ngFor="let item of this.pharmaciesList; let i = index">
        <h2 class="accordion-header" id="heading{{ i }}">
          <button class="accordion-button colored-accordion-button" [ngClass]="{'show': i === 0, 'collapsed': i !== 0}" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
            <div>
              <h3 class="d-block farmacy-name">{{ item.Name }}</h3>
              <p class="farmacy-address">{{ item.Address }}</p>
            </div>
          </button>
        </h2>
        <div [attr.id]="'collapse' + i" class="accordion-collapse collapse"  [attr.aria-labelledby]="'heading' + i"
          data-bs-parent="#accordionFlush">
          <div class="accordion-body">
            <h4 *ngIf="hasSchedule(item.Availabilities)" class="fw-medium">Horario</h4>

            <div *ngFor="let availabilitie of item.Availabilities;">
              <p *ngIf="availabilitie.IdAvailabilityType === 3 && availabilitie.Alias !== null" class="mb-0">
                {{ availabilitie.Alias }}
              </p>
            </div>

            <div class="d-flex align-items-end text-nowrap mt-3">
              <img  [src]="this.urlBase + '/assets/img/waze-logo.png'"  loading="lazy" alt="…" class="logo-waze">
              <a target="_blank" rel="noopener noreferrer"
                [href]="'https://waze.com/ul?&ll=' + item.ShopMapPoints.Latitude + ',' + item.ShopMapPoints.Longitude + '&navigate=yes'"
                class="me-3">Abrir en Waze</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
