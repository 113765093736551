<div [dynamicAttributes]="model.Attributes">
    <div *ngFor="let item of model.Items" class="col">
        <div class="card h-100">
            <img loading="lazy" *ngIf="" class="{{item.Image.Css}}" src="{{item.Image.Url}}" alt="{{item.Image.AlternativeText}}" title="{{item.Image.Title}}" />
            <div class="card-body">
                <h5 *ngIf="item.Title" [attr.class]="item.Title.Css">
                    <ng-container *ngIf="model.OpenDetails">
                        <a href="#" (click)="onDetailItemOpenHandler(item.Original, $event)">{{item.Title.Value}}</a>
                    </ng-container>
                    <ng-container *ngIf="!model.OpenDetails">
                        {{item.Title.Value}}
                    </ng-container>
                </h5>
                <p *ngIf="item.Text" class="{{item.Text.Css}}">{{item.Text.Value}}</p>
            </div>
        </div>
    </div>
</div>
