<main class="main d-flex align-items-center vh-100">
  <div class="container container-input">
      <div class="text-center text-not-found">
          <h1 class="">404</h1>
          <h2>Página no encontrada</h2>
          <p class="">Lo sentimos, la página a la que estás intentando acceder no existe o no se encuentra disponible
          </p>
          <a routerLink="/index">Regresar al inicio</a>
      </div>
  </div>
</main>
