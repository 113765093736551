import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IModal } from 'src/app/models/modal.model';
import { environment } from 'src/environments/environment';
declare var window: any;

export interface IModalOneProduct {
  continue: boolean;
  viewProduct: boolean;
}
@Component({
  selector: 'app-modal-one-product',
  templateUrl: './modal-one-product.component.html',
  styleUrls: ['./modal-one-product.component.css']
})
export class ModalOneProductComponent implements OnChanges {
  @Input() showModal: boolean = false;
  @Input() modalData: IModal = {
    ModalName: 'modalOneProduct',
  };
  @Input() title: string = '';
  @Input() recommendedProduct: any = null;
  @Input() selectUnit: number = 0;
  @Input() text: string = '';
  @Output() showModalChange = new EventEmitter<IModalOneProduct>();
  modal: any;
  urlBase = environment.urlBase;

  constructor(private router: Router){}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.modal.show();
    }
    if (changes.recommendedProduct && changes.recommendedProduct.currentValue && this.modal) {
      this.modal.show();
    }
  }

  continue() {
    this.modal.hide();
    this.showModalChange.emit({ continue: true, viewProduct: false });
  }

  viewProduct() {
    this.modal.hide();
    //this.showModalChange.emit({ continue: false, viewProduct: true });
    this.router.navigate(['/detalle-producto', this.recommendedProduct.URL]);
  }
}

