import { Component,ElementRef, PACKAGE_ROOT_URL } from '@angular/core';
import { ProductsService } from 'src/app/services/core/products.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { RecentSearchService } from 'src/app/services/shared/recent-search.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';

const settings = new SitefinitySettings();

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css'],
})
export class ProductosComponent {
  category!: string;
  idCategory!: number;
  urlParameter: string = '';
  products: any[] = [];
  countsProducts: number = 0;
  selectedUnits: { [key: number]: string } = {};
  selectedQuantity: { [key: number]: number } = {};
  selectedUnitPrice: number[] = [];
  selectedPromotion: string[] = [];
  categories: any;
  settings: SitefinitySettings = new SitefinitySettings();
  hasSearched = false;
  showFilters = false;
  source = '';
  searchParameter = '';
  searchParam: Params | undefined;
  foneticSearchType = settings.searchTypes.find((x) => x.name == 'fonetica')
  searchText : string = '';
  user: any;
  userId : any;

  subCategories: any[] = [];
  principalCategoryID: number = 0;
  allSelected = true;
  quantityValue: number = 1;
  unitSelected: string = 'Unidad'
  displayQuantityComponent: boolean = false;
  quantityByComponent:any = 10
  categoryName = "Productos"
  isProductsBySubCategories: boolean = false;
  notResults: boolean = false;
  productsV2: any = [];
  productsV3: any = [];
  state: any;

  constructor(
    private SearchService: RecentSearchService,
    private _utilitiesService: UtilitiesServicesService,
    private utilService: UtilitiesService,
    private notificationService: NotificationService,
    private productService: ProductsService,
    private aRoute: ActivatedRoute,
    private loader: LoadingService,
    private router: Router,
    private sitefinityPagesService: SitefinitypagesService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private utilities: UtilitiesService,
    private el: ElementRef
  ) {
  }

  ngOnInit() {

    this._utilitiesService.saveValueStorage('', "categoryIdSelected");
    this._utilitiesService.addMetaNoIndex();
    this.getQuantityProducts();
    this.state = window.history.state;

    this.route.params.subscribe(params => {
      this.source = this.aRoute.snapshot.data.source ? this.aRoute.snapshot.data.source : '';
      if (this.source == 'search') {
        //setea el título de la pagina
        this.sitefinityPagesService.setDetailPage(this.settings.pages.find((x) => x.Title === 'busqueda')!.ID);
        this.urlParameter = '';
        this.aRoute.queryParams.subscribe((params) => {
          this.searchParam = params;
          if (this.searchParam == undefined || this.searchParam == null || (Object.keys(this.searchParam).length === 0 && this.searchParam.constructor === Object)) {
            //Caso que sea /busqueda y nada más
            this.router.navigate(['/']);
          }
        });
      }
      else {
        //setea el título de la pagina
        this.hasSearched = false;
        this.sitefinityPagesService.setDetailPage(this.settings.pages.find((x) => x.Title === 'categorias')!.ID);
        this.urlParameter = this.aRoute.snapshot.paramMap.get('url') != null ? this.aRoute.snapshot.paramMap.get('url')! : '';
      }
      this.products = [];
      this.loader.showSpinner();
      if (this.source == 'search') {
        this.showFilters = false;
        this.GetProductsByLink();
      }
      else {
        //click en categoría
        this.showFilters = true;
        this.getCategoriesHeader().subscribe((result: any) => {
          this.categories = result;
          let isExistUrl = this.categories.find(
            (category: { URL: string }) => category.URL === this.urlParameter
          );
          if (isExistUrl && this.urlParameter != '') {
            const c = this.urlParameter.split('-');
            this.category = c[c.length - 1];

            this.idCategory = parseInt(this.category);
            this.principalCategoryID = this.idCategory;
            this._utilitiesService.saveValueStorage(this.idCategory, "categoryIdSelected");
            this.getProducts();
            this.getSubCategories();
            this.SearchService.SaveRecentSearch('', this.idCategory, 0);
          }
          else {
            this.getProductsBySubcategories();
          }
        });
      }
    });
    this.checkUserLoggedIn();
  }

  ngOnDestroy(): void {
    this._utilitiesService.removeMetaNoIndex();
  }

  getQuantityProducts() {
    this.utilities.GetParametersByKey('QuantityProducts').subscribe(res => {
      if (res.value.length > 0) {
        let quantity = res.value[0].value;
        let totalQuantity = parseInt(quantity);
        this.countsProducts = totalQuantity;
      }
    });
  }

  getProducts(idCategory?: any, requestFromFilter = false, index = 0, allOption = false) {
    this.hasSearched = false;
    this.allSelected = true;
    this.loader.showSpinner();
    if (idCategory == undefined) {
      idCategory = this.idCategory;
    }
    if (requestFromFilter) {
      const isDeselecting = this.subCategories[index]?.isSelected && !allOption;
      if (isDeselecting) {
        this.allSelected = true;
        idCategory = this.idCategory;
      }
      else {
        this.subCategories.forEach(category => category.isSelected = false);
        if (!allOption) {
          this.subCategories[index].isSelected = true;
          this.allSelected = false;
        }
        else {
          this.allSelected = true;
        }
      }
    }
    this.productService.GetProductsByCategorieMethod(idCategory).subscribe((res) => {
      this.hasSearched = true;
      this.notResults = false;
      if (res.response.code === 'OK0001') {
        this.products = res.products;


          let productMemory:any  = this._utilitiesService.getValueStorage('ProductMemoryStorage');
          let isButtonBackPush  = this._utilitiesService.getValueStorage('isButtonBackPush');
          let elementDiv:any  = this._utilitiesService.getValueStorage('scrollPosition');
          console.log(productMemory);
          console.log(isButtonBackPush);
          console.log(elementDiv);
         if(productMemory == null || isButtonBackPush == null)
         {
          this.productsV2 = res.products.slice(0,this.countsProducts);
          this.AdjustProducts(this.productsV2);

         }
         else
         {

          if(productMemory.urlSite == window.location.href )
            {
               if(productMemory.products.length ==  res.products.length)
                {
                  this.countsProducts = productMemory.products.length ;

                }
              this.productsV2 = productMemory.products
              this.AdjustProducts(this.productsV2);

            }
            else
            {
              // this.productsV2 = res.products.slice(0,this.countsProducts);
              // this.AdjustProducts(this.productsV2);


              this.utilities.GetParametersByKey('QuantityProducts').subscribe(des => {
               // des = JSON.parse(des.Data);
                if (des.value.length > 0) {
                  let quantity = des.value[0].value;
                  let totalQuantity = parseInt(quantity);
                  this.countsProducts = totalQuantity;

                  this.productsV2 = res.products.slice(0,this.countsProducts);
                  this.AdjustProducts(this.productsV2);
                }
              });

            }






        }
        this.getNameCategory();
        this.sitefinityPagesService.GetSitefinityDetail('20539463-a570-4bb1-b6c2-8682dc278485').subscribe(o=> {
          this.sitefinityPagesService.setMetadataOpenGraph(this.categoryName,o.OpenGraphDescription,"",window.location.href);
          this.sitefinityPagesService.setDataPage(this.categoryName, o.OpenGraphDescription, '');
        });
         setTimeout(() => {
            this.waitForElementAndScroll(elementDiv);
          }, 0);


      }
      else {
        this.products = [];
        this.productsV3 = [];
        this.notResults = true;
        this.getNameCategory();
      }
      this.loader.hideSpinner();
    });
  }

private waitForElementAndScroll(divId: string) {
console.log("hace scroll ?")


   const interval = setInterval(() => {
      const element = this.el.nativeElement.querySelector(`#${divId}`);
       if (element) {

      console.log("hace scroll ? 2")
        clearInterval(interval);
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

     }
    }, 100); // Verificar cada 100 milisegundos


  }


  getNameCategory() {
    let category = this.categories.find((c: { IDProductCategory: number; }) => c.IDProductCategory === parseInt(this.category));
    this.categoryName = category != null ? category.Name : this.categoryName;
  }

  getProductsBySubcategories() {
    const c = this.urlParameter.split('-');
    this.category = c[c.length - 1];
    this.idCategory = parseInt(this.category);
    this.categoryName = this.state.categoryName != null || this.state.categoryName != undefined ? this.state.categoryName : 'Productos';

    this.productService.GetProductsByCategorieMethod(this.idCategory).subscribe((cat: any) => {
      if (cat.response.code == 'OK0001') {
        if (cat.products.length > 0) {
          this.isProductsBySubCategories = true;;
          this.products = cat.products;
          this.productsV3 = cat.products;
          this.AdjustProducts();
          if (this.state.idCategory != null) {
            this.getSubCategories(this.state.idCategory);
          }
        }
        else {
          this.products = [];
          this.productsV3  = [];
        }
      }
      else {
        this.router.navigate(['/']);
      }
      this.loader.hideSpinner();
    });
  }

  GetProductsByLink() {
    this.loader.showSpinner();
    var text: string = Object.values(this.searchParam!)[0];
    var charValue: string = Object.keys(this.searchParam!)[0];
    var selectedType = this.settings.searchTypes.find((x) => x.charValue.toLowerCase() == charValue.toLowerCase());
    if (!selectedType) {
      //Parámetro de búsqueda incorrecto
      this.loader.hideSpinner();
      this.router.navigateByUrl('/');
    } else {
      var searchType = selectedType.id;
    if (searchType == this.foneticSearchType?.id && charValue == 'f') {
        //BUSQUEDA FONETICA
        const recoveredSearchText = this.utilService.RecoverTextFromUrl(text);
        this.productService.SearchProductsMethod(recoveredSearchText).subscribe((res) => {
          this.hasSearched = true;
          if (res.response.code === 'OK0001') {


            let productMemory:any  = this._utilitiesService.getValueStorage('ProductMemoryStorage');
            let isButtonBackPush  = this._utilitiesService.getValueStorage('isButtonBackPush');
            let elementDiv:any  = this._utilitiesService.getValueStorage('scrollPosition');
            console.log(productMemory);
            console.log(isButtonBackPush);
            console.log(elementDiv);

           if(productMemory == null || isButtonBackPush == null)
           {
            this.products = res.products;
            this.productsV2 = res.products.slice(0,this.countsProducts);
            this.AdjustProducts(this.productsV2);

           }
           else
           {

            if(productMemory.urlSite == window.location.href )
              {
                this.products = res.products;
                this.productsV2 = productMemory.products
                if(productMemory.products.length ==  res.products.length)
                  {
                    this.countsProducts =productMemory.products.length ;

                  }
                this.AdjustProducts(this.productsV2);
                // setTimeout(() => {
                //   this.waitForElementAndScroll(elementDiv);
                // }, 300);

              }
              else
              {
                // this.products = res.products;
                // this.productsV2 = res.products.slice(0,this.countsProducts);
                // this.AdjustProducts(this.productsV2);


                this.utilities.GetParametersByKey('QuantityProducts').subscribe(des => {
              //    des = JSON.parse(des.Data);
                  if (des.value.length > 0) {
                    let quantity = des.value[0].value;
                    let totalQuantity = parseInt(quantity);
                    this.countsProducts = totalQuantity;

                    this.products = res.products;
                    this.productsV2 = res.products.slice(0,this.countsProducts);
                    this.AdjustProducts(this.productsV2);
                  }
                });




              }






          }

          setTimeout(() => {
            this.waitForElementAndScroll(elementDiv);
          }, 0);

          } else {
            this.products = [];
            this.productsV3 = [];
          }
          //saveSearch
          this.SearchService.SaveRecentSearch(recoveredSearchText, 0, 0);
          this.hasSearched=true;
          this.loader.hideSpinner();
        },
          (err) => {
            this.hasSearched = true;
            this.notificationService.SendNotificationByCode('phoneticSearch-fail', '2')
            this.loader.hideSpinner()
          })
      }
      else {
        //BUSQUEDA POR URL
        this.productService
          .GetReducedProductsByLinkMethod(text, searchType)
          .subscribe((res) => {
            this.hasSearched = true;
            if (res.response.code === 'OK0001') {



              let productMemory:any  = this._utilitiesService.getValueStorage('ProductMemoryStorage');
              let isButtonBackPush  = this._utilitiesService.getValueStorage('isButtonBackPush');
              let elementDiv:any  = this._utilitiesService.getValueStorage('scrollPosition');
              console.log(productMemory);
              console.log(isButtonBackPush);
              console.log(elementDiv);
             if(productMemory == null || isButtonBackPush == null)
             {
              this.products = res.responseObject;
              this.productsV3 = res.responseObject;
              this.AdjustProducts();

             }
             else
             {

              if(productMemory.urlSite == window.location.href )
                {
                  this.productsV3 = productMemory.products
                  if(productMemory.products.length ==  res.responseObject.length)
                    {
                      this.countsProducts = productMemory.products.length ;

                    }
                 // this.AdjustProducts(this.productsV2);
                 this.AdjustProducts();
                //  setTimeout(() => {
                //   this.waitForElementAndScroll(elementDiv);
                // }, 300);
                }
                else
                {


                  this.utilities.GetParametersByKey('QuantityProducts').subscribe(des => {
                  //  des = JSON.parse(des.Data);
                    if (des.value.length > 0) {
                      let quantity = des.value[0].value;
                      let totalQuantity = parseInt(quantity);
                      this.countsProducts = totalQuantity;

                      this.products = res.responseObject;
                      this.productsV3 = res.responseObject;
                      this.AdjustProducts();
                    }
                  });



                }






            }





        setTimeout(() => {
              this.waitForElementAndScroll(elementDiv);
            }, 0);






            }
            else {
              this.products = [];
              this.productsV3 = [];
            }
            this.loader.hideSpinner();
          },
            (err) => {
              this.hasSearched = true;
              this.notificationService.SendNotificationByCode('linkSearch-fail', '2')
              this.loader.hideSpinner()
            })
      }
    }
  }

  AdjustProducts(products?: any) {
    this.productsV3 = products?.length > 0 ? products : this.products;
    this.productsV3.forEach((p: any, index: number) => {

      p.displayQuantityComponent = false;
      p.quantityByComponent = 10;
      p.numbers = [];

      if(p.MaxToPurchase > 10)
      {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else
      {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);
        }
      }


      this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
      this.selectedQuantity[index] = p.MinToPurchase;

      if (p.ProductAttributes && p.ProductAttributes.length > 0) {
        if (this.selectedUnits[index] === 'Unidad') {
          this.selectedUnitPrice[index] = p.ProductAttributes[0]
            .PriceWithDiscount
            ? p.ProductAttributes[0].PriceWithDiscount
            : p.ProductAttributes[0].PriceWithTaxes;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
        } else if (this.selectedUnits[index] === 'Caja') {
          if (
            p.ProductAttributes[0].PriceWithDiscount > 0 ||
            p.ProductAttributes[1]?.PriceWithDiscount > 0
          ) {
            this.selectedUnitPrice[index] = p.ProductAttributes[0]
              .PriceWithDiscount
              ? p.ProductAttributes[0].PriceWithDiscount
              : p.ProductAttributes[1]?.PriceWithDiscount;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
          } else {
            this.selectedUnitPrice[index] = p.ProductAttributes[0]
              .PriceWithTaxes
              ? p.ProductAttributes[0].PriceWithTaxes
              : p.ProductAttributes[1]?.PriceWithTaxes;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
          }
        }
      } else {
        if(p.PriceWithDiscount>0){
          this.selectedUnitPrice[index] = p.PriceWithDiscount * p.MinToPurchase;
        }
        else{
          this.selectedUnitPrice[index] = p.PriceWithTaxes * p.MinToPurchase; // Establece un valor predeterminado
        }
      }

      if(p.MinToPurchase > 1)
      {
        if(this.selectedUnits[index] === 'Caja')
        {
          this.selectedUnits[index] = 'Cajas';
        }
        else
        {
          this.selectedUnits[index] = 'Unidades';
        }
      }

      if(p.PercentDiscountRate > 0){
        if(p.PriceWithDiscount > 0){
          this.selectedPromotion[index] = p.PercentDiscountRate + "%"
        }
        else{
          if(p.ProductAttributes.length>0){
            let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
            if (this.selectedUnits[index].startsWith('Unidad')) {
              if(currentAttribute.PriceWithDiscount > 0){
                this.selectedPromotion[index] = p.PercentDiscountRate + "%"
              }
              else{
                if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else{
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else{
              if (this.selectedUnits[index].startsWith('Caja')) {
                if(currentAttribute.PriceWithDiscount > 0){
                  this.selectedPromotion[index] = p.PercentDiscountRate + "%"
                }
                else{
                  if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                    this.selectedPromotion[index] = currentAttribute.Dynamic;
                  }
                  else{
                    this.selectedPromotion[index] = "";
                  }
                }
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
          }
          else{
            if(p.Dynamic && p.Dynamic != ""){
              this.selectedPromotion[index] = p.Dynamic;
            }
            else{
              this.selectedPromotion[index] = "";
            }
          }
        }
      }
      else{
        if(p.ProductAttributes.length>0){
          let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if(currentAttribute.Dynamic || currentAttribute.Dynamic != ""){
            this.selectedPromotion[index] = currentAttribute.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
        else{
          if(p.Dynamic || p.Dynamic != ""){
            this.selectedPromotion[index] = p.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
      }

    });
  }

  AdjustNewProducts(p: any, index: number) {

      p.displayQuantityComponent = false;
      p.quantityByComponent = 10;
      p.numbers = [];

      if(p.MaxToPurchase > 10)
      {
        for (let i = p.MinToPurchase; i <= 9; i++) {
          p.numbers.push(i);
        }
      }
      else
      {
        for (let i = p.MinToPurchase; i <= p.MaxToPurchase; i++) {
          p.numbers.push(i);
        }
      }


      this.selectedUnits[index] = p.ProductAttributes && p.ProductAttributes.length > 0 ? p.ProductAttributes[0]?.Name : 'Unidad';
      this.selectedQuantity[index] = p.MinToPurchase;

      if (p.ProductAttributes && p.ProductAttributes.length > 0) {
        if (this.selectedUnits[index] === 'Unidad') {
          this.selectedUnitPrice[index] = p.ProductAttributes[0]
            .PriceWithDiscount
            ? p.ProductAttributes[0].PriceWithDiscount
            : p.ProductAttributes[0].PriceWithTaxes;
            this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
        } else if (this.selectedUnits[index] === 'Caja') {
          if (
            p.ProductAttributes[0].PriceWithDiscount > 0 ||
            p.ProductAttributes[1]?.PriceWithDiscount > 0
          ) {
            this.selectedUnitPrice[index] = p.ProductAttributes[0]
              .PriceWithDiscount
              ? p.ProductAttributes[0].PriceWithDiscount
              : p.ProductAttributes[1]?.PriceWithDiscount;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
          } else {
            this.selectedUnitPrice[index] = p.ProductAttributes[0]
              .PriceWithTaxes
              ? p.ProductAttributes[0].PriceWithTaxes
              : p.ProductAttributes[1]?.PriceWithTaxes;
              this.selectedUnitPrice[index] = this.selectedUnitPrice[index] * p.MinToPurchase
          }
        }
      } else {
        if(p.PriceWithDiscount>0){
          this.selectedUnitPrice[index] = p.PriceWithDiscount * p.MinToPurchase;
        }
        else{
          this.selectedUnitPrice[index] = p.PriceWithTaxes * p.MinToPurchase; // Establece un valor predeterminado
        }
      }

      if(p.MinToPurchase > 1)
      {
        if(this.selectedUnits[index] === 'Caja')
        {
          this.selectedUnits[index] = 'Cajas';
        }
        else
        {
          this.selectedUnits[index] = 'Unidades';
        }
      }

      if(p.PercentDiscountRate > 0){
        if(p.PriceWithDiscount > 0){
          this.selectedPromotion[index] = p.PercentDiscountRate + "%"
        }
        else{
          if(p.ProductAttributes.length>0){
            let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
            if (this.selectedUnits[index].startsWith('Unidad')) {
              if(currentAttribute.PriceWithDiscount > 0){
                this.selectedPromotion[index] = p.PercentDiscountRate + "%"
              }
              else{
                if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else{
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else{
              if (this.selectedUnits[index].startsWith('Caja')) {
                if(currentAttribute.PriceWithDiscount > 0){
                  this.selectedPromotion[index] = p.PercentDiscountRate + "%"
                }
                else{
                  if(currentAttribute.Dynamic && currentAttribute.Dynamic != ""){
                    this.selectedPromotion[index] = currentAttribute.Dynamic;
                  }
                  else{
                    this.selectedPromotion[index] = "";
                  }
                }
              }
              else{
                this.selectedPromotion[index] = "";
              }
            }
          }
          else{
            if(p.Dynamic && p.Dynamic != ""){
              this.selectedPromotion[index] = p.Dynamic;
            }
            else{
              this.selectedPromotion[index] = "";
            }
          }
        }
      }
      else{
        if(p.ProductAttributes.length>0){
          let currentAttribute = p.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if(currentAttribute.Dynamic || currentAttribute.Dynamic != ""){
            this.selectedPromotion[index] = currentAttribute.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
        else{
          if(p.Dynamic || p.Dynamic != ""){
            this.selectedPromotion[index] = p.Dynamic;
          }
          else{
            this.selectedPromotion[index] = "";
          }
        }
      }
  }

  loadProducts() {
    this.loader.showSpinner();
    if (this.countsProducts < this.products.length) {
      this.utilities.GetParametersByKey('loadProducts').subscribe(res => {
     if (res.value.length > 0) {


          let quantity:any = 0;
          let totalQuantity = 0;

          let total:any = 0;
              let productMemory:any  = this._utilitiesService.getValueStorage('ProductMemoryStorage');
              let isButtonBackPush  = this._utilitiesService.getValueStorage('isButtonBackPush');
              let elementDiv:any  = this._utilitiesService.getValueStorage('scrollPosition');

             if(productMemory == null || isButtonBackPush == null)
             {
             // console.log("entro aqui ?");
              quantity = res.value[0].value;
              totalQuantity = parseInt(quantity);
              this.countsProducts = this.countsProducts + totalQuantity;
              total = this.productsV3.length + totalQuantity;
              let pts: any[] = this.products.slice(this.productsV3.length, total);
              pts.forEach((p: any) => {
                this.productsV3.push(p);
                this.AdjustNewProducts(this.productsV3[this.productsV3.length -1], this.productsV3.length -1);
              });
             }
             else
             {

              if(productMemory.urlSite == window.location.href )
                {

                  //console.log("entro aqui 2?");
                  quantity = res.value[0].value;
                  totalQuantity = parseInt(quantity);
                  this.countsProducts = productMemory.countsProducts + totalQuantity ;
                  total = productMemory.total + totalQuantity;
                  // console.log("producto en memoria",productMemory.products.length);
                  // console.log("total",total);
                  // console.log("total productos",this.products.length);
                  // console.log("total productos V3",this.productsV3.length);

                  let pts: any[] = this.products.slice(productMemory.products.length, total);
                  console.log(pts);
                  pts.forEach((p: any) => {
                    this.productsV3.push(p);
                    this.AdjustNewProducts(this.productsV3[this.productsV3.length -1], this.productsV3.length -1);
                  });

                }
                else
                {
                  //console.log("entro aqui 3 ?");
                  quantity = res.value[0].value;
                  totalQuantity = parseInt(quantity);
                  this.countsProducts = this.countsProducts + totalQuantity;
                  total = this.productsV3.length + totalQuantity;
                  let pts: any[] = this.products.slice(this.productsV3.length, total);
                  pts.forEach((p: any) => {
                    this.productsV3.push(p);
                    this.AdjustNewProducts(this.productsV3[this.productsV3.length -1], this.productsV3.length -1);
                  });

                }


             }








          // let quantity = res.value[0].value;
          // let totalQuantity = parseInt(quantity);
          // this.countsProducts = this.countsProducts + totalQuantity;
          // let total = this.productsV3.length + totalQuantity;


          let pts: any[] = this.products.slice(this.productsV3.length, total);
          pts.forEach((p: any) => {
            this.productsV3.push(p);
            this.AdjustNewProducts(this.productsV3[this.productsV3.length -1], this.productsV3.length -1);
          });

          let ProductMemory :any = { //objecto se utiliza para guardar informacion de resultados previos a la hora de cargar cuando apretan el back.

            total:total,
            productslength:this.productsV3.length,
            urlSite: window.location.href,
            products:  this.productsV3,
            countsProducts: this.countsProducts

          }

          console.log(this.countsProducts);
          console.log(this.products);
          this._utilitiesService.saveValueStorage(ProductMemory, "ProductMemoryStorage");





        }
      });
    }
    this.loader.hideSpinner();
  }


  selectUnit(index: number, unit: string): void {
    this.selectedUnits[index] = unit;
    this.unitSelected = unit;
    this.selectType(index,this.selectedUnits[index]);
    // Llama a la función para recalcular el precio cuando se cambia la unidad
    // this.calculateTotalPrice(index);
  }

  selectQuantity(index: number, quantity: number): void {
    this.selectedQuantity[index] = quantity;
    this.quantityValue = quantity;
    this.selectType(index,this.selectedUnits[index]);
    // Calcula el precio total aquí (dependiendo de la cantidad y la unidad seleccionada)
    // this.calculateTotalPrice(index);
  }

  calculateTotalPrice(index: number): void {

    const product = this.products[index];
    const quantity = this.selectedQuantity[index];

    if (product.ProductAttributes.length > 0 && (this.selectedUnits[index] === 'Unidad' || this.selectedUnits[index] === 'Unidades')) {
      this.selectedUnitPrice[index] = product.ProductAttributes[0].PriceWithDiscount ? product.ProductAttributes[0].PriceWithDiscount * quantity : product.ProductAttributes[0].PriceWithTaxes * quantity;
    }
    else if (this.selectedUnits[index] === 'Caja' || this.selectedUnits[index] === 'Cajas' && product.ProductAttributes) {
      if (product.ProductAttributes.length > 1) {
        this.selectedUnitPrice[index] = product.ProductAttributes[1]?.PriceWithDiscount ? product.ProductAttributes[1]?.PriceWithDiscount * quantity : product.ProductAttributes[1]?.PriceWithTaxes * quantity;
      }
      else {
        this.selectedUnitPrice[index] = product.ProductAttributes[0]?.PriceWithDiscount ? product.ProductAttributes[0]?.PriceWithDiscount * quantity : product.ProductAttributes[0]?.PriceWithTaxes * quantity;
      }
    }
    else {
      this.selectedUnitPrice[index] = product.PriceWithDiscount > 0 ? product.PriceWithDiscount * quantity : product.PriceWithTaxes * quantity;
    }

    if(product.PercentDiscountRate > 0) {
      if(product.PriceWithDiscount > 0){
        this.selectedPromotion[index] = product.PercentDiscountRate + "%"
      }
      else {
        if (product.ProductAttributes.length > 0) {
          let currentAttribute = product.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
          if (this.selectedUnits[index].startsWith('Unidad')) {
            if(currentAttribute.PriceWithDiscount > 0){
              this.selectedPromotion[index] = product.PercentDiscountRate + "%"
            }
            else {
              if (currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                this.selectedPromotion[index] = currentAttribute.Dynamic;
              }
              else {
                this.selectedPromotion[index] = "";
              }
            }
          }
          else {
            if (this.selectedUnits[index].startsWith('Caja')) {
              if (currentAttribute.PriceWithDiscount > 0) {
                this.selectedPromotion[index] = product.PercentDiscountRate + "%"
              }
              else {
                if (currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                  this.selectedPromotion[index] = currentAttribute.Dynamic;
                }
                else {
                  this.selectedPromotion[index] = "";
                }
              }
            }
            else {
              this.selectedPromotion[index] = "";
            }
          }
        }
        else {
          if (product.Dynamic && product.Dynamic != "") {
            this.selectedPromotion[index] = product.Dynamic;
          }
          else {
            this.selectedPromotion[index] = "";
          }
        }
      }
    }
    else{
      if (product.ProductAttributes.length > 0) {
        let currentAttribute = product.ProductAttributes.filter((x:any)=>this.selectedUnits[index].startsWith(x.Name))[0];
        if (currentAttribute.Dynamic || currentAttribute.Dynamic != "") {
          this.selectedPromotion[index] = currentAttribute.Dynamic;
        }
        else {
          this.selectedPromotion[index] = "";
        }
      }
      else {
        if (product.Dynamic || product.Dynamic != "") {
          this.selectedPromotion[index] = product.Dynamic;
        }
        else {
          this.selectedPromotion[index] = "";
        }
      }
    }
  }

  validateOption(p: any, index: number): boolean {

    return !(
      p.ProductAttributes &&
      p.ProductAttributes.length === 1 &&
      this.selectedUnits[index] === 'Unidad'
    );
  }

  goToDetailProduct(url: string, index:any,sku:any, categories:any) {
    if (url != null) {


      if(this.source == 'search')
      { // aplica solo para busquedas.
          if(categories.length > 0)
            {

              this._utilitiesService.saveValueStorage(categories[0].IDProductCategory, "categoryIdSelected");

            }
      }

      this.router.navigate(['detalle-producto', url]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const element = "productId"+index+sku;
      this.saveScrollPosition(element);
    }
  }

  getCategoriesHeader(): Observable<any[]> {
    return this.productService.GetCategoriesHeaderMethod(0).pipe(
      map((res: any) => {
        if (res.response.code === 'OK0001') {
          return res.categories;
        } else {
          return [];
        }
      })
    );
  }

  addProductToCart(product: any, unitSelected: any, quantity: number, price: number) {
    if (product) {
      this.loader.showSpinner();
      this.sharedService.addProductsToCartV2(product, unitSelected, quantity, price);
      if (this.source == 'search') {
        this.showFilters = false;
        this.GetProductsByLink();
        this.quantityByComponent = 10;
      }
      else if (this.isProductsBySubCategories) {
        this.getProductsBySubcategories();
      }
      else {
        this.getProducts();
        this.quantityByComponent = 10;
      }
    }
  }


  getSubCategories(id?: number) {
    let idCategory = id != null || id != undefined ? id : this.idCategory;
    this.productService.GetCategoriesHeaderMethod(idCategory).subscribe((cat) => {
        if (cat.response.code == 'OK0001') {
          if (this.categories.length > 0) {
            this.subCategories = cat.categories.sort((a, b) => a.Name.localeCompare(b.Name));
            this.subCategories.forEach(category => category.isSelected = false);
            const index = this.subCategories.findIndex(c => c.IDProductCategory === this.idCategory);
            if (index !== -1) {
              this.subCategories[index].isSelected = true;
              this.allSelected = false;
            }
          }
          else {
            this.subCategories = [];
          }

        } else {
          this.subCategories = [];
        }
      });
  }


  getPluralizedName(itemName: string, quantity: number): string {
    if (itemName.toLowerCase() === 'unidad') {
      return quantity > 1 ? 'Unidades' : 'Unidad';
    } else if (itemName.toLowerCase() === 'caja') {
      return quantity > 1 ? 'Cajas' : 'Caja';
    } else {
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return itemName;
    }
  }

  selectType(index:any,itemName: string ): string {

    if (itemName.toLowerCase() === 'unidad' ||  itemName.toLowerCase() === 'unidades' ) {

      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Unidades' : 'Unidad';
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index]
    } else if (itemName.toLowerCase() === 'caja' ||  itemName.toLowerCase() === 'cajas') {

      this.selectedUnits[index] = this.selectedQuantity[index] > 1 ? 'Cajas' : 'Caja';
      this.calculateTotalPrice(index);
      return  this.selectedUnits[index];
    } else {
      this.selectedUnits = itemName;
      this.calculateTotalPrice(index);
      // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
      return  this.selectedUnits[index]
    }
  }

  openQuantityComponent(index:any,value:any)  {
    this.products[index].quantityByComponent =  this.validateQuantityZero(this.products[index].quantityByComponent, index);

    if (value == false){

    this.selectedQuantity[index] = this.products[index].quantityByComponent;
    this.selectType(index,this.selectedUnits[index]);
    }
    this.products[index].displayQuantityComponent = value;
  }

  validateNumber(event: any): void {
    const inputChar =  event.data;


    // Si es 'e', 'E', '+' o cualquier carácter no numérico, no permitas la entrada
    if ((inputChar === 'e' || inputChar === 'E' || inputChar === '+' || isNaN(parseInt(inputChar, 10))) && !event.ctrlKey) {
      // Prevent default para evitar que el valor sea introducido
      event.preventDefault();
    }
  }

  async checkUserLoggedIn() {
    if (this._utilitiesService.isUserLoggedIn()) {
      this.user = this._utilitiesService.getValueStorage("sessionStorage");
      this.userId = await this._utilitiesService.decryptData(this.user.i);
    }
  }

  validateQuantityZero(quantity: any, index: number) {
    if(quantity <= 0 ) {
      return this.products[index].MinToPurchase;
    }
    if (quantity < this.products[index].MinToPurchase) {
      return this.products[index].MinToPurchase;
    }
    if (quantity > this.products[index].MaxToPurchase) {
      return this.products[index].MaxToPurchase;
    }
    return quantity;
  }


 saveScrollPosition(productId: any) {

    this._utilitiesService.saveValueStorage(productId, "scrollPosition");

  }
}
