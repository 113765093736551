<div class="modal fade modal-overlay-light" [id]="this.modalData.ModalName" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog recommended-dialog modal-dialog-centered justify-content-center">
    <div class="modal-content modal-style modal-background">
      <div class="modal-header border-0 pt-4">
        <h2 class="modal-title fs-5 text-white" id="modalRecomendedLabel">Te recomendamos los siguientes productos
        </h2>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" (click)="Close()"></button>
      </div>
      <div class="modal-body mx-2 py-0 recommendeed-modal d-flex align-items-center">

        <div class="d-flex gap-2">
          <div class="" *ngFor="let p of products; let i = index">
            <div class="card h-100 px-3 card-recommended card-costum-shadow">
              <div class="product-card-content">
                <div class="img-container-item" (click)="goToDetailProduct(p.URL)">
                  <img src="{{p.ProductImages[0].WebPath}}" loading="lazy" alt="…">
                </div>
                <!-- <span class="badge discount-badge" *ngIf="p.PercentDiscountRate > 0">
                  {{p.PercentDiscountRate}}%
                </span> -->
                <span class="badge discount-badge discount-animation" *ngIf="selectedPromotion[i]!=''">{{selectedPromotion[i]}}</span>
              </div>
              <div class="card-body p-0">
                <div>
                  <h3 class="mb-0 title-card-banner">{{p.Name}}</h3>
                </div>

                <div class="py-2" *ngIf="p.ProductAttributes && p.ProductAttributes.length > 0">

                  <span class="banner-price text-nowrap d-block"
                    *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades')  && p.ProductAttributes[0].Name === 'Unidad'">
                    {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                  </span>
                  <span class="banner-price text-nowrap d-block"
                    *ngIf="(selectedUnits[i] === 'Caja'  || selectedUnits[i] === 'Cajas') && p.ProductAttributes">
                    {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                  </span>
                  <!-- Producto con descuento -->
                  <span class="banner-discount text-nowrap d-block"
                    *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.ProductAttributes[0].PriceWithDiscount > 0">
                    {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                  </span>
                  <span class="banner-discount text-nowrap d-block"
                    *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes.length > 1 && p.ProductAttributes[1].PriceWithDiscount > 0">
                    {{p.ProductAttributes[1].PriceWithTaxes * selectedQuantity[i]| mycurrency }} i.v.a.i
                  </span>
                  <span class="banner-discount text-nowrap d-block"
                    *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.ProductAttributes[0].Name === 'Caja' && p.ProductAttributes[0].PriceWithDiscount > 0">
                    {{p.ProductAttributes[0].PriceWithTaxes * selectedQuantity[i] | mycurrency }} i.v.a.i
                  </span>

                </div>

                <div class="py-2" *ngIf=" p.ProductAttributes.length  == 0">

                  <span class="banner-price text-nowrap d-block"
                    *ngIf="selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades' ">
                    {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                  </span>

                  <!-- Producto con descuento -->
                  <span class="banner-discount text-nowrap d-block"
                    *ngIf="(selectedUnits[i] === 'Unidad' || selectedUnits[i] === 'Unidades') && p.PriceWithDiscount > 0">
                    {{p.PriceWithDiscount| mycurrency }} i.v.a.i
                  </span>

                  <span class="banner-price text-nowrap d-block"
                    *ngIf="selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas' ">
                    {{ selectedUnitPrice[i] | mycurrency }} i.v.a.i
                  </span>

                  <!-- Producto con descuento -->
                  <span class="banner-discount text-nowrap d-block"
                    *ngIf="(selectedUnits[i] === 'Caja' || selectedUnits[i] === 'Cajas') && p.PriceWithDiscount > 0">
                    {{p.PriceWithDiscount| mycurrency }} i.v.a.i
                  </span>
                </div>
              </div>

              <div class="product-card-footer d-flex justify-content-start">
                <div *ngIf="!p.displayQuantityComponent" class="d-flex align-items-center me-2">
                  <div class="d-flex align-items-center me-1">
                    <div class="nav-item dropup">
                      <button class="btn btn-outline-secondary dropdown-toggle dropdown-product dropdown-product-recommended btn-sm" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        {{ selectedQuantity[i] }}
                      </button>
                      <ul class="dropdown-menu number-dropdown">
                        <li *ngFor="let quantity of p.numbers">
                          <a class="dropdown-item" (click)="selectQuantity(i, quantity)">{{ quantity }}</a>
                        </li>
                        <hr *ngIf="this.p.MaxToPurchase > 9" class="my-0">
                        <li *ngIf="this.p.MaxToPurchase > 10" (click)="openQuantityComponent(i,true)"><a
                            class="dropdown-item">10+ </a></li>
                      </ul>
                    </div>
                  </div>

                  <div class="d-flex align-items-center me-1">
                    <div class="nav-item dropup">
                      <button *ngIf="p.ProductAttributes.length > 1" class="btn btn-outline-secondary dropdown-toggle dropdown-product dropdown-product-recommended btn-sm" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false"
                        [ngClass]="{'btn-outline-secondary': true, 'dropdown-toggle': true, 'dropdown-product': true}">
                        {{ selectedUnits[i] }}
                      </button>

                      <button *ngIf="p.ProductAttributes.length == 1 || p.ProductAttributes.length == 0 "
                        class="btn btn-outline-secondary dropdown-product dropdown-product-recommended" type="button" aria-expanded="false">
                        {{ selectedUnits[i] }}
                      </button>

                      <ul class="dropdown-menu option-dropdown">
                        <li *ngFor="let item of p.ProductAttributes">
                          <a class="dropdown-item" (click)="selectType(i,item.Name)">{{ getPluralizedName(item.Name,
                            selectedQuantity[i]) }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <button aria-label="Agregar al carrito" type="button" class="btn btn-primary product-icon btn-sm" data-bs-toggle="tooltip"
                      data-bs-placement="top" data-bs-title="Agregar al carrito"
                      (click)="addProductToCart(p, selectedUnits[i], selectedQuantity[i], selectedUnitPrice[i])">
                      <i class="bi bi-cart-plus-fill"></i>
                    </button>
                  </div>
                </div>

                <div *ngIf="p.displayQuantityComponent" class="d-flex align-items-center d-none" id="quantity-custom-amount">
                  <input [(ngModel)]="p.quantityByComponent" type="number" class="form-control input-quantity"
                    id="quantity-product-input" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                    aria-describedby="emailHelp" [max]="p?.MaxToPurchase" [min]="1">
                  <button (click)="openQuantityComponent(i,false)" type="submit"
                    class="btn btn-outline-secondary ms-1"><i class="bi bi-plus-lg"></i></button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between border-0 pb-4">
        <div>
          <!-- <p class="text-footer-modal">Codigo de error</p> -->
        </div>

        <div>
          <!-- <button type="button" class="btn btn-primary btn-sm" (click)="Accept()">Continuar</button> -->
          <a class="btn-text-modal me-3 " type="button" data-bs-dismiss="modal" (click)="Accept()">Continuar</a>
        </div>
      </div>
    </div>
  </div>
</div>
