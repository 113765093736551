import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestFavoriteProduct } from 'src/app/models/core/clients.model';
import { IProduct, IProductCart } from 'src/app/models/core/product-cart.model';
import { RecentSearchRequest } from 'src/app/models/core/products.model';
import { IModal } from 'src/app/models/modal.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { ClientsService } from 'src/app/services/core/clients.service';
import { FidelityService } from 'src/app/services/core/fidelity.service';
import { MergeNService } from 'src/app/services/core/merge-n.service';
import { ProductsService } from 'src/app/services/core/products.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { RecentSearchService } from 'src/app/services/shared/recent-search.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
import { MessagesService } from 'src/app/services/sitefinity/messages.service';
import { SitefinitypagesService } from 'src/app/services/sitefinity/sitefinitypages.service';
import { environment } from 'src/environments/environment';
import { IModalOneProduct } from '../modals/modal-one-product/modal-one-product.component';
import { WindowReferenceService } from 'src/app/services/shared/windows-reference.service';

declare var window: any;

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.css']
})
export class DetalleProductoComponent {
  private windowDataLayer: any;
  sku!: string;
  url!: string;
  product: any = " ";
  selectedUnits : string = 'Unidad';
  selectedQuantity: number = 1;
  selectedUnitPrice: number = 0;
  selectedPromotion: string = "";
  unitSelected: boolean = false;
  selectPresentation: number | any;
  userId!: any;
  isUserLoggedIn: boolean = false;
  settings: SitefinitySettings = new SitefinitySettings;
  cartId!: string;
  productInFavorites!: boolean;
  showPharmacies: boolean = false;
  modalPharmacies: any;
  urlSite = environment.urlSite;
  ifProductEarnsPoints: boolean = false;
  form!: FormGroup;
  isSubmited: boolean = false;
  validationMsg = this.settings.validationMsgTypeMap;
  numbers: number[] = [];
  urlBase= environment.urlBase;
  modalCupon: IModal = {
    ModalName: 'modalCuponDetail',
    ShowCancelBtn: false
  };
  showModalCupon: boolean = false;
  principalImage: string = '';
  displayQuantityComponent: boolean = false;
  quantityByComponent:any = 10
  isCuponApplie: boolean = false;
  productCartSelected: IProductCart | null = null;
  recommendedProduct: IProduct | null = null;
  showProduct: boolean = false;
  dynamic!: string;
  attributeDescription:string = " - ";
  modalPharmacie: any = {
    ModalName: 'modalViewPharmacie',
  };
  validateProductFavorites: boolean = false;
  SelectAttributeUnit = 0;
  visible: boolean = false;
  categories: any;
  category: any = null;
  validateCategory: boolean = false;
  subCategories: any[] = [];
  sharedlinks: any = {}
  private popStateListener: any;
  subCategory: any = null;

  constructor(
    private productService: ProductsService,
    private aRoute: ActivatedRoute,
    private utilitiesService: UtilitiesServicesService,
    private clienteService: ClientsService,
    private notificationService: NotificationService,
    private loader: LoadingService,
    private sitefinityPagesService : SitefinitypagesService,
    private fidelityService: FidelityService,
    private clientService: ClientsService,
    private sharedService: SharedService,
    private cartService: CartService,
    private fb: FormBuilder,
    private SearchService: RecentSearchService,
    private messageService: MessagesService,
    private mergeNService: MergeNService,
    private activatedroute: ActivatedRoute,
    private _windowRef: WindowReferenceService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.form = this.fb.group({
      cupon: ['', [Validators.required,Validators.minLength(3)]]
    });
    this.windowDataLayer = _windowRef.nativeWindow;
    this.aRoute.params.subscribe(params => {
      this.url = params['url'];
     // this.ngOnInit();
    });
  }

  get cupon() { return this.form.get('cupon')}

  ngOnInit(): void {
    this.popStateListener = this.onPopState.bind(this);
    window.addEventListener('popstate', this.popStateListener);
    //setee el titulo de la pagina
    this.sitefinityPagesService.setDetailPage(this.settings.pages.find(x => x.Title === 'detalle-producto')!.ID);
    if (!this.url || this.url === 'null' || this.url === '') {
      this.product = null;
      this.loader.hideSpinner();
      return;
    }
    else
    {
      const s = this.url.split('-');
      this.sku = s[s.length - 1];
    }

    this.getProductoDetail();
    this.validateUserLogin();
    this.saveClientRecentSearches();
    this.checkIfProductEarnsPoints();
  }

  closeModalCupon(event: any) {
    if (event == true) {
      this.showModalCupon = false;
      this.isSubmited = false;
      if(!this.isCuponApplie)
      {
        this.form = this.fb.group({
          cupon: ['',[Validators.required]],
        });
        this.form.updateValueAndValidity();
        this.getProductoDetail();
      }

      if(this.modalCupon.UrlBtnConfirm && this.modalCupon.UrlBtnConfirm.trim() != ''){
        window.open(this.modalCupon.UrlBtnConfirm.trim(), '_blank'); // Abre una nueva pestaña con la URL proporcionada
      }
    } else {
      this.showModalCupon = false;
    }
  }

  showModalPharmacies(): void {
    this.showPharmacies = true;
  }

  customOptions: any = {};

  getProductoDetail(): void {
    this.loader.showSpinner();
    this.productService.GetFullProductBySKUMethod(0,0,0,this.sku).subscribe((res: any) => {

      if (res.response.code === 'OK0001') {
        this.numbers = [];
        this.product = res.responseObject;
        this.getCategories();

        let image = this.product.ProductImages.length > 0 ? this.product.ProductImages[0].WebPath : '';
        this.sitefinityPagesService.setMetadataOpenGraph(this.product!.Name ?? '', this.product.MetaDescription ?? '', image, window.location.href);
        //META
       // this.sitefinityPagesService.setDataPage(this.product.Name ?? '', this.product.MetaDescription ?? '',  this.product.Keywords ?? '');
        this.dynamic = this.product?.ProductAttributes[0]?.Dynamic ? this.product?.ProductAttributes[0].Dynamic : this.product.Dynamic;

        if (this.product.MaxToPurchase > 10) {
          for (let i = this.product.MinToPurchase; i <= 9; i++) {
            this.numbers.push(i);
          }
        }
        else {
          for (let i = this.product.MinToPurchase; i <= this.product.MaxToPurchase; i++) {
            this.numbers.push(i);
          }
        }

        this.productsInFavorites();

        this.selectedQuantity = this.product.MinToPurchase;
        this.product.LargeDescription = this.formatText(this.product.LargeDescription ?? '');
        this.product.Detail = this.formatText(this.product.Detail ?? '');
        this.product.Components = this.formatText(this.product.Components ?? '');
        this.product.Mechanics = this.formatText(this.product.Mechanics ?? '');
        this.product.ShortDescription =  this.formatText(this.product.ShortDescription ?? '');
        this.principalImage = this.product.ProductImages[0].WebPath

        if (this.product.ProductImages.length > 1) {
          this.product.carrusel =  this.product.ProductImages;
        }

        if (this.product.BasePrice == 0) {
          if (this.product.ProductAttributes.length > 0) {

            this.selectedUnits = this.product.ProductAttributes[0].Name;
            this.unitSelected = this.product.ProductAttributes[0].Name === 'Caja';
            if (this.product.ProductAttributes && this.product.ProductAttributes.length > 0) {
              if (this.selectedUnits === 'Unidad') {
                if (this.product.ProductAttributes[0].Description != "") {
                  this.attributeDescription =   this.product.ProductAttributes[0].Description;
                }
                else {
                  this.attributeDescription = "";
                }

                this.selectedUnitPrice = this.product.ProductAttributes[0].PriceWithDiscount ? this.product.ProductAttributes[0].PriceWithDiscount : this.product.ProductAttributes[0].PriceWithTaxes;
                this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
              }
              else if (this.selectedUnits === 'Caja') {
                if (this.product.ProductAttributes[0].PriceWithDiscount > 0 || this.product.ProductAttributes[1]?.PriceWithDiscount > 0) {
                  if(this.product.ProductAttributes[0].Description != "") {
                    this.attributeDescription =    this.product.ProductAttributes[0].Description;
                  }
                  else {
                    this.attributeDescription = "";
                  }

                  this.selectedUnitPrice = this.product.ProductAttributes[0].PriceWithDiscount ? this.product.ProductAttributes[0].PriceWithDiscount : this.product.ProductAttributes[1]?.PriceWithDiscount;
                  this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
                }
                else {
                  if (this.product.ProductAttributes[0].Description != "") {
                    this.attributeDescription =   this.product.ProductAttributes[0].Description;
                  }
                  else {
                    this.attributeDescription = "";
                  }

                  this.selectedUnitPrice = this.product.ProductAttributes[0].PriceWithTaxes ? this.product.ProductAttributes[0].PriceWithTaxes : this.product.ProductAttributes[1]?.PriceWithTaxes;
                  this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
                }
              }
            }
            else {
              this.selectedUnitPrice = 0; // Establece un valor predeterminado
              this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
            }
            if (this.product.MinToPurchase > 1) {
              if (this.selectedUnits === 'Caja') {
                this.selectedUnits = 'Cajas';
              }
              else {
                this.selectedUnits = 'Unidades';
              }
            }
          }
        }
        else {
          if (this.product.ProductAttributes.length > 0) {
            this.selectedUnits = this.product.ProductAttributes[0].Name;
            this.unitSelected = this.product.ProductAttributes[0].Name === 'Caja';
            if (this.product.ProductAttributes && this.product.ProductAttributes.length > 0) {
              if (this.selectedUnits === 'Unidad') {
                if (this.product.ProductAttributes[0].Description != "") {
                  this.attributeDescription =    this.product.ProductAttributes[0].Description;
                }
                else {
                  this.attributeDescription = "";
                }

                this.selectedUnitPrice = this.product.ProductAttributes[0].PriceWithDiscount ? this.product.ProductAttributes[0].PriceWithDiscount : this.product.ProductAttributes[0].PriceWithTaxes;
                this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
              }
              else if (this.selectedUnits === 'Caja') {
                if (this.product.ProductAttributes[0].PriceWithDiscount > 0 || this.product.ProductAttributes[1]?.PriceWithDiscount > 0) {
                  if (this.product.ProductAttributes[0].Description != "") {
                    this.attributeDescription =    this.product.ProductAttributes[0].Description;
                  }
                  else {
                    this.attributeDescription = "";
                  }

                  this.selectedUnitPrice = this.product.ProductAttributes[0].PriceWithDiscount ? this.product.ProductAttributes[0].PriceWithDiscount : this.product.ProductAttributes[1]?.PriceWithDiscount;
                  this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
                }
                else {
                  if (this.product.ProductAttributes[0].Description != "") {
                    this.attributeDescription =   this.product.ProductAttributes[0].Description;
                  }
                  else {
                    this.attributeDescription = "";
                  }
                  this.selectedUnitPrice = this.product.ProductAttributes[0].PriceWithTaxes ? this.product.ProductAttributes[0].PriceWithTaxes : this.product.ProductAttributes[1]?.PriceWithTaxes;
                  this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
                }
              }
            }
            else {
              this.attributeDescription = "";
              this.selectedUnitPrice = 0; // Establece un valor predeterminado
              this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
            }
            if(this.product.MinToPurchase > 1) {
              if (this.selectedUnits === 'Caja') {
                this.selectedUnits = 'Cajas';
              }
              else {
                this.selectedUnits = 'Unidades';
              }
            }
          }
          else {
              this.selectedUnits = 'Unidad';
              this.attributeDescription =  "";
              this.selectedUnitPrice = this.product.PriceWithDiscount ? this.product.PriceWithDiscount : this.product.PriceWithTaxes;
              this.selectedUnitPrice =  this.selectedUnitPrice  * this.product.MinToPurchase;
              this.unitSelected = false;
              this.loader.hideSpinner();
          }
        }

        if (this.product.ProductAttributes) {
          this.selectPresentation = 0;
        }

        if (this.product.PercentDiscountRate > 0) {
          if (this.product.PriceWithDiscount > 0) {
            this.selectedPromotion = this.product.PercentDiscountRate + "%"
          }
          else {
            if (this.product.ProductAttributes.length > 0) {
              let currentAttribute = this.product.ProductAttributes.filter((x:any)=>this.selectedUnits.startsWith(x.Name))[0];
              if (this.selectedUnits.startsWith('Unidad')) {
                if (currentAttribute.PriceWithDiscount > 0) {
                  this.selectedPromotion = this.product.PercentDiscountRate + "%"
                }
                else {
                  if (currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                    this.selectedPromotion = currentAttribute.Dynamic;
                  }
                  else {
                    this.selectedPromotion = "";
                  }
                }
              }
              else {
                if (this.selectedUnits.startsWith('Caja')) {
                  if (currentAttribute.PriceWithDiscount > 0) {
                    this.selectedPromotion = this.product.PercentDiscountRate + "%"
                  }
                  else {
                    if (currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                      this.selectedPromotion = currentAttribute.Dynamic;
                    }
                    else {
                      this.selectedPromotion = "";
                    }
                  }
                }
                else {
                  this.selectedPromotion = "";
                }
              }
            }
            else {
              if (this.product.Dynamic && this.product.Dynamic != "") {
                this.selectedPromotion = this.product.Dynamic;
              }
              else {
                this.selectedPromotion = "";
              }
            }
          }
        }
        else {
          if (this.product.ProductAttributes.length > 0) {
            let currentAttribute = this.product.ProductAttributes.filter((x:any)=>this.selectedUnits.startsWith(x.Name))[0];
            if (currentAttribute.Dynamic || currentAttribute.Dynamic != "") {
              this.selectedPromotion = currentAttribute.Dynamic;
            }
            else {
              this.selectedPromotion = "";
            }
          }
          else {
            if (this.product.Dynamic || this.product.Dynamic != "") {
              this.selectedPromotion = this.product.Dynamic;
            }
            else {
              this.selectedPromotion = "";
            }
          }
        }

        let listCategories = "";
        if(this.product){
          if(this.product!.Categories){
            if(this.product!.Categories.length>0){
              listCategories = this.product!.Categories.map((x: { Name: any; })=>x.Name).join(',');
            }
          }
        }

        this.windowDataLayer.dataLayer.push({
          'event' : 'view_item',
          'currency': 'CRC',
          'items' : [{
            'item_name' : '' + this.product!.Name,
            'item_category' : listCategories,
            'price': this.selectedUnitPrice,
            'item_id' : this.product.SKU,
            'quantity': this.selectedQuantity
            }]
        });

        this.loadDynamicScript(this.product.SKU);
        this.sharedLinksMethod(this.product);
        this.sendData(this.product);

      }
      else {
        this.product = null;
      }
      this.SearchService.SaveRecentSearch('', 0, this.product.IDProduct);
      this.loader.hideSpinner();
    });
  }

  goToCategory(category: any) {
    let url: any = this.categories.filter((c: { Name: any; }) => c.Name === category.Name);
    if (url.length > 0) {
      this.router.navigate(['/categorias', url[0].URL]);
    }
    else {
      let url = category.Name.toLowerCase().replace(/\s+/g, '-') + '-' + category.IDProductCategory;
      let categoryName = this.category.Name;
      let idCategory = this.category.IDProductCategory;
      this.router.navigate(['/categorias', url], {state: {categoryName, idCategory}});
    }
  }

  getCategories() {
    this.productService.GetCategoriesHeaderMethod(0).subscribe(response => {
      if (response.response.code == "OK0001") {
        this.categories = response.categories;
        let categoryIdSelected = this.utilitiesService.getValueStorage("categoryIdSelected");

        for (let p of this.product.Categories) {
          for (let c of this.categories) {
            if(p.Name != "Descuentos"){
              if ((categoryIdSelected === c.IDProductCategory) && !this.validateCategory) {
                this.category = c;
                this.validateCategory = true;
              }
            }
          }
        }
        this.getSubcategory();
      }
    });
  }

  getSubcategory() {
    if (this.category != null) {
      this.productService.GetCategoriesHeaderMethod(this.category.IDProductCategory).subscribe((cat) => {
        for (let p of this.product.Categories) {
          this.subCategory = cat.categories.find(s => s.IDProductCategory === p.IDProductCategory);
          if (this.subCategory) {
            this.visible = true;
            break;
          }
        }
      });
    }
    else
    {
      let categoryIdSelected = this.utilitiesService.getValueStorage("categoryIdSelected");
      this.productService.GetCategoriesHeaderMethod(categoryIdSelected).subscribe((cat) => {
        for (let p of this.product.Categories) {
          this.subCategory = cat.categories.find(s => s.IDProductCategory === p.IDProductCategory);
          if (this.subCategory) {
            this.visible = true;
            break;
          }
        }
      });



    }
  }

  async loadDynamicScript(sku: string): Promise<void> {
    if(sku != null && sku != "") {
      const existingScript = document.getElementById("2");
        if (existingScript) {
          existingScript.remove();
      }
      const scriptElement = document.createElement('script');
      scriptElement.src = '//546007595.collect.igodigital.com/collect.js';
      scriptElement.id = "2";
      scriptElement.innerHTML = `
        _etmc.push(["setOrgId", "546007595"]);
        _etmc.push(["trackPageView", { "item" : "${sku}" }]);
      `;
      document.body.appendChild(scriptElement);
    }
  }

  selectUnit(unit: string): void {
    this.selectedUnits = unit;
    // Llama a la función para recalcular el precio cuando se cambia la unidad
    this.calculateTotalPrice();
  }

  selectQuantity(quantity: number): void {
    this.selectedQuantity = quantity;
    // Calcula el precio total aquí (dependiendo de la cantidad y la unidad seleccionada)
    this.selectType(this.selectedUnits,this.selectPresentation);
    this.calculateTotalPrice();
  }

  calculateTotalPrice(): void {
    const product = this.product;
    const quantity = this.selectedQuantity;
    if (product.ProductAttributes.length > 0 && (this.selectedUnits === 'Unidad' || this.selectedUnits === 'Unidades')) {
      if (this.product.ProductAttributes[0].Description != "") {
        this.attributeDescription =    this.product.ProductAttributes[0].Description;
      }
      else {
        this.attributeDescription = "";
      }
      this.selectedUnitPrice = product.ProductAttributes[0].PriceWithDiscount ? product.ProductAttributes[0].PriceWithDiscount * quantity : product.ProductAttributes[0].PriceWithTaxes * quantity;
    }
    else if (this.selectedUnits === 'Caja' || this.selectedUnits === 'Cajas' && product.ProductAttributes) {
      if (product.ProductAttributes.length > 1){
        if (this.product.ProductAttributes[1].Description != "") {
          this.attributeDescription =   this.product.ProductAttributes[1].Description;
        }
        else {
          this.attributeDescription = "";
        }

        this.selectedUnitPrice = product.ProductAttributes[1]?.PriceWithDiscount ? product.ProductAttributes[1]?.PriceWithDiscount * quantity : product.ProductAttributes[1]?.PriceWithTaxes * quantity;
      }
      else {
        if (this.product.ProductAttributes[0].Description != "") {
          this.attributeDescription =    this.product.ProductAttributes[0].Description;
        }
        else {
          this.attributeDescription = "";
        }
        this.selectedUnitPrice = product.ProductAttributes[0]?.PriceWithDiscount ? product.ProductAttributes[0]?.PriceWithDiscount * quantity : product.ProductAttributes[0]?.PriceWithTaxes * quantity;
      }
    }
    else {
      this.attributeDescription = "";
      this.selectedUnitPrice = product.PriceWithDiscount ? product.PriceWithDiscount * quantity : product.PriceWithTaxes * quantity;
    }

    if (this.product.PercentDiscountRate > 0) {
      if (this.product.PriceWithDiscount > 0) {
        this.selectedPromotion = this.product.PercentDiscountRate + "%"
      }
      else {
        if (this.product.ProductAttributes.length>0) {
          let currentAttribute = this.product.ProductAttributes.filter((x:any)=>this.selectedUnits.startsWith(x.Name))[0];
          if (this.selectedUnits.startsWith('Unidad')) {
            if (currentAttribute.PriceWithDiscount > 0) {
              this.selectedPromotion = this.product.PercentDiscountRate + "%"
            }
            else {
              if (currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                this.selectedPromotion = currentAttribute.Dynamic;
              }
              else {
                this.selectedPromotion = "";
              }
            }
          }
          else {
            if (this.selectedUnits.startsWith('Caja')) {
              if (currentAttribute.PriceWithDiscount > 0) {
                this.selectedPromotion = this.product.PercentDiscountRate + "%"
              }
              else {
                if (currentAttribute.Dynamic && currentAttribute.Dynamic != "") {
                  this.selectedPromotion = currentAttribute.Dynamic;
                }
                else {
                  this.selectedPromotion = "";
                }
              }
            }
            else{
              this.selectedPromotion = "";
            }
          }
        }
        else {
          if (this.product.Dynamic && this.product.Dynamic != "") {
            this.selectedPromotion = this.product.Dynamic;
          }
          else {
            this.selectedPromotion = "";
          }
        }
      }
    }
    else {
      if (this.product.ProductAttributes.length > 0) {
        let currentAttribute = this.product.ProductAttributes.filter((x:any)=>this.selectedUnits.startsWith(x.Name))[0];
        if (currentAttribute.Dynamic || currentAttribute.Dynamic != "") {
          this.selectedPromotion = currentAttribute.Dynamic;
        }
        else {
          this.selectedPromotion = "";
        }
      }
      else {
        if (this.product.Dynamic || this.product.Dynamic != "") {
          this.selectedPromotion = this.product.Dynamic;
        }
        else {
          this.selectedPromotion = "";
        }
      }
    }
  }

  validateOption(): boolean {
    return !(this.product?.ProductAttributes && this.product?.ProductAttributes.length === 1 && this.product?.ProductAttributes[0].Name === 'Unidad');
  }

  async validateUserLogin(): Promise<boolean> {
    this.isUserLoggedIn = this.utilitiesService.isUserLoggedIn();
    if (this.isUserLoggedIn) {
      const user: any = this.utilitiesService.getValueStorage('sessionStorage');
      this.userId = await this.utilitiesService.decryptData(user.i);
      this.cartId = await this.utilitiesService.decryptData(user.c);
    }
    return this.isUserLoggedIn;
  }

  addFavoriteProduct(product: any): void {
    this.productInFavorites = true;
    this.loader.showSpinner();
    const requestProduct: RequestFavoriteProduct = {
      AddToQueue: true,
      IdClient:this.userId,
      Quantity:product.Quantity,
      Name:product.Name,
      IdProduct:product.IDProduct,
      Attributes:product.ProductAttributes,
      Presentation: this.selectedUnits
    };

    var object: RequestFavoriteProduct[]= [];
    object.push(requestProduct);

    this.clienteService.AddClientFavoriteProductMethod(object).subscribe(res => {
      if (res.response.code === 'addfavoriteProduct-success') {
        this.getProductoDetail();
      }

      this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
      this.loader.hideSpinner();
    });
  }

  closeModalOneProduct(value: IModalOneProduct) {
    if (value.continue) {
      // Continua con el mismo producto
      this.addProductToCart(this.productCartSelected!.product, this.productCartSelected!.unitSelected, this.productCartSelected!.quantity, this.productCartSelected!.price);
    } else {
      // Redirecciona al detalle del producto recomendado y recargo el componente
      window.location = `/detalle-producto/${this.recommendedProduct!.URL}`;
    }
    this.showProduct = false;
    this.productCartSelected = null;
    this.recommendedProduct = null;
  }

  showRecommendedProduct(product: IProduct, unitSelected: string, quantity: number, price: number) {

    if (this.selectedUnits !== undefined) {
      switch (this.selectedUnits) {
        case 'Unidades':
        case 'Unidad':
          this.selectedUnits = 'Unidad';
          break;
        case 'Cajas':
        case 'Caja':
          this.selectedUnits = 'Caja';
          break;
        // Agrega más casos según sea necesario
        // ...
        default:
          // Mantén el valor original si no coincide con ninguno de los casos anteriores
          break;
      }
    }
    else {
      this.selectedUnits = 'Unidad'
    }

    this.SelectAttributeUnit = this.selectedUnits === 'Caja' && this.product.ProductAttributes.length > 1 ? 1 : 0;



var atrribute =  product.ProductAttributes.find(o=>o.Name == this.selectedUnits);
var skuValue = atrribute ? atrribute.SKU : product.SKU;

    this.loader.showSpinner();
    this.productCartSelected = null;
    this.productCartSelected = {
      product: product,
      unitSelected: unitSelected,
      quantity: quantity,
      price: price
    };
    this.mergeNService.GetMoleculeMethod(skuValue).subscribe(res => {
      this.showProduct = false;
      this.recommendedProduct = null;
      if (res.responseObject.length > 0) {
        this.recommendedProduct = res.responseObject[0];
        this.showProduct = true;
        this.loader.hideSpinner();
      } else {
        this.addProductToCart(product, unitSelected, quantity, price);
      }
    })
  }
  async addProductToCart(product: any, unitSelected: any, quantity:number, price: number): Promise<void> {
    if (product) {

      this.loader.showSpinner();
      this.sharedService.addProductsToCartV2(product, unitSelected, quantity, price,this.form.value.cupon,this.isCuponApplie);

      if(this.loader.getSpinner()){
        this.loader.showSpinner();
      }
      this.isCuponApplie = false;
      this.form.get('cupon')?.enable();
      this.form = this.fb.group({
        cupon: ['',[Validators.required]],
      });
      this.form.updateValueAndValidity();
      this.getProductoDetail();
      this.quantityByComponent = 10; // O el valor predeterminado que desees
    }

  }

  checkIfProductEarnsPoints(): void{
    var object :any = {
      sku :this.sku,
    };
    this.fidelityService.CheckIfProductEarnsPoints(object).subscribe(resFidelity => {
      this.ifProductEarnsPoints = resFidelity;
    });
  }

  productsInFavorites(): void {
    if(this.isUserLoggedIn) {
      let user: any = this.utilitiesService.getValueStorage('sessionStorage');
      let token = this.utilitiesService.decryptData(user.t);
      this.clientService.GetFavoritesProductsMethod(token).subscribe(res => {
        if (res.response.code === "OK0001") {
          let productExist = res.products.find(p => p.IDProduct === this.product?.IDProduct);
          if (productExist) {
            this.productInFavorites = true;
          }
          else {
            this.productInFavorites = false;
          }
          this.validateProductFavorites = true;
        }
        else {
          this.validateProductFavorites = true;
        }
      });
    }
  }

  async saveClientRecentSearches(): Promise<void> {
    if (this.isUserLoggedIn) {
      const user: any = await this.utilitiesService.getValueStorage('sessionStorage');
      const userId: any = await this.utilitiesService.decryptData(user.i);

      const defaultRecentSearchRequest: RecentSearchRequest = {
        Argument: "acetominafen",
        IDClient: parseInt(userId),
        IDProduct: null,
        IDProductCtegory: 0,
        IPAddress: "::1",
        BrowserName: "Chrome",
        BrowserVersion: "116.0.0.0",
        OperatingSystem: "Windows",
        DeviceType: "Desktop Machine",
        IsMobileBrowser: "0",
        UserAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
        IsToCount: false,
      };
      this.productService.SaveRecentClientSearchesMethod(defaultRecentSearchRequest);
    }
  }

  addCupon(): void {
    this.modalCupon  = {
      ModalName: 'modalCuponDetail',
      ShowCancelBtn: false
    };
    this.showModalCupon = false;

    if (this.form.invalid) {
      this.isSubmited = true;
    }
    else {
      if (this.isCuponApplie) {
        this.messageService.getMessagesMethod('CDEL', '1').subscribe(m => {
          this.showModalCupon = true;
          this.isCuponApplie = false;
          this.modalCupon.Title = m.titleMessage;
          this.modalCupon.TextSmall = m.message;
          this.form.get('cupon')?.enable();
          this.form = this.fb.group({
            cupon: ['', [Validators.required]]
          });
        });
      }
      else {
        this.loader.showSpinner();
        this.cartService.ValidateAddGiftCertificateCuponToCartMethod(
                  this.cartId, this.form.value.cupon,
                  this.product.ProductAttributes.length>0?this.product.ProductAttributes[this.selectPresentation].SKU:this.product.SKU,
                  this.product.IDProduct,
                  this.product.ProductAttributes.length>0?this.product.ProductAttributes[this.selectPresentation].IDProductAttribute:0,
                  this.selectedQuantity.toString()).subscribe(res => {
          if (res.response.code === "CAPP") {
            this.form.get('cupon')?.disable();
            this.selectedUnitPrice = res.responseObject.ProductPriceWithDiscount;
            if(this.product.ProductAttributes.length>0){
              this.product.ProductAttributes[this.selectPresentation].PriceWithDiscount = res.responseObject.ProductPriceWithDiscount / this.selectedQuantity;
            }
            else{
              this.product.PriceWithDiscount = res.responseObject.ProductPriceWithDiscount / this.selectedQuantity;
            }

            if (res.responseObject.DisplayDiscount)
            {
              this.product.PercentDiscountRate = res.responseObject.Discount;
            }
            this.selectedPromotion = res.responseObject.Discount + "%";

            this.modalCupon.Title = res.response.titleMessage;
            this.modalCupon.TextSmall = res.response.message;
            this.showModalCupon = true;
            this.isCuponApplie = true;
          }
          else {
            this.modalCupon.Title = res.response.titleMessage;
            this.modalCupon.TextSmall = res.response.message;
            this.showModalCupon = true;
            this.isCuponApplie = false;
            this.form.get('cupon')?.enable();
            this.form = this.fb.group({
              cupon: ['', [Validators.required]]
            });
          }
        });
        setTimeout(() => {
          this.loader.hideSpinner();
        }, 1000);
      }
    }
  }

  addCuponValidateType(indexPresentation:number): void {
    this.modalCupon  = {
      ModalName: 'modalCuponDetail',
      ShowCancelBtn: false
    };
    this.showModalCupon = false;

    this.loader.showSpinner();
    this.cartService.ValidateAddGiftCertificateCuponToCartMethod(
              this.cartId, this.form.value.cupon,
              this.product.ProductAttributes.length>0?this.product.ProductAttributes[indexPresentation].SKU:this.product.SKU,
              this.product.IDProduct,
              this.product.ProductAttributes.length>0?this.product.ProductAttributes[indexPresentation].IDProductAttribute:0,
              this.selectedQuantity.toString()).subscribe(res => {
      if (res.response.code === "CAPP") {
        this.form.get('cupon')?.disable();
        this.selectedUnitPrice = res.responseObject.ProductPriceWithDiscount;
        if(this.product.ProductAttributes.length>0){
          this.product.ProductAttributes[indexPresentation].PriceWithDiscount = res.responseObject.ProductPriceWithDiscount / this.selectedQuantity;
        }
        else{
          this.product.PriceWithDiscount = res.responseObject.ProductPriceWithDiscount / this.selectedQuantity;
        }

        if (res.responseObject.DisplayDiscount)
        {
          this.product.PercentDiscountRate = res.responseObject.Discount;
        }
        this.selectedPromotion = res.responseObject.Discount + "%";
      }
      else {
        this.modalCupon.Title = res.response.titleMessage;
        this.modalCupon.TextSmall = res.response.message;
        this.showModalCupon = true;
        this.isCuponApplie = false;
        this.form.get('cupon')?.enable();
        this.form = this.fb.group({
          cupon: ['', [Validators.required]]
        });
      }
    });
    setTimeout(() => {
      this.loader.hideSpinner();
    }, 1000);
  }

 getRandomArbitrary(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  closeModalPharmacie(event: boolean): void {
    this.showPharmacies = false;
  }

  PutImageAsPrincipal(urlImage: string): void {
    this.principalImage = urlImage;
}

getPluralizedName(itemName: string, quantity: number): string {
  if (itemName.toLowerCase() === 'unidad') {
    return quantity > 1 ? 'Unidades' : 'Unidad';
  } else if (itemName.toLowerCase() === 'caja') {
    return quantity > 1 ? 'Cajas' : 'Caja';
  } else {
    // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
    return itemName;
  }
}

selectType(itemName: string, indexPresentation:any|number): string {
  if(indexPresentation!=null){
    this.selectPresentation = indexPresentation;
    if(this.isCuponApplie){
      this.addCuponValidateType(indexPresentation);
    }
  }

  if (itemName.toLowerCase() === 'unidad' ||  itemName.toLowerCase() === 'unidades' ) {
    this.selectedUnits = this.selectedQuantity > 1 ? 'Unidades' : 'Unidad';
    this.calculateTotalPrice();
    return this.selectedUnits
  } else if (itemName.toLowerCase() === 'caja' ||  itemName.toLowerCase() === 'cajas') {
    this.selectedUnits = this.selectedQuantity > 1 ? 'Cajas' : 'Caja';
    this.calculateTotalPrice();
    return  this.selectedUnits
  } else {
    this.selectedUnits = itemName;
    this.calculateTotalPrice();
    // Aquí puedes añadir más lógica para otros tipos de nombres si es necesario
    return this.selectedUnits;
  }
}

openQuantityComponent(value:any)  {
  this.quantityByComponent =  this.validateQuantityZero(this.quantityByComponent)
  if(value == false)
  {
    this.selectedQuantity = this.quantityByComponent;
    this.selectType(this.selectedUnits,this.selectPresentation);
  }
  this.displayQuantityComponent = value;
}

validateNumber(event: any): void {
  const inputChar =  event.data;
  // Si es 'e', 'E', '+' o cualquier carácter no numérico, no permitas la entrada
  if ((inputChar === 'e' || inputChar === 'E' || inputChar === '+' || isNaN(parseInt(inputChar, 10))) && !event.ctrlKey) {
    // Prevent default para evitar que el valor sea introducido
    event.preventDefault();
  }
}

  validateQuantityZero(quantity: any) {
    if(quantity <= 0 ) {
      return this.product.MinToPurchase;
    }
    if (quantity < this.product.MinToPurchase) {
      return this.product.MinToPurchase;
    }
    if (quantity > this.product.MaxToPurchase) {
      return this.product.MaxToPurchase;
    }
    return quantity;
  }

  formatText(text: string): string {
    return text.replace(/\n/g, '<br>');
  }


  sharedLinksMethod(p:any)
  {

    let urltw = window.location.href+"?utm_source=x&utm_content="+p.SKU ;
    let urlfb = window.location.href+"?utm_source=facebook&utm_content="+p.SKU ;
    let urlemail = window.location.href+"?utm_source=email&utm_content="+p.SKU ;
    let urlwa = window.location.href+"?utm_source=whatsapp&utm_content="+p.SKU ;



    let text = "Hola, quería compartirles el producto: " + p.Name + "%20"+ urlemail;
      //let textTwitter = "Hola, quería compartirles el producto: " + p.Name + "&amp;url="+ url;

    const urlEncoded: string = this.encodeURL(text);
    const urlEncodedfb: string = this.encodeURL(urlfb);
    const urlEncodedtwitter: string = this.encodeURL(urltw);

    let subject =  p.Name+ "%20";



    let twitter = "Hola+,+quería+compartirles+el+producto:" + p.Name +"&url=" + urlEncodedtwitter
    let facebook =  urlEncodedfb;
    let wa =  "Hola, quería compartirles el producto: " + p.Name + ": "+ this.encodeURL(urlwa) +""


    this.sharedlinks.xLink = "https://twitter.com/intent/tweet?text="+twitter;

    this.sharedlinks.fbLink = "https://www.facebook.com/sharer/sharer.php?u="+facebook
    this.sharedlinks.waLink = "https://api.whatsapp.com/send?text="+ wa;
    this.sharedlinks.mailToLink = "mailto:?subject="+subject+"&body="+urlEncoded;



  }

    encodeURL(text: string): string {
    return encodeURIComponent(text);
  }


ngOnDestroy() {
  window.removeEventListener('popstate', this.popStateListener);
}

onPopState(event: PopStateEvent) {
  this.ngZone.run(() => {
    // Maneja la lógica cuando el usuario presiona el botón "Atrás"
    this.utilitiesService.saveValueStorage("true", "isButtonBackPush");


  });
}





sendData(product:any)
{


  const fullUrl: string = window.location.href;


  let Payload: any = {
    item: ''+ product.Name,
    unique_id: '' + product.IDProduct,
    item_type: 'product',
    name: '' + product.Name,
    url: '' + fullUrl,
    CATEG1: '' +  product.Categories[0].Name,
    SkuID: ''+ product.SKU,
  };

  let example: any = {

    api_key:'cc3d27fc-fd07-11ee-bdcc-ce5728e59bd3',
    payload:Payload


  }

this.utilitiesService.SendDataToMarketingCloud(example).subscribe(o=>{

});
}
}

