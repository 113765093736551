import { Injectable } from '@angular/core';
import { MessagesService } from '../sitefinity/messages.service';
import { UtilitiesService } from '../core/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private showError: boolean = false;
  private _messageService: MessagesService

  // showError = false;
  message = 'Datos incorrectos';
  title = 'Datos incorrectos';
  type = 'Datos incorrectos';
  pushClass = 'push-error';
  iconClass = 'icon-push-error';
  iconBgClass = 'toast-icon-error';

  constructor(MessagesService: MessagesService, public utilities : UtilitiesService) {
    this._messageService = MessagesService
  }

  SendNotification(notificationType: string, title: string, message: string) {
    this.showError = true;
    this.title = title;
    this.message = message;
    this.type = notificationType;

    if(this.type == 'warning'){
      this.type = 'alert';
    }

    if (this.type != 'success' && this.type != 'alert' && this.type != 'error')
      this.type = 'error';
    this.pushClass = 'push-' + this.type;
    this.iconBgClass = 'toast-icon-' + this.type;
    //this.iconClass = 'icon-push-' + this.type

    switch (this.type) {
      case 'error':
        this.iconClass = 'bi-x-circle icon-push-error';
        break;
        case 'alert':
        this.iconClass = 'bi-exclamation-triangle icon-push-alert';
        break;
      case 'success':
        this.iconClass = 'bi-check-circle icon-push-success';
        break;
    }
  }

  SendNotificationByCode(code: string, sectionNumber: string) {
    this._messageService.getMessagesMethod(code, sectionNumber).subscribe((o) => {
      this.SendNotification(o.type, o.titleMessage, o.message);
    });
  }

  Hide() {
    this.showError = false;
  }

  GetShowError(): boolean {
    return this.showError;
  }
  GetMessage(): string {
    return this.utilities.decodeData(this.message);
  }
  GetTitle(): string {
    let text = '';
    if (this.title != undefined && this.title.trim() != '') {
      text = this.title;
    } else {
      switch (this.type) {
        case 'error':
          text = 'Error';
          break;
        case 'alert':
          text = 'Atención';
          break;
        case 'success':
          text = 'Correcto';
          break;
      }
    }
    return this.utilities.decodeData(text);
  }
  GetPushClass(): string {
    return this.pushClass;
  }
  GetIconClass(): string {
    return this.iconClass;
  }
  GetIconBgClass(): string {
    return this.iconBgClass;
  }
  GetType(): string {
    return this.type;
  }
}
