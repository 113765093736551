import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
declare var window: any;
@Component({
  selector: 'app-modal-succes',
  templateUrl: './modal-succes.component.html',
  styleUrls: ['./modal-succes.component.css']
})
export class ModalSuccesComponent implements OnInit, OnChanges {

  @Input() showModal: boolean = false;
  @Input() titulo: string = '';
  @Input() mensaje: string = '';
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;

  ngOnInit(): void {
    this.modal = new window.bootstrap.Modal(
      document.getElementById('modalOk')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.modal.show();
    }
  }

  accept() {
    this.modal.hide();
    this.showModalChange.emit(false);
  }
}