
<main class="main ">
  <div class="container  mt-3 mt-lg-5 px-4">
      <div class="">

          <h1 class="mb-3 fw-medium color-primary">¿Estás seguro de que deseás darte de baja?</h1>
          <h2 class="mb-3">Recordá que <span class="fw-medium text-unsubscribe">dejarías</span> de disfrutar de beneficios como:</h2>
          <div class="d-flex">
              <i class="bi bi-arrow-right-circle icon-unsubscribe"></i>
              <h3 class="mb-2">Compras por medio de nuestro Sitio Web, Aplicación y medios digitales.</h3>
          </div>
          <div class="d-flex">
              <i class="bi bi-arrow-right-circle icon-unsubscribe"></i>
              <h3 class="text-start mb-2">Acumulación de puntos por medio de nuestro Plan de Lealtad La Bomba Conmigo con el que podés realizar canjes en productos y servicios.</h3>
          </div>
          <div class="d-flex">
              <i class="bi bi-arrow-right-circle icon-unsubscribe"></i>
              <h3 class="mb-2">Promociones y descuentos.</h3>
          </div>
          <div class="d-flex">
              <i class="bi bi-arrow-right-circle icon-unsubscribe"></i>
              <h3 class="mb-2">Eventos exclusivos.</h3>
          </div>
          <div class="d-flex">
              <i class="bi bi-arrow-right-circle icon-unsubscribe"></i>
              <h3 class="mb-2">Comunicaciones e información de interés.</h3>
          </div>
          <h2 class="my-3">Si aún deseás hacerlo, por favor envianos un correo por medio del formulario <a (click)="redirectToContactUs(4)" routerLink="/contactanos" class="link-unsubscribe">Contactanos</a> con la
              opción de <span class="fw-medium text-unsubscribe">Des-inscripción La Bomba Digital</span>.</h2>

              <h2>El proceso de desactivación tarda un periodo de <span class="fw-medium text-unsubscribe">48 horas hábiles</span> para ser aplicado.</h2>

      </div>
  </div>
</main>
