import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IModal } from 'src/app/models/modal.model';
import { UtilitiesService } from 'src/app/services/core/utilities.service';

declare var window: any;

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.css']
})
export class ModalErrorComponent implements OnInit, OnChanges {

  @Input() showModal: boolean = false;
  @Input() modalData: IModal = {
    ModalName: 'modalErrorGeneral',
  };
  @Input() titulo?: string = '';
  @Input() mensaje?: string = '';
  @Input() codError?: string = '';
  @Output() showModalChange = new EventEmitter<boolean>();
  modal: any;

  ngOnInit(): void {
    this.modal = new window.bootstrap.Modal(
      document.getElementById('modalError')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.titulo = this.modalData.Title;

    this.mensaje = this.modalData.Text;

    this.codError = this.modalData.codError;

    if (!this.modal && document.getElementById(this.modalData.ModalName)) {
      this.modal = new window.bootstrap.Modal(
        document.getElementById(this.modalData.ModalName)
      );
    }
    if (changes.showModal && changes.showModal.currentValue && this.modal) {
      this.modal.show();
    }
  }

  accept() {
    this.modal.hide();
    this.showModalChange.emit(false);
  }
  Close() {
    this.modal.hide();
    this.showModalChange.emit(false);
  }
}
