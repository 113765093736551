import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse ,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SitefinitySettings } from '../../models/settings/sitefinity-settings.model'

const settings = new SitefinitySettings();
@Injectable({
  providedIn: 'root'
})

export class DatalakeService {

  constructor(
    private http: HttpClient
  ) { }

  InsertDatalakeCartProductEcommerceMethod(request: any): Observable<any> {
    const url = `${settings.CoreServicesUrl}Product.svc/InsertDatalakeCartProductEcommerce`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = JSON.stringify(request);

    return this.http.post<any>(url, body, { headers });
  }

public InsertDatalakeLoginEcommerceMethod(object:any):Observable<any>
{
  const url = settings.CoreServicesUrl + 'User.svc/InsertDatalakeLoginEcommerce';
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  var json =  JSON.stringify(object);

  return  this.http.post(url,json,{ headers });
}

}