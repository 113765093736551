import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICanton } from 'src/app/models/canton.model';
import { CartAddress } from 'src/app/models/core/cart.model';
import { IMakerAccount } from 'src/app/models/maker.model';
import { IModal } from 'src/app/models/modal.model';
import { IProvince } from 'src/app/models/provinces.model';
import { SitefinitySettings } from 'src/app/models/settings/sitefinity-settings.model';
import { CartService } from 'src/app/services/core/cart.service';
import { ClientsService } from 'src/app/services/core/clients.service';
import { UtilitiesService } from 'src/app/services/core/utilities.service';
import { LoadingService } from 'src/app/services/shared/loading.service';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilitiesServicesService } from 'src/app/services/shared/utilities-services.service';
declare var window: any;

@Component({
  selector: 'app-modal-direction',
  templateUrl: './modal-direction.component.html',
  styleUrls: ['./modal-direction.component.css']
})
export class ModalDirectionComponent implements OnInit, OnChanges {
  @Input() latLng: { lat: number; lng: number } = { lat: 0, lng: 0 };
  @Input() showModalDirection: boolean = false;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);
  form!: FormGroup;
  settings: SitefinitySettings = new SitefinitySettings;
  validationMsg = this.settings.validationMsgTypeMap;
  isSubmited: boolean = false;
  provinces: IProvince[] = [];
  selectedProvince = 0;
  cantons: ICanton[] = [];
  districts: any[] = [];
  selectedCanton: number = 0;
  selectedDistrict: number = 0;
  error: boolean = false;
  codeError: boolean = false;
  messageError!: string;

  // ICONO Y PROPIEDADES DEL MAPA
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
    // icon: `${environment.urlBase}${this.settings.iconMaker}`,
    animation: google.maps.Animation.DROP
  };

  maker: IMakerAccount = {
    lat: this.settings.latDefault,
    lng: this.settings.lngDefault
  };

  // CONFIGURACION DEL MAPA
  myLatLng = { lat: this.settings.lat, lng: this.settings.lng }; // Map Options
  defaultIcon = { lat: this.settings.latDefault, lng: this.settings.lngDefault }; // Map Options
  mapOptions: google.maps.MapOptions = {
    center: this.defaultIcon,
    zoom: this.settings.zoom,
    streetViewControl: false
  };


  LatLngComparation = {

    lat: 0,
    lont: 0

  }
  LatLngInitilize = {

    lat: 0,
    lont: 0

  }


  @ViewChild('googleMap', { static: true }) map!: google.maps.Map;
  placeSearch = '';

  resetValue = false;

  ModalWarningAddress: IModal = {
    ModalName: 'ModalWarningAddress',
    Title:'',
    Text:'',
    BtnConfirmText: 'Aceptar',
    ShowCancelBtn: true,
    BtnCancelText: 'Revisar'
  }
  showModalWarningAddress:boolean = false;
  showForm:boolean = true;

  AddressByLatLong: any = {
  idProvince:0,
  idCanton:0,
  idDistrict:0

  }



  constructor(
    private fb: FormBuilder,
    private loader: LoadingService,
    private cartService: CartService,
    private utilitiesService: UtilitiesService,
    private _utilitiesService: UtilitiesServicesService,
    private notificationService: NotificationService,
    private clientService: ClientsService,
    public utilities : UtilitiesService,
    private _clientService: ClientsService,
  ) {
    this.form = this.fb.group({
      placeSearch: [''],
      direction: ['', [Validators.required]],
     // province: ['', [Validators.required]],
     // canton: ['', [Validators.required]],
     // district: ['', [Validators.required]],
      detail: ['', [Validators.required]]
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.showDirection && changes.showDirection.currentValue) {
    }
    if (changes && changes.latLng && changes.latLng.currentValue) {
      if (changes.latLng.currentValue.lat !== 0 && changes.latLng.currentValue.lng !== 0) {
        this.myLatLng = { lat: changes.latLng.currentValue.lat, lng: changes.latLng.currentValue.lng };

        this.mapOptions = {
          center: this.myLatLng,
          zoom: this.settings.zoomDistritoModal,
          streetViewControl: false
        };
        this.maker = {
          lat: changes.latLng.currentValue.lat,
          lng: changes.latLng.currentValue.lng
        }


        //este bloque es para comparar y mandar un msjs diferente cuando se da crear la direccion.
        this.LatLngInitilize.lat = changes.latLng.currentValue.lat;
        this.LatLngInitilize.lont = changes.latLng.currentValue.lng;


        this.LatLngComparation.lat = changes.latLng.currentValue.lat;
        this.LatLngComparation.lont = changes.latLng.currentValue.lng;


        this.GeoCode(changes.latLng.currentValue.lat, changes.latLng.currentValue.lng);
      }
    }
    if (changes && changes.showModalDirection && changes.showModalDirection.currentValue) {
      this.resetValue = !this.resetValue;
    }
  }

  get direction() { return this.form.get('direction'); }
 // get province() { return this.form.get('province'); }
//  get canton() { return this.form.get('canton'); }
//  get district() { return this.form.get('district'); }
  get detail() { return this.form.get('detail'); }

  ngOnInit(): void {
    this.getProvinces();
  }

  private getProvinces() {
 //   this.loader.showSpinner();
    this.utilitiesService.getProvinces().subscribe(res => {
      this.provinces = JSON.parse(res.Parameters);
  //    this.loader.hideSpinner();
    }, error => {
      this.loader.hideSpinner();
    });
  }

  onChangeProvince(event: any) {
 //   this.loader.showSpinner();
    this.utilitiesService.GetCantonsCompleteByIDProvince(this.selectedProvince.toString()).subscribe(res => {
      this.selectedCanton = 0;
      this.cantons = [];
      this.cantons = JSON.parse(res.Parameters);

      if (this.cantons.length === 1) {
        this.selectedCanton = this.cantons[0].IDAddressCanton;

      }
   //   this.loader.hideSpinner();
      this.onChangeCanton(null);
    }, error => {
      this.loader.hideSpinner();
    });
  }

  onChangeCanton(event: any) {
    if (this.selectedCanton) {
    //  this.loader.showSpinner();
      this.utilitiesService.GetDistrictsByIDCanton(this.selectedCanton.toString()).subscribe(res => {
        this.districts = JSON.parse(res.Parameters);
     //   this.loader.hideSpinner();
      }, error => {
        this.loader.hideSpinner();
      });
    }
  }

  async OpenWarningAddressModal() {
    if (this._utilitiesService.isUserLoggedIn()) {
      let user: any = this._utilitiesService.getValueStorage('sessionStorage');
      let cartId = await this._utilitiesService.decryptData(user.c);
      let request: CartAddress = {
        DeliveryMethodName: "2",
        DeliveryMethodSecondChoice: null,
       FullAddress:   this.form.value.detail,
        IdAddressCanton:parseInt(this.selectedCanton.toString()), //this.form.value.canton,
        IdAddressDistrict: parseInt(this.selectedDistrict.toString()),//this.form.value.district,
        IdAddressProvince: parseInt(this.selectedProvince.toString()), // parseInt(this.form.value.province),
        IdClientAddress: 0,
        IdDeliveryArea: 0,
        IdDeliveryMethod: 2, //express
        IdShop: 0,
        IsDefault: false,
        IsOpen: true,
        MapPoints: {
          Latitude: this.myLatLng.lat,
          Longitude: this.myLatLng.lng
        },
        Name: this.form.value.direction,
        Phone: null,
        PickupDate: "",
        PickupTime: "",
        Status: "AP",
        TableNumber: 0,
        ToSave: true
      };
      console.log("Pro",this.selectedProvince);
      console.log("cant",this.selectedCanton);
      console.log("dist",this.selectedDistrict);
      console.log("form",this.form.invalid);
      if (this.form.invalid || (this.selectedProvince === 0 || this.selectedCanton === 0 || this.selectedDistrict === 0)) {
        this.isSubmited = true;
      }
      else {
        this.loader.showSpinner();
        this.clientService.GetNearestShopAndDeliveryAreaMethod(this.myLatLng.lat, this.myLatLng.lng).subscribe(o => {
          if (o.responseObject.IDShop === 0) {
            this.messageError = o.response.message;
            this.error = true;
          }
          else {
            request.IdShop = o.responseObject.IDShop;
            request.IdDeliveryArea = o.responseObject.IDDeliveryArea;
            this.cartService.AddAddressToCartMethod(cartId, request).subscribe(res => {
              if (res.response.code === "OK0001-AddAdress-success") {
                this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
                this.close();
              }
              else {
                this.notificationService.SendNotification(res.response.type, res.response.titleMessage, res.response.message);
                this.messageError = res.response.message;
                this.error = true;
              }
            });
          }
          this.loader.hideSpinner();
        });
      }
    }
  }

  makerDragend(event: any) {
    this.myLatLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    }

    this.LatLngComparation.lat = event.latLng.lat();
    this.LatLngComparation.lont =event.latLng.lng();
  }

  getEstablishmentAddress(event: any) {
    this.setValuesMaker(event.geometry.location.lat(), event.geometry.location.lng());

    this.GeoCode(event.geometry.location.lat(), event.geometry.location.lng());

    this.LatLngComparation.lat = event.geometry.location.lat();
    this.LatLngComparation.lont = event.geometry.location.lng();


  }

  private setValuesMaker(lat: number, lng: number) {

    const point: google.maps.LatLngLiteral = {
      lat: lat,
      lng: lng,
    };

    this.myLatLng = { lat: lat, lng: lng };
    this.mapOptions = {
      center: this.myLatLng,
      zoom: this.settings.zoomDistritoModal,
      streetViewControl: false
    };

    this.markerOptions = {
      draggable: true
    };

    this.maker = new google.maps.LatLng(point);
    this.map.panTo(point);
  }

  close() {
    this.isSubmited = false;

    this.cantons = [];
    this.districts = [];
    this.selectedProvince = 0;
    this.selectedCanton = 0;
    this.selectedDistrict = 0;
    this.error = false;
    this.placeSearch = '';
    // ICONO Y PROPIEDADES DEL MAPA
    this.myLatLng = { lat: this.latLng.lat, lng: this.latLng.lng };
         this.myLatLng = { lat: this.latLng.lat, lng: this.latLng.lng };

    this.LatLngInitilize.lat =  this.latLng.lat;
   this.LatLngInitilize.lont =this.latLng.lng;


   this.LatLngComparation.lat =  this.latLng.lat;
   this.LatLngComparation.lont =this.latLng.lng;



    let location = this.myLatLng.lat != 0 && this.myLatLng.lng != 0 ? this.myLatLng : this.defaultIcon;
    this.mapOptions = {
      center: location,
      zoom: this.settings.zoomDistritoModal,
      streetViewControl: false
    };
    this.maker = {
      lat: location.lat,
      lng: location.lng
    }

    this.form = this.fb.group({
      placeSearch: [''],
      direction: ['', [Validators.required]],
       // province: [0, [Validators.required]],
      // canton: [0, [Validators.required]],
      // district: [0, [Validators.required]],
      detail: ['', [Validators.required]]
    });
    this.form.updateValueAndValidity();

    this.closeModal.emit(true);
    this.resetValue = !this.resetValue;
  }

  addAddress()
  {




   if(this.LatLngComparation.lat == this.LatLngInitilize.lat && this.LatLngComparation.lont == this.LatLngComparation.lont)
   {
          this._clientService.getMessages("AddressWarningModal","1").subscribe(o => {
            this.isSubmited = false;
          let formulario: any = document.getElementById("miFormulario");
           formulario.style.display = 'none';
          this.ModalWarningAddress.Title = o.titleMessage;
          this.ModalWarningAddress.Text =  o.message;
          this.ModalWarningAddress.BtnConfirmText = o.buttonAcceptText;
          this.ModalWarningAddress.BtnCancelText = o.buttonCancelText;
          this.showModalWarningAddress = true;
          });

   }else
   {


    this._clientService.getMessages("AddressWarningModalOtherLocation","1").subscribe(o => {
      this.isSubmited = false;
      let formulario: any = document.getElementById("miFormulario");
       formulario.style.display = 'none';
      this.ModalWarningAddress.Title = o.titleMessage;
      this.ModalWarningAddress.Text =  o.message;
      this.ModalWarningAddress.BtnConfirmText = o.buttonAcceptText;
      this.ModalWarningAddress.BtnCancelText = o.buttonCancelText;
      this.showModalWarningAddress = true;
      });

   }







  }

  ModalErrorAction (event: any) {



    if(event)
    {

      this.showModalWarningAddress = false;
      let formulario: any = document.getElementById("miFormulario");
      formulario.style.display = 'block';
      this.OpenWarningAddressModal();
    }
    else
    {

      this.showModalWarningAddress = false;
      let formulario: any = document.getElementById("miFormulario");
      formulario.style.display = 'block';

    }



}

  GeoCode(lat_: number, lng_: number) {
    let geocoder = new google.maps.Geocoder();
    let latlng = { lat: lat_, lng:lng_ };
    geocoder.geocode({ location: latlng }, (results: any, status: google.maps.GeocoderStatus) => {

      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          let provincia: string = "";
          let canton: string = "";
          let distrito: string = "";

          for (const result of results) {
            const addressComponents = result.address_components;
            for (const component of addressComponents) {
                if (component.types.includes("administrative_area_level_1")) {
                    provincia = component.long_name;
                }
                if (component.types.includes("administrative_area_level_2")) {
                    canton = component.long_name;
                }
                if (component.types.includes("administrative_area_level_3")) {
                    distrito = component.long_name;
                }
                // Romper el bucle si todos los niveles administrativos se han encontrado
                if (provincia != "" && canton != "" && distrito != "") {
                    break;
                }
            }
          }

          this.utilitiesService.getProvinces().subscribe(res => {
          this.provinces = JSON.parse(res.Parameters);
          const words_provinces = provincia.split(' ');
          let result_province:any = this.provinces.find(provincia =>
            words_provinces.some(words_provinces => provincia.Name.toLowerCase().includes(words_provinces.toLowerCase()))
          );

          if (result_province != undefined && result_province?.Name != '') {
            this.AddressByLatLong.idProvince = result_province.IDAddressProvince;
            this.selectedProvince = result_province.IDAddressProvince;
            this.utilitiesService.GetCantonsCompleteByIDProvince(this.selectedProvince.toString()).subscribe(res => {
              this.selectedCanton = 0;
              this.cantons = [];
              this.cantons = JSON.parse(res.Parameters);
              if (this.cantons.length > 0) {
                const words_cantons = canton.split(' ');
                let canton_result:any = this.cantons.find(canton =>
                words_cantons.some(words_cantons => canton.Name.toLowerCase().includes(words_cantons.toLowerCase())));
                if (canton_result != undefined && canton_result?.Name != '') {
                  this.selectedCanton = canton_result.IDAddressCanton;
                  this.AddressByLatLong.idCanton = canton_result.IDAddressCanton;
                  this.utilitiesService.GetDistrictsByIDCanton(this.selectedCanton.toString()).subscribe(res => {
                  this.districts = JSON.parse(res.Parameters);
                  if (this.districts.length > 0) {
                    const words_distric = distrito.split(' ');
                    let district_result:any = this.districts.find(district => words_distric.some(words_distric => district.Name.toLowerCase().includes(words_distric.toLowerCase())));
                    if (district_result != undefined && district_result?.Name != '') {
                      this.AddressByLatLong.idDistrict = district_result.IDAddressDistrict;
                      this.selectedDistrict = district_result.IDAddressDistrict;
                      this.loader.hideSpinner();
                    }
                    else {
                      this.selectedProvince = 1;
                      this.selectedCanton = 19;
                      this.selectedDistrict =  115
                      this.loader.hideSpinner();
                    }
                  }
                  else {
                    this.selectedProvince = 1;
                    this.selectedCanton = 19;
                    this.selectedDistrict =  115
                    this.loader.hideSpinner();
                  }}, 
                  error => {
                    this.loader.hideSpinner();
                  });
                }
                else {
                  this.selectedProvince = 1;
                  this.selectedCanton = 19;
                  this.selectedDistrict =  115
                  this.loader.hideSpinner();
                }
              }
              else{
                this.selectedProvince = 1;
                this.selectedCanton = 19;
                this.selectedDistrict =  115
                this.loader.hideSpinner();
              }
            });
          }
        else {
          this.selectedProvince = 1;
          this.selectedCanton = 19;
          this.selectedDistrict =  115
          this.loader.hideSpinner();
        }});
      } 
        else {
          this.loader.hideSpinner();
          this.selectedProvince = 1;
          this.selectedCanton = 19;
          this.selectedDistrict =  115;
        }
      } 
      else {
        this.loader.hideSpinner();
        this.selectedProvince = 1;
        this.selectedCanton = 19;
        this.selectedDistrict =  115
      }
    });
  }
}
