<main *ngIf="source">
  <div class="main d-flex align-items-center">
    <div class="container ">
      <div class="text-center">
        <i class="bi bi-cart-check-fill icon-page-color"></i>
        <h1 class="">¡Muchas gracias por tu compra!</h1>
        <p class="">Podés seguir el estado de tu pedido desde
          <a routerLink="/micuenta">Mi cuenta</a>
        </p>
        <p *ngIf="!open">La orden será entregada una vez que la farmacia esté abierta.</p>
        <button class="btn btn-outline-secondary" routerLink="/index">Ir al inicio</button>
      </div>
    </div>
  </div>
</main>

<main *ngIf="!source">
  <div class="main d-flex align-items-center">
    <div class="container ">
      <div class="text-center">
        <i class="bi bi-cart icon-page-color"></i>
        <h1 class="">Compra no exitosa</h1>
        <p class="">Lo sentimos no podemos procesar tu pedido, por favor revisá tu información y volvé a intentarlo nuevamente.</p>
        <button class="btn btn-outline-secondary" routerLink="/payment">Regresar</button>
      </div>
    </div>
  </div>
</main>
